import { NgModule } from '@angular/core';
import { FunctionLockService, PayrollService } from "@services";
import { SlickToastModule } from '@slick-components';

import { AccountingStore } from "./accounting.store";
import { ChatStore } from "./chat.store";
import { CreditCardStore } from "./credit-card.store";
import { InventoryStore } from "./inventory.store";
import { LathamOrdersPickListStore } from "./latham-orders-pick-list.store";
import { LoggerStore } from "./logger.store";
import { PhotoUploadStore } from "./photo-upload.store";
import { SignalRMessageStore } from "./signalr-message.store";
import { TabsStore } from "./tabs.store";
import { TextChatStore } from "./text-chat.store";
import { ThemeStore } from "./theme.store";
import { TimeclockStore } from "./timeclock.store";

@NgModule({
	providers: [AccountingStore,
		ChatStore,
		CreditCardStore,
		InventoryStore,
		LathamOrdersPickListStore,
		LoggerStore,
		PhotoUploadStore,
		SignalRMessageStore,
		TabsStore,
		TextChatStore,
		ThemeStore,
		TimeclockStore,
		SlickToastModule, SlickToastModule]
})
export class StoresModule { }

