import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ComponentsModule } from "@components";
import { SalesOrdersComponentsModule } from "@app/sales-orders/sales-orders-components";
import { InvoiceComponentsModule } from "@app/invoices/invoice-components";
import { CustomerSearchModule } from "@components/customer-search";

import { RecurringAppointmentInfoComponent } from "./recurring-appointment-info/recurring-appointment-info.component";
import { RecurrenceEditComponent } from "./recurrence-edit/recurrence-edit.component";
import { FrequencyEditComponent } from "./frequency-edit/frequency-edit.component";
import { AppointmentEditComponent } from "./appointment-edit/appointment-edit.component";
import { ChecklistDialogComponent } from "./checklist-dialog/checklist-dialog.component";
import { ChecklistEditComponent } from "./checklist-dialog/checklist-edit/checklist-edit.component";
import { MarksDialogComponent } from "./marks-dialog/marks-dialog.component";
import { MarksEditComponent } from "./marks-dialog/marks-edit/marks-edit.component";
import { RecurringAppointmentLayoutComponent } from "./recurring-appointment-layout/recurring-appointment-layout.component";
import { SlickConfirmDialogModule, SlickDropDownModule, SlickDatePickerModule, SlickValidationIndicatorModule, SlickDialogModule, SlickTabsModule, SlickButtonSpinnerModule } from "@slick-components";
@NgModule({
    imports: [CommonModule, FormsModule, ComponentsModule, CustomerSearchModule, InvoiceComponentsModule, SalesOrdersComponentsModule,
        SlickConfirmDialogModule, SlickDropDownModule, SlickDatePickerModule, SlickValidationIndicatorModule, SlickDialogModule, SlickTabsModule, SlickButtonSpinnerModule],
    declarations: [RecurringAppointmentInfoComponent, RecurrenceEditComponent, FrequencyEditComponent, AppointmentEditComponent, ChecklistDialogComponent, ChecklistEditComponent, MarksDialogComponent, MarksEditComponent, RecurringAppointmentLayoutComponent],
    exports: [RecurringAppointmentInfoComponent, RecurrenceEditComponent, FrequencyEditComponent, AppointmentEditComponent, ChecklistDialogComponent, ChecklistEditComponent, MarksDialogComponent, MarksEditComponent, RecurringAppointmentLayoutComponent]
})
export class AppointmentsComponentsModule {}
