import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SlickConfirmDialogModule } from "../slick-confirm-dialog/slick-confirm-dialog.module";
import { SlickTreeViewComponent } from "./slick-tree-view.component";

@NgModule({
	imports: [CommonModule, FormsModule, SlickConfirmDialogModule],
	declarations: [SlickTreeViewComponent],
	exports: [CommonModule, FormsModule, SlickTreeViewComponent]
})
export class SlickTreeViewModule { }

export { SlickTreeViewComponent }
export { ISlickTreeNodeModel, SlickTreeNodeModel } from "./slick-tree-node.model";
