import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { DocumentModel, IDocumentModel, IDocumentFolderModel } from "@models";
import { UtilsService } from "./utils/utils.service";
import { GlobalsService } from "./utils/globals.service";
import moment from "moment";

export enum DocumentTypes { Customers, Jobs, Invoices, Payments, LathamOrders, Users, Global, Logo }

@Injectable()
export class DocumentsService {
	constructor(private readonly httpService: HttpService) {
	}

	async getAllGlobalDocumentFolders(): Promise<IDocumentFolderModel[]> {
		return this.httpService.get(`/documents/getAllGlobalDocumentFolders`);
	}

	async addFolder(folder: IDocumentFolderModel): Promise<IDocumentFolderModel> {
		return this.httpService.post('documents/addFolder', folder);
	}

	async updateFolder(folder: IDocumentFolderModel): Promise<IDocumentFolderModel> {
		return this.httpService.post('documents/updateFolder', folder);
	}

	async deleteFolders(folders: IDocumentFolderModel[]): Promise<boolean> {
		const folderUuids = folders.map(x => x.uuid);
		return this.httpService.delete('documents/deleteFolders', { folderUuids: folderUuids });
	}

	async getAllDocuments(folderUuid: string): Promise<IDocumentModel[]> {
		return await this.httpService.get(`documents/getAllDocuments?folderUuid=${folderUuid}`);
	}

	async addDocuments(folderUuid: string, fileModels: IDocumentModel[]): Promise<IDocumentModel[]> {
		const formData = new FormData();
		fileModels.forEach(file => {
			formData.append("file", file.file, file.file.name);
		});

		return await this.httpService.postMultipart(`documents/addDocuments?folderUuid=${folderUuid}`, formData);
	}

	async getPhotos(documentKey: string, documentType: DocumentTypes): Promise<IDocumentModel[]> {
		try {
			let subFolder: string;

			switch (documentType) {
				case DocumentTypes.Customers:
					subFolder = "Customers";
					break;

				case DocumentTypes.Jobs:
					subFolder = "Jobs";
					break;

				case DocumentTypes.Invoices:
					subFolder = "Invoices";
					break;

				case DocumentTypes.Payments:
					subFolder = "Payments";
					break;

				case DocumentTypes.LathamOrders:
					subFolder = "LathamOrders";
					break;

				case DocumentTypes.Users:
					subFolder = "Users";
					break;
			}
			const params = {
				documentKey,
				subFolder1: "Photos",
				subFolder2: subFolder
			};

			return this.httpService.get("/documents/getDocuments", params);
		} catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getDocuments(documentKey: string, documentType: DocumentTypes): Promise<IDocumentModel[]> {
		try {
			let subFolder: string;
			switch (documentType) {
				case DocumentTypes.Global:
					subFolder = "global";
					break;

				case DocumentTypes.Customers:
					subFolder = "Customers";
					break;

				case DocumentTypes.Jobs:
					subFolder = "Jobs";
					break;

				case DocumentTypes.Invoices:
					subFolder = "Invoices";
					break;

				case DocumentTypes.Payments:
					subFolder = "Payments";
					break;

				case DocumentTypes.LathamOrders:
					subFolder = "LathamOrders";
					break;

				case DocumentTypes.Users:
					subFolder = "Users";
					break;
			}

			const params = {
				documentKey,
				subFolder1: "Documents",
				subFolder2: subFolder
			};
			return this.httpService.get("/documents/getDocuments", params);
		} catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getAllJobPhotosForCustomer(documentKey: string): Promise<IDocumentModel[]> {
		return this.httpService.get('/documents/getAllJobPhotosForCustomer', { documentKey });
	}

	async updatePhotos(documentKey: string, documentType: DocumentTypes, fileModels: IDocumentModel[]): Promise<IDocumentModel[]> {
		try {
			const formData = new FormData();
			fileModels.forEach(file => {
				if (file.base64Image && file.base64Image.length > 0) {
					const fileCopy: IDocumentModel = UtilsService.clone(file);
					const fileName = file.name;
					delete fileCopy.file;
					delete fileCopy.thumbnailBase64Image;
					const fileJSON = JSON.stringify(fileCopy);
					formData.append(fileName, fileJSON);
				}
			});

			const returnFileModels = await this.httpService.postMultipart(`/documents/savePhotos?documentKey=${documentKey}&documentType=${documentType}`, formData);
			return Promise.resolve(returnFileModels);
		} catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async updateDocuments(documentKey: string, documentType: DocumentTypes, fileModels: IDocumentModel[]): Promise<IDocumentModel[]> {
		try {
			const formData = new FormData();
			fileModels.forEach(file => {
				formData.append("file", file.file, file.file.name);
			});

			let url = "/documents/saveDocuments?documentType=" + documentType;

			if (documentKey) {
				url += "&documentKey=" + documentKey;
			}

			const returnFileModels = await this.httpService.postMultipart(url, formData);
			return Promise.resolve(returnFileModels);
		} catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async saveAsExcel(data: any): Promise<DocumentModel> {
		return await this.httpService.post('/documents/saveAsExcel', data);
	}

	async getFile(documentId: number) {
		const params = {
			documentId: documentId
		};
		return this.httpService.get('/documents/getDocument', params);
	}

	async deleteDocument(documentId: number): Promise<boolean> {
		try {
			return this.httpService.delete("/documents/deleteDocument", { documentId: documentId });
		} catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async openDocument(documentId: number) {
		try {
			const w = window.open('', '_blank');

			w.location.href = `${GlobalsService.apiUrl}/documents/openDocument?companyId=${GlobalsService.company.companyId}&documentId=${documentId}`;
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

}
