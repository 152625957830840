import { Injectable } from "@angular/core";
import { HttpService } from "../utils/http.service";
import { ICreditCardProcessPaymentModel, ICreditCardPaymentTypeModel, ICreditCardProcessPaymentResult, IPaymentModel } from "@models";
import { ICreditCardService } from "./credit-card.service";
import { throwError } from "rxjs";
import { error } from "console";

@Injectable()
export class BamboraService implements ICreditCardService {

	constructor(private readonly httpService: HttpService) { }

	async getNewCreditCardPaymentType(customerId: number): Promise<ICreditCardPaymentTypeModel> {
		return await this.httpService.get("/bambora/getNewCreditCardPaymentType", { customerId: customerId });
	}

	async getCreditCardPaymentType(creditCardPaymentTypeId: number): Promise<ICreditCardPaymentTypeModel> {
		return await this.httpService.get("/bambora/getCreditCardPaymentType", { creditCardPaymentTypeId: creditCardPaymentTypeId });
	}

	async getCreditCardPaymentTypeForCustomer(customerId: number): Promise<ICreditCardPaymentTypeModel[]> {
		return await this.httpService.get("/bambora/getCreditCardPaymentTypeForCustomer", { customerId: customerId });
	}

	async addCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
		return await this.httpService.post("/bambora/addCreditCardPaymentType", creditCardPaymentTypeModel);
	}

	async updateCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
		return await this.httpService.post("/bambora/updateCreditCardPaymentType", creditCardPaymentTypeModel);
	}

	async deleteCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
		return await this.httpService.post("/bambora/deleteCreditCardPaymentType", creditCardPaymentTypeModel);
	}

	async processPrePayment(processCreditCardPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardProcessPaymentResult> {
		return await this.httpService.post("/bambora/processPrePayment", processCreditCardPaymentModel);
	}

	async processPayment(processCreditCardPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardProcessPaymentResult> {
		return await this.httpService.post("/bambora/processPayment", processCreditCardPaymentModel);
	}

	async voidPayment(paymentModel: IPaymentModel): Promise<IPaymentModel> {
		return await this.httpService.post("/bambora/voidPayment", paymentModel);
	}

	async refundPayment(paymentModel: IPaymentModel, amount: number): Promise<IPaymentModel> {
		throw new Error("Not Implemented");
	}
	

}