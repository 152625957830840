import { Component, OnChanges, Input, HostBinding, ViewChild, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { ChatStore } from "@stores";
import { ChatService, GlobalsService, SleepService } from "@services";
import { IChatGroupModel, IChatMessageModel, ChatMessageModel, NewGroupModel, NewMessageModel, IDocumentModel } from "@models";
import { Subscription } from "rxjs";
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { SlickPhotoGalleryComponent, SlickPhotoGalleryModel } from "@slick-components";


class DailyMessages {
	chatMessages: IChatMessageModel[] = [];
	dateString: string;
	constructor() { }
}


@Component({
	selector: "chat-messages",
	templateUrl: "./chat-messages.component.html",
	styleUrls: ['./chat-messages.component.scss']
})
export class ChatMessagesComponent implements OnChanges, OnInit, OnDestroy {
	@HostBinding('style.flex') flex = '1 1';

	@Input() chatGroup: IChatGroupModel;
	@ViewChild('autosize') autosize: CdkTextareaAutosize;

	@ViewChild("messagesDiv") messagesDiv: ElementRef;
	@ViewChild("newMessageRef") newMessageRef: ElementRef;
	@ViewChild("photoGalleryRef") photoGalleryRef: SlickPhotoGalleryComponent;



	chatMessages: IChatMessageModel[] = [];
	userId = GlobalsService.userInfo.userId;
	newMessage: string;
	onNewMessage$: Subscription;
	dailyMessagesLists: DailyMessages[];
	photos: SlickPhotoGalleryModel[];


	constructor(private readonly chatService: ChatService,
		private readonly chatStore: ChatStore) {
}

	async ngOnChanges() {
		this.chatMessages = null;
		this.newMessage = null;
		if (this.chatGroup) {
			this.chatMessages = await this.chatService.getChatMessages(this.chatGroup.id, 100, 0);
			this.scrollToBottomOfActiveMessages();
			setTimeout(() => {
				(<HTMLInputElement>this.newMessageRef.nativeElement).focus();
			}, 500);
		}
		this.dailyMessagesLists = [];
		if (this.chatMessages.length > 0)
			this.dailyMessagesLists = this.chatService.buildChatDays(this.chatMessages);
		
	}

	async ngOnInit() {
		this.onNewMessage$ = this.chatStore.onNewMessageStore.subscribe((newMessage) => {
			if ((newMessage.chatGroupId === this.chatGroup.id && newMessage.senderId !== this.chatStore.currentUser.userId) || newMessage.imageUrl) {
				this.chatStore.playNewMessageSound(newMessage);
				this.chatMessages.push(newMessage);
				this.dailyMessagesLists = this.chatService.buildChatDays(this.chatMessages);

				this.scrollToBottomOfActiveMessages();

				(<HTMLInputElement>this.newMessageRef.nativeElement).focus();
			}
		})

		
	}

	ngOnDestroy() {
		if (this.onNewMessage$)
			this.onNewMessage$.unsubscribe();


	}

	onKeyDown(e: KeyboardEvent) {
		if (e.which === 13) {
			e.preventDefault();
			e.stopPropagation();
			this.sendMessage();
		}
	}

	async sendMessage() {
		if (this.newMessage && this.newMessage.trim().length !== 0) {
			const newChatMessageModel = new ChatMessageModel();
			newChatMessageModel.message = this.newMessage;
			newChatMessageModel.senderId = GlobalsService.userInfo.userId;
			newChatMessageModel.chatGroupId = this.chatGroup.id;
			newChatMessageModel.sentOn = new Date();
			const newMessageModel = new NewMessageModel();
			newMessageModel.groupId = this.chatGroup.id;
			newMessageModel.message = this.newMessage;
			this.chatMessages.push(newChatMessageModel);
			await this.chatService.sendMessage(newMessageModel);
			this.newMessage = null;
			this.dailyMessagesLists = this.chatService.buildChatDays(this.chatMessages);
			this.chatGroup.isDeleted = false;
			this.scrollToBottomOfActiveMessages();
		}
	}

	private scrollToBottomOfActiveMessages() {
		setTimeout(async () => {
			this.messagesDiv.nativeElement.scrollTop = this.messagesDiv.nativeElement.scrollHeight;
		});
	}

	onImageClicked(message: IChatMessageModel) {
		this.photos = this.chatMessages
			.filter(x => x.imageUrl !== null)
			.map(x => {
				const photo = new SlickPhotoGalleryModel();
				photo.photoUrl = x.imageUrl;
				return photo;
			});
		const idx = this.photos.findIndex(x => x.photoUrl === message.imageUrl);
		this.photoGalleryRef.showPhotoGallery(idx);
	}

	async onFilesDropped(files: IDocumentModel[]) {
		if (!files || files.length === 0)
			return;

		await this.chatStore.sendAttachments(files);
	}

	

}
