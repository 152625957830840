import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { CustomersService, UtilsService, SleepService, GlobalsService, LookupService } from "@services";
import { CustomerModel, ICustomerModel } from "@models/customers/customer.model";
import { ICustomerListModel } from "@models/customers/customer-list.model";
import { ICustomerMultipleAddressModel } from "@models/customers/customer-multiple-address.model";
import { CustomerEditDialogComponent } from "@app/customers/customer-components";
import { SlickDialogComponent, SlickValidationTypes } from "@slick-components";
import { IAddressModel, ITaxRegionModel } from "@models";
import Swal from "sweetalert2";

@Component({
	selector: "customer-search",
	templateUrl: "./customer-search.component.html",
	styleUrls: ["./customer-search.component.css"],
	providers: [CustomersService]
})

export class CustomerSearchComponent implements OnChanges {
	@Input() searchText: string;
	@Input() customerModel: ICustomerModel;
	@Input() showSearchIcon: boolean = true;
	@Input() condensedButtons: boolean = false;
	@Input() placeholder: string = "";
	@Input() submitted: boolean;
	@Input() showButtons: boolean = true;
	@Input() valType = SlickValidationTypes.None;
	@Input() isBillingCustomer: boolean = false;
	@Input() showBuildersOnly: boolean = false;
	@Input() showHomeOwnersOnly: boolean = false;
	@Input() showCustomerDisplay: boolean = true;
	@Input() useCompanyId: number;
	@Input() disabled: boolean;
	@Output() customerModelChange: EventEmitter<ICustomerModel> = new EventEmitter();
	@Output() customerMultiAddressChange: EventEmitter<ICustomerMultipleAddressModel> = new EventEmitter();
	@Input() disableButtons: boolean
	@Input() includeMultiAddress: boolean = false;
	@Input() customerMultiAddress: ICustomerMultipleAddressModel;
	@Input() showAttentionNote: boolean = GlobalsService.company.customerAttentionNotePopUp;;
	@Input() editable: boolean = false;
	@Input() billingAddress: IAddressModel;
	@Input() showTaxRegionPopUp: boolean = true;



	@ViewChild("customerEditDialogRef", { static: true }) customerEditDialogRef: CustomerEditDialogComponent;
	@ViewChild("attentionNoteRef", { static: true }) attentionNoteRef: SlickDialogComponent;
	
	customerFullName: string;
	results: ICustomerListModel[];

	selectedCustomerBasic: ICustomerListModel;
	showOwningCompany: boolean;


	showCreditHoldNotes: boolean;

	constructor(private readonly customersService: CustomersService,
		private readonly lookupService: LookupService) {
	}

	async showEditCustomerDialog() {
		const editedCustomer = await this.customerEditDialogRef.openDialog(UtilsService.clone(this.customerModel));

		if (editedCustomer) {
			this.customerModel = editedCustomer;
			this.customerModelChange.emit(this.customerModel);
		}
	}

	async showNewCustomerDialog() {
		var customer = new CustomerModel();
		const newCustomer = await this.customerEditDialogRef.openDialog(customer);

		if (newCustomer) {
			this.customerModel = newCustomer;
			this.customerModelChange.emit(this.customerModel);
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.showSearchIcon)
			this.showSearchIcon = (this.showSearchIcon.toString().toLowerCase() === "false") ? false : true;

		if (changes.showButtons)
			this.showButtons = (this.showButtons.toString().toLowerCase() === "false") ? false : true;

		if (changes.showCustomerDisplay)
			this.showCustomerDisplay = (this.showCustomerDisplay.toString().toLowerCase() === "false") ? false : true;

		if (changes.isBillingCustomer)
			this.isBillingCustomer = (this.isBillingCustomer.toString().toLowerCase() === "true") ? true : false;
		
		if (changes.showBuildersOnly)
			this.showBuildersOnly = (this.showBuildersOnly.toString().toLowerCase() === "true") ? true : false;

		if (changes.showHomeOwnersOnly)
			this.showHomeOwnersOnly = (this.showHomeOwnersOnly.toString().toLowerCase() === "true") ? true : false;

		if (changes.condensedButtons)
			this.condensedButtons = (this.condensedButtons.toString().toLowerCase() === "true") ? true : false;

		if (changes.customerFullName) {
			this.searchText = changes.customerFullName.currentValue;
		}

		this.showCreditHoldNotes = (this.customerModel?.creditHold && this.customerModel?.creditHoldNotes?.trim().length > 0)
		
		
		if (this.customerModel && !this.customerMultiAddress) {
			this.selectedCustomerBasic = (this.customerModel as any) as ICustomerListModel;
			this.searchText = this.customerModel.companyName && this.customerModel.companyName.length > 0
				? this.customerModel.companyName
				: this.customerModel.fullName;
		}

		if (this.customerMultiAddress) {
			this.searchText = this.customerMultiAddress.description && this.customerMultiAddress.description.length > 0 ?
				this.customerMultiAddress.description : this.customerMultiAddress.companyName;
			if (this.searchText.length === 0) {
				this.searchText = this.customerMultiAddress.firstName.concat(" ", this.customerMultiAddress.lastName);
			}
        }

		if (changes.customerModel && !this.customerModel) {
			this.searchText = "";
			this.selectedCustomerBasic = null;
		}
	}

	async onKeyUp(e: KeyboardEvent) {
		await SleepService.sleep();
		if (!this.searchText && this.customerModel) {
			this.customerModel = null;
			this.customerModelChange.emit(null);
		}
	}

	async onBlur() {
		await SleepService.sleep();
		if (!this.searchText && this.customerModel) {
			this.customerModel = null;
			this.customerModelChange.emit(null);
		}
	}

	async search(searchText) {
		this.results = await this.customersService.getCustomersForList(searchText, this.showBuildersOnly, this.showHomeOwnersOnly, this.includeMultiAddress, this.useCompanyId);

		// If any of the results back are from a different company, then show the owning company
		this.showOwningCompany = (this.results.findIndex(x => x.companyId !== GlobalsService.company.companyId) >= 0);

	}

	async onSelect(customer: ICustomerListModel) {
		this.selectedCustomerBasic = customer;
		this.customerModel = await this.customersService.getCustomer(customer.customerId);
		this.billingAddress = null;
		if (customer.customerMultipleAddressId > 0) {
			this.customerMultiAddress = this.customerModel.customerMultipleAddresses.find(x => x.customerMultipleAddressId == customer.customerMultipleAddressId)

			this.searchText = this.customerMultiAddress.description && this.customerMultiAddress.description.length > 0 ?
				this.customerMultiAddress.description : this.customerMultiAddress.companyName;

			if (this.searchText.length === 0) {
				this.searchText = this.customerMultiAddress.firstName.concat(" ", this.customerMultiAddress.lastName);
			}

			if (this.customerMultiAddressChange)
				this.customerMultiAddressChange.emit(this.customerMultiAddress);

			if (this.customerModelChange)
				this.customerModelChange.emit(this.customerModel);
		}
		else {
			this.searchText = this.customerModel.companyName && this.customerModel.companyName.length > 0
				? this.customerModel.companyName
				: this.customerModel.fullName;
			this.customerMultiAddress = null;

			if (this.customerModelChange)
				this.customerModelChange.emit(this.customerModel);

			if (this.customerMultiAddressChange)
				this.customerMultiAddressChange.emit(this.customerMultiAddress);


		}

		if (GlobalsService.company.useAdvancedTax && !GlobalsService.company.useCereTax && !this.customerModel.taxRegionId && ((this.lookupService.getTaxRegions()?.length ?? 0) > 0) && GlobalsService.company.companyId === 3 && this.showTaxRegionPopUp) {
			await Swal.fire({
				icon: 'warning',
				title: 'Warning',
				text: 'Customer has no tax region',
				confirmButtonColor: '#007bff',
				width: '28em',
				heightAuto: false
			});
		}

 		if((this.customerModel.customerAttentionNote && this.showAttentionNote == true) || (this.customerModel.creditHoldNotes && this.customerModel.creditHold))
			this.attentionNoteRef.showDialog();

	}

	closeAttentionNoteRef() {
		this.attentionNoteRef.hideDialog();
    }

	onCustomerEditSave(customerModel: ICustomerModel) {
		this.customerModel = customerModel;
		if (this.customerModelChange)
			this.customerModelChange.emit(customerModel);
	}
}
