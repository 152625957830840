export interface IRebateModel {
	
	rebateId: number;
	name: string;
	amount: number;
	showOnInvoice: boolean;
	excludeLabor: boolean;
}

export class RebateModel implements IRebateModel {
	constructor() {
		this.rebateId = 0;
	}

	rebateId: number;
	name: string;
	amount: number;
	showOnInvoice: boolean;
	excludeLabor: boolean;
}