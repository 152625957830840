import { Injectable } from '@angular/core';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { DevLogsStore } from '@stores';
import { SQLiteService } from '@sqlite';
import { IInitModel } from '@services/utils/globals.service';
import { DevTraceService } from '@services/utils/dev-trace.service';
import { UtilsService } from '../../services';
import { UserInfo } from 'os';
import { IUserInfoModel } from '../../models';

interface IGlobalSettingsRecordModel {
	uuid: string;
	globalSettingsData: string;
}

@Injectable()
export class GlobalSettingsStorageService {
	private db!: SQLiteDBConnection;
	uuid: string;

	readonly databaseName: string = 'globalSettings';

	constructor(private devLogsStore: DevLogsStore,
		private devTrace: DevTraceService,
		private sqliteService: SQLiteService) {
		this.uuid = UtilsService.newGuid();
	}

	async initializeDatabase(): Promise<void> {
		try {
			this.devLogsStore.addMessage(`InitUuid: ${this.uuid}`);
			await this.sqliteService
				.addUpgradeStatement({
					database: this.databaseName,
					upgrade: this.globalSettingsUpdates
				});

			// create and/or open the database
			const loadToVersion = this.globalSettingsUpdates[this.globalSettingsUpdates.length - 1].toVersion;
			this.db = await this.sqliteService.openDatabase(this.databaseName, loadToVersion);
			this.sqliteService.setVersion(this.databaseName, loadToVersion);
			this.devLogsStore.addMessage(`Init ${this.databaseName} database to version ${loadToVersion}`);

		}
		catch (err) {
			this.devLogsStore.addMessage(`getGlobalSettings: ${this.databaseName}: ${(err as Error)?.message}`);
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	async deleteDatabase(): Promise<void> {
		this.devLogsStore.addMessage(`DeleteUuid: ${this.uuid}`);
		await this.sqliteService.deleteDatabase(this.databaseName);
		this.devLogsStore.addMessage(`Deleted ${this.databaseName}`);
	}

	async test(): Promise<void> {
		this.devLogsStore.addMessage(`DeleteUuid: ${this.uuid}`);
		const databases = await this.sqliteService.getAllDatabases(this.db);
		const str = databases.join(',');
		this.devLogsStore.addMessage(`Databases: ${str}`);
	}

	async updateGlobalSettings(globalSettingsData: IInitModel): Promise<void> {
		try {
			//this.devLogsStore.addMessage(`UpdateUuid: ${this.uuid}`);

			//this.devLogsStore.addMessage(`Adding globalSettings record`);

			const jsonEncoded = SQLiteService.encodeJSON(globalSettingsData);

			//this.devLogsStore.addMessage(`Checking globalSettings record`);
			const globalSettingsRecords = (await this.db.query(`SELECT * FROM globalSettings;`)).values as IGlobalSettingsRecordModel[];
			if (globalSettingsRecords?.length > 0) {
				//this.devLogsStore.addMessage(`Deleting globalSettings records.`);
				await this.db.run(`delete from globalSettings;`);
				//this.devLogsStore.addMessage(`Deleted globalSettings success`);
			}

			const sql = `INSERT INTO globalSettings (uuid, globalSettingsData) VALUES ('${this.uuid}', '${jsonEncoded}')`;
			await this.db.run(sql);
		}
		catch (err) {
			this.devLogsStore.addMessage(`updateGlobalSettings: ${this.databaseName}: ${(err as Error)?.message}`);
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}


	async updateUserInfo(userInfo: IUserInfoModel) {
		try {
			let globalSettings = await this.getGlobalSettings();
			globalSettings.userInfoModel = userInfo;
			this.updateGlobalSettings(globalSettings);
		}
		catch (err) {
			this.devLogsStore.addMessage(`updateUserInfo: ${this.databaseName}: ${(err as Error)?.message}`);
			this.devTrace.addTrace(`updateUserInfo: ${this.databaseName}: ${(err as Error)?.message}`);
		}

	}

	async getGlobalSettings(): Promise<IInitModel> {
		try {
			//this.devLogsStore.addMessage(`GetUuid: ${this.uuid}`);

			//this.devLogsStore.addMessage(`Getting globalSettings record`);
			const globalSettingsRecords = (await this.db.query(`SELECT * FROM globalSettings;`)).values as IGlobalSettingsRecordModel[];

			const globalSettingsEncoded = SQLiteService.decodeJSON(globalSettingsRecords[0].globalSettingsData);

			return <IInitModel>JSON.parse(globalSettingsEncoded);
		}
		catch (err) {
			this.devLogsStore.addMessage(`getGlobalSettings: ${this.databaseName}: ${(err as Error)?.message}`);
			//this.devTrace.addTrace(`getGlobalSettings: ${this.databaseName}: ${(err as Error)?.message}`);
		}

	}

	readonly globalSettingsUpdates = [
		{
			toVersion: 1,
			statements: [
				`
CREATE TABLE IF NOT EXISTS globalSettings(
        uuid TEXT PRIMARY KEY,
		globalSettingsData TEXT NOT NULL
);`
			]
		},
		/* add new statements below for next database version when required*/
		/*
		{
		toVersion: 2,
		statements: [
			`ALTER TABLE users ADD COLUMN email TEXT;`,
		]
		},
		*/
	]
}


