<slick-dialog *ngIf="isVisible === true" #dialogRef [top]='200' [width]='width' [showHeader]='false' [resizable]="false" [draggable]="false" [minimizable]="false" onClose='onClose()'>
	<div class="form-group text-center">
		<h4 style='margin-top: 10px; margin-bottom: 20px'><ng-content></ng-content></h4>
	</div>

	<div class="text-center" style='margin-top: 10px;'>
		<button *ngIf="showOkButton === true" type="button" style='width: 80px' class="btn btn-outline-primary" (click)="onOk()">{{okButtonText}}</button>&nbsp;&nbsp;
		<button *ngIf="showCancelButton === true" type="button" style='width: 80px' class="btn btn-outline-danger" (click)="onCancel()">Cancel</button>
	</div>
</slick-dialog>
