import { Component, Output, ViewChild, EventEmitter, ViewEncapsulation } from '@angular/core';
import { AppointmentEditBase } from "@base-components/appointment-edit.base";
import { ChecklistDialogComponent } from '../checklist-dialog/checklist-dialog.component';
import { MarksDialogComponent } from '../marks-dialog/marks-dialog.component';
import { InvoiceEditDialogComponent } from '@app/invoices/invoice-components';
import { AppointmentsService, JobsService, InvoicesService, LookupService, GlobalsService, UtilsService } from '@services';
import { SlickConfirmDialogComponent, SlickConfirmDialogResults, SlickDialogComponent } from "@slick-components";
import { SalesOrderEditDialogComponent } from '@app/sales-orders/sales-orders-components';
import { DropdownModel, IAppointmentModel, IDropdownModel } from '@models';
import * as moment from 'moment';
import Swal from 'sweetalert2';


@Component({
	selector: 'appointment-edit',
	templateUrl: './appointment-edit.component.html',
	styleUrls: ['appointment-edit.component.scss'],
	providers: [InvoicesService, AppointmentsService],
})

export class AppointmentEditComponent extends AppointmentEditBase {
	@ViewChild('checklistDialogRef') checklistDialogRef: ChecklistDialogComponent;
	@ViewChild('strikesDialogRef') strikesDialogRef: MarksDialogComponent;
	@ViewChild('salesOrderEditDialogRef', { static: true }) salesOrderEditDialogRef: SalesOrderEditDialogComponent;
	@ViewChild('invoiceEditDialogRef', { static: true }) invoiceEditDialogRef: InvoiceEditDialogComponent;
	@ViewChild('noInvoiceErrorRef') noInvoiceErrorRef: SlickConfirmDialogComponent;
	@ViewChild('deleteApptRef') deleteApptRef: SlickConfirmDialogComponent;
	@ViewChild('editTimeDialogRef', { static: true }) editTimeDialogRef: SlickDialogComponent;
	@ViewChild('editCrewDialogRef', { static: true }) editCrewDialogRef: SlickDialogComponent;
	@ViewChild('multiDayDialogRef', { static: true }) multiDayDialogRef: SlickDialogComponent;

	@Output('onAppointmentPrint') onAppointmentPrint: EventEmitter<IAppointmentModel> = new EventEmitter<IAppointmentModel>();
	@Output() onMultiDayAppointmentsAdded: EventEmitter<IAppointmentModel[]> = new EventEmitter<IAppointmentModel[]>();
	@Output() onExpandOrCollapse: EventEmitter<IAppointmentModel> = new EventEmitter<IAppointmentModel>();

	showCustomerNotes = GlobalsService.company.showCustomerNotes;
	useAppointmentReminder = GlobalsService.company.useAppointmentReminder;
	isSubmitted: boolean = false;

	isValid: boolean = false;
	multiDayAppointmentClick: boolean = false;
	multiDates: Date[];
	multiAppointments: IAppointmentModel[] = [];

	allCrewMembers: IDropdownModel[] = [];
	actualCrew: IDropdownModel[] = [];

	constructor(lookupService: LookupService,
		jobsService: JobsService,
		invoicesService: InvoicesService,
		private appointmentsService: AppointmentsService) {
		super(lookupService, jobsService, invoicesService);
	}

	async showEditCrewDialog() {
		this.allCrewMembers = await this.lookupService.getUsers(null, true)
			.filter(x => x.isCrewMember)
			.map(x => { return new DropdownModel(x.userId, x.fullName) });

		const actualCrewIds: string[] = JSON.parse(this.appointment.actualCrew);

		if (actualCrewIds)
			this.actualCrew = actualCrewIds?.map(crewId => {
				return this.allCrewMembers?.find(x => x.id === crewId);
			});
		else
			this.actualCrew = this.lookupService.getResources().find(x => x.resourceId == this.appointment.resourceId).defaultCrewMembers?.map(x => {return new DropdownModel(x.userId, x.fullName) })

		await this.editCrewDialogRef.showDialog();
	}

	onCrewMemberSelect(selectedCrewMember: IDropdownModel, oldCrewMember: IDropdownModel) {
		const idx = this.actualCrew.findIndex(x => x.id === oldCrewMember.id);
		this.actualCrew[idx].id = selectedCrewMember.id;
		this.actualCrew[idx].text = selectedCrewMember.text;
	}

	addCrewMember() {
		this.actualCrew.push(new DropdownModel(0, ""));
	}

	removeCrewMember(crewMember: IDropdownModel) {
		this.actualCrew = this.actualCrew.filter(x => x.id !== crewMember.id);
	}

	async saveActualCrew() {
		//first only get unique and non blank
		const seenIds = new Set();
		this.actualCrew = this.actualCrew.filter(item => {
			if (item.id !== 0 && !seenIds.has(item.id)) {
				seenIds.add(item.id);
				return true;
			}
			return false;
		});

		//update the actual crew
		await this.appointmentsService.updateActualCrew(this.appointment.appointmentId, this.actualCrew.map(x => { return x.id }))

		//update the appt with the new display
		const userIds: number[] = this.actualCrew.map(x => { return x.id });
		const users = this.lookupService.getUsers(null, true)
			.filter(x => userIds.includes(x.userId))
			.map(u => { return u.fullName });
		this.appointment.crewDisplay = users.join(", ");
		this.appointment.actualCrew = `[${userIds.join(", ")}]`
		this.editCrewDialogRef.hideDialog();
	}


	cancelActualCrew() {
		this.editCrewDialogRef.hideDialog();
	}
	
	async showEditTimeDialog() {
		this.isSubmitted = false;
		await this.editTimeDialogRef.showDialog();
	}

	/**  
	 * check to make sure they have the correct format of the times entered
	 * format should be hh:mm am/pm
	 * if the format is incorrect, return false to flag an error
	 */
	validateTimes() {
		let isValid = true;
		if (this.inTransitStartTime) {
			const timeCheck = /^([0-9]|0[0-9]|1[0-2]):[0-5][0-9]\s?[a|A|P|p][M|m]$/.test(this.inTransitStartTime);
			if (!timeCheck) 
				isValid = false;
		}	
		if (this.jobsiteStartTime) {
			const timeCheck = /^([0-9]|0[0-9]|1[0-2]):[0-5][0-9]\s?[a|A|P|p][M|m]$/.test(this.jobsiteStartTime);
			if (!timeCheck)
				isValid = false;
		}
		if (this.completedStartTime) {
			const timeCheck = /^([0-9]|0[0-9]|1[0-2]):[0-5][0-9]\s?[a|A|P|p][M|m]$/.test(this.completedStartTime);
			if (!timeCheck)
				isValid = false;
		}
		return isValid;
	}

	/**
	 * if they dont enter an in transit date or time but enter a jobsite date and time, autofill the transit date and time 
	 * with the jobsite time. Do this for jobsite time and transit time as well if they only input a completed time
	 */
	autofillDateTimes() {
		if ((this.jobsiteStartTime && this.jobsiteDate) && (!this.inTransitStartTime && !this.inTransitDate)) {
			this.inTransitDate = this.jobsiteDate;
			this.inTransitStartTime = this.jobsiteStartTime;
		}
		if ((!this.jobsiteStartTime && !this.jobsiteDate) && (this.completedStartTime && this.completedDate)) {
			this.jobsiteStartTime = this.completedStartTime;
			this.jobsiteDate = this.completedDate;
		}
		if ((this.completedDate && this.completedStartTime) && (!this.inTransitStartTime && !this.inTransitDate)) {
			this.inTransitDate = this.completedDate;
			this.inTransitStartTime = this.completedStartTime;
		}
	}

	/**
	 * check to make sure they have both a date and a time input before we save the times. 
	 * if not we return false to flag an error
	 */
	validateDateTime() {
		
		let isValid = true;
		if (this.inTransitDate && !this.inTransitStartTime || !this.inTransitDate && this.inTransitStartTime)
			isValid = false;
		if (this.jobsiteDate && !this.jobsiteStartTime || !this.jobsiteDate && this.jobsiteStartTime)
			isValid = false;
		if (this.completedDate && !this.completedStartTime || !this.completedDate && this.completedStartTime)
			isValid = false;
		return isValid;
	}

	async saveTimes() {
		this.isSubmitted = true;
		if (this.validateTimes() && this.validateDateTime()) {
			this.autofillDateTimes();
			if (this.inTransitDate && this.inTransitStartTime) {
				const tempTransitTime = `${moment(this.inTransitDate).format("YYYY-MM-DD")} ${this.inTransitStartTime}`;
				this.appointment.inTransitStartTime = moment(tempTransitTime, 'YYYY-MM-DD h:mma').toDate();
			}
			if (this.jobsiteDate && this.jobsiteStartTime) {
				const tempJobTime = `${moment(this.jobsiteDate).format("YYYY-MM-DD")} ${this.jobsiteStartTime}`;
				this.appointment.atJobsiteStartTime = moment(tempJobTime, 'YYYY-MM-DD h:mma').toDate();
			}
			if (this.completedDate && this.completedStartTime) {
				const tempCompletedTime = `${moment(this.completedDate).format("YYYY-MM-DD")} ${this.completedStartTime}`;
				this.appointment.finishedTime = moment(tempCompletedTime, 'YYYY-MM-DD h:mma').toDate();
			}
			if (this.appointment.inTransitStartTime || this.appointment.atJobsiteStartTime || this.appointment.finishedTime)
				if ((this.appointment.inTransitStartTime && this.appointment.atJobsiteStartTime) && (this.appointment.inTransitStartTime > this.appointment.atJobsiteStartTime) ||
					(this.appointment.inTransitStartTime && this.appointment.finishedTime) && (this.appointment.inTransitStartTime > this.appointment.finishedTime) ||
					(this.appointment.atJobsiteStartTime && this.appointment.finishedTime) && (this.appointment.atJobsiteStartTime > this.appointment.finishedTime)) {
					await Swal.fire({
						icon: 'warning',
						title: 'Oops',
						text: 'Input values are incorect',
						confirmButtonColor: '#007bff',
						width: '28em',
						footer: "* Transit time should be before jobsite time. <br/> * Jobsite time should be before completed time.",
						heightAuto: false
					});
					return;
				}
		
			if (this.appointment.inTransitStartTime && this.appointment.finishedTime)
				if (this.appointment.inTransitStartTime > this.appointment.finishedTime)
					return false;
			if (this.appointment.atJobsiteStartTime && this.appointment.finishedTime)
				if (this.appointment.atJobsiteStartTime > this.appointment.finishedTime)
					return false;

			const updatedAppt = await this.appointmentsService.editTimes(this.appointment);
			this.appointment.inTransitTotalDisplay = updatedAppt.inTransitTotalDisplay;
			this.appointment.atJobsiteTotalDisplay = updatedAppt.atJobsiteTotalDisplay;
			this.appointment.finishedTotalDisplay = updatedAppt.finishedTotalDisplay;
			this.editTimeDialogRef.hideDialog();
		}
	}

	cancelEditTimes () {
		this.editTimeDialogRef.hideDialog();
	}
	openChecklistDialog() {
		this.checklistDialogRef.openDialog();
	}

	openStrikesDialog() {
		this.strikesDialogRef.openDialog(this.appointment.appointmentId);
	}

	async openSalesOrderEditDialog() {
		let salesOrder = await this.invoicesService.getInvoice(this.appointment.invoiceId);
		salesOrder = await this.salesOrderEditDialogRef.openDialog(salesOrder);
	}

	async openInvoiceEditDialog() {
		let invoice = await this.invoicesService.getInvoice(this.appointment.invoiceId);
		invoice = await this.invoiceEditDialogRef.openDialog(invoice);
	}

	async deleteAppointment(appointment: IAppointmentModel) {
		const result = await this.deleteApptRef.confirm();

		if (result === SlickConfirmDialogResults.Ok)
			super.deleteAppointment(appointment);
	}

	async printAppointment(appointment: IAppointmentModel) {
		this.onAppointmentPrint.emit(appointment);
	}

	isApptValid() {
		this.isValid = true;
		if (!this.appointment.scheduledDateTime)
			this.isValid = false;

		if (!this.appointment.resourceId)
			this.isValid = false;

		if (!this.appointment.appointmentTypeId)
			this.isValid = false;

		if (!this.appointment.scheduledMinutes)
			this.isValid = false;

		if (!this.appointment.appointmentStatusId)
			this.isValid = false;

		if (!this.startTime)
			this.isValid = false;

		if (this.appointment.travelMinutes === null)
			this.isValid = false;
		return this.isValid;
    }

	openMultiDaySchedule() {
		this.multiDates = [this.appointment.scheduledDateTime];
		this.multiDayAppointmentClick = true;
		if (this.isApptValid()) {
			this.multiDayDialogRef.showDialog();
        }
	}

	saveMultiDay() {
		for (var i = 1; i < this.multiDates.length; i++) {
			const clonedAppointment = UtilsService.clone(this.appointment);
			clonedAppointment.scheduledDateTime = moment(this.multiDates[i]).add(this.appointment.scheduledDateTime.getHours(), 'hours').add(this.appointment.scheduledDateTime.getMinutes(), 'minutes');
			clonedAppointment.scheduledDateTime = new Date(clonedAppointment.scheduledDateTime);
			clonedAppointment.appointmentId = 0;
			clonedAppointment.checklistQuestions.forEach(x => x.appointmentChecklistQuestionId = 0);
			clonedAppointment.uuid = UtilsService.newGuid();
			this.multiAppointments.push(clonedAppointment);
		}
		this.multiAppointments = this.multiAppointments.filter(x => x.scheduledDateTime != this.appointment.scheduledDateTime);

		if (this.multiAppointments.length > 1) {
			this.multiAppointments = this.multiAppointments.sort(
				(apptA, apptB) => apptA.scheduledDateTime.getTime() - apptB.scheduledDateTime.getTime()
			);
		}

		this.onMultiDayAppointmentsAdded.emit(this.multiAppointments);
		
		this.multiDayDialogRef.hideDialog();
    }

	cancelMultiDay() {
		this.multiDayAppointmentClick = false;
		this.multiDates = [];
		this.multiDayDialogRef.hideDialog();
	}

	toggleState(appointment: IAppointmentModel) {
		if (!appointment.uiState || appointment.uiState === 'collapsed')
			appointment.uiState = "expanded"
		else
			appointment.uiState = "collapsed";
			this.onExpandOrCollapse.emit(appointment);
	}
	
}

