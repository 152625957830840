import { Injectable, ElementRef } from "@angular/core";

@Injectable()
export class SlickDialogService {
	static overlayCount: number = 1;
	constructor() { }

    static minimizedDialogs = new Array<ElementRef>();

	public createOverlay(): HTMLDivElement {
		let overlayEl = document.createElement("div");
		overlayEl.classList.add("slick-dialog-overlay");

		document.body.appendChild(overlayEl);

		return overlayEl;
	}

	public showOverlay(overlayEl: HTMLDivElement) {
		overlayEl.classList.add("visible");
		overlayEl.style.zIndex = (SlickDialogService.overlayCount * 100).toString();
		SlickDialogService.overlayCount++;
	}

	public hideOverlay(overlayEl: HTMLDivElement) {
		overlayEl.classList.remove("visible");
		//SlickDialogService.overlayCount--;
	}

    public minimize(el: ElementRef) {
        SlickDialogService.minimizedDialogs.push(el);

        for (let i = 0; i < SlickDialogService.minimizedDialogs.length; i++)
            this.positionInBottomBar(i + 1);
    }

    public maximize(el: ElementRef, top: number, width: number, height: number, xOffset: number, yOffset: number) {

        this.positionAtCenter(el, top, width, height, xOffset, yOffset);
        this.removeDialogFromMinimized(el);

    }

    public removeDialogFromMinimized(el: ElementRef) {
        SlickDialogService.minimizedDialogs = SlickDialogService.minimizedDialogs.filter((x) => {
            return x.nativeElement !== el.nativeElement;
        });

        for (let i = 0; i < SlickDialogService.minimizedDialogs.length; i++)
            this.positionInBottomBar(i + 1);
    }

    public positionAtCenter(el: ElementRef, top: number, width: number, height: number, xOffset: number, yOffset: number) {
        el.nativeElement.style.top = top + "px";
        el.nativeElement.style.width = width + "px";
        el.nativeElement.style.height = height === 0 ? "initial" : height + "px";
        el.nativeElement.style.bottom = "initial";
        el.nativeElement.style.right = "calc(50% - " + ( width / 2 ) + "px)";
        el.nativeElement.style.transform = "translate(" + xOffset + "px, " + yOffset +"px)";
    }

    public positionInBottomBar(position: number) {
        const index = position - 1;
        const slotSize = 280;
        const slotHeight = 44;
        const padding = 10;
        const width = window.innerWidth;
        const availableSlots = parseInt((width / (slotSize + padding)).toString());

        const column = position % availableSlots;
        const row = parseInt((position / availableSlots).toString()) + 1;
        const el = SlickDialogService.minimizedDialogs[index];

        el.nativeElement.style.left = "initial";
        el.nativeElement.style.top = `calc(100% - ${row * (slotHeight + padding)}px)`;
        el.nativeElement.style.bottom = (row * (slotHeight + padding)) + "px";
        el.nativeElement.style.right = "0";
        el.nativeElement.style.transform = `translateX(-${((column - 1) * slotSize) + padding}px)`;
    }
}
