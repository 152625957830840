<footer *ngIf="isLoggedIn === true && isLoginPage === false" class="bg-white footer-bar">
	<div style="min-height: 1px;" class="bg-light"></div>
	<div class="container-fluid text-dark">
		<div style="float: right"><i class="far fa-bug" style="cursor: pointer" title="Bug or Enhancement" (click)="addEnhancement()"></i></div>
		<div *ngIf="latestVersion" style="float: right; cursor: pointer;" class="me-2" (click)="showCurrentVersion()">v.{{latestVersion}}</div>
		&copy;{{currentYear}} Skedit
		<span *ngIf="isStaging" class="bg-danger text-white px-3 ms-3">** STAGING **</span>
		<span *ngIf="isRC" class="bg-danger text-white px-3 ms-3">** RELEASE CANDIDATE **</span>
	</div>
</footer>

<slick-dialog #enhancementDialgRef width="700" header="Requests">


	<div class="mb-1"></div>
	<label>Short Description</label>
	<input type="text" class="form-control" [(ngModel)]="enhancementModel.shortDescription" maxlength="80" />

	<div class="mb-3"></div>
	<label>Details (Optional)</label>
	<textarea class="form-control" [(ngModel)]="enhancementModel.description" rows="6"></textarea>
	<div>
		<em class="ms-1">Please include job/invoice number if applicable.</em>
	</div>

	<div class="mt-3">
		<div class="d-flex mb-2">

		<div class="me-1" style="width:50%">
			<label>Name</label>
			<input type="text" class="form-control" [(ngModel)]="enhancementModel.requestedBy" />
		</div>
		<div class="me-1" style="width:50%">
			<label>Company</label>
			<input type="text" class="form-control" [(ngModel)]="enhancementModel.companyName" />
		</div>
		</div>
		<div class="d-flex">

		<div class="me-1" style="width:50%">
			<label>Email</label>
			<input type="text" class="form-control" [(ngModel)]="enhancementModel.email" />
		</div>
		<div style="width:50%">
			<label>Phone</label>
			<input type="text" class="form-control" [(ngModel)]="enhancementModel.phoneNumber" />
		</div>
		</div>
	</div>
	<div class="mt-2" style="width:300px">
		<label>Priority</label>
		<slick-drop-down [items]="priorities" [(ngModel)]="enhancementModel.priority">

		</slick-drop-down>
	</div>

	<slick-dialog-footer>
		<div style="text-align: center">
			<button type="button" class="btn btn-outline-success me-3" style="width: 160px" [slick-button-spinner]="spinnerStatus" (click)="saveEnhancement()"><i class="bi bi-check-circle"></i> Save</button>
			<button type="button" class="btn btn-outline-danger" style="width: 160px" (click)="cancelEnhancement()"><i class="bi bi-x-circle"></i> Cancel</button>
		</div>
	</slick-dialog-footer>
</slick-dialog>

<version-release-dialog #dialogRef></version-release-dialog>