import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { HttpService } from "@services/utils/http.service";
import { ISystemLogDetailModel, ISystemLogModel, SystemLogModel, SystemLogDetailModel, SystemLogType } from "@models";

@Component({
    selector: "system-logs",
    templateUrl: "./system-logs.component.html"
})

export class SystemLogsComponent implements OnChanges {
    @Input() noteType: SystemLogType;
    @Input() referenceId: number;

    logs: ISystemLogModel[];

    constructor(private readonly httpService: HttpService) { }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.referenceId) {
            await this.reloadLogs();
        }
    }

    async reloadLogs() {
		if (this.referenceId) {
			const params = {
				noteType: this.noteType,
				referenceId: this.referenceId
			}

			this.logs = await this.httpService.get("/systemLogs/getSystemLogs", params);
        }
    }
}