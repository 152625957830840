import { Component, Input, OnChanges, OnDestroy, ElementRef, ViewChild } from "@angular/core";
import { SlickSleepService } from "../utils/slick-sleep.service";

@Component({
	selector: "slick-google-map",
	template:
	`<div #googleMapRef [style.width]='width' [style.height]='height'></div>`
})
export class SlickGoogleMapComponent implements OnChanges, OnDestroy {
	@Input() options: google.maps.MapOptions;
	@Input() overlays: google.maps.Marker[];
	@Input() width: string;
	@Input() height: string;
	@ViewChild("googleMapRef", { static: true }) googleMapRef: ElementRef;

	private map: google.maps.Map;
	private currentMarkers: google.maps.Marker[];

	constructor() {
	}

	ngOnDestroy() {
		this.map = null;
	}

	async ngOnChanges() {
		if (!this.map)
			this.map = new google.maps.Map(this.googleMapRef.nativeElement, this.options);

		await SlickSleepService.sleep();		

		this.map.setOptions(this.options);

		if (this.currentMarkers)
			this.currentMarkers.forEach(currentMarker => currentMarker.setMap(null));

		this.currentMarkers = [];
		if (this.overlays && this.overlays.length > 0) {
			this.overlays.forEach(o => {
				o.setMap(this.map);

				this.currentMarkers.push(o);
			});

		}

		await SlickSleepService.sleep();		
		google.maps.event.trigger(this.map, 'resize')
	}
}
		