export interface IAdvancedHourModel {

    advancedHourId: number;
    companyId: number;
    activeDate: Date;
    inactiveDate?: Date;
    mondayOfficeOpenHours: string;
    mondayOfficeCloseHours: string;
    mondayServiceOpenHours: string;
    mondayServiceCloseHours: string;
    mondayIsOfficeClosed: boolean;
    mondayIsServiceClosed: boolean;
    tuesdayOfficeOpenHours: string;
    tuesdayOfficeCloseHours: string;
    tuesdayServiceOpenHours: string;
    tuesdayServiceCloseHours: string;
    tuesdayIsOfficeClosed: boolean;
    tuesdayIsServiceClosed: boolean;
    wednesdayOfficeOpenHours: string;
    wednesdayOfficeCloseHours: string;
    wednesdayServiceOpenHours: string;
    wednesdayServiceCloseHours: string;
    wednesdayIsOfficeClosed: boolean;
    wednesdayIsServiceClosed: boolean;
    thursdayOfficeOpenHours: string;
    thursdayOfficeCloseHours: string;
    thursdayServiceOpenHours: string;
    thursdayServiceCloseHours: string;
    thursdayIsOfficeClosed: boolean;
    thursdayIsServiceClosed: boolean;
    fridayOfficeOpenHours: string;
    fridayOfficeCloseHours: string;
    fridayServiceOpenHours: string;
    fridayServiceCloseHours: string;
    fridayIsOfficeClosed: boolean;
    fridayIsServiceClosed: boolean;
    saturdayOfficeOpenHours: string;
    saturdayOfficeCloseHours: string;
    saturdayServiceOpenHours: string;
    saturdayServiceCloseHours: string;
    saturdayIsOfficeClosed: boolean;
    saturdayIsServiceClosed: boolean;
    sundayOfficeOpenHours: string;
    sundayOfficeCloseHours: string;
    sundayServiceOpenHours: string;
    sundayServiceCloseHours: string;
    sundayIsOfficeClosed: boolean;
    sundayIsServiceClosed: boolean;
    expanded: boolean;
}

export class AdvancedHourModel implements IAdvancedHourModel {
    constructor() {
    }

    advancedHourId: number;
    companyId: number;
    activeDate: Date = new Date();
    inactiveDate?: Date;
    mondayOfficeOpenHours: string = "8:00 am";
    mondayOfficeCloseHours: string = "5:00 pm";
    mondayServiceOpenHours: string = "8:00 am";
    mondayServiceCloseHours: string = "5:00 pm";
    mondayIsOfficeClosed: boolean = false;
    mondayIsServiceClosed: boolean = false;
    tuesdayOfficeOpenHours: string = "8:00 am";
    tuesdayOfficeCloseHours: string = "5:00 pm";
    tuesdayServiceOpenHours: string = "8:00 am";
    tuesdayServiceCloseHours: string = "5:00 pm";
    tuesdayIsOfficeClosed: boolean = false;
    tuesdayIsServiceClosed: boolean = false;
    wednesdayOfficeOpenHours: string = "8:00 am";
    wednesdayOfficeCloseHours: string = "5:00 pm";
    wednesdayServiceOpenHours: string = "8:00 am";
    wednesdayServiceCloseHours: string = "5:00 pm";
    wednesdayIsOfficeClosed: boolean = false;
    wednesdayIsServiceClosed: boolean = false; 
    thursdayOfficeOpenHours: string = "8:00 am";
    thursdayOfficeCloseHours: string = "5:00 pm";
    thursdayServiceOpenHours: string = "8:00 am";
    thursdayServiceCloseHours: string = "5:00 pm";
    thursdayIsOfficeClosed: boolean = false;
    thursdayIsServiceClosed: boolean = false;
    fridayOfficeOpenHours: string = "8:00 am";
    fridayOfficeCloseHours: string = "5:00 pm";
    fridayServiceOpenHours: string = "8:00 am";
    fridayServiceCloseHours: string = "5:00 pm";
    fridayIsOfficeClosed: boolean = false;
    fridayIsServiceClosed: boolean = false;
    saturdayOfficeOpenHours: string = "8:00 am";
    saturdayOfficeCloseHours: string = "5:00 pm";
    saturdayServiceOpenHours: string = "8:00 am";
    saturdayServiceCloseHours: string = "5:00 pm";
    saturdayIsOfficeClosed: boolean = true;
    saturdayIsServiceClosed: boolean = true;
    sundayOfficeOpenHours: string = "8:00 am";
    sundayOfficeCloseHours: string = "5:00 pm";
    sundayServiceOpenHours: string = "8:00 am";
    sundayServiceCloseHours: string = "5:00 pm";
    sundayIsOfficeClosed: boolean = true;
    sundayIsServiceClosed: boolean = true;
    expanded: boolean = true;
}