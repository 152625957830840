import { NgModule } from '@angular/core';
import { SQLiteService } from './sqlite.service';
import { DevTraceService } from '@services/utils/dev-trace.service';
import { OfflineRequestQueueStorageService } from "./offline-request-queue/offline-request-queue.storage";
import { GlobalSettingsStorageService } from './globals/globalSettings.storage';
// Resources
import { ResourceStorageService } from './resources/resources.storage';
import { DailyCrewStorageService } from './resources/daily-crew.storage';
// ScheduledAppointments
import { ScheduledAppointmentsStorageService } from './scheduled-appointments/scheduled-appointments.storage';
import { BlockedTimeSlotsStorageService } from './scheduled-appointments/blocked-time-slots.storage';
// Appointments
import { AppointmentStorageService } from './appointments/appointments.storage';

@NgModule({
	providers: [
		SQLiteService,
		DevTraceService,
		OfflineRequestQueueStorageService,
		GlobalSettingsStorageService,
		DailyCrewStorageService,
		ResourceStorageService,
		ScheduledAppointmentsStorageService,
		BlockedTimeSlotsStorageService,
		AppointmentStorageService
	]
})
export class SqliteModule { }

