import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { SlickContainerComponent } from "./slick-container.component";

import { SlickContainerHeaderComponent } from "./slick-container-header.component";
import { SlickContainerBodyComponent } from "./slick-container-body.component";
import { SlickContainerFooterComponent } from "./slick-container-footer.component";

@NgModule({
	imports: [CommonModule],
	declarations: [SlickContainerComponent, SlickContainerHeaderComponent, SlickContainerBodyComponent, SlickContainerFooterComponent],
	exports: [CommonModule, SlickContainerComponent, SlickContainerHeaderComponent, SlickContainerBodyComponent, SlickContainerFooterComponent]
})
export class SlickContainerModule { }

export { SlickContainerComponent }
export { SlickContainerHeaderComponent }
export { SlickContainerBodyComponent }
export { SlickContainerFooterComponent }
