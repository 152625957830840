export interface IHelpPageModel {
	helpPageId: number; 
	name: string;
	code: string;
	html: string;
}

export class HelpPageModel implements IHelpPageModel {
	constructor() {
		this.helpPageId = 0;
	}

	helpPageId: number;
	name: string;
	code: string;
	html: string;
}