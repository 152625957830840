<slick-dialog #dialogRef header="Payment Edit" width="1000" top="15">
	<payment-edit #paymentEditComponentRef [(paymentModel)]="paymentModel" isDialog="true"></payment-edit>

	<slick-dialog-footer>
        <div class="d-flex pt-2 ps-3">
            <div>
                <button type="button" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-success me-2" style="width: 140px;" (click)="savePayment()"><i class="far fa-check"></i> Save</button>
                <button type="button" class="btn btn-outline-danger" style="width: 140px;" (click)="cancelPayment()"><i class="far fa-times"></i> Cancel</button>
            </div>

            <div class="flex-fill me-3" style="text-align: right">
                <div class="btn-group me-1" role="group">
                    <!-- hide the void and refund buttons if the company is using bambora -->
                    <button type="button" [disabled]="paymentModel?.paymentId === 0" class="btn btn-outline-primary" title="Print Payment" (click)="generatePaymentPdf()"><i class="far fa-print"></i></button>
                    <button type="button" [disabled]="paymentModel?.paymentId === 0" class="btn btn-outline-primary" title="Email Payment" (click)="emailPayment()"><i class="far fa-envelope"></i></button>
                    <button type="button" class="btn btn-outline-primary" title="Charge Card" (click)="openCreditCardDialog()"><i class="far fa-credit-card"></i></button>
                    <button *ngIf="!isBambora && canRefundPayments && paymentModel?.canRefund" [disabled]="paymentModel?.paymentId === 0" type="button" class="btn btn-outline-primary" title="Refund Payment" (click)="openRefundPaymentDialog()">R</button>
                    <button *ngIf="!isBambora && canVoidPayments && paymentModel?.canVoid" [disabled]="paymentModel?.paymentId === 0" type="button" class="btn btn-outline-primary" title="Void Payment" (click)="voidPayment()">V</button>

                </div>
            </div>

        </div>
	</slick-dialog-footer>

</slick-dialog>
