<div class="slick-file-list-item" id="slick-file-list-item_{{uuid}}">
	<div class="slick-file-list-item_img gray-block card" (click)="onClick()" [ngStyle]="{ 'cursor': (showPhotoGallery === true) ? 'pointer' : 'default' }" [style.minHeight.px]="itemSize + 2" [style.minWidth.px]="itemSize">
		<img *ngIf="file.thumbnailBase64Image" [src]="file.thumbnailBase64Image" [style.maxWidth.px]="itemImgSize" [style.maxHeight.px]="itemImgSize" />
		<img *ngIf="!file.thumbnailBase64Image && file.base64Image" [src]="file.base64Image" [style.maxWidth.px]="itemImgSize" [style.maxHeight.px]="itemImgSize" />
		<img *ngIf="!file.thumbnailBase64Image && !file.base64Image && file.thumbnailUrl" [src]="file.thumbnailUrl" [style.maxWidth.px]="itemImgSize" [style.maxHeight.px]="itemImgSize" />
		<img *ngIf="!file.thumbnailBase64Image && !file.base64Image && !file.thumbnailUrl && file.url" [src]="file.url" [style.maxWidth.px]="itemImgSize" [style.maxHeight.px]="itemImgSize" />
		<i *ngIf="allowDelete" class="fas fa-circle-x bg-white text-danger" (click)="deleteFile($event)"></i>
		<div class="file-info">
			<div class="slick-file-list-item_title" [style.minWidth.px]="itemSize" [style.maxWidth.px]="itemSize">
			  <div class="slick-file-list-item_display-title" #titleDivRef style="cursor: pointer" *ngIf="!isEditing" (click)="editTitle()">
				{{ (file.title ?? '').length > 18 ? (file.title ?? '').slice(0, 18) + '...' : file.title }}
			  </div>
			  <div #editTitleDivRef class="slick-file-list-item_edit-title" *ngIf="isEditing">
				<input #editTitleInputRef type="text" class="form-control" [(ngModel)]="tempTitle" (keydown)="onKeyDown($event)" [style.width.px]="itemSize" />
			  </div>
			</div>
			<div class="slick-file-list-item_title" *ngIf="fileDateFormatted" [style.minWidth.px]="itemSize" [style.maxWidth.px]="itemSize">
			  <div class="slick-file-list-item_date">
				<div *ngIf="fileDateFormatted" [style.minWidth.px]="itemSize">{{ fileDateFormatted }}</div>
				<div *ngIf="fileTimeFormatted" [style.minWidth.px]="itemSize">{{ fileTimeFormatted }}</div>
			  </div>
			</div>
		</div>
	</div>

	<!-- We want the user to be able to click just a little outside of the checkbox and still have it check -->
	<div *ngIf="showCheckbox === true" class="slick-file-checkbox" (click)="onCheckboxClicked($event)">
		<input type="checkbox" [ngModel]="file.isChecked" />
	</div>
</div>

<slick-confirm-dialog #deleteConfirmRef okButtonText='Delete'>
	<h4 class="p-0 m-0">Are you sure you want to delete?</h4>
	<h5 class="p-0 m-0">"{{file.title}}"</h5>
</slick-confirm-dialog>