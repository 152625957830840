<slick-dialog #dialogRef header="Estimate Edit" width="1000" top="15">
	<div class="mt-2 ps-1">

		<estimate-edit #estimateEditComponentRef [(estimateModel)]="estimateModel" (onSave)="onSave($event)" isDialog="true"></estimate-edit>
	</div>
		<slick-dialog-footer>
			<div class="d-flex pt-2 ps-3">
				<div>
					<button type="button" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-success me-2" style="width: 140px;" (click)="saveEstimate()"><i class="far fa-check"></i> Save</button>
					<button type="button" class="btn btn-outline-danger" style="width: 140px;" (click)="cancelEstimate()"><i class="far fa-times"></i> Cancel</button>
				</div>

				<div class="flex-fill me-3" style="text-align: right">
					<div class="btn-group" role="group">
						<button type="button" class="btn btn-outline-primary" title="Convert to Sales Order" (click)="convertToSalesOrder()"><i class="far fa-dollar-sign"></i></button>
						<button type="button" class="btn btn-outline-primary" title="Convert to Invoice" (click)="convertToInvoice()"><i class="far fa-file-invoice-dollar"></i></button>
						<button type="button" class="btn btn-outline-primary" title="Print Estimate" (click)="generateEstimatePdf()"><i class="far fa-print"></i></button>
						<button type="button" class="btn btn-outline-primary" title="Print Work Order" (click)="generateWorkOrderPdf()">
							<div style="position: absolute; font-size: 6px; border-radius: 6px; right: 6px; top: 18px; line-height: 12px; min-width: 12px; padding: 0;" class="bg-dark text-white">WO</div>
							<i class="far fa-print"></i>
						</button>
						<button type="button" class="btn btn-outline-primary" title="Email Estimate" (click)="emailEstimate()"><i class="far fa-envelope"></i></button>
						<button type="button" class="btn btn-outline-primary" title="Text Estimate" (click)="textEstimate()"><i class="far fa-comment-alt-lines"></i></button>
						<button type="button" class="btn btn-outline-primary" title="Pre-Payment" (click)="prePayment()"><i class="far fa-credit-card"></i></button>
					</div>
				</div>

			</div>
		</slick-dialog-footer>
</slick-dialog>
