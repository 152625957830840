<div *ngIf="customerModel" class="mt-2 mb-2">
	<div class="form-group">
	<div>
		<div class="form-group mt-2 ms-3 d-inline-flex pb-2">
			<div style="padding-right: 30px;">
				<div class="pe-1 pt-1">
					Last Outreach
				</div>
				<div class="me-4">
					<input style="max-width: 7em;" [disabled]=true class="form-control" [ngModel]="customerModel.lastOutreachDate | date:'M/d/yyyy'" 
					(ngModelChange)="lastOutreachDate=$event" />	

				</div>
			</div>
			<div style="padding-right: 30px;">
				<div class="pe-1 pt-1">
					Next Outreach
				</div>
				<div class="me-4">
					<input  [ngStyle]="{'pointer-events': !customerModel.lastOutreachDate ? 'none' : 'auto'}" 
							type="text" 
							style="max-width: 7em;" 
							class="form-control" 
							(onDateChange)="changeOutreachDays()" 
							[(ngModel)]="customerModel.nextOutreachDate" slick-date-picker />
							<div style="position: relative; margin-right: -200px;">
								<form-error *ngIf="!dateAllowed" style="position: absolute;">
									Next outreach cannot be in the past
								</form-error>
							</div>
				</div>
			</div>
			<div>
				<div class="pe-1 pt-1">
					Outreach Frequency (Days) 	
				</div>
				<div class="me-1">
					<!-- <input type="number" style="max-width: 3.3em;"  class="text-box-sm form-control" (keyup)="changeNextOutreachDate()" [(ngModel)]="daysBetweenOutreach" /> -->
					<input type="number" style="width: 7em;"  class="text-box-sm form-control" [(ngModel)]="customerModel.daysBetweenOutreach" (ngModelChange)="changeNextOutreachDate()"/>
				</div>
			</div>
		</div>

		<div class="mt-4 ms-2">
			<button (click)="addOutreach('Phone')" class="btn btn-outline-primary btn-rounded"><i class="bi bi-telephone-fill"></i> Phone</button>
			<button (click)="addOutreach('Email')" class="btn btn-outline-primary btn-rounded"><i class="bi bi-envelope-fill"></i> Email</button>
			<button (click)="addOutreach('Text')" class="btn btn-outline-primary btn-rounded"><i class="bi bi-chat-dots-fill"></i> Text</button>
			<button (click)="addOutreach('Meeting')" class="btn btn-outline-primary btn-rounded"><i class="bi bi-people-fill"></i> Meeting</button>
		</div>

		<div class="form-group mt-3">
			<div>
				<div class="col-12">
					<div class="col-12 px-3">
						<div>
							<table style="width:77%" class="table table-bordered">
								<colgroup><col style="width: 180px;" /></colgroup>
								<colgroup><col /></colgroup>
								<colgroup><col style="width: 60px;" /></colgroup>
								<thead class="table-light">
									<tr style="margin-left: 30px;">
										<th colspan="3">Customer Outreach</th>
									</tr>
								</thead>
								<tbody class="custom-table">
									<tr *ngFor="let outreach of customerModel.customerOutreaches; let i = index;">
										<td style="font-size: 15px; border-right: none;">
											{{outreach.userFullName}}<br />
											<span *ngIf="outreach.outreachType === 'Phone'"><i class="bi bi-telephone-fill"></i></span>
											<span *ngIf="outreach.outreachType === 'Email'"><i class="bi bi-envelope-fill"></i></span>
											<span *ngIf="outreach.outreachType === 'Text'"><i class="bi bi-chat-dots-fill"></i></span>
											<span *ngIf="outreach.outreachType === 'Lunch'"><i class="far fa-utensils"></i></span>
											<span *ngIf="outreach.outreachType === 'Meeting'"><i class="bi bi-people-fill"></i></span>
											&nbsp;{{outreach.outreachType}}
											<br />{{outreach.outreachDate | date:'M/d/yy - hh:mm a'}}
										</td>
										<td class="align-middle" style="border-right: none; border-left: none;"><textarea class="form-control" [(ngModel)]="outreach.notes"></textarea></td>
										<td class="align-middle" style="border-left: none;"><button class="btn red" (click)="removeOutreach(i, outreach.customerOutreachId)"><i class="bi bi-trash"></i></button></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>	
</div>

 <slick-confirm-dialog #removeCustomerOutReach>
	Are you sure you want to delete this Outreach?
</slick-confirm-dialog> 