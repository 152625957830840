import { ISlickEmailerModel, SlickEmailerModel } from "@slick-components";
import { IEmailModel } from "../email/email.model";

export interface IEmailerModel extends ISlickEmailerModel {
    referenceUuid: string;
    isSent: boolean;
    locationId?: number;
    lathamOrderId?: number;
    invoiceId?: number;
    jobId?: number;
    customerId?: number;
    purchaseOrderId?: number;
    paymentId?: number;
}
export class EmailerModel extends SlickEmailerModel implements IEmailerModel {
    referenceUuid: string;
    isSent: boolean;
    locationId?: number;
    lathamOrderId?: number;
    invoiceId?: number;
    jobId?: number;
    customerId?: number;
    purchaseOrderId?: number;
    paymentId?: number;
}