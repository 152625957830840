import { CustomersAuthService } from '@services/utils/auth-services/customers-auth.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ChangeDetectorRef, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { LookupService, SleepService, CustomersService, GlobalsService, HttpService, UtilsService } from '@services';
import { DiscountModel, DropdownModel, IAddressModel, ICustomCustomerFieldModel, ICustomerListModel, ICustomerModel, IDiscountModel, IDropdownModel, IRebateModel, RebateModel } from '@models';
import { CustomerQuickbooksLinkDialogComponent } from "@app/customers/customer-components/customer-quickbooks-link-dialog/customer-quickbooks-link-dialog.component";
import * as moment from 'moment';
import { SlickToastService} from "@slick-components";
import { ConflictingCustomerDialogComponent } from '@shared-components/conflicting-customers-dialog/conflicting-customers-dialog.component';
import Swal from 'sweetalert2';
import { CustomCustomerFieldAnswerModel, ICustomCustomerFieldAnswerModel } from '@models/customers/custom-customer-field-answer.model';


class CustomCustomerFieldWithAnswer {
    customCustomerFieldModel: ICustomCustomerFieldModel;
    answer: string;
}

@Component({
	selector: 'customer-edit-layout',
	templateUrl: 'customer-edit-layout.component.html',
	styleUrls: ['customer-edit-layout.component.scss'],
	providers: [CustomersAuthService, CustomersService]
})
export class CustomerEditLayoutComponent implements OnInit, OnChanges {
	@Input() customerModel: ICustomerModel;
	@ViewChild("customerQuickbooksLinkDialogRef") customerQuickbooksLinkDialogRef: CustomerQuickbooksLinkDialogComponent;
	@ViewChild("conflictingCustomerDialogRef") conflictingCustomerDialogRef: ConflictingCustomerDialogComponent;
	@Output() existingCustomerSelected: EventEmitter<number> = new EventEmitter<number>();
	@Output() customerModelChange: EventEmitter<ICustomerModel> = new EventEmitter();

	dealerDropdown: IDropdownModel[];
	customerTypes: IDropdownModel[];
	priceLevels: IDropdownModel[];
	paymentTerms: IDropdownModel[];
	customerAreas: IDropdownModel[];
	taxRegions: IDropdownModel[];
	qbLocations: IDropdownModel[];
	showCustomerAreas: boolean = true;
	showTaxRegions: boolean = true;
	addressModel: IAddressModel;
	billingAddressModel: IAddressModel;
	shippingAddressModel: IAddressModel;
	isSubmitted: boolean = false;
	inviteSpinnerStatus: string;
	serviceAgreementSpinnerStatus: string;
	addChildSpinnerStatus: string;
	apEmailIsValid: boolean = true;
	emailIsValid: boolean = true;
	emailSent: boolean = false;
	showPaymentTerms: boolean = true;
	//showPaymentTerms: boolean = GlobalsService.company.showPaymentTypeInCustomerInfo;
	showTaxId: boolean = GlobalsService.company.noTaxIdNotification;
	showAttentionNote: boolean = GlobalsService.company.customerAttentionNoteNotification;
	test: boolean = GlobalsService.company.customerAdditionalAddresses;
	isQBOnline: boolean = GlobalsService.company.quickbooksOnlineEnabled;
	isQBDesktop: boolean = GlobalsService.company.quickbooksWebConnectorEnabled;
	isQuickbooksEnabled: boolean = GlobalsService.isQuickbooksEnabled;
	showQuickbooksName: boolean = GlobalsService.isQuickbooksEnabled;
	editQuickbooksName: boolean = false;
	isBusinessCentralEnabled: boolean = GlobalsService.isBusinessCentralEnabled;
	isMizu: boolean = (GlobalsService.company.companyId === 1)
	isCanada: boolean = (GlobalsService.company.companyId === 3)
	isJellyfish: boolean = (GlobalsService.company.companyId === 133)
	isGlobalAdmin: boolean = GlobalsService.userInfo.isGlobalAdmin;
	skeditPay: boolean = GlobalsService.company.useSkeditPay;
	useCereTax: boolean = GlobalsService.company.useCereTax;
	taxType: string;

	customCustomerFields: ICustomCustomerFieldModel[];
    customCustomerFieldWithAnswers: CustomCustomerFieldWithAnswer[];
	adjustedDiscounts = new Array<IDropdownModel[]>();
    adjustedRebates = new Array<IDropdownModel[]>();
	

	newCompanyAdminLogin: string;
	newCompanyAdminPassword: string;
	salesReps: IDropdownModel[] = [];
	promotionsExpanded: boolean = false
	arReps: IDropdownModel[] = [];
	billingCustomerSearchText: string;
	billingCustomerResults: ICustomerListModel[];


	countries: any = [
		{ id: 'US', text: 'US' },
		{ id: 'Canada', text: 'Canada' },
		{ id: 'Mexico', text: 'Mexico' },
		{ id: 'Israel', text: 'Israel' }
	];
	phoneTypes: any = [
		{ id: 'home', text: 'Home' },
		{ id: 'mobile', text: 'Mobile' },
		{ id: 'work', text: 'Work' }
	];
	taxTypes: IDropdownModel[] = [
		new DropdownModel('Taxable', 'Taxable'),
		new DropdownModel('Tax Exempt', 'Tax Exempt')
	]
	constructor(
		private lookupService: LookupService,
		private httpService: HttpService,
		public customersAuthService: CustomersAuthService,
		private customersService: CustomersService,
		private changeDetector: ChangeDetectorRef,
		private slickToastService: SlickToastService) {
		this.customerTypes = this.lookupService.getCustomerTypesForDropdown();
		this.priceLevels = this.lookupService.getPriceLevelsForDropdown();
		this.paymentTerms = this.lookupService.getPaymentTermsForDropdown();
		this.customerAreas = this.lookupService.getCustomerAreasForDropdown();
		this.taxRegions = this.lookupService.getTaxRegionsForDropdown();
		this.customCustomerFields = this.lookupService.getCustomCustomerFields();
	}

	async ngOnInit() {
		if (this.customerAreas.length == 0) {
			this.showCustomerAreas = false;
		}

		if (this.taxRegions.length == 0) {
			this.showTaxRegions = false;
		}
		//for jellyfish only
		if (this.isJellyfish) {
			const temp: any[] = await this.httpService.get('/customers/getDealersForDropdown');
			this.dealerDropdown = temp.map(x => new DropdownModel(parseInt(x.id), x.text));
		}

		// For Canada only, get the locations
		if (this.isCanada) 
			this.qbLocations = await this.httpService.get('/quickbooks/getAllLocationsForDropdown');
	}

	async ngOnChanges(changes: SimpleChanges) {
		this.isSubmitted = false;
		this.emailSent = false;
		this.editQuickbooksName = false;
		this.inviteSpinnerStatus = "reset";
		this.serviceAgreementSpinnerStatus = "reset";
		this.addChildSpinnerStatus = "reset";
		this.promotionsExpanded = false
		this.billingCustomerSearchText = null;

		// Get the answers from the database since we don't populate the customer model with answers
        const customerCustomAnswers = (<ICustomCustomerFieldAnswerModel[]>await this.httpService.get(`/customCustomerFields/getCustomCustomerFieldAnswers?customerId=${this.customerModel.customerId}`));


		if (this.customerModel && this.customerModel.customerId === 0) {
			this.customerModel.linkBillingAddress = true;
			this.customerModel.linkShippingAddress = true;
			this.customerModel.customerTypeId = this.lookupService.getCustomerTypes().find(x => x.name === 'Home Owner').customerTypeId;
			this.customerModel.priceLevelId = this.lookupService.getPriceLevels().find(x => x.name === 'Default').priceLevelId;
			this.customerModel.paymentTermId = this.lookupService.getPaymentTerms().find(x => x.name === 'COD').paymentTermId;
		}

		if (this.customerModel) {
			this.reInitDiscountsAndRebates();
			this.salesReps = this.lookupService.getUsers(this.customerModel.salesRepId)
				.filter(x => x.isSalesRep === true)
				.map(x => new DropdownModel(x.userId, x.fullName));

			this.arReps = this.lookupService.getUsers(this.customerModel.arRepId)
				.filter(x => x.isAR === true)
				.map(x => new DropdownModel(x.userId, x.fullName));

			this.billingCustomerSearchText = this.customerModel.billingCustomer?.displayName;
			
			this.taxType = ((this.customerModel.taxExempt ?? false) === true) ? 'Tax Exempt' : 'Taxable';
		}

		// Now go through each field and add the answer
        this.customCustomerFieldWithAnswers = this.customCustomerFields.map(f => {
            const customCustomerFieldWithAnswer = new CustomCustomerFieldWithAnswer();
            customCustomerFieldWithAnswer.customCustomerFieldModel = f;
            const customerCustomAnswer = customerCustomAnswers.find(a => f.customCustomerFieldId === a.customCustomerFieldId);
            if (customerCustomAnswer)
                customCustomerFieldWithAnswer.answer = customerCustomAnswer.answer;

            return customCustomerFieldWithAnswer;
        });
	}

	onTaxTypeSelected(taxType: IDropdownModel) {
		this.customerModel.taxExempt = (taxType.id === 'Tax Exempt');
	}

	async billingCustomerSearch(billingCustomerSearchText) {
		this.billingCustomerResults = await this.customersService.getCustomersForList(billingCustomerSearchText, false, false, false);
	}

	async onBillingCustomerSelect(customer: ICustomerListModel) {
		if (!customer) {
			this.customerModel.billingCustomer = null;
			this.customerModel.billingCustomerId = null;
			this.billingCustomerSearchText = null;
		}
		else {
			this.customerModel.billingCustomer = await this.customersService.getCustomer(customer.customerId);
			this.customerModel.billingCustomerId = customer.customerId;
			this.billingCustomerSearchText = this.customerModel.billingCustomer?.displayName;
		}
	}

	async updateAnswers() {
        await SleepService.sleep();

        this.customerModel.customCustomerFieldAnswers = this.customCustomerFieldWithAnswers.map(x => {
            const customCustomerFieldAnswerModel = new CustomCustomerFieldAnswerModel();

            customCustomerFieldAnswerModel.customCustomerFieldId = x.customCustomerFieldModel.customCustomerFieldId;
            customCustomerFieldAnswerModel.answer = x.answer;

            return customCustomerFieldAnswerModel;
        });

        this.customerModelChange.emit(this.customerModel);
	}

	onSalesRepSelect(salesRep: IDropdownModel) {
		this.customerModel.salesRepFullName = salesRep?.text;
	}

	onARRepSelect(arRep: IDropdownModel) {
		this.customerModel.arRepFullName = arRep?.text;
	}

	async sendServiceAgreement() {
		if (this.customerModel.customerId === 0) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: `Please save this customer before sending service agreement.`,
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			this.serviceAgreementSpinnerStatus = "error";
			return;
		}

		if (!this.customerModel.email) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please enter an email address",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			this.serviceAgreementSpinnerStatus = "error";
			return;
		}

		this.serviceAgreementSpinnerStatus = "spin";
		await SleepService.sleep(500);
		try {
			await this.customersService.sendServiceAgreement(this.customerModel.customerId);
			this.serviceAgreementSpinnerStatus = "ok";
			this.slickToastService.showSuccess("Service Agreement sent")
		}
		catch {
			this.serviceAgreementSpinnerStatus = "error";
		}
	}

	async checkForEmail() {
		this.emailIsValid = true
		if (this.customerModel.customerId === 0) {
			this.emailIsValid = false
		}

		if (!this.customerModel.email) {
			this.emailIsValid = false
		}

		if (this.emailIsValid === true) {
			this.serviceAgreementSpinnerStatus = "reset";
			return
		}
	}

	async quickbooksSync() {
		if (this.customerModel.companyName)
			this.customerModel.quickbooksName = this.customerModel.companyName;
		else {
			if (GlobalsService.company.quickbooksCustomerLastCommaFirst)
				this.customerModel.quickbooksName = this.customerModel.lastName + ', ' + this.customerModel.firstName;
			else
				this.customerModel.quickbooksName = this.customerModel.firstName + ' ' + this.customerModel.lastName
		}

		if (this.customerModel.syncToQuickbooks) {
			this.customerModel.quickbooksId = null;
			this.customerModel.syncToQuickbooks = false;
			this.customerModel.quickbooksLinkOnly = false;

		}
		else {
			this.customerModel.quickbooksId = this.customerModel.quickbooksId;
			this.customerModel.syncToQuickbooks = true;
			this.customerModel.quickbooksLinkOnly = this.customerModel.quickbooksLinkOnly;
			// 1/10/24 PS - we want to do this for everyone now
			//if (GlobalsService.company.companyId === 3 || GlobalsService.company.companyId === 41) {
			if (this.isQBOnline) {
				if (this.customerModel.customerId === 0) {
					await Swal.fire({
						icon: 'warning',
						title: 'Oops...',
						text: "Please save the customer before syncing",
						confirmButtonColor: '#007bff',
						heightAuto: false
					});
					return;
				}

				const updatedCustomer = await this.customerQuickbooksLinkDialogRef.openDialog(this.customerModel);

				if (updatedCustomer) {
					this.customerModel.quickbooksName = updatedCustomer.quickbooksName;
					this.customerModel.quickbooksId = updatedCustomer.quickbooksId;
					this.customerModel.syncToQuickbooks = updatedCustomer.syncToQuickbooks;
					this.customerModel.quickbooksLinkOnly = updatedCustomer.quickbooksLinkOnly;
				}
					//}
			}
		
        }
    }

	async businessCentralSync() {
		if (this.customerModel.syncToBusinessCentral) {
			this.customerModel.businessCentralId = null;
			this.customerModel.syncToBusinessCentral = false;
		}
		else {
			this.customerModel.businessCentralId = this.customerModel.businessCentralId;
			this.customerModel.syncToBusinessCentral = true;
		}
	}
	setSubmitted() {
		this.isSubmitted = true;
	}

	isValid(): boolean {
		let isValid = true;

		if (!this.customerModel.customerTypeId)
			isValid = false;

		if (!this.customerModel.priceLevelId)
			isValid = false;

		if (!this.customerModel.firstName && !this.customerModel.lastName && !this.customerModel.companyName)
			isValid = false;

		this.emailIsValid = true;
		const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,}$/;
		if (this.customerModel.email) {
			this.customerModel.email.split(";").forEach(x => {
				let trimmedEmail = x.trim();
				if (regex.test(trimmedEmail) === false && trimmedEmail.length !== 0) {
					this.emailIsValid = false;
					isValid = false;
				}
			});
		}

		this.apEmailIsValid = true;
		if (this.customerModel.apEmail) {
			this.customerModel.apEmail.split(";").forEach(x => {
				let trimmedEmail = x.trim();
				if (regex.test(trimmedEmail) === false && trimmedEmail.length !== 0) {
					this.apEmailIsValid = false;
					isValid = false;
				}
			});
		}
		
		return isValid;
	}

	async onGoogleMapAddressSelect(placeResult: google.maps.places.PlaceResult) {
		this.customerModel = this.customersService.setAddress(this.customerModel, placeResult);
		this.checkAddress();
	}

	onGoogleMapBillingAddressSelect(placeResult: google.maps.places.PlaceResult) {
		this.customerModel = this.customersService.setAddress(this.customerModel, placeResult, "billing");
		this.changeDetector.detectChanges();
	}

	onGoogleMapShippingAddressSelect(placeResult: google.maps.places.PlaceResult) {
		this.customerModel = this.customersService.setAddress(this.customerModel, placeResult, "shipping");
		this.changeDetector.detectChanges();
	}

	onCustomerTypeSelected(customerType: IDropdownModel) {
		if (customerType.text === "Builder") {
			this.customerModel.outreach = true;
			this.customerModel.daysBetweenOutreach = 30;
			this.customerModel.nextOutreachDate = moment().startOf("day").add(30, "days").toDate();
		}
	}

	async checkAddress() {
		if (this.customerModel.customerId === 0 || this.customerModel.customerId === null) {
			const duplicateAddresses = await this.customersService.checkDuplicateAddress(this.customerModel.address1);
			const relevantDuplicateAddesses = duplicateAddresses.filter(x => x.customerId !== this.customerModel.customerId);
			if (relevantDuplicateAddesses.length > 0) {
				const customerId = await this.conflictingCustomerDialogRef.showDialog(relevantDuplicateAddesses);
				if (customerId === 0)
					return
				if (customerId > 0) {
					this.existingCustomerSelected.emit(customerId);
				}
				else {
					this.customerModel = null;
				}

			}
		}
	}

	//promotions section
	async reInitDiscountsAndRebates() {
        const discounts = await this.lookupService.getDiscountsForDropdown();
        const rebates = await this.lookupService.getRebatesForDropdown();

        this.adjustedDiscounts = this.getAdjustedDiscounts(discounts, this.customerModel.discounts);
        this.adjustedRebates = this.getAdjustedRebates(rebates, this.customerModel.rebates);
    }

	removeDiscount(i) {
        this.customerModel.discounts.splice(i, 1);
        this.recalculateAvailableDiscounts();
    }

    getAdjustedDiscounts(discounts: IDropdownModel[], customerDiscounts: IDiscountModel[]): Array<IDropdownModel[]> {
        const res = new Array<IDropdownModel[]>();
        const usedDiscounts = this.customerModel.discounts.map(d => { return d.discountId; });

        if (!discounts) return [];

        for (let discount of customerDiscounts) {
            res.push(this.unUsedDiscount(discount.discountId, usedDiscounts, discounts));
        }

        return res;
    }

    getAdjustedRebates(rebates: IDropdownModel[], customerRebates: IRebateModel[]): Array<IDropdownModel[]> {
        const res = new Array<IDropdownModel[]>();
        const usedRebates = this.customerModel.rebates.map(r => { return r.rebateId; });

        if (!rebates) return [];

        for (let rebate of customerRebates) {
            res.push(this.unUsedRebates(rebate.rebateId, usedRebates, rebates));
        }

        return res;
    }

    unUsedDiscount(exceptId: number, usedDiscounts: number[], discounts: IDropdownModel[]): IDropdownModel[] {
        if (!discounts)
            return [];

        return UtilsService.clone(discounts.filter(f => {
            return !usedDiscounts.includes(f.id) || f.id === exceptId;
        }));
    }

    unUsedRebates(exceptId: number, usedRebates: number[], rebates: IDropdownModel[]): IDropdownModel[] {
        if (!rebates)
            return [];

        return UtilsService.clone(rebates.filter(f => {
            return !usedRebates.includes(f.id) || f.id === exceptId;
        }));
    }
	async addDiscount() {
        if (!this.customerModel.discounts)
            this.customerModel.discounts = new Array<DiscountModel>();

        this.customerModel.discounts.push(new DiscountModel());

        this.recalculateAvailableDiscounts();
    }

    async recalculateAvailableDiscounts() {
        const discounts = await this.lookupService.getDiscountsForDropdown();
        this.adjustedDiscounts = this.getAdjustedDiscounts(discounts, this.customerModel.discounts);
    }

    async recalculateAvailableRebates() {
        const rebates = await this.lookupService.getRebatesForDropdown();
        this.adjustedRebates = this.getAdjustedRebates(rebates, this.customerModel.rebates);
    }

    removeRebate(i) {
        this.customerModel.rebates.splice(i, 1);
        this.recalculateAvailableRebates();
    }

    async updateDiscount(model: IDropdownModel, pos: number) {
        const discount = await this.lookupService.getDiscounts().find(x => x.discountId === model.id);
        this.customerModel.discounts[pos] = discount;
    }

    async updateRebate(model: IDropdownModel, pos: number) {
        const rebate = await this.lookupService.getRebates().find(x => x.rebateId === model.id);
        this.customerModel.rebates[pos] = rebate;
    }

    addRebate() {
        if (!this.customerModel.rebates)
            this.customerModel.rebates = [];

        this.customerModel.rebates.push(new RebateModel());

        this.recalculateAvailableRebates();
    }
}
