import { ICustomerModel } from "../customers/customer.model";
import * as moment from 'moment';
import { iCalModel } from "./iCal.model";

export interface IRecurringAppointmentModel {
    recurringAppointmentId: number;
    customerId: number;
    billToCustomerId: number;
    jobTypeId: number;
    jobTypeDescription: string;
    appointmentTypeId: number;
    appointmentTypeDescription: string;
    resourceId: number;
    resourceName: string;
    description: string;
    startDate: Date;
    endDate: Date;
    jobName: string;
    jobDescription: string;
    scheduledTime: Date;
    scheduledMinutes: number;
    travelMinutes: number;
    appointmentDescription: string;
    lastUpdated: Date;
    customer: ICustomerModel;
    billToCustomer: ICustomerModel;
    iCalBilling: iCalModel;
    iCalAppointment: iCalModel;
    recalcAppointments: boolean;
    jobNotes: string;
    lastExtendedDate: Date;
    displayFrequency: string;
}

export class RecurringAppointmentModel implements IRecurringAppointmentModel {
    constructor() {
        this.recurringAppointmentId = 0
        this.startDate = moment().startOf("day").toDate();
        this.scheduledTime = moment().startOf("day").add(8, 'hours').toDate();
        this.iCalBilling = new iCalModel();
        this.iCalAppointment = new iCalModel();
        this.recalcAppointments = false;
        this.travelMinutes = 0;
    }
    recurringAppointmentId: number;
    customerId: number;
    billToCustomerId: number;
    jobTypeId: number;
    jobTypeDescription: string;
    appointmentTypeId: number;
    appointmentTypeDescription: string;
    resourceId: number;
    resourceName: string;
    description: string;
    startDate: Date;
    endDate: Date;
    jobName: string;
    jobDescription: string;
    scheduledTime: Date;
    scheduledMinutes: number;
    travelMinutes: number;
    appointmentDescription: string;
    lastUpdated: Date;
    customer: ICustomerModel;
    billToCustomer: ICustomerModel;
    iCalBilling: iCalModel;
    iCalAppointment: iCalModel;
    recalcAppointments: boolean;
    jobNotes: string;
    lastExtendedDate: Date;
    displayFrequency: string;

}
