<div #containerRef *ngIf="isVisible === true" class='slick-photo-gallery'>
	<div class="slick-photo-gallery_overlay" (click)="closeWindow()" [ngClass]="{'visible': isPhotoGalleryVisible === true}"></div>
	<div class="modal-content slick-photo-gallery_content" style="width: 90%; margin-left: 5%;" *ngIf="isPhotoGalleryVisible === true">
		<div class="modal-header slick-photo-gallery_header"><div class="header-right" (click)='closeWindow()'><i class="far fa-times"></i></div></div>
		<div class="modal-body">
			<div *ngIf="selectedPhoto" class='slick-photo-gallery_photo-container'>
				<img class='slick-photo-gallery_photo' [src]='selectedPhoto.photoUrl' />
			</div>
			<div (click)='previousPhoto()' class='slick-photo-gallery_previous-photo'><i class='far fa-chevron-left'></i></div>
			<div (click)='nextPhoto()' class='slick-photo-gallery_next-photo'><i class='far fa-chevron-right'></i></div>
			<!--<div *ngIf="photos" class='slick-photo-gallery_position-indicators'>
				<i (click)='setPosition(index)' *ngFor='let photo of photos; let index = index' [ngClass]='(index === visibleIndex) ? "fas fa-circle" : "far fa-circle"'></i>
			</div>-->
		</div>
	</div>
</div>
