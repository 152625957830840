import { Injectable } from "@angular/core";
import { SlickGridRequestModel } from "./slick-grid-request.model";
import { SlickGridResponseModel } from "./slick-grid-response.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SlickUtilsService } from "../utils/slick-utils.service";

@Injectable()
export class SlickGridService {
	constructor(private http: HttpClient) { }

	async getDataFromServer (requestModel: SlickGridRequestModel): Promise<SlickGridResponseModel> {
		try {
			let params = <any>{};
			if (requestModel.additionalParams)
				params.additionalParams = requestModel.additionalParams;

			// If the user has a custom search string, add it to the params here
			if (requestModel.searchString)
				params.searchString = requestModel.searchString;

			if (requestModel.recordsPerPage)
				params.recordsPerPage = requestModel.recordsPerPage;

			params.page = (requestModel.page === null || requestModel.page === undefined) ? 1: requestModel.page;

			if (requestModel.sortColumn) {
				params.sortColumn = requestModel.sortColumn;
				params.sortDirection = requestModel.sortDirection;
			}

			delete params.columnSearchValues;
			if (requestModel.columnSearchValues)
				params.columnSearchValues = requestModel.columnSearchValues;

			let data: SlickGridResponseModel = (<SlickGridResponseModel>await this.http.post(requestModel.url, params, { headers: requestModel.httpHeaders }).toPromise());
			data = SlickUtilsService.dateSanitize(data);
			return Promise.resolve(data);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}

	}

	async getDataFromDataObj(requestModel: SlickGridRequestModel, data: any[]): Promise<SlickGridResponseModel> {
		try {
			let responseModel: SlickGridResponseModel = new SlickGridResponseModel();
			
			if (!data || data.length === 0) {
				responseModel.totalRecords = 0;
				responseModel.data = [];
			}
			else {
				responseModel.totalRecords = data.length;
				let page = (requestModel.page === null || requestModel.page === undefined) ? 1 : requestModel.page;

				let startIndex = (page - 1) * requestModel.recordsPerPage;
				let endIndex = startIndex + requestModel.recordsPerPage;
				if (endIndex > data.length)
					endIndex = data.length;
				responseModel.data = data.slice(startIndex, endIndex);
			}

			return Promise.resolve(responseModel);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}

	}
}