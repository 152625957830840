import { Injectable } from '@angular/core';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { DevLogsStore } from '@stores';
import { IScheduledAppointmentModel } from '@models';
import { SQLiteService } from '@sqlite';
import { DevTraceService } from '@services/utils/dev-trace.service';
import * as moment from "moment";


interface IScheduledAppointmentRecord {
	hashKey: string;
	scheduledAppointments: string;
}

@Injectable()
export class ScheduledAppointmentsStorageService {
	private db!: SQLiteDBConnection;

	readonly databaseName: string = 'scheduledAppointments';

	constructor(private devLogsStore: DevLogsStore,
		private devTrace: DevTraceService,
		private sqliteService: SQLiteService) {
	}

	async initializeDatabase(): Promise<void> {
		try {
			await this.sqliteService
				.addUpgradeStatement({
					database: this.databaseName,
					upgrade: this.scheduledAppointmentsUpdates
				});

			// create and/or open the database
			const loadToVersion = this.scheduledAppointmentsUpdates[this.scheduledAppointmentsUpdates.length - 1].toVersion;
			this.db = await this.sqliteService.openDatabase(this.databaseName, loadToVersion);
			this.sqliteService.setVersion(this.databaseName, loadToVersion);
			this.devLogsStore.addMessage(`Init ${this.databaseName} database to version ${loadToVersion}`);
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	async deleteDatabase(): Promise<void> {
		await this.sqliteService.deleteDatabase(this.databaseName);
		this.devLogsStore.addMessage(`Deleted ${this.databaseName}`);
	}

	async updateScheduledAppointments(startDate: Date, endDate: Date, scheduledAppointments: IScheduledAppointmentModel[]) {
		try {
			const hashKey = `${moment(startDate).format("YYYYMMDD")}_${moment(endDate).format("YYYYMMDD")}`
			//this.devLogsStore.addMessage(`Adding scheduledAppointment record for date ${hashKey}`);

			const scheduledAppointmentsEncoded = SQLiteService.encodeJSON(scheduledAppointments);

			let allRecords = (await this.db.query(`SELECT * FROM scheduledAppointments`)).values as IScheduledAppointmentRecord[];

			const currentDate = new Date();
			const currentDateString = currentDate.toISOString().split('T')[0].replace(/-/g, ''); // Convert to YYYYMMDD format

			const recordsToKeep = allRecords.filter(record => {
				const [firstPart, secondPart] = record.hashKey.split('_');

				// Check if both parts of the hashKey are dates (YYYYMMDD format)
				const isDateRange = /^\d{8}$/.test(firstPart) && /^\d{8}$/.test(secondPart);
				if (!isDateRange) {
					return true; // Keep records that do not follow the YYYYMMDD_YYYYMMDD format
				}

				// If it's a date range, check if the current date is within the range
				return currentDateString >= firstPart && currentDateString <= secondPart;
			});

			const hashKeysToKeep = recordsToKeep.map(record => record.hashKey);

			for (const record of allRecords) {
				if (!hashKeysToKeep.includes(record.hashKey)) {
					//this.devLogsStore.addMessage(`Deleting scheduledAppointment records for ${record.hashKey}`);

					await this.db.query(`DELETE FROM scheduledAppointments WHERE hashKey = ?`, [record.hashKey]);
				}
			}

			await this.db.run(`delete from scheduledAppointments where hashKey='${hashKey}';`);

			const sql = `INSERT INTO scheduledAppointments (hashKey, scheduledAppointments) VALUES ('${hashKey}', '${scheduledAppointmentsEncoded}');`;
			await this.db.run(sql);
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}
	async updateScheduledAppointmentsForUser(scheduledDate: Date, userId: number, scheduledAppointments: IScheduledAppointmentModel[]) {
		try {
			const currentFormattedDate = moment().startOf("date").format("YYYYMMDD");
			const hashKey = `${moment(scheduledDate).format("YYYYMMDD")}_${userId}`

			//this.devLogsStore.addMessage(`Deleting scheduledAppointment records for all but ${hashKey}`);

			// Delete all records for the current user that are not for current date
			await this.db.run(`
            DELETE FROM scheduledAppointments 
            WHERE hashKey LIKE '%_${userId}' 
            AND hashKey NOT LIKE '${currentFormattedDate}_%';
        `);

			//this.devLogsStore.addMessage(`Adding scheduledAppointment record for ${hashKey}`);

			const scheduledAppointmentsEncoded = SQLiteService.encodeJSON(scheduledAppointments);

			await this.db.run(`delete from scheduledAppointments where hashKey='${hashKey}';`);

			const sql = `INSERT INTO scheduledAppointments (hashKey, scheduledAppointments) VALUES ('${hashKey}', '${scheduledAppointmentsEncoded}');`;
			await this.db.run(sql);
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	async removeScheduledAppointment(appointmentId: number) {
		// Fetch all scheduled appointments records from the database
		const allRecords = (await this.db.query(`SELECT * FROM scheduledAppointments`)).values as IScheduledAppointmentRecord[];

		// Iterate over each record
		for (const scheduledAppointmentsRecord of allRecords) {
			const scheduledAppointmentsEncoded = scheduledAppointmentsRecord.scheduledAppointments;

			// Decode and parse the JSON data
			const scheduledAppointmentsJSON = SQLiteService.decodeJSON(scheduledAppointmentsEncoded);
			const scheduledAppointments: IScheduledAppointmentModel[] = JSON.parse(scheduledAppointmentsJSON);

			// Update the appointment status if the appointmentId matches
			let updated = false;
			let updatedAppts = scheduledAppointments.filter(x => x.appointmentId !== appointmentId);

			if (updatedAppts?.length !== scheduledAppointments?.length)
				updated = true;

			// Only update the record if there was a change
			if (updated) {
				const updatedScheduledAppointmentsEncoded = SQLiteService.encodeJSON(updatedAppts);

				await this.db.run(`delete from scheduledAppointments where hashKey='${scheduledAppointmentsRecord.hashKey}';`);

				const sql = `INSERT INTO scheduledAppointments (hashKey, scheduledAppointments) VALUES ('${scheduledAppointmentsRecord.hashKey}', '${updatedScheduledAppointmentsEncoded}');`;
				await this.db.run(sql);
			}
		}
	}
	

	async updateScheduledAppointment(appointmentId: number, newAppt: IScheduledAppointmentModel) {

		await this.removeScheduledAppointment(appointmentId);
		// Fetch all scheduled appointments records from the database
		const allRecords = (await this.db.query(`SELECT * FROM scheduledAppointments`)).values as IScheduledAppointmentRecord[];
		const newApptDate = new Date(newAppt.scheduledDateTime);

		// Iterate over each record
		for (const scheduledAppointmentsRecord of allRecords) {
			const scheduledAppointmentsEncoded = scheduledAppointmentsRecord.scheduledAppointments;

			// Decode and parse the JSON data
			const scheduledAppointmentsJSON = SQLiteService.decodeJSON(scheduledAppointmentsEncoded);
			const scheduledAppointments: IScheduledAppointmentModel[] = JSON.parse(scheduledAppointmentsJSON);

            let updated = false;

            // Parse the hashKey and check if the new appointment's date is within the range
            const [startDateStr, endDateStr] = scheduledAppointmentsRecord.hashKey.split('_');
            const startDate = moment(startDateStr, "YYYYMMDD").toDate();
            const endDate = moment(endDateStr, "YYYYMMDD").toDate();

            // Check if the new appointment's scheduledDateTime is within this hashKey's date range
            if (newApptDate >= startDate && newApptDate <= endDate) {
                scheduledAppointments.push(newAppt);
                updated = true;
            }

			// Only update the record if there was a change
			if (updated) {
				const updatedScheduledAppointmentsEncoded = SQLiteService.encodeJSON(scheduledAppointments);

				// Use an UPDATE query instead of DELETE + INSERT
				await this.db.run(`delete from scheduledAppointments where hashKey='${scheduledAppointmentsRecord.hashKey}';`);

				const sql = `INSERT INTO scheduledAppointments (hashKey, scheduledAppointments) VALUES ('${scheduledAppointmentsRecord.hashKey}', '${updatedScheduledAppointmentsEncoded}');`;
				await this.db.run(sql);
			}
		}
	}

	async updateScheduledAppointmentStatus(appointmentId: number, appointmentStatusId: number, color: string) {
		// Fetch all scheduled appointments records from the database
		const allRecords = (await this.db.query(`SELECT * FROM scheduledAppointments`)).values as IScheduledAppointmentRecord[];

		// Iterate over each record
		for (const scheduledAppointmentsRecord of allRecords) {
			const scheduledAppointmentsEncoded = scheduledAppointmentsRecord.scheduledAppointments;

			// Decode and parse the JSON data
			const scheduledAppointmentsJSON = SQLiteService.decodeJSON(scheduledAppointmentsEncoded);
			const scheduledAppointments: IScheduledAppointmentModel[] = JSON.parse(scheduledAppointmentsJSON);

			// Update the appointment status if the appointmentId matches
			let updated = false;
			const index = scheduledAppointments.findIndex(x => x.appointmentId === appointmentId);
			if (index !== -1) {
				scheduledAppointments[index].appointmentStatusId = appointmentStatusId;
				scheduledAppointments[index].appointmentStatusColor = color;
				updated = true;
			}

			// Only update the record if there was a change
			if (updated) {
				const updatedScheduledAppointmentsEncoded = SQLiteService.encodeJSON(scheduledAppointments);

				// Use an UPDATE query instead of DELETE + INSERT
				await this.db.run(`delete from scheduledAppointments where hashKey='${scheduledAppointmentsRecord.hashKey}';`);

				const sql = `INSERT INTO scheduledAppointments (hashKey, scheduledAppointments) VALUES ('${scheduledAppointmentsRecord.hashKey}', '${updatedScheduledAppointmentsEncoded}');`;
				await this.db.run(sql);
			}
		}
	}



	async getScheduledAppointments(startDate: Date, endDate: Date): Promise<IScheduledAppointmentModel[]> {
		try {
			const hashKey = `${moment(startDate).format("YYYYMMDD")}_${moment(endDate).format("YYYYMMDD")}`

			const scheduledAppointmentsRecords: IScheduledAppointmentRecord[] = (await this.db.query(`SELECT * FROM scheduledAppointments where hashkey='${hashKey}';`)).values as IScheduledAppointmentRecord[];

			if ((scheduledAppointmentsRecords?.length ?? 0) === 0)
				return [];

			const scheduledAppointmentsEncoded = scheduledAppointmentsRecords[0].scheduledAppointments;
			const scheduledAppointmentsJSON = SQLiteService.decodeJSON(scheduledAppointmentsEncoded)
			const scheduledAppointments: IScheduledAppointmentModel[] = JSON.parse(scheduledAppointmentsJSON);

			//this.devLogsStore.addMessage(`Returning JSON ${scheduledAppointmentsJSON}`);

			return scheduledAppointments;
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	async getScheduledAppointmentsForUser(scheduledDate: Date, userId: number): Promise<IScheduledAppointmentModel[]> {
		try {
			const hashKey = `${moment(scheduledDate).format("YYYYMMDD")}_${userId}`

			const scheduledAppointmentsRecords: IScheduledAppointmentRecord[] = (await this.db.query(`SELECT * FROM scheduledAppointments where hashkey='${hashKey}';`)).values as IScheduledAppointmentRecord[];

			if ((scheduledAppointmentsRecords?.length ?? 0) === 0)
				return [];

			const scheduledAppointmentsEncoded = scheduledAppointmentsRecords[0].scheduledAppointments;
			const scheduledAppointmentsJSON = SQLiteService.decodeJSON(scheduledAppointmentsEncoded)
			const scheduledAppointments: IScheduledAppointmentModel[] = JSON.parse(scheduledAppointmentsJSON);

			//this.devLogsStore.addMessage(`Returning JSON ${scheduledAppointmentsJSON}`);

			return scheduledAppointments;
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	async getScheduledAppointment(appointmentId: number): Promise<IScheduledAppointmentModel> {
		const allRecords = (await this.db.query(`SELECT * FROM scheduledAppointments`)).values as IScheduledAppointmentRecord[];

		// Iterate over each record
		for (const scheduledAppointmentsRecord of allRecords) {
			const scheduledAppointmentsEncoded = scheduledAppointmentsRecord.scheduledAppointments;

			// Decode and parse the JSON data
			const scheduledAppointmentsJSON = SQLiteService.decodeJSON(scheduledAppointmentsEncoded);
			const scheduledAppointments: IScheduledAppointmentModel[] = JSON.parse(scheduledAppointmentsJSON);

			// Update the appointment status if the appointmentId matches
			const appt = scheduledAppointments.find(x => x.appointmentId === appointmentId);
			if (appt) {
				return appt;
			}
		}

		return null;

	}

	readonly scheduledAppointmentsUpdates = [
		{
			toVersion: 1,
			statements: [
				`
CREATE TABLE IF NOT EXISTS scheduledAppointments(
    hashKey string PRIMARY KEY,
    scheduledAppointments TEXT NULL
);`
			]
		},
		/* add new statements below for next database version when required*/
		/*
		{
		toVersion: 2,
		statements: [
			`ALTER TABLE users ADD COLUMN email TEXT;`,
		]
		},
		*/
	]
}


