import { Component, ViewChild, OnInit, ElementRef, Input, OnDestroy } from "@angular/core";
import { AuthService, GlobalsService, LoginService, SleepService, TimeclockService, FunctionLockService, JobsService, ScheduleStore, InvoicesService, AppointmentsService, LookupService} from "@services";
import { ITimeclockEntryModel, ITimeclockStartEndModel, IUserInfoModel, TimeclockBreakModel, TimeclockEntryModel, TimeclockStartEndModel, IScheduleAppointmentSearchModel, IScheduledAppointmentModel } from "@models";
import { Title } from "@angular/platform-browser";
import { ChatStore, TimeclockStore } from "@stores";
import { JobEditDialogComponent } from "@app/jobs/job-components";
import { SkeditChatComponent } from "@app/chat/chat.module";
import { Subscription } from "rxjs";
import { SlickDialogComponent, SlickScreenBlockerService, SlickSearchBarModule, SlickSearchBarComponent } from "@slick-components";

import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
	selector: "header-bar",
	templateUrl: "header-bar.component.html",
	styleUrls: ['header-bar.component.scss'],
	providers: [AppointmentsService, TimeclockService, SlickScreenBlockerService, JobsService, InvoicesService],
	host: { 'class': 'header-bar' }
})

export class HeaderBarComponent implements OnInit, OnDestroy {
	@ViewChild("trialRef", { static: true }) trialRef: ElementRef;
	@ViewChild("chatRef", { static: false }) chatRef: SkeditChatComponent;
	@ViewChild("jobEditDialogRef", { static: true }) jobEditDialogRef: JobEditDialogComponent;
	@ViewChild("searchBarRef") searchBarRef: SlickSearchBarComponent;


	firstName: string;
	profilePictureUrl: string;
	trialDays: number;
	trialStartDate: Date;
	trialEndDate: Date;
	daysLeftInTrial: number;
	companyName: string;
	searchString: string;

	currentDate: Date;
	clipboardAppointment: IScheduledAppointmentModel;
	appointmentId: number;
	searchResults: IScheduleAppointmentSearchModel[];

	currentDate$: Subscription;
	clipboardAppointment$: Subscription;

	chatType: string;
	chatType$: Subscription;
	refreshCurrentEntry$: Subscription;
	unreadMessageCount$: Subscription;
	newChatMessagesCount: number;
	breaks: ITimeclockStartEndModel[];

	currentTimeclockEntry: ITimeclockEntryModel;
	isTimeclockExempt: boolean = GlobalsService.userInfo.timeClockExempt;
	profilePicUrl: string = GlobalsService.userInfo.profilePictureUrl;
	fullName: string = GlobalsService.userInfo.fullName;

	useQuickbooks: boolean = (GlobalsService.company.quickbooksWebConnectorEnabled === true)
	isQPro = GlobalsService.company.isQPro;
	isLathamOrdersOnly = GlobalsService.company.isLathamOrdersOnly;

	@ViewChild('newCompanyPopup', { static: true }) newCompanyPopup: SlickDialogComponent;

	canAccessChat: boolean = false;
	skeditPay: boolean = GlobalsService.company.useSkeditPay;

	constructor(
		public titleService: Title,
		private readonly authService: AuthService,
		private readonly loginService: LoginService,
		private readonly lookupService: LookupService,
		private readonly timeclockService: TimeclockService,
		private readonly slickScreenBlockerService: SlickScreenBlockerService,
		private timeclockStore: TimeclockStore,

		private functionLockService: FunctionLockService,
		private jobsService: JobsService,
		private appointmentsService: AppointmentsService,
		private scheduleStore: ScheduleStore,
		private chatStore: ChatStore) {

		this.firstName = GlobalsService.userInfo.firstName;
		this.profilePictureUrl = GlobalsService.userInfo.profilePictureUrl;
		this.trialDays = GlobalsService.company.trialDays;
		this.trialStartDate = GlobalsService.company.trialStartDate;
		this.trialEndDate = GlobalsService.company.freeTrialEndDate;
		this.daysLeftInTrial = GlobalsService.company.daysLeftInTrial
		this.companyName = GlobalsService.company.companyName;


		this.canAccessChat = GlobalsService.userInfo.showOnChat;
		
	}


	async ngOnInit() {
		this.titleService.setTitle("");
		this.newChatMessagesCount = this.chatStore.unreadMessageCount;

		this.currentDate = this.scheduleStore.currentDate;

		this.currentDate$ = this.scheduleStore.currentDateStore.subscribe(async (currentDate) => {
			this.currentDate = currentDate;
		});

		this.clipboardAppointment$ = this.scheduleStore.clipboardAppointmentStore.subscribe(async (clipboardAppt: IScheduledAppointmentModel) => {
			this.clipboardAppointment = clipboardAppt;
		});
		

		this.chatType = this.chatStore.chatType;
		this.chatType$ = this.chatStore.chatTypeStore.subscribe(chatType => this.chatType = chatType);
		this.refreshCurrentEntry$ = this.timeclockStore.refreshCurrentEntry$.subscribe((timeclockEntryModel: ITimeclockEntryModel) => {
			this.currentTimeclockEntry = timeclockEntryModel;
		})
		this.currentTimeclockEntry = await this.timeclockService.getLastTimeclockEntry(GlobalsService.userInfo.userId);

		this.unreadMessageCount$ = this.chatStore.unreadMessageCountSubject.subscribe(messageCount => {
			
			this.newChatMessagesCount = messageCount
		});
		if (GlobalsService.company.isPaid === false && GlobalsService.company.isLathamOrdersOnly === false) {


			await SleepService.sleep(4000);
			(<HTMLDivElement>this.trialRef.nativeElement).classList.add("show");

			await SleepService.sleep(5000);
			(<HTMLDivElement>this.trialRef.nativeElement).classList.remove("show");
			(<HTMLDivElement>this.trialRef.nativeElement).classList.add("hide");

			await SleepService.sleep(1000);
			(<HTMLDivElement>this.trialRef.nativeElement).classList.remove("hide");
		}

		
	}

	ngOnDestroy() {
		if(this.currentDate$)
			this.currentDate$.unsubscribe();
		if(this.clipboardAppointment$)
			this.clipboardAppointment$.unsubscribe();

		if (this.chatType$)
			this.chatType$.unsubscribe();

		if (this.refreshCurrentEntry$)
			this.refreshCurrentEntry$.unsubscribe();
		if (this.unreadMessageCount$)
			this.unreadMessageCount$.unsubscribe();
	}

	async search(searchString) {
		await this.functionLockService.lock("SCHEDULE_APPOINTMENT_SEARCH");
		this.searchResults = null;
		if (this.searchString?.length < 1)
			return;

		this.searchResults = await this.appointmentsService.scheduleAppointmentSearch(searchString);
		await this.functionLockService.release("SCHEDULE_APPOINTMENT_SEARCH");
	}

	async onSelect(scheduleAppointmentSearchModel: IScheduleAppointmentSearchModel) {
		this.appointmentId = scheduleAppointmentSearchModel.appointmentId;
		let jobModel = await this.jobsService.getJobByAppointmentId(this.appointmentId);
		jobModel = await this.jobEditDialogRef.openDialog(jobModel, this.appointmentId);


		if (!jobModel){
			return;
		}

		const savedAppt = jobModel.appointments.find(x => x.appointmentId === this.appointmentId);
		this.scheduleStore.currentDate = savedAppt.scheduledDateTime;

	}

	openChat(e: Event) {
		e.stopImmediatePropagation();
		e.preventDefault();

		//if (GlobalsService.userInfo.layoutSettings.chatLayout === 'skedit')
		this.chatStore.isDialogClosed = false;
		this.chatStore.setSelectedChatGroupMessagesToRead();
		this.chatStore.refreshChatList();
		this.chatRef.openChat();
	}

	async logout() {
		//await this.loginService.logout();
		this.authService.clearToken();
		this.lookupService.clearLookups();
		window.location.href = "/";
	}

	async clockIn() {
		try {
			this.slickScreenBlockerService.forceBlock();

			await SleepService.sleep(500);

			// Check to see if they are still clocked into this job
			const actualCurrentTimeclockEntry = await this.timeclockService.getLastTimeclockEntry(GlobalsService.userInfo.userId);

			// Not clocked in at all.  Show an error
			if (actualCurrentTimeclockEntry) {
				this.slickScreenBlockerService.forceUnblock();

				this.currentTimeclockEntry = actualCurrentTimeclockEntry;

				await Swal.fire({
					icon: 'warning',
					title: 'Oops',
					text: 'You are already clocked in',
					confirmButtonColor: '#007bff',
					width: '28em',
					heightAuto: false
				});

				return;
			}

			const timeclockEntryModel = new TimeclockEntryModel();
			timeclockEntryModel.userId = GlobalsService.userInfo.userId;
			timeclockEntryModel.clockIn = new Date();

			this.currentTimeclockEntry = await this.timeclockService.clockIn(timeclockEntryModel);
			this.timeclockStore.refreshCurrentEntry(this.currentTimeclockEntry);
		}
		finally {
			this.slickScreenBlockerService.forceUnblock();
		}
	}

	async clockOut() {
		try {
			this.slickScreenBlockerService.forceBlock();

			await SleepService.sleep(500);

			// Check to see if they are still clocked into this job
			const lastTimeclockEntry = await this.timeclockService.getLastTimeclockEntry(GlobalsService.userInfo.userId);

			// Not clocked in at all.  Show an error
			if (!lastTimeclockEntry) {
				this.slickScreenBlockerService.forceUnblock();

				this.currentTimeclockEntry = null;

				await Swal.fire({
					icon: 'warning',
					title: 'Oops',
					text: 'You are not currently clocked in',
					confirmButtonColor: '#007bff',
					width: '28em',
					heightAuto: false
				});
				
				return;
			}

			this.currentTimeclockEntry.clockOut = new Date();

			await this.timeclockService.clockOut(this.currentTimeclockEntry);

			this.currentTimeclockEntry = null;

			this.timeclockStore.refreshCurrentEntry(this.currentTimeclockEntry);

		}
		finally {
			this.slickScreenBlockerService.forceUnblock();
		}

	}

	async startBreak() {
		const newBreak = new TimeclockBreakModel();
		newBreak.breakStart = moment().toDate();
		newBreak.timeclockEntryId = this.currentTimeclockEntry.timeclockEntryId;
		await this.timeclockService.startBreak(newBreak);
		this.currentTimeclockEntry = await this.timeclockService.getLastTimeclockEntry(GlobalsService.userInfo.userId);
		this.recalcBreaks();
	}

	async endBreak() {
		this.currentTimeclockEntry.currentBreak.breakEnd = moment().toDate();
		await this.timeclockService.endBreak(this.currentTimeclockEntry.currentBreak);
		this.currentTimeclockEntry = await this.timeclockService.getLastTimeclockEntry(GlobalsService.userInfo.userId);
		this.recalcBreaks();
		
    }

	private recalcBreaks() {
		this.breaks = this.currentTimeclockEntry.breaks.map(b => {
			const timeclockStartEndModel = new TimeclockStartEndModel(b.breakStart, b.breakEnd, b.totalMinutes, b.uuid);
			timeclockStartEndModel.startGeolocation = b.breakStartGeolocation;
			timeclockStartEndModel.endGeolocation = b.breakEndGeolocation;

			return timeclockStartEndModel;
		});
		this.currentTimeclockEntry = this.timeclockService.recalc(this.currentTimeclockEntry);
	}
}