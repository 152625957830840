<div id="slick-grid_{{uuid}}" class="slick-grid d-flex flex-column flex-fill" style="position: absolute; left: 0; top: 0; right: 0; bottom: 0;" #slickGrid>
	<div #tableContainer class="d-flex flex-fill" style="overflow: auto">

		<div>
			<table #tableRef class="table table-sm" [class.table-bordered]="bordered" [class.table-borderless]="!bordered" [class.table-striped]="striped" [class.table-hover]="hover" [style.opacity]="gridOpacity" style="margin: 0; padding: 0;" (keydown)="tableKeydown($event)" tabindex="0">
				<thead>
					<tr #tableHeaderRow (mousemove)="onColResizeMousemove($event)" [ngClass]="{ 'resizing': isResizing === true }">
						<td *ngFor="let column of visibleColumns; let idx = index" [style.width]="column.width" [style.maxWidth]="column.width" [style.height]="column.titleHeight">
							<div [ngClass]="{ 'slick-grid_title-vertical' : column.titleOrientation === 1 }">
								<div *ngIf="idx > 0 && idx < visibleColumns.length" class="resize_column_left" [class.table-bordered]="bordered" (mousedown)="onColResizeMousedown($event, idx - 1)" (dblclick)="onColResizeDoubleclick($event, idx - 1)"></div>
								<span class="sortIcon" *ngIf="column.sortable === true && column.sortDirection > 0"><i class="far" [ngClass]="{'fa-caret-up': column.sortDirection === 1, 'fa-caret-down': column.sortDirection === 2}"></i>&nbsp;</span>
								<a href="javascript:void(0)" *ngIf="column.sortable === true" (click)="sortBy(column)" draggable="false" title="{{column.title}}">{{column.title}}</a>
								<div *ngIf="column.sortable === false" title="{{column.title}}">{{column.title}}</div>
								<div class="resize_column_right" (mousedown)="onColResizeMousedown($event, idx)" (dblclick)="onColResizeDoubleclick($event, idx)"></div>
							</div>
						</td>
					</tr>
					<tr #tableHeaderSearchRow *ngIf="showSearchRow === true">
						<td *ngFor="let column of visibleColumns">
							<div class="slick-grid_search-column">
								<i *ngIf="column.isFiltering" class="fas fa-times-circle bg-white text-danger me-1" (click)="resetFilter(column)"></i>
								<i *ngIf="column.isFiltering && column.isLocked" class="far fa-lock me-1" (click)="toggleLock(column)"></i>
								<i *ngIf="column.isFiltering && !column.isLocked" class="far fa-lock-open me-1" (click)="toggleLock(column)"></i>
								<div *ngIf="column.filterType === 1" class="slick-grid_text-search">
									<input autocomplete="nope" type="text" class="form-control" [(ngModel)]="column.filterText" name="searchText" placeholder="Search..." (keydown)="onSearch(column)" />
								</div>

								<div *ngIf="column.filterType === 2" class="input-group slick-grid_drop-down-search">
									<slick-drop-down [items]="column.filterDropdownValues" [(ngModel)]="column.filterDropdownValue" cssClass="slick-grid_filter-drop-down" listWidth="auto" placeholder="Search..." (onSelect)="onSearchSelect($event, column)"></slick-drop-down>
								</div>
							</div>
						</td>
					</tr>
				</thead>

				<tbody>
					<tr *ngIf="loaded && visibleData.length === 0"><td [colSpan]="visibleColumns.length">No Data</td></tr>
					<tr *ngFor="let rowData of visibleData; let idx = index" #tableBodyRows (click)="onRowClicked(rowData, idx)" (dblclick)="onRowDoubleClicked(rowData, idx)" (contextmenu)="onRowContextMenuClicked($event, rowData, idx)" [class.table-success]="selectedIndex === idx">
						<td *ngFor="let column of visibleColumns">
							<div *ngIf="!column.columnKey" class="slick-grid_column-content" [style.width]="column.width" [style.maxWidth]="column.width" [innerHTML]="getColumnHTML(rowData, column.dataFieldName) | slickGridSanitizeHtml"></div>
							<div *ngIf="column.columnKey" class="slick-grid_column-content" [style.width]="column.width" [style.maxWidth]="column.width">
								<ng-container *ngTemplateOutlet="templateRefs[column.columnKey], context: { $implicit: rowData }">
								</ng-container>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>


	<div class="slick-grid-footer mt-2 d-flex">
		<div>
		  <button type="button" (click)="openColumnSelectDialog()" class="btn btn-outline-primary" style="border-radius: 100%; height: 2.2rem; width: 2.2rem; padding: 0;"><i class="fal fa-columns"></i></button>
		</div>
		<div *ngIf="favorites?.length > 1">
		  <button #favoriteSelectRef type="button" class="btn btn-outline-primary ms-1"><i class="far fa-star"></i> {{this.selectedFavorite}}</button>
		  <slick-popover [element]="favoriteSelectRef" position="top" showArrow="false" [topOffsetPx]="0" [leftOffsetPx]="0" [showByClick]="true">
			<table class="table-borderless table-sm table-hover" style="min-width: 250px; cursor: default">
			  <tbody>
				<tr *ngFor="let favorite of favorites" (click)="setFavorite(favorite.text)">
				  <td><i *ngIf="favorite.text === selectedFavorite" class="far fa-star me-1"></i>{{favorite.text}}</td>
				</tr>
			  </tbody>
			</table>
		  </slick-popover>
		</div>
		<div *ngIf="showingAllRecords == false">
		  <slick-paging [(pageNumber)]="currentPage" [totalRecords]="totalRecords" [recordsPerPage]="recordsPerPage" (onPageChange)="onPageChange($event)"></slick-paging>
		</div>
		<div class="ms-auto">
		  <button #recordsPerPageRef type="button" class="btn btn-outline-primary">{{recordsPerPageValue}}&nbsp;&nbsp;<i style="font-size: 12px;" class="far fa-chevron-down"></i></button>
		  <slick-popover [element]="recordsPerPageRef" position="top" showArrow="false" [topOffsetPx]="0" [leftOffsetPx]="0" [showByClick]="true">
			<table class="table-borderless table-sm table-hover" style="min-width: 150px;">
			  <tbody>
				<tr (click)="onRecordsPerPageSelected('Auto')"><td>Auto</td></tr>
				<tr (click)="onRecordsPerPageSelected('25')"><td>25</td></tr>
				<tr (click)="onRecordsPerPageSelected('50')"><td>50</td></tr>
				<tr (click)="onRecordsPerPageSelected('100')"><td>100</td></tr>
			  </tbody>
			</table>
		  </slick-popover>
		</div>
	  </div>
	  
</div>

<slick-dialog #columnSelectDialogRef header="Select Columns">
	<div style="text-align: center; width: 475px;" class="mx-5">
		<div class="d-flex">
			<div class="flex-fill">
				<slick-drop-down [items]="favorites"
								 [ngModel]="selectedFavorite"
								 (onSelect)="loadFavorite($event)"></slick-drop-down>
			</div>
			<div><button class="btn btn-outline-primary ms-1" (click)="addNewFavorite()"><i class="far fa-plus"></i></button></div>
		</div>
		<div class="d-flex flex-column mt-1" slick-drop-list (onSlickDropListReorder)="onColumnReorder($event)">
			<div *ngFor="let column of columnSelectDialogColumns" class="d-flex" slick-drop-list-item>
				<div style="width: 20px"><input type="checkbox" style="position: relative; top: 2px;" [(ngModel)]="column.visible" /></div>
				<div class="py-0 flex-fill" style="text-align: left; cursor: ns-resize" slick-drop-list-grab>{{column.title}}</div>
			</div>
		</div>
	</div>
	<slick-dialog-footer>
		<div style="margin-top: 10px;" class="col-12 text-center mb-2 mt-1">
			<button type="button" (click)="onSaveColumns()" class="btn btn-outline-primary me-1"><i class="far fa-save"></i>&nbsp;Save</button>&nbsp;
			<button type="button" class="btn btn-outline-danger" (click)="onCancelColumns()">Cancel</button>
		</div>
	</slick-dialog-footer>
</slick-dialog>


<slick-confirm-dialog #addNewFavoriteDialogRef width="300">
	<label>Enter new favorite name</label>
	<input type="text" class="form-control mx-3" style="width: 250px" [(ngModel)]="newFavoriteText" />
</slick-confirm-dialog>