<div #containerRef class="slick-dialog-container modal" *ngIf="showDialog_internal === true" [hidden]="!isDialogVisible" [style.zIndex]="zIndex" #bounds>
    <div #dialogWrapper class="position-absolute slick-dialog-z-index d-inline-block" [ngClass]="{'isMinimized' : isMinimized }" (endOffset)="onMoveEnd($event)" [position]="position" [ngDraggable]="draggable && !isMinimized" [bounds]="bounds" [handle]="draggingHandle" [inBounds]="inBounds" [style.top.px]="top">
        <div #dialogRef (rzStop)="onResizeStop($event)" [ngResizable]="resizable && !isMinimized" class="slick-dialog modal-content d-flex flex-column drag-block-handle {{cssClass}}" [style.height]="height === 0 ? '100%' : height + 'px'" [style.width.px]="width" [style.max-height.px]="maxHeight">
            <div [class.slick-dialog-draggingHangle]="draggable && !isMinimized" #draggingHandle>
                <div #headerRef class="slick-dialog_header modal-header py-0" *ngIf="showHeader === true">
                    <div class="header-left">{{header}}</div>
                    <div class="header-right" *ngIf="minimizable && !isMinimized" (mousedown)="onDialogMinimize($event)" style="width: 28px;"><i class="far fa-window-minimize"></i></div>
                    <div class="header-right" *ngIf="minimizable && !isMinimized" (mousedown)="toggleFullSize($event)" style="width: 28px;"><i *ngIf="!isMaximized" class="far fa-window-maximize"></i><i *ngIf="isMaximized" class="far fa-window-restore"></i></div>
                    <div class="header-right" *ngIf="minimizable && isMinimized" (mousedown)="onDialogMaximize($event)" style="width: 28px;"><i class="far fa-window-restore"></i></div>
                    <div class="header-right" *ngIf="!isMinimized" (mousedown)="onDialogClose($event)" style="width: 28px;"><i class="far fa-times"></i></div>
                </div>
            </div>
            <div *ngIf="!isMinimized" [style.visibility]="(isBodyVisible === true) ? 'visible' : 'hidden'" style="overflow-y: auto" class="slick-dialog_body modal-body flex-fill" [ngClass]="{'disable_scrollbar' : disableScrollbar === true, 'p-0': noPadding === true }"><ng-content></ng-content></div>
            <div #footerRef [ngClass]="{'slick-dialog_footer modal-footer': hasFooter === true }" *ngIf="!isMinimized"><ng-content select="slick-dialog-footer"></ng-content></div>
        </div>
    </div>

</div>