import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';  
import { SlickConfirmDialogComponent, SlickConfirmDialogResults } from "./slick-confirm-dialog.component";
import { SlickDialogModule } from "../slick-dialog/slick-dialog.module";

@NgModule({
	imports: [SlickDialogModule, CommonModule],
	declarations: [SlickConfirmDialogComponent],
	exports: [SlickConfirmDialogComponent]
})
export class SlickConfirmDialogModule { }
export { SlickConfirmDialogComponent, SlickConfirmDialogResults }
