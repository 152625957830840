export interface IAddressModel {
    addressId: number;
    firstName: string;
    lastName: string;
    companyName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    fullAddress: string;
    latitude?: number;
    longitude?: number;
    active: boolean;
    displayCityStateZip: string;
}

export class AddressModel implements IAddressModel {
    addressId: number;
    firstName: string;
    lastName: string;
    companyName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    fullAddress: string;
    latitude?: number;
    longitude?: number;
    active: boolean;
    displayCityStateZip: string;
}