import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, HostListener, OnDestroy } from "@angular/core";
import { SlickUtilsService } from "../utils/slick-utils.service";
import { ISlickFileModel, SlickFileModel } from "../slick-file-model/slick-file.model";

const imgExtensions: string[] = ['bmp', 'gif', 'jpeg', 'jpg', 'png', 'tif', 'tiff']

@Component({
	selector: 'slick-file-drop',
	templateUrl: 'slick-file-drop.component.html'
})
export class SlickFileDropComponent implements OnDestroy {
	@Input("displayText") displayText: string = "Drop File or click to Upload";
	@Output("filesChanged") filesChangedEmitter: EventEmitter<ISlickFileModel[]> = new EventEmitter();
	@ViewChild("containerRef") containerRef: ElementRef;
	@ViewChild("fileInput") fileInput: ElementRef;

	private fnOnFileInputChange = (e) => this.onFileInputChange(e);
	private processFileTimeout: NodeJS.Timer;

	@HostListener('click', ['$event'])
	onClick(e) {
		this.fileInput.nativeElement.click();
		(<HTMLInputElement>this.fileInput.nativeElement).removeEventListener("change", this.fnOnFileInputChange, true);
		(<HTMLInputElement>this.fileInput.nativeElement).addEventListener("change", this.fnOnFileInputChange, true);
	}

	ngOnDestroy() {
		(<HTMLInputElement>this.fileInput.nativeElement).removeEventListener("change", this.fnOnFileInputChange, true);
	}

	private onFileInputChange(e) {
		try {
			this.processFileTimeout = setTimeout(() => {
				alert("Processing files timed out");
			}, 10000);

			this.processFiles((<HTMLInputElement>this.fileInput.nativeElement).files);

			// A validation to make sure the files all got processed
			clearTimeout(this.processFileTimeout);
			(<HTMLInputElement>this.fileInput.nativeElement).value = '';
		}
		catch (err) {
			alert("SlickFileDrop Error: " + err);
		}
	}

	@HostListener('drop', ['$event'])
	onDrop(e) {
		e.preventDefault();
		e.stopPropagation();

		this.containerRef.nativeElement.classList.remove("slick-file-drop_drag-enter");

		this.processFiles((<DataTransfer>e.dataTransfer).files);
	}

	@HostListener('dragenter', ['$event'])
	onDragEnter(e) {
		this.containerRef.nativeElement.classList.add("slick-file-drop_drag-enter");
	}

	@HostListener('dragover', ['$event'])
	onDragOver(e) {
		e.preventDefault();
	}

	@HostListener('dragleave', ['$event'])
	onDragLeave(e) {
		this.containerRef.nativeElement.classList.remove("slick-file-drop_drag-enter");
	}

	uuid: string;

	constructor() {
		this.uuid = SlickUtilsService.newGuid();
	}

	private async processFiles(files: FileList) {
		let slickFileModels: ISlickFileModel[] = [];
		let imageLoadPromises = [];

		let imgPromises: Promise<string>[] = [];
		for (let i = 0; i < files.length; i++) {
			imageLoadPromises.push(new Promise(async (resolve) => {
				let file: File = files.item(i);
				let slickFileModel = new SlickFileModel();
				slickFileModel.uuid = SlickUtilsService.newGuid();
				slickFileModel.file = file;
				slickFileModel.name = file.name;
				slickFileModel.title = file.name;

				let fileNameParts = slickFileModel.name.split('.');
				let ext = fileNameParts[fileNameParts.length - 1].toLowerCase();

				if (imgExtensions.indexOf(ext) >= 0) {
					let img = new Image;
					img.onload = () => {	
						slickFileModel.thumbnailBase64Image = this.resizeImageToBase64(img, 90, 90);
						slickFileModel.base64Image = this.resizeImageToBase64(img, 1600, 1600);
						slickFileModels.push(slickFileModel);
						resolve(null);
					}
					img.src = await this.getBase64(file);
				}
				else {
					slickFileModels.push(slickFileModel);
					resolve(null);
				}
			}));
		}

		await Promise.all(imageLoadPromises);
		this.filesChangedEmitter.emit(slickFileModels);
	}

	private resizeImageToBase64(img: HTMLImageElement, width: number, height: number): string {
		let scale: number = 1;
		if (img.width > img.height) {
			if (img.width > width)
				scale = width / img.width;
		}
		else {
			if (img.height > height)
				scale = height / img.height;
		}

		let newWidth = img.width * scale;
		let newHeight = img.height * scale;

		// We create a canvas and get its context.
		var canvas = document.createElement('canvas');
		var ctx = canvas.getContext('2d');

		// We set the dimensions at the wanted size.
		canvas.width = newWidth;
		canvas.height = newHeight;

		// We resize the image with the canvas method drawImage();
		ctx.drawImage(img, 0, 0, newWidth, newHeight);

		return canvas.toDataURL("image/jpeg");
	}

	private getBase64(file: File): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			// Capacitor fails on the FileReader, so we need to get _readReader
			// See: https://github.com/ionic-team/ionic-native/issues/505#issuecomment-503316333
			let fileReader = new FileReader();

			// Get the original real FileReader. The polyfill saves a reference to it.
			const realFileReader = (fileReader as any)._realReader;

			// Make sure we were able to get the original FileReader 
			if (realFileReader) {
				// Swap out the polyfill instance for the original instance.
				fileReader = realFileReader;
			}

			fileReader.readAsDataURL(file);
			fileReader.onload = () => resolve(<string>fileReader.result);
			fileReader.onerror = (error) => reject('Error: ' + error);
		});
	}
}
