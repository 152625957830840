import { Injectable } from '@angular/core';
import { HttpService } from './utils/http.service';
import { IPurchaseOrderGridModel, IPurchaseOrderModel, IEmailerModel } from '@models';

@Injectable()
export class PurchaseOrdersService {
    constructor(private httpService: HttpService) {
    }

	getPurchaseOrder(purchaseOrderId: number): Promise<IPurchaseOrderModel> {
		return this.httpService.get('/purchaseOrders/getPurchaseOrder', { purchaseOrderId });
	}

	getPurchaseOrderForGrid(purchaseOrderId: number): Promise<IPurchaseOrderGridModel> {
		return this.httpService.get('/purchaseOrders/getPurchaseOrderForGrid', { purchaseOrderId });
	}

	async getPurchaseOrdersForInfiniteScroll(pageNumber: number, searchString: string): Promise<IPurchaseOrderGridModel[]> {
		const params = {
			pageNumber,
			searchString
		}
		return await this.httpService.get("/purchaseOrders/getPurchaseOrdersForInfiniteScroll", params);
	}

	async generatePurchaseOrderPdf(purchaseOrderId: number): Promise<any> {
		return await this.httpService.post(`/purchaseOrders/generatePurchaseOrderPdf?purchaseOrderId=${purchaseOrderId}`, null);
	}

	async preparePurchaseOrderEmail(model: IPurchaseOrderModel): Promise<IEmailerModel> {
		return await this.httpService.post("/purchaseOrders/preparePurchaseOrderEmail", model);
	}

	addPurchaseOrder(purchaseOrder: IPurchaseOrderModel): Promise<IPurchaseOrderModel> {
		return this.httpService.post('/purchaseOrders/addPurchaseOrder', purchaseOrder);
	}

	updatePurchaseOrder(purchaseOrder: IPurchaseOrderModel): Promise<IPurchaseOrderModel> {
		return this.httpService.post('/purchaseOrders/updatePurchaseOrder', purchaseOrder);
	}

	deletePurchaseOrder(purchaseOrderId: number): Promise<boolean> {
		return this.httpService.delete('/purchaseOrders/deletePurchaseOrder', { purchaseOrderId });
	}

	async addPurchaseOrderEmailSentNote(purchaseOrderId): Promise<void> {
		return this.httpService.patch(`/purchaseOrders/addPurchaseOrderEmailSentNote?purchaseOrderId=${purchaseOrderId}`);
	}

	async reSyncQuickbooks(purchaseOrderId: number): Promise<void> {
		return this.httpService.patch(`/purchaseOrders/syncPOFromQuickbooks?purchaseOrderId=${purchaseOrderId}`);
	}
}
