export interface IBillingModel {
    billTo: string;
    billToOverride: boolean;
    taxRateType: string;
    taxRate: number;
}

export class BillingModel implements IBillingModel
{
    billTo: string;
    billToOverride: boolean;
    taxRateType: string;
    taxRate: number;
}