import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { ICustomerListModel, ICustomerModel, IDiscountModel, IRebateModel, IDropdownModel, IJobModel, ICustomerDisplayListModel, ICustomerGridModel, ICustomerJobHistoryModel, IAddressModel, AddressModel, ICustomerOutreachListViewModel, ICompanyModel } from "@models";
import { Observable, of } from "rxjs";
import { CustomerMultipleAddressModel, ICustomerMultipleAddressModel } from "@models/customers/customer-multiple-address.model";

@Injectable()
export class CustomersService {
    constructor(private httpService: HttpService) { }

    async getAllCustomers(): Promise<ICustomerModel[]> {
        return await this.httpService.get("/customers/getAllCustomers");
    }

    async getCustomer(customerId: number): Promise<ICustomerModel> {
        const params = {
            customerId: customerId
        }
        return await this.httpService.get("/customers/getCustomer", params);
    }

    async getCustomerForGridView(customerId: number): Promise<ICustomerGridModel> {
        return await this.httpService.get("/customers/getCustomerForGridView", { customerId: customerId });
    }

    async getCustomersForInfiniteScroll(pageNumber: number, searchString: string): Promise<ICustomerDisplayListModel[]> {
        const params = {
            pageNumber,
            searchString
        }
        return await this.httpService.get("/customers/getCustomersForInfiniteScroll", params);
    }

    async getCustomerDiscounts(customerId: number): Promise<IDiscountModel[]> {
        return await this.httpService.get("/customers/getCustomerDiscounts", { customerId: customerId });
    }

    async getCustomerRebates(customerId: number): Promise<IRebateModel[]> {
        return await this.httpService.get("/customers/getCustomerRebates", { customerId: customerId });
    }

    async getCustomersForList(searchText: string, showBuildersOnly: boolean, showHomeOwnersOnly: boolean, showMultiAddresses: boolean , forCompanyId?: number ): Promise<ICustomerListModel[]> {
        const params = {
            searchText: searchText,
            showBuildersOnly: showBuildersOnly,
            showHomeOwnersOnly: showHomeOwnersOnly,
            showMultiAddresses: showMultiAddresses
        };

        if (forCompanyId)
            params["forCompanyId"] = forCompanyId;

        return await this.httpService.get("/customers/getCustomersForList", params);
    }

    async getCustomerForList(customerId: ICustomerDisplayListModel): Promise<ICustomerListModel> {
        return await this.httpService.get(`/customers/getCustomerForList?customerId=${customerId}`);
    }

    async getCustomersForGrid(): Promise<ICustomerModel[]> {
        return await this.httpService.get("/customers/getCustomersForList");
    }

    async addCustomer(customerModel: ICustomerModel): Promise<ICustomerModel> {
        
        return await this.httpService.post("/customers/addCustomer", customerModel);
    }

    async addMultipleAddress(multiAddressModel: ICustomerMultipleAddressModel, customerId: number): Promise<ICustomerMultipleAddressModel> {
        return await this.httpService.post(`/customers/addMultipleAddress?customerId=${customerId}`, multiAddressModel );
    }

    async deleteMultipleAddress(multipleAddressId: number): Promise<boolean> {
        return await this.httpService.delete("/customers/deleteMultipleAddress", { multipleAddressId: multipleAddressId });
    }

    async updateCustomer(customerModel: ICustomerModel): Promise<ICustomerModel> {
        return await this.httpService.post("/customers/updateCustomer", customerModel)
    }

    async deleteCustomer(customerId: number): Promise<boolean> {
        return await this.httpService.delete("/customers/deleteCustomer", { customerId: customerId });
    }

    async getBuildersForDropdown(): Promise<IDropdownModel[]> {
        return await this.httpService.get("/customers/getBuildersForDropdown");
    }

    async getCustomerJobHistory(customerId: number, searchString: string): Promise<ICustomerJobHistoryModel[]> {
        return await this.httpService.get("/customers/getCustomerJobHistory", { customerId: customerId, searchString: searchString });
    }

    async getCustomersDisplayListModels(searchString: string, showBuildersOnly: boolean, searchAllLinkedBuilders: boolean): Promise<ICustomerDisplayListModel[]> {
        return await this.httpService.get("/customers/getCustomersDisplayListModels",
            {
                searchString: searchString,
                showBuildersOnly: showBuildersOnly,
                searchAllLinkedBuilders: searchAllLinkedBuilders
            });
    }

    async getCustomerDisplayListModel(customerId: number): Promise<ICustomerDisplayListModel> {
        return await this.httpService.get(`/customers/getCustomerDisplayListModel?customerId=${customerId}`);
    }

    async sendInvitationEmail(customerId) {
        return await this.httpService.get("/customers/sendInvitationEmail", { customerId: customerId });
    }

    async sendServiceAgreement(customerId: number): Promise<boolean> {
        return await this.httpService.get("/customers/sendServiceAgreement", { customerId: customerId });
    }

    setAddress(customerModel, placeResult, addressType = 'default') {
    // Reset the relevant address fields based on the address type
    switch (addressType) {
        case 'billing':
            customerModel.billingAddress1 = '';
            customerModel.billingAddress2 = '';
            customerModel.billingCity = '';
            customerModel.billingState = '';
            customerModel.billingCountry = '';
            customerModel.billingZipcode = '';
            break;

        case 'shipping':
            customerModel.shippingAddress1 = '';
            customerModel.shippingAddress2 = '';
            customerModel.shippingCity = '';
            customerModel.shippingState = '';
            customerModel.shippingCountry = '';
            customerModel.shippingZipcode = '';
            break;

        default:
            customerModel.address1 = '';
            customerModel.address2 = '';
            customerModel.city = '';
            customerModel.state = '';
            customerModel.country = '';
            customerModel.zipcode = '';
            break;
    }

    // Process each component of the placeResult
    placeResult.address_components.forEach(adr => {
        adr.types.forEach(type => {
            let addressLine;
            switch (type) {
                case 'street_number':
                case 'route':
                    addressLine = adr.long_name + ' ';
                    if (addressType === 'billing') {
                        customerModel.billingAddress1 += addressLine;
                    } else if (addressType === 'shipping') {
                        customerModel.shippingAddress1 += addressLine;
                    } else {
                        customerModel.address1 += addressLine;
                    }
                    break;

                case 'locality':
                    if (addressType === 'billing') {
                        customerModel.billingCity = adr.long_name;
                    } else if (addressType === 'shipping') {
                        customerModel.shippingCity = adr.long_name;
                    } else {
                        customerModel.city = adr.long_name;
                    }
                    break;

                case 'administrative_area_level_1':
                    if (addressType === 'billing') {
                        customerModel.billingState = adr.short_name;
                    } else if (addressType === 'shipping') {
                        customerModel.shippingState = adr.short_name;
                    } else {
                        customerModel.state = adr.short_name;
                    }
                    break;

                case 'postal_code':
                    if (addressType === 'billing') {
                        customerModel.billingZipcode = adr.short_name;
                    } else if (addressType === 'shipping') {
                        customerModel.shippingZipcode = adr.short_name;
                    } else {
                        customerModel.zipcode = adr.short_name;
                    }
                    break;

                case 'country':
                    const country = (adr.short_name === 'CA' ? 'Canada' :
                        adr.short_name === 'IL' ? 'Israel' :
                            adr.short_name === 'MX' ? 'Mexico' :
                                adr.short_name);
                    if (addressType === 'billing') {
                        customerModel.billingCountry = country;
                    } else if (addressType === 'shipping') {
                        customerModel.shippingCountry = country;
                    } else {
                        customerModel.country = country;
                    }
                    break;
            }
        });
    });

    return customerModel;
}



    async getCustomersForListView(daysbetweenOutReach: number): Promise<ICustomerOutreachListViewModel[]> {
        return await this.httpService.get("/customers/GetCustomersForOutreachGrid", { daysBeforeNextOutreach: daysbetweenOutReach });
    }

    async updateGeocode(customerId: number, lat: number, lng: number): Promise<string> {
        const params = {
            lat,
            lng
        }
        return await this.httpService.patch(`/customers/updateGeocode?customerId=${customerId}`, params);
    }

    async updateGeocodeAddress(oneTimeAddressId: number, lat: number, lng: number): Promise<string> {
        const params = {
            lat,
            lng
        }
        return await this.httpService.patch(`/customers/updateGeocodeAddress?addressId=${oneTimeAddressId}`, params)
    }

    async createChildCompany(parentCustomerId: number, adminLogin: string, adminPassword: string): Promise<ICompanyModel> {
        const params = {
            parentCustomerId,
            adminLogin,
            adminPassword
        }

        return this.httpService.post('/companies/createChildCompany', params);
    }

    async checkDuplicateAddress(address1: string): Promise<ICustomerModel[]> {
        return this.httpService.get('/customers/CheckDuplicateCustomerAddresses', {
            address1: address1
        });
    }

    async checkCustomersFromChildLinkId(builderChildLinkId: number, homeOwnerChildLinkId: number): Promise<boolean> {
        return this.httpService.get(`/customers/checkCustomersFromChildLinkId?builderChildLinkId=${builderChildLinkId}&homeOwnerChildLinkId=${homeOwnerChildLinkId}`);
    }

    async emailOptOut(lid: string): Promise<boolean> {
        return this.httpService.patch(`/customers/emailOptOut?lid=${lid}`);
    }
}
