import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SlickDialogModule } from "@slick-components";
import { MissedClockOutDialogComponent } from "./missed-clock-out-dialog.component";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SlickDialogModule
	],
	declarations: [MissedClockOutDialogComponent],
	exports: [MissedClockOutDialogComponent]
})
export class MissedClockOutDialogModule{ }
