import { AddressModel } from "../latham-ordering/address.model";

export interface ICreditCardPaymentTypeModel {
    creditCardPaymentTypeId: number;
    customerId: number;
    addressId: number;
    processor: string;
    processorPaymentId: string;
    paymentDescription: string;
    paymentType: string;
    accountNumber: string;
    accountMasked: string;
    expDate: string;
    cvc: string;
    addressModel: AddressModel
    isDefault: boolean;
    isDeleted: boolean;
    displayText: string;
    accountNumberLastFour: string;   
}

export class CreditCardPaymentTypeModel implements ICreditCardPaymentTypeModel {
    constructor() {
        this.addressModel = new AddressModel();
        this.addressModel.active = true;
    }
    creditCardPaymentTypeId: number;
    customerId: number;
    addressId: number;
    processor: string;
    processorPaymentId: string;
    paymentDescription: string;
    paymentType: string;
    accountNumber: string;
    accountMasked: string;
    expDate: string;
    cvc: string;
    addressModel: AddressModel
    isDefault: boolean;
    isDeleted: boolean;
    displayText: string;
    accountNumberLastFour: string;
}