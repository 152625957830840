import * as moment from "moment";
import { GlobalsService } from "@services/utils/globals.service";
import { IAppointmentVisibility } from "./appointment-visibility.model";

export interface IAppointmentDimensions {
	blockTopPx: string;
	blockLeftPx: string;
	blockTop: number;
	blockLeft: number;
	travelWidthPx: string;
	travelWidth: number;
	appointmentWidthPx: string;
	appointmentWidth: number;
	resizeTravelPx: string;
	resizeTravel: number;
}

export class AppointmentDimensions implements IAppointmentDimensions {
	blockTopPx: string;
	blockLeftPx: string;
	blockTop: number;
	blockLeft: number;
	travelWidthPx: string;
	travelWidth: number;
	appointmentWidthPx: string;
	appointmentWidth: number;
	resizeTravelPx: string;
	resizeTravel: number;
	textPadding: boolean;

	static getAppointmentDimensions(resourceLine: HTMLDivElement, apptVisibility: IAppointmentVisibility): AppointmentDimensions {
		const appointmentDimensions = new AppointmentDimensions();

		const blockTop = resourceLine.offsetTop + 1;
		appointmentDimensions.blockTop = blockTop;
		if (GlobalsService.company.largeScheduleRows)
			appointmentDimensions.blockTop++;
		appointmentDimensions.blockTopPx = appointmentDimensions.blockTop + "px";

		const blockLeft = AppointmentDimensions.findPixelFromTimeSlot(resourceLine, moment(apptVisibility.appointmentBlockStart));

		let travelWidth = 0;
		const travelEndTime = moment(apptVisibility.appointmentBlockStart).add(apptVisibility.travelMinutes, 'minutes');
		if (apptVisibility.travelMinutes > 0)
			travelWidth = (this.findPixelFromTimeSlot(resourceLine, travelEndTime) - blockLeft);

		// Min schedule of 15 minutes
		if (apptVisibility.scheduledMinutes <= 0)
			apptVisibility.scheduledMinutes = 15;

		const appointmentEndTime = travelEndTime.add(apptVisibility.scheduledMinutes, "minutes");
		const appointmentWidth = this.findPixelFromTimeSlot(resourceLine, appointmentEndTime) - blockLeft - travelWidth;

		// adjust the margin and resource column
		appointmentDimensions.blockLeft = blockLeft + resourceLine.offsetLeft;
		appointmentDimensions.blockLeftPx = appointmentDimensions.blockLeft + 'px';
		appointmentDimensions.travelWidth = travelWidth;
		appointmentDimensions.travelWidthPx = appointmentDimensions.travelWidth + 'px';
		appointmentDimensions.appointmentWidth = appointmentWidth - 3;
		appointmentDimensions.textPadding = true;
		if (appointmentDimensions.appointmentWidth < 0) {
			appointmentDimensions.textPadding = false;
			appointmentDimensions.appointmentWidth = 3;
		}
		appointmentDimensions.appointmentWidthPx = appointmentDimensions.appointmentWidth + 'px';

		appointmentDimensions.resizeTravel = (travelWidth - 2);
		appointmentDimensions.resizeTravelPx = appointmentDimensions.resizeTravel + 'px';

		return appointmentDimensions;
	}

	private static findPixelFromTimeSlot(resourceLine: HTMLDivElement, time: moment.Moment): number {
		const timeSlotEl = <HTMLDivElement>resourceLine.querySelector(`[data-calchour="${time.format("HHmm")}"]`);
		if (!timeSlotEl) {
			// Ok, so the timeslots will only go to n-15 mins for the last time slot.  For example,
			// if it's 8am-5pm, the last time slot will actually be 4:45pm.  If we have an ending time slot of 5:00
			// it won't be found.  Hacky, but take 15 minutes off of the requested time and see if that time slot exists.
			// If it does, return that time slot + the width.
			time = time.subtract(15, "minutes");
			const lastTimeSlotEl = <HTMLDivElement>resourceLine.querySelector(`[data-calchour="${time.format("HHmm")}"]`);
			if (!lastTimeSlotEl)
				return 0;

			return lastTimeSlotEl.offsetLeft + lastTimeSlotEl.offsetWidth - 1;
		}

		return timeSlotEl.offsetLeft;
	}
}
