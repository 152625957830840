<section>
	<div class="d-flex row m-2" *ngIf="hideFileDrop == false">
		<div class="col-12 p-0">
			<slick-file-drop displayText="Drop files here or click to upload."
							 (filesChanged)="onNewFilesAdded($event)"></slick-file-drop>
		</div>
	</div>
	<div *ngIf="title" class="bold-header mb-2 mt-1" style="display: flex; align-items: center; padding-top: 5px !important;">
		<div style="flex-grow: 1;">{{title}}</div>
		<div class="gray-line"></div>
	</div>
	<span *ngIf="!((documents && documents.length > 0) || (photos && photos.length > 0))">No {{folderType}} uploaded</span>
	<div class="d-flex flex-column m-2">
		<div>
			<slick-file-list [files]="photos"
							 showCheckboxes="false"
							 [thumbnailSize]="175"
							 [showPhotoGallery]="true"
							 [allowDelete]="canDelete"
							 (onFileDelete)="onFileDeleted($event)"></slick-file-list>
		</div>
		<div>
			<slick-file-list [files]="documents"
							 showCheckboxes="false"
							 [thumbnailSize]="175"
							 [showPhotoGallery]="false"
							 [allowDelete]="canDelete"
							 (onFileDelete)="onFileDeleted($event)"
							 (onFileClicked)="onDocumentClicked($event)"></slick-file-list>
		</div>
	</div>
</section>
