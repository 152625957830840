import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { ICustomerListModel, ICustomerModel, IDiscountModel, IRebateModel, IDropdownModel, IJobModel, ICustomerDisplayListModel, ICustomerGridModel, ICustomerJobHistoryModel, IAddressModel, AddressModel, ICustomerOutreachListViewModel, ICompanyModel, CustomerOutreachModel, ICustomerOutreachModel } from "@models";
import { Observable, of } from "rxjs";
import { CustomerMultipleAddressModel, ICustomerMultipleAddressModel } from "@models/customers/customer-multiple-address.model";

@Injectable()
export class CustomerOutreachService {
    constructor(private httpService: HttpService) { }



    async deleteCustomerOutreach(crmModel: CustomerOutreachModel): Promise<boolean> {
        return await this.httpService.post("/customerOutreach/DeleteCustomerOutreach", crmModel);
    }

    async createChildCompany(parentCustomerId: number, adminLogin: string, adminPassword: string): Promise<ICompanyModel> {
        const params = {
            parentCustomerId,
            adminLogin,
            adminPassword
        }

        return this.httpService.post('/companies/createChildCompany', params);
    }

   
}
