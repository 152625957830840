<div *ngIf="emailerModel" id="slick-emailer_{{uuid}}" class="slick-emailer">
    <div class="row form-group">
        <div class="col-12">
            <div *ngIf="isSubmitted === true && emailerModel.toEmailAddresses.length === 0" class="errorWrapper showIcons"><div class="text-danger">Email must have at least one email address</div></div>
            <div class="input-group">
                <span class="input-group-text"><span class="fas fa-search"></span></span>
                <input class="form-control" #emailAddressAutocomplete [(ngModel)]="toEmailAddress" placeholder="Email Search..." autofocus />
            </div>
            <slick-auto-complete [inputRef]="emailAddressAutocomplete" textField="emailDisplay" [items]="toEmailAddressList" (onRefresh)="toAddressSearch($event)" (onSelect)="onSelectEmailAddress($event)" (onFreeformTextSelect)="onFreeformTextSelect($event)">
                <ng-template let-emailDisplay [slickAutoCompleteListItemTemplate]>
                    <div style='line-height: 1.1em; font-size: 12px;'>
                        {{emailDisplay.displayName}} [{{emailDisplay.emailAddress}}]
                    </div>
                </ng-template>
            </slick-auto-complete>

            <label>To:&nbsp;</label>
            <div *ngFor="let emailAddress of emailerModel.toEmailAddresses; let idx = index;" class="slick-emailer_email-address alert alert-secondary">{{emailAddress.displayName}} [{{emailAddress.emailAddress}}]<i (click)="deleteEmailAddress(idx)" class="fas fa-times-circle bg-white text-danger ms-1"></i></div>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-12">
            <label>Subject:</label>
            <input type="text" class="form-control" [(ngModel)]="emailerModel.subject" />
        </div>
    </div>

    <div class="row form-group">
        <div class="col-12">
            <label>Body:</label>
            <div #tinyMCEContainerRef></div>
        </div>
    </div>

    <div class="row form-group">
        <div class="col-12">
            <label>Attachments:</label>
            <slick-file-drop (filesChanged)="onAttachmentsChanged($event)"></slick-file-drop>
            <slick-file-list [(files)]="emailerModel.attachments"></slick-file-list>
        </div>
    </div>

    <div *ngIf="showButtons" class="row form-group">
        <div class="col-12 text-center">
            <button type="button" style="width: 120px; margin-right: 10px;" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-primary" (click)="onSendEmail()"><i class="far fa-envelope"></i>&nbsp;Send</button>
            <button type="button" style="width: 120px;" class="btn btn-outline-danger" (click)="onCancelEmail()"><i class="far fa-times"></i>&nbsp;Cancel</button>
        </div>
    </div>
</div>
