import { ICustomerModel } from "../customers/customer.model";
import { IBillingModel, BillingModel } from "./billing.model";
import { IShippingModel, ShippingModel } from "./shipping.model";
import { ICoverModel, CoverModel } from "./cover.model";
import { ILidModel, LidModel } from "./lid.model";
import { IDeckEdgeModel, DeckEdgeModel } from "./deck-edge.model";
import { ITubesModel, TubesModel } from "./tubes.model";
import { ITrackModel, TrackModel } from "./track.model";
import { IAddOnsModel, AddOnsModel } from "./addons.model";
import { IPreShippedModel, PreShippedModel } from "./preshipped.model";
import { IProrationModel, ProrationModel } from "./proration.model";
import { ISystemModel, SystemModel } from "./system.model";

export interface ILathamOrderSpecsModel {
    distributorCompanyId?: number;
	distributor: ICustomerModel;
    homeOwner: ICustomerModel;
    builder: ICustomerModel;
    billing: IBillingModel;
    shipping: IShippingModel;
    system: ISystemModel;
    proration: IProrationModel;
    cover: ICoverModel;
    lid: ILidModel;
    deckEdge: IDeckEdgeModel;
    tubes: ITubesModel;
    track: ITrackModel;
    addOns: IAddOnsModel;
    preShipped: IPreShippedModel;
    brandType: string;
    modelType: string;
}

export class LathamOrderSpecsModel implements ILathamOrderSpecsModel {

    constructor() {
        this.system = new SystemModel();
        this.proration = new ProrationModel();
        this.addOns = new AddOnsModel();
        this.cover = new CoverModel();
        this.track = new TrackModel();
        this.preShipped = new PreShippedModel();
        this.lid = new LidModel();
        this.tubes = new TubesModel();
        this.deckEdge = new DeckEdgeModel();
        this.billing = new BillingModel();
        this.shipping = new ShippingModel();
    }

    distributorCompanyId?: number;
    distributor: ICustomerModel;
    homeOwner: ICustomerModel;
    builder: ICustomerModel;
    billing: IBillingModel;
    shipping: IShippingModel;
    system: ISystemModel;
    proration: IProrationModel;
    cover: ICoverModel;
    lid: ILidModel;
    deckEdge: IDeckEdgeModel;
    tubes: ITubesModel;
    track: ITrackModel;
    addOns: IAddOnsModel;
    preShipped: IPreShippedModel;
    brandType: string;
    modelType: string;
}
