import { Component, ViewChild } from '@angular/core';
import { SlickDialogComponent } from "@slick-components";
import { InvoicesService, LookupService, UtilsService, LineItemsService, SleepService, GlobalsService } from '@services';
import { IInvoiceModel, ILineItemModel, InvoiceModel, LineItemTypes } from '@models';
import { sortBy } from "sort-by-typescript";

@Component({
	selector: 'split-invoice-dialog',
	templateUrl: './split-invoice-dialog.component.html',
	providers: [InvoicesService, LineItemsService]
})

export class SplitInvoiceDialogComponent {
	@ViewChild("splitInvoiceDialogRef") splitInvoiceDialogRef: SlickDialogComponent;

	resolve: any;
	spinnerStatus: string;
	isSubmitted: boolean;
	labelText: string;

	originalInvoice: IInvoiceModel;

	lineItems: ILineItemModel[] = [];

	constructor(private invoicesService: InvoicesService,
		private lineItemsService: LineItemsService) {
	}

	async openDialog(invoice: IInvoiceModel, labelText: string = 'invoice'): Promise<IInvoiceModel> {
		this.spinnerStatus = "reset";
		this.isSubmitted = false;
		this.labelText = labelText || 'invoice';

		return new Promise(async (resolve) => {
			this.originalInvoice = invoice;

			this.lineItems = this.originalInvoice.lineItems
				.filter(x => x.lineItemType === LineItemTypes.LineItem && !x.isBlankLineItem)

			this.lineItems.forEach(x => {
				x.toSplit = false;
				x.splitQuantity = x.quantity;
			});

			const systemLineItems = this.lineItems.filter(x => x.isSystemLineItem === true).sort(sortBy("displayOrder^"));
			const additionalLineItems = this.lineItems.filter(x => x.isSystemLineItem === false).sort(sortBy("displayOrder^"));

			this.lineItems = systemLineItems.concat(additionalLineItems);

			this.resolve = resolve;
			// Load items
			this.splitInvoiceDialogRef.showDialog();

		})
	}

	async onSave() {
		try {
			let lineItemsToSplit = this.lineItems.filter(x => x.toSplit === true);

			let isValid = true;

			for (let li of lineItemsToSplit) {
				// If we're only doing a partial split for this LI, keep the line item with reduced qty
				this.originalInvoice.lineItems = this.originalInvoice.lineItems.filter(x => x.uuid !== li.uuid);

				li.quantity = li.splitQuantity;
				li.isSystemLineItem = false;	// All should be MR items
				li.uuid = UtilsService.newGuid();
			};

			this.isSubmitted = true;
			this.spinnerStatus = "spin";

			const nextSeq = await this.invoicesService.getNextSeq(this.originalInvoice.invoiceGroupUuid);

			let newInvoice = new InvoiceModel();
			newInvoice.invoiceId = 0;
			newInvoice.customer = this.originalInvoice.customer;
			newInvoice.invoiceGroupUuid = this.originalInvoice.invoiceGroupUuid;
			newInvoice.invoiceGroupSeq = nextSeq;
			newInvoice.documentKey = UtilsService.newGuid();
			newInvoice.isQuote = this.originalInvoice.isQuote;
			newInvoice.isSalesOrder = this.originalInvoice.isSalesOrder;
			newInvoice.jobId = this.originalInvoice.jobId;
			newInvoice.invoiceNumber = `${this.originalInvoice.invoiceNumber} - ${nextSeq}`;
			newInvoice.purchaseOrderNumber = this.originalInvoice.purchaseOrderNumber;
			newInvoice.createdBy = GlobalsService.userInfo.userId;
			newInvoice.quoteDate = new Date();
			newInvoice.invoiceDate = new Date();
			newInvoice.invoiceStatusId = 1;
			newInvoice.paymentTermId = this.originalInvoice.customer.paymentTermId;
			newInvoice.lineItems = lineItemsToSplit;
			newInvoice.lineItems = this.lineItemsService.recalculateByInvoice(newInvoice);

			this.originalInvoice.lineItems = this.lineItemsService.recalculateByInvoice(this.originalInvoice);

			newInvoice = await this.invoicesService.splitInvoice(this.originalInvoice, newInvoice);

			this.spinnerStatus = "ok";

			await SleepService.sleep(1000);

			this.splitInvoiceDialogRef.hideDialog();

			this.resolve(newInvoice);
		}
		catch {
			this.spinnerStatus = "error";
		}
	}

	onCancel() {
		this.splitInvoiceDialogRef.hideDialog();

		this.resolve(null);
	}
}
