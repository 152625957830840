import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { InvoiceCustomFieldsComponent } from "./invoice-custom-fields.component";

@NgModule({
	imports: [
		CommonModule,
		FormsModule
	],
	declarations: [
		InvoiceCustomFieldsComponent
	],
	exports: [InvoiceCustomFieldsComponent]
})
export class InvoiceCustomFieldsModule {
}

export { InvoiceCustomFieldsComponent }