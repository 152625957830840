<ng-container *ngIf="shippingAddress" [ngTemplateOutlet]="renderAddressFields" [ngTemplateOutletContext]="{address: shippingAddress}" ></ng-container>
<ng-container *ngIf="address" [ngTemplateOutlet]="renderAddressFields" [ngTemplateOutletContext]="{address: address}"></ng-container>

<ng-template #renderAddressFields let-address="address">

    <div *ngIf="address">
        <div *ngIf="shippingAddress" class="d-flex">

            <div class="width-half customer-info form-group me-1">
                <label>Company Name</label>

                <input [disabled]="disabled"
                       type="text"
                       class="form-control"
                       [(ngModel)]="address.companyName" />
            </div>
        </div>
        <div class="d-flex">

            <div class="width-half customer-info form-group me-1">
                <label>First Name</label>

                <input [disabled]="disabled"
                       type="text"
                       class="form-control"
                       [(ngModel)]="address.firstName" />
            </div>
            <div class="width-half customer-info form-group">
                <label>Last Name</label>
                <input [disabled]="disabled"
                       type="text"
                       class="form-control"
                       [(ngModel)]="address.lastName" />
            </div>
        </div>

        <div class="customer-info form-group">
            <div class="width-full">
                <label>Address Search</label>
                <input [disabled]="disabled"
                       type="search"
                       class="form-control"
                       name="addressSearch"
                       [slick-google-address-search]
                       (onGoogleAddressSelect)="onGoogleMapAddressSelect($event)" />
            </div>
        </div>

        <div class=" form-group">
            <div class="width-full">
                <label>Address 1</label>
                <input [disabled]="disabled"
                       class="form-control"
                       [(ngModel)]="address.address1" />
                <form-error *ngIf="isSubmitted && !address.address1"> Address 1 is required</form-error>
            </div>
        </div>

        <div class=" form-group">
            <div class="width-full">
                <label>Address 2</label>
                <input [disabled]="disabled"
                       class="form-control"
                       [(ngModel)]="address.address2" />
            </div>
        </div>

        <div class="d-flex form-group">
            <div class="w-25 me-1">
                <label>City</label>
                <input [disabled]="disabled"
                       class="form-control"
                       [(ngModel)]="address.city" />
            </div>

            <div class="w-25 me-1">
                <div *ngIf="address.country !== 'Canada'">
                    <label>State</label>
                    <input [disabled]="disabled"
                           class="form-control"
                           [(ngModel)]="address.state" />
                </div>

                <div *ngIf="address.country === 'Canada'">
                    <label>Province</label>
                    <input [disabled]="disabled"
                           class="form-control"
                           [(ngModel)]="address.state" />
                </div>
            </div>

            <div class="w-25 me-1">
                <label>Zipcode</label>
                <input [disabled]="disabled"
                       class="form-control"
                       [(ngModel)]="address.zipcode" />
            </div>

            <div class="w-25">
                <label>Country</label>
                <slick-drop-down [disabled]="disabled"
                                 [(ngModel)]="address.country"
                                 [items]="countries"></slick-drop-down>
            </div>
        </div>
    </div>
</ng-template>

