import { Component, ElementRef, ViewChild } from "@angular/core";
import { IChatGroupAttendantModel, IChatGroupModel, IUserModel, NewAttendantModel, ChatGroupAttendantModel, ITeamModel, NewGroupModel, TeamModel, IUserLookupModel } from "@models";
import { ChatService, LookupService, GlobalsService, UtilsService, TeamsService } from "@services";
import { SlickDialogComponent } from "@slick-components";
import { sortBy } from "sort-by-typescript";

class UserAttendantModel {
	team: ITeamModel;
	user: IUserLookupModel;
	isChecked: boolean;
	isAllUsers: boolean = false;
	AllUsersSearchString: string;
}

@Component({
	selector: "chat-group-attendants",
	templateUrl: "./chat-group-attendants.component.html",
	styleUrls: ['./chat-group-attendants.component.scss'],
	providers: [TeamsService]
})
export class ChatGroupAttendantsComponent {
	@ViewChild("dialogRef") dialogRef: SlickDialogComponent;
	@ViewChild("groupNameDialogRef") groupNameDialogRef: SlickDialogComponent;
	@ViewChild("attendantSearchRef") attendantSearchRef: ElementRef;
	userAttendants: UserAttendantModel[];

	chatGroup: IChatGroupModel;
	searchString: string;
	resolve: any;
	chatGroupName: string;
	allUsers: UserAttendantModel[];

	constructor(private chatService: ChatService,
		private teamsService: TeamsService,
		private lookupService: LookupService) {

	}

	showDialog(chatGroup: IChatGroupModel): Promise<IChatGroupModel> {
		return new Promise<IChatGroupModel>((resolve) => {
			this.resolve = resolve;
			this.searchString = '';
			this.chatGroup = chatGroup;
			this.chatGroupName = chatGroup.groupName;
			this.userAttendants = this.lookupService.getUsers()
				.filter(user => (user.active && user.showOnChat && user.userId !== GlobalsService.userInfo.userId))
				.map(user => {
					const userAttendantModel = new UserAttendantModel();
					userAttendantModel.user = user;
					userAttendantModel.isChecked = (chatGroup.attendants.findIndex(att => att.userId === user.userId) >= 0)

					return userAttendantModel;
				})
				.sort(sortBy("^fullName"));
			
			let teams = this.lookupService.getTeams().map(team => {
				const userAttendantModel = new UserAttendantModel();
				userAttendantModel.team = team;
				userAttendantModel.isChecked = chatGroup.attendants.findIndex(att => att.teamId === team.teamId) >= 0;
				return userAttendantModel;
			});

			teams.forEach(x => this.userAttendants.unshift(x));
			//only add all users option if this is a new chat group
			if (chatGroup.id === -1) {
				const allUserOption = new UserAttendantModel();
				allUserOption.isAllUsers = true;
				allUserOption.AllUsersSearchString = "All Users"
				allUserOption.isChecked = chatGroup.attendants.findIndex(att => att.isAllUsers) >= 0;
				this.userAttendants.unshift(allUserOption)
			}

			this.allUsers = this.userAttendants;
			setTimeout(() => {
				(<HTMLInputElement>this.attendantSearchRef.nativeElement).focus();
			}, 500);
			this.dialogRef.showDialog();
		})
	}

	async onOk() {
		this.dialogRef.hideDialog();
		
        const checkedUserAttendants = this.allUsers
            .filter(attendant => attendant.isChecked)
			.map(attendant => {
                let newAttendantModel = new NewAttendantModel();
				if (attendant.user)
					newAttendantModel.userId = attendant.user.userId;
				else if (attendant.team)
					newAttendantModel.teamId = attendant.team.teamId;
				else if (attendant.isAllUsers) {
					newAttendantModel.isAllUsers = attendant.isAllUsers;
				}
                newAttendantModel.groupId = this.chatGroup.id;
				newAttendantModel.invitedById = GlobalsService.userInfo.userId;
				newAttendantModel.allUsersCompanyId = GlobalsService.company.companyId;

                return newAttendantModel;
			});
		// if no attendants were selected, return so we dont create a chat with no attendants
		if (checkedUserAttendants.length === 0) {
			return;
        }
		//first we need to check if a chat already exists with the checked users
		//loop through teams and check members 
		let addCurrentUser = true;
		this.lookupService.getTeams().forEach(x => {
			checkedUserAttendants.forEach(u => {
				if (u.teamId === x.teamId) {
					if (x.teamMembers.find(tm => tm.teamMemberUserId === GlobalsService.userInfo.userId) || x.teamLeadUserId === GlobalsService.userInfo.userId)
						addCurrentUser = false;
                }
            })
		});
		// Make sure this user is part of the attendants
		if (addCurrentUser && !checkedUserAttendants.find(x => x.isAllUsers)) {
			checkedUserAttendants.push({
				userId: GlobalsService.userInfo.userId,
				groupId: this.chatGroup.id,
				invitedById: GlobalsService.userInfo.userId,
				isAllUsers: false,
				teamId: null,
				allUsersCompanyId: GlobalsService.company.companyId
			});
		}
		let existingChatGroup = await this.chatService.getExistingChat(checkedUserAttendants);
		if (existingChatGroup && this.chatGroup.id === -1) {
			this.resolve(existingChatGroup);
			return;
		}
		if (this.chatGroup.id === -1) {
			const newGroupRequest = new NewGroupModel();
			newGroupRequest.uuid = this.chatGroup.uuid;
			newGroupRequest.groupName = this.chatGroup.groupName;
			newGroupRequest.attendants = checkedUserAttendants;
			newGroupRequest.documentKey = this.chatGroup.documentKey;
			
			this.chatGroup = await this.chatService.createNewGroup(newGroupRequest);
		}
		else {
			if(this.chatGroupName !== this.chatGroup.groupName)
				await this.chatService.saveChatGroupName(this.chatGroup.groupName, this.chatGroup.id);
			this.chatGroup = await this.chatService.updateGroupAttendants(this.chatGroup.id, checkedUserAttendants);

        }


		this.resolve(this.chatGroup);
	}

	onCancel() {
		this.dialogRef.hideDialog();
	}

	filterUsers() {
		this.userAttendants = this.allUsers;
		if (this.searchString) {
			const searchTerms = this.searchString.split(" ");
			searchTerms.forEach(term => {
				this.userAttendants = this.userAttendants.filter(x => {
					//this searches the full name, team name, and the all users option
					const attendantExists = (x.user?.fullName.toLowerCase().match(term.toLowerCase())) ||
						(x.team?.teamName.toLowerCase().match(term.toLowerCase()) ||
							(x.AllUsersSearchString?.toLowerCase().match(term.toLowerCase())));
					return (attendantExists)
				})
			})
		}
    }
}
