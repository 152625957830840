export interface IItemGroupingModel {
	itemGroupingId: number;
	sku: string;
	description: string;
	skusToInclude: string;
	forceToTop: boolean;
	includeTaxes: boolean;
	active: boolean;
}

export class ItemGroupingModel implements IItemGroupingModel {
	constructor() {
		this.itemGroupingId = 0;
		this.active = true;
	}

	itemGroupingId: number;
	sku: string;
	description: string;
	skusToInclude: string;
	forceToTop: boolean;
	includeTaxes: boolean;
	active: boolean;
}