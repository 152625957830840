import { Component, OnInit, ViewChild } from '@angular/core';
import { InvoicesService, LathamOrdersService, SleepService, HttpService, UtilsService, LineItemsService, GlobalsService } from '@services';
import { IDropdownModel, IInventoryLocationsListModel, IInvoiceModel, ILathamOrderModel, ILineItemModel, IPurchaseOrderModel, LineItemTypes } from '@models';

import { SlickDialogComponent, SlickToastService } from "@slick-components";
import *  as moment from 'moment';
import { InventoryStore } from '@stores';

@Component({
	selector: 'compare-latham-bill-dialog',
	templateUrl: './compare-latham-bill-dialog.component.html',
	styleUrls: ['./compare-latham-bill-dialog.component.css'],
	providers: [InvoicesService, LineItemsService, LathamOrdersService, SlickToastService]
})
export class CompareLathamBillDialogComponent {
	@ViewChild("dialogRef") dialogRef: SlickDialogComponent;
	res: any;
	loading: boolean = false;
	invoiceModel: IInvoiceModel;
	lathamOrder: ILathamOrderModel;
	lathamSalesOrder: ILathamOrderModel;
	purchaseOrder: IPurchaseOrderModel;
	spinnerStatus: string;
	poSpinnerStatus: string;
	shipDate: Date = (moment(localStorage.getItem("COMPARE_LATHAM_BILL_SHIP_DATE"), "MM/DD/YYYY").startOf("date").toDate() || moment().startOf("date").toDate())
	inventoryWarehouseId: number = parseInt(localStorage.getItem("COMPARE_LATHAM_BILL_WAREHOUSE_ID"));
	inventoryWarehouses: IDropdownModel[]
	locationId: number;
	shippingCharge: number;
	skeditLineItems: SkeditLineItem[] = [];
	lathamLineItems: LathamLineItem[] = [];

	displayLineItems: DisplayLineItem[] = [];

	invalidDate: boolean = false;
	submitted: boolean = false;
	constructor(private readonly httpService: HttpService,
		private readonly invoicesService: InvoicesService,
		private readonly inventoryStore: InventoryStore,
		private readonly lineItemsService: LineItemsService,
		private readonly lathamOrdersService: LathamOrdersService) {
		this.inventoryWarehouses = this.inventoryStore.getInventoryWarehousesForDropdown();
	}

	async openDialog(invoiceModel: IInvoiceModel): Promise<void> {
		this.spinnerStatus = "reset";
		this.poSpinnerStatus = "reset";
		this.shippingCharge = 0;

		return new Promise(async (res) => {
			this.res = res;
			this.loading = true;
			this.invoiceModel = invoiceModel;

			this.lathamSalesOrder = null;
			this.skeditLineItems = [];
			this.lathamLineItems = [];

			this.dialogRef.showDialog();

			//const json = localStorage.getItem("LATHAM_BILL_TEST");
			//if (json) {
			//	this.copyFromJSON(json);
			//	this.loading = false;
			//	return;
			//}

			await this.refresh();

			localStorage.setItem("LATHAM_BILL_TEST", JSON.stringify(this.displayLineItems));
			this.loading = false;
		})
	}

	async process() {
		this.spinnerStatus = "spin";

		try {
			const displayLineItemsJSON = JSON.stringify(this.displayLineItems);
			const params = {
				invoiceId: this.invoiceModel.invoiceId,
				displayLineItemsJSON: displayLineItemsJSON
			}
			await this.httpService.post(`/lathamBills/saveLathamBillHistory`, params);

			// Make a copy of the line items currently in use so we know which ones were added
			const originalLineItems: ILineItemModel[] = UtilsService.clone(this.invoiceModel.lineItems);

			const dto = new LathamBillCompareDTO();
			dto.skeditLineItems = [...this.skeditLineItems];
			dto.lathamLineItems = [...this.lathamLineItems];

			await this.httpService.post(`/lathamBills/fixLathamBill?invoiceId=${this.invoiceModel.invoiceId}`, dto);

			this.invoiceModel = await this.invoicesService.getInvoice(this.invoiceModel.invoiceId);			

			// Since Invoices are supposed to be locked as far as pricing goes, only update new line items,
			// Only update the pricing if we've added a new item with a $0 price amount
			if (this.invoiceModel.lineItems.findIndex(li => li.lineItemType === LineItemTypes.LineItem && li.price === 0) >= 0) {
				// The refreshPricing will refresh the array passed in as well as return it, so make a copy of the current line items
				const lineItemsCopy: ILineItemModel[] = UtilsService.clone(this.invoiceModel.lineItems);
				const updatedPricingLineItems = await this.lineItemsService.refreshPricing(lineItemsCopy, this.invoiceModel.customer);

				this.invoiceModel.lineItems.forEach(li => {
					// Check our original line items to see if this one was added by the fix tool
					if (li.lineItemType === LineItemTypes.LineItem && originalLineItems.findIndex(x => x.uuid === li.uuid) < 0) {
						const updatedPricingLineItem = updatedPricingLineItems.find(x => x.itemId === li.itemId);
						if (updatedPricingLineItem)
							li.price = updatedPricingLineItem.price;
					}
				});

				this.invoiceModel.lineItems = this.lineItemsService.recalculateByInvoice(this.invoiceModel);

				if (GlobalsService.company.useInventory && this.invoiceModel.useDefaultInventory)
					this.invoiceModel.inventoryLineItems = this.invoicesService.syncInventoryFromLineItems(this.invoiceModel);

				this.invoiceModel = await this.invoicesService.updateInvoice(this.invoiceModel);
				this.lathamOrder.invoice = this.invoiceModel;
			}

			await this.refresh();

			await SleepService.sleep(500);
			this.spinnerStatus = "Ok";
		}
		catch {
			this.spinnerStatus = "error";
		}
	}

	async generatePO() {
		this.poSpinnerStatus = "spin";
		this.submitted = true;
		this.invalidDate = false;
		let dateCheck = new Date(this.shipDate);
		if (isNaN(dateCheck.getTime())) {
			this.invalidDate = true;
			this.poSpinnerStatus = "error";
			return;
		}

		if (!this.inventoryWarehouseId) {
			return;
		}

		try {
			await SleepService.sleep(500);

			this.lathamOrder.invoice = this.invoiceModel;

			const dateIsoString = moment(this.shipDate).toISOString();

			this.purchaseOrder = await this.httpService.post(`purchaseOrders/addLPPPurchaseOrder?shipDate=${dateIsoString}&receivingWarehouseId=${this.inventoryWarehouseId}&shippingCharge=${this.shippingCharge}`, this.lathamOrder);

			this.poSpinnerStatus = "ok";
		}
		catch (err) {
			console.error(err);
			this.poSpinnerStatus = "error";
		}
	}

	onInventoryWarehouseSelect(inventoryWarehouse: IDropdownModel) {
		this.inventoryWarehouseId = inventoryWarehouse.id;
		localStorage.setItem("COMPARE_LATHAM_BILL_WAREHOUSE_ID", inventoryWarehouse.id.toString());
	}

	onShipDateChanged(date: Date) {
		localStorage.setItem("COMPARE_LATHAM_BILL_SHIP_DATE", moment(date).format('MM/DD/YYYY'));
	}

	onDialogClose() {
		this.res();
	}

	async refresh(): Promise<void> {

		// Need to get the latham order so we can get the latham order id
		this.purchaseOrder = await this.httpService.get(`/purchaseOrders/getPurchaseOrderByInvoiceId?invoiceId=${this.invoiceModel.invoiceId}`);

		if (!this.lathamSalesOrder) {
			this.lathamOrder = await this.lathamOrdersService.getLathamOrder(this.invoiceModel.lathamOrderId);
			this.lathamSalesOrder = await this.lathamOrdersService.getLathamOrderFromSalesOrder(this.lathamOrder.orderId);
		}

		this.skeditLineItems = this.invoiceModel.lineItems
			.filter(x => x.isGlobalItem && x.lineItemType === LineItemTypes.LineItem && x.sendToManufacturer === true)
			.map(li => {
				const newSkeditLineItem = new SkeditLineItem();
				newSkeditLineItem.lineItemUuid = li.uuid;
				newSkeditLineItem.sku = (li.sku ?? "").trim().toUpperCase();
				newSkeditLineItem.description = li.description;
				newSkeditLineItem.displayOrder = li.displayOrder;
				newSkeditLineItem.cost = li.cost;
				newSkeditLineItem.quantity = li.quantity;

				return newSkeditLineItem;
			});

		this.lathamLineItems = this.lathamSalesOrder.lathamSalesOrderLineItems
			.map(li => {
				const newLathamLineItem = new LathamLineItem();
				newLathamLineItem.sku = (li.sku ?? "").trim().toUpperCase();
				newLathamLineItem.description = li.description;
				newLathamLineItem.price = li.price;
				newLathamLineItem.quantity = li.quantity;
				newLathamLineItem.priceMatchFound = true;
				newLathamLineItem.skuMatchFound = true;

				return newLathamLineItem;
			});

		this.skeditLineItems.forEach(li => {
			const lathamLineItem = this.lathamLineItems.find(x => x.sku === li.sku);
			if (!lathamLineItem) {
				li.skuMatchFound = false;
				li.priceMatchFound = false;
				li.quantityMatchFound = false;
			}
			else {
				if (li.cost !== lathamLineItem.price)
					li.priceMatchFound = false;

				if (li.quantity !== lathamLineItem.quantity)
					li.quantityMatchFound = false;
			}
		})

		this.lathamLineItems.forEach(li => {
			const skeditLineItem = this.skeditLineItems.find(x => x.sku === li.sku);
			if (!skeditLineItem)
				li.skuMatchFound = false;
			else {
				if (li.price !== skeditLineItem.cost)
					li.priceMatchFound = false;

				if (li.quantity !== skeditLineItem.quantity)
					li.quantityMatchFound = false;
			}
		})

		this.recalc();
	}

	recalc() {
		this.displayLineItems = [];

		this.skeditLineItems
			.filter(skli => skli.skuMatchFound === true)
			.forEach(skli => {
				const displayLineItem = new DisplayLineItem();

				displayLineItem.skeditLineItem = skli;
				displayLineItem.lathamLineItem = this.lathamLineItems.find(x => x.sku == skli.sku);
				this.displayLineItems.push(displayLineItem);
			})

		this.skeditLineItems
			.filter(skli => skli.skuMatchFound === false)
			.forEach(skli => {
				const displayLineItem = new DisplayLineItem();

				displayLineItem.skeditLineItem = skli;
				displayLineItem.lathamLineItem = null;
				this.displayLineItems.push(displayLineItem);
			})

		this.lathamLineItems
			.filter(lali => lali.skuMatchFound === false)
			.forEach(lali => {
				lali.quantityMatchFound = false;
				lali.priceMatchFound = false;

				const emptyLathamLineItem = this.displayLineItems.find(x => x.lathamLineItem === null);
				if (emptyLathamLineItem) {
					emptyLathamLineItem.lathamLineItem = lali;
				}
				else {
					const displayLineItem = new DisplayLineItem();

					displayLineItem.skeditLineItem = null;
					displayLineItem.lathamLineItem = lali;
					this.displayLineItems.push(displayLineItem);
				}
			})
	}

	private copyFromJSON(displayLineItemsJSON: string) {
		const displayLineItems: DisplayLineItem[] = JSON.parse(displayLineItemsJSON);

		this.displayLineItems = displayLineItems.map(li => {
			let returnLineItem = new DisplayLineItem();
			returnLineItem.skeditLineItem = UtilsService.clone(li.skeditLineItem);
			returnLineItem.lathamLineItem = UtilsService.clone(li.lathamLineItem);

			return returnLineItem;
		})
		this.skeditLineItems = this.displayLineItems
			.filter(x => x.skeditLineItem != null)
			.map(x => x.skeditLineItem);
		this.lathamLineItems = this.displayLineItems
			.filter(x => x.lathamLineItem != null)
			.map(x => x.lathamLineItem);
	}
}

class SkeditLineItem {
	constructor() {
		this.skuMatchFound = true;
		this.priceMatchFound = true;
		this.quantityMatchFound = true;
	}

	lineItemUuid: string;
	sku: string;
	description: string;
	displayOrder: number;
	cost: number;
	quantity: number;
	skuMatchFound: boolean;
	priceMatchFound: boolean;
	quantityMatchFound: boolean;
}

class LathamLineItem {
	constructor() {
		this.skuMatchFound = true;
		this.priceMatchFound = true;
		this.quantityMatchFound = true;
	}

	sku: string;
	description: string;
	price: number;
	quantity: number;
	skuMatchFound: boolean;
	priceMatchFound: boolean;
	quantityMatchFound: boolean;
}

class DisplayLineItem {
	skeditLineItem: SkeditLineItem;
	lathamLineItem: LathamLineItem;
	get classSkeditSku(): string {
		if (!this.skeditLineItem)
			return '';

		if ((this.skeditLineItem?.skuMatchFound ?? false) === true)
			return "bg-success text-white";
		else
			return "bg-danger text-white";
	}

	get classSkeditQty(): string {
		if (!this.skeditLineItem)
			return '';

		if ((this.skeditLineItem?.quantityMatchFound ?? false) === true)
			return "bg-success text-white";
		else
			return "bg-danger text-white";
	}

	get classSkeditCost(): string {
		if (!this.skeditLineItem)
			return '';

		if ((this.skeditLineItem?.priceMatchFound ?? false) === true)
			return "bg-success text-white";
		else
			return "bg-danger text-white";
	}

	get classLathamSku(): string {
		if (!this.lathamLineItem)
			return '';

		if ((this.lathamLineItem?.skuMatchFound ?? false) === true)
			return "bg-success text-white";
		else
			return "bg-danger text-white";
	}

	get classLathamQty(): string {
		if (!this.lathamLineItem)
			return '';

		if ((this.lathamLineItem?.quantityMatchFound ?? false) === true)
			return "bg-success text-white";
		else
			return "bg-danger text-white";
	}

	get classLathamPrice(): string {
		if (!this.lathamLineItem)
			return '';

		if ((this.lathamLineItem?.priceMatchFound ?? false) === true)
			return "bg-success text-white";
		else
			return "bg-danger text-white";
	}
}

class LathamBillCompareDTO {
	skeditLineItems: SkeditLineItem[];
	lathamLineItems: LathamLineItem[];
}