export interface INewAttendantModel {
    userId?: number;
    groupId: number;
    invitedById: number;
    teamId?: number;
    isAllUsers: boolean;
    allUsersCompanyId: number;
}

export class NewAttendantModel implements  INewAttendantModel {
    userId?: number;
    groupId: number;
    invitedById: number;
    teamId?: number;
    isAllUsers: boolean;
    allUsersCompanyId: number;

}