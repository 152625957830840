import { Component, Input, ViewChild, OnChanges, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import { SlickDialogComponent } from "../slick-dialog/slick-dialog.component";

export enum SlickConfirmDialogResults { Ok, Cancel }

@Component({
	selector: 'slick-confirm-dialog',
	templateUrl: 'slick-confirm-dialog.component.html'
})
export class SlickConfirmDialogComponent implements OnChanges {
	@Input() width = 300;
	@Input() okButtonText = "Ok";
	@Input() cancelButtonText = "Cancel";
	@Input() showOkButton = true;
	@Input() showCancelButton = true;

	@ViewChild("dialogRef") dialogRef: SlickDialogComponent;

	isVisible = false;

	private dialogResolve: any;

	constructor(private changeDetector: ChangeDetectorRef) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.showOkButton)
			this.showOkButton = (this.showOkButton.toString().toLowerCase() === 'false') ? false : true;

		if (changes.showCancelButton)
			this.showCancelButton = (this.showCancelButton.toString().toLowerCase() === 'false') ? false : true;
	}

	confirm(): Promise<SlickConfirmDialogResults> {
		this.isVisible = true;

		this.changeDetector.detectChanges();

		return new Promise<any>((resolve, reject) => {
			this.dialogRef.showDialog()
			this.dialogResolve = resolve;
		});
	}

	onOk() {
		this.dialogRef.hideDialog()
		this.dialogResolve(SlickConfirmDialogResults.Ok);
	}

	onCancel() {
		this.dialogRef.hideDialog()
		this.dialogResolve(SlickConfirmDialogResults.Cancel);
	}

	onClose() {
		this.dialogResolve(SlickConfirmDialogResults.Cancel);
	}
}