import { Injectable } from '@angular/core';
import { HttpService } from './utils/http.service';
import { IVendorGridModel, IVendorListModel, IVendorModel } from '@models';

@Injectable()
export class VendorsService {
    constructor(private httpService: HttpService) {
    }

	getVendorForGrid(vendorId: number): Promise<IVendorGridModel> {
		return this.httpService.get('/vendors/getVendorForGrid', { vendorId });
	}

	getVendorsForList(searchString: string): Promise<IVendorListModel[]> {
		return this.httpService.get('/vendors/getVendorsForList', { searchString });
	}

	getVendor(vendorId: number): Promise<IVendorModel> {
		return this.httpService.get('/vendors/getVendor', { vendorId });
	}

	addVendor(vendor: IVendorModel): Promise<IVendorModel> {
		return this.httpService.post('/vendors/addVendor', vendor);
	}

	updateVendor(vendor: IVendorModel): Promise<IVendorModel> {
		return this.httpService.post('/vendors/updateVendor', vendor);
	}

	deleteVendor(vendorId: number): Promise<boolean> {
		return this.httpService.delete('/vendors/deleteVendor', { vendorId });
	}
}
