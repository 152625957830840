import { Component, forwardRef, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, HostListener } from "@angular/core";
import { SlickUtilsService } from "../utils/slick-utils.service";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import moment from "moment";


class DayModel {
	date: Date;
	isSelected: boolean;
	isToday: boolean;
	isInCurrentMonth: boolean;
	isActive: boolean;
}

class WeekModel {
	days: DayModel[];
}

class MonthModel {
	monthDisplay: string;
	weeks: WeekModel[]
}

@Component({
	selector: 'slick-calendar',
	templateUrl: 'slick-calendar.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SlickCalendarComponent),
			multi: true,
		}]
})
export class SlickCalendarComponent implements ControlValueAccessor, OnInit, OnChanges {
	@Input() condensed: boolean = false;
	@Input() monthsToShow: number = 1;
	@Input() monthsToIncrement: number = 1;
	@Input() inactiveDates: Date[] = [];
	@Input() inactiveWeekends: boolean = false;
	@Input() showDaysNotInMonth: boolean = false;
	@Input() multiSelect: boolean = false;
	@Input() multiSelectDates: Date[];
	@Output() multiSelectDatesChange: EventEmitter<Date[]> = new EventEmitter<Date[]>();
	@Output() onDateSelect: EventEmitter<Date> = new EventEmitter<Date>();
	@Output() onDateChange: EventEmitter<Date> = new EventEmitter<Date>();
	@Output() onMonthChange: EventEmitter<Date> = new EventEmitter<Date>();
	@Output() onMultiDateChange: EventEmitter<Date[]> = new EventEmitter<Date[]>();

	@HostListener('click', ['$event', '$event.target'])
	onClick(event: MouseEvent, targetElement: HTMLElement): void {
		if (!targetElement || SlickUtilsService.checkParentClassExists(targetElement, "slickCalendar_month"))
			return;
	}

	uuid: string;
	selectedDate: Date = new Date();
	visibleDate: Date = new Date();
	months: MonthModel[];
	minWidth: number;

	constructor() {
		this.uuid = SlickUtilsService.newGuid();
	}

	ngOnInit() {
		this.recalc();
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (changes.condensed)
			this.condensed = (this.condensed || 'false').toString().toLowerCase() === 'true' ? true : false;

		if (changes.inactiveWeekends)
			this.inactiveWeekends = (this.inactiveWeekends || 'true').toString().toLowerCase() === 'false' ? false : true;

		if (changes.showDaysNotInMonth)
			this.showDaysNotInMonth = (this.showDaysNotInMonth || 'false').toString().toLowerCase() === 'true' ? true : false;

		if (changes.multiSelect)
			this.multiSelect = (this.multiSelect || 'false').toString().toLowerCase() === 'true' ? true : false;

		const widthPerCalendar = (this.condensed) ? 180 : 250;
		this.minWidth = this.monthsToShow * widthPerCalendar;

		this.recalc();

	}

	propagateChange = (_: any) => { };

	// this is the initial value set to the component
	writeValue(obj: any) {
		if (obj) {
			if (moment(obj).isValid()) {
				this.selectedDate = obj;
				this.visibleDate = obj;

				if (this.months) {

					// Say we have 3 months showing and they select a date on the 3rd month
					// Recalc will re-draw the 3rd month as the first month instead of just 
					// higlighting the date on the 3rd month.  Highlight instead.
					let dateFound = false;
					for (let month of this.months) {
						for (let week of month.weeks) {
							for (let day of week.days) {
								day.isSelected = false;
								if (day.isInCurrentMonth === true && moment(day.date).isSame(this.selectedDate, "date")) {
									day.isSelected = true;
									dateFound = true;
								}
							}
						}
					}

					// But if they've 
					if (dateFound === false)
						this.recalc();
				}
				else {
					this.recalc();
				}
			}
			else {
				this.selectedDate = null;
			}
		}
		else {
			if (!this.selectedDate && this.onDateChange)
				this.onDateChange.emit(null);
			this.selectedDate = null;
		}
	}

	// registers 'fn' that will be fired when changes are made
	// this is how we emit the changes back to the form
	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	// not used, used for touch input
	registerOnTouched() { }

	prevMonth() {
		this.visibleDate = moment(this.visibleDate).subtract(this.monthsToIncrement, "months").toDate();
		this.recalc();

		if (this.onMonthChange) {
			let monthStart = moment(this.visibleDate).startOf("month");
			if (this.showDaysNotInMonth === true)
				monthStart = monthStart.startOf("week");

			this.onMonthChange.emit(monthStart.toDate());
		}
	}

	nextMonth() {
		this.visibleDate = moment(this.visibleDate).add(this.monthsToIncrement, "months").toDate();
		this.recalc();

		if (this.onMonthChange) {
			let monthStart = moment(this.visibleDate).startOf("month");
			if (this.showDaysNotInMonth === true)
				monthStart = monthStart.startOf("week");

			this.onMonthChange.emit(monthStart.toDate());
		}
	}

	selectMonth(monthIdx) {
		const month = this.months[monthIdx];
		let visibleDate = moment(this.visibleDate);
		let dateString = month + visibleDate.format(" 1, YYYY");

		this.visibleDate = moment(dateString, "MMMM DD, YYYY").toDate();
		this.recalc();
	}

	setDate(dayModel: DayModel) {
		if (this.showDaysNotInMonth === false && dayModel.isInCurrentMonth === false)
			return;

		if (dayModel.isActive === false)
			return;

		if (this.multiSelect === false) {
			this.selectedDate = dayModel.date;
			this.visibleDate = dayModel.date;

			this.propagateChange(this.selectedDate);

			if (this.onDateChange)
				this.onDateChange.emit(this.selectedDate);

			if (this.onDateSelect)
				this.onDateSelect.emit(this.selectedDate);

			// Say we have 3 months showing and they select a date on the 3rd month
			// Recalc will re-draw the 3rd month as the first month instead of just 
			// higlighting the date on the 3rd month.  Highlight instead.
			let dateFound = false;
			for (let month of this.months) {
				for (let week of month.weeks) {
					for (let day of week.days) {
						day.isSelected = false;
						if (day.isInCurrentMonth === true && moment(day.date).isSame(this.selectedDate, "date")) {
							day.isSelected = true;
							dateFound = true;
						}
					}
				}
			}

			// But if they've 
			if (dateFound === false)
				this.recalc();
		}
		else {
			// This will be reversed since we haven't re-calced the dates yet.
			if (!dayModel.isSelected)
				this.multiSelectDates.push(dayModel.date);
			else {
				let dateIdx = this.multiSelectDates.findIndex(d => moment(d).startOf("date").isSame(moment(dayModel.date).startOf("date")));
				this.multiSelectDates.splice(dateIdx, 1);
			}

			if (this.onMultiDateChange)
				this.onMultiDateChange.emit(this.multiSelectDates)

			for (let month of this.months) {
				for (let week of month.weeks) {
					for (let day of week.days) {
						day.isSelected = false;
						if (day.isInCurrentMonth === true && (this.multiSelectDates.findIndex(x => moment(x).isSame(moment(day.date), "date")) >= 0)) {
							day.isSelected = true;
						}
					}
				}
			}

		}
	}

	private recalc() {
		this.months = [];

		for (let monthCount = 0; monthCount < this.monthsToShow; monthCount++) {
			let currentDate = moment(this.visibleDate).startOf("month");
			currentDate = moment(currentDate).add(monthCount, "months")
			currentDate = moment(currentDate).startOf("week").startOf("date");

			const month = new MonthModel();
			month.weeks = [];

			// CurrentDate is most likely going to be the month before, so add 15 days to get to the middle of the current month
			const monthToDisplay = moment(currentDate).add(15, "days");
			month.monthDisplay = (this.condensed === true) ? monthToDisplay.format("MMM, YY") : monthToDisplay.format("MMMM, YYYY");

			for (let weekCount = 0; weekCount < 6; weekCount++) {
				const week = new WeekModel();
				week.days = [];

				for (let dayCount = 0; dayCount < 7; dayCount++) {
					const day = new DayModel();
					day.date = currentDate.toDate();
					day.isToday = moment().isSame(currentDate, "date");
					if (this.multiSelect && this.multiSelectDates)
						day.isSelected = (this.multiSelectDates.findIndex(x => moment(x).isSame(moment(day.date), "date")) >= 0);
					else
						day.isSelected = moment(this.selectedDate).isSame(currentDate, "date");

					day.isInCurrentMonth = (currentDate.month() === monthToDisplay.month());

					day.isActive = true;

					if (this.inactiveWeekends && currentDate.day() === 6 || currentDate.day() === 7)
						day.isActive = false;

					if (this.inactiveDates && this.inactiveDates.findIndex(x => moment(x).isSame(currentDate, "date")) >= 0)
						day.isActive = false;

					if (this.showDaysNotInMonth === false && day.isInCurrentMonth === false)
						day.isActive = false;

					// It's possible for the day to be in the month display, but hidden, but active
					if (day.isActive === false)
						day.isSelected = false;

					if (day.isInCurrentMonth === false)
						day.isSelected = false;

					week.days.push(day);

					currentDate = moment(currentDate).add(1, "days");
				}

				month.weeks.push(week);
			}

			this.months.push(month);
		}
	}

}