<div #containerRef id="slick-file-list_{{uuid}}" class="slick-file-list">
	<slick-file-list-item *ngFor="let file of files; let idx = index" 
						  [file]="file" 
						  [allowDelete]="allowDelete" 
						  [showPhotoGallery]="showPhotoGallery" 
						  [showCheckbox]="showCheckboxes" 
						  [thumbnailSize]="thumbnailSize" 
						  [confirmDelete]="confirmDelete"
						  (checkChanged)="onCheckChanged($event, idx)" 
						  (fileClicked)="fileClicked($event, idx)" 
						  (fileChanged)="onFileChange($event, idx)" 
						  (fileDelete)="onFileDelete($event, idx)"></slick-file-list-item>
</div>

<slick-photo-gallery #photoGalleryRef *ngIf="showPhotoGallery === true" [files]="files"></slick-photo-gallery>

