import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { SlickPopoverComponent } from "./slick-popover.component";

@NgModule({
	imports: [CommonModule],
	declarations: [SlickPopoverComponent],
	exports: [CommonModule, SlickPopoverComponent]
})
export class SlickPopoverModule { }
export { SlickPopoverComponent }
