import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { SlickGridColumnModel, SlickGridColumnFilterTypes, SlickGridColumnSortDirection } from "./slick-grid-column.model";
import { ISlickGridFavoriteModel, SlickGridFavoriteModel } from "./slick-grid-favorite.model";
import { ISlickGridRequestModel, SlickGridRequestModel } from "./slick-grid-request.model";
import { ISlickGridResponseModel, SlickGridResponseModel } from "./slick-grid-response.model";
import { SlickGridComponent } from "./slick-grid.component";
import { SlickGridColumnTemplateDirective } from "./slick-grid-column-template.directive";
import { ISlickGridOptions, SlickGridOptions } from "./slick-grid.options";
import { SlickGridService } from "./slick-grid.service";
import { SlickConfirmDialogModule } from "../slick-confirm-dialog/slick-confirm-dialog.module";
import { SlickDialogModule } from "../slick-dialog/slick-dialog.module";
import { SlickDropDownModule } from "../slick-drop-down/slick-drop-down.module";
import { SlickDropListModule } from "../slick-drop-list/slick-drop-list.module";
import { SlickPagingModule } from "../slick-paging/slick-paging.module";
import { SlickPickListModule } from "../slick-pick-list/slick-pick-list.module";
import { SlickPopoverModule } from "../slick-popover/slick-popover.module";
import { SlickGridSanitizeHtmlPipe } from "./slick-grid-sanitize.pipe";
import { SlickFunctionLockService } from "../utils/slick-function-lock.service";

@NgModule({
	imports: [CommonModule, SlickConfirmDialogModule, SlickPickListModule, SlickPopoverModule, SlickDialogModule, SlickDropDownModule, SlickDropListModule, SlickPagingModule, HttpClientModule],
	declarations: [SlickGridComponent,  SlickGridColumnTemplateDirective, SlickGridSanitizeHtmlPipe],
	exports: [SlickGridComponent, SlickGridColumnTemplateDirective, SlickPickListModule, SlickDialogModule, SlickDropDownModule],
	providers: [SlickGridService, SlickFunctionLockService, SlickGridSanitizeHtmlPipe]
})
export class SlickGridModule {
}
export { SlickGridColumnModel, SlickGridColumnFilterTypes, SlickGridColumnSortDirection }
export { ISlickGridFavoriteModel, SlickGridFavoriteModel }
export { ISlickGridRequestModel, SlickGridRequestModel }
export { ISlickGridResponseModel, SlickGridResponseModel }
export { SlickGridComponent }
export { SlickGridColumnTemplateDirective }
export { ISlickGridOptions, SlickGridOptions }

