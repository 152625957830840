import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { INotificationModel, ICompanyModel, INewCompanySetupModel } from "@models";
import { SlickGridRequestModel } from "@slick-components";

@Injectable()
export class CompanyService {
	constructor(private httpService: HttpService) { }

	async getCompany(companyId: number): Promise<ICompanyModel> {
		return await this.httpService.get("/companies/getCompany", companyId);
	}

	async getAllCompaniesForGrid(requestModel: SlickGridRequestModel): Promise<ICompanyModel> {
		return await this.httpService.get("/companies/getAllCompaniesForGrid", requestModel);
	}

	async getAllCompanies(): Promise<ICompanyModel[]> {
		return await this.httpService.get("/companies/getAllCompanies");
	}

	async hasChildCompanies(companyId: number): Promise<ICompanyModel> {
		return await this.httpService.get("/companies/hasChildCompanies", companyId);
	}

	async updateCompany(companyModel: ICompanyModel): Promise<ICompanyModel> {
		return await this.httpService.post("/companies/updateCompany", companyModel);
	}

	async getCompanyName(companyId: number): Promise<ICompanyModel> {
		return await this.httpService.get("/companies/getCompanyName", companyId);
	}

	async createNewCompany(newCompany: INewCompanySetupModel): Promise<INewCompanySetupModel> {
		return await this.httpService.post("/companies/createNewCompany", newCompany);
	}

	async updateCompanyLogo(enc64Image: string): Promise<boolean> {
		return await this.httpService.post("/companies/updateCompanyLogo", JSON.stringify(enc64Image));
	}

	async updateCompanyAppointmentReminderSendTime(sendTime: string): Promise<boolean> {
		return await this.httpService.post("/companies/updateCompanyAppointmentReminderSendTime", JSON.stringify(sendTime));
	}
}
