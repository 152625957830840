import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlickButtonSpinnerComponent } from "./slick-button-spinner.component";

@NgModule({
	imports: [CommonModule],
	declarations: [SlickButtonSpinnerComponent],
	exports: [SlickButtonSpinnerComponent]
})
export class SlickButtonSpinnerModule { }

export { SlickButtonSpinnerComponent }