import { BrowserModule } from '@angular/platform-browser';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from "@angular/forms";
import { environment } from '../environments/environment';
import { RouterModule, PreloadAllModules } from "@angular/router";
import { ServicesModule, GlobalsService, LookupService, LoginService, ScheduleMouseEventsService, ScheduleDisplayService, InventoryService } from "@services";
import { BamboraService, PayTraceService, USAePayService, ZiftService } from "@services/credit-card-processing";
import { ChatStore, InventoryStore, KeepAliveStore, OfflineStore, SignalRMessageStore, TextChatStore, DevLogsStore } from "@stores";
import { HttpErrorDialogComponent } from "./http-error-dialog.component";
import { SlickButtonSpinnerModule, SlickDialogModule, SlickPopoverModule } from "@slick-components";
import { SignalRMessageHubService, ChatHubService, TextChatHubService } from "@signalr";
import { AtatusErrorHandler } from './atatus.handler';
import { AppComponent } from './app.component';
import { AppRoutes } from "./routing";
import { SqliteModule } from "@sqlite"
import { VersionReleaseDialogModule } from "@shared-components/version-release-dialog";
import { MissedClockOutDialogModule } from "@shared-components/missed-clock-out-dialog/missed-clock-out-dialog.module";
import { MissedClockInDialogModule } from "@shared-components/missed-clock-in-dialog";

// Services
import { ThemeService } from "@components/theme.service";
// Page Not Found
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
// Layout
import { LayoutModule } from "./layout/layout.module";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './http.interceptor';

// Chat
import { ChatModule } from "@app/chat/chat.module";

// stores
import { StoresModule } from "@stores"
import { DevTraceService } from '@services/utils/dev-trace.service';

@NgModule({
	imports: [
		HttpClientModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ServicesModule,
		VersionReleaseDialogModule,
		MissedClockOutDialogModule,
		MissedClockInDialogModule,
		SlickButtonSpinnerModule, SlickDialogModule, SlickPopoverModule,
		LayoutModule,
		RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloadAllModules }),
		ChatModule,
		StoresModule,
		SqliteModule,
		VersionReleaseDialogModule
		
	],
	declarations: [
		AppComponent,
		PageNotFoundComponent,
		HttpErrorDialogComponent
	],
	providers: [
		DevTraceService,
		ZiftService,
		USAePayService,
		BamboraService,
		PayTraceService,
		SignalRMessageHubService,
		SignalRMessageStore,
		ChatHubService,
		TextChatHubService,
		ScheduleMouseEventsService,
		ScheduleDisplayService,
		LoginService,
		KeepAliveStore,
		OfflineStore,
		DevLogsStore,
		GlobalsService, ThemeService, ChatStore, InventoryStore, TextChatStore, InventoryService,
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{
			provide: ErrorHandler,
			useClass: AtatusErrorHandler
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: init,
			deps: [GlobalsService, LookupService, ThemeService, SignalRMessageStore, ChatStore, InventoryStore],
			multi: true
		}],
	bootstrap: [AppComponent]
})
export class AppModule { }

export function init(globalsService: GlobalsService, lookupService: LookupService, themeService: ThemeService, signalRMessageStore: SignalRMessageStore, chatStore: ChatStore, inventoryStore: InventoryStore) {
	return async () => {
		if (environment.staging === true)
			globalsService.setStaging();
		else if (environment.rc === true)
			globalsService.setReleaseCandidate();
		else if (environment.production === false)
			globalsService.setDebug();

		GlobalsService.isOnline = true;
		GlobalsService.isDesktop = true;
		GlobalsService.isAppMobile = false;
		GlobalsService.isAndroid = false;
		GlobalsService.isiOS = false;

		GlobalsService.isOnlinePayments = false;

		if (window.location.host === 'payments.skedit.com' ||
			window.location.host === 'devpayments.skedit.com' ||
			window.location.host === 'stagingpayments.skedit.com') {
			GlobalsService.isLoggedIn = false;
			GlobalsService.isOnlinePayments = true;
			await themeService.loadTheme("default")
			return;
		}

		if (window.location.href.indexOf("/quickbooksOAuthResponse") >= 0 ||
			window.location.href.indexOf('/resetpassword') >= 0 ||
			window.location.href.indexOf('/acceptLathamQuote') >= 0 ||
			window.location.href.indexOf('/emailOptOut') >= 0 ||
			window.location.href.indexOf('/acceptEstimate') >= 0 ||
			window.location.href.indexOf('/acceptBatchEstimate') >= 0 ||
			window.location.href.indexOf('/acceptServiceAgreement') >= 0) {
			GlobalsService.isLoggedIn = false;
			await themeService.loadTheme("default")
			return;
		}

		const isLoggedIn = await globalsService.isLoggedIn();

		if (isLoggedIn === false &&
			((window.location.href.indexOf("/login") < 0) &&
			(window.location.href.toLowerCase().indexOf('/newcompanysetup') < 0)) &&
			(window.location.href.toLowerCase().indexOf('/acceptestimate') < 0)) {
			window.location.href = "/#/login";
		}

		if (!isLoggedIn) {
			await themeService.loadTheme("default")
			return;
		}

		await Promise.all([globalsService.initDesktop(), lookupService.preloadLookups()]);

		await Promise.all([
			themeService.loadTheme(),
			signalRMessageStore.init(),
			chatStore.init(),
			inventoryStore.init()])
	}
}

