import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SlickEmailViewerComponent } from "./slick-email-viewer.component";
import { SlickEmailViewerDialogComponent } from "./slick-email-viewer-dialog.component";
import { SlickDialogModule } from "../slick-dialog/slick-dialog.module";
import { SlickFileListModule } from "../slick-file-list/slick-file-list.module";

@NgModule({
	imports: [CommonModule, FormsModule, SlickDialogModule, SlickFileListModule],
	declarations: [SlickEmailViewerComponent, SlickEmailViewerDialogComponent],
	exports: [CommonModule, FormsModule, SlickEmailViewerComponent, SlickEmailViewerDialogComponent]
})
export class SlickEmailViewerModule { }
export { SlickEmailViewerComponent }
export { SlickEmailViewerDialogComponent }
export { ISlickEmailerModel, SlickEmailerModel } from "../slick-emailer/slick-emailer.model";
export { ISlickEmailerAddressModel, SlickEmailerAddressModel } from "../slick-emailer/slick-emailer-address.model";
