import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlickSliderComponent } from "./slick-slider.component";

@NgModule({
	imports: [CommonModule],
	exports: [SlickSliderComponent, CommonModule],
	declarations: [SlickSliderComponent]
})
export class SlickSliderModule { }

export { SlickSliderComponent }
export * from "./slick-slider-tick.model";