import { SlickGridColumnSortDirection } from "./slick-grid-column.model";
import { HttpHeaders } from "@angular/common/http";

export interface ISlickGridRequestSearchModel {
	columnName: string,
	searchValueId: string,
	searchValueText: string
}

export interface ISlickGridRequestSortModel {
	columnName: string,
	sortDirection: string
}

export interface ISlickGridRequestModel {
	url: string;
	httpHeaders: HttpHeaders;
	additionalParams: any;
	page: number;
	recordsPerPage: number;
	sortColumn: string;
	sortDirection: SlickGridColumnSortDirection;
	searchString: string;
	columnSearchValues: ISlickGridRequestSearchModel[];
}

export class SlickGridRequestModel implements ISlickGridRequestModel {
	url: string;
	httpHeaders: HttpHeaders;
	additionalParams: any;
	page: number;
	recordsPerPage: number;
	sortColumn: string;
	sortDirection: SlickGridColumnSortDirection;
	searchString: string;
	columnSearchValues: ISlickGridRequestSearchModel[];
}

