import { UtilsService } from "@services";

export interface ILathamOrderPickListDefinitionLineItemModel {
	uuid: string;
	sku: string;
	description: string;
	quantity: number;
}

export class LathamOrderPickListDefinitionLineItemModel implements ILathamOrderPickListDefinitionLineItemModel {
	constructor() {
		this.uuid = UtilsService.newGuid();
	}

	uuid: string;
	sku: string;
	description: string;
	quantity: number;
}