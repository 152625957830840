<slick-dialog #dialogRef width="800">
	<div class="d-flex">

		<div class="flex-fill">
			<customer-display [customerModel]="customerModel"></customer-display>
		</div>
		<div class="mx-3">
			<button (click)='addNew()' style='width: 120px' [slick-button-spinner]='spinnerStatus' class='btn btn-primary btn-sm'><i class='far fa-plus'></i> Add To QB</button>
		</div>
	</div>

	<div class="border my-3"></div>

	Search Quickbooks for this customer:
	<div class="input-group" style="width: 300px">
		<span class="input-group-text"><i class="far fa-search"></i></span>
		<input (keyup)="onKeyUp()" [(ngModel)]="searchString" class="form-control" placeholder="Search..." />
	</div>
	<input type="checkbox" [(ngModel)]="linkOnly" />Link Only (Will not update customer on quickbooks)

	<div style="margin-top: 10px; min-height: 400px; max-height: 400px; overflow-y: auto; overflow-x: hidden">
		<div *ngIf="searching">Searching...</div>
		<div *ngIf="(!qbCustomers || qbCustomers.length === 0) && searching === false">
			No Results
		</div>
		<div *ngFor="let qbCustomer of qbCustomers" class="card card-body mb-1">
			<div class="d-flex">
				<div class="flex-fill" style="line-height: .9rem">
					<div *ngIf="qbCustomer.companyName">
						<div style="font-size: 16px; font-weight: bold;">{{qbCustomer.displayName}}</div>
						<div style="font-size: 10px;">{{qbCustomer.firstName}} {{qbCustomer.lastName}} </div>
					</div>
					<div *ngIf="!qbCustomer.companyName">
						<div style="font-size: 16px; font-weight: bold;">{{qbCustomer.firstName}} {{qbCustomer.lastName}}</div>
					</div>
					<div>{{qbCustomer.address1}}</div>
					<div *ngIf="qbCustomer.address2">{{qbCustomer.address2}}</div>
					<div>{{qbCustomer.city}}, {{qbCustomer.state}}, {{qbCustomer.zipcode}}</div>
					<div>{{qbCustomer.primaryPhoneNumber}}</div>
					<div>{{qbCustomer.emailAddress}}</div>
				</div>

				<div>
					<button (click)="selectCustomer(qbCustomer)" class="btn btn-primary"><i class="fa fa-check-circle"></i>&nbsp;Select</button>
				</div>
			</div>
		</div>
	</div>

	<slick-dialog-footer>
		<button (click)='onCancel()' style='width: 100px' class='btn btn-danger'><i class='fa fa-times'></i>&nbsp;Cancel</button>
	</slick-dialog-footer>
</slick-dialog>