export interface ICustomCustomerFieldAnswerModel {
    customerId: number;
    customCustomerFieldId: number;
    customText: string;
    answer: string;
    active: boolean;
}

export class CustomCustomerFieldAnswerModel implements ICustomCustomerFieldAnswerModel{
    customerId: number;
    customCustomerFieldId: number;
    customText: string;
    answer: string;
    active: boolean;
}
