import {
    Component,
    forwardRef,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    FormControl,
    Validator
} from '@angular/forms';
import { HttpService } from '@services';

@Component({
    selector: 'user-name',
    templateUrl: 'user-name.component.html',
    styleUrls: ['user-name.component.scss'],
    providers: [        
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserNameComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => UserNameComponent),
            multi: true
        }
    ]
})
export class UserNameComponent implements ControlValueAccessor, Validator {
    initialUserName: string;
    userNameIsDuplicate: boolean;
    isLoading: boolean = false;
    lastUserName: string;
    userNameTimeout: NodeJS.Timer;
    userName: string;

    @Input()
    readonly: boolean;

    constructor(private httpService: HttpService) {}

    private propagateChange = (_: any) => {};

    // this is the initial value set to the component
    public writeValue(obj: any) {
        if (obj) {
            this.initialUserName = obj;
            this.userName = obj;
            this.onUserNameChanged();
        } else {
            this.initialUserName = null;
            this.userName = null;
            this.userNameIsDuplicate = null;
            this.isLoading = false;
            clearTimeout(this.userNameTimeout);
        }
    }

    public validate(c: FormControl) {
        let errors = null;

        if (!this.userName) errors = { required: { valid: false } };
        if (this.userNameIsDuplicate === true)
            errors = { duplicateUserName: { valid: false } };

        return errors;
    }

    // registers 'fn' that will be fired when changes are made
    // this is how we emit the changes back to the form
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    // not used, used for touch input
    public registerOnTouched() {}

    async onUserNameChanged() {
        this.propagateChange(this.userName);

        if (this.lastUserName === this.userName) return;

        if (this.userName) this.userName = this.userName.trim();

        if (
            this.initialUserName &&
            this.initialUserName.toLowerCase().trim() ===
                this.userName.toLowerCase().trim()
        ) {
            this.userNameIsDuplicate = false;
            this.isLoading = false;
            clearTimeout(this.userNameTimeout);
            return;
        }

        this.lastUserName = this.userName;

        clearTimeout(this.userNameTimeout);
        if (!this.userName) {
            this.userNameIsDuplicate = null;
            this.isLoading = false;
        } else {
            this.userNameTimeout = setTimeout(async () => {
                this.isLoading = true;

                setTimeout(async () => {
                    const  isDuplicate: boolean = await this.httpService.get(
                        '/users/isduplicateusername',
                        { userName: this.userName }
                    );
                    this.userNameIsDuplicate = isDuplicate;
                    this.propagateChange(this.userName);
                    this.isLoading = false;
                }, 1000);
            }, 500);
        }
    }
}
