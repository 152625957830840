import { Injectable } from "@angular/core";
import { ITimeclockEntryModel, ITimeclockBreakModel, ITimeclockWeeklySummaryModel, ITimeclockUserGridModel, TimeclockBreakModel, TimeclockEntryModel, ITimeClockDailyNotesModel } from "@models";
import { HttpService } from "./utils/http.service";
import * as moment from "moment";

@Injectable()
export class TimeclockService {
	constructor(private httpService: HttpService) { }

	async getTimeclockEntry(timeclockEntryId: number): Promise<ITimeclockEntryModel> {
		const params = {
			timeclockEntryId: timeclockEntryId
		}
		return await this.httpService.get("/timeclock/getTimeclockEntry", params);
	}

    async getTimeclockUserForGrid(userId: number, payPeriodId: number): Promise<ITimeclockUserGridModel> {
        return await this.httpService.get(`/timeclock/getTimeclockUserForGrid?userId=${userId}&payPeriodId=${payPeriodId}`);
	}

	async getPayPeriodSyncForUser(employeeId: number, payPeriodId: number): Promise<boolean> {
		const params = {
			employeeId: employeeId,
			payPeriodId: payPeriodId
		}
		return this.httpService.get("/timeclock/getPayPeriodSyncForUser", params);

	}

	async getAllTimeclockEntriesForUser(employeeId: number, payPeriodId: number): Promise<ITimeclockEntryModel[]> {
		const params = {
			employeeId: employeeId,
			payPeriodId: payPeriodId
		}
		return await this.httpService.get("/timeclock/getAllTimeclockEntriesForUser", params);

	}

	async getLastTimeclockEntry(userId: number): Promise<ITimeclockEntryModel> {
		const params = {
			userId: userId
		}
		return await this.httpService.get("/timeclock/getLastTimeclockEntry", params);
	}

	async getWeeklySummaryForUser(userId: number, startDate: Date, endDate: Date): Promise<ITimeclockWeeklySummaryModel> {
		const params = {
			userId: userId,
			startDate: moment(startDate).format("MM/DD/YYYY"),
			endDate: moment(endDate).format("MM/DD/YYYY"),
		}
		return await this.httpService.get("/timeclock/getWeeklySummaryForUser", params);
	}

	async getTimeClockDailyNotesForUser(userId: number, startDate: Date, endDate: Date): Promise<ITimeClockDailyNotesModel[]> {
		const params = {
			userId: userId,
			startDate: moment(startDate).format("MM/DD/YYYY"),
			endDate: moment(endDate).format("MM/DD/YYYY"),
		}
		return await this.httpService.get("/timeclock/getTimeClockDailyNotesForUser", params);
	}

	async clockIn(timeclockEntryModel: ITimeclockEntryModel): Promise<ITimeclockEntryModel> {
		return await this.httpService.post("/timeclock/clockIn", timeclockEntryModel);
	}

	async clockOut(timeclockEntryModel: ITimeclockEntryModel): Promise<ITimeclockEntryModel> {
		return await this.httpService.post("/timeclock/clockOut", timeclockEntryModel);
	}

	async startBreak(timeclockBreakModel: ITimeclockBreakModel): Promise<ITimeclockBreakModel> {
		return await this.httpService.post("/timeclock/startBreak", timeclockBreakModel);
	}

	async endBreak(timeclockBreakModel: ITimeclockBreakModel): Promise<ITimeclockBreakModel> {
		return await this.httpService.post("/timeclock/endBreak", timeclockBreakModel);
	}

	async addTimeclockEntry(timeclockEntryModel: ITimeclockEntryModel): Promise<ITimeclockEntryModel> {
		return await this.httpService.post("/timeclock/addTimeclockEntry", timeclockEntryModel);
	}

	async updateTimeclockEntry(timeclockEntryModel: ITimeclockEntryModel): Promise<ITimeclockEntryModel> {
		return await this.httpService.post("/timeclock/updateTimeclockEntry", timeclockEntryModel);
	}

	async updateTimeClockDailyNotes(timeClockDailyNoteModel: ITimeClockDailyNotesModel): Promise<ITimeClockDailyNotesModel> {
		return await this.httpService.post("/timeclock/updateTimeClockDailyNotes", timeClockDailyNoteModel);
	}

	async deleteTimeclockEntry(timeclockEntryId: number): Promise<void> {
        return await this.httpService.delete(`/timeclock/deleteTimeclockEntry?timeclockEntryId=${timeclockEntryId}`);
	}

    getTotalMinutes(timeclockEntry: ITimeclockEntryModel): number {
		return Math.trunc(moment.duration(moment(timeclockEntry.clockOut || new Date()).diff(moment(timeclockEntry.clockIn))).asMinutes());
	}

	getTotalSeconds(timeclockEntry: ITimeclockEntryModel): number {
		return Math.trunc(moment.duration(moment(timeclockEntry.clockOut || new Date()).diff(moment(timeclockEntry.clockIn))).asSeconds());
	}

	getTotalMinutesDisplay(timeclockEntry: ITimeclockEntryModel, includeSeconds: boolean = true): string {
		const format = includeSeconds ? "H:mm:ss" : "H:mm";
		return moment.utc(this.getTotalSeconds(timeclockEntry) * 1000).format(format);
	}

	getTotalMinutesWorked(timeclockEntry: ITimeclockEntryModel): number {
		return Math.trunc(this.getTotalMinutes(timeclockEntry) - this.getTotalBreakMinutes(timeclockEntry));
	}

	getTotalMinutesWorkedDisplay(timeclockEntry: ITimeclockEntryModel, includeSeconds: boolean = true): string {
		const format = includeSeconds ? "H:mm:ss" : "H:mm";
		return moment.utc(this.getTotalMinutesWorked(timeclockEntry) * 60 * 1000).format(format);
	}

	getTotalBreakMinutes(timeclockEntry: ITimeclockEntryModel): number {
		let total = 0;
        timeclockEntry.breaks.forEach(b => {
            if (b.totalMinutes)
                total += b.totalMinutes
            else
			    total += moment.duration(moment(b.breakEnd).diff(moment(b.breakStart))).asMinutes();
		});

		return Math.trunc(total);
	}

	getTotalBreakMinutesDisplay(timeclockEntry: ITimeclockEntryModel, includeSeconds: boolean = true): string {
		const format = includeSeconds ? "H:mm:ss" : "H:mm";
		return moment.utc(this.getTotalBreakMinutes(timeclockEntry) * 60 * 1000).format(format);
	}

	recalc(timeclockEntry: ITimeclockEntryModel): ITimeclockEntryModel {
		timeclockEntry.totalMinutes = this.getTotalMinutes(timeclockEntry);
		timeclockEntry.totalDisplay = this.getTotalMinutesDisplay(timeclockEntry, false);
		timeclockEntry.totalWorkedMinutes = this.getTotalMinutesWorked(timeclockEntry);
		timeclockEntry.totalWorkedDisplay = this.getTotalMinutesWorkedDisplay(timeclockEntry, false);
		timeclockEntry.totalBreakMinutes = this.getTotalBreakMinutes(timeclockEntry);
		timeclockEntry.totalBreakDisplay = this.getTotalBreakMinutesDisplay(timeclockEntry, false);
		return timeclockEntry;
	}

	validateTimeclockEntry(timeclockEntry: ITimeclockEntryModel) {

	}

	async approveTimeclockEntry(timeclockEntryId: number): Promise<ITimeclockEntryModel> {		
		return await this.httpService.patch(`/timeclock/approveTimeclockEntry?timeclockEntryId=${timeclockEntryId}`);
	}
	getCoordString(coords: GeolocationCoordinates): string {
		return JSON.stringify({ latitude: coords.latitude, longitude: coords.longitude });
	}

	async approveAllTimeclockEntries(userId: number, payPeriodId: number): Promise<void> {
		await this.httpService.patch(`/timeclock/approveAllTimeclockEntries?userId=${userId}&payPeriodId=${payPeriodId}`);
	}

	combineToDate(date: Date, text: string) {
		const temp = `${moment(date).format("YYYY-MM-DD")} ${text}`;
		return moment(temp, 'YYYY-MM-DD h:mma').toDate();
	}

	validateTimes(timeclockEntry: ITimeclockEntryModel) {
		let isValid = true;
		const regex = new RegExp("^([0-9]|0[0-9]|1[0-2]):[0-5][0-9]\\s?[aApP][mM]$");
		if (timeclockEntry.clockInHoursText) {
			const timeCheck = regex.test(timeclockEntry.clockInHoursText);
			if (!timeCheck)
				isValid = false;
		}
		if (timeclockEntry.clockOutHoursText) {
			const timeCheck = regex.test(timeclockEntry.clockOutHoursText);
			if (!timeCheck)
				isValid = false;
		}
		if (timeclockEntry.breaks) {
			timeclockEntry.breaks.forEach(x => {
				if (x.breakStart) {
					if (x.breakStartHoursText) {
						const timeCheck = regex.test(x.breakStartHoursText);
						if (!timeCheck)
							isValid = false;
					}
					if (x.breakEndHoursText.length > 0) {
						const timeCheck = regex.test(x.breakEndHoursText);
						if (!timeCheck)
							isValid = false;
					}
				}
            });
        }
        return isValid;
    }

    checkForOverlappingEntries(timeClockEntries: ITimeclockEntryModel[], newTimeClockEntry?: ITimeclockEntryModel): boolean {
        let overlapFound = false;

		//this is for checking all entries against eachother.
		if (!newTimeClockEntry) {
			for (const timeClockEntry of timeClockEntries) {
				// Check for entries without a ClockOut

				// Check for overlaps with other entries
				for (const otherEntry of timeClockEntries) {
					// Skip checking the same entry against itself and entries without a ClockOut
					if (timeClockEntry === otherEntry || otherEntry.clockOut === null) continue;

					// Check for all three types of overlaps
					const clockInOverlap = timeClockEntry.clockIn >= otherEntry.clockIn && timeClockEntry.clockIn < (otherEntry.clockOut || new Date());
					const clockOutOverlap = (timeClockEntry.clockOut || new Date()) > otherEntry.clockIn && (timeClockEntry.clockOut || new Date()) <= (otherEntry.clockOut || new Date());
					const otherEntryWithin = otherEntry.clockIn >= timeClockEntry.clockIn && (otherEntry.clockOut || new Date()) <= (timeClockEntry.clockOut || new Date());

					if (clockInOverlap || clockOutOverlap || otherEntryWithin) {
						timeClockEntry.hasError = true; // Mark the entry as having an error
						overlapFound = true;
					}
				}
			}
		}
		// this is to just check if one entry that they are saving are overlapping any others.
		else {
			for (const existingEntry of timeClockEntries) {
				// Skip checking entries without a ClockOut
				if (existingEntry.clockOut === null || existingEntry.timeclockEntryId == newTimeClockEntry.timeclockEntryId) continue;

				// Check for all three types of overlaps
				const clockInOverlap = newTimeClockEntry.clockIn >= existingEntry.clockIn && newTimeClockEntry.clockIn < (existingEntry.clockOut || new Date());
				const clockOutOverlap = (newTimeClockEntry.clockOut || new Date()) > existingEntry.clockIn && (newTimeClockEntry.clockOut || new Date()) <= (existingEntry.clockOut || new Date());
				const entryWithinExisting = newTimeClockEntry.clockIn >= existingEntry.clockIn && (newTimeClockEntry.clockOut || new Date()) <= (existingEntry.clockOut || new Date());
				const existingWithinEntry = existingEntry.clockIn >= newTimeClockEntry.clockIn && (existingEntry.clockOut || new Date()) <= (newTimeClockEntry.clockOut || new Date());

				if (clockInOverlap || clockOutOverlap || entryWithinExisting || existingWithinEntry) {
					newTimeClockEntry.hasError = true; // Mark the new entry as having an error
					overlapFound = true;
					break; // Stop checking once an overlap is found
				}
			}
		}

        return overlapFound; // No overlaps found.
	}



}
