import { Injectable } from "@angular/core";
import { GlobalsService } from "@services/utils/globals.service";
import { HubConnection, HubConnectionBuilder, LogLevel, HubConnectionState } from "@microsoft/signalr";
import { ChatStore, LoggerStore } from "@stores";
import { IChatGroupModel, IChatMessageModel } from "@models";

@Injectable()
export class ChatHubService {
    private static hubConnection: HubConnection = null;

    constructor(private chatStore: ChatStore,
        private loggerStore: LoggerStore) { }

    private startConnection() {
        this.loggerStore.addLog(`ChatHub start connection (State: ${ChatHubService.hubConnection.state})`);
        if (ChatHubService.hubConnection.state === HubConnectionState.Connected) {
            this.loggerStore.addLog("ChatHub exiting because connected");
            return;
        }

        ChatHubService.hubConnection
            .start()
            .then(() => {
                this.loggerStore.addLog("ChatHub connected");
            })
            .catch(err => {
                this.loggerStore.addLog("ChatHub start failed");

                //console.error(err);
                setTimeout(() => {
                    this.startConnection();
                }, 5000);
            });
    }

    init() {
        if (GlobalsService.isDebug)
            return;

        if (ChatHubService.hubConnection === null) {
            ChatHubService.hubConnection = new HubConnectionBuilder()
                .withUrl(GlobalsService.apiUrl + "/chatHub", { accessTokenFactory: () => GlobalsService.jwtToken })
                .build();

            ChatHubService.hubConnection.on("HandleMessage", (senderId: number, groupId: number, message: IChatMessageModel) => this.chatStore.onNewMessage(senderId, groupId, message));
            ChatHubService.hubConnection.on("HandleChatGroupCreated", (group: IChatGroupModel) => this.chatStore.onChatGroupCreated(group) );
            ChatHubService.hubConnection.on("HandleChatGroupUpdated", (group: IChatGroupModel) => this.chatStore.onChatGroupUpdated(group) );

            // detect if the connection is lost and reconnect
            ChatHubService.hubConnection.onclose(() => {
                this.loggerStore.addLog("ChatHub onclose");
                setTimeout(() => {
                    this.startConnection();
                }, 10000);
            });

            this.startConnection();
        }
    }
}
