<div class="slick-search-bar border d-flex flex-fill align-items-center bg-white">
	<div *ngIf="showSearchIcon" class="slick-search-bar_icon ms-3 p-0"><i [ngClass]="icon"></i></div>
	<div *ngIf="!showSearchIcon" class="ms-3"></div>
	<input #slickSearchTextBoxRef type="text" class="form-control ms-2" [(ngModel)]="searchText" (keydown)="onKeyDown()" [placeholder]="placeholder" />
	<div *ngIf="(showAddButton || showEditButton)" class="d-flex">
		<div *ngIf="showAddButton" class="slick-search-bar_add-button me-2">
			<div *ngIf="disableAddButton"><i class="far fa-plus"></i></div>
			<a href="#" *ngIf="!disableAddButton" (click)="onAddClicked(); false;"><i class="far fa-plus"></i></a>
		</div>
		<div *ngIf="showEditButton" class="slick-search-bar_edit-button me-2">
			<div *ngIf="disableEditButton"><i class="far fa-pencil"></i></div>
			<a href="#" *ngIf="!disableEditButton" (click)="onEditClicked(); false;"><i class="far fa-pencil"></i></a>
		</div>
	</div>
	<div style="width: 10px"></div>
</div>
