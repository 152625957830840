import { Component, ElementRef, ViewChild, Input, HostListener, forwardRef, OnInit, OnDestroy, EventEmitter, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SlickSleepService } from "../utils/slick-sleep.service";
import { SlickUtilsService } from "../utils/slick-utils.service";
import moment from "moment";

@Component({
	selector: '[slick-date-picker]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SlickDatePickerComponent),
			multi: true
		}],
	templateUrl: 'slick-date-picker.component.html'
})
export class SlickDatePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
	@Input("slick-date-picker") slickDatePicker: SlickDatePickerComponent;
	@Input("slick-date-picker-format") format: string = "M/D/YYYY";
	@Input("slick-date-picker-condensed") condensed: boolean = false;
	@Input("slick-date-picker-mobile") mobile: boolean = false;
	@Output("onDateChange") onDateChange = new EventEmitter<Date>();
	@ViewChild("containerRef") containerRef: ElementRef;

	fnDocumentClick = (e) => this.documentClick(e);
	fnDocumentMouseDown = (e) => this.documentMouseDown(e);

	// Make sure they only press valid keys
	@HostListener('keydown', ['$event'])
	async onKeydown(event: KeyboardEvent) {		
		if (event.altKey || event.ctrlKey || event.metaKey === true)
			return;

		this.hideCalendar();

		// Allow: backspace, delete, tab, escape, enter
		if ((event.keyCode === 46 || event.keyCode === 8) ||
			(event.keyCode === 9 || event.keyCode === 27 || event.keyCode === 13) ||
			// Allow F1-F12 keys
			((event.keyCode >= 112 && event.keyCode <= 123)) ||
			// Allow: home, end, left, right, down, up
			(event.keyCode >= 35 && event.keyCode <= 40)) {
			// let it happen, don't do anything
			return;
		}

		// Ensure that it is a number and stop the keypress
		if ((event.shiftKey || event.altKey || event.key < '0' || event.key > '9') &&
			event.key !== '/' &&  // Allow /
			event.key !== '-') {  // Allow -		
			event.preventDefault();
			return;
		}
	}

	// When we first focus, show the date picker
	@HostListener('focus', ['$event'])
	onFocus(event: FocusEvent): void {
		this.showCalendar();
	}

	// When we blur, update the model
	@HostListener('blur', ['$event', '$event.target'])
	async onBlur(event: FocusEvent, targetElement: HTMLElement) {
		// If the user clicked on the calendar, it will trigger a mouse down event before the blur event.  Don't blur
		// if they clicked on the calendar.
		if (this.ignoreBlur === true) {
			this.ignoreBlur = false;
			return;
		}

		const textVal = this.el.nativeElement.value;

		if (!textVal) {
			this.doPropagate(null);
			return;
		}

		if (moment(textVal, this.format).isValid()) {
			var newDate = moment(textVal, this.format);
			this.doPropagate(newDate.toDate())
			this.el.nativeElement.value = newDate.format(this.format);
		}
	}

	uuid: string = SlickUtilsService.newGuid();
	ignoreBlur: boolean = false;
	showDatePicker: boolean = false;
	datePickerVisiblity: string = "hidden";
	datePickerOpacity: string = '0';
	left: string;
	top: string;
	selectedDate: Date = null;
	isInitial = true;

	private inputGroupPrepend: HTMLElement;

	constructor(private el: ElementRef) {
	}

	async ngOnInit() {

		this.mobile = (this.mobile || 'false').toString().toLowerCase() === 'true' ? true : false;
		this.condensed = (this.condensed || 'false').toString().toLowerCase() === 'true' ? true : false;

		// Since this.mobile isn't necessarily defined yet, we need to sleep to allow the ngIf's to do their thing
		await SlickSleepService.sleep();
		// Remove this from the inline version and put it on the body so that it doesn't hide behind the bottom
		// of a div when it expands beyond the bottom.
		this.containerRef.nativeElement.remove(this.containerRef);
		document.body.appendChild(this.containerRef.nativeElement);

		await SlickSleepService.sleep();
		this.inputGroupPrepend = SlickUtilsService.getClosestSibling(this.el.nativeElement, "input-group-prepend");
	}

	ngOnDestroy() {
		document.removeEventListener("mousedown", this.fnDocumentMouseDown, true);
		document.removeEventListener("click", this.fnDocumentClick, true);
		// Is okay if this fails.  It just means it was already removed.
		try {
			document.body.removeChild(this.containerRef.nativeElement);
		}
		catch { }
	}

	propagateChange = (_: any) => { };

    private doPropagate(date: Date) {
        // We don't always go through the emit on the first call, so make sure this isn't the first change
        this.isInitial = false;

		if (!date) {
			if (this.selectedDate) {
				this.propagateChange(null);
				this.emitDateChange(null);
			}

			this.selectedDate = null;
			return;
		}
		else {
			const newDate = moment(date);
			// If the date is not valid, return
			if (newDate.isValid() === false)
				return;

			// Don't propogate if the dates are the same
			if (moment(this.selectedDate).isSame(newDate))
				return;


			// Setting this selectedDate will trigger the ngModelChange of the calendar.  Ignore this.
			this.selectedDate = moment(date).startOf("date").toDate();
			this.propagateChange(this.selectedDate);
			this.emitDateChange(this.selectedDate);
		}
	}

	private emitDateChange(date: Date) {
		if (this.onDateChange && !this.isInitial)
			this.onDateChange.emit(date);

		this.isInitial = false;
	}

	// this is the initial value set to the component
	public writeValue(obj: any) {
		if (obj) {
			if (moment(obj).isValid() === false) {
				this.selectedDate = null;
                this.el.nativeElement.value = "Invalid Date";
                this.isInitial = false;
				return;
			}

			let updatedDate: moment.Moment;
			if (obj instanceof Date || obj.indexOf("Z") >= 0)
				updatedDate = moment(obj).startOf("date");
			else
				updatedDate = moment(obj, this.format).startOf("date");

            if (moment(this.selectedDate || new Date()).isSame(updatedDate)) {
                this.isInitial = false;
                return;
            }

			this.selectedDate = updatedDate.toDate();
			this.el.nativeElement.value = moment(this.selectedDate).format(this.format);
		}
		else {
            if (!this.selectedDate && !obj) {
                this.isInitial = false;
                return;
            }

			this.el.nativeElement.value = '';
			this.selectedDate = null;
		}
	}

	// registers 'fn' that will be fired when changes are made
	// this is how we emit the changes back to the form
	public registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	// not used, used for touch input
	public registerOnTouched() { }

	private documentMouseDown(e: MouseEvent) {
		// This is the only way to capture that they clicked on the calendar before the blur event
		// fires.  
		if (SlickUtilsService.checkParentClassExists(<HTMLElement>e.target, "slick-calendar") === true)
			this.ignoreBlur = true;
	}

	private documentClick(e: MouseEvent) {
		if (!e.target)
			return;

		let targetElement: HTMLElement = (<HTMLElement>e.target);

		if (SlickUtilsService.checkParentClassExists(targetElement, "slick-calendar") === true)
			return;

		if (this.inputGroupPrepend && ((targetElement === this.inputGroupPrepend)
			|| (targetElement.parentElement && targetElement.parentElement === this.inputGroupPrepend))
			|| (targetElement.parentElement && targetElement.parentElement.parentElement && targetElement.parentElement.parentElement === this.inputGroupPrepend)) {
			this.toggleCalendar();
			return;
		}

		const clickedInside = this.el.nativeElement.contains(targetElement);
		if (!clickedInside) {
			this.hideCalendar();
		}
	}

	onDateSelect(date) {
		this.el.nativeElement.value = moment(date).format(this.format);
		this.hideCalendar();

		const newDate = moment(this.el.nativeElement.value, this.format).toDate();

		this.doPropagate(newDate);		
	}

	async showCalendar() {
		document.removeEventListener("mousedown", this.fnDocumentMouseDown, true);
		document.removeEventListener("click", this.fnDocumentClick, true);

		this.showDatePicker = true;

		if (!this.mobile) {
			this.reposition();
		}

		this.datePickerOpacity = '1';
		this.datePickerVisiblity = 'visible';

		// Not sure why, but the click event is called immediately, which hides the calendar
		setTimeout(() => {
			document.addEventListener("mousedown", this.fnDocumentMouseDown, true);
			document.addEventListener("click", this.fnDocumentClick, true);
		}, 350)
	}

	hideCalendar() {
		document.removeEventListener("mousedown", this.fnDocumentMouseDown, true);
		document.removeEventListener("click", this.fnDocumentClick, true);

		this.datePickerVisiblity = 'hidden';
		this.datePickerOpacity = '0';
		this.showDatePicker = false;
	}

	private reposition() {
		if (this.mobile === true)
			return;

		// Reposition the element to be where the textbox is, either below or above if there isn't enough room
		const inputEl: HTMLInputElement = this.el.nativeElement;

		const rect = inputEl.getBoundingClientRect();
		const textboxLeft = rect.left;
		const textboxTop = rect.top;
		const textboxHeight = inputEl.offsetHeight;
		const containerHeight = (<HTMLDivElement>this.containerRef.nativeElement).offsetHeight;

		this.left = textboxLeft + "px";
		this.top = (textboxTop + textboxHeight) + "px";

		if (textboxTop + textboxHeight + containerHeight > window.innerHeight) {
			this.top = (textboxTop - containerHeight - 2) + "px";
		}

	}

	toggleCalendar() {
		(this.showDatePicker === true) ? this.hideCalendar() : this.showCalendar();
	}
}