import { Injectable } from "@angular/core";

export class SlickInitParams {
	attachTo: string;
	platform: string;
	errorLog: string = "minimal";
	version: string;

}

@Injectable()
export class SlickInitService {	
	static version = "5.0.28";

	constructor() { }


	static init(initParams: SlickInitParams): SlickInitParams {
		console.info(`Slick Components Version ${SlickInitService.version}`);
		initParams.version = SlickInitService.version;
		(<any>window).slickComponentsParams = initParams;

		return (<any>window).slickComponentsParams;
	}

	static getParams(): SlickInitParams {
		if (!(<any>window).slickComponentsParams) {
			const defaultParams = new SlickInitParams();
			defaultParams.attachTo = "body";
			(<any>window).slickComponentsParams = defaultParams;
		}

		return <SlickInitParams>(<any>window).slickComponentsParams;
	}

	static getAttachTo(): string {
		return SlickInitService.getParams().attachTo;
	}

	static setAttachTo(attachToElement: string) {
		SlickInitService.getParams().attachTo = attachToElement;
	}

	static getPlatform(): string {
		return SlickInitService.getParams().platform;
	}

	static setPlatform(platform: string) {
		SlickInitService.getParams().platform = platform;
	}

	static getErrorLog(): string {
		return SlickInitService.getParams().errorLog;
	}

	static setErrorLog(errorLog: string) {
		SlickInitService.getParams().errorLog = errorLog;
	}
}

