import { Component, Input, ViewChild, OnChanges, SimpleChanges, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { JobEditBase } from "@base-components/job-edit.base";
import { JobsAuthService } from '@services/utils/auth-services/jobs-auth.service';
import { AppointmentsService, JobsService, LookupService, GlobalsService, ScheduleDisplayService, InvoicesService, CustomersService, SleepService, FunctionLockService, UtilsService, JobCostingService } from '@services';
import { IInvoiceModel, IAppointmentModel, IInvoiceListModel, IDropdownModel, IScheduledAppointmentModel, TextChatConversationModel, ITextChatConversationModel, AddressModel, CustomerModel, IJobModel, IJobCostingSetupModel } from '@models';
import { DefaultInvoiceTypes } from '@services/jobs.service';
import { AutoSchedulerDialogComponent } from '@app/auto-scheduler';
import { EstimateEditDialogComponent } from '@app/estimates/estimates-components';
import { SalesOrderEditDialogComponent } from '@app/sales-orders/sales-orders-components';
import { InvoiceEditDialogComponent } from '@app/invoices/invoice-components';
import { SlickConfirmDialogComponent, SlickConfirmDialogResults, SlickDialogComponent, SlickScreenBlockerService, SlickToastService } from "@slick-components";
import { TextChatStore } from '@stores';
import Swal from 'sweetalert2';
import { ICustomerMultipleAddressModel } from '@models/customers/customer-multiple-address.model';


@Component({
	selector: 'job-edit',
	templateUrl: 'job-edit.component.html',
	styleUrls: ['job-edit.component.scss'],
	providers: [LookupService, JobsService, JobsAuthService, CustomersService, SlickToastService, JobCostingService]
})
export class JobEditComponent extends JobEditBase implements OnChanges {
	@Input() isDialog: boolean;
	@Input() showHistory: boolean = true;
	@Output() onAppointmentSaved: EventEmitter<IJobModel> = new EventEmitter<IJobModel>();
	@Output() onDialogJobSaved: EventEmitter<IJobModel> = new EventEmitter<IJobModel>();

	@ViewChild('autoSchedulerDialogRef', { static: true }) autoSchedulerDialogRef: AutoSchedulerDialogComponent;
	@ViewChild('estimateEditDialogRef', { static: true }) estimateEditDialogRef: EstimateEditDialogComponent;
	@ViewChild('salesOrderEditDialogRef', { static: true }) salesOrderEditDialogRef: SalesOrderEditDialogComponent;
	@ViewChild('invoiceEditDialogRef', { static: true }) invoiceEditDialogRef: InvoiceEditDialogComponent;
	@ViewChild("jobEditDialogRef", { static: true }) jobEditDialogRef: SlickDialogComponent;
	@ViewChild("overwriteInvoiceRef") overwriteInvoiceRef: SlickConfirmDialogComponent;
	@ViewChild("invoiceIsOnExistingJobRef") invoiceIsOnExistingJobRef: SlickConfirmDialogComponent;
	@ViewChild("createNewEstimateRef") createNewEstimateRef: SlickConfirmDialogComponent;
	@ViewChild("createNewSalesOrderRef") createNewSalesOrderRef: SlickConfirmDialogComponent;
	@ViewChild("createNewInvoiceRef") createNewInvoiceRef: SlickConfirmDialogComponent;
	@ViewChild("linkInvoiceDialogRef") linkInvoiceDialogRef: SlickDialogComponent;
	@ViewChild("deleteInvoiceDialogRef") deleteInvoiceDialogRef: SlickDialogComponent;

	@ViewChild("oneTimeAddressDialogRef", { static: true }) oneTimeAddressDialogRef: SlickDialogComponent;

	spinnerStatus: string;
	spinnerDialogStatus: string;
	tabIndex: number = 0;
	tabKey: string;
	isEstimate: boolean;
	isSalesOrder: boolean;
	isInvoice: boolean;
	useTextChat: boolean = GlobalsService.company.useTextChat;
	linkInvoiceSearchString: string;
	showClosedLinkInvoices: boolean;
	allLinkInvoices: IInvoiceListModel[];
	visibleLinkInvoices: IInvoiceListModel[];
	addressModel: AddressModel;
	resolve: any;
	jobModelForDialog: IJobModel
	parentMaxHeight = (GlobalsService.userInfo.layoutSettings.jobsLayout === 'grid-full-screen') ? "calc(100vh - 140px)" : "calc(100vh - 80px)"
	tabMaxHeight = (GlobalsService.userInfo.layoutSettings.jobsLayout === 'grid-full-screen') ? "calc(100vh - 250px)" : "calc(100vh - 185px)"
	maxHeight = (GlobalsService.userInfo.layoutSettings.jobsLayout === 'grid-full-screen') ? "calc(100vh - 250px)" : "calc(100vh - 185px)"

	jobHistorySearchString: string = "";
	isQPro = GlobalsService.company.isQPro;
	isMizu = (GlobalsService.company.companyId === 1);

	useOneTimeAddress = GlobalsService.company.useOneTimeAddress;
	countries: any = [
		{ id: 'US', text: 'US' },
		{ id: 'Canada', text: 'Canada' },
		{ id: 'Mexico', text: 'Mexico' },
		{ id: 'Israel', text: 'Israel' }

	];

	jobCostingSetupModel: IJobCostingSetupModel;
	isJobCostSetupValid: boolean = true;
	canAccessJobCosting: boolean = GlobalsService.checkPermission("JobCosting", "Access");

	isDialogOpen: boolean;

	constructor(
		public jobsAuthService: JobsAuthService,
		jobsService: JobsService,
		appointmentsService: AppointmentsService,
		lookupService: LookupService,
		private readonly textChatStore: TextChatStore,
		private readonly scheduleDisplayService: ScheduleDisplayService,
		private readonly invoicesService: InvoicesService,
		private jobCostingService: JobCostingService,

		private readonly customersService: CustomersService,
		private changeDetector: ChangeDetectorRef,
		private readonly slickScreenBlockerService: SlickScreenBlockerService,
		private readonly slickToastService: SlickToastService) {
		super(jobsService, appointmentsService, lookupService);
	}

	async ngOnInit() {
		this.spinnerStatus = "reset";
		this.jobCostingSetupModel = await this.lookupService.getJobCostingSetupModel();

	}

	ngOnChanges() {
		this.isDialog = ((this.isDialog || false).toString().toLowerCase() === 'true');
		if (this.isDialog === true) {
			this.parentMaxHeight = null;
			this.tabMaxHeight = null;
		}
		if (this.jobModel) 
			this.setInvoiceType();		

		super.ngOnChanges();

		if (this.tabKey === "History" && !this.jobModel.showOneTimeAddress && this.jobModel.jobId > 0)
			this.refreshHistory();

		if (this.tabKey === 'Text Chat' && this.jobModel.jobCustomer) {
			this.getConversation();
		}
		if (this.jobModel && this.jobModel.appointments == null) {
            this.createNewAppointment();
		}

		this.tabIndex = 0;
	}

	async showNewAddressDialog() {
		this.addressModel = new AddressModel();
		this.isSubmitted = false;
		await this.oneTimeAddressDialogRef.showDialog();
	}

	async showEditAddressDialog() {
		this.addressModel = this.jobModel.oneTimeAddress;
		await this.oneTimeAddressDialogRef.showDialog();
	}

	swapJobSiteCustomer() {
		if (!this.jobModel.showOneTimeAddress) {
			this.jobModel.showJobSiteCustomer = false;
			this.jobModel.showOneTimeAddress = true;
		}
		else {
			this.jobModel.showOneTimeAddress = false;
			this.jobModel.showJobSiteCustomer = true;
		}
	}

	async onMultiAddressChange(customerMultipleAddress: ICustomerMultipleAddressModel) {
		await SleepService.sleep();
		this.jobModel.customerMultipleAddress = customerMultipleAddress;
		//if(!this.jobModel.billToCustomer)
		//	this.jobModel.billToCustomer = UtilsService.clone(this.jobModel.jobCustomer);
    }

	async onGoogleMapAddressSelect(placeResult: google.maps.places.PlaceResult) {
        if (!this.jobModel.oneTimeAddress)
            this.jobModel.oneTimeAddress = new AddressModel();
        // Check if the input is a coordinate
		const coordinateRegex = /^-?((\d{1,2}(\.\d+)?)|(\d{1,2}\s\d+(\.\d+)?)),\s*-?((\d{1,3}(\.\d+)?)|(\d{1,3}\s\d+(\.\d+)?))$/;
        if (coordinateRegex.test(placeResult.name)) {
            // If it's a coordinate, assign it to address1
            this.jobModel.oneTimeAddress.address1 = placeResult.name;
            // Reset other address fields
            this.jobModel.oneTimeAddress.address2 = '';
            this.jobModel.oneTimeAddress.city = '';
            this.jobModel.oneTimeAddress.state = '';
            this.jobModel.oneTimeAddress.country = '';
            this.jobModel.oneTimeAddress.zipcode = '';
            this.jobModel.oneTimeAddress.latitude = null;
            this.jobModel.oneTimeAddress.longitude = null;
        }

        else {

            this.jobModel.oneTimeAddress.address1 = '';
            this.jobModel.oneTimeAddress.address2 = '';
            this.jobModel.oneTimeAddress.city = '';
            this.jobModel.oneTimeAddress.state = '';
            this.jobModel.oneTimeAddress.country = '';
            this.jobModel.oneTimeAddress.zipcode = '';
            this.jobModel.oneTimeAddress.latitude = null;
            this.jobModel.oneTimeAddress.longitude = null;

            placeResult.address_components.forEach(adr => {
                adr.types.forEach(type => {
                    switch (type) {
                        case 'street_number':
                        case 'route':
                            this.jobModel.oneTimeAddress.address1 += adr.long_name + ' ';
                            break;

                        case 'locality':
                            this.jobModel.oneTimeAddress.city = adr.long_name;
                            break;

                        case 'administrative_area_level_1':
                            this.jobModel.oneTimeAddress.state = adr.short_name;
                            break;

                        case 'postal_code':
                            this.jobModel.oneTimeAddress.zipcode = adr.short_name;
                            break;

                        case 'country':
                            if (adr.short_name === 'CA') {
                                this.jobModel.oneTimeAddress.country = 'Canada';
                            } else if (adr.short_name === 'IL') {
                                this.jobModel.oneTimeAddress.country = 'Israel';
                            } else if (adr.short_name === 'MX') {
                                this.jobModel.oneTimeAddress.country = 'Mexico';
                            } else {
                                this.jobModel.oneTimeAddress.country = adr.short_name;
                            }
                            break;
                    }
                });
            });
        }
        this.changeDetector.detectChanges();
    }
	saveOneTimeAddress() {
		this.isSubmitted = true;
		if (this.addressModel.address1) {
			this.jobModel.oneTimeAddress = this.addressModel;
			this.jobModel.oneTimeAddress.latitude = null;
			this.jobModel.oneTimeAddress.longitude = null;
			this.oneTimeAddressDialogRef.hideDialog();
		}
	}

	cancelOneTimeAddress() {
		this.oneTimeAddressDialogRef.hideDialog();
	}

	async onJobTypeSelected(jobType: IDropdownModel) {
		await SleepService.sleep();

		this.jobModel.jobTypeDisplayName = jobType.text;
		this.setInvoiceType();
	}

	async onJobStatusSelected(jobStatus: IDropdownModel) {
		this.jobModel.jobStatusDisplayName = jobStatus.text;
	}

	createNewAppointment() {
		if (this.jobModel.appointments == null)
			this.jobModel.appointments = [];

		this.defaultApptId = null;
		// if this job is closed and we add a new appt, we need to re-open the job
		if (this.jobModel.jobStatusId === 3) {
			this.jobModel.jobStatusId = this.lookupService.getJobStatuses().find(x => x.description === "In Process").jobStatusId;
			this.jobModel.jobStatusDisplayName = "In Process";
		}

		const newAppointment = this.appointmentsService.getNewAppointmentModel(this.jobModel.jobId);
		this.jobModel.appointments.unshift(newAppointment);
	}

	async openAutoScheduler() {
		const newJob = await this.autoSchedulerDialogRef.openDialog(this.jobModel, this.jobModel.jobCustomer);

		if (newJob)
			this.jobModel = newJob;
	}

	async linkInvoice() {
		this.linkInvoiceSearchString = null;
		this.showClosedLinkInvoices = false;

		if (!this.jobModel.billToCustomer) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "A bill to customer is required",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		if (this.jobModel.invoice && this.jobModel.invoice.invoiceId > 0) {
			const okToOverwrite = await this.overwriteInvoiceRef.confirm();
			if (okToOverwrite === SlickConfirmDialogResults.Cancel)
				return;
		}

		this.allLinkInvoices = await this.invoicesService.getInvoicesByCustomer(this.jobModel.billToCustomer.customerId, false);
		this.visibleLinkInvoices = [...this.allLinkInvoices];

		if (this.showClosedLinkInvoices === false)
			this.visibleLinkInvoices = this.visibleLinkInvoices.filter(x => x.invoiceStatusName !== 'Closed');

		this.linkInvoiceDialogRef.showDialog();
	}

	async refreshLinkInvoices() {
		await SleepService.sleep();

		const searchString = (this.linkInvoiceSearchString ?? '').trim().toLowerCase();

		if (!searchString)
			this.visibleLinkInvoices = [...this.allLinkInvoices];
		else
			this.visibleLinkInvoices = this.allLinkInvoices.filter(x => {
				if ((x.invoiceNumber ?? '').trim().toLowerCase().indexOf(searchString) >= 0)
					return true;

				if ((x.purchaseOrderNumber ?? '').trim().toLowerCase().indexOf(searchString) >= 0)
					return true;

				return false;
			});

		if (this.showClosedLinkInvoices === false)
			this.visibleLinkInvoices = this.visibleLinkInvoices.filter(x => x.invoiceStatusName !== 'Closed');
	}

	async selectInvoiceToLink(invoiceToLink: IInvoiceListModel) {
		const invoiceJob = await this.jobsService.getJobByInvoiceId(invoiceToLink.invoiceId);

		if (invoiceJob) {
			const confirm = await this.invoiceIsOnExistingJobRef.confirm();
			if (confirm === SlickConfirmDialogResults.Cancel)
				return;
		}

		const updatedInvoice = await this.invoicesService.linkInvoiceToJob(invoiceToLink.invoiceId, this.jobModel.jobId);
		this.jobModel.invoiceId = updatedInvoice.invoiceId;
		this.jobModel.invoice = updatedInvoice;
		this.jobModel.jobCostingDetailModel = updatedInvoice.jobCostingDetailModel;

		this.linkInvoiceDialogRef.hideDialog();
		this.slickToastService.showSuccess("Invoice linked");
	}

	async openEstimate() {
		if (!this.jobModel.billToCustomer) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "A bill to customer is required for estimate",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		if (!this.jobModel.invoice || this.jobModel.invoice.invoiceId === 0) {
			const result = await this.createNewEstimateRef.confirm();

			if (result === SlickConfirmDialogResults.Cancel)
				return;
		}

		this.jobModel.invoice = await this.jobsService.getJobInvoice(this.jobModel, DefaultInvoiceTypes.Estimate);

		if (!this.jobModel.invoiceId)
			this.jobModel.invoiceId = this.jobModel.invoice.invoiceId;

		const returnSalesOrder = await this.estimateEditDialogRef.openDialog(this.jobModel.invoice);
		if (returnSalesOrder) {
			this.jobModel.invoice = returnSalesOrder;
			this.jobModel.invoiceId = returnSalesOrder.invoiceId;
			this.jobModel.billToCustomer = returnSalesOrder.customer;
			this.jobModel.jobCostingDetailModel = returnSalesOrder.jobCostingDetailModel;

			this.setInvoiceType();
		}
	}

	async openSalesOrder() {
		if (!this.jobModel.billToCustomer) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "A bill to customer is required for sales order",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		if (!this.jobModel.invoice || this.jobModel.invoice.invoiceId === 0) {
			const result = await this.createNewSalesOrderRef.confirm();

			if (result === SlickConfirmDialogResults.Cancel)
				return;
		}

		this.jobModel.invoice = await this.jobsService.getJobInvoice(this.jobModel, DefaultInvoiceTypes.SalesOrder);

		if (!this.jobModel.invoiceId)
			this.jobModel.invoiceId = this.jobModel.invoice.invoiceId;

		const returnSalesOrder = await this.salesOrderEditDialogRef.openDialog(this.jobModel.invoice);
		if (returnSalesOrder) {
			this.jobModel.invoice = returnSalesOrder;
			this.jobModel.invoiceId = returnSalesOrder.invoiceId;
			this.jobModel.billToCustomer = returnSalesOrder.customer;
			this.jobModel.jobCostingDetailModel = returnSalesOrder.jobCostingDetailModel;

			this.setInvoiceType();
		}
	}

	async openInvoice() {
		if (!this.jobModel.billToCustomer) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "A bill to customer is required for invoice",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		if (!this.jobModel.invoice || this.jobModel.invoice.invoiceId === 0) {
			const result = await this.createNewInvoiceRef.confirm();

			if (result === SlickConfirmDialogResults.Cancel)
			return;
		}

		this.jobModel.invoice = await this.jobsService.getJobInvoice(this.jobModel, DefaultInvoiceTypes.Invoice);

		if (!this.jobModel.invoiceId)
			this.jobModel.invoiceId = this.jobModel.invoice.invoiceId;

		const returnInvoice = await this.invoiceEditDialogRef.openDialog(this.jobModel.invoice);
		if (returnInvoice) {
			this.jobModel.invoice = returnInvoice;
			this.jobModel.billToCustomer = returnInvoice.customer;
			this.jobModel.jobCostingDetailModel = this.jobModel.invoice.jobCostingDetailModel;

			this.setInvoiceType();
		}
	}

	async openInvoicefromAppt(invoiceId: number ) {
		const invoice = await this.invoicesService.getInvoice(invoiceId);
		await this.invoiceEditDialogRef.openDialog(invoice);
	}

	async openJob(jobId: number) {
        this.jobModelForDialog = await this.jobsService.getJob(jobId)
		this.jobEditDialogRef.showDialog()
    }

	cancelDialogJob(){
		this.jobModelForDialog = null;
		this.jobEditDialogRef.hideDialog();
	}

	async saveDialogJob(){
		try {
			this.isSubmitted = true;

			// Check if job name is empty
			if (!this.jobModelForDialog.jobName) {
				return ; 
			}

			const isValid = await super.isValid();
			if (!isValid) {
				this.spinnerDialogStatus = 'error';
				return;
			}

			this.spinnerDialogStatus = 'spin';

			if (this.jobModelForDialog.appointments && this.jobModelForDialog.appointments.length > 0) {
				const conflictingAppts = await this.appointmentsService.checkForConflicts(this.jobModelForDialog.appointments);

				if (conflictingAppts) {
					const shouldSave = await this.conflictingApptDialogRef.showDialog(conflictingAppts);

					if (!shouldSave) {
						this.spinnerDialogStatus = 'error';
						return this.jobModelForDialog;
					}
				}
			}
			if (this.jobModelForDialog.jobStatusId === 7 && this.jobModelForDialog.invoiceId && this.jobModelForDialog.invoiceId > 0) {
				let deleteInvoicesLinkedToJob = await this.showDeleteInvoiceDialog();
				if (deleteInvoicesLinkedToJob)
					this.invoicesService.deleteInvoice(this.jobModelForDialog.invoiceId);
			}

			await SleepService.sleep(500);
			if (this.jobModelForDialog.jobId === 0) {
				this.jobModelForDialog = await this.jobsService.addJob(this.jobModelForDialog);
			} else {
				this.jobModelForDialog = await this.jobsService.updateJob(this.jobModelForDialog);
			}

			if (this.jobModelForDialog.jobCustomer === null || this.jobModelForDialog.jobCustomer.customerId === 0)
				this.jobModelForDialog.showOneTimeAddress = true;
			else
				this.jobModelForDialog.showJobSiteCustomer = true;
			this.spinnerDialogStatus = 'ok';
			this.isSubmitted = false;
			this.refreshHistory()
			this.onDialogJobSaved.emit(this.jobModelForDialog)
			return this.jobModelForDialog;

		} catch (err) {
			this.spinnerDialogStatus = 'error';
			throw (err);
		}
	}

	async onTabChanged(tabKey: string) {
		this.tabKey = tabKey;

		if (tabKey === 'Appt. History' && !this.customerJobHistory && this.jobModel.jobCustomer)
			this.refreshHistory();
		if (tabKey === 'Text Chat' && this.jobModel.jobCustomer) {
			this.getConversation();
		}

		if (this.tabKey === "Job Costing") {
			if (!this.jobCostingService.isValidJobCostingSetupModel(this.jobCostingSetupModel)) {
				this.isJobCostSetupValid = false;
				return;
			}
		}
	}

	async getConversation() {
		this.slickScreenBlockerService.forceBlock();
		this.textChatStore.clearActiveConversation();

		const textChatPhoneNumber = this.jobModel.jobCustomer.cellPhone || this.jobModel.jobCustomer.homePhone;

        if (!textChatPhoneNumber) {
            this.slickScreenBlockerService.forceUnblock();
            return;
        }

		const textChatConversation = await this.textChatStore.getConversationByPhoneNumber(textChatPhoneNumber);
		this.textChatStore.setActiveConversation(textChatConversation);
		this.slickScreenBlockerService.forceUnblock();

	}

	async generateJobCostingReport() {
		try {
			this.slickScreenBlockerService.forceBlock();

			this.jobModel.jobCostingDetailModel = await this.jobCostingService.generateJobCostingDetail(this.jobModel.invoiceId, this.jobModel.jobId);
			this.slickScreenBlockerService.forceUnblock();
			this.slickToastService.showSuccess("Report updated");
		}
		catch {
			this.slickScreenBlockerService.forceUnblock();

			this.slickToastService.showDanger("Error generating report")
		}

	}

	showDeleteInvoiceDialog(): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			this.resolve = resolve;
			this.deleteInvoiceDialogRef.showDialog();
		})
	}

	deleteLinkedInvoice() {
		this.deleteInvoiceDialogRef.hideDialog();
		this.resolve(true);
	}

	doNotDeleteInvoice() {
		this.deleteInvoiceDialogRef.hideDialog();
		this.resolve(false);
    }

	async saveJob() {
		try {
			this.isSubmitted = true;

			// Check if job name is empty
			if (!this.jobModel.jobName) {
				return ; 
			}

			const isValid = await super.isValid();
			if (!isValid) {
				this.spinnerStatus = 'error';
				return;
			}

			this.spinnerStatus = 'spin';

			if (this.jobModel.appointments && this.jobModel.appointments.length > 0) {
				const conflictingAppts = await this.appointmentsService.checkForConflicts(this.jobModel.appointments);

				if (conflictingAppts) {
					const shouldSave = await this.conflictingApptDialogRef.showDialog(conflictingAppts);

					if (!shouldSave) {
						this.spinnerStatus = 'error';
						return this.jobModel;
					}
				}
			}
			if (this.jobModel.jobStatusId === 7 && this.jobModel.invoiceId && this.jobModel.invoiceId > 0) {
				let deleteInvoicesLinkedToJob = await this.showDeleteInvoiceDialog();
				if (deleteInvoicesLinkedToJob)
					this.invoicesService.deleteInvoice(this.jobModel.invoiceId);
			}

			await SleepService.sleep(500);
			if (this.jobModel.jobId === 0) {
				this.jobModel = await this.jobsService.addJob(this.jobModel);
			} else {
				this.jobModel = await this.jobsService.updateJob(this.jobModel);
			}

			if (this.jobModel.jobCustomer === null || this.jobModel.jobCustomer.customerId === 0)
				this.jobModel.showOneTimeAddress = true;
			else
				this.jobModel.showJobSiteCustomer = true;
			this.onAppointmentSaved.emit(this.jobModel);
			this.spinnerStatus = 'ok';
			this.isSubmitted = false;
			return this.jobModel;

		} catch (err) {
			this.spinnerStatus = 'error';
			throw (err);
		}
	}

	cancelJob() {
		this.onCancel.emit(this.jobModel);
	}


	onAppointmentDelete(appointment: IAppointmentModel) {
		super.onAppointmentDelete(appointment);
		if (appointment.appointmentId) {
			this.scheduleDisplayService.removeAppointment(appointment.appointmentId);
			this.scheduleDisplayService.removeMiniScheduleAppointment(appointment.appointmentId);
		}
	}

	onMultiDayAppointmentsAdded(appointments: IAppointmentModel[]) {
		this.jobModel.appointments = [...this.jobModel.appointments, ...appointments];
    }

	async onAppointmentPrint(appointment: IAppointmentModel) {
		
		await this.saveJob();
		if (this.isValid()) {
			appointment = this.jobModel.appointments.find(x => x.uuid === appointment.uuid);
			const w = window.open('', '_blank');
			w.document.write('Generating pdf...');
			const url = await this.appointmentsService.generateAppointmentPdf(appointment);
			w.location.href = url;
        }
	}

	onExpandOrCollapse(appointment,index) {
		this.jobModel.appointments[index] = appointment;
	}

	expand(index){
		this.jobModel.appointments[index].uiState = 'expanded';
	}


	async refreshHistory() {
		this.slickScreenBlockerService.forceBlock();
		this.customerJobHistory = await this.customersService.getCustomerJobHistory(this.jobModel.jobCustomer.customerId, this.jobHistorySearchString);
		this.slickScreenBlockerService.forceUnblock();
	}

	onSearch(searchString: string) {
		this.jobHistorySearchString = searchString;
		this.refreshHistory();
	}

	private setInvoiceType() {
		this.isEstimate = false;
		this.isSalesOrder = false;
		this.isInvoice = false;

		const invoice = this.jobModel.invoice;

		if (invoice.invoiceId > 0) {
			this.isEstimate = invoice.isQuote;
			this.isSalesOrder = (invoice.isQuote === false && invoice.isSalesOrder === true);
			this.isInvoice = (invoice.isQuote === false && invoice.isSalesOrder === false);
		}
		else {
			// If this is a new invoice, default to sales order unless the job type says to default to another type
			this.isSalesOrder = true;

			// If the job type has the StartJobAsType flag set, this will start as an estimate, Sales Order or Invoice
			if (this.jobModel.jobTypeId) {
				const jobType = this.lookupService.getJobTypes(this.jobModel.jobTypeId).find(x => x.jobTypeId === this.jobModel.jobTypeId);

				if (jobType.startJobAsType === 'Estimate') {
					this.isEstimate = true;
					this.isSalesOrder = false;
					this.isInvoice = false;
				}

				if (jobType.startJobAsType === 'Sales Order') {
					this.isEstimate = false;
					this.isSalesOrder = true;
					this.isInvoice = false;
				}

				if (jobType.startJobAsType === 'Invoice') {
					this.isEstimate = false;
					this.isSalesOrder = false;
					this.isInvoice = true;
				}
			}
		}
	}
}
