import { IReceivedLineItemModel, IVendorModel, ILineItemModel } from "@models";
import { IInventoryWarehouseModel } from "../lookups/inventory-warehouse.model";

export interface IPurchaseOrderModel {
	purchaseOrderId: number;
	vendorId?: number;
	shippingInventoryWarehouseId?: number;
	shippingAddressId?: number;
	invoiceId?: number;
	lathamOrderId?: number;
	purchaseOrderNumber: string;
	serialNumber: string;
	purchaseOrderStatus: string;
	submittedDate?: Date;
	expectedReceivedDate?: Date;
	actualReceivedDate?: Date;
	shipDate?: Date;
	proNumber: string;
	notes: string;
	quickbooksId: string;
	syncToQuickbooks: boolean;
	businessCentralId: string;
	syncToBusinessCentral: boolean;
	receivingInventoryWarehouseId?: number;
	received?: boolean;
	receivedDate?: Date;
	receivedBy: string;
	sentFromInventoryWarehouseId?: number;
	totalCost: number;

	vendor: IVendorModel;
	shippingInventoryWarehouse: IInventoryWarehouseModel;
	receivingInventoryWarehouse: IInventoryWarehouseModel;
	sentFromInventoryWarehouse: IInventoryWarehouseModel;
	lineItems: ILineItemModel[];
	receivedLineItems: IReceivedLineItemModel[];
}

export class PurchaseOrderModel implements IPurchaseOrderModel {
	constructor() {
		this.purchaseOrderId = 0;
		this.purchaseOrderStatus = "Open";
		this.lineItems = [];
		this.receivedLineItems = [];
	}

	purchaseOrderId: number;
	vendorId?: number;
	shippingInventoryWarehouseId?: number;
	shippingAddressId?: number;
	invoiceId?: number;
	lathamOrderId?: number;
	purchaseOrderNumber: string;
	serialNumber: string;
	purchaseOrderStatus: string;
	submittedDate?: Date;
	expectedReceivedDate?: Date;
	actualReceivedDate?: Date;
	shipDate?: Date;
	proNumber: string;
	notes: string;
	quickbooksId: string;
	syncToQuickbooks: boolean;
	businessCentralId: string;
	syncToBusinessCentral: boolean;
	receivingInventoryWarehouseId?: number;
	received?: boolean;
	receivedDate?: Date;
	receivedBy: string;
	sentFromInventoryWarehouseId?: number;
	totalCost: number;

	vendor: IVendorModel;
	shippingInventoryWarehouse: IInventoryWarehouseModel;
	receivingInventoryWarehouse: IInventoryWarehouseModel;
	sentFromInventoryWarehouse: IInventoryWarehouseModel;

	lineItems: ILineItemModel[];
	receivedLineItems: IReceivedLineItemModel[];
}