import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { SlickUtilsService } from "../utils/slick-utils.service";

@Component({
	selector: 'slick-tab-header',
	template: `<i style='margin-right: 5px; font-size: .8em; position: relative; top: -.1em;' *ngIf='icon' class='far {{icon}}'></i><div class='slick-tab-header' #tabHeaderRef><ng-content></ng-content></div>`
})
export class SlickTabHeaderComponent {
	@ViewChild("tabHeaderRef") tabHeaderRef: ElementRef;

	uuid: string;
	icon: string;

	constructor() {
		this.uuid = SlickUtilsService.newGuid();
	}

	setHeader(header: string) {
		this.tabHeaderRef.nativeElement.innerHTML = header;
	}

	setIcon(icon: string) {
		this.icon = icon;
	}
}
