import { UtilsService } from "@services/utils/utils.service";

export interface ISystemModel {
    brandType: string;
    orderType: string;
    modelType: string;
    poolType: string;
    unitLocation: string;
    motorSide: string;
    trackSpaceFeet: number;
    trackSpaceInches: number;
	totalTrackSpace: number;
    trackLengthFeet: number;
	trackLengthInches: number;
	totalTrackLength: number;
	totalPerimeter: number;
    squareFeet: number;
    motorType: string;
    hydroMotorSize: string;
    hoseLength: number;
	controlType: string;
	is10x20: boolean;
	is12x24: boolean;
	is16x32: boolean;
	is18x36: boolean;
	is20x40: boolean;
	is22x50: boolean;
	isReallyBig: boolean;
}

export class SystemModel implements ISystemModel
{
	brandType: string;
	orderType: string;
	modelType: string;
	poolType: string;
	unitLocation: string;
	motorSide: string;
	trackSpaceFeet: number;
	trackSpaceInches: number;
	totalTrackSpace: number;
	trackLengthFeet: number;
	trackLengthInches: number;
	totalTrackLength: number;
	totalPerimeter: number;
	get squareFeet(): number {
		if (isNaN(this.trackSpaceFeet) ||
			isNaN(this.trackSpaceInches) ||
			isNaN(this.trackLengthFeet) ||
			isNaN(this.trackLengthInches))
			return null;

		var totalSqft = (((this.trackSpaceFeet * 12) + this.trackSpaceInches) * (this.trackLengthFeet * 12) + this.trackLengthInches) / 144;
		// Round Up
		totalSqft += .5;
		totalSqft = UtilsService.round(totalSqft, 0)
	}
	motorType: string;
	hydroMotorSize: string;
	hoseLength: number;
	controlType: string;
	is10x20: boolean;
	is12x24: boolean;
	is16x32: boolean;
	is18x36: boolean;
	is20x40: boolean;
	is22x50: boolean;
	isReallyBig: boolean;
}