import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SlickDialogModule } from "@slick-components";

import { ConflictingAppointmentsDialogComponent } from "./conflicting-appointments-dialog.component";


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SlickDialogModule
	],
	declarations: [ConflictingAppointmentsDialogComponent],
	exports: [ConflictingAppointmentsDialogComponent]
})
export class ConflictingAppointmentsDialogModule { }
