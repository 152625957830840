import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { DropdownModel, IDropdownModel, IInventoryItemLocationModel, IInventorySettingsModel, IInventoryWarehouseLocationsModel, IInventoryItemModel, IInventoryLocationsListModel, IInventoryLineItemModel, IInventoryItemLocationQuantityOnHandModel, IInventoryPickListModel, InventoryPickListModel, InventoryLineItemModel, IInventoryWarehouseModel } from "@models";
import * as moment from 'moment';
import { pick } from "lodash";

@Injectable()
export class InventoryService {

	constructor(private readonly httpService: HttpService) { }

	async getInventorySettings(): Promise<IInventorySettingsModel> {
		return this.httpService.get("/inventory/getInventorySettings");
	}

	async updateInventorySettings(inventorySettingsModel: IInventorySettingsModel): Promise<IInventorySettingsModel> {
		return this.httpService.post("/inventory/updateInventorySettings", inventorySettingsModel);
	}

	async getInventoryItem(itemId: number, inventoryWarehouseId: number, inventoryItemLocationId: number): Promise<IInventoryItemModel> {
		var params = {
			itemId,
			inventoryWarehouseId
		}

		if (inventoryItemLocationId)
			params["inventoryItemLocationId"] = inventoryItemLocationId;

		return this.httpService.get("/inventory/getInventoryItem", params);
	}

	async checkInventoryItemConcurrency(itemId: number, inventoryWarehouseId: number, inventoryItemLocationId: number, currentVersion: number): Promise<any> {
		const params = {
			itemId,
			inventoryWarehouseId,
			currentVersion
		}

		if (inventoryItemLocationId)
			params["inventoryItemLocationId"] = inventoryItemLocationId;

		return this.httpService.get("/inventory/checkInventoryItemConcurrency", params);
	}

	async inventoryItemCycleCount(itemId: number, inventoryWarehouseId: number, inventoryItemLocationId: number, inventoryLocationId: number, newQuantity: number): Promise<IInventoryItemLocationModel> {
		const params = {
			inventoryWarehouseId,
			itemId,
			newQuantity
		}

		if (inventoryItemLocationId)
			params["inventoryItemLocationId"] = inventoryItemLocationId;

		if (inventoryLocationId)
			params["inventoryLocationId"] = inventoryLocationId;

		return this.httpService.post("/inventory/inventoryItemCycleCount", params);
	}

	async transferInventory(itemId: number, fromInventoryWarehouseId: number, fromInventoryItemLocationId: number, toInventoryWarehouseId: number, toInventoryItemLocationId: number,toInventoryLocationId: number, transferQuantity: number): Promise<IInventoryItemModel> {
		const params = {
			fromInventoryWarehouseId,
			toInventoryWarehouseId,
			itemId,
			transferQuantity,
			toInventoryLocationId
		}

		if (fromInventoryItemLocationId > 0)
			params["fromInventoryItemLocationId"] = fromInventoryItemLocationId;

		if (toInventoryItemLocationId > 0)
			params["toInventoryItemLocationId"] = toInventoryItemLocationId;
		return this.httpService.put("/inventory/transferInventory", params);
	}

	async updateInventoryWarehouseLocations(inventoryWarehouseId: number, inventoryWarehouseLocations: IInventoryWarehouseLocationsModel): Promise<IInventoryWarehouseLocationsModel> {
		return this.httpService.post(`/inventory/updateInventoryWarehouseLocations?inventoryWarehouseId=${inventoryWarehouseId}`, inventoryWarehouseLocations);
	}

	async getLocationsForList(warehouseId: number, itemId: number, includeAllLocations: boolean, searchText: string): Promise<IInventoryLocationsListModel[]> {
		const params = {
			warehouseId: warehouseId,
			itemId: itemId,
			searchText: searchText,
			includeAllLocations: includeAllLocations
		};

		return await this.httpService.get("/inventory/GetLocationsForList", params);
	}
	async updateInventoryItemLocation(inventoryItemLocation: IInventoryItemLocationModel): Promise<IInventoryItemLocationModel> {

	 return await this.httpService.post("/inventory/updateInventoryItemLocation", inventoryItemLocation)
	}

	async updateInventoryItemLocations(inventoryItemLocations: IInventoryItemLocationModel[]): Promise<IInventoryItemLocationModel[]> {
		return await this.httpService.post("/inventory/updateInventoryItemLocations", inventoryItemLocations)
	}

	async getInventoryPickListForDate(date: Date): Promise<IInventoryPickListModel[]> {
		const params = {
			date: moment(date).format('YYYY-MM-DD')
		}
		return await this.httpService.get(`/inventory/getInventoryPickListForDate`, params);
	}

	async savePickList(pickListModels: IInventoryPickListModel[]):Promise<void> {
		await this.httpService.post(`/inventory/savePickList`, pickListModels);
	}

	async onLineItemChecked(inventoryLineItemModel: IInventoryLineItemModel, invoiceNumberString: string, showAllItemLocations: boolean): Promise<InventoryLineItemModel> {
		const params = {
			inventoryLineItemModel: inventoryLineItemModel,
			invoiceNumberString: invoiceNumberString,
			showAllItemLocations: showAllItemLocations
		}
		return await this.httpService.post(`/inventory/PerformCheckListTransfer`, params);
	}

	async getInventoryWarehouse(warehouseId: number): Promise<IInventoryWarehouseModel> {
		return await this.httpService.get(`/inventory/getinventorywarehouse?inventoryWarehouseId=${warehouseId}`);
	}

	async verifyAllocations(itemId: number, inventoryItemLocationId: number): Promise<number> {
		return await this.httpService.get(`/inventory/verifyAllocations?itemId=${itemId}&inventoryitemLocationId=${inventoryItemLocationId}`);
	}

	async updateAllocations(inventoryItemLocationId: number, newAllocationsAmount: number): Promise<boolean> {
		return await this.httpService.patch(`/inventory/updateAllocations?inventoryItemLocationId=${inventoryItemLocationId}&newAllocationsAmount=${newAllocationsAmount}`);
	}

	
}