export interface IChecklistQuestionLocationModel {
	locationId: number;
	locationName: string;
	isChecked: boolean;
}

export class ChecklistQuestionLocationModel implements IChecklistQuestionLocationModel {
	locationId: number;
	locationName: string;
	isChecked: boolean;
}