import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalsService } from "@services/utils/globals.service";
import { UtilsService } from "@services/utils/utils.service";
import { FunctionLockService } from './function-lock.service';

class DevTraceModel {
	traceGroupUuid: string;
	userName: string;
	appVersion: string;
	deviceType: string;
	traceText: string;
}

@Injectable()
export class DevTraceService {
	public static traceUuid: string;

	constructor(private http: HttpClient,
		private functionLockService: FunctionLockService) {
	}

	async addTrace(traceText: string): Promise<void> {
		try {
			if (GlobalsService.isOnline === false)
				return;

			//await this.functionLockService.lock('devTrace');

			//if (GlobalsService.isDebug) {
			//	//console.info(traceText); 
			//	return;
			//}

			if (!DevTraceService.traceUuid)
				DevTraceService.traceUuid = UtilsService.newGuid();

			const model = new DevTraceModel();
			model.traceGroupUuid = DevTraceService.traceUuid;

			if (GlobalsService.userInfo)
				model.userName = GlobalsService.userInfo.userName || 'N/A';
			else
				model.userName = "N/A";
			model.traceGroupUuid
			model.appVersion = GlobalsService.appVersion;
			model.deviceType = "Unknown";
			if (GlobalsService.isAndroid)
				model.deviceType = "Android";
			else if (GlobalsService.isiOS)
				model.deviceType = "iOS";
			model.traceText = traceText;
			await this.http.post(`/devTrace/addTrace`, model).toPromise();

			//this.functionLockService.release('devTrace');
		}
		finally { }
	}
}
