export interface ITexterModel {
    numbers: string;
    body: string;
    uuid: string;
    type: string;
    lathamOrderId?: number;
}

export class TexterModel implements ITexterModel {
    numbers: string;
    body: string;
    uuid: string;
    type: string;
    lathamOrderId?: number;
}