import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { INotificationModel } from "@models";

@Injectable()
export class NotificationsService {
	constructor(private httpService: HttpService) { }

	getActiveNotifications(): Promise<INotificationModel[]> {
		try {
			return this.httpService.get("/notifications/getActiveNotifications");
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	clearAllNotifications(): Promise<string> {
		try {
			return this.httpService.get("/notifications/clearAllNotifications");
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	clearNotification(notificationId): Promise<string> {
		try {
			return this.httpService.get("/notifications/clearNotification", { notificationId: notificationId });
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}
}