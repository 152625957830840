import { Injectable } from '@angular/core';
import { FunctionLockService, HttpService, InventoryService, UtilsService } from '@services';
import { DropdownModel, IDropdownModel, IInventoryLocationModel, IInventoryItemLocationModel, IInventoryLineItemModel, IInventoryTypeModel, IInventoryWarehouseItemLocationsModel, IInventoryWarehouseLocationsModel, IInventoryWarehouseModel, ILineItemModel, InventoryTypeModel, IReceivedLineItemModel, LineItemTypes, IInventoryItemLocationQuantityOnHandModel } from '../models';
import { sortBy } from "sort-by-typescript";

export class InventoryWarehouseItemLocationsModel implements IInventoryWarehouseItemLocationsModel {
	warehouseId: number;
	itemId: number;
	itemLocations: IInventoryItemLocationModel[];
	globalLocations: IInventoryLocationModel[];
}

@Injectable()
export class InventoryStore {

	private static _inventoryWarehouses: IInventoryWarehouseModel[];
	private static _inventoryWarehouseLocations: IInventoryWarehouseLocationsModel[] = [];
	private static _inventoryWarehouseItemLocations: IInventoryWarehouseItemLocationsModel[];
	private static _inventoryLocations: IInventoryLocationModel[];
	constructor(private httpService: HttpService) {
		InventoryStore._inventoryWarehouseItemLocations = [];
	}

	async init(): Promise<void> {
		return new Promise<void>(async (res) => {
			const result = await Promise.all([
				this.httpService.get("/inventory/getInventoryWarehouses"),
				this.httpService.get("/inventory/getInventoryLocations")
			])

			InventoryStore._inventoryWarehouses = result[0];
			InventoryStore._inventoryLocations = result[1];

			res();
		});
		
	}


	async getQuantitiesOnHand(itemLocationIds: number[]): Promise<IInventoryItemLocationQuantityOnHandModel[]> {
		return await this.httpService.post("/inventory/GetInventoryItemLocationQuantityOnHand", itemLocationIds);
	}

	async checkInventoryItemLocationsForWarehouse(locationIds: number[]): Promise<IInventoryItemLocationModel[]> {
		return await this.httpService.get(`/inventory/checkInventoryItemLocationsForWarehouse?locationIds=${locationIds}`);
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Inventory Warehouses
	////////////////////////////////////////////////////////////////////////////////////////////////
	get inventoryWarehouses(): IInventoryWarehouseModel[] {
		return <IInventoryWarehouseModel[]>UtilsService.clone(InventoryStore._inventoryWarehouses);
	}

	public getInventoryWarehousesForDropdown(inventoryWarehouseId?: number): IDropdownModel[] {
		
		const activeWarehouses = InventoryStore._inventoryWarehouses.filter(x => x.active === true);
		if (inventoryWarehouseId != null && !activeWarehouses.find(x => x.inventoryWarehouseId === inventoryWarehouseId)) {
			const selectedInventoryWarehouse = InventoryStore._inventoryWarehouses.find(x => x.inventoryWarehouseId === inventoryWarehouseId);
			if (selectedInventoryWarehouse)
				activeWarehouses.unshift(selectedInventoryWarehouse);
		}
		const dropDown = activeWarehouses.map(x => new DropdownModel(x.inventoryWarehouseId, x.warehouseName, x.isDefault));
		return dropDown;
	}

	public async refreshInventoryWarehouses(): Promise<IInventoryWarehouseModel[]> {
		const inventoryWarehouses = await this.httpService.get("/inventory/getInventoryWarehouses");
		InventoryStore._inventoryWarehouses = inventoryWarehouses;
		return inventoryWarehouses;
	}

	public async updateInventoryWarehouse(inventoryWarehouseModel: IInventoryWarehouseModel): Promise<IInventoryWarehouseModel> {
		const updatedInventoryWarehouse = await this.httpService.post("/inventory/updateInventoryWarehouse", inventoryWarehouseModel);

		if (inventoryWarehouseModel.inventoryWarehouseId === 0)
			InventoryStore._inventoryWarehouses.unshift(updatedInventoryWarehouse);
		else {
			const idx = InventoryStore._inventoryWarehouses.findIndex(x => x.inventoryWarehouseId === updatedInventoryWarehouse.inventoryWarehouseId)
			if (idx >= 0)
				InventoryStore._inventoryWarehouses[idx] = updatedInventoryWarehouse;
		}

		return updatedInventoryWarehouse;
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Inventory Global Locations
	////////////////////////////////////////////////////////////////////////////////////////////////
	get inventoryLocations(): IInventoryLocationModel[] {
		return <IInventoryLocationModel[]>UtilsService.clone(InventoryStore._inventoryLocations);
	}

	public getInventoryLocationsForDropdown(inventoryLocationId?: number): IDropdownModel[] {
		const activeInventoryLocations = InventoryStore._inventoryLocations.filter(x => x.active === true);
		if (inventoryLocationId != null && !activeInventoryLocations.find(x => x.inventoryLocationId === inventoryLocationId)) {
			const selectedInventoryLocation = InventoryStore._inventoryLocations.find(x => x.inventoryLocationId === inventoryLocationId);
			if (selectedInventoryLocation)
				activeInventoryLocations.unshift(selectedInventoryLocation);
		}

		const dropDown = activeInventoryLocations.map(x => new DropdownModel(x.inventoryWarehouseId, x.locationName));
		return dropDown;
	}

	public async updateInventoryLocations(inventoryLocationModels: IInventoryLocationModel[]): Promise<IInventoryLocationModel[]> {
		InventoryStore._inventoryLocations = await this.httpService.post("/inventory/updateInventoryLocations", inventoryLocationModels);
		return [...InventoryStore._inventoryLocations];
	}


	////////////////////////////////////////////////////////////////////////////////////////////////
	// Inventory Warehouse Locations
	////////////////////////////////////////////////////////////////////////////////////////////////
	public async getInventoryWarehouseLocations(inventoryWarehouseId: number): Promise<IInventoryWarehouseLocationsModel> {
		let inventoryWarehouseLocations = InventoryStore._inventoryWarehouseLocations.find(x => x.inventoryWarehouseId === inventoryWarehouseId);
		if (!inventoryWarehouseLocations) {
			inventoryWarehouseLocations = await this.httpService.get(`/inventory/getInventoryWarehouseLocations?inventoryWarehouseId=${inventoryWarehouseId}`);
			InventoryStore._inventoryWarehouseLocations.push(inventoryWarehouseLocations);
		}

		return <IInventoryWarehouseLocationsModel>UtilsService.clone(inventoryWarehouseLocations);
	}

	public async refreshInventoryWarehouseLocations(inventoryWarehouseId: number): Promise<IInventoryItemLocationModel> {
		const inventoryWarehouseLocations = await this.httpService.get(`/inventory/getInventoryWarehouseLocations?inventoryWarehouseId=${inventoryWarehouseId}`);

		const idx = InventoryStore._inventoryWarehouseLocations.findIndex(x => x.inventoryWarehouseId === inventoryWarehouseId);
		InventoryStore._inventoryWarehouseLocations[idx] = inventoryWarehouseLocations;

		return inventoryWarehouseLocations;
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Inventory Warehouse Item Locations
	////////////////////////////////////////////////////////////////////////////////////////////////
	public async populateLineItemLocations(inventoryWarehouseId: number, lineItems: ILineItemModel[]): Promise<ILineItemModel[]> {
		const inventoryItemLocationPromises: Promise<IInventoryWarehouseItemLocationsModel>[] = [];
		if (!InventoryStore._inventoryLocations)
			InventoryStore._inventoryLocations = await this.httpService.get("/inventory/getInventoryLocations");
		const globalInventoryLocations = InventoryStore._inventoryLocations.filter(x => x.inventoryWarehouseId === inventoryWarehouseId);
		const globalInventoryLocationDropDowns = globalInventoryLocations.map(x => new DropdownModel(x.inventoryLocationId, x.locationName, "Global"));
		
		lineItems.forEach(li => {
			if ((li.lineItemType || LineItemTypes.LineItem) === LineItemTypes.LineItem && li.itemId && (li.itemInventoryType ?? 0) === 1) {
				inventoryItemLocationPromises.push(this.getInventoryWarehouseItemLocations(li.itemId, inventoryWarehouseId));
			}
		});

		const response: IInventoryWarehouseItemLocationsModel[] = await Promise.all(inventoryItemLocationPromises);

        lineItems.forEach(li => {
            li.inventoryItemLocations = globalInventoryLocationDropDowns;

            const item = response.find(x => x.itemId === li.itemId);
            if (item?.itemLocations)
                li.inventoryItemLocations = li.inventoryItemLocations.concat(item.itemLocations.map(x => new DropdownModel(x.inventoryItemLocationId, x.locationName)));

            li.inventoryItemLocations = li.inventoryItemLocations.sort(sortBy("^locationName"));
        });
		
	
		return lineItems;
	}

	public async populateInventoryLineItemLocations(inventoryWarehouseId: number, inventoryLineItems: IInventoryLineItemModel[]): Promise<IInventoryLineItemModel[]> {
		const inventoryItemLocationPromises: Promise<IInventoryWarehouseItemLocationsModel>[] = [];

		if (!inventoryWarehouseId)
			return [];
		inventoryLineItems.forEach(li => {
			if (li.itemId) {
				inventoryItemLocationPromises.push(this.getInventoryWarehouseItemLocations(li.itemId, inventoryWarehouseId));
			}
		});

		const response: IInventoryWarehouseItemLocationsModel[] = await Promise.all(inventoryItemLocationPromises);
		response.forEach(res => {
			const li = inventoryLineItems.find(x => x.itemId === res.itemId);
			if (li) {
				li.inventoryItemLocations = [];
				if (res.itemLocations)
					li.inventoryItemLocations = res.itemLocations.map(x => new DropdownModel(x.inventoryItemLocationId, x.locationName));
			}
		});

		return inventoryLineItems;
	}

	public async getInventoryItemLocations(itemId: number, inventoryWarehouseId: number): Promise<IInventoryItemLocationModel[]> {
		const inventoryWarehouseItemLocations = await this.getInventoryWarehouseItemLocations(itemId, inventoryWarehouseId);
		return inventoryWarehouseItemLocations.itemLocations; 
	}

	async getInventoryItemLocationsForDropdown(itemId: number, inventoryWarehouseId: number): Promise<IDropdownModel[]> {
		if (!itemId || !inventoryWarehouseId)
			return [];

		const inventoryItemLocations: IInventoryItemLocationModel[] = await this.getInventoryItemLocations(itemId, inventoryWarehouseId);
		if (inventoryItemLocations === null)
			return [];

		return inventoryItemLocations.map(x => new DropdownModel(x.inventoryItemLocationId, x.locationName))
	}

	async getShippingInventoryItemLocationsForDropdown(itemId: number, inventoryWarehouseId: number): Promise<IDropdownModel[]> {
		if (!itemId || !inventoryWarehouseId)
			return [];

		const shippingInventoryItemLocations: IInventoryItemLocationModel[] = ((await this.getInventoryItemLocations(itemId, inventoryWarehouseId)).filter(x => x.isShippingLocation));
		if (shippingInventoryItemLocations === null)
			return [];

		return shippingInventoryItemLocations.map(x => new DropdownModel(x.inventoryItemLocationId, x.locationName))
	}

	async updateInventoryItemLocation(inventoryItemLocationModel: IInventoryItemLocationModel) {
		// Clear the cache
		InventoryStore._inventoryWarehouseItemLocations = InventoryStore._inventoryWarehouseItemLocations.filter(x => !(x.warehouseId === inventoryItemLocationModel.inventoryWarehouseId && x.itemId === inventoryItemLocationModel.itemId));
		return this.httpService.post(`inventory/updateInventoryItemLocation`, inventoryItemLocationModel);
	}

	private async getInventoryWarehouseItemLocations(itemId: number, inventoryWarehouseId: number): Promise<IInventoryWarehouseItemLocationsModel> {
		return new Promise<IInventoryWarehouseItemLocationsModel>(async (res) => {
			const inventoryWarehouseItemLocation = InventoryStore._inventoryWarehouseItemLocations.find(x => x.warehouseId === inventoryWarehouseId && x.itemId === itemId);			
			if (inventoryWarehouseItemLocation)
				res(UtilsService.clone(inventoryWarehouseItemLocation));
			else {
				const params = {
					itemId: itemId,
					inventoryWarehouseId: inventoryWarehouseId
				};
				const itemLocations: IInventoryItemLocationModel[] = await this.httpService.get("/inventory/getInventoryItemLocations", params);
				const newInventoryWarehouseItemLocation = new InventoryWarehouseItemLocationsModel();
				newInventoryWarehouseItemLocation.itemId = itemId;
				newInventoryWarehouseItemLocation.warehouseId = inventoryWarehouseId;
				newInventoryWarehouseItemLocation.itemLocations = itemLocations;
				if (itemLocations.length === 0)
					newInventoryWarehouseItemLocation.itemLocations = null;
				InventoryStore._inventoryWarehouseItemLocations.push(newInventoryWarehouseItemLocation);
				res(newInventoryWarehouseItemLocation);
			}
		});
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	// Inventory Types
	////////////////////////////////////////////////////////////////////////////////////////////////
	public getInventoryTypes(): IInventoryTypeModel[] {
		return <InventoryTypeModel[]>[
			{ inventoryTypeId: 1, description: 'Inventory' },
			{ inventoryTypeId: 2, description: 'Non Inventory' },
			{ inventoryTypeId: 3, description: 'Service' },
			{ inventoryTypeId: 4, description: 'Discount' },
			{ inventoryTypeId: 5, description: 'Other' },
		];
	}

	public getInventoryTypesForDropdown(): IDropdownModel[] {
		return this.getInventoryTypes().map(x => new DropdownModel(x.inventoryTypeId, x.description));
	}

}
