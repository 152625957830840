import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { SlickValidationIndicatorDirective, SlickValidationTypes } from "./slick-validation-indicator.directive";

@NgModule({
	imports: [CommonModule],
	declarations: [SlickValidationIndicatorDirective],
	exports: [CommonModule, SlickValidationIndicatorDirective]
})
export class SlickValidationIndicatorModule { }
export { SlickValidationIndicatorDirective, SlickValidationTypes }
