import { EventEmitter, Input, OnChanges, OnInit, Output, Directive } from '@angular/core';
import { DropdownModel, IAppointmentModel, IDropdownModel, AppointmentChecklistQuestionModel, AppointmentSmartlistQuestionModel } from '@models';
import { GlobalsService, InvoicesService, JobsService, LookupService, SleepService } from '@services';
import * as moment from 'moment';

@Directive()
export class AppointmentEditBase implements OnInit, OnChanges {
	@Input() appointment: IAppointmentModel;
	@Input() isSalesOrder: boolean;
	@Input() isInvoice: boolean;
	@Input() submitted: boolean;
	@Input() showDeleteButton: boolean;
	@Output('appointmentChange') appointmentChange = new EventEmitter<IAppointmentModel>();
	@Output() onAppointmentDelete: EventEmitter<IAppointmentModel> = new EventEmitter<IAppointmentModel>();
	jobTypes: IDropdownModel[];
	appointmentTypes: IDropdownModel[];
	appointmentStatuses: IDropdownModel[];
	resources: IDropdownModel[];
	startTimeItems: IDropdownModel[];
	durationItems: IDropdownModel[];
	travelItems: IDropdownModel[];

	inTransitDate: Date;
	jobsiteDate: Date;
	completedDate: Date;
	scheduledDate: Date;
	startTime = '';
	inTransitStartTime = '';
	jobsiteStartTime = '';
	completedStartTime = '';

	canCompleteAppointment: boolean = GlobalsService.checkPermission('Appointments', "CompleteAppointment");


	constructor(protected readonly lookupService: LookupService,
		protected readonly jobsService: JobsService,
		protected readonly invoicesService: InvoicesService) {
	}

	ngOnInit() {
		this.jobTypes = this.lookupService.getJobTypesForDropdown();
		this.appointmentTypes = this.lookupService.getAppointmentTypesForDropdown();
		this.appointmentStatuses = this.lookupService.getAppointmentStatusesForDropdown();
		
		if (!(GlobalsService.company.companyId === 1 && this.appointment.appointmentTypeDisplayName === "Estimate")){
			if (!(this.appointment.appointmentStatusId === this.lookupService.getAppointmentStatuses().find(x => x.description === "Complete").appointmentStatusId) && !this.canCompleteAppointment) {
				this.appointmentStatuses = this.appointmentStatuses.filter(x => x.text !== "Complete");
			}
		}
		// Only get the resources that this user has access to by location
		this.resources = this.lookupService.getAvailableResourcesForDropdown(this.appointment.resourceId)
		
		this.startTimeItems = [];
		let startTime = moment().startOf("day");
		for (let i = 0; i < 96; i++) {
			const time = startTime.format("h:mm A");
			this.startTimeItems.push(new DropdownModel(time, time));
			startTime = startTime.add(15, "minutes");
		}
		this.durationItems = this.renderTimesList(8);
		this.travelItems = this.renderTimesList(8);
		this.travelItems.unshift(new DropdownModel(0, "0:00"));
		this.appointment.crewDisplay = null;
		if (this.appointment.actualCrew) {
			const userIds: number[] = JSON.parse(this.appointment.actualCrew);
			const users = this.lookupService.getUsers(null, true)
				.filter(x => userIds.includes(x.userId))
				.map(u => { return u.fullName });
			this.appointment.crewDisplay = users.join(", ");
		}
		else {
			if (this.appointment.resourceId)
				this.appointment.crewDisplay = this.lookupService.getResources(this.appointment.resourceId).find(x => x.resourceId === this.appointment.resourceId)?.crewMembersDisplay;
		}
	}

	async ngOnChanges() {
		if (this.appointment) {
			this.appointment.checklistQuestions = this.appointment.checklistQuestions || [];
			this.appointment.smartlistQuestions = this.appointment.smartlistQuestions || [];

			this.resources = this.lookupService.getResourcesForDropdown(this.appointment.resourceId);

			if (this.appointment.scheduledDateTime) {
				this.scheduledDate = moment(this.appointment.scheduledDateTime).startOf("day").toDate();
				this.startTime = moment(this.appointment.scheduledDateTime).format('h:mm A');
			}

			if (this.appointment.inTransitStartTime) {
				this.inTransitDate = moment(this.appointment.inTransitStartTime).startOf("day").toDate();
				this.inTransitStartTime = moment(this.appointment.inTransitStartTime).format('h:mm A');
			}

			if (this.appointment.atJobsiteStartTime) {
				this.jobsiteDate = moment(this.appointment.atJobsiteStartTime).startOf("day").toDate();
				this.jobsiteStartTime = moment(this.appointment.atJobsiteStartTime).format('h:mm A');
			}

			if (this.appointment.finishedTime) {
				this.completedDate = moment(this.appointment.finishedTime).startOf("day").toDate();
				this.completedStartTime = moment(this.appointment.finishedTime).format('h:mm A');
			}
		}
	}
	async onScheduledDateChange(date: Date) {
		await SleepService.sleep();

		if (date === null) {
			this.appointment.scheduledDateTime = null;
		}
		else {
			this.scheduledDate = moment(date).startOf("day").toDate();
			this.appointment.scheduledDateTime = date;

			if (this.startTime) {
				const temp = `${moment(date).format("YYYY-MM-DD")} ${this.startTime}`;
				this.appointment.scheduledDateTime = moment(temp, 'YYYY-MM-DD h:mma').toDate()
			}
		}
	}

	async onTransitDateChange(date: Date) {
		await SleepService.sleep();

		if (date === null) {
			this.appointment.inTransitStartTime = null;
		}
		else {
			this.inTransitDate = moment(date).startOf("day").toDate();
			this.appointment.inTransitStartTime = date;
		}
	}
	async onJobsiteDateChange(date: Date) {
		await SleepService.sleep();

		if (date === null) {
			this.appointment.atJobsiteStartTime = null;
		}
		else {
			this.jobsiteDate = moment(date).startOf("day").toDate();
			this.appointment.atJobsiteStartTime = date;
		}
	}
	async onCompleteDateChange(date: Date) {
		await SleepService.sleep();

		if (date === null) {
			this.appointment.finishedTime = null;
		}
		else {
			this.completedDate = moment(date).startOf("day").toDate();
			this.appointment.finishedTime = date;
		}
	}

	onStartTimeSelected(startTime: IDropdownModel) {
		if (!startTime) {
			this.startTime = null;
			this.appointment.scheduledDateTime = null;
		}
		else {
			this.startTime = startTime.id;

			if (this.startTime) {
				const temp = `${moment(this.scheduledDate).format("YYYY-MM-DD")} ${this.startTime}`;
				this.appointment.scheduledDateTime = moment(temp, 'YYYY-MM-DD h:mma').toDate()

			}
			else
				this.appointment.scheduledDateTime = null;
		}
	}


	renderTimesList(maxHours: number): IDropdownModel[] {
		const times = new Array<IDropdownModel>();

		for (let h = 0; h < maxHours; h++) {
			for (let m = 0; m < 60; m += 15) {

				if (h === 0 && m === 0) {
					continue;
				}

				const time = `${h}:${m === 0 ? '00' : m}`;
				const id = h * 60 + m;
				times.push(new DropdownModel(id, time));
			}
		}

		times.push(new DropdownModel(480, `${maxHours}:00`));

		return times;
	}

	async onAppointmentTypeSelect(appointmentType: IDropdownModel) {
		await SleepService.sleep();

		if (!appointmentType) {
			this.appointment.appointmentTypeDisplayName = null;
			return;
		}

		this.appointment.appointmentTypeDisplayName = appointmentType.text;
		
		if (!this.appointment.appointmentId && !this.appointment.lockDuration)
			this.appointment.scheduledMinutes = this.lookupService.getAppointmentTypes().find(x => x.appointmentTypeId == this.appointment.appointmentTypeId).defaultMinutes;

		const defaultAppt = this.lookupService.getAppointmentTypes().find(x => x.appointmentTypeId === appointmentType.id);

		this.appointment.sendReminder = defaultAppt.sendReminders;

		if (!this.appointment.description)
			this.appointment.description = '';
		this.appointment.description += defaultAppt.defaultDescription || '';

		if (!this.appointment.notes)
			this.appointment.notes = '';
		this.appointment.notes += defaultAppt.defaultNotes || '';

		// Get the checklist questions

		// Any custom questions that were entered, keep
		const customChecklistQuestions = this.appointment.checklistQuestions.filter(x => (x.checklistQuestionId || 0) === 0);
		const checklistQuestions = this.lookupService.getChecklistQuestions().filter(c => c.appointmentTypeId === appointmentType.id);
		const resource = this.lookupService.getResources(this.appointment.resourceId).find(x => x.resourceId === this.appointment.resourceId);
		this.appointment.checklistQuestions = checklistQuestions.map(c => {
			// don't add this question if the resource location is not one of the locations selected
			if (c.locations && resource && resource.locationId) {
				if (c.locations.findIndex(x => x === resource.locationId) < 0)
					return null;
			}

			const appointmentChecklistQuestion = new AppointmentChecklistQuestionModel();
			appointmentChecklistQuestion.appointmentChecklistQuestionId = 0;
			appointmentChecklistQuestion.checklistQuestionId = c.checklistQuestionId;
			appointmentChecklistQuestion.question = c.question;
			appointmentChecklistQuestion.checklistStatus = c.checklistStatus;
			appointmentChecklistQuestion.isChecked = false
			appointmentChecklistQuestion.checkedBy = null;
			return appointmentChecklistQuestion;
		})
			.filter(x => x !== null);
		this.appointment.checklistQuestions = this.appointment.checklistQuestions.concat(customChecklistQuestions);

		// Get the smartlist questions
		this.appointment.showOnSmartlist = this.lookupService.getAppointmentTypes().find(x => x.appointmentTypeId === this.appointment.appointmentTypeId).showOnSmartlist;
		if (this.appointment.showOnSmartlist) {
			const apptTypeSmartlistQuestions = this.lookupService.getAppointmentTypes().find(x => x.appointmentTypeId === appointmentType.id).appointmentTypeSmartlistQuestions.filter(x => x.isChecked);
			
			this.appointment.smartlistQuestions = apptTypeSmartlistQuestions.map(x => {
				const existingQuestion = this.appointment.smartlistQuestions.find(q => q.smartlistQuestionId === x.smartlistQuestionId);

				const isChecked = (existingQuestion && existingQuestion.isChecked === true) ? true : false;
				return new AppointmentSmartlistQuestionModel(x.smartlistQuestionId, x.question, isChecked);
			})
		}
	}

	async onAppointmentStatusSelect(appointmentStatus: IDropdownModel) {
		await SleepService.sleep();

		if (!appointmentStatus)
			this.appointment.appointmentStatusDisplayName = "";
		else
			this.appointment.appointmentStatusDisplayName = appointmentStatus.text;
		if (this.appointment.appointmentStatusDisplayName === "Unscheduled")
			this.appointment.unscheduled = true;
	}

	async onResourceSelect(resource: IDropdownModel) {
		await SleepService.sleep();

		if (!resource)
			this.appointment.resourceDisplayName = "";
		else
			this.appointment.resourceDisplayName = resource.text;
	}

	deleteAppointment(appointment: IAppointmentModel) {
		this.onAppointmentDelete.emit(appointment);
	}
}
