import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { AddressSearchModule } from "@shared-components/address-search";
import { CustomerDisplayModule } from "@components/customer-display";
import { SlickButtonSpinnerModule, SlickDialogModule, SlickDropDownModule } from "@slick-components";

import { ShippingAddressComponent } from "./shipping-address.component";
import { ShippingAddressDialogComponent } from "./shipping-address-dialog.component";

@NgModule({
	imports: [
		CommonModule,		
		FormsModule,
		AddressSearchModule,
		CustomerDisplayModule,
		SlickButtonSpinnerModule, SlickDialogModule, SlickDropDownModule
	],
	declarations: [		
		ShippingAddressComponent,
		ShippingAddressDialogComponent
	],
	exports: [ShippingAddressComponent]
})
export class ShippingAddressModule {
}

export { ShippingAddressComponent }