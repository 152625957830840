import { Component, ViewChild } from '@angular/core';
import { SlickDialogComponent, SlickToastService } from "@slick-components";
import { IInvoiceModel } from '@models';
import { SleepService, InvoicesService, GlobalsService, LookupService } from '@services';
import { SalesOrderEditComponent } from '../sales-order-edit/sales-order-edit.component';
import Swal from 'sweetalert2';

@Component({
	selector: 'sales-order-edit-dialog',
	templateUrl: 'sales-order-edit-dialog.component.html',
	providers: [InvoicesService]
})
export class SalesOrderEditDialogComponent {
	@ViewChild('dialogRef', { static: true }) dialogRef: SlickDialogComponent;
	@ViewChild('salesOrderEditComponentRef', { static: true }) salesOrderEditComponentRef: SalesOrderEditComponent;

	salesOrderModel: IInvoiceModel;
	defaultApptId: number;
	spinnerStatus: string;
	resolve: any;
	canMoveToEstimate: boolean = GlobalsService.checkPermission("SalesOrders", "CanMoveToEstimate");

	constructor(private invoicesService: InvoicesService,
		private slickToastService: SlickToastService,
	private lookupService: LookupService) {
	}

	openDialog(salesOrderModel: IInvoiceModel): Promise<IInvoiceModel> {
		return new Promise((resolve) => {
			this.resolve = resolve;
			this.spinnerStatus = 'reset';

			this.salesOrderModel = salesOrderModel;
			this.dialogRef.showDialog();
			if (GlobalsService.company.useAdvancedTax && !GlobalsService.company.useCereTax && !this.salesOrderModel.customer.taxRegionId && (this.lookupService.getTaxRegions().length ?? 0) > 0 && GlobalsService.company.companyId === 3) {
				Swal.fire({
					icon: 'warning',
					title: 'Warning',
					text: 'Customer has no tax region',
					confirmButtonColor: '#007bff',
					width: '28em',
					heightAuto: false
				});
			}
		});
	}

	async saveSalesOrder() {
		try {
			this.salesOrderEditComponentRef.isSubmitted = true;
			
			if (this.salesOrderEditComponentRef.isValid() === false) {
				this.spinnerStatus = 'error';
				return;
			}

			this.spinnerStatus = 'spin';

			await SleepService.sleep(500);

			if (this.salesOrderEditComponentRef.useInventory && this.salesOrderModel.useDefaultInventory) {
				this.salesOrderModel.inventoryLineItems = this.invoicesService.syncInventoryFromLineItems(this.salesOrderModel);
			}

			this.salesOrderModel = await this.invoicesService.updateInvoice(this.salesOrderModel);

			this.spinnerStatus = 'ok';

			await SleepService.sleep(500);

			this.salesOrderEditComponentRef.isSubmitted = false;
			this.resolve(this.salesOrderModel);
			this.dialogRef.hideDialog();
		} catch {
			this.spinnerStatus = 'error';
		}
	}

	async cancelSalesOrder() {
		await this.dialogRef.hideDialog();
		this.resolve(null);
	}

	onSave(salesOrderModel: IInvoiceModel) {
		this.salesOrderModel = salesOrderModel;
	}

	async convertToEstimate() {
		this.salesOrderModel = await this.salesOrderEditComponentRef.convertToEstimate();

		this.salesOrderEditComponentRef.isSubmitted = false;
		this.resolve(this.salesOrderModel);
		this.dialogRef.hideDialog();
	}

	async convertToInvoice() {
		this.salesOrderModel = await this.salesOrderEditComponentRef.convertToInvoice();
	}

	async generateSalesOrderPdf() {
		if (this.salesOrderModel.invoiceId === 0) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please save the sales order before printing",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}
		this.salesOrderEditComponentRef.generateSalesOrderPdf();
	}

	async generateWorkOrderPdf() {
		if (this.salesOrderModel.invoiceId === 0) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please save the sales order before printing",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}
		this.salesOrderEditComponentRef.generateWorkOrderPdf();
	}

	async textSalesOrder() {
		if (this.salesOrderModel.invoiceId === 0) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please save the sales order before texting",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}
		await this.salesOrderEditComponentRef.textSalesOrder();
	}

	async emailSalesOrder() {
		if (this.salesOrderModel.invoiceId === 0) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please save the sales order before emailing",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}
		this.salesOrderEditComponentRef.emailSalesOrder();
	}

	async prePayment() {
		if (this.salesOrderModel.invoiceId === 0) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please save the sales order before pre-payment",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}
		this.salesOrderEditComponentRef.prePayment();
	}
}
