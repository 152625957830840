import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {AppointmentUserStrikeModel, IAppointmentUserStrikeModel, IAppointmentUserStrikeTypeModel, IDropdownModel, IUserLookupModel, IUserModel} from "@models";
import {AppointmentUserStrikesService, GlobalsService, LookupService} from "@services";

@Component({
    selector: "marks-edit",
    templateUrl: "./marks-edit.component.html",
    styleUrls: ["./marks-edit.component.scss"],
    providers: [AppointmentUserStrikesService, LookupService]
})

export class MarksEditComponent implements OnInit, OnChanges {
    @Input() appointmentId: number;
    @Output("onSave") onSave = new EventEmitter<any>();
    @Output("onCancel") onCancel = new EventEmitter<any>();

    appointmentUserStrikes: IAppointmentUserStrikeModel[];
    appointmentUserStrikeTypes: IDropdownModel[];
    users: IUserLookupModel[];
    newStrike: IAppointmentUserStrikeModel;
    badMark: boolean = false;

    constructor(private readonly appointmentUserStrikesService: AppointmentUserStrikesService,
                private readonly lookupService: LookupService) {
        this.users = this.lookupService.getUsers()
    }

    async ngOnInit() {
        this.appointmentUserStrikeTypes = await this.appointmentUserStrikesService.getAppointmentUserStrikeTypesForDropDown();
	}

    async ngOnChanges(changes: SimpleChanges) {
        if (this.appointmentId) {
            this.appointmentUserStrikes = await this.appointmentUserStrikesService.getAppointmentUserStrikes(this.appointmentId);

            if (this.appointmentUserStrikes == null)
                this.appointmentUserStrikes = new Array<IAppointmentUserStrikeModel>();
        }
    }

    createStrike() {
        this.newStrike = new AppointmentUserStrikeModel();
        this.newStrike.awardedDate = new Date();
        this.newStrike.awardedBy = GlobalsService.userInfo.fullName;
        this.newStrike.appointmentId = this.appointmentId;
    }

    async save() {
        this.newStrike.badStrike = this.badMark;
        const newStrike = await this.appointmentUserStrikesService.addAppointmentUserStrike(this.newStrike);
        this.appointmentUserStrikes.unshift(newStrike);
        this.appointmentUserStrikes = await this.appointmentUserStrikesService.getAppointmentUserStrikes(this.appointmentId);
        this.onSave.emit();
        this.newStrike = null;
    }

    async cancel() {
        this.newStrike = null;
        this.onCancel.emit();
    }

    onEmployeeSelected(user: IUserModel) {
        this.newStrike.userId = user.userId;
        this.newStrike.userName = user.fullName;
    }
}
