export interface ISlickFileModel {
	uuid: string;
	file: File;
	title: string;
	name: string;
	fileDate?: Date;
	fileDateFormat: string;
	thumbnailUrl: string;
	url: string;
	thumbnailBase64Image: string;
	base64Image: string;
    isChecked: boolean;
	parentFolderId: number;
}

export class SlickFileModel implements ISlickFileModel {
	uuid: string;
	file: File;
	title: string;
	name: string;
	fileDate?: Date;
	fileDateFormat: string;
	thumbnailUrl: string;
	url: string;
	thumbnailBase64Image: string;
	base64Image: string;
    isChecked: boolean;
    parentFolderId: number;
}
