import { Component, ViewChild, HostListener, Input } from "@angular/core";
import { SlickDialogComponent } from "@slick-components";
import { IConflictingAppointmentResultModel } from "@models";
import * as moment from 'moment';
import Swal from "sweetalert2";

@Component({
	selector: "conflicting-appointments-dialog",
	templateUrl: "./conflicting-appointments-dialog.component.html"
})
export class ConflictingAppointmentsDialogComponent {
	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;
	ctrlKeyDown: boolean = false;
	originalText: string;
	conflictingAppointments: IConflictingAppointmentResultModel[];

	apptsAfterTen: number;
	blockedTimeSlotsAfterTen: number;
	private onResolve: any;

	constructor() {
	}

	async showDialog(conflictingAppointments: IConflictingAppointmentResultModel[]): Promise<boolean> {
		return new Promise<boolean>(async (resolve) => {
			if (this.ctrlKeyDown) {
				resolve(true);
			} else if (!conflictingAppointments || conflictingAppointments.length === 0) {
				resolve(true);
			} else {
				for (const conflict of conflictingAppointments) {
					if (conflict.conflictingAppointments?.length > 10)
						this.apptsAfterTen = conflict.conflictingAppointments.length - 10;
					if (conflict.conflictingBlockedTimeSlots?.length > 10)
						this.blockedTimeSlotsAfterTen = conflict.conflictingBlockedTimeSlots.length - 10;
				}
				
				this.onResolve = resolve;
				this.conflictingAppointments = conflictingAppointments;
				this.dialogRef.showDialog();
			}
		});
	}

	save() {
		this.apptsAfterTen = 0;
		this.blockedTimeSlotsAfterTen = 0;
		this.dialogRef.hideDialog();
		this.onResolve(true);
	}

	cancel() {
		this.blockedTimeSlotsAfterTen = 0;
		this.apptsAfterTen = 0;
		this.dialogRef.hideDialog();
		this.onResolve(false);
	}

	convertToDate(date: Date, minutes: number) {
		if (!date) {
			Swal.fire({
				icon: 'error',
				title: 'Error!',
				text: "Date is null.",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return moment().toDate();
		}

		return moment(date).add((minutes || 0), "minutes").toDate();
	}

	@HostListener("document:keydown", ["$event"])
	handleCtrlDown(event: KeyboardEvent) {
		if (event.key === "Control")
			this.ctrlKeyDown = true;
	}

	@HostListener("document:keyup", ["$event"])
	handleCtrlUp(event: KeyboardEvent) {
		if (event.key === "Control")
			this.ctrlKeyDown = false;
	}
}
