export interface IQuickAddCategoryItemModel {
	itemId: number;
	quickAddCategoryId: number;
	sku: string;
	shortDescription: string;
	description: string;
    imageThumbnailUrl: string;
    imageUrl: string;
}

export class QuickAddCategoryItemModel implements IQuickAddCategoryItemModel {
	constructor() {
		this.itemId = 0;
	}

	itemId: number;
	quickAddCategoryId: number;
	sku: string;
	shortDescription: string;
	description: string;
    imageThumbnailUrl: string;
    imageUrl: string;
}