import { AddressModel, IAddressModel } from "../latham-ordering/address.model";

export interface IInventoryWarehouseModel {
	inventoryWarehouseId: number; 
	companyId: number;
	warehouseName: string;
	contactName: string;
	phoneNumber: string;
	addressId?: number;
	isDefault: boolean;
	syncToBusinessCentral: boolean;
	businessCentralId: string;
	active: boolean;

	address: IAddressModel;
}

export class InventoryWarehouseModel implements IInventoryWarehouseModel {
	constructor() {
		this.inventoryWarehouseId = 0;
		this.active = true;
		this.address = new AddressModel();
	}

	inventoryWarehouseId: number;
	companyId: number;
	warehouseName: string;
	contactName: string;
	phoneNumber: string;
	addressId?: number;
	isDefault: boolean;
	syncToBusinessCentral: boolean;
	businessCentralId: string;
	active: boolean;

	address: IAddressModel;
}