import { Injectable } from "@angular/core";
import { IEmployeePayRateModel, IPayPeriodDefinitionModel, IPayPeriodModel } from "../models";
import { HttpService } from "./utils/http.service";

@Injectable()
export class PayrollService {
	constructor(private httpService: HttpService) { }

	async getPayPeriods(): Promise<IPayPeriodModel[]> {
		return this.httpService.get("/payroll/getPayPeriods");
	}

	async getPayPeriodDefinitions(showInactive: boolean = false): Promise<IPayPeriodDefinitionModel[]> {
		return this.httpService.get("/payroll/getPayPeriodDefinitions", { showInactive: showInactive });
	}

	async updatePayPeriodDefinition(payPeriodDefinitionModel: IPayPeriodDefinitionModel): Promise<IPayPeriodDefinitionModel> {
		return this.httpService.post("/payroll/updatePayPeriodDefinitions", payPeriodDefinitionModel);
	}

	async getEmployeePayRates(employeeId: number): Promise<IEmployeePayRateModel[]> {
		return this.httpService.get("/payroll/getEmployeePayRates", { employeeId: employeeId });
	}

	async updateEmployeePayRates(employeeId: number, employeePayRateModels: IEmployeePayRateModel[]): Promise<IEmployeePayRateModel[]> {
		return this.httpService.post(`/payroll/updateEmployeePayRates?employeeId=${employeeId}`, employeePayRateModels);
	}
}
