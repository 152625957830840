import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SlickFileDropComponent } from "./slick-file-drop.component";

@NgModule({
	imports: [CommonModule, FormsModule],
	declarations: [SlickFileDropComponent],
	exports: [CommonModule, FormsModule, SlickFileDropComponent]
})
export class SlickFileDropModule { }
export { SlickFileDropComponent }
