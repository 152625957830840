export interface ICrewMemberModel {
	userId: number;
	fullName: string;
	userName: string;
}

export class CrewMemberModel implements ICrewMemberModel {
	userId: number;
	fullName: string;
	userName: string;
}