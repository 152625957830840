<div *ngIf="conversation" class="text-chat-messages" [ngClass]="{ 'is-dialog': isDialog }">
	<div class="d-flex flex-column text-chat-container">
		<div class="d-flex">
			<div class="flex-fill">
				<div class="d-flex flex-column">
					<h4 class="border-0 m-0" *ngIf="conversation.customerFullName">{{conversation.customerFullName}}</h4>
					<h4 class="border-0 m-0" *ngIf="!conversation.customerFullName && conversation.customerPhoneNumber">{{conversation.customerPhoneNumber}}</h4>
					<div *ngIf="conversation.customerPhoneNumber && conversation.customerFullName" style="font-size: 12px;">{{conversation.customerPhoneNumber}}</div>
					<div style="font-size: 12px;" class="d-flex">
						<div class="me-2">
							Assigned To:
						</div>
						<div style="width: 220px">
							<slick-drop-down [(ngModel)]="conversation.assignedToUserId"
											 [items]="attendants"
											 [compact]="true"
											 placeholder="Not Assigned"
											 idFieldName="userId"
											 textFieldName="userFullName"
											 (onSelect)="onAssignedToChanged($event)"></slick-drop-down>
						</div>

					</div>
					<div style="font-size: 12px; line-height: 16px;">
						<i class="far fa-pencil me-1" style="cursor: pointer" (click)="editAttendants()"></i>
						<span style="line-height: 12px">Attendants: {{conversation.attendantFullNames}}</span>
					</div>
				</div>
			</div>

			<div *ngIf="!conversation.isClosed" class="justify-content-end align-self-start mb-1 me-2">
				<button type="button" class="btn btn-outline-primary" [slick-button-spinner]="spinnerStatus" style="width: 180px" (click)="closeConversation()"><i class="far fa-comment-alt-check"></i> Close Chat</button>
			</div>
		</div>
		<div class="border-bottom"></div>

		<div #mainWindowRef class="flex-fill bg-white text-dark border py-1" style="overflow-y: auto;">
			<div *ngFor="let message of conversation.messages">
				<ng-container *ngIf="message.isSystemMessage" [ngTemplateOutlet]="renderSystemMessage" [ngTemplateOutletContext]="{ message: message }"></ng-container>
				<ng-container *ngIf="!message.isSystemMessage && !message.isNote && message.isFromCustomer && message.body" [ngTemplateOutlet]="renderCustomerMessage" [ngTemplateOutletContext]="{ message: message }"></ng-container>
				<ng-container *ngIf="!message.isSystemMessage && !message.isNote && message.isFromCustomer && message.imageUrl" [ngTemplateOutlet]="renderCustomerImage" [ngTemplateOutletContext]="{ message: message }"></ng-container>
				<ng-container *ngIf="!message.isSystemMessage && !message.isNote && !message.isFromCustomer && message.body" [ngTemplateOutlet]="renderUserMessage" [ngTemplateOutletContext]="{ message: message }"></ng-container>
				<ng-container *ngIf="!message.isSystemMessage && !message.isNote && !message.isFromCustomer && message.imageUrl" [ngTemplateOutlet]="renderUserImage" [ngTemplateOutletContext]="{ message: message }"></ng-container>
				<ng-container *ngIf="!message.isSystemMessage && message.isNote" [ngTemplateOutlet]="renderNoteMessage" [ngTemplateOutletContext]="{ message: message }"></ng-container>
			</div>
		</div>

		<div  class="d-flex p-1 mt-1 justify-content-center align-content-center text-muted" *ngIf="conversation.customerOptedOut">
			This customer has opted out of messaging and will no longer receive any messages.
		</div>

		<div class="d-flex mt-2">
			<div class="flex-fill me-1">
				<textarea [disabled]="conversation.customerOptedOut"
						  [(ngModel)]="newMessage"
						  placeholder="Message..."
						  cdkTextareaAutosize
						  class="form-control"
						  (keydown)="onKeyDown($event)"
						  #autosize="cdkTextareaAutosize"
						  cdkAutosizeMinRows="1"></textarea>
				<!--<input #newMessageRef type="text" class="form-control" [(ngModel)]="newMessage" (keydown)="onKeyDown($event)" placeholder="Message..." />-->
			</div>
			<div>
				<button type="button" [disabled]="!newMessage" class="btn btn-primary" (click)="sendMessage()"><i class="far fa-paper-plane"></i></button>
				<button type="button" class="btn btn-primary ms-1" style="width: 40px" (click)="onAddNote()"><i class="fad fa-sticky-note"></i></button>
			</div>

		</div>
		<div class="d-flex mt-1" style="font-size: 10px;">
			<div *ngIf="templates.length > 0" class="me-2">
				<button type="button" class="btn btn-outline-primary template-button" style="font-size: 10px;" (click)="toggleTemplates()"><i class="far fa-comment-alt-lines" title="Templates"></i> Templates</button>
			</div>

			<div class="flex-fill">
				<slick-file-drop (filesChanged)="onFilesDropped($event)" displayText="Click or drag to send photos..."></slick-file-drop>
			</div>
		</div>

	</div>


	<div *ngIf="templates" class="templates-container">
		<div class="templates card" [ngClass]="templatesExpanded === true ? 'templates-expanded' : 'templates-collapsed'">
			<h4 class="px-2 pt-1">Templates</h4>

			<table class="table table-hover table-sm">
				<tbody>
					<tr *ngFor="let template of templates" (click)="selectTemplate(template)">
						<td>
							<div class="template d-flex flex-column mb-1 px-3 py-2">
								<div style="font-weight: bold; font-size: 16px; line-height: 14px;">
									{{template.templateName}}
								</div>
								<div style="font-size: 14px;">
									{{template.templateText}}
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<ng-template #renderSystemMessage let-message="message">
	<div class="flex-fill" style="text-align: center; color: #b9b9b9; font-size: 12px">
		{{message.sentDate | date: 'M/d/yyyy h:mm:ss a'}} - {{message.body}}
	</div>
</ng-template>

<ng-template #renderNoteMessage let-message="message">
	<div class="flex-fill py-1 mb-1" style="line-height: 14px; text-align: center; background: #f8f8f8; border-top: 1px solid #f0f0f0; border-bottom: 1px solid #f0f0f0; color: #b9b9b9; font-size: 12px">
		{{message.sentDate | date: 'M/d/yyyy h:mm:ss a'}} by {{message.sender.userFullName}}<br />
		<pre class="mb-0" style="color: #b9b9b9; line-height: 12px; font-size: 12px;">{{message.body}}</pre>
	</div>
</ng-template>

<ng-template #renderUserMessage let-message="message">
	<div style="font-size: 1rem; line-height: .8rem;" class="d-flex flex-fill justify-content-end">

		<div class='card text-chat-user text-white me-2' [ngClass]="{ 'bg-success': message.senderId === userId, 'bg-info': message.senderId !== userId }">
			<div class="d-flex align-items-center">
				<div style="text-align: left; word-break: break-word;">
					{{message.body}}
					<div style="font-size: .6rem; line-height: 10px; white-space: nowrap; text-align:right">
						{{message.sentDate | date:'M/d/yyyy h:mm:ss a'}}<br />
						{{message.sender.userFullName}}
					</div>
				</div>
				<div class="ms-2">
					<img [src]="message.sender.profilePictureUrl" style="border: 1px solid white; border-radius: 60px; min-width: 40px; max-width: 40px; min-height: 40px; max-height: 40px; border-radius: 50%;" />
				</div>
			</div>
		</div>

	</div>
	<div *ngIf="message.errorMessage" style="font-size: .6rem; line-height: 8px; white-space: nowrap; text-align:right" class="text-danger me-3 mt-1">
		<i>{{message.errorMessage}}</i>
	</div>

	<div class="mb-2"></div>
</ng-template>

<ng-template #renderUserImage let-message="message">
	<div style="font-size: 1rem; line-height: .8rem;" class="d-flex flex-fill mb-2 justify-content-end">

		<div class='text-chat-customer ms-2'>
			<div class="d-flex align-items-center">

				<div>
					<img *ngIf="message.imageUrl" [src]="message.imageUrl" />
					<div style="font-size: .6rem; line-height: 11px; white-space: nowrap">
						{{message.sentDate | date:'M/d/yyyy h:mm:ss a'}}
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #renderCustomerMessage let-message="message">
	<div style="font-size: 1rem; line-height: .8rem;" class="d-flex flex-fill mb-2 justify-content-start">

		<div class='card text-chat-customer text-white ms-2 bg-primary'>
			<div class="d-flex align-items-center">

				<div style="word-break: break-word;">
					{{message.body}}<br />
					<div style="font-size: .6rem; line-height: 11px; white-space: nowrap">
						{{message.sentDate | date:'M/d/yyyy h:mm:ss a'}}
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #renderCustomerImage let-message="message">
	<div style="font-size: 1rem; line-height: .8rem;" class="d-flex flex-fill mb-2 justify-content-start">

		<div class='text-chat-customer ms-2'>
			<div class="d-flex align-items-center">

				<div>
					<img *ngIf="message.imageUrl" [src]="message.imageUrl" style="cursor: pointer" (click)="onImageClicked(message)" />
					<div style="font-size: .6rem; line-height: 11px; white-space: nowrap">
						{{message.sentDate | date:'M/d/yyyy h:mm:ss a'}}
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<slick-dialog #editAttendantsDialogRef header="Attendants" [width]="400">
	<div class="d-flex flex-column">
		<div>
			<button type="button" class="btn btn-link ms-2" (click)="removeAllAttendants()">Remove All</button>
		</div>
		<div *ngFor="let attendant of attendants" style="cursor: pointer" (click)="attendant.isAttendant = !attendant.isAttendant">
			<div class="d-flex px-2" [ngClass]="{ 'bg-success text-white': attendant.isAttendant }">
				<div>
					<i *ngIf="attendant.isAttendant" class="far fa-check-square"></i>
					<i *ngIf="!attendant.isAttendant" class="far fa-square"></i>
				</div>

				<div class="flex-fill ms-1">
					{{attendant.userFullName}}
				</div>
			</div>
		</div>
	</div>

	<slick-dialog-footer>
		<button type="button" style="width: 120px" class="btn btn-outline-primary me-3" [slick-button-spinner]="editAttendantsSpinnerStatus" (click)="onEditAttendantsOk()"><i class="far fa-check"></i> Ok</button>
		<button type="button" style="width: 120px" class="btn btn-outline-danger" (click)="onEditAttendantsCancel()"><i class="bi bi-x-circle"></i> Cancel</button>
	</slick-dialog-footer>

</slick-dialog>

<slick-dialog #addNoteDialogRef header="Add Note" [width]="400">
	<div class="d-flex flex-column">
		<label>Note:</label>
		<textarea class="form-control" rows="4" [(ngModel)]="userNote"></textarea>
		<div style="font-size: 12px;"><i>You can also add a note by typing</i> note: <i>in the message.</i></div>
	</div>

	<slick-dialog-footer>
		<button type="button" style="width: 120px" class="btn btn-outline-primary me-3" (click)="onAddNoteOk()"><i class="far fa-check"></i> Ok</button>
		<button type="button" style="width: 120px" class="btn btn-outline-danger" (click)="onAddNoteCancel()"><i class="bi bi-x-circle"></i> Cancel</button>
	</slick-dialog-footer>
</slick-dialog>

<slick-photo-gallery #photoGalleryRef [photos]="photos"></slick-photo-gallery>
