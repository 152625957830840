import { IUserPermissionModel } from "./user-permission.model";

export interface IUserRoleModel {
	userRoleId: number;
	roleName: string;
	roleTypeId: number;
	isSystemRole: boolean;

	defaultPermissions: IUserPermissionModel[];

}

export class UserRoleModel implements IUserRoleModel {
	constructor() {
		this.userRoleId = 0;
		this.isSystemRole = false;

		this.defaultPermissions = [];
	}

	userRoleId: number;
	roleName: string;
	roleTypeId: number;
	isSystemRole: boolean;

	defaultPermissions: IUserPermissionModel[];
}
