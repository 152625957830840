export interface IPriceLevelItemModel {
	priceLevelId: number;
	itemId: number;
	priceLevelGroupId?: number;
	priceLevelGroupUuid: string;
	costOverride?: number;
	costDiscount?: number;
	exchangeRate?: number;
	percentMarkup?: number;
	dollarMarkup?: number;
	percentMargin?: number;
	visibleToChildren: boolean;
	modified: boolean;
}

export class PriceLevelItemModel implements IPriceLevelItemModel { 
	constructor() {
		this.priceLevelId = 0;
	}

	priceLevelId: number;
	itemId: number;
	priceLevelGroupId?: number;
	priceLevelGroupUuid: string;
	costOverride?: number;
	costDiscount?: number;
	exchangeRate?: number;
	percentMarkup?: number;
	dollarMarkup?: number;
	percentMargin?: number;
	visibleToChildren: boolean;
	modified: boolean;
}