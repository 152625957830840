import { Component, HostBinding, Input } from "@angular/core";

@Component({
	selector: 'slick-container-body',
	template: `<ng-content></ng-content>`
})
export class SlickContainerBodyComponent {
	@HostBinding('class') flex = 'slick-container-body flex-fill';
	@HostBinding('style.overflowY') overflowY = 'auto';
}
