<div class="slick-calendar default-width card" id="slick-calendar_{{uuid}}" [style.minWidth.px]="minWidth" [ngClass]="{'slick-calendar_condensed': condensed === true, 'slick-calendar_single': monthsToShow === 1 }">
	<div class="slick-calendar_prev-month slick-calendar-arrow" (click)="prevMonth()" style="margin-left: 12px; margin-top: 3px;"><i class="fas fa-long-arrow-left" ></i></div>
	<div class="slick-calendar_next-month slick-calendar-arrow" (click)="nextMonth()" style="margin-right: 12px; margin-top: 3px;"><i class="fas fa-long-arrow-right" ></i></div>

	<div class="d-flex justify-content-around pt-2" [ngClass]="{ 'mx-3': monthsToShow > 1, 'mx-1': monthsToShow === 1 }">
		<div *ngFor="let month of months" class="flex-fill mx-2" style="text-align: center;max-width: 220px;">

			<table class="table table-sm table-borderless calendarfix" style="table-layout: fixed; ">
				<thead>
					<tr class="slick-calendar_month-display">
						<td colspan="7"><b>{{month.monthDisplay}}</b></td>
					</tr>
					<tr class="slick-calendar_days-of-week text-dark">
						<td><span *ngIf='condensed === false'>S</span><span *ngIf='condensed === true'>S</span></td>
						<td><span *ngIf='condensed === false'>M</span><span *ngIf='condensed === true'>M</span></td>
						<td><span *ngIf='condensed === false'>T</span><span *ngIf='condensed === true'>T</span></td>
						<td><span *ngIf='condensed === false'>W</span><span *ngIf='condensed === true'>W</span></td>
						<td><span *ngIf='condensed === false'>T</span><span *ngIf='condensed === true'>T</span></td>
						<td><span *ngIf='condensed === false'>F</span><span *ngIf='condensed === true'>F</span></td>
						<td><span *ngIf='condensed === false'>S</span><span *ngIf='condensed === true'>S</span></td>
					</tr>
				</thead>

				<tbody>
					<tr *ngFor="let week of month.weeks">
						<td *ngFor="let day of week.days" (click)="setDate(day)" class="slick-calendar_date" [ngClass]="{ 'slick-calendar_is-today': day.isToday, 'bg-primary text-white': day.isSelected, 'slick-calendar_not_in_month': day.isInCurrentMonth === false, 'slick-calendar_inactive': day.isActive === false }">
							<span *ngIf="showDaysNotInMonth === true || (showDaysNotInMonth === false && day.isInCurrentMonth === true)">{{day.date | date:'d'}}</span>
							<span *ngIf="monthsToShow > 1 && (showDaysNotInMonth === false && day.isInCurrentMonth === false)">&nbsp;</span>
						</td>
					</tr>
				</tbody>
			</table>


		</div>

	</div>
</div>