import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { SlickDialogComponent, SlickToastService } from "@slick-components";
import { ChatStore } from '@stores';
import { ChatService, FunctionLockService, GlobalsService } from "@services"
import { IChatGroupModel } from "@models";
import { Subscription } from "rxjs";
import { ChatGroupAttendantsComponent } from "../components/chat-group-attendants/chat-group-attendants.component";

@Component({
    selector: "skedit-chat",
    templateUrl: "./skedit-chat.component.html",
	styleUrls: ["./skedit-chat.component.scss"],
	providers: [FunctionLockService]
})
export class SkeditChatComponent implements OnInit, OnDestroy {
    @ViewChild("chatDialogRef") chatDialogRef: SlickDialogComponent;
	@ViewChild("chatGroupAttendantsRef") chatGroupAttendantsRef: ChatGroupAttendantsComponent;
	@ViewChild("editGroupNameRef") editGroupNameRef: SlickDialogComponent;

	newChatGroupName: string;
    chatGroup: IChatGroupModel;
	onNewMessage$: Subscription;
	chatGroupUpdatedStore$: Subscription;
	//toggleShowDeleted$: Subscription;

	constructor(private readonly chatStore: ChatStore,
		private slickToastService: SlickToastService,
		private readonly chatService: ChatService,
		private readonly functionLockSvc: FunctionLockService) {
	}

	async ngOnInit() {
		this.onNewMessage$ = this.chatStore.onNewMessageStore.subscribe(async (newMessage) => {
			if (newMessage.senderId === this.chatStore.currentUser.userId)
				return;

			if (!this.chatGroup) {
				this.chatStore.addPendingMessage(newMessage);
				return;
			}

			await this.functionLockSvc.lock("SKEDIT_CHAT_MESSAGE");
			try {
				if ((this.chatGroup.id !== newMessage.chatGroupId) || this.chatStore.isDialogClosed)
					this.chatStore.addPendingMessage(newMessage);
				else {
					if (!this.chatStore.isDialogClosed)
						this.chatStore.setAllMessagesAsRead(this.chatGroup.id);
				}
			}
			finally {
				this.functionLockSvc.release("SKEDIT_CHAT_MESSAGE");
			}
		})

		this.chatGroupUpdatedStore$ = this.chatStore.chatGroupUpdatedStore.subscribe((chatGroup) => {
			if (this.chatGroup && this.chatGroup.uuid === chatGroup.uuid)
				this.chatGroup = chatGroup;				
		});

		//this.toggleShowDeleted$ = this.chatStore.toggleShowDeletedGroupsStore.subscribe(() => {
		//	if(this.chatGroup?.isDeleted)
		//		this.chatGroup = null
		//});
		
	}

	ngOnDestroy() {
		this.onNewMessage$.unsubscribe();
		this.chatGroupUpdatedStore$.unsubscribe();
		//if (this.toggleShowDeleted$)
		//	this.toggleShowDeleted$.unsubscribe();
	}

	openChat() {
        this.chatDialogRef.showDialog();
    }

	async addAttendants(chatGroup) {
		await this.chatGroupAttendantsRef.showDialog(chatGroup);
	}

	async onGroupClicked(chatGroup: IChatGroupModel) {
		this.chatGroup = chatGroup;
		if(chatGroup.unreadMessages > 0)
			this.chatService.updateChatGroupUserLastRead(GlobalsService.userInfo.userId, chatGroup.id)
		this.chatStore.setAllMessagesAsRead(chatGroup.id);
	}

	async editGroupName() {
		this.newChatGroupName = this.chatGroup.groupName;
		this.editGroupNameRef.showDialog();
	}

	async saveChatGroupName(newGroupName: string) {
		await this.chatService.saveChatGroupName(newGroupName, this.chatGroup.id);
		this.chatGroup.groupName = newGroupName;
		this.editGroupNameRef.hideDialog();
		this.chatStore.refreshChatList();
    }

	cancelGroupName() {
		this.editGroupNameRef.hideDialog();
	}

	async deleteGroup(chatGroup: IChatGroupModel) {
		await this.chatService.deleteGroup(chatGroup.id, GlobalsService.userInfo.userId);
		this.chatStore.refreshChatListAfterEvent();
		this.chatGroup = null;
		this.slickToastService.showSuccess("Chat Deleted", 1000);
	}

	onDialogClose() {
		this.chatStore.isDialogClosed = true;
	}

	toggleMute(chatGroup: IChatGroupModel) {
		this.chatStore.toggleChatMuted(chatGroup);
    }
}


