import {Injectable, Version} from "@angular/core";
import {HttpService} from "./utils/http.service";
import {ILayoutSettings, IUserModel, IUserPermissionModel, IUserInfoModel} from "@models";
import {IUserLocationModel} from "@models/users/user-location.model";
import {IUserResourceSortOrderModel} from "@models/users/user-resource-sort-order.model";
import moment from "moment";
import { HttpHeaders } from '@angular/common/http';


@Injectable()
export class UsersService {
    constructor(private httpService: HttpService) {
    }

    async getAllUsers(): Promise<IUserModel[]> {
        return await this.httpService.get("/users/getAllUsers");
    }

    async getUser(userId: number): Promise<IUserModel> {
        return await this.httpService.get("/users/getUser", {userId: userId});
    }

    async getUserInfo(userId: number): Promise<IUserInfoModel> {
        return await this.httpService.get("/users/getUserInfo", { userId: userId });
    }

    async getPermissionsForUserRole(roleId: number): Promise<IUserPermissionModel[]> {
        return await this.httpService.get("/users/getPermissionsForUserRole", {roleId: roleId});
    }

    async getUserLocations(userId: number): Promise<IUserLocationModel[]> {
        return await this.httpService.get('/users/getUserLocations', {userId: userId})
    }

    async getUserResourceSortOrder(): Promise<IUserResourceSortOrderModel[]> {
        return await this.httpService.get('/users/getUserResourceSortOrder')
    }

    async addUser(userModel: IUserModel): Promise<IUserModel> {
        return await this.httpService.post("/users/addUser", userModel);
    }

    async updateUser(userModel: IUserModel): Promise<IUserModel> {
        return await this.httpService.post("/users/updateUser", userModel);
    }

    async updateLayoutSettings(model: ILayoutSettings): Promise<ILayoutSettings> {
        return await this.httpService.post("/users/updateLayoutSettings", model);
    }

    async updateUserResourceSortOrder(model: IUserResourceSortOrderModel[]): Promise<IUserResourceSortOrderModel[]> {
        return await this.httpService.post("/users/updateUserResourceSortOrder", model);
    }

    async resetUserResourceSortOrder(): Promise<void> {
        this.httpService.post("/users/resetUserResourceSortOrder", null);
    }

    async resetAllUserResourceSortOrder(): Promise<void> {
        this.httpService.post("/users/resetAllUserResourceSortOrder", null);
    }

    async toggleSelectedLocation(locationId: number): Promise<boolean> {
        return await this.httpService.patch(`/users/toggleSelectedLocation?locationId=${locationId}`);
    }

    async updateLastSeenVersion(version: string): Promise<boolean> {
        return await this.httpService.post(`/users/UpdateLastSeenVersion`, { appVersion: version });
    }

    async updateLastActiveTime(time: Date): Promise<void> {
        this.httpService.get(`/users/UpdateLastActiveTime?time=${moment(time).format('YYYY-MM-DD HH:mm:ss') }`);
    }

    //this resets the password for any user as long as the user changing the password is an admin
    async adminResetPassword(selectedUserId: number, newPassword: string): Promise<boolean> {
        const params: any = {
            selectedUserId: selectedUserId,
            newPassword: newPassword
        }
        return await this.httpService.post(`/users/adminResetPassword`, params);

    }
    // this resets the password for the default admin user of a company
    async resetCompanyAdminPassword(newPassword: string, companyId: number): Promise<boolean> {
        return await this.httpService.get(`/users/resetCompanyAdminPassword?newPassword=${newPassword}&cid=${companyId}`);
    }
}
