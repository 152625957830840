import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { GlobalsService } from "@services";

@Component({
	selector: "business-central-icon",
	templateUrl: "business-central-icon.component.html",
	styleUrls: ["business-central-icon.component.css"]
})

export class BusinessCentralIconComponent implements OnChanges {
	@Input() mini: boolean = false;
	@Input() lockSynced: boolean
	@Input() businessCentralSyncStatus: string;
	@Input() syncToBusinessCentral: boolean;
	@Input() businessCentralId: string;

	@Output() onClick: EventEmitter<boolean> = new EventEmitter();

	isBusinessCentralEnabled: boolean = GlobalsService.isBusinessCentralEnabled;
	titleString: string;

	ngOnChanges(changes: SimpleChanges) {
		if (changes.mini)
			this.mini = (this.mini.toString().toLowerCase() !== 'true') ? false : true;

		if (changes.lockSynced)
			this.lockSynced = (this.lockSynced.toString().toLowerCase() !== 'true') ? false : true;

		if (changes.syncToBusinessCentral) {
			if (this.syncToBusinessCentral === null || this.syncToBusinessCentral === undefined)
				this.syncToBusinessCentral = false;
			else
				this.syncToBusinessCentral = (this.syncToBusinessCentral.toString().toLowerCase() !== 'true') ? false : true;
		}
	}

	unSync(e: MouseEvent) {
		// These are here because the grid will go to the row and we just want the icon clicked
		e.preventDefault();
		e.stopPropagation();

		if (this.lockSynced)
			return;

		this.onClick.emit(false);
	}

	setSync(e: MouseEvent, isSynced: boolean) {
		// These are here because the grid will go to the row and we just want the icon clicked
		e.preventDefault();
		e.stopPropagation();

		this.onClick.emit(isSynced);
	}
}
