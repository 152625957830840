import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IDocumentModel } from "@models";
import { DocumentsService, DocumentTypes, GlobalsService, HttpService } from "@services";
import * as moment from 'moment';
import Swal from "sweetalert2";

@Component({
	selector: "documents-edit",
	templateUrl: "./documents-edit.component.html",
	styleUrls: ["./documents-edit.component.css"],
	providers: [DocumentsService]
})
export class DocumentsEditComponent implements OnChanges {
	@Input("title") title: string;
	@Input("documentKey") documentKey: string;
	@Input("excludeDocumentKey") excludeDocumentKey: string;
	@Input("documentType") documentType: DocumentTypes;
	@Input('canDelete') canDelete: boolean;
	@Input('folderType') folderType: string;
	@Input('hideFileDrop') hideFileDrop: boolean;
	@Input('getUrl') getUrl: string;

	imageExts: string[] = ['.jpg', '.jpeg', '.bmp', '.png', '.gif'];

	photos: IDocumentModel[];
	documents: IDocumentModel[];

	constructor(private readonly documentsService: DocumentsService,
		private readonly httpService: HttpService) {
	}

	async ngOnChanges(changes: SimpleChanges) {
		/*GlobalsService.checkPermission(feature, action)*/
		if (changes.documentType) {
			switch (this.documentType.toString().toLowerCase()) {
				case "customers":
					this.documentType = DocumentTypes.Customers;
					break;

				case "jobs":
					this.documentType = DocumentTypes.Jobs;
					break;

				case "invoices":
					this.documentType = DocumentTypes.Invoices;
					break;

				case "payments":
					this.documentType = DocumentTypes.Payments;
					break;

				case "lathamorders":
					this.documentType = DocumentTypes.LathamOrders;
					break;

				case "users":
					this.documentType = DocumentTypes.Users;
					break;
			}
		}

		if (changes.documentKey && this.documentKey)
			this.reload();
	}

	async onFileDeleted(file: IDocumentModel) {
		await this.documentsService.deleteDocument(file.documentId);
	}

	async onNewFilesAdded(files: IDocumentModel[]) {
		if (this.folderType.toLowerCase() === 'photos') {
			let validExt = true;
			files.forEach(x => {
				if (!this.imageExts.includes(".".concat(x.file.type.split("/")[1]))) {
					validExt = false;
                }
			})
			if (validExt)
				await this.documentsService.updatePhotos(this.documentKey, this.documentType, files);
			else {
				await Swal.fire({
					icon: 'warning',
					title: 'Oops',
					text: 'Unsupported file type',
					confirmButtonColor: '#007bff',
					width: '28em',
					heightAuto: false
				});
				return;
			}
		}
		else if (this.folderType.toLowerCase() === 'documents')
			await this.documentsService.updateDocuments(this.documentKey, this.documentType, files);

		this.reload();
	}

	async onDocumentClicked(fileModel: IDocumentModel) {
		if (fileModel.mimeType === 'application/pdf') {
			const w = window.open('', '_blank');
			w.location.href = fileModel.url;
		}
		else
			this.documentsService.openDocument(fileModel.documentId);
	}

	private async reload() {
		if (this.getUrl) {
			const allDocuments = <IDocumentModel[]>(await this.httpService.get(this.getUrl, { documentKey: this.documentKey }));
			this.documents = this.getDocuments(allDocuments);
			this.photos = this.getPhotos(allDocuments);
		}
		else {
			if (this.folderType && this.folderType.toLowerCase() === 'photos') {
				this.documents = [];
				this.photos = await this.documentsService.getPhotos(this.documentKey, this.documentType);
			}
			else if (this.folderType && this.folderType.toLowerCase() === 'documents') {
				let allDocuments = await this.documentsService.getDocuments(this.documentKey, this.documentType);
				this.documents = this.getDocuments(allDocuments);
				this.photos = this.getPhotos(allDocuments);
			}
		}

		if (this.excludeDocumentKey) {
			this.photos = this.photos.filter(x => x.documentKey !== this.excludeDocumentKey);
			this.documents = this.documents.filter(x => x.documentKey !== this.excludeDocumentKey);
		}
	}

	private getDocuments(documents: IDocumentModel[]): IDocumentModel[] {
		const allDocuments = documents.map(doc => {
			doc.isImage = false;

			this.imageExts.forEach(ext => {
				if (doc.url.indexOf(ext) >= 0)
					doc.isImage = true;
			});

			return doc;
		})

		return allDocuments.filter(x => x.isImage === false);
	}

	private getPhotos(documents: IDocumentModel[]): IDocumentModel[] {
		const allDocuments = documents.map(doc => {
			doc.isImage = false;

			this.imageExts.forEach(ext => {
				if (doc.url.indexOf(ext) >= 0)
					doc.isImage = true;
			});

			return doc;
		})

		return allDocuments.filter(x => x.isImage === true);
	}

}

