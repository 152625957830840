import { IUserPermissionModel } from "./user-permission.model";
import {LocationModel} from "@models/lookups/location.model";

export interface IUserModel {
	userId: number;
	uuid: string;
	defaultLocationId?: number;
	defaultWarehouseId?: number;
	payPeriodDefinitionId?: number;
	userName: string;
	password: string;
	confirmPassword: string;
	passwordsMatch: boolean;
	validPassword: boolean;
	userGuid: string;
	timeZone: string;
	firstName: string;
	lastName: string;
	fullName: string;
	cellPhone: string;
	emailAddress: string;
	active: boolean;
	sendDailyEmail: boolean;
	userRoleId: number;
	isAdmin: boolean;
	isCrewMember: boolean;
	showOnChat: boolean;
	flagNonSignedServiceAgreement: boolean;
	serviceAgreementHtml: string;
	startDate?: Date;
	terminationDate?: Date;
	isGlobalAdmin: boolean;
	geoTrackingExempt: boolean;
	canApproveSuspended: boolean;
	timeClockExempt: boolean;
	canSeeFutureAppts: boolean;
	dashboardOptions: string;
	isSalesRep: boolean;
	isAR: boolean;
	isAP: boolean;
	qbSalesRepCode: string;
	showAllQBWCErrors: boolean;
	profilePictureUrl: string;
    theme: string;
    emergencyContactName: string;
    emergencyContactPhoneNumber: string;
	userPermissions: IUserPermissionModel[];
	locationIds: number[];
	userLocations: LocationModel[];
	selectedLocations: string;
	canAccessTextChat: boolean;
	textChatInitialAttendant: boolean;
	usePushNotifications: boolean;
	bambooEmployeeNumber: number;
	isPartTimeEmployee: boolean;
	isSeasonalEmployee: boolean;
	lathamOrdersToSuspended: boolean;
	lastActiveTime?: Date;
	skeditPayAdmin: boolean;
}

export class UserModel implements IUserModel {
	constructor() {
		this.userId = 0;
		this.isAdmin = false;
		this.active = true;
		this.userPermissions = [];
		this.locationIds = [];
	}

	userId: number;
	uuid: string;
	defaultLocationId?: number;
	defaultWarehouseId?: number;
	payPeriodDefinitionId?: number;
	userName: string;
	password: string;
	confirmPassword: string;
	passwordsMatch: boolean;
	validPassword: boolean;
	userGuid: string;
	timeZone: string;
	firstName: string;
	lastName: string;
	fullName: string;
	cellPhone: string;
	emailAddress: string;
	active: boolean;
	sendDailyEmail: boolean;
	userRoleId: number;
	isAdmin: boolean;
	isCrewMember: boolean;
	showOnChat: boolean;
	flagNonSignedServiceAgreement: boolean;
	serviceAgreementHtml: string;
	startDate?: Date;
	terminationDate?: Date;
	isGlobalAdmin: boolean;
	geoTrackingExempt: boolean;
	canApproveSuspended: boolean;
	timeClockExempt: boolean;
	canSeeFutureAppts: boolean;
	dashboardOptions: string;
	isSalesRep: boolean;
	isAR: boolean;
	isAP: boolean;
	qbSalesRepCode: string;
	showAllQBWCErrors: boolean;
	profilePictureUrl: string;
	theme: string;
	emergencyContactName: string;
	emergencyContactPhoneNumber: string;
	userPermissions: IUserPermissionModel[];
	locationIds: number[];
	userLocations: LocationModel[];
	selectedLocations: string;
	canAccessTextChat: boolean;
	textChatInitialAttendant: boolean;
	usePushNotifications: boolean;
	bambooEmployeeNumber: number;
	isPartTimeEmployee: boolean;
	isSeasonalEmployee: boolean;
	lathamOrdersToSuspended: boolean;
	lastActiveTime?: Date;
	skeditPayAdmin: boolean;
}
