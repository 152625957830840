import { Injectable } from '@angular/core';
import { HttpService } from './utils/http.service';
import { IItemModel, IItemListViewModel } from '@models';

@Injectable()
export class ItemsService {
    constructor(private httpService: HttpService) { }

    async getAllItems(): Promise<IItemModel[]> {
        return this.httpService.get('/items/getAllItems');
    }

    async getItem(itemId: number): Promise<IItemModel> {
        return this.httpService.get('/items/getItem', { itemId: itemId });
    }

    async getItemsForInfiniteScroll(pageNumber: number, searchString: string, showLathamParts: boolean): Promise<IItemListViewModel[]> {
        const params = {
            pageNumber,
            searchString,
            showLathamParts
        }
        return this.httpService.get("/items/getItemsForInfiniteScroll", params);
    }

    async addItem(itemModel: IItemModel) {
        return this.httpService.post('/items/addItem', itemModel);
    }

    async updateItems(itemModels: IItemModel[]): Promise<IItemModel[]> {
        return this.httpService.post('/items/updateItems', itemModels);
    }

    async updateItem(itemModel: IItemModel): Promise<IItemModel> {
        return this.httpService.post('/items/updateItem', itemModel);
    }

    async updateItemImage(itemId: number, enc64Image: string): Promise<IItemModel> {
        const params = {
            enc64Image
		}
        return this.httpService.post(`/items/updateItemImage?itemId=${itemId}`, params);
    }

    async addItemToQuickbooks(itemId: number, incomeAccountId: string, itemSubcategoryId: string): Promise<string> {
        const params = {
            itemId,
            incomeAccountId,
            itemSubcategoryId
        }

        return this.httpService.post('/items/addItemToQuickbooks', params);
	}

    async addItemToBusinessCentral(itemId: number): Promise<string> {
        const response = await this.httpService.patch(`/items/addItemToBusinessCentral?itemId=${itemId}`);

        return response.businessCentralId;
    }

    async addInvoiceLaborItemToBusinessCentral(invoiceLaborItemId: number): Promise<string> {
        const response = await this.httpService.patch(`/items/addInvoiceLaborItemToBusinessCentral?invoiceLaborItemId=${invoiceLaborItemId}`);

        return response.businessCentralId;
    }

    async getItemsBySkus(skus: string[], customerId?: number, includeItemAssemblies: boolean = false) {
        const params = {
            skus,
            customerId: (customerId ?? 0),
            includeItemAssemblies: includeItemAssemblies
		}
        return this.httpService.get('/items/getItemsBySkus', params);
	}
}
