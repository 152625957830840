export interface ILidModel {
    lidType: string;
    hingeType: string;
    lidDimensionsOverride: boolean;
    motorEndLidFeet: number;
    motorEndLidInches: number;
    mainLidFeet: number;
    mainLidInches: number;
    nonMotorEndLidFeet: number;
    nonMotorEndLidInches: number;
	standardLidBrackets: boolean;
	hasFlushLid: boolean;
}

export class LidModel implements ILidModel
{
    lidType: string;
    hingeType: string;
    lidDimensionsOverride: boolean;
    motorEndLidFeet: number;
    motorEndLidInches: number;
    mainLidFeet: number;
    mainLidInches: number;
    nonMotorEndLidFeet: number;
    nonMotorEndLidInches: number;
	standardLidBrackets: boolean;
	hasFlushLid: boolean;
}