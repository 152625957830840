import { ILathamOrderSpecsModel } from "./latham-order-specs.model";
import { ILineItemModel } from "../line-items/line-item.model";

export interface ILathamOrderFavoriteModel {
    lathamOrderFavoriteId: number;
    name: string;
    orderSpecs: ILathamOrderSpecsModel;
    addedItems: ILineItemModel[];
    orderNotes: string;
    active: boolean;
}

export class LathamOrderFavoriteModel implements ILathamOrderFavoriteModel {
    lathamOrderFavoriteId: number;
    name: string;
    orderSpecs: ILathamOrderSpecsModel;
    addedItems: ILineItemModel[];
    orderNotes: string;
    active: boolean;
}