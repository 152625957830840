import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
	selector: '[slickGridColumnTemplate]'
})
export class SlickGridColumnTemplateDirective {
	@Input('slickGridColumnTemplate') columnKey: string;

	constructor(public template: TemplateRef<any>) {
	}
}

