import * as moment from 'moment';

export interface ITimeSlotModel {
	date: Date;
	hour: number;
	minute: number;
	calcDate: string;
	calcHour: string;
	display: string;
	displaySm: string;
	top: number;
	left: number;
	height: number;
	width: number;
}

export class TimeSlotModel implements ITimeSlotModel {
	date: Date;
	hour: number;
	minute: number;
	calcDate: string;
	calcHour: string;
	display: string;
	displaySm: string;
	top: number;
	left: number;
	height: number;
	width: number;

	constructor(time: moment.Moment) {
		this.date = moment(time).startOf("day").toDate();
		this.hour = time.hour();
		this.minute = time.minute();
		this.calcDate = TimeSlotModel.getCalcDate(time);
		this.calcHour = TimeSlotModel.getCalcHour(time);
		this.displaySm = time.format("ha");
		this.displaySm = this.displaySm.substring(0, this.displaySm.length - 1);
		this.display = time.format("h:mma");
		this.display = this.display.substring(0, this.display.length - 1);
	}

	static getCalcDate(date: moment.Moment): string {
		return date.format("YYYYMMDD");
	}

	static getCalcHour(time: moment.Moment): string {
		return time.format("HHmm")
	}
}
