<slick-dialog #dialogRef header="Missed Clock In">
    <section class="text-center" *ngIf="timeClockEntry">
        <h2>
            Oops...
        </h2>
        <div>
            Looks like you forgot to clock in
        </div>
        <div class="mt-2">
            Please enter a clock in time here
        </div>

        <div class="mt-1 d-flex col-12">
            <div class="col-3"></div>
            <div class="col-6">
                Clock In
                <input class="form-control" [(ngModel)]="timeClockEntry.clockInHoursText" />
            </div>
        </div>

        <div class="col-12 d-flex">

            <div *ngIf="!isMobile" class="col-4">
            </div>
            <div *ngIf="!isMobile">
                <div *ngIf="formatError && isSubmitted" style="width:210px" class='error-wrapper show-icons text-danger'><div class='error'>Clock in is in incorrect format<ng-content></ng-content></div></div>
            </div>
            <div *ngIf="isMobile" class="ms-5">
                <div *ngIf="formatError && isSubmitted" style="width:210px" class='error-wrapper show-icons text-danger'><div class='error'>Clock in is in incorrect format<ng-content></ng-content></div></div>
            </div>
        </div>

        <div class="mt-2">
            Or click continue to clock in now
        </div>
    </section>

    <slick-dialog-footer>
        <button class="btn btn-outline-primary me-2" style="width:100px" (click)="continue()">Continue</button>
        <button class="btn btn-outline-danger" style="width:100px" (click)="close()">Cancel</button>
    </slick-dialog-footer>

</slick-dialog>
