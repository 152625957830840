import { Component, ViewChild, EventEmitter, Output } from "@angular/core";
import { SlickDialogComponent } from "@slick-components";
import { ICustomerModel } from "@models";
import { SleepService, CustomersService, GlobalsService } from "@services";
import { CustomerEditComponent } from "@app/customers/customer-components/customer-edit/customer-edit.component";


@Component({
	selector: "customer-edit-dialog",
	templateUrl: "customer-edit-dialog.component.html",
})

export class CustomerEditDialogComponent {
	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;
	@ViewChild("customerEditRef") customerEditRef: CustomerEditComponent;
	@Output() onSavedChange = new EventEmitter();
	customerModel: ICustomerModel;
	spinnerStatus: string;
	skeditPay: boolean = GlobalsService.company.useSkeditPay;
	agreementspinnerStatus: string;


	resolve: any;

	constructor(private customersService: CustomersService) {
	}

	openDialog(customerModel: ICustomerModel): Promise<ICustomerModel> {
		return new Promise<ICustomerModel>((resolve) => {
			this.resolve = resolve;

			this.spinnerStatus = "reset";

			this.customerModel = customerModel;
			this.dialogRef.showDialog();

            if (this.customerEditRef)
			    this.customerEditRef.clearSubmitted();
		})
	}

	async onSave() {
		try {

			// set submitted first so that we can flag the error messages to show
            if (this.customerEditRef)
				this.customerEditRef.setSubmitted();

			if (this.customerEditRef.isValid() === false) {
				this.spinnerStatus = "error";
				return;
			}

			this.spinnerStatus = "spin";
			await SleepService.sleep(500);

			if (!this.customerModel.customerId)
				this.customerModel = await this.customersService.addCustomer(this.customerModel);
			else
				this.customerModel = await this.customersService.updateCustomer(this.customerModel);

			this.spinnerStatus = "ok";

			await SleepService.sleep(500);

            this.dialogRef.hideDialog();
			this.onSavedChange.emit('Changed');
            if (this.customerEditRef)
                this.customerEditRef.clearSubmitted();

			this.resolve(this.customerModel);
		}

		catch (err) {
			this.spinnerStatus = "error";
		}
	}

	onCancel() {
		this.dialogRef.hideDialog();
		this.resolve(null);
	}

	async sendServiceAgreement() {
		try {
			this.agreementspinnerStatus = "spin";
			await this.customerEditRef.sendServiceAgreement();
			this.agreementspinnerStatus = "ok";
		}
		catch {
			this.agreementspinnerStatus = "error";
		}
	}
}