export enum PayPeriodDefintionTypes {
	EveryWeek = 0,
	EveryOtherWeek = 1,
	TwiceMonthly = 2
}

export interface IPayPeriodDefinitionModel {
	payPeriodDefinitionId: number;
	name: string;
	definitionType: PayPeriodDefintionTypes;
	startDayOfWeek: number;
	dayOfMonth1?: number;
	dayOfMonth2?: number;
	lastPayPeriodStartDate?: Date;
	regularPayQBCode: string;
	overtimePayQBCode: string;
	ptoQBCode: string;
	isDefault: boolean;
	active: boolean;
	overtimeTypeId: number;
}

export class PayPeriodDefinitionModel implements IPayPeriodDefinitionModel {
	payPeriodDefinitionId: number;
	name: string;
	definitionType: PayPeriodDefintionTypes;
	startDayOfWeek: number;
	dayOfMonth1?: number;
	dayOfMonth2?: number;
	lastPayPeriodStartDate?: Date;
	regularPayQBCode: string;
	overtimePayQBCode: string;
	ptoQBCode: string;
	isDefault: boolean;
	active: boolean;
	overtimeTypeId: number;

}