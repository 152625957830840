import { Injectable } from '@angular/core';
import { HttpService } from './utils/http.service';
import { IItemModel, IItemListViewModel, IInvoiceModel, CustomerModel, ICustomerModel, JellyFishOrderModel, IJellyFishOrderModel, IJellyFishOrderSpecsModel } from '@models';
import { promises } from 'dns';

@Injectable()
export class JellyFishService {
    constructor(private httpService: HttpService) { }



    public async getJellyFishOrdersForInfiniteScroll(statusFilter: number, pageCount: number, searchString: string): Promise<IJellyFishOrderModel[]> {
       
        return await this.httpService.get(`jellyfish/GetJellyFishOrdersForInfiniteScroll?statusFilter=${statusFilter}&pageCount=${pageCount}&searchString=${searchString}`);
    }

    public async submitOrderToJellyFish(jellyFishOrderModel: IJellyFishOrderModel): Promise<IJellyFishOrderModel> {
        return await this.httpService.post(`jellyfish/submitOrderToJellyFish`, jellyFishOrderModel);
    }

    public async updateJellyFishInvoice(invoiceModel: IInvoiceModel, paymentId: number, childInvoiceId:number): Promise<IInvoiceModel> {
        
        return await this.httpService.post(`jellyfish/updateJellyFishInvoice?paymentId=${paymentId}&childInvoiceId=${childInvoiceId}`, invoiceModel);
    }

    public async approveJellyFishOrder(jellyFishInvoiceId: number): Promise<JellyFishOrderModel> {
        return await this.httpService.post(`jellyfish/approveJellyFishOrder`, jellyFishInvoiceId);
    }

    public async getJellyFishCustomer(): Promise<ICustomerModel> {
        return await this.httpService.get(`jellyfish/getJellyFishCustomer`);
    }

    public async generateJellyFishInvoiceModel(invoiceModel: IInvoiceModel): Promise<IInvoiceModel> {
        return await this.httpService.post(`jellyFish/generateJellyFishInvoiceModel`, invoiceModel);
    }

    public async saveOrder(jellyFishOrderModel: IJellyFishOrderModel): Promise<IJellyFishOrderModel> {
        return await this.httpService.post(`jellyFish/saveOrder`, jellyFishOrderModel);
    }

    public async saveOrderSpecs(jellyFishOrderSpecs: IJellyFishOrderSpecsModel): Promise<IJellyFishOrderSpecsModel> {
        if (!jellyFishOrderSpecs.linearFeet)
            jellyFishOrderSpecs.linearFeet = 0;
        if (!jellyFishOrderSpecs.homeRunsOver50ft)
            jellyFishOrderSpecs.homeRunsOver50ft = 0;
        if (!jellyFishOrderSpecs.jumpsOver20ft)
            jellyFishOrderSpecs.jumpsOver20ft = 0;
        return await this.httpService.post(`jellyFish/saveOrderSpecs`, jellyFishOrderSpecs);
    }

    public async createOrderFromInvoice(invoiceModel: IInvoiceModel): Promise<IInvoiceModel> {
        return await this.httpService.post(`jellyFish/createOrderFromInvoice`, invoiceModel);
    }

    public async getJellyFishOrder(orderId: number): Promise<IJellyFishOrderModel> {
        return await this.httpService.get(`jellyfish/getJellyFishOrder?orderId=${orderId}`);
    }

    
}
