import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { SlickSearchBarComponent } from "./slick-search-bar.component";

@NgModule({
	imports: [CommonModule, FormsModule],
	exports: [SlickSearchBarComponent],
	declarations: [SlickSearchBarComponent]
})
export class SlickSearchBarModule { }

export { SlickSearchBarComponent } 