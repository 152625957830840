import { Component, Input, ViewChild } from "@angular/core";
import { SlickDialogComponent } from "@slick-components";
import { SlickConfirmDialogComponent } from "@slick-components";
import { UtilsService, LookupService, SleepService, HttpService } from "@services";
import Swal from "sweetalert2";
import { ITexterModel, TexterModel } from "../../models/texter/texter.model";


@Component({
	selector: "texter-dialog",
	templateUrl: "./texter-dialog.component.html"
})
export class TexterDialogComponent {
	@ViewChild("texterDialogRef") texterDialogRef: SlickDialogComponent;
	@ViewChild("noNumberEnteredRef") noNumberEnteredRef: SlickConfirmDialogComponent;
	@ViewChild("noMessageEnteredRef") noMessageEnteredRef: SlickConfirmDialogComponent;
	@Input() isMobile: boolean;
	
	messageSplit: boolean;
	spinnerStatus: string = null;
	resolve: any;
	texterModel: TexterModel;
	
	constructor(private httpService: HttpService, private lookupService: LookupService) {
	}

	async showDialog(texterModel: ITexterModel = null): Promise<ITexterModel> {
		return new Promise<ITexterModel>(async (resolve) => {
			if (this.isMobile)
				this.texterDialogRef.width = 360;
			this.resolve = resolve;
			this.texterModel = (texterModel) ? texterModel : new TexterModel();
			this.texterModel.uuid = (this.texterModel.uuid) ? this.texterModel.uuid : UtilsService.newGuid();
			await SleepService.sleep();

			this.checkLength();
			this.texterDialogRef.showDialog();

		})
	}


	async onSendText(texterModel: ITexterModel) {
		try {
			this.spinnerStatus = "spin";
			
			await SleepService.sleep(500);
			// Check to make sure that there is a number to send to
			if (texterModel.numbers === "") {
				await this.noNumberEnteredRef.confirm();
				this.spinnerStatus = "error";
				return;
			}
			if (texterModel.body === "") {
				await this.noMessageEnteredRef.confirm();
				this.spinnerStatus = "error";
				return;
			}

			const blackListedNumbers: string[] = await this.httpService.get(`textchat/CheckIfNumbersAreBlackListed?numbers=${texterModel.numbers}`);
			if (blackListedNumbers.length > 0) {
				await Swal.fire({
					icon: 'warning',
					title: 'Oops...',
					text: `${blackListedNumbers.join(", ")} has opted out of text messaging`,
					confirmButtonColor: '#007bff',
					heightAuto: false
				});
				this.spinnerStatus = "error";
				return;
			}

			let sent = true;
			switch (texterModel.type) {
				case "invoice":
					sent = await this.httpService.post("textchat/textInvoice", texterModel);
					break;
				case "latham":
					sent = await this.httpService.post("lathamOrders/textLathamOrder", texterModel);
			}

			if (sent)
				this.resolve(texterModel);

			this.spinnerStatus = "ok"
			this.texterDialogRef.hideDialog();
		}
		catch {
			this.spinnerStatus= "error"
        }
	}

	onCancel() {
		this.texterDialogRef.hideDialog();
		this.resolve(null);
	}

	checkLength() {
		this.messageSplit = this.texterModel.body.length > 160;
    }


}
