import { IInvoiceModel } from "../invoices/invoice.model";
import { IPaymentModel } from "../payments/payment.model";
import { UtilsService } from "@services/utils/utils.service";

export interface IAppliedPaymentModel {
	appliedPaymentId: number;
	invoiceId: number;
	paymentId: number;
	uuid: string;
	appliedAmount: number;
	invoice: IInvoiceModel;
	payment: IPaymentModel;
	// Used in the Print Invoice Payment dialog
	isChecked: boolean;
}

export class AppliedPaymentModel implements IAppliedPaymentModel {
	//constructor() {
	//	this.appliedPaymentId = 0;
	//	this.uuid = UtilsService.newGuid();
	//	this.appliedAmount = 0;
	//}

	appliedPaymentId: number;
	invoiceId: number;
	paymentId: number;
	uuid: string;
	appliedAmount: number;
	invoice: IInvoiceModel;
	payment: IPaymentModel;
	// Used in the Print Invoice Payment dialog
	isChecked: boolean;
}