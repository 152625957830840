export interface IAppointmentChecklistQuestionModel {
    appointmentChecklistQuestionId: number;
    appointmentId: number;
    checklistQuestionId: number;
    question: string;
    isChecked: boolean;
    checkedBy: string;
    saving: boolean;
    checklistStatus: string;
}

export class AppointmentChecklistQuestionModel implements IAppointmentChecklistQuestionModel {
    constructor() {
        this.checklistStatus = "Complete";
    }
    appointmentChecklistQuestionId: number;
    appointmentId: number;
    checklistQuestionId: number;
    question: string;
    isChecked: boolean;
    checkedBy: string;
    saving: boolean;
    checklistStatus: string;
}