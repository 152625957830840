import {EventEmitter, Injectable} from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IDailyCrewModel, ICrewMemberModel } from "@models";
import * as moment from "moment";

@Injectable()
export class CrewsService {
	constructor(private httpService: HttpService) { }

	async getDailyCrewsForDate(date: Date): Promise<IDailyCrewModel[]> {
		const params = {
			date: moment(date).format('YYYY-MM-DD')
		}
		return await this.httpService.get("/crews/getDailyCrewsForDate", params);
	}

	async getUsersFlaggedAsCrewMembers(): Promise<ICrewMemberModel[]> {
		return await this.httpService.get("/crews/getDailyCrewsForDate");
	}
 
	async updateDailyCrews(date: Date, dailyCrewModels: IDailyCrewModel[]): Promise<IDailyCrewModel[]> {
		const params = {
			date: moment(date).format('YYYY-MM-DD'),
			dailyCrewModels: dailyCrewModels
		}

		return await this.httpService.post("/crews/updateDailyCrews", params);
	}

}
