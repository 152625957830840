export interface IJobTypeModel {
	jobTypeId: number;
	description: string;
	startJobAsType: string;
	active: boolean;
}

export class JobTypeModel implements IJobTypeModel {
	constructor() {
		this.jobTypeId = 0;
		this.startJobAsType = "Sales Order"
		this.active = true;
	}

	jobTypeId: number;
	description: string;
	startJobAsType: string;
	active: boolean;
}