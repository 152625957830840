export interface IiCalModel {
    frequency: string;
    weeklySunday: boolean;
    weeklyMonday: boolean;
    weeklyTuesday: boolean;
    weeklyWednesday: boolean;
    weeklyThursday: boolean;
    weeklyFriday: boolean;
    weeklySaturday: boolean;
    dateType: number;
    dayOfWeek: string;
    dayOfMonth: number;
    weekNumber: number;
}

export class iCalModel implements IiCalModel {
    frequency: string;
    weeklySunday: boolean;
    weeklyMonday: boolean;
    weeklyTuesday: boolean;
    weeklyWednesday: boolean;
    weeklyThursday: boolean;
    weeklyFriday: boolean;
    weeklySaturday: boolean;
    dateType: number;
    dayOfWeek: string;
    dayOfMonth: number;
    weekNumber: number;
}