<div *ngIf="!selectedQuickAddCategory" class="d-flex flex-wrap justify-content-start align-items-start">
	<div *ngFor="let quickAddCategory of quickAddCategories" class="d-flex flex-column justify-content-center mb-2 me-4 quick-add-container" (click)="selectCategory(quickAddCategory)">
		<div class="quick-add-img p-1 align-self-center justify-content-center">
			<img src="{{quickAddCategory.imageUrl }}" />
		</div>
		<div class="quick-add-text">{{quickAddCategory.categoryName}}</div>
	</div>
</div>

<div *ngIf="selectedQuickAddCategory" class="d-flex flex-wrap justify-content-start align-items-start">
	<div class="d-flex flex-column justify-content-center align-content-center mb-2 me-4 quick-add-container" style="text-align: center" (click)="this.selectedQuickAddCategory = null">
		<i class="far fa-angle-double-left" style="font-size: 28px;"></i>
	</div>
	<div *ngFor="let quickAddCategoryItem of selectedQuickAddCategory.quickAddCategoryItems" class="d-flex flex-column justify-content-center mb-2 me-3 quick-add-container" (click)="selectItem(quickAddCategoryItem)">
		<div class="quick-add-img p-1 align-self-center justify-content-center">
			<img src="{{quickAddCategoryItem.imageUrl }}" />
		</div>
		<div class="quick-add-text">{{quickAddCategoryItem.sku}}<i style="cursor: pointer" class="ms-1 fas fa-info-circle text-info" (click)="showItemInfo($event, quickAddCategoryItem)"></i></div>
	</div>
</div>


<slick-dialog #itemDetailDialogRef top="100" width="600" header="Item Detail" [resizable]="false" [draggable]="false" [minimizable]="false">
	<div *ngIf="itemDetail" style="text-align: center">
		<img src="{{itemDetail.imageUrl }}" style="max-width: 560px;" />
		<br />
		<h5 class="my-2 border-0">{{itemDetail.description}}</h5>
		<table *ngIf="itemDetail.itemAssemblies && itemDetail.itemAssemblies.length > 0" class="table table-striped table-sm mt-3">
			<colgroup>
				<col style="width: 40px" />
				<col style="width: 60px" />
				<col />
				<col style="width: 40px" />
			</colgroup>
			<thead>
				<tr>
					<td></td>
					<td>Sku</td>
					<td>Description</td>
					<td>Qty</td>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let assemblyItem of itemDetail.itemAssemblies">
					<td><img [src]="assemblyItem.imageThumbnailUrl" style="max-width: 60px; max-height: 40px;" /></td>
					<td>{{assemblyItem.sku}}</td>
					<td>{{assemblyItem.description}}</td>
					<td>{{assemblyItem.quantity}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</slick-dialog>