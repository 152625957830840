import { Injectable } from "@angular/core";
import { ISkeditPayTransactionLogModel, IPaymentModel, ISkeditPayRecurringPaymentModel, iCalModel, IShippingModel, IShippingRecordModel, ShippingRecordModel } from "@models";
import { HttpService } from "./utils/http.service";
import * as moment from "moment";

@Injectable()
export class ShippingService {
    constructor(private httpService: HttpService) { }

    async getShippingRecord(shippingRecordId: number): Promise<IShippingRecordModel> {
        return await this.httpService.get(`/shipping/getShippingRecord?shippingRecordId=${shippingRecordId}`)
    }

    async updateShippingRecord(shippingRecord: IShippingRecordModel): Promise<IShippingRecordModel> {
        return await this.httpService.post(`/shipping/updateShippingRecord`, shippingRecord);
    }

    async ship(shippingRecordId: number): Promise<IShippingRecordModel> {
        return await this.httpService.post(`/shipping/ship`,shippingRecordId)
    }

    async createShippingRecordFromInvoice(invoiceId: number): Promise<IShippingRecordModel> {
        return await this.httpService.post(`/shipping/createShippingRecordFromInvoice`, invoiceId);
    }

   
}
