<section>
    <div class="row mb-3">
        <div class="col-5">
            <strong>Question</strong>
        </div>
        <div class="col-3">
            <strong>Status</strong>
        </div>
        <div class="col-3">
            <strong>Checked By</strong>
        </div>
    </div>
    <div class="row" *ngFor="let question of newQuestionsList">
        <div class="col-5">
            <div class="form-group">
                <div class="input-group input-group-sm">
                    <input type="text" class="form-control" placeholder="Question" [(ngModel)]="question.question" />
                </div>
            </div>
        </div>
        <div class="col-3">
            <slick-drop-down [items]="checklistStatuses"
                             [(ngModel)]="question.checklistStatus"
							 ></slick-drop-down>
        </div>
        <div class="col-3">
            <div class="input-group mb-3 input-group-sm">
                <div class="input-group-text">
                    <input type="checkbox" [(ngModel)]="question.isChecked" (ngModelChange)="question.checkedBy = ''" />
                </div>
                <input type="text" class="form-control" placeholder="Checked By" [(ngModel)]="question.checkedBy" [disabled]="!question.isChecked">
            </div>
        </div>
        <div class="col-1">
            <div class="input-group">
                <button class="btn btn-danger btn-sm" (click)="deleteNewQuestion(question)"><i class="bi bi-trash"></i></button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <button class="save-button btn btn-primary btn-sm" (click)="newQuestion()"><i class="far fa-plus"></i> Question</button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <button class="save-button btn btn-outline-success me-3" (click)="onSave()"><i class="bi bi-check-circle"></i> Save</button>
            <button class="cancel-button btn btn-outline-danger" (click)="onCancel()"><i class="bi bi-x-circle"></i> Cancel</button>
        </div>
    </div>
</section>