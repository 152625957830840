import { Component, ViewChild } from '@angular/core';
import { SlickDialogComponent, SlickToastService } from "@slick-components";
import { IPaymentModel } from '@models';
import { SleepService, GlobalsService, PaymentsService } from '@services';
import { PaymentEditComponent } from '../payment-edit/payment-edit.component';

@Component({
	selector: 'payment-edit-dialog',
	templateUrl: 'payment-edit-dialog.component.html',
	providers: [PaymentsService]
})
export class PaymentEditDialogComponent {
	@ViewChild('dialogRef', { static: true }) dialogRef: SlickDialogComponent;
	@ViewChild('paymentEditComponentRef', { static: true }) paymentEditComponentRef: PaymentEditComponent;

	paymentModel: IPaymentModel;
	
	spinnerStatus: string;
	resolve: any;

	isUserAdmin: boolean = (GlobalsService.userInfo.roleTypeId === 1);
	adminEdit: boolean;
	isBambora: boolean = GlobalsService.company.useBambora;
	canVoidPayments: boolean = GlobalsService.checkPermission("Payments", "CanVoidPayments");
	canRefundPayments: boolean = GlobalsService.checkPermission("Payments", "CanRefundPayments");
	isQuickbooksEnabled = GlobalsService.isQuickbooksEnabled;
	isBusinessCentralEnabled = GlobalsService.isBusinessCentralEnabled;


	constructor(private paymentsService: PaymentsService,
		private slickToastService: SlickToastService) {
	}

	openDialog(paymentModel: IPaymentModel): Promise<IPaymentModel> {
		return new Promise((resolve) => {
			this.resolve = resolve;
			this.spinnerStatus = 'reset';

			this.adminEdit = false;
			this.paymentModel = paymentModel;
			this.dialogRef.showDialog();
		});
	}


	async savePayment() {
		try {
			this.paymentEditComponentRef.isSubmitted = true;
			
			if (this.paymentEditComponentRef.validate() === false) {
				this.spinnerStatus = 'error';
				return;
			}

			this.spinnerStatus = 'spin';

			await SleepService.sleep(500);

			this.paymentModel = await this.paymentsService.updatePayment(this.paymentModel);

			//seperating this out to fix qb errors being thrown and causing the payment model to not be returned.
			if ((this.isQuickbooksEnabled && this.paymentModel.syncToQuickbooks === true) || (this.isBusinessCentralEnabled && this.paymentModel.syncToBusinessCentral === true)) {
				this.paymentModel = await this.paymentsService.syncToQuickbooks(this.paymentModel.paymentId);
			}

			this.spinnerStatus = 'ok';

			await SleepService.sleep(500);

			this.paymentEditComponentRef.isSubmitted = false;
			this.resolve(this.paymentModel);
			this.dialogRef.hideDialog();
		} catch {
			this.spinnerStatus = 'error';
		}
	}

	async cancelPayment() {
		await this.dialogRef.hideDialog();
		this.resolve(null);
	}

	async generatePaymentPdf() {
		await this.paymentEditComponentRef.generatePaymentPdf();
	}


	async emailPayment() {
		await this.paymentEditComponentRef.emailPayment();
	}

	async openCreditCardDialog() {
		await this.paymentEditComponentRef.openCreditCardDialog(); 
	}

	async voidPayment() {
		await this.paymentEditComponentRef.voidPayment();
	}

	async openRefundPaymentDialog() {
		await this.paymentEditComponentRef.openRefundPaymentDialog();
    }

}
