import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlickCalendarComponent } from "./slick-calendar.component";

@NgModule({
	imports: [CommonModule],
	declarations: [SlickCalendarComponent],
	exports: [SlickCalendarComponent]
})
export class SlickCalendarModule { }

export { SlickCalendarComponent }
