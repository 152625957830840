import { ITextChatAttendantModel } from "./text-chat-attendant.model";
import { ITextChatMessageModel } from "./text-chat-message.model";

export interface ITextChatConversationModel {
    textChatConversationId: number;
    uuid: string;
    appointmentId?: number;
    locationId?: number;
    customerId?: number;
    customerFullName: string;
    customerOptedOut: boolean;
    customerPhoneNumber: string;
    isAssigned: boolean;
    assignedToUserId?: number;
    assignedToUserFullName: string;
    attendantFullNames: string;
    isClosed: boolean;
    closedDate: Date;
    closedBy: string;

    attendants: ITextChatAttendantModel[];
    messages: ITextChatMessageModel[];
}

export class TextChatConversationModel implements ITextChatConversationModel {
    textChatConversationId: number;
    uuid: string;
    appointmentId?: number;
    locationId?: number;
    customerId?: number;
    customerFullName: string;
    customerOptedOut: boolean;
    customerPhoneNumber: string;
    isAssigned: boolean;
    assignedToUserId?: number;
    assignedToUserFullName: string;
    attendantFullNames: string;
    isClosed: boolean;
    closedDate: Date;
    closedBy: string;

    attendants: ITextChatAttendantModel[];
    messages: ITextChatMessageModel[];
}
