import { Injectable } from '@angular/core';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { DevLogsStore } from '@stores';
import { IDailyCrewModel } from '@models';
import { SQLiteService } from '@sqlite';
import { DevTraceService } from '@services/utils/dev-trace.service';

import * as moment from 'moment';

interface IDailyCrewRecord {
	scheduledDate: string;
	dailyCrewData: string;
}

@Injectable()
export class DailyCrewStorageService {
	private db!: SQLiteDBConnection;

	readonly databaseName: string = 'dailyCrews';

	constructor(private devLogsStore: DevLogsStore,
		private devTrace: DevTraceService,
		private sqliteService: SQLiteService) {
	}

	async initializeDatabase(): Promise<void> {
		try {
			await this.sqliteService
				.addUpgradeStatement({
					database: this.databaseName,
					upgrade: this.dailyCrewsUpdates
				});

			// create and/or open the database
			const loadToVersion = this.dailyCrewsUpdates[this.dailyCrewsUpdates.length - 1].toVersion;
			this.db = await this.sqliteService.openDatabase(this.databaseName, loadToVersion);
			this.sqliteService.setVersion(this.databaseName, loadToVersion);
			//this.devLogsStore.addMessage(`Init ${this.databaseName} database to version ${loadToVersion}`);
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	async deleteDatabase(): Promise<void> {
		await this.sqliteService.deleteDatabase(this.databaseName);
		this.devLogsStore.addMessage(`Deleted ${this.databaseName}`);
	}

	async updateDailyCrews(scheduledDate: Date, dailyCrews: IDailyCrewModel[]) {
		try {
			const currentFormattedDate = moment().startOf("date").format("YYYYMMDD");


			//this.devLogsStore.addMessage(`Deleting scheduledAppointment records for all but ${currentFormattedDate}`);

			// Delete all records for the current user that are not for current date
			await this.db.run(`
            DELETE FROM dailyCrews 
            where scheduledDate NOT LIKE '${currentFormattedDate}_%';
        `);

			const scheudledDateKey: string = moment(scheduledDate).startOf("date").format("YYYYMMDD");

			//this.devLogsStore.addMessage(`Adding dailyCrew record for scheduledDate ${scheudledDateKey}`);

			const dailyCrewsEncoded = SQLiteService.encodeJSON(dailyCrews);

			await this.db.run(`delete from dailyCrews where scheduledDate='${scheudledDateKey}';`);

			const sql = `INSERT INTO dailyCrews (scheduledDate, dailyCrewsData) VALUES ('${scheudledDateKey}', '${dailyCrewsEncoded}');`;
			await this.db.run(sql);
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	async getDailyCrews(scheduledDate: Date): Promise<IDailyCrewModel[]> {
		try {
			const scheudledDateKey: string = moment(scheduledDate).startOf("date").format("YYYYMMDD");
			const dailyCrewRecords: IDailyCrewRecord[] = (await this.db.query(`SELECT * FROM dailyCrews where scheduledDate='${scheudledDateKey}';`)).values as IDailyCrewRecord[];

			if ((dailyCrewRecords?.length ?? 0) === 0)
				return null;

			const dailyCrewsEncoded = dailyCrewRecords[0].dailyCrewData;
			const dailyCrewsJSON = SQLiteService.decodeJSON(dailyCrewsEncoded)
			const dailyCrews: IDailyCrewModel[] = JSON.parse(dailyCrewsJSON);

			//this.devLogsStore.addMessage(`Returning JSON ${dailyCrewsJSON}`);

			return dailyCrews;
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	readonly dailyCrewsUpdates = [
		{
			toVersion: 1,
			statements: [
				`
CREATE TABLE IF NOT EXISTS dailyCrews(
    scheduledDate string PRIMARY KEY,
    dailyCrewsData TEXT NULL
);`
			]
		},
		/* add new statements below for next database version when required*/
		/*
		{
		toVersion: 2,
		statements: [
			`ALTER TABLE users ADD COLUMN email TEXT;`,
		]
		},
		*/
	]
}


