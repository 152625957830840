export enum SlickGridColumnFilterTypes { none, text, dropdown }
export enum SlickGridColumnSortDirection { none, asc, desc }
export enum SlickGridColumnTitleOrientation { Horizontal, Vertical }

export interface ISlickGridFilterDropdownValue {
	id: string,
	text: string
}

export interface ISlickGridColumnModel {
	columnKey: string;
	title: string;
	titleOrientation: SlickGridColumnTitleOrientation;
	titleHeight: string;
	dataFieldName: string;
	displayOrder: number;
	sortable: boolean;
	sortDirection: SlickGridColumnSortDirection;
	filterType: SlickGridColumnFilterTypes;
	filterText: string;
	lastSearchText: string;
	filterDropdownValues: ISlickGridFilterDropdownValue[];
	filterDropdownValue: string;
	isFiltering: boolean;
	isLocked: boolean;
	default: boolean;
	visible: boolean;
	width: string;
	flexible: boolean;
	html: string;
}

export class SlickGridColumnModel implements ISlickGridColumnModel {
	columnKey: string;
	title: string;
	titleOrientation: SlickGridColumnTitleOrientation;
	titleHeight: string;
	dataFieldName: string;
	displayOrder: number;
	sortable: boolean = true;
	sortDirection: SlickGridColumnSortDirection = SlickGridColumnSortDirection.none;
	filterType: SlickGridColumnFilterTypes = SlickGridColumnFilterTypes.none;
	filterText: string;
	lastSearchText: string;
	filterDropdownValues: ISlickGridFilterDropdownValue[];
	filterDropdownValue: string;
	isFiltering: boolean;
	isLocked: boolean;
	default: boolean = true;
	visible: boolean = true;
	width: string;
	flexible: boolean = false;
	html: string = '';
	// Used to create custom HTML for the column
	columnFormatCallback: Function;

	static addGridColumn(params: any): ISlickGridColumnModel {
		let gridColumn = new SlickGridColumnModel();

		if (params.columnKey)
			gridColumn.columnKey = params.columnKey;
		if (params.title)
			gridColumn.title = params.title;
		gridColumn.titleOrientation = params.titleOrientation || SlickGridColumnTitleOrientation.Horizontal;
		if (params.titleHeight)
			gridColumn.titleHeight = params.titleHeight;
		if (params.dataFieldName)
			gridColumn.dataFieldName = params.dataFieldName;
		if (params.displayOrder !== null && params.displayOrder !== undefined)
			gridColumn.displayOrder = params.displayOrder;
		if (params.sortable !== null && params.sortable !== undefined)
			gridColumn.sortable = params.sortable;
		if (params.sortDirection)
			gridColumn.sortDirection = <SlickGridColumnSortDirection>params.sortDirection;
		if (params.filterType)
			gridColumn.filterType = <SlickGridColumnFilterTypes>params.filterType;
		if (params.filterDropdownValues)
			gridColumn.filterDropdownValues = <ISlickGridFilterDropdownValue[]>params.filterDropdownValues;
		if (params.width !== null && params.width !== undefined) 
			gridColumn.width = params.width;
		if (!!params.flexible)
			gridColumn.flexible = params.flexible;
		if (params.html)
			gridColumn.html = params.html;
		if (params.default === null || params.default === undefined)
			gridColumn.default = true
		else
			gridColumn.default = params.default;
		gridColumn.visible = gridColumn.default;
		if (params.columnFormatCallback) {
			console.error("columnFormatCallback is no longer used.  Please use key and <slick-grid-column columnKey='key'> with <ng-template let-item [slickGridColumnTemplate]>")
		}

		return gridColumn;
	}
}

