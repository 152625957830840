import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { GlobalsService } from "@services";

import * as atatus from 'atatus-spa';
atatus.config('68bf3c8d645c49c48dbc319152878202').install();

@Injectable()
class AtatusErrorHandler implements ErrorHandler {
    handleError(error: any): void {
		if (atatus && environment.production === true) {
			// Send the error to Atatus
			const tags = ['desktop'];
			if (GlobalsService.userInfo)
				tags.push(GlobalsService.userInfo.fullName);
			
			atatus.notify(error.originalError || error, null, tags);
		}

        console.error(error);
        throw error;

	}
}

export { AtatusErrorHandler };