export interface IAddOnsModel {
    housingType: string;
    polymerHousingMountingKit: string;
    extendedMountingFeet: boolean;
    deckCoverType: string;
    featureControllerType: string;
    pumpType: string;
}

export class AddOnsModel implements IAddOnsModel
{
    housingType: string;
    polymerHousingMountingKit: string;
    extendedMountingFeet: boolean;
    deckCoverType: string;
    featureControllerType: string;
    pumpType: string;
}