export interface ICustomerOutreachModel {
	customerOutreachId: number;
	customerId: number;
	userId: number;
	userFullName: string;
	outreachDate: Date;
	outreachType: string;
	notes: string;
	toDelete: boolean;
}

export class CustomerOutreachModel implements ICustomerOutreachModel { 
	constructor() {
		this.customerOutreachId = 0;
	}

	customerOutreachId: number;
	customerId: number;
	userId: number;
	userFullName: string;
	outreachDate: Date;
	outreachType: string;
	notes: string;
	toDelete: boolean;
}
