<div *ngIf="shippingAddress">
    <b>Shipping Address</b>


    <div style="line-height: 1rem" class="customer-info mt-1 p-2 card">
        <span *ngIf="shippingAddress?.companyName" style="font-size: 18px; font-weight: bold;">{{shippingAddress?.companyName}}</span>
        <span *ngIf="shippingAddress?.companyName" class="mt-1" style="font-size: .8rem; font-weight: bold;">{{shippingAddress?.firstName}} {{shippingAddress?.lastName}}</span>
        <span *ngIf="!shippingAddress?.companyName && (shippingAddress?.firstName || shippingAddress?.lastName)" style="font-size: 18px; font-weight: bold;">{{shippingAddress?.firstName}} {{shippingAddress?.lastName}}</span>
        <span *ngIf="shippingAddress?.address1 || shippingAddress?.address2 || shippingAddress?.city || shippingAddress?.state || shippingAddress?.zipcode" class="mt-2"><i class="bi bi-house-fill" style="margin-right: 4px;"></i>{{shippingAddress?.address1}} {{shippingAddress?.address2}} <span *ngIf="shippingAddress?.city">{{shippingAddress?.city}}, </span> <span *ngIf="shippingAddress?.state"> {{shippingAddress?.state}},</span> {{shippingAddress?.zipcode}}<br /></span>
    </div>

    <div class="mt-1 ms-1">
        <div style="width:200px">
            <span *ngIf="(!isCanada || (isCanada && !lathamOrderId)) && customer != null && editable"> 
                <a style="cursor:pointer; color: var(--bs-link-color);" (click)="onLinkAddressesChange()">Edit Address</a>
            </span>

            <div *ngIf="isCanada">
                <slick-drop-down [(ngModel)]="shipTo"
                                 [items]="shippingOptions"
                                 [compact]="true"
                                 (onSelect)="onShippingAddressSelected($event)">
                </slick-drop-down>
            </div>


        </div>
    </div>
</div>

<shipping-address-dialog #shippingAddressDialogRef [isMobile]="isMobile"></shipping-address-dialog>