export interface ICustomCustomerFieldModel {
    id: number;
    customCustomerFieldId: number;
    customText: string;
    active: boolean;
}

export class CustomCustomerFieldModel implements ICustomCustomerFieldModel {
    id: number;
    customCustomerFieldId: number;
    customText: string;
    active: boolean;
}
