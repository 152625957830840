import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IPriceLevelGroupModel, IPriceLevelItemModel, PriceLevelGroupModel } from "@models";
import { UtilsService } from "./services.module";

@Injectable()
export class PriceLevelService {
	constructor(private httpService: HttpService) { }

	async getAllPriceLevelItems(priceLevelId: number): Promise<IPriceLevelItemModel[]> {
		return this.httpService.get("/priceLevels/getAllPriceLevelItems", { priceLevelId: priceLevelId });
	}

	async getPriceLevelGroups(): Promise<IPriceLevelGroupModel[]> {
		return this.httpService.get("/priceLevels/getPriceLevelGroups");
	}

	async getPriceLevelItem(priceLevelItemId: number): Promise<IPriceLevelItemModel> {
		return this.httpService.get("/priceLevels/getPriceLevelItem", priceLevelItemId);
	}

	async updatePriceLevelItems(priceLevelId: number, priceLevelItemModels: IPriceLevelItemModel[]): Promise<IPriceLevelItemModel[]> {
		return this.httpService.post("/priceLevels/updatePriceLevelItems?priceLevelId=" + priceLevelId, priceLevelItemModels);
	}
}