import { Injectable } from '@angular/core';
import { GlobalsService } from './globals.service';



@Injectable()
export class AuthService  {
    constructor() {}

    get isAuthenticated(): boolean {
        const token = this.getToken();

        return !!token;
    }

    setToken(token: string, rememberMe: boolean) {
        if(rememberMe)
            localStorage.setItem('skedit_jwt_token', token);
        else
            sessionStorage.setItem('skedit_jwt_token', token);
    }

    getToken(): string {
        var token = localStorage.getItem('skedit_jwt_token');
        if (token)
            return token
        else 
            return sessionStorage.getItem('skedit_jwt_token');
    }

    clearToken() {
        console.info('Clearing Token');
        GlobalsService.jwtToken = null;
        localStorage.removeItem('skedit_jwt_token');
        sessionStorage.removeItem('skedit_jwt_token');
    }

    canActivate() {
        return this.isAuthenticated;
	}

	canActivateChild() {
		return this.isAuthenticated;
	}
}

export interface IAuthService {
    feature: string;
    canAccess(): boolean;
    canAdd(): boolean;
    canDelete(): boolean;
    canEdit(): boolean;
    canView(): boolean;
}
