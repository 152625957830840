import { Injectable } from "@angular/core";
import { FunctionLockService, GlobalsService, HttpService, LookupService, UtilsService } from "@services";
import { IItemModel, IJellyFishOrderModel, IJellyFishOrderSpecsModel, ILineItemModel, ItemModel, JellyFishOrderingSettingsModel } from "../models";
import Swal from "sweetalert2";

class SystemSkuModel {
    constructor(sku: string, quantity: number, sendToJellyFish: boolean, displayOrder: number) {
        this.sku = sku;
        this.quantity = quantity;
        this.sendToJellyFish = sendToJellyFish;
        this.displayOrder = displayOrder;
    }

    sku: string;
    quantity: number;
    sendToJellyFish: boolean;
    displayOrder: number;
}

// 3 pieces of clips per 1 piece of track
// 1 piece of track per 7.5 feet
// light strands - 37.5 ft long

@Injectable()
export class JellyFishSystemLineItemService {
    constructor(private httpService: HttpService,
        private functionLockService: FunctionLockService,
    private lookupService: LookupService) { }

    private systemSkus: SystemSkuModel[];
    private displayOrder: number;

    private settings: JellyFishOrderingSettingsModel;



    async calculateSystemLineItems(lineItems: ILineItemModel[], jellyFishOrderSpecsModel: IJellyFishOrderSpecsModel, customerId: number, useTenFoot: boolean = false, forceRefresh: boolean = false, forItemsToOrder: boolean = false): Promise<ILineItemModel[]> {
        try {
            await this.functionLockService.lock("JELLYFISH_ORDER_CALCULATE_SYSTEM_LINE_ITEMS");

            this.displayOrder = 0;

            this.systemSkus = [];


            this.settings = this.lookupService.getJellyFishOrderingSettings();

            if (this.settings?.item && jellyFishOrderSpecsModel.linearFeet && !forItemsToOrder) {
                this.addItem(this.settings.item.sku, jellyFishOrderSpecsModel.linearFeet)
            }
            else {

                const trackAmount = Math.ceil(jellyFishOrderSpecsModel.linearFeet / (useTenFoot ? 10 : 7.5));
                const clipAmount = Math.ceil(trackAmount / 3);

                ////////////////
                // 7.5 foot track part numbers
                ////////////////

                if (!useTenFoot) {
                    //////////////////
                    /*TRACK TYPE*/
                    //////////////////
                    switch (jellyFishOrderSpecsModel.trackType) {
                        case "Canted":
                            switch (jellyFishOrderSpecsModel.trackColor) {
                                case "Black":
                                    this.addItem("TR-C7-BLK", trackAmount);
                                    break;
                                case "Musket Brown":
                                    this.addItem("TR-C7-MBRO", trackAmount);
                                    break;
                                case "Royal Brown":
                                    this.addItem("TR-C7-RBRO", trackAmount);
                                    break;
                                case "Dark Bronze":
                                    this.addItem("TR-C7-DRBZ", trackAmount);
                                    break;
                                case "London Brown":
                                    this.addItem("TR-C7-LBRN", trackAmount);
                                    break;
                                case "Charcoal Grey":
                                    this.addItem("TR-C7-CGR", trackAmount);
                                    break;
                                case "Terra Bronze":
                                    this.addItem("TR-C7-TBRO", trackAmount);
                                    break;
                                case "Evergreen":
                                    this.addItem("TR-C7-EVER", trackAmount);
                                    break;
                                case "Russet Red":
                                    this.addItem("TR-C7-RED", trackAmount);
                                    break;
                                case "Harbor Grey":
                                    this.addItem("TR-C7-HGREY", trackAmount);
                                    break;
                                case "Montana Suede":
                                    this.addItem("TR-C7-MSUE", trackAmount);
                                    break;
                                case "Pebblestone Clay":
                                    this.addItem("TR-C7-PCLA", trackAmount);
                                    break;
                                case "Everest":
                                    this.addItem("TR-C7-EVRST", trackAmount);
                                    break;
                                case "Victorian Grey":
                                    this.addItem("TR-C7-VGREY", trackAmount);
                                    break;
                                case "Wicker":
                                    this.addItem("TR-C7-WICK", trackAmount);
                                    break;
                                case "Desert Sand":
                                    this.addItem("TR-C7-DSAN", trackAmount);
                                    break;
                                case "Mountain Blue":
                                    this.addItem("TR-C7-BLU", trackAmount);
                                    break;
                                case "Almond":
                                    this.addItem("TR-C7-ALMD", trackAmount);
                                    break;
                                case "Classic Cream":
                                    this.addItem("TR-C7-CCRE", trackAmount);
                                    break;
                                case "Linen":
                                    this.addItem("TR-C7-LINEN", trackAmount);
                                    break;
                                case "Cameo":
                                    this.addItem("TR-C7-CAM", trackAmount);
                                    break;
                                case "White":
                                    this.addItem("TR-C7-WHTE", trackAmount);
                                    break;
                                case "Copper Penny":
                                    this.addItem("TR-C7-COPPR", trackAmount);
                                    break;
                                case "Sand":
                                    this.addItem("TR-C7-SAND", trackAmount);
                                    break;
                            }
                            break;

                        case "Madhave":
                            switch (jellyFishOrderSpecsModel.trackColor) {
                                case "Black":
                                    this.addItem("TR-MAD7-BLK", trackAmount);
                                    break;
                                case "Musket Brown":
                                    this.addItem("TR-MAD7-MBRO", trackAmount);
                                    break;
                                case "Royal Brown":
                                    this.addItem("TR-MAD7-RBRO", trackAmount);
                                    break;
                                case "Dark Bronze":
                                    this.addItem("TR-MAD7-DRBZ", trackAmount);
                                    break;
                                case "London Brown":
                                    this.addItem("TR-MAD7-LBRN", trackAmount);
                                    break;
                                case "Charcoal Grey":
                                    this.addItem("TR-MAD7-CGR", trackAmount);
                                    break;
                                case "Terra Bronze":
                                    this.addItem("TR-MAD7-TBRO", trackAmount);
                                    break;
                                case "Evergreen":
                                    this.addItem("TR-MAD7-EVER", trackAmount);
                                    break;
                                case "Russet Red":
                                    this.addItem("TR-MAD7-RED", trackAmount);
                                    break;
                                case "Harbor Grey":
                                    this.addItem("TR-MAD7-HGREY", trackAmount);
                                    break;
                                case "Montana Suede":
                                    this.addItem("TR-MAD7-MSUE", trackAmount);
                                    break;
                                case "Pebblestone Clay":
                                    this.addItem("TR-MAD7-PCLA", trackAmount);
                                    break;
                                case "Everest":
                                    this.addItem("TR-MAD7-EVRST", trackAmount);
                                    break;
                                case "Victorian Grey":
                                    this.addItem("TR-MAD7-VGREY", trackAmount);
                                    break;
                                case "Wicker":
                                    this.addItem("TR-MAD7-WICK", trackAmount);
                                    break;
                                case "Desert Sand":
                                    this.addItem("TR-MAD7-DSAN", trackAmount);
                                    break;
                                case "Mountain Blue":
                                    this.addItem("TR-MAD7-BLU", trackAmount);
                                    break;
                                case "Almond":
                                    this.addItem("TR-MAD7-ALMD", trackAmount);
                                    break;
                                case "Classic Cream":
                                    this.addItem("TR-MAD7-CCRE", trackAmount);
                                    break;
                                case "Linen":
                                    this.addItem("TR-MAD7-LINEN", trackAmount);
                                    break;
                                case "Cameo":
                                    this.addItem("TR-MAD7-CAM", trackAmount);
                                    break;
                                case "White":
                                    this.addItem("TR-MAD7-WHTE", trackAmount);
                                    break;
                                case "Copper Penny":
                                    this.addItem("TR-MAD7-COPPR", trackAmount);
                                    break;
                                case "Sand":
                                    this.addItem("TR-MAD7-SAND", trackAmount);
                                    break;
                            }
                            break;

                        case "Standard":
                            switch (jellyFishOrderSpecsModel.trackColor) {
                                case "Black":
                                    this.addItem("TR-S7-BLK", trackAmount);
                                    break;
                                case "Musket Brown":
                                    this.addItem("TR-S7-MBRO", trackAmount);
                                    break;
                                case "Royal Brown":
                                    this.addItem("TR-S7-RBRO", trackAmount);
                                    break;
                                case "Dark Bronze":
                                    this.addItem("TR-S7-DRBZ", trackAmount);
                                    break;
                                case "London Brown":
                                    this.addItem("TR-S7-LBRN", trackAmount);
                                    break;
                                case "Charcoal Grey":
                                    this.addItem("TR-S7-CGR", trackAmount);
                                    break;
                                case "Terra Bronze":
                                    this.addItem("TR-S7-TBRO", trackAmount);
                                    break;
                                case "Evergreen":
                                    this.addItem("TR-S7-EVER", trackAmount);
                                    break;
                                case "Russet Red":
                                    this.addItem("TR-S7-RED", trackAmount);
                                    break;
                                case "Harbor Grey":
                                    this.addItem("TR-S7-HGREY", trackAmount);
                                    break;
                                case "Montana Suede":
                                    this.addItem("TR-S7-MSUE", trackAmount);
                                    break;
                                case "Pebblestone Clay":
                                    this.addItem("TR-S7-PCLA", trackAmount);
                                    break;
                                case "Everest":
                                    this.addItem("TR-S7-EVRST", trackAmount);
                                    break;
                                case "Victorian Grey":
                                    this.addItem("TR-S7-VGREY", trackAmount);
                                    break;
                                case "Wicker":
                                    this.addItem("TR-S7-WICK", trackAmount);
                                    break;
                                case "Desert Sand":
                                    this.addItem("TR-S7-DSAN", trackAmount);
                                    break;
                                case "Mountain Blue":
                                    this.addItem("TR-S7-BLU", trackAmount);
                                    break;
                                case "Almond":
                                    this.addItem("TR-S7-ALMD", trackAmount);
                                    break;
                                case "Classic Cream":
                                    this.addItem("TR-S7-CCRE", trackAmount);
                                    break;
                                case "Linen":
                                    this.addItem("TR-S7-LINEN", trackAmount);
                                    break;
                                case "Cameo":
                                    this.addItem("TR-S7-CAM", trackAmount);
                                    break;
                                case "White":
                                    this.addItem("TR-S7-WHTE", trackAmount);
                                    break;
                                case "Copper Penny":
                                    this.addItem("TR-S7-COPPR", trackAmount);
                                    break;
                                case "Sand":
                                    this.addItem("TR-S7-SAND", trackAmount);
                                    break;
                            }
                            break;
                    }

                    //////////////////
                    /*TRACK HOUSING*/
                    //////////////////

                    switch (jellyFishOrderSpecsModel.trackHousing) {
                        case "Fat":
                            switch (jellyFishOrderSpecsModel.trackColor) {
                                case "Black":
                                    this.addItem("TR-F7-BLK", trackAmount);
                                    break;
                                case "Musket Brown":
                                    this.addItem("TR-F7-MBRO", trackAmount);
                                    break;
                                case "Royal Brown":
                                    this.addItem("TR-F7-RBRO", trackAmount);
                                    break;
                                case "Dark Bronze":
                                    this.addItem("TR-F7-DRBZ", trackAmount);
                                    break;
                                case "London Brown":
                                    this.addItem("TR-F7-LBRN", trackAmount);
                                    break;
                                case "Charcoal Grey":
                                    this.addItem("TR-F7-CGR", trackAmount);
                                    break;
                                case "Terra Bronze":
                                    this.addItem("TR-F7-TBRO", trackAmount);
                                    break;
                                case "Evergreen":
                                    this.addItem("TR-F7-EVER", trackAmount);
                                    break;
                                case "Russet Red":
                                    this.addItem("TR-F7-RED", trackAmount);
                                    break;
                                case "Harbor Grey":
                                    this.addItem("TR-F7-HGREY", trackAmount);
                                    break;
                                case "Montana Suede":
                                    this.addItem("TR-F7-MSUE", trackAmount);
                                    break;
                                case "Pebblestone Clay":
                                    this.addItem("TR-F7-PCLA", trackAmount);
                                    break;
                                case "Everest":
                                    this.addItem("TR-F7-EVRST", trackAmount);
                                    break;
                                case "Victorian Grey":
                                    this.addItem("TR-F7-VGREY", trackAmount);
                                    break;
                                case "Wicker":
                                    this.addItem("TR-F7-WICK", trackAmount);
                                    break;
                                case "Desert Sand":
                                    this.addItem("TR-F7-DSAN", trackAmount);
                                    break;
                                case "Mountain Blue":
                                    this.addItem("TR-F7-BLU", trackAmount);
                                    break;
                                case "Almond":
                                    this.addItem("TR-F7-ALMD", trackAmount);
                                    break;
                                case "Classic Cream":
                                    this.addItem("TR-F7-CCRE", trackAmount);
                                    break;
                                case "Linen":
                                    this.addItem("TR-F7-LINEN", trackAmount);
                                    break;
                                case "Cameo":
                                    this.addItem("TR-F7-CAM", trackAmount);
                                    break;
                                case "White":
                                    this.addItem("TR-F7-WHTE", trackAmount);
                                    break;
                                case "Copper Penny":
                                    this.addItem("TR-F7-COPPR", trackAmount);
                                    break;
                                case "Sand":
                                    this.addItem("TR-F7-SAND", trackAmount);
                                    break;
                            }
                            break;

                        case "Z Flashing":
                            switch (jellyFishOrderSpecsModel.trackColor) {
                                case "Black":
                                    this.addItem("TR-Z7-BLK", trackAmount);
                                    break;
                                case "Musket Brown":
                                    this.addItem("TR-Z7-MBRO", trackAmount);
                                    break;
                                case "Royal Brown":
                                    this.addItem("TR-Z7-RBRO", trackAmount);
                                    break;
                                case "Dark Bronze":
                                    this.addItem("TR-Z7-DRBZ", trackAmount);
                                    break;
                                case "London Brown":
                                    this.addItem("TR-Z7-LBRN", trackAmount);
                                    break;
                                case "Charcoal Grey":
                                    this.addItem("TR-Z7-CGR", trackAmount);
                                    break;
                                case "Terra Bronze":
                                    this.addItem("TR-Z7-TBRO", trackAmount);
                                    break;
                                case "Evergreen":
                                    this.addItem("TR-Z7-EVER", trackAmount);
                                    break;
                                case "Russet Red":
                                    this.addItem("TR-Z7-RED", trackAmount);
                                    break;
                                case "Harbor Grey":
                                    this.addItem("TR-Z7-HGREY", trackAmount);
                                    break;
                                case "Montana Suede":
                                    this.addItem("TR-Z7-MSUE", trackAmount);
                                    break;
                                case "Pebblestone Clay":
                                    this.addItem("TR-Z7-PCLA", trackAmount);
                                    break;
                                case "Everest":
                                    this.addItem("TR-Z7-EVRST", trackAmount);
                                    break;
                                case "Victorian Grey":
                                    this.addItem("TR-Z7-VGREY", trackAmount);
                                    break;
                                case "Wicker":
                                    this.addItem("TR-Z7-WICK", trackAmount);
                                    break;
                                case "Desert Sand":
                                    this.addItem("TR-Z7-DSAN", trackAmount);
                                    break;
                                case "Mountain Blue":
                                    this.addItem("TR-Z7-BLU", trackAmount);
                                    break;
                                case "Almond":
                                    this.addItem("TR-Z7-ALMD", trackAmount);
                                    break;
                                case "Classic Cream":
                                    this.addItem("TR-Z7-CCRE", trackAmount);
                                    break;
                                case "Linen":
                                    this.addItem("TR-Z7-LINEN", trackAmount);
                                    break;
                                case "Cameo":
                                    this.addItem("TR-Z7-CAM", trackAmount);
                                    break;
                                case "White":
                                    this.addItem("TR-Z7-WHTE", trackAmount);
                                    break;
                                case "Copper Penny":
                                    this.addItem("TR-Z7-COPPR", trackAmount);
                                    break;
                                case "Sand":
                                    this.addItem("TR-Z7-SAND", trackAmount);
                                    break;
                            }
                            break;
                    }
                }

                ////////////////
                // 10 foot track part numbers
                ////////////////

                if (useTenFoot) {
                    switch (jellyFishOrderSpecsModel.trackType) {
                        case "Canted":
                            switch (jellyFishOrderSpecsModel.trackColor) {
                                case "Black":
                                    this.addItem("TR-C10-BLK", trackAmount);
                                    break;
                                case "Musket Brown":
                                    this.addItem("TR-C10-MBRO", trackAmount);
                                    break;
                                case "Royal Brown":
                                    this.addItem("TR-C10-RBRO", trackAmount);
                                    break;
                                case "Dark Bronze":
                                    this.addItem("TR-C10-DRBZ", trackAmount);
                                    break;
                                case "London Brown":
                                    this.addItem("TR-C10-LBRN", trackAmount);
                                    break;
                                case "Charcoal Grey":
                                    this.addItem("TR-C10-CGR", trackAmount);
                                    break;
                                case "Terra Bronze":
                                    this.addItem("TR-C10-TBRO", trackAmount);
                                    break;
                                case "Evergreen":
                                    this.addItem("TR-C10-EVER", trackAmount);
                                    break;
                                case "Russet Red":
                                    this.addItem("TR-C10-RED", trackAmount);
                                    break;
                                case "Harbor Grey":
                                    this.addItem("TR-C10-HGREY", trackAmount);
                                    break;
                                case "Montana Suede":
                                    this.addItem("TR-C10-MSUE", trackAmount);
                                    break;
                                case "Pebblestone Clay":
                                    this.addItem("TR-C10-PCLA", trackAmount);
                                    break;
                                case "Everest":
                                    this.addItem("TR-C10-EVRST", trackAmount);
                                    break;
                                case "Victorian Grey":
                                    this.addItem("TR-C10-VGREY", trackAmount);
                                    break;
                                case "Wicker":
                                    this.addItem("TR-C10-WICK", trackAmount);
                                    break;
                                case "Desert Sand":
                                    this.addItem("TR-C10-DSAN", trackAmount);
                                    break;
                                case "Mountain Blue":
                                    this.addItem("TR-C10-BLU", trackAmount);
                                    break;
                                case "Almond":
                                    this.addItem("TR-C10-ALMD", trackAmount);
                                    break;
                                case "Classic Cream":
                                    this.addItem("TR-C10-CCRE", trackAmount);
                                    break;
                                case "Linen":
                                    this.addItem("TR-C10-LINEN", trackAmount);
                                    break;
                                case "Cameo":
                                    this.addItem("TR-C10-CAM", trackAmount);
                                    break;
                                case "White":
                                    this.addItem("TR-C10-WHTE", trackAmount);
                                    break;
                                case "Copper Penny":
                                    this.addItem("TR-C10-COPPR", trackAmount);
                                    break;
                                case "Sand":
                                    this.addItem("TR-C10-SAND", trackAmount);
                                    break;
                            }
                            break;

                        case "Madhave":
                            switch (jellyFishOrderSpecsModel.trackColor) {
                                case "Black":
                                    this.addItem("TR-MAD10-BLK", trackAmount);
                                    break;
                                case "Musket Brown":
                                    this.addItem("TR-MAD10-MBRO", trackAmount);
                                    break;
                                case "Royal Brown":
                                    this.addItem("TR-MAD10-RBRO", trackAmount);
                                    break;
                                case "Dark Bronze":
                                    this.addItem("TR-MAD10-DRBZ", trackAmount);
                                    break;
                                case "London Brown":
                                    this.addItem("TR-MAD10-LBRN", trackAmount);
                                    break;
                                case "Charcoal Grey":
                                    this.addItem("TR-MAD10-CGR", trackAmount);
                                    break;
                                case "Terra Bronze":
                                    this.addItem("TR-MAD10-TBRO", trackAmount);
                                    break;
                                case "Evergreen":
                                    this.addItem("TR-MAD10-EVER", trackAmount);
                                    break;
                                case "Russet Red":
                                    this.addItem("TR-MAD10-RED", trackAmount);
                                    break;
                                case "Harbor Grey":
                                    this.addItem("TR-MAD10-HGREY", trackAmount);
                                    break;
                                case "Montana Suede":
                                    this.addItem("TR-MAD10-MSUE", trackAmount);
                                    break;
                                case "Pebblestone Clay":
                                    this.addItem("TR-MAD10-PCLA", trackAmount);
                                    break;
                                case "Everest":
                                    this.addItem("TR-MAD10-EVRST", trackAmount);
                                    break;
                                case "Victorian Grey":
                                    this.addItem("TR-MAD10-VGREY", trackAmount);
                                    break;
                                case "Wicker":
                                    this.addItem("TR-MAD10-WICK", trackAmount);
                                    break;
                                case "Desert Sand":
                                    this.addItem("TR-MAD10-DSAN", trackAmount);
                                    break;
                                case "Mountain Blue":
                                    this.addItem("TR-MAD10-BLU", trackAmount);
                                    break;
                                case "Almond":
                                    this.addItem("TR-MAD10-ALMD", trackAmount);
                                    break;
                                case "Classic Cream":
                                    this.addItem("TR-MAD10-CCRE", trackAmount);
                                    break;
                                case "Linen":
                                    this.addItem("TR-MAD10-LINEN", trackAmount);
                                    break;
                                case "Cameo":
                                    this.addItem("TR-MAD10-CAM", trackAmount);
                                    break;
                                case "White":
                                    this.addItem("TR-MAD10-WHTE", trackAmount);
                                    break;
                                case "Copper Penny":
                                    this.addItem("TR-MAD10-COPPR", trackAmount);
                                    break;
                                case "Sand":
                                    this.addItem("TR-MAD10-SAND", trackAmount);
                                    break;
                            }
                            break;

                        case "Standard":
                            switch (jellyFishOrderSpecsModel.trackColor) {
                                case "Black":
                                    this.addItem("TR-S10-BLK", trackAmount);
                                    break;
                                case "Musket Brown":
                                    this.addItem("TR-S10-MBRO", trackAmount);
                                    break;
                                case "Royal Brown":
                                    this.addItem("TR-S10-RBRO", trackAmount);
                                    break;
                                case "Dark Bronze":
                                    this.addItem("TR-S10-DRBZ", trackAmount);
                                    break;
                                case "London Brown":
                                    this.addItem("TR-S10-LBRN", trackAmount);
                                    break;
                                case "Charcoal Grey":
                                    this.addItem("TR-S10-CGR", trackAmount);
                                    break;
                                case "Terra Bronze":
                                    this.addItem("TR-S10-TBRO", trackAmount);
                                    break;
                                case "Evergreen":
                                    this.addItem("TR-S10-EVER", trackAmount);
                                    break;
                                case "Russet Red":
                                    this.addItem("TR-S10-RED", trackAmount);
                                    break;
                                case "Harbor Grey":
                                    this.addItem("TR-S10-HGREY", trackAmount);
                                    break;
                                case "Montana Suede":
                                    this.addItem("TR-S10-MSUE", trackAmount);
                                    break;
                                case "Pebblestone Clay":
                                    this.addItem("TR-S10-PCLA", trackAmount);
                                    break;
                                case "Everest":
                                    this.addItem("TR-S10-EVRST", trackAmount);
                                    break;
                                case "Victorian Grey":
                                    this.addItem("TR-S10-VGREY", trackAmount);
                                    break;
                                case "Wicker":
                                    this.addItem("TR-S10-WICK", trackAmount);
                                    break;
                                case "Desert Sand":
                                    this.addItem("TR-S10-DSAN", trackAmount);
                                    break;
                                case "Mountain Blue":
                                    this.addItem("TR-S10-BLU", trackAmount);
                                    break;
                                case "Almond":
                                    this.addItem("TR-S10-ALMD", trackAmount);
                                    break;
                                case "Classic Cream":
                                    this.addItem("TR-S10-CCRE", trackAmount);
                                    break;
                                case "Linen":
                                    this.addItem("TR-S10-LINEN", trackAmount);
                                    break;
                                case "Cameo":
                                    this.addItem("TR-S10-CAM", trackAmount);
                                    break;
                                case "White":
                                    this.addItem("TR-S10-WHTE", trackAmount);
                                    break;
                                case "Copper Penny":
                                    this.addItem("TR-S10-COPPR", trackAmount);
                                    break;
                                case "Sand":
                                    this.addItem("TR-S10-SAND", trackAmount);
                                    break;
                            }
                            break;
                    }

                    //////////////////
                    /*TRACK HOUSING*/
                    //////////////////

                    switch (jellyFishOrderSpecsModel.trackHousing) {
                        case "Fat":
                            switch (jellyFishOrderSpecsModel.trackColor) {
                                case "Black":
                                    this.addItem("TR-F10-BLK", trackAmount);
                                    break;
                                case "Musket Brown":
                                    this.addItem("TR-F10-MBRO", trackAmount);
                                    break;
                                case "Royal Brown":
                                    this.addItem("TR-F10-RBRO", trackAmount);
                                    break;
                                case "Dark Bronze":
                                    this.addItem("TR-F10-DRBZ", trackAmount);
                                    break;
                                case "London Brown":
                                    this.addItem("TR-F10-LBRN", trackAmount);
                                    break;
                                case "Charcoal Grey":
                                    this.addItem("TR-F10-CGR", trackAmount);
                                    break;
                                case "Terra Bronze":
                                    this.addItem("TR-F10-TBRO", trackAmount);
                                    break;
                                case "Evergreen":
                                    this.addItem("TR-F10-EVER", trackAmount);
                                    break;
                                case "Russet Red":
                                    this.addItem("TR-F10-RED", trackAmount);
                                    break;
                                case "Harbor Grey":
                                    this.addItem("TR-F10-HGREY", trackAmount);
                                    break;
                                case "Montana Suede":
                                    this.addItem("TR-F10-MSUE", trackAmount);
                                    break;
                                case "Pebblestone Clay":
                                    this.addItem("TR-F10-PCLA", trackAmount);
                                    break;
                                case "Everest":
                                    this.addItem("TR-F10-EVRST", trackAmount);
                                    break;
                                case "Victorian Grey":
                                    this.addItem("TR-F10-VGREY", trackAmount);
                                    break;
                                case "Wicker":
                                    this.addItem("TR-F10-WICK", trackAmount);
                                    break;
                                case "Desert Sand":
                                    this.addItem("TR-F10-DSAN", trackAmount);
                                    break;
                                case "Mountain Blue":
                                    this.addItem("TR-F10-BLU", trackAmount);
                                    break;
                                case "Almond":
                                    this.addItem("TR-F10-ALMD", trackAmount);
                                    break;
                                case "Classic Cream":
                                    this.addItem("TR-F10-CCRE", trackAmount);
                                    break;
                                case "Linen":
                                    this.addItem("TR-F10-LINEN", trackAmount);
                                    break;
                                case "Cameo":
                                    this.addItem("TR-F10-CAM", trackAmount);
                                    break;
                                case "White":
                                    this.addItem("TR-F10-WHTE", trackAmount);
                                    break;
                                case "Copper Penny":
                                    this.addItem("TR-F10-COPPR", trackAmount);
                                    break;
                                case "Sand":
                                    this.addItem("TR-F10-SAND", trackAmount);
                                    break;
                            }
                            break;

                        case "Z Flashing":
                            switch (jellyFishOrderSpecsModel.trackColor) {
                                case "Black":
                                    this.addItem("TR-Z10-BLK", trackAmount);
                                    break;
                                case "Musket Brown":
                                    this.addItem("TR-Z10-MBRO", trackAmount);
                                    break;
                                case "Royal Brown":
                                    this.addItem("TR-Z10-RBRO", trackAmount);
                                    break;
                                case "Dark Bronze":
                                    this.addItem("TR-Z10-DRBZ", trackAmount);
                                    break;
                                case "London Brown":
                                    this.addItem("TR-Z10-LBRN", trackAmount);
                                    break;
                                case "Charcoal Grey":
                                    this.addItem("TR-Z10-CGR", trackAmount);
                                    break;
                                case "Terra Bronze":
                                    this.addItem("TR-Z10-TBRO", trackAmount);
                                    break;
                                case "Evergreen":
                                    this.addItem("TR-Z10-EVER", trackAmount);
                                    break;
                                case "Russet Red":
                                    this.addItem("TR-Z10-RED", trackAmount);
                                    break;
                                case "Harbor Grey":
                                    this.addItem("TR-Z10-HGREY", trackAmount);
                                    break;
                                case "Montana Suede":
                                    this.addItem("TR-Z10-MSUE", trackAmount);
                                    break;
                                case "Pebblestone Clay":
                                    this.addItem("TR-Z10-PCLA", trackAmount);
                                    break;
                                case "Everest":
                                    this.addItem("TR-Z10-EVRST", trackAmount);
                                    break;
                                case "Victorian Grey":
                                    this.addItem("TR-Z10-VGREY", trackAmount);
                                    break;
                                case "Wicker":
                                    this.addItem("TR-Z10-WICK", trackAmount);
                                    break;
                                case "Desert Sand":
                                    this.addItem("TR-Z10-DSAN", trackAmount);
                                    break;
                                case "Mountain Blue":
                                    this.addItem("TR-Z10-BLU", trackAmount);
                                    break;
                                case "Almond":
                                    this.addItem("TR-Z10-ALMD", trackAmount);
                                    break;
                                case "Classic Cream":
                                    this.addItem("TR-Z10-CCRE", trackAmount);
                                    break;
                                case "Linen":
                                    this.addItem("TR-Z10-LINEN", trackAmount);
                                    break;
                                case "Cameo":
                                    this.addItem("TR-Z10-CAM", trackAmount);
                                    break;
                                case "White":
                                    this.addItem("TR-Z10-WHTE", trackAmount);
                                    break;
                                case "Copper Penny":
                                    this.addItem("TR-Z10-COPPR", trackAmount);
                                    break;
                                case "Sand":
                                    this.addItem("TR-Z10-SAND", trackAmount);
                                    break;
                            }
                            break;
                    }
                }

                //////////////////
                /*CLIP TYPE*/
                //////////////////

                switch (jellyFishOrderSpecsModel.clipType) {
                    case "New Clip":
                        switch (jellyFishOrderSpecsModel.trackColor) {
                            case "Black":
                                this.addItem("BR-NC-BLK", clipAmount);
                                break;
                            case "Musket Brown":
                                this.addItem("BR-NC-MBRO", clipAmount);
                                break;
                            case "Royal Brown":
                                this.addItem("BR-NC-RBRO", clipAmount);
                                break;
                            case "Dark Bronze":
                                this.addItem("BR-NC-DRBZ", clipAmount);
                                break;
                            case "London Brown":
                                this.addItem("BR-NC-LBRN", clipAmount);
                                break;
                            case "Charcoal Grey":
                                this.addItem("BR-NC-CGR", clipAmount);
                                break;
                            case "Terra Bronze":
                                this.addItem("BR-NC-TRBO", clipAmount);
                                break;
                            case "Evergreen":
                                this.addItem("BR-NC-EVER", clipAmount);
                                break;
                            case "Russet Red":
                                this.addItem("BR-NC-RED", clipAmount);
                                break;
                            case "Harbor Grey":
                                this.addItem("BR-NC-HGREY", clipAmount);
                                break;
                            case "Montana Suede":
                                this.addItem("BR-NC-MSUE", clipAmount);
                                break;
                            case "Pebblestone Clay":
                                this.addItem("BR-NC-PCLA", clipAmount);
                                break;
                            case "Everest":
                                this.addItem("BR-NC-EVRST", clipAmount);
                                break;
                            case "Victorian Grey":
                                this.addItem("BR-NC-VGREY", clipAmount);
                                break;
                            case "Wicker":
                                this.addItem("BR-NC-WICK", clipAmount);
                                break;
                            case "Desert Sand":
                                this.addItem("BR-NC-DSAN", clipAmount);
                                break;
                            case "Mountain Blue":
                                this.addItem("BR-NC-BLU", clipAmount);
                                break;
                            case "Almond":
                                this.addItem("BR-NC-ALMD", clipAmount);
                                break;
                            case "Classic Cream":
                                this.addItem("BR-NC-CCRE", clipAmount);
                                break;
                            case "Linen":
                                this.addItem("BR-NC-LINEN", clipAmount);
                                break;
                            case "Cameo":
                                this.addItem("BR-NC-CAM", clipAmount);
                                break;
                            case "White":
                                this.addItem("BR-NC-WHTE", clipAmount);
                                break;
                            case "Copper Penny":
                                this.addItem("BR-NC-COPPR", clipAmount);
                                break;
                            case "Sand":
                                this.addItem("BR-NC-SAND", clipAmount);
                                break;
                        }
                        break;
                }

                let totalLightStrandsToAdd = 0;

                if (jellyFishOrderSpecsModel.linearFeet > 0) {
                    totalLightStrandsToAdd = Math.ceil(jellyFishOrderSpecsModel.linearFeet / 37.5);
                }

                // Add total light strands
                if (totalLightStrandsToAdd > 0)
                    this.addItem("L4000", totalLightStrandsToAdd);
            }

            if (!this.settings || this.settings?.includeElectronics == false || forItemsToOrder) {


                //////////////////
                /*CONTROLLER*/
                //////////////////

                switch (jellyFishOrderSpecsModel.controllerType) {
                    case "24v Controller":
                        this.addItem("CON-24V")
                        break;
                    case "48v Controller":
                        this.addItem("CON-48V")
                        break;
                    default:
                        break;
                }

                // we need to add a receiver and a transmitter per jump over 20ft
                // we need to add a receiver per home runs over 50 ft
                let totalReceiversToAdd = 0;
                let totalTransmittersToAdd = 0;

                // Calculate the total number of receivers needed
                if (jellyFishOrderSpecsModel.homeRunsOver50ft > 0) {
                    totalReceiversToAdd += jellyFishOrderSpecsModel.homeRunsOver50ft;
                }

                if (jellyFishOrderSpecsModel.jumpsOver20ft > 0) {
                    totalReceiversToAdd += jellyFishOrderSpecsModel.jumpsOver20ft;
                    totalTransmittersToAdd += jellyFishOrderSpecsModel.jumpsOver20ft;
                }

                // Add the calculated quantities of receivers and transmitters
                if (totalReceiversToAdd > 0) {
                    this.addItem("REC-100", totalReceiversToAdd);
                }

                if (totalTransmittersToAdd > 0) {
                    this.addItem("TRS200", totalTransmittersToAdd);
                }
            }
            


            let systemLineItems = lineItems.filter(li => li.isSystemLineItem);
            systemLineItems = await this.getSystemItemsBySkus(this.systemSkus, systemLineItems, customerId, forceRefresh)

            let returnLineItems = lineItems.filter(li => !li.isSystemLineItem);
            returnLineItems = returnLineItems.concat(systemLineItems);

            returnLineItems = returnLineItems.map(li => {
                li.cost = UtilsService.round(li.cost, 2);
                li.price = UtilsService.round(li.price, 2);
                li.quantity = UtilsService.round(li.quantity, 3);
                return li;
            })
            return returnLineItems;

        }
        finally {
            await this.functionLockService.release("JELLYFISH_ORDER_CALCULATE_SYSTEM_LINE_ITEMS");

        }

    }

    private addItem(sku: string, quantity: number = 1) {
        quantity = quantity || 1;
        this.systemSkus.push(new SystemSkuModel(sku, quantity, true, this.displayOrder++));
    }

    private async getSystemItemsBySkus(itemsToAdd: SystemSkuModel[], existingSystemLineItems: ILineItemModel[], customerId: number, forceRefresh: boolean): Promise<ILineItemModel[]> {
        let items = [];
        if (!itemsToAdd || itemsToAdd.length === 0)
            return items;

        let allItemsFound = true;
        if (forceRefresh === false) {
            allItemsFound = true;
            for (let i = 0; i < itemsToAdd.length; i++) {
                const item = existingSystemLineItems.find(li => li.sku === itemsToAdd[i].sku && li.isSystemLineItem === true);

                if (!item)
                    allItemsFound = false;
            }
        }

        if (allItemsFound === false || forceRefresh === true) {
            const skus = itemsToAdd.map(item => item.sku);

            const params = {
                skus: skus,
                customerId: customerId
            }

            const returnItems = <ILineItemModel[]>(await this.httpService.post("/jellyfish/getsystemitemsbyskus", params));

            // When this comes back, it will be sorted by sku.  
            // We don't want this, so instead sort it by the original order
            items = itemsToAdd.map(itemToAdd => {
                const i = returnItems.find(returnItem => returnItem.sku === itemToAdd.sku);

                if (!i)
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: `Item ${itemToAdd.sku} was not found.`,
                        confirmButtonColor: '#007bff',
                        heightAuto: false
                    });
                else {
                    i.isSystemLineItem = true;
                    i.isParentItem = true;
                    i.displayOrder = itemToAdd.displayOrder;
                    i.sendToManufacturer = true;
                    i.editable = false;
                    i.taxable = true;
                }

                const existingSystemLineItem = existingSystemLineItems.find(x => x.sku === itemToAdd.sku);
                if (existingSystemLineItem) {
                    if (existingSystemLineItem.quantityModified) {
                        i.quantityModified = true;
                        i.quantity = existingSystemLineItem.quantity;
                    }
                    i.sendToManufacturer = existingSystemLineItem.sendToManufacturer;
                }

                return i;
            });

            items = this.assignQuantities(items, itemsToAdd);

        }
        else {
            items = this.assignQuantities(existingSystemLineItems, itemsToAdd);
        }

        return items;
    }

    private assignQuantities(systemItems: ILineItemModel[], itemsToAdd: SystemSkuModel[]) {
        const returnItems = itemsToAdd.map(itemToAdd => {
            const item: ILineItemModel = systemItems.find(i => itemToAdd.sku === i.sku);
            if (!item)
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: `Item ${item.sku} was not found.`,
                    confirmButtonColor: '#007bff',
                    heightAuto: false
                });
            else {
                item.quantity = itemToAdd.quantity;
                item.sendToManufacturer = item.sendToManufacturer;
                return item;
            }
        })

        return returnItems;
    }

}