export interface ICarrierModel {
    carrierId: number;
    carrierName: string;
    active: boolean;
}

export class CarrierModel implements ICarrierModel {
    constructor() {
        this.active = true;
    }
    carrierId: number;
    carrierName: string;
    active: boolean;
}

