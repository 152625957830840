import { ICrewMemberModel } from "@models/crews/crew-member.model";

export interface IResourceModel {
	resourceId: number;
	ownerId: number;
	resourceName: string;
	shortName: string;
	sortOrder: number;
	acuityAccountId?: number;
	acuityReferenceId?: number;
	color: string;
	backgroundColor: string;
	noAutoSchedule: boolean;
	active: boolean;
	crewMembersDisplay: string;
	defaultCrewMembers: ICrewMemberModel[];
	indexOfUser: number;
	locationId: number;	
	locationName: string;
	hasAppointments: boolean;
	optimizing: boolean;
	optimizedLevel1: boolean;
	optimizedLevel2: boolean;
	dailyPreChecklistGenerated: boolean;
	dailyPreChecklistGenerating: boolean;
}

export class ResourceModel implements IResourceModel {
	constructor() {
		this.resourceId = 0;
		this.active = true;
		this.backgroundColor = '#FFFFFF';
		this.color = '#000000';
	}

	resourceId: number;
	ownerId: number;
	resourceName: string;
	shortName: string;
	sortOrder: number;
	acuityAccountId?: number;
	acuityReferenceId?: number;
	color: string;
	backgroundColor: string;
	noAutoSchedule: boolean;
	active: boolean;
	crewMembersDisplay: string;
	defaultCrewMembers: ICrewMemberModel[];
	indexOfUser: number;
	locationId: number;	
	locationName: string;
	hasAppointments: boolean;
	optimizing: boolean;
	optimizedLevel1: boolean;
	optimizedLevel2: boolean;
	dailyPreChecklistGenerated: boolean;
	dailyPreChecklistGenerating: boolean;
}
