import {IEmailMessageTypeModel} from "@models/email-templates/email-message-type.model";

export interface IEmailTemplateModel {
	uuid: string;
	emailMessageTypeId: number;
	emailMessageTypeDescription: string;
	appointmentTypeId?: number;
	appointmentTypeDescription: string;
	locationId?: number;
	locationName: string;
	subject: string;
	body: string;
	sendServiceAgreement?: boolean;
	active: boolean;
	smsMessage: string;
	sendTo: string;
	sendOneDayBefore: boolean;
	sendTwoDaysBefore: boolean;
	sendThreeDaysBefore: boolean;
	sendSevenDaysBefore: boolean;
	sendFourteenDaysBefore: boolean;
	sendThirtyDaysBefore: boolean;
	includeTechPhoto: boolean;
	emailMessageType: IEmailMessageTypeModel;
}

export class EmailTemplateModel implements IEmailTemplateModel {
	uuid: string;
	emailMessageTypeId: number;
	emailMessageTypeDescription: string;
	appointmentTypeId?: number;
	appointmentTypeDescription: string;
	locationId?: number;
	locationName: string;
	subject: string;
	body: string;
	sendServiceAgreement?: boolean;
	active: boolean;
	smsMessage: string;
	sendTo: string;
	sendOneDayBefore: boolean;
	sendTwoDaysBefore: boolean;
	sendThreeDaysBefore: boolean;
	sendSevenDaysBefore: boolean;
	sendFourteenDaysBefore: boolean;
	sendThirtyDaysBefore: boolean;
	includeTechPhoto: boolean;
	emailMessageType: IEmailMessageTypeModel;
}
