import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SlickFileListComponent } from "./slick-file-list.component";
import { SlickFileListItemComponent } from "./slick-file-list-item.component";
import { SlickPhotoGalleryModule } from "../slick-photo-gallery/slick-photo-gallery.module";
import { SlickConfirmDialogModule } from '../slick-confirm-dialog/slick-confirm-dialog.module';

@NgModule({
	imports: [CommonModule, FormsModule, SlickConfirmDialogModule, SlickPhotoGalleryModule],
	declarations: [SlickFileListComponent, SlickFileListItemComponent],
	exports: [CommonModule, FormsModule, SlickFileListComponent]
})
export class SlickFileListModule { }

export { SlickFileListComponent }
