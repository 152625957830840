import { Directive, Input, ElementRef, forwardRef, OnChanges, SimpleChanges, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SlickSleepService } from '../utils/slick-sleep.service';
import { SlickCurrencyMaskOptions } from "./slick-currency-mask-options.model";
import { SlickUtilsService } from '../utils/slick-utils.service';

@Directive({
	selector: '[slick-currency-mask]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SlickCurrencyMaskDirective),
			multi: true,
		}]
})
export class SlickCurrencyMaskDirective implements OnInit, ControlValueAccessor {
	@Input("slick-currency-mask") slickCurrencyMask: SlickCurrencyMaskOptions;
	hasFocus: boolean = false;

	uuid: string = SlickUtilsService.newGuid();

	options: SlickCurrencyMaskOptions = new SlickCurrencyMaskOptions();

	private fnKeyDown = (e) => this.keyDown(e);
	private fnFocus = () => this.focus();
	private fnBlur = () => this.blur();

	constructor(private el: ElementRef) {
		(<HTMLInputElement>this.el.nativeElement).style.textAlign = "right";
		this.hasFocus = false;

		this.el.nativeElement.addEventListener('keydown', this.fnKeyDown);
		this.el.nativeElement.addEventListener('focus', this.fnFocus);
		this.el.nativeElement.addEventListener('blur', this.fnBlur);
	}

	ngOnInit() {
		// If the currency mask doesn't have any options, set default options
		if (!this.slickCurrencyMask) 
			this.slickCurrencyMask = new SlickCurrencyMaskOptions();

		this.options = new SlickCurrencyMaskOptions();
		this.options.prefix = this.slickCurrencyMask.prefix || "$";
		this.options.decimal = this.slickCurrencyMask.decimal || ".";
		this.options.thousands = this.slickCurrencyMask.thousands || ",";
		this.options.precision = (this.slickCurrencyMask.precision === null || this.slickCurrencyMask.precision === undefined) ? 2 : parseInt(this.slickCurrencyMask.precision.toString());
		this.options.allowNegative = (this.slickCurrencyMask.allowNegative === null || this.slickCurrencyMask.allowNegative === undefined) ? true : (this.slickCurrencyMask.allowNegative.toString() === 'true');
		this.options.updateOnBlurOnly = (this.slickCurrencyMask.updateOnBlurOnly === null || this.slickCurrencyMask.updateOnBlurOnly === undefined) ? true : (this.slickCurrencyMask.updateOnBlurOnly.toString() === 'true');
		this.options.defaultToZero = (this.slickCurrencyMask.defaultToZero === null || this.slickCurrencyMask.defaultToZero === undefined) ? true : (this.slickCurrencyMask.defaultToZero.toString() === 'true');
	}

	ngOnDestroy() {
		this.el.nativeElement.removeEventListener('keydown', this.fnKeyDown);
		this.el.nativeElement.removeEventListener('focus', this.fnFocus);
		this.el.nativeElement.removeEventListener('blur', this.fnBlur);
	}

	async keyDown(e: KeyboardEvent) {
		if (e.altKey || e.ctrlKey)
			return;

		let isValid = false;

		if (e.key === this.options.decimal)
			isValid = true;

		if (e.code === 'ArrowLeft' || e.code === 'ArrowRight')
			isValid = true;

		// Backspace
		if (e.keyCode === 8)
			isValid = true;

		// Tab
		if (e.keyCode === 9)
			isValid = true;

		// Del
		if (e.keyCode === 46)
			isValid = true;

		// F1-F12
		if (e.keyCode >= 112 && e.keyCode <= 123)
			isValid = true;

		if (e.key >= '0' && e.key <= '9')
			isValid = true;

		if (e.key === '-' && this.options.allowNegative === true)
			isValid = true;

		if (e.keyCode === 46)
			isValid = true;

		if (!isValid) {
			e.preventDefault();
			return;
		}

		await SlickSleepService.sleep();		

		const usLocaleText = this.convertToUSLocale((<HTMLInputElement>this.el.nativeElement).value);

		const dollarValue = this.parseDollarValue(usLocaleText);
		if (this.options.updateOnBlurOnly === false)
			this.propagateChange(dollarValue);

	}

	async focus() {
		this.el.nativeElement.addEventListener('keydown', this.fnKeyDown);
		this.el.nativeElement.addEventListener('blur', this.fnBlur);

		this.hasFocus = true;
		const usLocaleText = this.convertToUSLocale((<HTMLInputElement>this.el.nativeElement).value);
		this.updateTextbox(usLocaleText);
		(<HTMLInputElement>this.el.nativeElement).select()
	}

	async blur() {
		this.el.nativeElement.removeEventListener('keydown', this.fnKeyDown);
		this.el.nativeElement.removeEventListener('blur', this.fnBlur);

		this.hasFocus = false;
		const usLocaleText = this.convertToUSLocale((<HTMLInputElement>this.el.nativeElement).value);
		this.updateTextbox(usLocaleText);
		const dollarValue = this.parseDollarValue(usLocaleText);
		this.propagateChange(dollarValue);
	}

	propagateChange = (_: number) => {};

	// this is the initial value set to the component
	public writeValue(obj: number | string) {
		if (obj === null || obj === undefined) {
			if (this.options.defaultToZero === true) {
				obj = 0;
				this.propagateChange(0);
			}
			else {
				(<HTMLInputElement>this.el.nativeElement).value = null;
				this.propagateChange(null);
				return;
			}
		}

		// If this is a locale where , is the decimal separator, convert it back to . for our purposes
		const objStr = obj.toString().replace(",", ".");
		const objNum = parseFloat(objStr);
		// toLocaleString will add in the ,'s so make sure to remove them
		const objStrLocale = objNum.toLocaleString("en-US").replace(",", "");
		this.updateTextbox(objStrLocale);
	}

	// registers 'fn' that will be fired when changes are made
	// this is how we emit the changes back to the form
	public registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	// not used, used for touch input
	public registerOnTouched() { }

	private convertToUSLocale(text: string): string {
		while (text.indexOf(" ") >= 0)
			text = text.replace(" ", "");

		if (!text) {
			if (this.options.defaultToZero === true) 
				text = "0.0";			
			else 
				return null;			
		}

		if (text.indexOf(this.options.decimal) === 0)
			text = `0${text}`;

		const textParts = text.split(this.options.decimal).filter(x => x !== "");

		if (textParts.length === 0)
			textParts.push('0');

		if (textParts.length === 1)
			textParts.push('0');

		// Only take the first 2.  The rest are errors
		if (textParts.length > 2)
			textParts.slice(0, 1);

		textParts[0] = textParts[0]
			.replace(this.options.thousands, "")
			.replace(this.options.prefix, "")
			.replace(this.options.decimal, "");

		return `${textParts[0]}.${textParts[1]}`;
	}

	private parseDollarValue(text: string): number {
		if (!text) 
			return null;
		
		while (text.indexOf(" ") >= 0)
			text = text.replace(" ", "");

		// Split the text into the dollar's part and the cents part
		const textParts = text.split(".").filter(x => x !== "");
		// Make sure we have a dollars and cents
		if (textParts.length === 0)
			textParts.push('0');

		if (textParts.length === 1)
			textParts.push('0');

		// Only take the first 2.  The rest are not allowed
		if (textParts.length > 2)
			textParts.slice(0, 1);

		textParts[0] = textParts[0]
			.replace(this.options.prefix, "")
			.replace(",", "");

		try {
			return parseFloat(`${textParts[0]}.${textParts[1]}`);
		}
		catch {
			return 0;
		}
	}

	private updateTextbox(strVal: string) {
		if (!strVal) {
			(<HTMLInputElement>this.el.nativeElement).value = null;
			return;
		}

		if (this.options.allowNegative === false)
			strVal = strVal.replace("-", "");

		// If there is a - sign, remove it temporarily
		const negativePart = (strVal.indexOf("-") === 0) ? "-" : "";
		strVal = strVal.replace("-", "");

		const currencyParts = strVal.split(".").filter(x => x !== "");
		// make sure there is a dollar amount
		if (currencyParts.length === 0) 
			currencyParts.push("0");

		// make sure there is a cents amount
		if (currencyParts.length === 1)
			currencyParts.push("0");

		// Only take the first 2.  The rest are errors
		if (currencyParts.length > 2)
			currencyParts.slice(0, 1);

		let dollarPart = <string>currencyParts[0];
		dollarPart = dollarPart.replace(',', '').replace('.', '');
		let centsPart = currencyParts[1];
		if (centsPart.length > this.options.precision)
			centsPart = centsPart.substr(0, this.options.precision);

		while (centsPart.length < this.options.precision)
			centsPart = centsPart + "0";

		// reverse the dollar part so we can put commas every 3 chars
		if (!this.hasFocus && dollarPart.length > 3) {
			for (let i = dollarPart.length - 3; i > 0; i -= 3)
				dollarPart = [dollarPart.slice(0, i), this.options.thousands, dollarPart.slice(i)].join('');
		}

		let textboxVal = `${negativePart}${dollarPart}${this.options.decimal}${centsPart}`;
		if (!this.hasFocus)
			textboxVal = `${this.options.prefix}${textboxVal}`;

		(<HTMLInputElement>this.el.nativeElement).value = textboxVal;
	}
}
