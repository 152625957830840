import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, ElementRef, AfterViewInit } from "@angular/core";
import { SlickSleepService } from "../utils/slick-sleep.service";
import { SlickUtilsService } from "../utils/slick-utils.service";
import { SlickTabHeaderComponent } from "./slick-tab-header.component";
import { ISlickTabModel } from "./slick-tab.model";
import { SlickTabsComponent } from "./slick-tabs.component";

@Component({
	selector: 'slick-tab',
	templateUrl: 'slick-tab.component.html'
})
export class SlickTabComponent implements OnChanges, AfterViewInit {
	@Input() header: string;
	@Input() icon: string;
	@Input() key: string;

	@ViewChild("tabHeaderRef") tabHeaderRef: SlickTabHeaderComponent;
	@ViewChild("tabBodyRef") tabBodyRef: ElementRef;

	uuid: string;
	showHeaderFromInput: boolean = false;
	selected: boolean = false;
	parentComponent: SlickTabsComponent;

	constructor(private el: ElementRef) {
		this.uuid = SlickUtilsService.newGuid();		
	}

	async ngAfterViewInit() {
		// Sleep 1 cycle to give this.parentComponent a chance to populate
		await SlickSleepService.sleep();

		// Remove this from the inline version and put it on the body so that it doesn't hide behind the bottom
		// of a div when it expands beyond the bottom.
		let displayBody = document.getElementById("slick-tab-display-body_" + this.parentComponent.uuid);
		if (displayBody)
			displayBody.appendChild(this.tabBodyRef.nativeElement);
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (changes.header) {
			this.showHeaderFromInput = true;
			await SlickSleepService.sleep()

			if (this.tabHeaderRef) 
				this.tabHeaderRef.setHeader(changes.header.currentValue);			
		}

		if (changes.icon) {
			await SlickSleepService.sleep();

			if (this.tabHeaderRef)
				this.tabHeaderRef.setIcon(changes.icon.currentValue);
		}
	}

	async onTabClicked() {
		// If this is already selected, don't run the onTabSelected again.
		// This is causing double calls to the event
		if (this.selected)
			return;

		this.parentComponent.onTabSelected(this.uuid);
		this.selected = true;
	}
}
 