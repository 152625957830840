import { ITextChatAttendantModel } from "./text-chat-attendant.model";

export interface ITextChatMessageModel {
    textChatMessageId: number;
    uuid: string;
    textChatConversationId: number;
    senderId?: number;
    isFromCustomer: boolean;
    isSystemMessage: boolean;
    isNote: boolean;
    sentDate: Date;
    body: string;
    imageUrl: string;
    errorMessage: string;

    sender: ITextChatAttendantModel;
}

export class TextChatMessageModel implements ITextChatMessageModel {
    textChatMessageId: number;
    uuid: string;
    textChatConversationId: number;
    senderId?: number;
    isFromCustomer: boolean;
    isSystemMessage: boolean;
    isNote: boolean;
    sentDate: Date;
    body: string;
    imageUrl: string;
    errorMessage: string;

    sender: ITextChatAttendantModel;
}