import { IChecklistQuestionModel } from "./checklist-question.model";
import { IAppointmentTypeSmartlistQuestionModel, AppointmentTypeSmartlistQuestionModel } from "./appointment-type-smartlist-question.model";
import { ISmartlistQuestionModel } from "./smartlist-question.model";

export interface IAppointmentTypeModel { 
	appointmentTypeId: number;
	appointmentStatusOnComplete?: number;
	description: string;
	defaultMinutes: number;
	defaultDescription: string;
	defaultNotes: string;
	showOnSmartlist: boolean;
	daysBeforeShowOnSmartlist: number;
	showOnCustomerSchedule: boolean;
	sendReminders: boolean;
	active: boolean;

	checklistQuestions: IChecklistQuestionModel[];
	appointmentTypeSmartlistQuestions: IAppointmentTypeSmartlistQuestionModel[]; 
}
 
export class AppointmentTypeModel implements IAppointmentTypeModel {
	constructor(smartlistQuestions: ISmartlistQuestionModel[]) {
		this.appointmentTypeId = 0;
		this.sendReminders = true;
		this.active = true;
		this.checklistQuestions = [];
		this.appointmentTypeSmartlistQuestions = smartlistQuestions.map(x => {
			const smartlistQuestion = new AppointmentTypeSmartlistQuestionModel();
			smartlistQuestion.appointmentTypeId = 0;
			smartlistQuestion.smartlistQuestionId = x.smartlistQuestionId;
			smartlistQuestion.isChecked = false;
			smartlistQuestion.question = x.question;
			return smartlistQuestion;
		})
	}

	appointmentTypeId: number;
	appointmentStatusOnComplete?: number;
	description: string;
	defaultMinutes: number;
	defaultDescription: string;
	defaultNotes: string;
	showOnSmartlist: boolean;
	daysBeforeShowOnSmartlist: number;
	showOnCustomerSchedule: boolean;
	sendReminders: boolean;
	active: boolean;

	checklistQuestions: IChecklistQuestionModel[];
	appointmentTypeSmartlistQuestions: IAppointmentTypeSmartlistQuestionModel[]; 
}
