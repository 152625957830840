import { Injectable } from '@angular/core';
import { IPurchaseOrderModel } from '@models';
import { HttpService } from './utils/http.service';

@Injectable()
export class ReceivingService {
    constructor(private httpService: HttpService) {
    }

	async receive(purchaseOrderModel: IPurchaseOrderModel, inventoryWarehouseId: number): Promise<IPurchaseOrderModel> {
		return this.httpService.post(`/receiving/receive?inventoryWarehouseId=${inventoryWarehouseId}`, purchaseOrderModel);
	}
}
