import { Directive, ElementRef, OnDestroy, OnInit, EventEmitter, Output } from "@angular/core";
import { SlickUtilsService } from '../utils/slick-utils.service';

@Directive({
	selector: '[slick-drop-list]'
})
export class SlickDropListDirective implements OnInit, OnDestroy {
	@Output("onSlickDropListReorder") onSlickDropListReorder = new EventEmitter<number[]>();
	@Output("onSlickDropListEnter") onSlickDropListEnter = new EventEmitter<any>();
	@Output("onSlickDropListLeave") onSlickDropListLeave = new EventEmitter<number>();

	el: HTMLElement;
	uuid: string = SlickUtilsService.newGuid();
	fnMouseDown = (e) => this.mouseDown(e);
	fnMouseMove = (e) => this.mouseMove(e);
	fnMouseUp = () => this.mouseUp();

	isAdding: boolean;
	isAddingMouseEvent: MouseEvent;
	isMoving: boolean;
	placeholderEl: HTMLElement;
	placeholderHeight: number;
	itemHeights: number[];
	cursorOffset: number;
	originalIdx: number;
	currentIdx: number;
	
	constructor(el: ElementRef) {
		this.el = (<HTMLElement>el.nativeElement);
		this.el.setAttribute("drop-list-uuid", this.uuid);
	}

	ngOnInit() {
		this.el.style.position = "relative";
		document.addEventListener("mousedown", this.fnMouseDown, true);
	}

	ngOnDestroy() {
		document.removeEventListener("mousedown", this.fnMouseDown, true);
		document.removeEventListener("mousemove", this.fnMouseMove, true);
		document.removeEventListener("mouseup", this.fnMouseUp, true);
	}

	async mouseDown(e: MouseEvent, target: HTMLElement = null) {
		this.isAdding = false;
		this.isMoving = false;

		if (e.button !== 0 || !e || !e.target)
			return;

		document.removeEventListener("mouseup", this.fnMouseUp, true);
		document.removeEventListener("mousemove", this.fnMouseMove, true);
		document.addEventListener("mouseup", this.fnMouseUp, true);
		document.addEventListener("mousemove", this.fnMouseMove, true);

		if (!target)
			target = <HTMLElement>e.target;

		const parent = SlickUtilsService.findParentByAttr(target, 'drop-list-uuid', this.uuid);
		if (!parent)
			return;

		this.isMoving = true;

		const grabEls = this.el.querySelectorAll(".slick-drop-list-grab");
		if (grabEls.length > 0 && !SlickUtilsService.findParent(target, "slick-drop-list-grab"))
			return true;

		const dropListItem = SlickUtilsService.findParent(target, "slick-drop-list-item");
		if (!dropListItem) {
			console.error("Can't find item element");
			return;
		}

		// Find which element the mouse is over
		this.currentIdx = this.findElementIdx(e.y, false);
		this.originalIdx = this.currentIdx;

		this.startMove(dropListItem);
	}

	mouseMove(e: MouseEvent) {
		if (this.isAdding)
			return;

		const parent = SlickUtilsService.findParentByAttr(<HTMLElement>e.target, 'drop-list-uuid', this.uuid);
		if (!parent) {
			// If this isn't the parent, but we we're moving, that means we just left this parent
			// Send a message which element was dragged out and reset everything else
			if (this.isMoving && this.onSlickDropListLeave.observers.length > 0) {
				this.isMoving = false;
				const allItems = this.el.querySelectorAll<HTMLElement>(".slick-drop-list-item");
				allItems.forEach(item => {
					item.classList.remove("slick-drop-list-item-animation");
					item.style.transform = "";
				});

				this.onSlickDropListLeave.emit(this.originalIdx);
			}

			return;
		}

		if (!this.isMoving && this.onSlickDropListEnter.observers.length === 0)
			return;

		// If the mouse was clicked outside of this droplist, but it has now moved inside this droplist, tell the parent where
		// in the droplist it has moved.
		// Since there is no way for a directive to have functions called via ViewChild, just assume that the page 
		// has added the appropriate element at the appropriate place
		if (!this.isMoving && this.onSlickDropListEnter) {
			this.isAdding = true;

			// Find which element the mouse is over
			const idx = this.findElementIdx(e.y, false);
			this.isAddingMouseEvent = e;
			// The caller needs to call the ready function after adding the new record to the array
			this.onSlickDropListEnter.emit({ idx: idx, ready: this.newItemReady.bind(this) });

			return;
		}
		
		// Find which element the mouse is over
		const dropListItems = this.el.querySelectorAll<HTMLElement>(".slick-drop-list-item")

		for (let i = 0; i < dropListItems.length; i++) {
			const dropListItem = dropListItems[i];
			const boundingRect = dropListItem.getBoundingClientRect();

			const topHalfTop = boundingRect.top;
			const topHalfBottom = topHalfTop + (boundingRect.height / 2);
			const bottomHalfTop = topHalfBottom + 1;
			const bottomHalfBottom = boundingRect.bottom;

			if (this.currentIdx !== i && e.y >= topHalfTop && e.y <= topHalfBottom) {
				this.currentIdx = i;
				this.redraw();
				break;
			}

			if (this.currentIdx !== i + 1 && e.y >= bottomHalfTop && e.y <= bottomHalfBottom) {
				this.currentIdx = i + 1;
				this.redraw();
				break;
			}			
		}
	}

	mouseUp() {
		document.removeEventListener("mouseup", this.fnMouseUp, true);
		document.removeEventListener("mousemove", this.fnMouseMove, true);

		if (!this.isMoving)
			return;

		this.isMoving = false;

		// Remove the placeholder
		this.placeholderEl.classList.remove("slick-drop-list-placeholder");
		this.placeholderEl.classList.add("slick-drop-list-item");
		this.placeholderEl.style.boxShadow = "";
		this.placeholderEl.style.transform = "";
		this.el.removeChild(this.placeholderEl);

		// Add the placeholder back
		const allItems = this.el.querySelectorAll<HTMLElement>(".slick-drop-list-item");
		if (this.currentIdx === allItems.length)
			this.el.appendChild(this.placeholderEl);
		else
			this.el.insertBefore(this.placeholderEl, allItems[this.currentIdx]);

		this.placeholderEl = null;

		allItems.forEach(item => {
			item.classList.remove("slick-drop-list-item-animation");
			item.style.transform = "";
		});

		if (this.onSlickDropListReorder)
			this.onSlickDropListReorder.emit([this.originalIdx, this.currentIdx]);

		this.originalIdx = null;
		this.currentIdx = null;
	}

	private redraw() {
		const allChildren = this.el.querySelectorAll<HTMLElement>(".slick-drop-list-item");
		let placeholderOffset = 0;

		for (let i = 0; i < allChildren.length; i++) {
			const item = allChildren[i];

			if (i < this.currentIdx) {
				item.style.transform = `translate3d(0px, 0px, 0px)`;
			}
			else {
				item.style.transform = `translate3d(0px, ${this.placeholderHeight}px, 0px)`;
				placeholderOffset += this.itemHeights[i];
			}
		}

		this.placeholderEl.style.transform = `translate3d(0px, -${placeholderOffset}px, 0px)`;
	}

	newItemReady(idx: number) {
		setTimeout(() => {
			this.currentIdx = idx;
			this.originalIdx = idx;

			const dropListItem = this.el.querySelectorAll<HTMLElement>(".slick-drop-list-item").item(idx);
			this.mouseDown(this.isAddingMouseEvent, dropListItem);
		});
	}

	private findElementIdx(mouseY, useTopAndBottom: boolean): number {
		const dropListItems = this.el.querySelectorAll<HTMLElement>(".slick-drop-list-item")

		for (let i = 0; i < dropListItems.length; i++) {
			const dropListItem = dropListItems[i];
			const boundingRect = dropListItem.getBoundingClientRect();

			if (useTopAndBottom) {
				const topHalfTop = boundingRect.top;
				const topHalfBottom = topHalfTop + (boundingRect.height / 2);
				const bottomHalfTop = topHalfBottom + 1;
				const bottomHalfBottom = boundingRect.bottom;

				if (mouseY >= topHalfTop && mouseY <= topHalfBottom)
					return i;

				if (mouseY >= bottomHalfTop && mouseY <= bottomHalfBottom)
					return i + 1;
			}
			else {
				if (mouseY >= boundingRect.top && mouseY <= boundingRect.bottom)
					return i;
			}
		}
	}

	private startMove(dropListItem: HTMLElement) {
		this.el.style.userSelect = "none";

		this.placeholderEl = <HTMLElement>dropListItem;
		this.placeholderEl.classList.add("slick-drop-list-placeholder");
		this.placeholderEl.classList.remove("slick-drop-list-item");
		this.placeholderEl.style.boxShadow = "4px 12px 6px rgba(0,0,0,.24)"

		this.placeholderHeight = this.placeholderEl.getBoundingClientRect().height;

		this.el.removeChild(this.placeholderEl);
		this.el.appendChild(this.placeholderEl);

		this.itemHeights = [];
		this.el.querySelectorAll<HTMLElement>(".slick-drop-list-item").forEach(item => this.itemHeights.push(item.getBoundingClientRect().height));

		this.redraw();

		// Don't start the animation until after the first click because it looks weird
		setTimeout(() => {
			const allChildren = this.el.querySelectorAll<HTMLElement>(".slick-drop-list-item");
			allChildren.forEach(item => item.classList.add("slick-drop-list-item-animation"));
		});
	}
}