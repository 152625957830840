import { Injectable } from "@angular/core";
import { DropdownModel, IDropdownModel } from "@models";
import { HttpService } from "./utils/http.service";

@Injectable()
export class AcuityService {
	constructor(private httpService: HttpService) { }

	async getAllAccountsForDropDown(): Promise<IDropdownModel[]> {
		const acuityAccounts = await this.httpService.get("/acuity/getAllAccountsForDropDown");

		if (!acuityAccounts)
			return [];

		return acuityAccounts;
	}

	async getAllCalendarsForDropDown(): Promise<any[]> {
		const acuityCalendars = await this.httpService.get("/acuity/getAllCalendarsForDropDown");

		if (!acuityCalendars)
			return [];

		return acuityCalendars.map(c => new DropdownModel(parseInt(c.id.toString()), c.text));
	}

	async getAllAppointmentTypesForDropDown(acuityAccountId: number): Promise<IDropdownModel[]> {
		const acuityAppointmentTypes = await this.httpService.get("/acuity/getAllAppointmentTypesForDropDown", { acuityAccountId: acuityAccountId });

		if (!acuityAppointmentTypes)
			return [];

		return acuityAppointmentTypes.map(c => new DropdownModel(parseInt(c.id.toString()), c.text));
	}

	async getAppointmentTypeReferenceId(acuityAccountId, appointmentTypeId): Promise<number> {
		const params = {
			acuityAccountId: acuityAccountId,
			appointmentTypeId: appointmentTypeId
		}

		return await this.httpService.get("/acuity/getAppointmentTypeReferenceId", params);
	}

	async updateAppointmentTypeReferenceId(acuityAccountId, appointmentTypeId, referenceId): Promise<void> {
		return await this.httpService.put(`/acuity/updateAppointmentTypeReferenceId?acuityAccountId=${acuityAccountId}&appointmentTypeId=${appointmentTypeId}`, (referenceId || 0));
	}
}