import { Injectable, OnDestroy } from "@angular/core";
import { ChatGroupAttendantModel, ChatGroupModel, IChatGroupModel, IChatMessageModel, IDocumentModel, IUserInfoModel } from "@models";
import { Subject } from "rxjs";
import { AuthService } from "@services/utils/auth.service";
import { GlobalsService } from "@services/utils/globals.service";
import { ChatService, UtilsService, LookupService, HttpService } from "@services";
import { sortBy } from "sort-by-typescript";

@Injectable()
export class ChatStore {
	currentUser: IUserInfoModel;
	isDialogClosed: boolean;
	

	//private _showDeletedGroups: boolean = ((localStorage.getItem("SHOW_DELETED_CHAT_GROUPS") || 'false') === 'true');
	private static _chatType: string;
	private static _chatGroups: IChatGroupModel[];
	private  _selectedChatGroup: IChatGroupModel;
	private static _unreadMessageCount: number;
	private static _chatMuted: boolean;
	private static audio;
	private static _lastMessageId: number = -1;
	

	constructor(private authService: AuthService,
		private chatService: ChatService,
		private httpService: HttpService,
		private lookupService: LookupService) {
	}
	
	async init() {
		this.currentUser = GlobalsService.userInfo;

		if (this.authService.isAuthenticated === true) {
			ChatStore._chatType = this.currentUser.layoutSettings.chatLayout;
			// Pre-load all chat groups
			let chatGroups = await this.chatService.getConversations();
			chatGroups = chatGroups.map(g => {
				g.attendants = g.attendants.filter(x => x.userId !== GlobalsService.userInfo.userId);
				g.isExpanded = false;
				return g;
			});
			ChatStore._chatGroups = chatGroups;
			

			this.updateUnreadConversationCount();
		}
	}

	//*****************************************************************************
	//* chatType
	//*****************************************************************************
	private chatTypeSubject: Subject<string> = new Subject<string>();
	get chatType(): string {
		return ChatStore._chatType;
	}

	set chatType(chatType: string) {
		ChatStore._chatType = chatType;
		this.chatTypeSubject.next(chatType);
	}

	get chatTypeStore(): Subject<string> {
		return this.chatTypeSubject;
	}

	//*****************************************************************************
	//* newMessages
	//*****************************************************************************
	 onNewMessageSubject: Subject<IChatMessageModel> = new Subject<IChatMessageModel>();

	onNewMessage(senderId: number, groupId: number, message: IChatMessageModel) {
		const chatGroup = ChatStore._chatGroups.find(gr => gr.id === groupId);
		if (chatGroup.isMuted)
			this.chatMuted = true;
		else
			this.chatMuted = false;
		chatGroup.messages.push(message);
		chatGroup.lastMessage = message;
		this.updateUnreadConversationCount();
		this.unreadMessageCountSubject.next(ChatStore._unreadMessageCount);

		ChatStore._chatGroups = ChatStore._chatGroups.filter(x => x.uuid !== chatGroup.uuid);
		ChatStore._chatGroups.unshift(chatGroup);
		this.chatGroupUpdatedSubject.next(chatGroup);

		this.onNewMessageSubject.next(message);
	}

	resendMessage(message: IChatMessageModel) {
		this.onNewMessageSubject.next(message);
	}

	get onNewMessageStore(): Subject<IChatMessageModel> {
		return this.onNewMessageSubject;
	}

	//*****************************************************************************
	//* chatRefresh
	//*****************************************************************************

	private refreshChatListSubject: Subject<void> = new Subject<void>();

	refreshChatList() {
		this.refreshChatListSubject.next();
	}

	async refreshChatListAfterEvent() {
		let chatGroups = await this.chatService.getConversations();
		chatGroups = chatGroups.map(g => {
			g.attendants = g.attendants.filter(x => x.userId !== GlobalsService.userInfo.userId);
			g.isExpanded = false;
			return g;
		});
		ChatStore._chatGroups = chatGroups;
		this.refreshChatListSubject.next();
	}

	get refreshChatListStore(): Subject<void> {
		return this.refreshChatListSubject;
	}


	//*****************************************************************************
	//* chatGroups
	//*****************************************************************************
	private chatGroupCreatedSubject: Subject<IChatGroupModel> = new Subject<IChatGroupModel>();
	private chatGroupUpdatedSubject: Subject<IChatGroupModel> = new Subject<IChatGroupModel>();
	

	get chatGroups(): IChatGroupModel[] {
		return ChatStore._chatGroups;
	}

	addPendingMessage(message: IChatMessageModel) {
		// Don't process this twice
		if (ChatStore._lastMessageId === message.id)
			return;
		ChatStore._lastMessageId = message.id;
		const pendingChatGroup = ChatStore._chatGroups.find(x => x.id === message.chatGroupId);
		if (pendingChatGroup) {
			pendingChatGroup.messages.push(message);
			pendingChatGroup.unreadMessages++;
			this.updateUnreadConversationCount();
			this.unreadMessageCountSubject.next(ChatStore._unreadMessageCount);

			ChatStore._chatGroups = ChatStore._chatGroups.filter(x => x.id !== pendingChatGroup.id);
			ChatStore._chatGroups.unshift(pendingChatGroup);

			this.playNewMessageSound(message);
		}
	}

	onChatGroupCreated(chatGroup: IChatGroupModel) {
		if (chatGroup.groupName)
			chatGroup.attendantsString = chatGroup.groupName;
		else {
			if (chatGroup.attendants.find(x => x.isAllUsers)) {
				chatGroup.attendantsString = "All Users";
			}
			else {
				chatGroup.attendantsString = this.chatService.getAttendantsString(chatGroup);
			}
		}


		const chatGroupIdx = ChatStore._chatGroups.findIndex(x => x.uuid === chatGroup.uuid);

		if (chatGroupIdx < 0) {
			ChatStore._chatGroups.unshift(chatGroup);
			this.chatGroupCreatedSubject.next(chatGroup);
		}
		else {
			chatGroup.unreadMessages = 0;
			ChatStore._chatGroups[chatGroupIdx] = chatGroup;
			this.chatGroupUpdatedSubject.next(chatGroup);
		}
	}

	get chatGroupCreatedStore(): Subject<IChatGroupModel> {
		return this.chatGroupCreatedSubject;
	}

	onChatGroupUpdated(chatGroup: ChatGroupModel) {
		if (chatGroup.groupName)
			chatGroup.attendantsString = chatGroup.groupName;
		else {
			if (chatGroup.attendants.find(x => x.isAllUsers)) {
				chatGroup.attendantsString = "All Users";
			}
			else {
				chatGroup.attendantsString = this.chatService.getAttendantsString(chatGroup);
			}
		}
		const chatGroupIdx = ChatStore._chatGroups.findIndex(x => x.uuid === chatGroup.uuid);

		if (chatGroupIdx < 0)
			return;

		ChatStore._chatGroups[chatGroupIdx] = chatGroup;
		
		this.chatGroupUpdatedSubject.next(chatGroup);
	}

	get chatGroupUpdatedStore(): Subject<IChatGroupModel> {
		return this.chatGroupUpdatedSubject;
	}

	setSelectedChatGroup(chatGroup: IChatGroupModel) {
		this._selectedChatGroup = chatGroup;
    }



	//*****************************************************************************
	//* unreadMessageCount
	//*****************************************************************************
	unreadMessageCountSubject: Subject<number> = new Subject<number>();

	get unreadMessageCount(): number {
		return (ChatStore._unreadMessageCount === null || ChatStore._unreadMessageCount === undefined) ? -1 : ChatStore._unreadMessageCount;
	}

	set unreadMessageCount(unreadMessageCount: number) {
		ChatStore._unreadMessageCount = unreadMessageCount;
		this.unreadMessageCountSubject.next(unreadMessageCount);
	}

	get unreadMessageCountStore(): Subject<number> {
		return this.unreadMessageCountSubject;
	}

	updateUnreadConversationCount() {
		let total: number = 0;
		ChatStore._chatGroups.forEach(x => {
			total += (x.unreadMessages || 0);
		});
		ChatStore._unreadMessageCount = total;
		this.unreadMessageCountSubject.next(total);
	}

	async setAllMessagesAsRead(groupId: number): Promise<void> {
		await this.chatService.flagAsRead(groupId);
		const chatGroup = ChatStore._chatGroups.find(x => x.id === groupId);
		if (chatGroup)
			chatGroup.unreadMessages = 0;

		this.updateUnreadConversationCount();
		this.unreadMessageCountSubject.next(ChatStore._unreadMessageCount);
	}

	async setSelectedChatGroupMessagesToRead() {
		if (this._selectedChatGroup) {
			this.setAllMessagesAsRead(this._selectedChatGroup.id);
			await this.chatService.updateChatGroupUserLastRead(this.currentUser.userId, this._selectedChatGroup.id);
			
		}
	}

	//*****************************************************************************
	//* chatMuted
	//*****************************************************************************
	get chatMuted(): boolean {
		return JSON.parse(localStorage.getItem('CHAT_MUTED'));
	}

	set chatMuted(chatMuted: boolean) {
		localStorage.setItem('CHAT_MUTED', chatMuted.toString());
	}

	toggleChatMuted(chatGroup: IChatGroupModel) {
		this.chatService.toggleMute(chatGroup.id);
		chatGroup.isMuted = !chatGroup.isMuted;
	}

	playNewMessageSound(message: IChatMessageModel) {
		if (!this.chatMuted && this.currentUser.userId !== message.senderId && !GlobalsService.isAppMobile) {

			if (!ChatStore.audio) {
				ChatStore.audio = new Audio();
				ChatStore.audio.src = "/assets/audio/chat.mp3";
				ChatStore.audio.load();
			}

			ChatStore.audio.play();
			(<HTMLAudioElement>document.getElementById("chatDing")).play();
		}
	}

	async sendAttachments(files: IDocumentModel[]): Promise<IChatMessageModel[]> {
		try {
			const formData = new FormData();
			files.forEach(file => {
				if (file.base64Image && file.base64Image.length > 0) {
					const fileCopy: IDocumentModel = UtilsService.clone(file);
					const fileName = file.name;
					delete fileCopy.file;
					delete fileCopy.thumbnailBase64Image;
					const fileJSON = JSON.stringify(fileCopy);
					formData.append(fileName, fileJSON);
				}
			});
			return await this.httpService.postMultipart(`/chat/sendAttachments?chatGroupId=${this._selectedChatGroup.id}`, formData);
		} catch (error) {
			console.error(error);
		}
	}


		//*****************************************************************************
	//* showDeletedGroups
	//*****************************************************************************
	//toggleShowDeletedGroupsSubject: Subject<void> = new Subject<void>();


 //   get showDeletedGroups(): boolean {
 //       return ((localStorage.getItem("SHOW_DELETED_CHAT_GROUPS") || 'false') === 'true');
	//}

	//set showDeletedGroups(showDeleted: boolean) {
	//	localStorage.setItem('SHOW_DELETED_CHAT_GROUPS', showDeleted.toString());
	//}

	//toggleShowDeletedGroups() {
	//	const prevVal: boolean = JSON.parse(localStorage.getItem('SHOW_DELETED_CHAT_GROUPS'));
	//	localStorage.setItem('SHOW_DELETED_CHAT_GROUPS', (!prevVal).toString());
	//	this.toggleShowDeletedGroupsSubject.next();

	//}

	//get toggleShowDeletedGroupsStore(): Subject<void> {
	//	return this.toggleShowDeletedGroupsSubject;
 //   }

}
