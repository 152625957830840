<div *ngIf="isMenuExpanded" class="mt-2 ms-3">
    <div style="min-width: 180px" *ngIf="logoWhite">
        <img src="https://app.skedit.com/content/images/skedit-logo-white.png" style="max-width: 150px; max-height: 150px;" />
    </div>
    <div style="min-width: 180px" *ngIf="!logoWhite">
        <img src="https://app.skedit.com/content/images/skedit-logo.png" style="max-width: 200px; max-height: 200px;" />
    </div>
</div>
<div *ngIf="!isMenuExpanded" style="width:55px">
    <div class="mt-3 ms-3" *ngIf="!logoWhite">
        <img src="https://app.skedit.com/content/images/skedit-icon-black.png" style="max-width: 30px; max-height: 30px;" />
    </div>
    <div class="mt-3 ms-3" *ngIf="logoWhite">
        <img src="https://app.skedit.com/content/images/skedit-icon-gray.png" style="max-width: 30px; max-height: 30px;" />
    </div>
</div>

<div class="sidenav text-light" [ngClass]="{ 'is-open' : isMenuExpanded }" style="background-color: #10253a;">

    <div class="caret">
        <button type="button" (click)="toggleExpanded()"><i class="bi bi-caret-right-fill fa-sm text-light"></i></button>
    </div>

    <button *ngIf="isLathamOrdersOnly && !skeditPay" [routerLink]="['/accessDenied/overview']" class="bg-transparent border-0"><div class="logo"></div></button>
    <button *ngIf="skeditPay" [routerLink]="['/skeditPayTransactionLog']" class="bg-transparent border-0"><div class="skedit-pay-logo"></div></button>


    <!-- MENU ITEMS -->
	<!-- SKEDIT PAY  -->
    <ul class="mainmenu">
        <div *ngIf="skeditPay">
        <li class="item-accounting">
            <a style="border-radius:10px" href="/skeditPayTransactionLog"
               [routerLink]="['/skeditPayTransactionLog']"
               routerLinkActive="bg-primary text-white">
                <i class="bi bi-currency-dollar"></i>Transactions
            </a>
        </li>
        <li *ngIf="isSkeditPayAdmin" class="item-accounting">
            <a style="border-radius:10px" href="/skeditPayRecurring"
               [routerLink]="['/skeditPayRecurring']"
               routerLinkActive="bg-primary text-white">
                <i class="bi bi-arrow-repeat"></i>Recurring
            </a>
            <div *ngIf="skeditPayRecurringPaymentsErrorCount" class="bg-danger text-white px-2 py-1 side-menu-badge">
                {{skeditPayRecurringPaymentsErrorCount}}
            </div>
        </li>
        <li class="item-customers">
            <a style="border-radius:10px" href="/customers"
               [routerLink]="['/customers']"
               routerLinkActive="bg-primary text-white">
                <i class="bi bi-people-fill"></i>Customers
            </a>
        </li>

        <li class="item-settings" *ngIf="isSkeditPayAdmin">
            <a style="border-radius:10px" href="/users" [routerLink]="['/users']"
               routerLinkActive="bg-primary text-white">
                <i class="bi bi-person-circle"></i>Users
            </a>
        </li>

        <li class="item-settings" *ngIf="isSkeditPayAdmin && isQuickbooksEnabled">
            <a style="border-radius:10px" href="/skeditPayQuickbooksOnline" [routerLink]="['/skeditPayQuickbooksOnline']"
               routerLinkActive="bg-primary text-white">
                <i class="bi bi-gear-fill"></i>Quickbooks
            </a>
        </li>

</div>


<!-- NOT SKEDIT PAY  -->
<!-- SCHEDULE -->
<div *ngIf="!skeditPay">

    <li *ngIf="canAccessSchedule" class="item-schedule">
        <a (click)="resetHighlight(); checkHighlight('')" *ngIf="!isLathamOrdersOnly" href='/schedule' [routerLink]="['/schedule']" style="border-radius:10px"
           routerLinkActive="bg-primary text-white">
            <i class="bi bi-calendar-fill"></i>Schedule
        </a>
        <a (click)="resetHighlight(); checkHighlight('')" *ngIf="isLathamOrdersOnly" href="/accessDenied" [routerLink]="['/accessDenied/schedule']" style="border-radius:10px">
            <i class="bi bi-calendar-fill"></i>Schedule
        </a>

    </li>

    <!-- CUSTOMERS -->
    <div *ngIf="!isQPro && !isJellyFishTrial">
        <li *ngIf="canAccessCustomers || canAccessCRM" class="item-customers">
            <input class="toggle" type="checkbox" id="item-customers" [checked]="customersExpanded">
            <label class="sub-toggle" for="item-customers" style="border-radius:10px" (click)="toggleLocalStorage('Customers');" [ngClass]="{'bg-primary text-white' : highlightCustomers}"><i class="bi bi-people-fill"></i>Customers</label>

            <ul class="submenu" style="border-radius:10px">
                <li *ngIf="canAccessCustomers">
                    <a (click)="checkHighlight('/customers')" href="/customers"
                       [routerLink]="['/customers']"
                       routerLinkActive="text-white">
                        Customers
                    </a>
                </li>
                <li *ngIf="canAccessCRM">
                    <a (click)="checkHighlight('/crm')" *ngIf="!isLathamOrdersOnly" href="/crm/outreaches" [routerLink]="['/crm/outreaches']"
                       routerLinkActive="text-white" style="border-radius:10px">
                        CRM
                    </a>
                    <a (click)="checkHighlight('/crm')" *ngIf="isLathamOrdersOnly" href="/accessDenied" [routerLink]="['/accessDenied/crm']" style="border-radius:10px">
                        CRM
                    </a>
                </li>
            </ul>
        </li>
    </div>


    <div *ngIf="isQPro || isJellyFishTrial">
        <li class="item-customers">
            <a (click)="resetHighlight(); checkHighlight('')" href='/customers' [routerLink]="['/customers']"
               routerLinkActive="bg-primary text-white" style="border-radius:10px">
                <i class="bi bi-people-fill"></i>Customers
            </a>
        </li>
    </div>

    <li class="item-jobs" *ngIf="isJellyFishTrial">
        <a (click)="checkHighlight('')" href="/jobs" [routerLink]="['/jobs']"
           routerLinkActive="text-white bg-primary" style="border-radius:10px">
            <i class="bi bi-briefcase-fill"></i>Jobs
        </a>
    </li>


    <!-- JOBS -->
    <li *ngIf="(canAccessJobs || canAccessRecurringJobs || canAccessSmartlist) && !isJellyFishTrial" class="item-jobs">
        <input class="toggle" type="checkbox" id="item-jobs" [checked]="jobsExpanded">
        <label class="sub-toggle" for="item-jobs" style="border-radius:10px" (click)="toggleLocalStorage('Jobs');" [ngClass]="{'bg-primary text-white' : highlightJobs}">
            <i class="bi bi-briefcase-fill"></i>Jobs
            <div *ngIf="smartlistOverdueCount" class="bg-danger text-white px-2 py-1 side-menu-badge">
                {{smartlistOverdueCount}}
            </div>
        </label>


        <ul class="submenu">
            <li *ngIf="canAccessJobs">
                <a (click)="checkHighlight('/jobs')" *ngIf="!isLathamOrdersOnly" href="/jobs" [routerLink]="['/jobs']"
                   routerLinkActive="text-white">
                    Jobs
                </a>
                <a (click)="checkHighlight('/jobs')" *ngIf="isLathamOrdersOnly" href="/accessDenied" [routerLink]="['/accessDenied/jobs']">
                    Jobs
                </a>
            </li>
            <li *ngIf="canAccessRecurringJobs">
                <a (click)="checkHighlight('/jobs')" *ngIf="!isLathamOrdersOnly" href="/recurring" [routerLink]="['/recurring']"
                   routerLinkActive="text-white">
                    Recurring
                </a>
                <a (click)="checkHighlight('/jobs')" *ngIf="isLathamOrdersOnly" href="/accessDenied" [routerLink]="['/accessDenied/recurring']">
                    Recurring
                </a>
            </li>
            <li *ngIf="canAccessSmartlist">
                <a (click)="checkHighlight('/jobs')" *ngIf="!isLathamOrdersOnly" href="/smartlist" [routerLink]="['/smartlist']"
                   routerLinkActive="text-white">
                    <div style="position: relative">
                        Smartlist
                        <div *ngIf="smartlistOverdueCount" class="bg-danger text-white px-2 py-1 sub-menu-badge">
                            {{smartlistOverdueCount}}
                        </div>
                    </div>
                </a>
                <a (click)="checkHighlight('/jobs')" *ngIf="isLathamOrdersOnly" href="/accessDenied" [routerLink]="['/accessDenied/smartlist']">
                    <div style="position: relative">
                        Smartlist
                        <div *ngIf="smartlistOverdueCount" class="bg-danger text-white px-2 py-1 sub-menu-badge">
                            {{smartlistOverdueCount}}
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </li>

    <!-- ACCOUNTING -->
    <div *ngIf="!isQPro">
        <li *ngIf="canAccessEstimates || canAccessSalesOrders || canAccessInvoices || canAccessPayments || canAccessQuickbooks" class="item-accounting">
            <input class="toggle" type="checkbox" id="item-accounting" [checked]="accountingExpanded">
            <label class="sub-toggle" for="item-accounting" style="border-radius:10px" (click)="toggleLocalStorage('Accounting');" [ngClass]="{'bg-primary text-white' : highlightAccounting}">
                <i class="bi bi-currency-dollar"></i>Accounting
                <div *ngIf="recurringBillingErrorCount" class="bg-danger text-white px-2 py-1 side-menu-badge">
                    {{recurringBillingErrorCount}}
                </div>
            </label>

            <ul class="submenu">
                <li *ngIf="canAccessEstimates">
                    <a (click)="checkHighlight('/estimates')" href="/estimates" [routerLink]="['/estimates']"
                       routerLinkActive="text-white">
                        Estimates
                    </a>
                    <!--<a *ngIf="isLathamOrdersOnly" href="/accessDenied" [routerLink]="['/accessDenied']">
                        <i class="far fa-calculator"></i>Estimates
                    </a>-->
                </li>
                <li *ngIf="canAccessSalesOrders">
                    <a (click)="checkHighlight('/salesOrders')" href="/salesOrders" [routerLink]="['/salesOrders']"
                       routerLinkActive="text-white">
                        Sales Orders
                    </a>
                    <!--<a *ngIf="isLathamOrdersOnly" href="/accessDenied" [routerLink]="['/accessDenied']">
                        <i class="far fa-dollar-sign"></i>Sales Orders
                    </a>-->
                </li>
                <li *ngIf="canAccessInvoices">
                    <a (click)="checkHighlight('/invoices')" href="/invoices" [routerLink]="['/invoices']"
                       routerLinkActive="text-white">
                        Invoices
                    </a>
                    <!--<a *ngIf="isLathamOrdersOnly" href="/accessDenied" [routerLink]="['/accessDenied']">
                        <i class="far fa-file-invoice-dollar"></i>Invoices
                    </a>-->
                </li>
                <li *ngIf="canAccessPayments">
                    <a (click)="checkHighlight('/payments')" href="/payments" [routerLink]="['/payments']"
                       routerLinkActive="text-white">
                        Payments
                    </a>
                    <!--<a *ngIf="isLathamOrdersOnly" href="/accessDenied" [routerLink]="['/accessDenied']">
                        <i class="far fa-credit-card"></i>Payments
                    </a>-->
                </li>
                <li *ngIf="!isJellyFishTrial">
                    <a (click)="checkHighlight('/recurring-billing')" href="/recurring-billing" [routerLink]="['/recurring-billing']"
                       routerLinkActive="text-white">

                        <div style="position: relative">
                            Recurring Billing
                            <div *ngIf="recurringBillingErrorCount" class="bg-danger text-white px-2 py-1 sub-menu-badge">
                                {{recurringBillingErrorCount}}
                            </div>
                        </div>

                    </a>

                    <!--<a *ngIf="isLathamOrdersOnly" href="/accessDenied" [routerLink]="['/accessDenied']">
                        <i class="far fa-credit-card"></i>Payments
                    </a>-->
                </li>
                <li *ngIf="canAccessQuickbooks">
                    <a (click)="checkHighlight('/quickbooks')" href="/quickbooks" [routerLink]="['/quickbooks']"
                       routerLinkActive="text-white">
                        <div>
                            <span class="quickbooks-text">Quickbooks</span>
                        </div>
                    </a>
                </li>
            </ul>
        </li>
    </div>

    <!-- JellyFish Orders-->
    <!--<li *ngIf="useJellyFishOrdering || isJellyFish" class="item-latham-orders">
        <a (click)="resetHighlight(); checkHighlight('');" href="/jellyFishOrders" [routerLink]="['/jellyFishOrders']"
           routerLinkActive="bg-primary" style="border-radius:10px">
            <div class="d-flex align-items-center">
                <div class="flex-fill">
                    <i><img src="../../../content/images/jelly-fish-logo-white.png" style="max-width: 20px; max-height: 20px;" /></i>JellyFish Orders
                </div>
                <div class="mr-1">
                    <div *ngIf="isJellyFish && jellyFishUnapprovedOrdersCount" class="bg-danger text-white px-2 py-1 side-menu-badge">
                        {{jellyFishUnapprovedOrdersCount}}
                    </div>
                </div>
            </div>
        </a>
    </li>-->


    <!-- LATHAM ORDERS -->
    <li *ngIf="canAccessLathamOrders" class="item-latham-orders">
        <a (click)="resetHighlight(); checkHighlight('');" href="/lathamOrders" [routerLink]="['/lathamOrders']"
           routerLinkActive="bg-primary" style="border-radius:10px">
            <div class="d-flex align-items-center">
                <div class="flex-fill">
                    <i><img src="../../../content/images/latham-logo-white.png" style="max-width: 15px; max-height: 15px;" /></i>Latham Orders
                </div>
                <div class="me-1">
                    <div *ngIf="totalOrdersCount" class="bg-danger text-white px-2 py-1 side-menu-badge">
                        {{totalOrdersCount}}
                    </div>
                </div>
            </div>
        </a>
    </li>

    <!-- INVENTORY -->
    <li *ngIf="canAccessInventory && canAccessPartsList" class="item-inventory">
        <input class="toggle" type="checkbox" id="item-inventory" [checked]="inventoryExpanded">
        <label class="sub-toggle" for="item-inventory" style="border-radius:10px" (click)="toggleLocalStorage('Inventory');" [ngClass]="{'bg-primary text-white' : highlightInventory}"><i class="bi bi-box-seam-fill"></i>Inventory</label>

        <ul class="submenu">
            <li *ngIf="canAccessInventory">
                <a (click)="checkHighlight('/inventory')" href="/inventory" [routerLink]="['/inventory']"
                   routerLinkActive="text-white">
                    Inventory
                </a>
            </li>
            <li *ngIf="canAccessPartsList">
                <a (click)="checkHighlight('/items')" href="/items" [routerLink]="['/items']"
                   routerLinkActive="text-white">
                    Parts List
                </a>
            </li>
            <li *ngIf="canAccessInventory && canAccessPickList">
                <a (click)="checkHighlight('/pickList')" href="/pickList" [routerLink]="['/pickList']"
                   routerLinkActive="text-white">
                    Pick List
                </a>
            </li>
        </ul>
    </li>

    <li *ngIf="canAccessInventory && !canAccessPartsList" class="item-inventory" id="item-inventory">
        <a style="border-radius:10px" (click)="resetHighlight(); checkHighlight('')" href="/inventory" [routerLink]="['/inventory']"
           routerLinkActive="bg-primary text-white">
            <i class="bi bi-box-seam-fill"></i>Inventory
        </a>
    </li>

    <li *ngIf="canAccessPartsList && !canAccessInventory" class="item-inventory" id="item-inventory">
        <a style="border-radius:10px" (click)="resetHighlight(); checkHighlight('')" href="/items" [routerLink]="['/items']"
           routerLinkActive="bg-primary text-white">
            <i class="bi bi-tools"></i>Parts List
        </a>
    </li>

    <!-- Purchasing -->
    <li class="item-purchasing" *ngIf="canAccessVendors || canAccessPurchaseOrders || canAccessReceiving">
        <input class="toggle" type="checkbox" id="item-purchasing" [checked]="purchasingExpanded">
        <label class="sub-toggle" for="item-purchasing" style="border-radius:10px" (click)="toggleLocalStorage('Purchasing');" [ngClass]="{'bg-primary text-white' : highlightPurchasing}"><i class="bi bi-cart-fill"></i>Purchasing</label>

        <ul class="submenu">
            <li *ngIf="canAccessVendors">
                <a (click)="checkHighlight('/vendors')" href="/vendors"
                   [routerLink]="['/vendors']"
                   routerLinkActive="text-white">
                    Vendors
                </a>
            </li>

            <li *ngIf="canAccessPurchaseOrders">
                <a (click)="checkHighlight('/purchaseOrders')" href="/purchaseOrders" [routerLink]="['/purchaseOrders']"
                   routerLinkActive="text-white">
                    Purchase Orders
                </a>
            </li>

            <li *ngIf="canAccessReceiving">
                <a (click)="checkHighlight('/receiving')" href="/receiving" [routerLink]="['/receiving']"
                   routerLinkActive="text-white">
                    Receiving
                </a>
            </li>

        </ul>
    </li>

    <li class="item-purchasing" *ngIf="canAccessShipping && !isLathamOrdersOnly">
        <a (click)="resetHighlight(); checkHighlight('')" href="/shipping" [routerLink]="['/shipping']"
           routerLinkActive="bg-primary text-white" style="border-radius:10px">
            <i class="fas fa-truck"></i>Shipping
        </a>
    </li>

    <!-- TextChat -->
    <li *ngIf="canAccessTextChat" class="item-text-chat">
        <a (click)="resetHighlight(); checkHighlight('')" href='/textChat'
           [routerLink]="['/textChat']"
           routerLinkActive="bg-primary text-white" style="border-radius:10px">

            <div class="d-flex align-items-center">
                <div class="flex-fill">
                    <i class="bi bi-chat-square-text-fill"></i>Text Chat
                </div>
                <div class="me-1">
                    <div *ngIf="textChatUnreadMessagesCount > 0" class="bg-danger text-white px-2 py-1 side-menu-badge">
                        {{textChatUnreadMessagesCount}}
                    </div>
                </div>
            </div>
        </a>
    </li>

    <!-- Timeclock -->
    <li *ngIf="!isJellyFishTrial" class="item-timeclock">
        <a (click)="resetHighlight(); checkHighlight('')" *ngIf="!isLathamOrdersOnly" href='/timeclock' [routerLink]="['/timeclock']"
           routerLinkActive="bg-primary text-white" style="border-radius:10px">
            <i class="bi bi-clock-fill"></i>Time Clock
        </a>
        <a (click)="resetHighlight(); checkHighlight('')" *ngIf="isLathamOrdersOnly" href="/accessDenied" [routerLink]="['/accessDenied/timeclock']" style="border-radius:10px">
            <i class="bi bi-clock-fill"></i>Time Clock
        </a>
    </li>

    <!-- Documents -->
    <li *ngIf="canAccessDocuments" class="item-documents">
        <a (click)="resetHighlight(); checkHighlight('')" *ngIf="!isLathamOrdersOnly" href='/documents' [routerLink]="['/documents']"
           routerLinkActive="bg-primary text-white" style="border-radius:10px">
            <i class="bi bi-file-earmark-text-fill"></i>Documents
        </a>
        <a (click)="resetHighlight(); checkHighlight('')" *ngIf="isLathamOrdersOnly" href="/accessDenied" [routerLink]="['/accessDenied/documents']" style="border-radius:10px">
            <i class="bi bi-file-earmark-text-fill"></i>Documents
        </a>
    </li>

    <!-- Reports -->
    <li *ngIf="canAccessReports && !isJellyFishTrial" class="item-reports">
        <input class="toggle" type="checkbox" id="item-reports" [checked]="reportsExpanded">
        <label class="sub-toggle" for="item-reports" style="border-radius:10px" (click)="toggleLocalStorage('Reports');" [ngClass]="{'bg-primary text-white' : highlightReports}"><i class="bi bi-bar-chart-fill"></i>Reports</label>


        <ul class="submenu">
            <li>
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" *ngIf="!isLathamOrdersOnly" href='/reporting/apptAveragesReport'
                   [routerLink]="['/reporting/apptAveragesReport']"
                   routerLinkActive="text-white">
                    Appt Averages Report
                </a>
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" *ngIf="isLathamOrdersOnly" href="/accessDenied" [routerLink]="['/accessDenied/apptAveragesReport']">
                    Appt Averages Report
                </a>
            </li>
            <li>
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" *ngIf="!isLathamOrdersOnly" href='/reporting/itemUsageReport'
                   [routerLink]="['/reporting/itemUsageReport']"
                   routerLinkActive="text-white">
                    Item Usage Report
                </a>
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" *ngIf="isLathamOrdersOnly" href="/accessDenied" [routerLink]="['/accessDenied/itemUsageReport']">
                    Item Usage Report
                </a>
            </li>

            <li *ngIf="isCanada || isIMPC">
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" href='/reporting/itemUsageByBuilderReport'
                   [routerLink]="['/reporting/itemUsageByBuilderReport']"
                   routerLinkActive="text-white">
                    Item Usage By Builder
                </a>
            </li>

            <li *ngIf="isCanada">
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" href='/reporting/cscPartsListReport'
                   [routerLink]="['/reporting/cscPartsListReport']"
                   routerLinkActive="text-white">
                    CSC Parts List Report
                </a>
            </li>

            <li *ngIf="canAccessLathamOrders">
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" href='/reporting/lathamOrderInsights'
                   [routerLink]="['/reporting/lathamOrderInsights']"
                   routerLinkActive="text-white">
                    Latham Insights Report
                </a>
            </li>

            <li *ngIf="isIMPC">
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" href='/reporting/lathamShipping'
                   [routerLink]="['/reporting/lathamShipping']"
                   routerLinkActive="text-white">
                    Latham Shipping Report
                </a>
            </li>

            <li *ngIf="isCanada">
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" href='/reporting/lathamReport'
                   [routerLink]="['/reporting/lathamReport']"
                   routerLinkActive="text-white">
                    Latham Report
                </a>
            </li>
            <li *ngIf="isCanada">
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" href='/reporting/rebatesReport'
                   [routerLink]="['/reporting/rebatesReport']"
                   routerLinkActive="text-white">
                    Rebates Report
                </a>
            </li>
            <li>
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" *ngIf="!isLathamOrdersOnly" href='/reporting/resourceApptTypesReport'
                   [routerLink]="['/reporting/resourceApptTypesReport']"
                   routerLinkActive="text-white">
                    Resource Appt Types
                </a>
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" *ngIf="isLathamOrdersOnly" href="/accessDenied" [routerLink]="['/accessDenied/resourceApptTypesReport']">
                    Resource Appt Types
                </a>
            </li>
            <li *ngIf="isCanada">
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" href='/reporting/ontarioBuilderReport'
                   [routerLink]="['/reporting/ontarioBuilderReport']"
                   routerLinkActive="text-white">
                    Ontario Builder Report
                </a>
            </li>
            <li *ngIf="isCanada">
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" href='/reporting/lathamSystemSummaryReport'
                   [routerLink]="['/reporting/lathamSystemSummaryReport']"
                   routerLinkActive="text-white">
                    System Summary Report
                </a>
            </li>
            <li>
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" href='/reporting/employeeApptsReport'
                   [routerLink]="['/reporting/employeeApptsReport']"
                   routerLinkActive="text-white">
                    Employee Appts Report
                </a>
            </li>
            <li>
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" href='/reporting/employeeAvgHoursWorked'
                   [routerLink]="['/reporting/employeeAvgHoursWorked']"
                   routerLinkActive="text-white">
                    Employee Avg Hours
                </a>
            </li>
            <li>
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" href='/reporting/employeeMonthlyHoursWorked'
                   [routerLink]="['/reporting/employeeMonthlyHoursWorked']"
                   routerLinkActive="text-white">
                    Employee Monthly Hours
                </a>
            </li>
            <li>
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" href='/reporting/employeeYearlyHoursWorked'
                   [routerLink]="['/reporting/employeeYearlyHoursWorked']"
                   routerLinkActive="text-white">
                    Employee Yearly Hours
                </a>
            </li>

            <li *ngIf="isIMPC">
                <a (click)="checkHighlight('/reporting')" style="margin:0px;" href='/reporting/quoteTracking'
                   [routerLink]="['/reporting/quoteTracking']"
                   routerLinkActive="text-white">
                    Quote Tracking
                </a>
            </li>
        </ul>
    </li>



    <!-- SETTINGS -->
    <li *ngIf="canAccessSettings || canAccessUsers" class="item-settings">
        <input class="toggle" type="checkbox" id="item-settings" [checked]="settingsExpanded">
        <label class="sub-toggle" for="item-settings" style="border-radius:10px" (click)="toggleLocalStorage('Settings');" [ngClass]="{'bg-primary text-white' : highlightSettings}"><i class="bi bi-gear-fill"></i>Settings</label>

        <ul class="submenu">
            <li *ngIf="canAccessSettings">
                <a (click)="checkHighlight('/settings')" href="/settings" [routerLink]="['/settings']"
                   routerLinkActive="text-white">
                    Settings
                </a>
            </li>
            <li *ngIf="canAccessUsers">
                <a (click)="checkHighlight('/users')" href="/users" [routerLink]="['/users']"
                   routerLinkActive="text-white">
                    Users
                </a>
            </li>
        </ul>
    </li>
</div>

    </ul>
</div>