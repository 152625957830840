import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { IAuthService } from '../auth.service';
import { Observable } from 'rxjs';
import { GlobalsService } from '@services';
import { Injectable } from '@angular/core';
@Injectable()
export class CustomersAuthService
    implements CanActivate, CanActivateChild, IAuthService {
    feature: string;

    constructor() {
        this.feature = 'customers';
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return null;
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return null;
    }

    canAccess(): boolean {
        return GlobalsService.checkPermission(this.feature, 'access');
    }

    canAdd(): boolean {
        return GlobalsService.checkPermission(this.feature, 'add');
    }

    canDelete(): boolean {
        return GlobalsService.checkPermission(this.feature, 'delete');
    }

    canEdit(): boolean {
        return GlobalsService.checkPermission(this.feature, 'edit');
    }

    canView(): boolean {
        return GlobalsService.checkPermission(this.feature, 'view');
    }
}
