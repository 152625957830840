export interface IBlockedTimeSlotModel {
	blockedTimeSlotId: number;
	resourceId: number;
	blockDateTime: Date;
	duration: number;
	text: string;
	allowAppointments: boolean
}

export class BlockedTimeSlotModel implements IBlockedTimeSlotModel {
	blockedTimeSlotId: number;
	resourceId: number;
	blockDateTime: Date;
	duration: number;
	text: string;
	allowAppointments: boolean
}
