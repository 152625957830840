import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { SlickTabsComponent } from "./slick-tabs.component";
import { SlickTabComponent } from "./slick-tab.component";
import { SlickTabHeaderComponent } from "./slick-tab-header.component";

@NgModule({
	imports: [CommonModule],
	declarations: [SlickTabsComponent, SlickTabComponent, SlickTabHeaderComponent],
	exports: [CommonModule, SlickTabsComponent, SlickTabComponent, SlickTabHeaderComponent]
})
export class SlickTabsModule { }
export { SlickTabsComponent }
export { SlickTabComponent }
export { SlickTabHeaderComponent } 
