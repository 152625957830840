
<slick-dialog header="Select Card" #creditCardDialogRef (onClose)="onClose()">
    <div class="container">

        <div class="row">
            <ng-template [ngIf]="creditCardPaymentTypes">
                <article [ngClass]="isMobile ? 'col-12' : 'col-6'" class="credit-card-info " *ngFor="let creditcard of creditCardPaymentTypes">
                    <button type="button" class="card-ui btn" [ngClass]="{ 'btn-primary': creditcard.creditCardPaymentTypeId === selectedPaymentTypeModel?.creditCardPaymentTypeId, 'btn-outline-primary': creditcard.creditCardPaymentTypeId !== selectedPaymentTypeModel?.creditCardPaymentTypeId }" (click)="selectPaymentType(creditcard)" (dblclick)="editPaymentType(creditcard)">
                        <div class="col-12 ps-0 pe-0">
                            <span class="col-10 ps-0 pe-0 card-description">{{ creditcard.paymentDescription}}</span>
                            <span class="col-2 ps-0 pe-0 card-icon">
                                <i *ngIf="creditcard.paymentType.toLowerCase().indexOf('visa') > -1" class='fab fa-cc-visa'></i>
                                <i *ngIf="creditcard.paymentType.toLowerCase().indexOf('mastercard') > -1" class='fab fa-cc-mastercard'></i>
                                <i *ngIf="creditcard.paymentType.toLowerCase().indexOf('discover') > -1" class='fab fa-cc-discover'></i>
                                <i *ngIf="creditcard.paymentType.toLowerCase().indexOf('american express') > -1" class='fab fa-cc-amex'></i>
                                <i class="far fa-pencil ms-2" style="font-size: .8rem" (click)="editPaymentType(creditcard)"></i>
                            </span>
                        </div>
                        <div class="col-12 ps-0 pe-0 card-number">
                            XXXX XXXX XXXX {{ creditcard.accountNumberLastFour }}
                        </div>
                        <div style="text-align: right" class="col-12 ps-0 pe-0 ">
                            {{ creditcard.expDate }}
                        </div>
                        <div class="col-12 ps-0 pe-0 default-card" *ngIf="creditcard.isDefault">
                            Default Card
                        </div>
                    </button>
                </article>
            </ng-template>

            <article *ngIf="customerModel" [ngClass]="isMobile ? 'col-12' : 'col-6'" class="credit-card-info ">
                <button type="button" class="card-ui btn btn-outline-primary add-new-payment-holder" (click)="createNewPaymentType()">
                    <div class="add-new-payment"><i class="fa fa-plus"></i> Add Card</div>
                </button>
            </article>
        </div>
    </div>
    <slick-dialog-footer>
        <div class="mt-2">
            <button class="btn btn-outline-primary" (click)="onSelect()">Select</button>
            <form-error *ngIf="submitted && !selectedPaymentTypeModel">Please select a payment type.</form-error>
        </div>
        <div class="mt-2">
            <em>*Your card will not be charged until your order is approved.</em>
        </div>
    </slick-dialog-footer>
</slick-dialog>

<credit-card-edit-dialog #creditCardEditDialog (onChange)="creditCardEditDialogChanges()" [isMobile]="isMobile"></credit-card-edit-dialog>
