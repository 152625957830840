import { Component, Input } from "@angular/core";

@Component({
	selector: 'form-error',
	template: `
<div class='d-flex flex-fill justify-content-end'>
	<div class='error-wrapper show-icons text-danger' [ngClass]="{'absolute' : absolute === true }" [style.width]='width'>
		<div class='error'><ng-content></ng-content></div>
	</div>
</div>`
})
export class FormErrorComponent {
	@Input() absolute: boolean = false;
	@Input() width: string = "100%";
}