import { Injectable, OnInit } from "@angular/core";
import { GlobalsService } from "@services";
import { HubConnection, HubConnectionBuilder, LogLevel, HubConnectionState } from "@microsoft/signalr";
import { SignalRMessageStore, LoggerStore } from "@stores";

export enum SignalRFeatureTypes { Chat, Notification, Quickbooks, Appointments, LathamOrders, Test, RecurringBilling, SkeditPayRecurringPayments, JellyFishOrders }

@Injectable()
export class SignalRMessageHubService {
    private static hubConnection: HubConnection = null;

    constructor(private signalRMessageStore: SignalRMessageStore,
        private loggerStore: LoggerStore) { }

    private startConnection() {
        this.loggerStore.addLog(`SignalRMessageHub start connection (State: ${SignalRMessageHubService.hubConnection.state})`);
        if (SignalRMessageHubService.hubConnection.state === HubConnectionState.Connected) {
            this.loggerStore.addLog("SignalRMessageHub exiting because connected");
            return;
        }

        SignalRMessageHubService.hubConnection
            .start()
            .then(() => {
                this.loggerStore.addLog("SignalRMessageHub connected");
            })
            .catch(err => {
                setTimeout(() => {
                    if (SignalRMessageHubService.hubConnection.state === HubConnectionState.Disconnected)
                        this.startConnection();
                }, 5000);
            });
    }

    init() {
        if (GlobalsService.isDebug)
           return;

        if (SignalRMessageHubService.hubConnection === null) {
            SignalRMessageHubService.hubConnection = new HubConnectionBuilder()
                .withUrl(GlobalsService.apiUrl + "/signalRMessageHub", { accessTokenFactory: () => GlobalsService.jwtToken })
                .build();

            SignalRMessageHubService.hubConnection.on('SendMessage', (featureType: SignalRFeatureTypes, action: string, data: any) => this.signalRMessageStore.onSendMessage(featureType, action, data));

            // detect if the connection is lost and reconnect
            SignalRMessageHubService.hubConnection.onclose(() => {
                this.loggerStore.addLog("SignalRMessageHub onclose");
                setTimeout(() => {
                    if (SignalRMessageHubService.hubConnection.state === HubConnectionState.Disconnected)
                        this.startConnection();
                }, 10000);
            });

            this.startConnection();
        }
    }
}