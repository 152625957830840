<slick-dialog #chatDialogRef width="800" header="Chat" (onClose)="onDialogClose()" [showOverlay]="false" [disableScrollbar]="true" [resizable]="false">
	<div class="d-flex" style="min-height: calc(100vh - 320px); max-height: calc(100vh - 160px);">
		<div style="min-width: 300px; max-width: 300px; overflow-y: auto;" class="modal-content border-0 pe-2">
			<chat-group-list (onGroupClicked)="onGroupClicked($event)"></chat-group-list>
		</div>

		<div *ngIf="chatGroup" class="d-flex flex-column flex-fill border-start" style="max-width: 480px;">
			<h4 class="p-2 bg-primary text-white d-flex" style="font-size:18px">
				<div *ngIf="chatGroup?.groupName" class="flex-fill" style="cursor:default" title="{{chatGroup?.longAttendantsString}}">
					{{chatGroup?.groupName}}
				</div>
				<div *ngIf="chatGroup?.attendantsString && !chatGroup?.groupName" style="cursor:default" title="{{chatGroup?.longAttendantsString}}" class="flex-fill">
					{{chatGroup?.attendantsString}}
				</div>

				<div *ngIf="chatGroup.isMuted" class="me-1" (click)="toggleMute(chatGroup)"><i class="far fa-volume-slash"></i></div>
				<div *ngIf="!chatGroup.isMuted" class="me-1" (click)="toggleMute(chatGroup)"><i class="far fa-volume"></i></div>
				<div *ngIf="!chatGroup.isDeleted && !chatGroup.isAllUsers" class="me-1" (click)="editGroupName()"><i class="far fa-pencil"></i></div>
				<div *ngIf="!chatGroup.isDeleted && !chatGroup.isAllUsers" class="me-1" (click)="addAttendants(chatGroup)"><i class="far fa-user-plus"></i></div>
				<div *ngIf="!chatGroup.isDeleted" (click)="deleteGroup(chatGroup)"><i class="bi bi-trash"></i></div>
			</h4>
			<chat-messages *ngIf="chatGroup" [chatGroup]="chatGroup"></chat-messages>
		</div>
	</div>
</slick-dialog>

<chat-group-attendants #chatGroupAttendantsRef></chat-group-attendants>
<slick-dialog #editGroupNameRef [showHeader]="false" width="300px">
	<div>
		Group Name
		<input type="text" class="form-control" [(ngModel)]="newChatGroupName" />
	</div>
	<slick-dialog-footer>
		<button class=" btn btn-outline-primary me-1" (click)="saveChatGroupName(newChatGroupName)"><i class="far fa-check"></i> Ok</button>
		<button class="btn btn-outline-danger" (click)="cancelGroupName()"><i class="bi bi-x-circle"></i> Cancel</button>
	</slick-dialog-footer>
</slick-dialog>