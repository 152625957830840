<div class="d-flex flex-fill align-items-center position-relative">
	<input #autoCompleteInputRef
		   type="text"
		   class="form-control"
		   [ngClass]="{ 'text-body': disabled }"
		   autocomplete="off"
		   [placeholder]="placeholder"
		   [(ngModel)]="searchText"
		   (keyup)="onKeyUp($event)"
		   (blur)="onBlur()"
		   [disabled]="disabled"
		   [slick-validation-indicator]="customerModel"
		   [slickValidationType]="valType"/>

	<div *ngIf="showButtons" class="d-flex position-absolute top-50 translate-middle-y end-0">
		<div  class="slick-search-bar_add-button me-2">
			<div *ngIf="disableAddButton"><i class="far fa-plus"></i></div>
			<a href="#" *ngIf="!disableAddButton" (click)="showNewCustomerDialog(); false;"><i class="far fa-plus"></i></a>
		</div>
		<div class="slick-search-bar_edit-button me-2">
			<div *ngIf="disableEditButton"><i class="far fa-pencil"></i></div>
			<a href="#" *ngIf="!disableEditButton" (click)="showEditCustomerDialog(); false;"><i class="far fa-pencil"></i></a>
		</div>
	</div>
</div>
<slick-auto-complete [inputRef]="autoCompleteInputRef"
					 textField="customerFullName"
					 [items]="results"
					 (onRefresh)="search($event)"
					 (onSelect)="onSelect($event)">
	<ng-template let-result
				 [slickAutoCompleteListItemTemplate]>
		<ul class="customer-list-item m-0">
			<li *ngIf="result.customerCompanyName" class="bigger">{{ result.customerCompanyName }}</li>
			<li *ngIf="result.customerFullName && !result.customerCompanyName && result.customerMultipleAddressId" class="bigger">{{ result.customerFullName }}</li>
			<li *ngIf="result.owningCompanyName && result.customerFullName && showOwningCompany" style="font-size: 10px"><i>{{result.owningCompanyName}}</i></li>
			<li *ngIf="result.owningCompanyName && !result.customerFullName && showOwningCompany" style="font-size: 10px"><i>{{result.owningCompanyName}}</i></li>
			<li *ngIf="result.customerFullName && !result.customerMultipleAddressId" class="bigger">{{ result.customerFullName }}</li>
			<li *ngIf="result.multiDescription">{{result.multiDescription}}</li>
			<li *ngIf="result.multiCompanyName">{{ result.multiCompanyName }}</li>

			<li *ngIf="result.multiFullName">{{ result.multiFullName }}</li>

			<li *ngIf="result.address1 && !isBillingCustomer">{{ result.address1 }}</li>
			<li *ngIf="result.address2 && !isBillingCustomer">{{ result.address2 }}</li>
			<li *ngIf="result.cityStateZip && !isBillingCustomer" style="line-height: 1rem">{{ result.cityStateZip }}</li>

			<li *ngIf="result.billingAddress1 && isBillingCustomer">{{ result.billingAddress1 }}</li>
			<li *ngIf="result.billingAddress2 && isBillingCustomer">{{ result.billingAddress2 }}</li>
			<li *ngIf="result.billingCityStateZip && isBillingCustomer" style="line-height: 1rem">{{ result.billingCityStateZip }}</li>

			<li *ngIf="result.cellPhone">Cell: {{ result.cellPhone }}</li>
			<li *ngIf="result.homePhone">Home: {{ result.homePhone }}</li>
			<li *ngIf="result.workPhone">Work: {{ result.workPhone }}</li>
		</ul>

	</ng-template>
</slick-auto-complete>
<customer-display *ngIf="showCustomerDisplay && !customerMultiAddress" [customerModel]="customerModel"
				  [enableCreditCardEdit]="!disabled"
				  [isBillingCustomer]="isBillingCustomer"
				  [hideEditButton]="disableButtons"
				  [billingAddress]="billingAddress"></customer-display>

<div *ngIf="customerMultiAddress">
	<section class="customer-info mt-1 border">
		<ul class="customer-list-item pt-0 ms-1 m-2">
			<li *ngIf="customerModel?.fullName" class="bigger">{{customerModel.fullName}}</li>
			<li *ngIf="customerMultiAddress?.description && customerModel?.fullName">{{customerMultiAddress.description}}</li>
			<li *ngIf="customerMultiAddress?.description && !customerModel?.fullName" class="bigger">{{customerMultiAddress.description}}</li>
			<li *ngIf="customerMultiAddress?.companyName">{{ customerMultiAddress.companyName }}</li>
			<li *ngIf="customerMultiAddress?.companyName">{{ customerMultiAddress.firstName }} {{customerMultiAddress.lastName}}</li>

			<li *ngIf="!customerMultiAddress?.companyName">{{ customerMultiAddress.firstName }} {{customerMultiAddress.lastName}}</li>

			<li *ngIf="customerMultiAddress.address1 && !isBillingCustomer" style="line-height: 1rem">{{ customerMultiAddress.address1 }}</li>
			<li *ngIf="customerMultiAddress.address2 && !isBillingCustomer" style="line-height: 1rem">{{ customerMultiAddress.address2 }}</li>
			<li style="line-height: 1rem">
				<span *ngIf="customerMultiAddress.city && !isBillingCustomer">{{ customerMultiAddress.city }}, </span>
				<span *ngIf="customerMultiAddress.state && !isBillingCustomer">{{ customerMultiAddress.state }}, </span>
				<span *ngIf="customerMultiAddress.zipcode && !isBillingCustomer">{{ customerMultiAddress.zipcode }}</span>
			</li>

			<li *ngIf="customerMultiAddress.cellPhone" style="line-height: 1rem">Cell: {{ customerMultiAddress.cellPhone }}</li>
			<li *ngIf="customerMultiAddress.homePhone" style="line-height: 1rem">Home: {{ customerMultiAddress.homePhone }}</li>
			<li *ngIf="customerMultiAddress.workPhone" style="line-height: 1rem">Work: {{ customerMultiAddress.workPhone }}</li>
			<li *ngIf="customerMultiAddress.email" style="line-height: 1rem">Email: {{ customerMultiAddress.email }}</li>
		</ul> 
	</section>

</div>

<customer-edit-dialog #customerEditDialogRef
					  (onCustomerEditSave)="onCustomerEditSave($event)"></customer-edit-dialog>

<slick-dialog #attentionNoteRef header="Attention!">
	<div class="customer-details" *ngIf="customerModel && showAttentionNote && customerModel.customerAttentionNote">
		<div class="note-section attention-note-section">
			<div class="section-title attention-title">
				<i class="fas fa-exclamation-circle"></i> Attention Notes:
			</div>
			<div class="note attention-note">{{customerModel.customerAttentionNote}}</div>
		</div>
	</div>

	<div class="customer-details" *ngIf="customerModel && showCreditHoldNotes && customerModel.creditHoldNotes">
		<div class="note-section attention-note-section">
			<div class="section-title attention-title">
				<i class="fas fa-exclamation-circle"></i> Credit Hold Notes:
			</div>
			<div class="note attention-note">{{customerModel.creditHoldNotes}}</div>
		</div>
	</div>

	<slick-dialog-footer>

		<button type="button" class="btn btn-outline-primary" style="width: 100px;" (click)="closeAttentionNoteRef()"><i class="far fa-check"></i> Ok</button>
	</slick-dialog-footer>

</slick-dialog>