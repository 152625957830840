<div *ngIf="customerJobHistory" class="p-2">
	<div style="width:300px" class="mt-2 mb-2">

		<slick-search-bar [ngModel]="searchString" (onSearch)="onSearch($event)" [showAddButton]="false" [showEditButton]="false"></slick-search-bar>
	</div>
	<div *ngIf="customerJobHistory.length === 0">
		No History to display
	</div>

	<div *ngIf="customerJobHistory.length > 0">
		<div class="card card-body mb-2 p-2 pe-3 mt-2" *ngFor="let jobHistory of customerJobHistory">
			<div class="resource-label" style="font-size: 20px;">
				<span class="border-0" style="font-weight: bold;">{{jobHistory.appointmentTypeDescription}}</span>
				<span style="margin-right: 10px; margin-left: 10px;">|</span>
				<span>{{jobHistory.jobName}} </span>
				<span style="margin-right: 13px; margin-left: 13px;">|</span>
				<span>{{jobHistory.jobSiteDisplayName}} </span>
				<span *ngIf="jobHistory.invoiceId" style="flex-grow: 1; text-align: right; font-size: 15px; font-weight: bold; margin-top: 7px;">Invoice Information</span>
				<span *ngIf="!jobHistory.invoiceId" style="flex-grow: 1; text-align: right; font-size: 15px; font-weight: bold; margin-top:7px">No Invoice</span>
			</div>
			<div class="resource-label">
				<div style="display: flex; align-items: left;">
					<span style="font-weight: bold;">{{ jobHistory.resourceName }}</span>
					<span *ngIf="jobHistory.crewDisplay" style="margin-right: 13px; margin-left: 13px;">|</span>
					<span *ngIf="jobHistory.crewDisplay">{{jobHistory.crewDisplay}} </span>
				</div>
				<span *ngIf="jobHistory.invoiceId" style="flex-grow: 1; text-align: right; font-size: 12px;">{{jobHistory.billingDisplayName}}</span>
			</div>
			<div class="resource-label">
				<div style="display: flex; align-items: left;">
					<span>{{ jobHistory.scheduledDateTime | date:'M/d/yy - h:mm a' }}</span>
					<span *ngIf="jobHistory.finishedTotalDisplay != '0:00'" style="margin-right: 13px; margin-left: 13px;">|</span>
					<span *ngIf="jobHistory.finishedTotalDisplay != '0:00'"> Total time: {{jobHistory.finishedTotalDisplay}}</span>
				</div>
				<span *ngIf="jobHistory.invoiceId" style="flex-grow: 1; text-align: right;">Invoice: {{jobHistory.invoiceNumber}}</span>
			</div>
			<div class="resource-label">
				<div *ngIf="jobHistory.invoiceId" style="flex-grow: 1; text-align: right;">Total Cost ${{ jobHistory.invoiceTotal }}</div>
			</div>
			<!--<div *ngIf="jobHistory.invoiceId">
				<button style="float:right" class="btn btn-outline-primary" (click)="openInvoice(jobHistory.invoiceId)">Open Invoice</button>
			</div>-->
			<div class="d-flex mt-4">
				<div class="flex-fill me-2" style="min-width: 33%; max-width: 33%; border-right: 2px solid var(--bs-gray-300);">
					<label style="font-size: .7rem;">Description</label>
					<div class="ps-2 pe-1">
						{{ jobHistory.appointmentDescription }}
					</div>
				</div>
				<div class="flex-fill me-2" style="min-width: 33%; max-width: 33%; border-right: 2px solid var(--bs-gray-300);">
					<label style="font-size: .7rem;">Customer Notes</label>
					<div class="ps-2 pe-1">
						{{ jobHistory.appointmentCustomerNotes}}
					</div>
				</div>
				<div class="flex-fill">
					<label style="font-size: .7rem;">Tech Notes</label>
					<div class="ps-2">
						{{ jobHistory.appointmentNotes }}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>