import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter, QueryList, ContentChildren, AfterViewInit, OnDestroy, ElementRef, forwardRef } from "@angular/core";
import { SlickSleepService } from "../utils/slick-sleep.service";
import { SlickUtilsService } from "../utils/slick-utils.service";
import { ISlickTabModel } from "./slick-tab.model";
import { SlickTabComponent } from "./slick-tab.component";
import { Subscription } from "rxjs";

@Component({
	selector: 'slick-tabs',
	templateUrl: 'slick-tabs.component.html'
})
export class SlickTabsComponent implements OnChanges, AfterViewInit, OnDestroy {
	@Input("tabs") tabModels: ISlickTabModel[];
	@Input() hideBorder: boolean = false;
	@Input() tabIndex: number = 0;
	@Output() tabIndexChange: EventEmitter<number> = new EventEmitter();
	@Output() tabChange: EventEmitter<string> = new EventEmitter();

	@ContentChildren(forwardRef(() => SlickTabComponent)) slickTabs: QueryList<SlickTabComponent>;

	uuid: string;
	private savedTabChildren: SlickTabComponent[] = [];
	private slickTabs$: Subscription;

	constructor() {		
		this.uuid = SlickUtilsService.newGuid();		
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.hideBorder && changes.hideBorder.currentValue)
			this.hideBorder = (changes.hideBorder.currentValue.toString().toLowerCase() !== 'false');

		if (changes.tabIndex && changes.tabIndex.firstChange === false)
			this.slickTabs.toArray()[changes.tabIndex.currentValue].onTabClicked();
	}

	async ngAfterViewInit() {
		this.slickTabs.forEach(tab => this.processTab(tab));
		await SlickSleepService.sleep();
		if (this.slickTabs.length > 0 && this.tabIndex < this.slickTabs.length)
			this.slickTabs.toArray()[this.tabIndex].onTabClicked();

		await SlickSleepService.sleep();
		if (this.slickTabs && this.slickTabs.changes) {
			this.slickTabs$ = this.slickTabs.changes.subscribe(async (changes) => {
				let tabsAdded = changes.filter(changedTab => !this.savedTabChildren.find(savedTabChild => savedTabChild.uuid === changedTab.uuid));
				let tabsRemoved = this.savedTabChildren.filter(savedTabChild => !changes.find(changedTab => savedTabChild.uuid === changedTab.uuid));

				tabsRemoved.forEach(x => {
					document.getElementById("tabBody_" + x.uuid).style.display = "none";
					x.selected = false;
				});

				tabsAdded.forEach(t => this.processTab(t));

				await SlickSleepService.sleep();

				if (this.slickTabs.length > 0 && this.tabIndex < this.slickTabs.length)
					this.slickTabs.toArray()[this.tabIndex].onTabClicked();
			});
		}
	}

	private processTab(tab: SlickTabComponent) {
		var existingTab = this.savedTabChildren.find(x => x.uuid === tab.uuid);
		if (!existingTab) {
			this.savedTabChildren.push(tab);
			tab.parentComponent = this;
		}
	}

	onTabSelected(uuid: string) {
		let tabIndex = 0;		

		this.slickTabs.forEach(x => {
			let tabBody = document.getElementById("tabBody_" + x.uuid);

			if (tabBody) {
				if (x.uuid === uuid) {
					this.tabIndex = tabIndex;
					if (this.tabIndexChange)
						this.tabIndexChange.emit(tabIndex);

					if (this.tabChange) {
						const tabKey = this.getTabKey(tabIndex);
						this.tabChange.emit(tabKey);
					}
				}

				tabIndex++;

				tabBody.style.display = (x.uuid === uuid) ? "inline-block" : "none";
				x.selected = false
			}
		});		
	}

	getTabHeader(index: number): string {
		const tabs = this.slickTabs.toArray();
		if (index > tabs.length)
			return null;

		return tabs[index].header;
	}

	getTabKey(index: number): string {
		const tabs = this.slickTabs.toArray();
		if (index > tabs.length)
			return null;

		return tabs[index].key || tabs[index].header || index.toString();
	}

	ngOnDestroy() {
		if (this.slickTabs$)
			this.slickTabs$.unsubscribe();
	}
}
