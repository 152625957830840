import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BlockedTimeSlotsStorageService, OfflineRequestQueueStorageService, OfflineRequestTypes, ScheduledAppointmentsStorageService, DailyCrewStorageService, ResourceStorageService, SQLiteService, GlobalSettingsStorageService, AppointmentStorageService, IOfflineRequestRecordModel } from "@sqlite";
import { GlobalsService, HttpService } from '@services';
import { IInitModel } from "@services/utils/globals.service";
import { defineCustomElements as jeepSqlite } from 'jeep-sqlite/loader';
import { IAppointmentModel, IBlockedTimeSlotModel, IDailyCrewModel, IDailyPreChecklistModel, IScheduledAppointmentModel } from '@models';

import * as moment from 'moment';
import { DevLogsStore } from './devlogs.store';
import { Subject, Subscription, scheduled } from 'rxjs';

@Injectable()
export class OfflineStore {
	private static _status: boolean;

	constructor(private sqlite: SQLiteService,
		private globalSettingsStorageService: GlobalSettingsStorageService,
		private offlineRequestQueueStorageService: OfflineRequestQueueStorageService,
		private resourcesStorageService: ResourceStorageService,
		private dailyCrewStorageService: DailyCrewStorageService,
		private scheduledAppointmentsStorageService: ScheduledAppointmentsStorageService,
		private blockedTimeSlotsStorageService: BlockedTimeSlotsStorageService,
		private appointmentStorageService: AppointmentStorageService,
		private httpService: HttpService,
		private httpClient: HttpClient,
		private devLogsStore: DevLogsStore) {

	}

	public async init() {
		try {
			await this.sqlite.initializePlugin();

			if (GlobalsService.isDesktop) {
				// Web platform
				// required for jeep-sqlite Stencil component
				// to use a SQLite database in Browser
				jeepSqlite(window);

				const jeepEl = document.createElement("jeep-sqlite");
				document.body.appendChild(jeepEl);
				await customElements.whenDefined('jeep-sqlite');
				jeepEl.autoSave = true;

				await this.sqlite.initWebStore();
			}

			//this.devLogsStore.addMessage(`Init GlobalSettings`);
			await this.globalSettingsStorageService.initializeDatabase();
			//this.devLogsStore.addMessage(`Init GlobalSettings Complete`);
			//this.devLogsStore.addMessage(`Init OfflineRequestQueue`);
			await this.offlineRequestQueueStorageService.initializeDatabase();
			//this.devLogsStore.addMessage(`Init OfflineRequestQueue Complete`);
			//this.devLogsStore.addMessage(`Init Resources`);
			await this.resourcesStorageService.initializeDatabase();
			//this.devLogsStore.addMessage(`Init Resources Complete`);
			//this.devLogsStore.addMessage(`Init DailyCrew`);
			await this.dailyCrewStorageService.initializeDatabase();
			//this.devLogsStore.addMessage(`Init DailyCrew Complete`);
			//this.devLogsStore.addMessage(`Init ScheduledAppointments`);
			await this.scheduledAppointmentsStorageService.initializeDatabase();
			//this.devLogsStore.addMessage(`Init ScheduledAppointments Complete`);
			//this.devLogsStore.addMessage(`Init BlockedTimeSlots`);
			await this.blockedTimeSlotsStorageService.initializeDatabase();
			//this.devLogsStore.addMessage(`Init BlockedTimeSlots Complete`);
			//this.devLogsStore.addMessage(`Init Appointment`);
			await this.appointmentStorageService.initializeDatabase();
			//this.devLogsStore.addMessage(`Init Appointment Complete`);

			//this.devLogsStore.addMessage(`Getting all databases`);
			await this.globalSettingsStorageService.test();

			const currentVersion = parseInt(localStorage.getItem("OFFLINE_DATABASE_VERSION") || '0');
			if (currentVersion !== GlobalsService.databaseVersion) {
				localStorage.setItem("OFFLINE_DATABASE_VERSION", GlobalsService.databaseVersion.toString());
				await this.deleteDatabases();
			}

			//this.devLogsStore.addMessage(`sqlite.initializePlugin: init complete`);

			//if they are online, process the requests
			if (GlobalsService.isOnline)
				this.processOfflineRequestsOnStatusChange();
		}
		catch (err) {
			this.devLogsStore.addMessage(`sqlite.initializePlugin: ${(err as Error)?.message}`);
		}

	}

	private offlineRequestsProcessedSubject: Subject<void> = new Subject<void>();

	get offlineRequestsProcessedStore(): Subject<void> {
		return this.offlineRequestsProcessedSubject;
	}

	public async deleteDatabases(): Promise<void> {
		this.devLogsStore.addMessage(`Getting all databases`);
		await this.globalSettingsStorageService.test();

		this.devLogsStore.addMessage(`Deleting databases`);
		await this.globalSettingsStorageService.deleteDatabase();
		await this.offlineRequestQueueStorageService.deleteDatabase();
		await this.resourcesStorageService.deleteDatabase();
		await this.dailyCrewStorageService.deleteDatabase();
		await this.scheduledAppointmentsStorageService.deleteDatabase();
		await this.blockedTimeSlotsStorageService.deleteDatabase();
		await this.appointmentStorageService.deleteDatabase();

		await this.globalSettingsStorageService.initializeDatabase();
		await this.offlineRequestQueueStorageService.initializeDatabase();
		await this.resourcesStorageService.initializeDatabase();
		await this.dailyCrewStorageService.initializeDatabase();
		await this.scheduledAppointmentsStorageService.initializeDatabase();
		await this.blockedTimeSlotsStorageService.initializeDatabase();
		await this.appointmentStorageService.initializeDatabase();
	}

	public async getGlobalSettings(): Promise<IInitModel> {
		let globalSettings: IInitModel;
		if (GlobalsService.isOnline) {
			globalSettings = <IInitModel>(
				await this.httpClient.get(`/init/init?platform=${GlobalsService.getPlatform()}&version=${GlobalsService.appVersion}`).toPromise()
			);

			await this.globalSettingsStorageService.updateGlobalSettings(globalSettings);
		}
		else {
			globalSettings = await this.globalSettingsStorageService.getGlobalSettings();
		}

		return globalSettings;
	}

	public async addOfflineRequest(offlineRequestType: OfflineRequestTypes | string, data: any): Promise<void> {

		if (typeof (offlineRequestType) === 'string') {
			switch (offlineRequestType) {
				case "In Transit":
					offlineRequestType = OfflineRequestTypes.InTransit;
					break;

				case "At Jobsite":
					offlineRequestType = OfflineRequestTypes.AtJobsite;
					break;

				case "Not Finished":
					offlineRequestType = OfflineRequestTypes.NotFinished;
					break;

				default:
					offlineRequestType = OfflineRequestTypes.Complete;
					break;
			}
		}

		await this.offlineRequestQueueStorageService.addOfflineRequest(<OfflineRequestTypes>offlineRequestType, data);
	}

	private statusChangedSubject: Subject<boolean> = new Subject<boolean>();

	get status(): boolean {
		return OfflineStore._status;
	}

	set status(status: boolean) {
		OfflineStore._status = status
		this.statusChangedSubject.next(status);
	}
	get statusStore(): Subject<boolean> {
		return this.statusChangedSubject;
	}

	async getAllOfflineRequests(): Promise<IOfflineRequestRecordModel[]> {
		return this.offlineRequestQueueStorageService.getAllOfflineRequests();
	}

	async clearAllOfflineRequests(): Promise<IOfflineRequestRecordModel[]> {
		return this.offlineRequestQueueStorageService.clearAllOfflineRequests();
	}

	async processOfflineRequest(offlineRequestRecord: IOfflineRequestRecordModel): Promise<boolean> {
		return this.offlineRequestQueueStorageService.processOfflineRequest(offlineRequestRecord);
	}

	async processOfflineRequestsOnStatusChange(): Promise<void> {
		if (GlobalsService.isOnline === false) {
			return;
		}

		let offlineRequests = await this.offlineRequestQueueStorageService.getAllOfflineRequests();
		let loopProtection = 1000;

		while (offlineRequests.length > 0 && loopProtection-- > 0 && GlobalsService.isOnline) {
			const offlineRequest = offlineRequests[0];

			try {
				await this.processOfflineRequest(offlineRequest);
				offlineRequests = offlineRequests.filter(x => x.requestUuid !== offlineRequest.requestUuid);
			} catch (error) {
				this.devLogsStore.addMessage(`Error processing offline request: ${error}`);
			}
		}

		if (loopProtection <= 0) {
			this.devLogsStore.addMessage('Loop protection triggered, check for potential issues in processing offline requests.');
		}

		this.offlineRequestsProcessedSubject.next();
	}


	public async getScheduledAppointmentsForCurrentUser(scheduledDate: Date, userId: number): Promise<IScheduledAppointmentModel[]> {
		scheduledDate = moment(scheduledDate).startOf("day").toDate();
		let scheduledAppointments: IScheduledAppointmentModel[] = [];
		if (GlobalsService.isOnline) {
			const params = {
				startDate: moment(scheduledDate).startOf("day").format('YYYY-MM-DD'),
				endDate: moment(scheduledDate).startOf("day").add(1, "days").format('YYYY-MM-DD')
			};

			scheduledAppointments = await this.httpService.post('/appointments/getScheduledAppointmentsForCurrentUser', params);
			await this.scheduledAppointmentsStorageService.updateScheduledAppointmentsForUser(scheduledDate, userId, scheduledAppointments);

			await this.getAppointments(scheduledAppointments);
		}
		else {
			scheduledAppointments = await this.scheduledAppointmentsStorageService.getScheduledAppointmentsForUser(scheduledDate, userId);
		}

		return scheduledAppointments;
	}

	public async getScheduledAppointmentsForDateRange(startDate: Date, endDate: Date, loadAppointments: boolean): Promise<IScheduledAppointmentModel[]> {
		startDate = moment(startDate).startOf('day').toDate();
		endDate = moment(endDate).startOf('day').toDate();
		let scheduledAppointments: IScheduledAppointmentModel[] = [];
		
		if (GlobalsService.isOnline) {
			const params = {
				startDate: moment(startDate).startOf('day').format('YYYY-MM-DD'),
				endDate: moment(endDate).startOf('day').format('YYYY-MM-DD')
			};

			scheduledAppointments = await this.httpService.get('/appointments/getScheduledAppointmentsForDateRange', params);
			await this.scheduledAppointmentsStorageService.updateScheduledAppointments(startDate, endDate, scheduledAppointments);
			if (loadAppointments) {
				this.getAppointments(scheduledAppointments);
			}
		}
		else {
			scheduledAppointments = await this.scheduledAppointmentsStorageService.getScheduledAppointments(startDate, endDate);
		}

		return scheduledAppointments;
	}


	public async getBlockedTimeSlotsForCurrentUser(scheduledDate: Date, userId: number): Promise<IBlockedTimeSlotModel[]> {
		scheduledDate = moment(scheduledDate).startOf("day").toDate();
		let blockedTimeSlots: IBlockedTimeSlotModel[] = [];
		if (GlobalsService.isOnline) {
			const params = {
				startDate: moment(scheduledDate).startOf("day").format('YYYY-MM-DD'),
				endDate: moment(scheduledDate).startOf("day").add(1, "days").format('YYYY-MM-DD')
			};

			blockedTimeSlots = await this.httpService.get('/appointments/getBlockedTimeSlotsForCurrentUser', params);
			await this.blockedTimeSlotsStorageService.updateBlockedTimeSlotsForUser(scheduledDate, userId, blockedTimeSlots);
		}
		else {
			blockedTimeSlots = await this.blockedTimeSlotsStorageService.getBlockedTimeSlotsForUser(scheduledDate, userId);
		}

		return blockedTimeSlots;
	}

	public async getBlockedTimeSlotsForDateRange(startDate: Date, endDate: Date): Promise<IBlockedTimeSlotModel[]> {
		startDate = moment(startDate).startOf('day').toDate();
		endDate = moment(endDate).startOf('day').toDate();
		let blockedTimeSlots: IBlockedTimeSlotModel[] = [];

		if (GlobalsService.isOnline) {
			const params = {
				startDate: moment(startDate).startOf('day').format('YYYY-MM-DD'),
				endDate: moment(endDate).startOf('day').format('YYYY-MM-DD')
			};

			blockedTimeSlots = await this.httpService.get('/appointments/getBlockedTimeSlots', params);
			await this.blockedTimeSlotsStorageService.updateBlockedTimeSlots(startDate, endDate, blockedTimeSlots);
		}
		else {
			blockedTimeSlots = await this.blockedTimeSlotsStorageService.getBlockedTimeSlots(startDate, endDate);
		}

		return blockedTimeSlots;
	}

	public async getDailyPreChecklistForUser(scheduledDate: Date, userId: number): Promise<IDailyPreChecklistModel> {
		if (GlobalsService.company.useInventory === false)
			return null;

		if (GlobalsService.isOnline) {
			const params = {
				userId: userId,
				scheduledDate: moment(scheduledDate).format("MM/DD/yyyy")
			}

			return this.httpService.get(`dailyPreChecklist/getDailyPreChecklistForUser`, params);
		}
	}


	public async getDailyCrewsForDate(scheduledDate: Date): Promise<IDailyCrewModel[]> {
		scheduledDate = moment(scheduledDate).startOf("day").toDate();
		let dailyCrews: IDailyCrewModel[] = [];
		if (GlobalsService.isOnline) {
			const params = {
				date: moment(scheduledDate).format('YYYY-MM-DD')
			}
			dailyCrews = await this.httpService.get("/crews/getDailyCrewsForDate", params);
			await this.dailyCrewStorageService.updateDailyCrews(scheduledDate, dailyCrews);
		}
		else {
			dailyCrews = await this.dailyCrewStorageService.getDailyCrews(scheduledDate);
		}

		return dailyCrews;
	}

	async getAppointments(scheduledAppointments: IScheduledAppointmentModel[]): Promise<void> {
		if (!(moment(scheduledAppointments[0]?.scheduledDateTime).startOf("day").isSame(moment().startOf("day"), "day")))
			return;

		if (scheduledAppointments.length > 25) {
			this.getBatchAppointments(scheduledAppointments);
		}
		else {
			const appointmentLoadPromises: Promise<IAppointmentModel>[] = [];
			for (let scheduledAppointment of scheduledAppointments) {
				appointmentLoadPromises.push(this.getAppointment(scheduledAppointment.appointmentId));
			}

			//// If we are offline, wait for these to load before we return
			//if (!GlobalsService.isOnline)
			Promise.all(appointmentLoadPromises);
		}
	}

	async getBatchAppointments(scheduledAppointments: IScheduledAppointmentModel[]): Promise<void> {
		if (GlobalsService.isOnline) {
			let allAppointments: IAppointmentModel[] = [];
			let batchNumber = 0;
			let hasMoreAppointments = true;
			let appointmentIdsBatch: number[] = [];  // Array to hold appointment IDs for each batch

			// Safe Guard
			let loopCount = 0;
			// Loop through the appointments in batches of 10
			while (hasMoreAppointments && loopCount < 50) {
				// Get the next 10 appointment IDs (or fewer if it's the last batch)
				appointmentIdsBatch = scheduledAppointments.slice(batchNumber * 10, (batchNumber + 1) * 10).map(appt => appt.appointmentId);

				if (appointmentIdsBatch.length === 0) {
					// If there are no appointment IDs left, stop
					hasMoreAppointments = false;
					break;
				}

				// Build the parameters for the batch request
				const batchParams = {
					apptIds: appointmentIdsBatch  // Pass the appointment IDs for the current batch
				};

				// Make the request to fetch the appointments for the current batch
				const batchAppointments: IAppointmentModel[] = await this.httpService.get("appointments/getBatchAppointments", batchParams);

				// Update local storage with the batch of appointments
				this.appointmentStorageService.updateAppointments(batchAppointments);

				// Add the fetched appointments to the allAppointments array
				allAppointments = allAppointments.concat(batchAppointments);

				// Increment the batch number for the next loop iteration
				batchNumber++;

				// If fewer than 10 records are returned, stop, indicating no more appointments
				hasMoreAppointments = batchAppointments.length === 10;

				loopCount++;
			}
		}
	}





	async getAppointment(appointmentId: number): Promise<IAppointmentModel> {
		let appointment: IAppointmentModel;

		if (GlobalsService.isOnline) {
			const params = {
				appointmentId: appointmentId,
				includeJob: true
			}
			appointment = await this.httpService.get("/appointments/getAppointment", params);
			await this.appointmentStorageService.updateAppointment(appointmentId, appointment);
		}
		else {
			appointment = await this.appointmentStorageService.getAppointment(appointmentId);
		}

		return appointment;
	}
}
