import { ICustomerModel } from "../customers/customer.model";
import * as moment from 'moment';
import { iCalModel } from "../recurring/iCal.model";
import { LineItemModel } from "../line-items/line-item.model";

export interface ISkeditPayRecurringPaymentModel {
    skeditPayRecurringPaymentId: number;
    recurringName: string;
    customerId: number;
    startDate: Date;
    endDate: Date;
    lastUpdated: Date;
    customer: ICustomerModel;
    iCalBilling: iCalModel;
    lastExtendedDate: Date;
    notes: string;
    creditCardPaymentTypeId: number;
    isPaused: boolean;
    nextPaymentDate?: Date;
    lastTransactionIsSuccess: boolean;
    lastTransactionStatusMessage: string;
    createdDate: Date;
    amount: number;
    syncToQuickbooks: boolean;
}

export class SkeditPayRecurringPaymentModel implements ISkeditPayRecurringPaymentModel {
    constructor() {
        this.startDate = moment().startOf("day").toDate();
        this.iCalBilling = new iCalModel();
    }
    skeditPayRecurringPaymentId: number;
    recurringName: string;
    customerId: number;
    startDate: Date;
    endDate: Date;
    lastUpdated: Date;
    customer: ICustomerModel;
    iCalBilling: iCalModel;
    lastExtendedDate: Date;
    notes: string;
    creditCardPaymentTypeId: number;
    isPaused: boolean;
    nextPaymentDate?: Date;
    lastTransactionIsSuccess: boolean;
    lastTransactionStatusMessage: string;
    createdDate: Date;
    amount: number;
    syncToQuickbooks: boolean;

}