import { Injectable } from '@angular/core';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { DevLogsStore } from '@stores';
import { IBlockedTimeSlotModel } from '@models';
import { SQLiteService } from '@sqlite';
import { DevTraceService } from '@services/utils/dev-trace.service';
import * as moment from "moment";


interface IBlockedTimeSlotRecord {
	hashKey: string;
	blockedTimeSlots: string;
}

@Injectable()
export class BlockedTimeSlotsStorageService {
	private db!: SQLiteDBConnection;

	readonly databaseName: string = 'blockedTimeSlots';

	constructor(private devLogsStore: DevLogsStore,
		private devTrace: DevTraceService,
		private sqliteService: SQLiteService) {
	}

	async initializeDatabase(): Promise<void> {
		try {
			await this.sqliteService
				.addUpgradeStatement({
					database: this.databaseName,
					upgrade: this.blockedTimeSlotsUpdates
				});

			// create and/or open the database
			const loadToVersion = this.blockedTimeSlotsUpdates[this.blockedTimeSlotsUpdates.length - 1].toVersion;
			this.db = await this.sqliteService.openDatabase(this.databaseName, loadToVersion);
			this.sqliteService.setVersion(this.databaseName, loadToVersion);
			//this.devLogsStore.addMessage(`Init ${this.databaseName} database to version ${loadToVersion}`);
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	async deleteDatabase(): Promise<void> {
		await this.sqliteService.deleteDatabase(this.databaseName);
		//this.devLogsStore.addMessage(`Deleted ${this.databaseName}`);
	}

	async updateBlockedTimeSlots(startDate: Date, endDate: Date, blockedTimeSlots: IBlockedTimeSlotModel[]) {
		try {
			const hashKey = `${moment(startDate).format("YYYYMMDD")}_${moment(endDate).format("YYYYMMDD")}`
			//this.devLogsStore.addMessage(`Adding blockedTimeSlot record for date ${hashKey}`);

			const blockedTimeSlotsEncoded = SQLiteService.encodeJSON(blockedTimeSlots);

			let allRecords = (await this.db.query(`SELECT * FROM blockedTimeSlots`)).values as IBlockedTimeSlotRecord[];

			const currentDate = new Date();
			const currentDateString = currentDate.toISOString().split('T')[0].replace(/-/g, ''); // Convert to YYYYMMDD format

			const recordsToKeep = allRecords.filter(record => {
				const [firstPart, secondPart] = record.hashKey.split('_');

				// Check if both parts of the hashKey are dates (YYYYMMDD format)
				const isDateRange = /^\d{8}$/.test(firstPart) && /^\d{8}$/.test(secondPart);
				if (!isDateRange) {
					return true; // Keep records that do not follow the YYYYMMDD_YYYYMMDD format
				}

				// If it's a date range, check if the current date is within the range
				return currentDateString >= firstPart && currentDateString <= secondPart;
			});

			const hashKeysToKeep = recordsToKeep.map(record => record.hashKey);

			for (const record of allRecords) {
				if (!hashKeysToKeep.includes(record.hashKey)) {
					//this.devLogsStore.addMessage(`Deleting blockedTimeSlots records for ${record.hashKey}`);

					await this.db.query(`DELETE FROM blockedTimeSlots WHERE hashKey = ?`, [record.hashKey]);
				}
			}

			await this.db.run(`delete from blockedTimeSlots where hashKey='${hashKey}';`);

			const sql = `INSERT INTO blockedTimeSlots (hashKey, blockedTimeSlots) VALUES ('${hashKey}', '${blockedTimeSlotsEncoded}');`;
			await this.db.run(sql);
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}
	async updateBlockedTimeSlotsForUser(scheduledDate: Date, userId: number, blockedTimeSlots: IBlockedTimeSlotModel[]) {
		try {
			const currentFormattedDate = moment().startOf("date").format("YYYYMMDD");
			const hashKey = `${moment(scheduledDate).format("YYYYMMDD")}_${userId}`

			//this.devLogsStore.addMessage(`Deleting blockedTimeSlots records for all but ${hashKey}`);

			// Delete all records for the current user that are not for current date
			await this.db.run(`
            DELETE FROM blockedTimeSlots 
            WHERE hashKey LIKE '%_${userId}' 
            AND hashKey NOT LIKE '${currentFormattedDate}_%';
        `);

			//this.devLogsStore.addMessage(`Adding blockedTimeSlot record for ${hashKey}`);

			const blockedTimeSlotsEncoded = SQLiteService.encodeJSON(blockedTimeSlots);

			await this.db.run(`delete from blockedTimeSlots where hashKey='${hashKey}';`);

			const sql = `INSERT INTO blockedTimeSlots (hashKey, blockedTimeSlots) VALUES ('${hashKey}', '${blockedTimeSlotsEncoded}');`;
			await this.db.run(sql);
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}


    async getBlockedTimeSlots(startDate: Date, endDate: Date): Promise<IBlockedTimeSlotModel[]> {
        try {
            const hashKey = `${moment(startDate).format("YYYYMMDD")}_${moment(endDate).format("YYYYMMDD")}`

            const blockedTimeSlotsRecords: IBlockedTimeSlotRecord[] = (await this.db.query(`SELECT * FROM blockedTimeSlots where hashkey='${hashKey}';`)).values as IBlockedTimeSlotRecord[];

            if ((blockedTimeSlotsRecords?.length ?? 0) === 0)
                return [];

            const blockedTimeSlotsEncoded = blockedTimeSlotsRecords[0].blockedTimeSlots;
            const blockedTimeSlotsJSON = SQLiteService.decodeJSON(blockedTimeSlotsEncoded)
            const blockedTimeSlots: IBlockedTimeSlotModel[] = JSON.parse(blockedTimeSlotsJSON);

            //this.devLogsStore.addMessage(`Returning JSON ${blockedTimeSlotsJSON}`);

            return blockedTimeSlots;
        }
        catch (err) {
            this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
        }
    }

	async getBlockedTimeSlotsForUser(scheduledDate: Date, userId: number): Promise<IBlockedTimeSlotModel[]> {
		try {
			const hashKey = `${moment(scheduledDate).format("YYYYMMDD")}_${userId}`

			const blockedTimeSlotsRecords: IBlockedTimeSlotRecord[] = (await this.db.query(`SELECT * FROM blockedTimeSlots where hashkey='${hashKey}';`)).values as IBlockedTimeSlotRecord[];

			if ((blockedTimeSlotsRecords?.length ?? 0) === 0)
				return [];

			const blockedTimeSlotsEncoded = blockedTimeSlotsRecords[0].blockedTimeSlots;
			const blockedTimeSlotsJSON = SQLiteService.decodeJSON(blockedTimeSlotsEncoded)
			const blockedTimeSlots: IBlockedTimeSlotModel[] = JSON.parse(blockedTimeSlotsJSON);

			//this.devLogsStore.addMessage(`Returning JSON ${blockedTimeSlotsJSON}`);

			return blockedTimeSlots;
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	readonly blockedTimeSlotsUpdates = [
		{
			toVersion: 1,
			statements: [
				`
CREATE TABLE IF NOT EXISTS blockedTimeSlots(
    hashKey string PRIMARY KEY,
    blockedTimeSlots TEXT NULL
);`
			]
		},
		/* add new statements below for next database version when required*/
		/*
		{
		toVersion: 2,
		statements: [
			`ALTER TABLE users ADD COLUMN email TEXT;`,
		]
		},
		*/
	]
}


