<div class="mx-2 my-1">
	<div class="input-group">
		<span class="input-group-text"><i class="far fa-search"></i></span>
		<input [(ngModel)]="searchString" class="form-control me-1" placeholder="Search..." (keyup)="filterUsers()" />
		<button style="min-width:50px" class="btn btn-outline-primary me-1" (click)="createNewChat()"><i class="far fa-plus"></i> Add</button>
	</div>
    <!--<input type="checkbox" (click)="onShowDeletedGroups()" [(ngModel)]="showDeletedGroups"  />Show Deleted-->
	
	
</div>

<table class="table table-striped table-sm table-hover table-bordered" >
    <tr *ngFor="let chatGroup of visibleChatGroups" (click)="openUserChat(chatGroup)">
        <td title="{{chatGroup?.longAttendantsString}}" class="px-2" [ngClass]="{ 'bg-success text-white': !isLegacy && selectedChatGroup?.uuid === chatGroup.uuid }">
            <div class="d-flex align-items-center justify-content-between" style="line-height: 1rem; max-width: 270px; pointer-events: none">
                <div class="me-2 d-flex">
                    <ng-container *ngIf="!chatGroup.isCompanyChat && !chatGroup.teamId">

                        <div *ngFor="let attendant of chatGroup.chatGroupAttendantsForList; let idx = index" [ngClass]="{ 'chat-user-img-offset': idx > 0 }">
                            <img [src]="attendant.profilePictureUrl" style="border: 1px solid white; border-radius: 60px; min-width: 40px; max-width: 40px; min-height: 40px; max-height: 40px; border-radius: 50%;" />
                        </div>
                    </ng-container>

                    <div>
                        <img *ngIf="chatGroup.isCompanyChat || chatGroup.teamId > 0" [src]="chatGroup.companyProfilePicture" style="border: 1px solid white; border-radius: 60px; min-width: 40px; max-width: 40px; min-height: 40px; max-height: 40px; border-radius: 50%;" />
                    </div>


                </div>
                <div class="flex-fill" style="max-width:calc(80%)" >
                    <div class="d-flex">
                        <div style="min-width:calc(95%)">

                            {{chatGroup.attendantsString}}
                        </div>
                        <div *ngIf="chatGroup.unreadMessages" class="badge badge-danger bg-danger py-1 justify-content-end" style="line-height: 1">{{chatGroup.unreadMessages}}</div>
                    </div>
                    <div class="d-flex mt-2">

                         <div *ngIf='chatGroup.messages && chatGroup.lastMessage && chatGroup.chatGroupAttendantsForList.length === 1' class="ellipses" style="font-size: .7rem; line-height: .8rem; width: 230px;" [ngStyle]="{'color': chatGroup?.id === selectedChatGroup?.id && !isLegacy ? 'white' : 'black'}">{{chatGroup.lastMessage.message}}</div>
                            <div *ngIf='chatGroup.messages && chatGroup.lastMessage && chatGroup.chatGroupAttendantsForList.length === 2' class="ellipses" style="font-size: .7rem; line-height: .8rem; width: 225px;"  [ngStyle]="{'color': chatGroup?.id === selectedChatGroup?.id && !isLegacy ? 'white' : 'black'}">{{chatGroup.lastMessage.message}}</div>
                            <div *ngIf='chatGroup.messages && chatGroup.lastMessage && chatGroup.chatGroupAttendantsForList.length === 3' class="ellipses" style="font-size: .7rem; line-height: .8rem; width:160px;" [ngStyle]="{'color': chatGroup?.id === selectedChatGroup?.id && !isLegacy ? 'white' : 'black'}">{{chatGroup.lastMessage.message}}</div>
                            <div class="d-flex justify-content-end" *ngIf="chatGroup.lastSentMessageTimeString" style="font-size: .7rem; line-height: .8rem; min-width:50px;">{{chatGroup.lastSentMessageTimeString}}</div>
                    </div>
                </div>

            </div>
        </td>
    </tr>
</table>

<chat-group-attendants #chatGroupAttendantsRef></chat-group-attendants>