import { Injectable } from "@angular/core";
import { GlobalsService } from "@services";
import { ThemeStore } from "@stores";

@Injectable()
export class ThemeService {
	constructor(private themeStore: ThemeStore) { }
	loadTheme(themeName = null): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			document.body.style.visibility = "hidden";

			if (!themeName)
				themeName = localStorage.getItem("theme") || "default"

			if (themeName === "undefined")
				themeName = "default";

			// GlobalsService.userInfo.theme = themeName;

			(<any>document.getElementById('user-theme-overrides')).href = "/content/themes/" + themeName + "-overrides.css";
			(<any>document.getElementById('user-theme')).href = "/content/themes/" + themeName + "/bootstrap.min.css";

			// A little hacky, but there's no good way to know when the style has loaded,
			// so we check the stylesheets to see if the one we want is loaded 
			let infLoopCheck = 500;
			let themeVisibleInterval: NodeJS.Timer = setInterval(() => {
				let bootstrapStyleSheet;
				for (let i = 0; i < document.styleSheets.length; i++) {
					if (document.styleSheets.item(i).href && document.styleSheets.item(i).href.indexOf("/content/themes/" + themeName + "/bootstrap.min.css") >= 0)
						bootstrapStyleSheet = document.styleSheets.item(i);
				}
				infLoopCheck--;
				if (infLoopCheck === 0 || bootstrapStyleSheet) {
					if (infLoopCheck === 0)
						console.error("theme loader timed out");
					document.body.style.visibility = "visible";
					clearInterval(themeVisibleInterval);
					this.themeStore.setTheme(themeName);
					resolve();
				}
			}, 100)
		})
	}
}
