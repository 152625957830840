<slick-dialog key="DIALOG_CUSTOMER_EDIT" header="Customer" height="1000" width="1200" #dialogRef>
	<div *ngIf="customerModel">
		<customer-edit #customerEditRef [customerModel]="customerModel" isDialog="true"></customer-edit>
	</div>
	<slick-dialog-footer>
		<div class="d-flex ">
			<button class="save-button btn btn-outline-success save-button me-3" (click)="onSave()" [slick-button-spinner]="spinnerStatus">
				<i class="bi bi-check-circle"></i> Save
			</button>
			<button class="cancel-button btn btn-outline-danger save-button" (click)="onCancel()">
				<i class="bi bi-x-circle"></i> Cancel
			</button>
			<div class="ms-auto">
				<div *ngIf="!skeditPay">
					<div *ngIf="customerModel?.acceptedTerms === true" class="mt-2">
						<i class="bi bi-check-circle-fill text-success"></i>&nbsp;<b>Service Agreement Signed</b>
					</div>
					<div *ngIf="customerModel?.acceptedTerms === false">
						<button type="button" style="width: 280px;" class="btn btn-outline-primary" [slick-button-spinner]="agreementspinnerStatus" (click)="sendServiceAgreement()">
							<i class="bi bi-envelope"></i> Send Service Agreement
						</button>
					</div>
				</div>
			</div>
		</div>

	</slick-dialog-footer>
</slick-dialog>