import { Injectable } from "@angular/core"; 
import { HttpService } from "./utils/http.service";
import { GlobalsService } from "./utils/globals.service";
import { ILathamOrderModel, ILathamCarrierModel, IAddressModel, ILathamOrderFavoriteModel, IEmailerModel, ICustomerModel, ILathamOrderGridModel, ILathamQCPhotoModel, ILineItemModel, LineItemTypes, LathamOrderModel } from "@models";
import { ISlickGridRequestModel, SlickGridRequestModel, ISlickGridResponseModel } from "@slick-components";
import { UtilsService } from "./utils/utils.service";
import { TexterModel } from "../models/texter/texter.model";

@Injectable()
export class LathamOrdersService {

	constructor(private readonly httpService: HttpService) { }

	async getNewLathamOrder(): Promise<ILathamOrderModel> {
		
		const orderIds = await this.getNextOrderId();
		const newOrder = await this.getEmptyLathamOrder();

		// orderIds is list with [acutalOrderId, orderId]
		[newOrder.actualOrderId, newOrder.orderId] = orderIds;

		switch (GlobalsService.company.orderingType) {
			case "PCS":
				newOrder.orderSpecs.system.brandType = 'PCS';
				break;

			case "Coverstar":
				newOrder.orderSpecs.system.brandType = 'Coverstar';
				break;
		}

		return newOrder;
	}

	async deleteLathamOrder(orderId: string): Promise<boolean> {
		return this.httpService.delete("/lathamOrders/deleteLathamOrder", { orderId: orderId });
	}

	async copyOrder(originalOrderModel: ILathamOrderModel): Promise<ILathamOrderModel> {
		const orderCopy = await this.getNewLathamOrder();

		orderCopy.orderSpecs = UtilsService.clone(originalOrderModel.orderSpecs);
		orderCopy.orderNotes = originalOrderModel.orderNotes;
		orderCopy.originalOrderId = originalOrderModel.orderId;
		orderCopy.orderSpecs.proration.prorationSqft = originalOrderModel.orderSpecs.system.squareFeet;
		if ((originalOrderModel.lathamShipDate < originalOrderModel.submittedDate) || !originalOrderModel.lathamShipDate)
			orderCopy.orderSpecs.proration.prorationOriginalOrderDate = originalOrderModel.submittedDate;
		else
			orderCopy.orderSpecs.proration.prorationOriginalOrderDate = originalOrderModel.lathamShipDate;
		

		// If this is an archived order, we need to create the customer
		if (originalOrderModel.orderStatus === "Archived")
			orderCopy.orderSpecs.homeOwner = await this.getCustomerFromLathamArchiveOrder(originalOrderModel.lathamOrderId);
		
		// Need a new invoice
		// Line items need new guids and make sure the price modified flag is cleared
		orderCopy.invoice.lineItems = originalOrderModel.invoice.lineItems
			.filter(x => x.isSystemLineItem === false)
			.map(li => {
				li.uuid = UtilsService.newGuid();
				li.priceModified = false;
				return li;
			});
		orderCopy.invoice.customer = UtilsService.clone(originalOrderModel.invoice.customer);

		// CS Orders use Undertrack, not 403 Undertrack
		if (originalOrderModel.brandType === 'Coverstar' && originalOrderModel.orderSpecs.track.trackType === "403 Undertrack")
			orderCopy.orderSpecs.track.trackType = "Undertrack";

		return orderCopy;
	}

	async getLathamOrdersForCards(orderStatus: string, searchText: string, orderFilterStatus: string, currentPage: number, itemsPerPage: number): Promise<ISlickGridResponseModel> {
		const lathamOrderGridRequestModel: ISlickGridRequestModel = new SlickGridRequestModel();
		lathamOrderGridRequestModel.page = currentPage;
		lathamOrderGridRequestModel.recordsPerPage = itemsPerPage;
		lathamOrderGridRequestModel.additionalParams = {
			orderStatus: orderStatus,
			searchString: searchText,
			orderFilterStatus: orderFilterStatus
		}

		return this.httpService.post("/lathamorders/getLathamOrdersForCards", lathamOrderGridRequestModel);
    }

	async getLathamOrdersForInfiniteScroll(orderStatus: string, pageCount: number, searchString: string): Promise<ILathamOrderGridModel[]> {
		const params = {
			orderStatus,
			pageCount,
			searchString
		}

		return this.httpService.get("/lathamOrders/getLathamOrdersForInfiniteScroll", params);
	}

    async getLathamOrder(lathamOrderId: number): Promise<ILathamOrderModel> {
        return this.httpService.get("/lathamorders/getLathamOrder", { lathamOrderId: lathamOrderId });
	}

	async getLathamOrderForApproval(companyId: number, lathamOrderId: number): Promise<ILathamOrderModel> {
		return this.httpService.get("/lathamorders/getLathamOrderForApproval", { companyId: companyId, lathamOrderId: lathamOrderId });
	}

	async getLathamOrderByOrderId(orderId: string): Promise<ILathamOrderModel> {
		return this.httpService.get("/lathamorders/getLathamOrderByOrderId", { orderId: orderId });
	}

	async getLathamOrderForGridView(lathamOrderId: number): Promise<ILathamOrderGridModel> {
		return this.httpService.get("/lathamorders/getLathamOrderForGridView", { lathamOrderId: lathamOrderId });
	}

	async getLathamArchivedOrder(lathamArchiveOrderId: number): Promise<ILathamOrderModel> {
		return this.httpService.get("/lathamOrders/getLathamArchivedOrder", { lathamArchiveOrderId: lathamArchiveOrderId });
	}

	async getLathamOrderFromSalesOrder(lathamSalesOrderId): Promise<ILathamOrderModel> {
		return this.httpService.get("/lathamOrders/getLathamOrderFromSalesOrder", { lathamSalesOrderId: lathamSalesOrderId });
	}

	async getCustomerFromLathamArchiveOrder(lathamArchiveOrderId: number): Promise<ICustomerModel> {
		return this.httpService.get("/lathamorders/getCustomerFromLathamArchiveOrder", { lathamArchiveOrderId: lathamArchiveOrderId });
	}

    async getCarriersForList(companyId: number): Promise<ILathamCarrierModel[]> {
        return this.httpService.get("/lathamorders/getCarriersForList", { companyId: companyId });
    }

    async getSavedAddresses(): Promise<IAddressModel[]> { 
        return this.httpService.get("/lathamorders/getSavedAddresses");
    }

    async getNextOrderId(): Promise<string[]> {
        return this.httpService.get("/lathamorders/getNextOrderId");
    }

    async getEmptyLathamOrder(): Promise<ILathamOrderModel> {
        return this.httpService.get("/lathamorders/getEmptyLathamOrder");
    }

    async getLathamOrderFavorites(): Promise<ILathamOrderFavoriteModel[]> {
        return this.httpService.get("/lathamorders/getLathamOrderFavorites");
    }

    async deleteLathamOrderFavorite(lathamOrderFavoriteId: number): Promise<any> {
        return this.httpService.get("/lathamorders/deleteLathamOrderFavorite", { lathamOrderFavoriteId: lathamOrderFavoriteId });
    }

    async updateLathamOrderFavorite(model: ILathamOrderFavoriteModel): Promise<ILathamOrderFavoriteModel> {
        return this.httpService.post("/lathamorders/updateLathamOrderFavorite", model);
    }

    async updateSavedShippingAddress(address: IAddressModel): Promise<IAddressModel> {
        return this.httpService.post("/lathamorders/updateSavedShippingAddress", address);
	}

	async updateJobId(lathamOrderId: number, jobId: number): Promise<boolean> {
		return this.httpService.patch(`/lathamorders/updateJobId?lathamOrderId=${lathamOrderId}`, jobId);
	}

    async updateLathamOrder(model: ILathamOrderModel): Promise<ILathamOrderModel> {
        return this.httpService.post("/lathamorders/updateLathamOrder", model);
	}

	async approveLathamOrder(model: ILathamOrderModel) {
		return this.httpService.post("/lathamorders/approveLathamOrder", model);
	}

	async approveAndSubmitLathamOrder(model: ILathamOrderModel) {
		return this.httpService.post("/lathamorders/approveAndSubmitLathamOrder", model);
	}

	async updateLathamOrderForCSC(model: ILathamOrderModel): Promise<ILathamOrderModel> {
		return this.httpService.post("/lathamorders/updateLathamOrderForCSC", model);
	}

	async submitLathamOrder(model: ILathamOrderModel): Promise<ILathamOrderModel> {
		if (model.isSentry)
			return this.httpService.post("/sentry/submitSentryOrder", model);
		else
			return this.httpService.post("/lathamorders/submitLathamOrder", model);
    }

    async generateQuotePDF(model: ILathamOrderModel): Promise<any> {
        return this.httpService.post("/lathamorders/generateQuotePDF", model);
    }

	async generateQuotePDFNoPricing(model: ILathamOrderModel): Promise<any> {
		return this.httpService.post("/lathamorders/generateQuotePDFNoPricing", model);
	}

	async prepareLathamOrderQuoteEmail(model: ILathamOrderModel, showAcceptQuoteLink: boolean): Promise<IEmailerModel> {
		return this.httpService.post(`/lathamorders/prepareLathamOrderQuoteEmail?showAcceptQuoteLink=${showAcceptQuoteLink}`, model);
	}

	async prepareText(model: ILathamOrderModel): Promise<TexterModel> {
		return this.httpService.post("/lathamorders/prepareText", model);
	}

	async getLathamQCPhotos(lathamOrderId: number): Promise<ILathamQCPhotoModel[]> {
		return this.httpService.get("/lathamorders/getLathamQCPhotos", { lathamOrderId: lathamOrderId });
	}

	async syncLathamQCPhotos(lathamOrderId: number) {
		return this.httpService.get("/lathamorders/syncLathamQCPhotos", { lathamOrderId: lathamOrderId });
	}

	async GetLathamOrdersReports(params): Promise<any>{
		return this.httpService.get("/lathamorders/GetLathamOrders", { CompanyID:params.companyId , startDate:params.startDate , endDate:params.endDate , showTaxesLaborAndShipping:params.showTaxesLaborAndShipping , showQuotes:params.showQuotes , models:params.models });
	}

	async refreshPricing(lineItems: ILineItemModel[], customerId?: number): Promise<ILineItemModel[]> {
		const skus = lineItems
			.filter(x => x.lineItemType === LineItemTypes.LineItem)
			.map(x => x.sku);

		const params = {
			skus,
			customerId: (customerId ?? 0)
		}
		const updatedLineItems = await this.httpService.get('/items/getItemsBySkus', params);

		updatedLineItems.forEach(updatedLineItem => {
			const li = lineItems.find(x => x.sku === updatedLineItem.sku);
			if (!li.costModified)
				li.cost = updatedLineItem.cost;
			if (!li.priceModified)
				li.price = updatedLineItem.price;
		})

		return lineItems;
	}

	async addOrderEmailSentNote(lathamOrderId: number): Promise<void> {
		return this.httpService.patch(`/lathamorders/addOrderEmailSentNote?lathamOrderId=${lathamOrderId}`);
	}

	async prepareBatchOrderEmail(orderId: number, uuid: string): Promise<IEmailerModel> {
		const params = {
			orderId,
			uuid,
		}
		return this.httpService.get("/lathamOrders/prepareBatchOrderEmail", params);
	}

	async printBatchOrders(orderIds: number[], uuid: string): Promise<string> {
		const params = {
			orderIds,
			uuid
		}

		return this.httpService.get("/lathamOrders/GenerateBatchOrdersPdf", params);
	} 

	async deleteBatchLathamOrders(orderIds: string[]): Promise<boolean> {
		return this.httpService.post("/lathamOrders/deleteBatchLathamOrders", orderIds);
	} 

}