import { CustomerTypeModel, CustomerContactModel, ITaxRateGroupModel } from "@models";
import { CustomerOutreachModel } from "./customer-outreach.model";
import { IDiscountModel, IRebateModel } from "@models";
import { ICustomCustomerFieldAnswerModel} from "@models/customers/custom-customer-field-answer.model";
import { UtilsService } from "@services/utils/utils.service";
import { ICustomerMultipleAddressModel } from "./customer-multiple-address.model";
import { GlobalsService } from "@services";

export interface ICustomerModel {
	uuid: string;
	customerId: number;
	companyId: number;
	customerLinkId?: number;
	customerTypeId: number;
	priceLevelId: number;
	customerAreaId?: number;
	taxRegionId?: number;
	salesRepId?: number;
	salesRepFullName: string;
	arRepId?: number;
	arRepFullName: string;
	paymentTermId: number;
	paymentTermName: string;
	paymentTermNotificationPopup: boolean;
	paymentTermNotificationMessage: string;
	documentKey: string;
	customerType: CustomerTypeModel;
	userName: string;
	password: string;
	firstName: string;
    fullName: string;
	displayName: string;
	displayCityStateZip: string;
	quickbooksName: string;
	quickbooksClassId: string;
	quickbooksLocationId: string;
	lastName: string;
	companyName: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	zipcode: string;
	country: string;
	billingCustomerId?: number;
	linkBillingAddress: boolean;
	billingAddress1: string;
	billingAddress2: string;
	billingCity: string;
	billingState: string;
	billingZipcode: string;
	billingCountry: string;
	linkShippingAddress: boolean;
	shippingAddress1: string;
	shippingAddress2: string;
	shippingCity: string;
	shippingState: string;
	shippingZipcode: string;
	shippingCountry: string;
	email: string;
	apEmail: string;
	homePhone: string;
	cellPhone: string;
	workPhone: string;
	fax: string;
	website: string;
	cardOnFile: string;
	taxId: string;
	taxExempt: boolean;
	emailOptOut: boolean;
	textOptOut: boolean;
	creditHold: boolean;
	creditHoldNotes: string;
	criticalCreditHold: boolean;
	childCompanyId?: number;
	childCompanyName: string;
	childCompanyLogin: string;
	childLinkId: number;
	latitude?: string;
	longitude?: string;
	usingGeocodeAddress: boolean;
	acceptedTerms: boolean;
	customerAttentionNote: string;
	customerNotes: string;
	quickbooksId: string;
	syncToQuickbooks?: boolean;
	quickbooksLinkOnly?: boolean;
	quickbooksIdOnly?: boolean;
	businessCentralId: string;
	syncToBusinessCentral: boolean;
	showOnBuildersList: boolean;
	lathamAddressId?: number;
	lathamCarriers: string;
	lathamDefaultNotes: string;
	lathamCarrierNotes: string;
	outreach: boolean;
	lastOutreachDate?: Date;
	nextOutreachDate?: Date;
	daysBetweenOutreach?: number;
	paymentProcessorProfileId: string;
	billingNotes: string;
	isDeleted: boolean;
	billingCustomer: ICustomerModel;
	discounts: IDiscountModel[];
	rebates: IRebateModel[];
	customCustomerFieldAnswers: ICustomCustomerFieldAnswerModel[];
	contacts: CustomerContactModel[];
	customerOutreaches: CustomerOutreachModel[];
	customerMultipleAddresses: ICustomerMultipleAddressModel[];
	dealerId: number;
	taxRateGroup: ITaxRateGroupModel;
}

export class CustomerModel implements ICustomerModel {
	constructor() {
		this.customerId = 0;
		this.documentKey = UtilsService.newGuid();
        this.customerOutreaches = new Array<CustomerOutreachModel>();
		this.discounts = [];
		this.rebates = [];
		this.syncToQuickbooks = GlobalsService.company.customerQuickbooksAutoSync;
		this.textOptOut = true;
	}

	// This uuid is a temporary variable used in the mobile app to determine which subscription is the right one
	uuid: string;
	customerId: number;
	companyId: number;
	customerLinkId?: number;
	customerTypeId: number;
	priceLevelId: number;
	customerAreaId?: number;
	taxRegionId?: number;
	salesRepId?: number;
	salesRepFullName: string;
	arRepId?: number;
	arRepFullName: string;
	paymentTermId: number;
	paymentTermName: string;
	paymentTermNotificationPopup: boolean;
	paymentTermNotificationMessage: string;
	documentKey: string;
	customerType: CustomerTypeModel;
	userName: string;
	password: string;
	firstName: string;
    fullName: string;
	displayName: string;
	displayCityStateZip: string;
	quickbooksName: string;
	quickbooksClassId: string;
	quickbooksLocationId: string;
	lastName: string;
	companyName: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	zipcode: string;
	country: string;
	billingCustomerId?: number;
	linkBillingAddress: boolean;
	billingAddress1: string;
	billingAddress2: string;
	billingCity: string;
	billingState: string;
	billingZipcode: string;
	billingCountry: string;
	linkShippingAddress: boolean;
	shippingAddress1: string;
	shippingAddress2: string;
	shippingCity: string;
	shippingState: string;
	shippingZipcode: string;
	shippingCountry: string;
	email: string;
	apEmail: string;
	homePhone: string;
	cellPhone: string;
	workPhone: string;
	fax: string;
	website: string;
	cardOnFile: string;
	taxId: string;
	taxExempt: boolean;
	emailOptOut: boolean;
	textOptOut: boolean;
	creditHold: boolean;
	creditHoldNotes: string;
	criticalCreditHold: boolean;
	childCompanyId?: number;
	childCompanyName: string;
	childCompanyLogin: string;
	childLinkId: number;
	latitude?: string;
	longitude?: string;
	usingGeocodeAddress: boolean;
	acceptedTerms: boolean;
	customerAttentionNote: string;
	customerNotes: string;
	quickbooksId: string;
	syncToQuickbooks?: boolean;
	quickbooksLinkOnly?: boolean;
	quickbooksIdOnly?: boolean;
	businessCentralId: string;
	syncToBusinessCentral: boolean;
	showOnBuildersList: boolean;
	lathamAddressId?: number;
	lathamCarriers: string;
	lathamDefaultNotes: string;
	lathamCarrierNotes: string;
	outreach: boolean;
	lastOutreachDate?: Date;
	nextOutreachDate?: Date;
	daysBetweenOutreach?: number;
	paymentProcessorProfileId: string;
	billingNotes: string;
	isDeleted: boolean;
	billingCustomer: ICustomerModel;
	discounts: IDiscountModel[];
	rebates: IRebateModel[];
	customCustomerFieldAnswers: ICustomCustomerFieldAnswerModel[];
	contacts: CustomerContactModel[];
	customerOutreaches: CustomerOutreachModel[];
	customerMultipleAddresses: ICustomerMultipleAddressModel[];
	dealerId: number;
	taxRateGroup: ITaxRateGroupModel;

}
