<div class="notifications bg-light c-pointer d-flex align-items-center justify-content-center" (click)="showNotifications()">
	<div *ngIf="activeNotifications.length === 0" style="opacity: .15" class="text-dark notifications-flag"><i class="fal fa-flag"></i></div>
	<div *ngIf="activeNotifications.length > 0" class="text-danger notifications-flag">
		<i class="badge badge-danger bg-danger border">{{activeNotifications.length}}</i>
		<i class="far fa-flag"></i>
	</div>
</div>

<div class="notification-details card card-body border bg-light" [ngClass]="{ 'fade-in': showingNotifications === true, 'fade-out': showingNotifications === false }">
	<div *ngIf="activeNotifications.length === 0">
		No notifications
	</div>

	<div *ngIf="activeNotifications.length > 0">
		<div class="mb-2">
			<a href="javascript:void(0)" (click)="clearAllNotifications()"><i style="font-size: 12px; position: relative; top: -1px;" class="bi bi-trash"></i> Clear All Notifications</a>
		</div>
		<div class="notification-detail card bg-dark text-white pt-2 pb-2 ps-2 pe-3 mb-2" *ngFor="let notification of activeNotifications">
			<div style="display: inline-block; cursor: pointer; width:20px; vertical-align: top; font-size: 14px; line-height: 12px;" (click)="clearNotification(notification)">
				<i class="far fa-times-circle text-danger"></i>
			</div>
			<div style="display: inline-block; width: calc(100% - 20px);">
				<div style="font-size: 10px; line-height: 12px;">{{notification.notificationDate | date:'M/d/yyyy h:mm:ss a'}}</div>
				<div style="font-size: 12px; line-height: 12px;" [innerHTML]="notification.message">
				</div>
				<div *ngIf="notification.notificationType === 'Quickbooks Error'" class="view-quickbooks-task" (click)="viewQuickbooksTask(notification.referenceId)">
					<i class="fal fa-eye"></i> View Quickbooks Task
				</div>
			</div>

		</div>
	</div>
</div>
<quickbooks-task-display-dialog #quickbooksTaskDisplayDialog></quickbooks-task-display-dialog>
