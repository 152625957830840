<div #autoCompleteRef id="slick-auto-complete_{{uuid}}" class='slick-auto-complete dropdown-menu' [style.width]="width" [style.minWidth]="width" [style.visibility]="(expanded === true) ? 'visible' : 'hidden'" [style.opacity]="(expanded === true) ? '1' : '0'" [style.max-height]="listHeight" [style.left]="left" [style.top]="top">
	<div *ngIf="loading" style="font-size: .8rem" class="ms-3 py-2">Loading...</div>
	<div *ngIf="!loading && items?.length === 0" style="font-size: .8rem" class="ms-3">
		No items found
	</div>
	<div *ngIf="!loading && items?.length > 0">
		<div *ngFor="let item of items; let index = index;" (click)="onItemClick(index)" [class.card-header]="selectedIndex === index" class="dropdown-item">
			<ng-container *ngTemplateOutlet="listItemTemplateRef.template, context: { $implicit: item, index: index }">
			</ng-container>
		</div>
	</div>
</div>
