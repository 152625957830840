import { IUserModel } from "@models/users/user.model";
import { IResourceModel } from "@models/lookups/resource.model";

export interface INewCompanySetupModel {
    companyId : number;
    companyName: string;
    adminUserName: string;
    adminPassword: string;
	address: string;
    city: string;
    country: string;
    state: string;
    zipcode: string;
    email: string;
    contactPhone: string;
    timeZone: string;
    users: IUserModel[];
    resources : IResourceModel[];
}

export class NewCompanySetupModel implements INewCompanySetupModel {
    companyId : number;
    companyName: string;
    adminUserName: string;
    adminPassword: string;
	address: string;
    city: string;
    country: string;
    state: string;
    zipcode: string;
    email: string;
    contactPhone: string;
    timeZone: string;
    users: IUserModel[];
    resources : IResourceModel[];
}