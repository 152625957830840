import { Directive, ElementRef, AfterViewInit, OnDestroy, OnInit } from "@angular/core";
import { SlickUtilsService } from '../utils/slick-utils.service';

@Directive({
	selector: '[slick-drop-list-grab]'
})
export class SlickDropListGrabDirective {

	constructor(el: ElementRef) {
		const dropListEl = <HTMLElement>el.nativeElement;
		if (dropListEl.classList.contains("slick-drop-list-grab") === false)
			dropListEl.classList.add("slick-drop-list-grab");

	}
}