import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HeaderBarComponent } from "./header/header-bar.component";
import { JobComponentsModule } from "@app/jobs/job-components";
import { AppointmentsService, InvoicesService, JobsService, ScheduleDisplayService, ScheduleService } from "@services";
import { NotificationsComponent } from "./notifications/notifications.component";
import { SideMenuComponent } from "./side-menu/side-menu.component";
import { TopMenuComponent } from "./top-menu/top-menu.component";
import { FooterBarComponent } from "./footer/footer-bar.component";
import { ChatModule } from "@app/chat/chat.module";
import { SlickButtonSpinnerModule, SlickDropDownModule, SlickDialogModule, SlickScreenBlockerModule, SlickToastModule, SlickSearchBarModule, SlickAutoCompleteModule } from "@slick-components";
import { QuickbooksTaskDisplayModule } from "@app/quickbooks/quickbooks-task-display/quickbooks-task-display.module"
import { VersionReleaseDialogModule } from "@shared-components/version-release-dialog"

@NgModule({
	imports: [CommonModule,
		JobComponentsModule,
		FormsModule,
		RouterModule,
		ChatModule,
		QuickbooksTaskDisplayModule,
		SlickSearchBarModule,
		SlickAutoCompleteModule,
		SlickButtonSpinnerModule, SlickDropDownModule, SlickDialogModule, SlickScreenBlockerModule, SlickToastModule, VersionReleaseDialogModule],
	declarations: [HeaderBarComponent, NotificationsComponent, SideMenuComponent, TopMenuComponent, FooterBarComponent],
	providers: [ScheduleService, AppointmentsService, ScheduleDisplayService, JobsService, InvoicesService],
	exports: [HeaderBarComponent, SideMenuComponent, TopMenuComponent, FooterBarComponent]
})
export class LayoutModule {
}
