import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from "@angular/core";
import { DropdownModel, IDropdownModel, IInvoiceModel, IJellyFishOrderSpecsModel, ILineItemModel, ILocationModel } from "@models";
import { GlobalsService, LookupService } from "../../services";
import { JellyFishSystemLineItemService } from "../../services/jelly-fish-system-line-items.service";




@Component({
	selector: "jelly-fish-order-specs",
    templateUrl: "./jelly-fish-order-specs.component.html",
    styleUrls:["./jelly-fish-order-specs.scss"]
})

export class JellyFishOrderSpecsComponent implements OnChanges{
    @Input() orderSpecs: IJellyFishOrderSpecsModel;
    @Input() dealerId: number;
    @Input() lineItems: ILineItemModel[];
    @Input() customerId: number;
    @Input() useTenFoot: boolean;
    @Input() invoiceModel: IInvoiceModel;
    @Input() locked: boolean;

    @Output() onLineItemsChanged: EventEmitter<ILineItemModel[]> = new EventEmitter<ILineItemModel[]>();
    @Output() onInventoryLineItemsChanged: EventEmitter<ILineItemModel[]> = new EventEmitter<ILineItemModel[]>();

    isJellyFish: boolean = GlobalsService.company.companyId === 133;
    itemsToOrder: ILineItemModel[];
    itemsToOrderExpanded: boolean = false;

    trackHousing: IDropdownModel[] = [new DropdownModel("Fat", "Fat"),
        new DropdownModel("Z Flashing", "Z Flashing"),
        new DropdownModel("No Housing", "No Housing")];

    trackTypes: IDropdownModel[] = [new DropdownModel("Canted", "Canted"),
    new DropdownModel("Madhave", "Madhave"),
        new DropdownModel("Standard", "Standard"),
    new DropdownModel("No Track", "No Track")];

    trackColors: IDropdownModel[] = [
        new DropdownModel("Almond", "Almond"),
        new DropdownModel("Black", "Black"),
        new DropdownModel("Cameo", "Cameo"),
        new DropdownModel("Charcoal Grey", "Charcoal Grey"),
        new DropdownModel("Classic Cream", "Classic Cream"),
        new DropdownModel("Copper Penny", "Copper Penny"),
        new DropdownModel("Dark Bronze", "Dark Bronze"),
        new DropdownModel("Desert Sand", "Desert Sand"),
        new DropdownModel("Everest", "Everest"),
        new DropdownModel("Evergreen", "Evergreen"),
        new DropdownModel("Harbor Grey", "Harbor Grey"),
        new DropdownModel("Linen", "Linen"),
        new DropdownModel("London Brown", "London Brown"),
        new DropdownModel("Montana Suede", "Montana Suede"),
        new DropdownModel("Mountain Blue", "Mountain Blue"),
        new DropdownModel("Musket Brown", "Musket Brown"),
        new DropdownModel("Pebblestone Clay", "Pebblestone Clay"),
        new DropdownModel("Royal Brown", "Royal Brown"),
        new DropdownModel("Russet Red", "Russet Red"),
        new DropdownModel("Sand", "Sand"),
        new DropdownModel("Terra Bronze", "Terra Bronze"),
        new DropdownModel("Victorian Grey", "Victorian Grey"),
        new DropdownModel("Wicker", "Wicker"),
        new DropdownModel("White", "White")
    ];


    clipTypes: IDropdownModel[] = [new DropdownModel("New Clip", "New Clip"),
        new DropdownModel("No Clip", "No Clip")
    ];

    controllerTypes: IDropdownModel[] = [
        new DropdownModel("48v Controller", "48v Controller"),
        new DropdownModel("No Controller", "No Controller")
    ];


    constructor(private jellyFishSystemLineItemService: JellyFishSystemLineItemService, private lookupService: LookupService) {
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (this.lineItems)
            this.lineItems = [...this.lineItems];

        if (changes.customerId) {
            await this.calcLineItems();
        }

        //only for jellyfish
        if (GlobalsService.company.companyId === 133) {
            this.useTenFoot = false;
            const locations = this.lookupService.getLocations();
            let defaultLocationId;

            // first check the sales rep
            if (this.invoiceModel?.salesRepUserId) {
                defaultLocationId = this.lookupService.getUsers().find(x => x.userId === this.invoiceModel.salesRepUserId).defaultLocationId;
            }
            //then check the created by
            if (this.invoiceModel?.createdBy && !defaultLocationId) {
                defaultLocationId = this.lookupService.getUsers().find(x => x.userId === this.invoiceModel.createdBy).defaultLocationId;
            }
            //lastly check the user that is logged in
            if (GlobalsService.userInfo.defaultLocationId && !defaultLocationId) {
                defaultLocationId = GlobalsService.userInfo.defaultLocationId;
            }

            //if its salt lake then we use 7.5 calc, if not we use 10 for calc
            this.useTenFoot = (locations.find(x => x.locationId === defaultLocationId).name === "Salt Lake City" ? false : true);
        }
    }

    async onTrackHousingChanged(housing: IDropdownModel) {
        if (housing.id !== "No Housing")
            this.orderSpecs.trackType = "Standard";
        await this.calcLineItems();
    }
    //change this so html just calls calc line items
    async onLinearFeetChanged() {
        await this.calcLineItems();
    }

    async onTrackTypeChanged() {
        await this.calcLineItems();
    }

    async onTrackColorChanged() {
        await this.calcLineItems();
    }

    async onClipTypeChanged() {
        await this.calcLineItems();
    }

    async onControllerTypeChanged() {
        await this.calcLineItems();
    }

    async calcLineItems() {
        if (!this.locked) {
            this.itemsToOrder = await this.jellyFishSystemLineItemService.calculateSystemLineItems([], this.orderSpecs, this.customerId, this.useTenFoot, false, true)
            this.lineItems = await this.jellyFishSystemLineItemService.calculateSystemLineItems(this.lineItems, this.orderSpecs, this.customerId, this.useTenFoot, false, false);
            this.onLineItemsChanged.emit(this.lineItems);
        }
    }

}