export interface IAppointmentUserStrikeTypeModel {
    appointmentUserStrikeTypeId: number;
    description: string;
    active: boolean;
}

export class AppointmentUserStrikeTypeModel implements IAppointmentUserStrikeTypeModel {
    constructor() {
        this.active = true;
    }

    appointmentUserStrikeTypeId: number;
    description: string;
    active: boolean;
}
