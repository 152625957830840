import { Component, HostBinding, ViewChild, ViewEncapsulation } from '@angular/core';
import { InventoryLineItemsBaseComponent } from "@base-components/inventory-line-items-base.component";
import { LineItemsService, LookupService, HttpService, FunctionLockService } from '@services';
import { InventoryStore } from '@stores';


@Component({
	selector: 'inventory-line-items',
	templateUrl: 'inventory-line-items.component.html',
	styleUrls: ['inventory-line-items.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class InventoryLineItemsComponent extends InventoryLineItemsBaseComponent {
	@HostBinding('style.flex') flex = '1';

	constructor(lineItemsService: LineItemsService,
		httpService: HttpService,
		inventoryStore: InventoryStore,
		functionLockService: FunctionLockService) {
		super(lineItemsService, httpService, inventoryStore, functionLockService);
	}

	// All the rest of the code is in line-items-base.component.ts
}