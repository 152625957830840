import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SlickAutoCompleteModule, SlickValidationIndicatorModule,SlickDialogModule } from "@slick-components";

import { InventoryLocationsSearchComponent } from "./inventory-locations-search.component";

@NgModule({
	imports: [CommonModule,
		FormsModule,
		SlickAutoCompleteModule, SlickValidationIndicatorModule, SlickDialogModule
	],
	declarations: [InventoryLocationsSearchComponent],
	exports: [InventoryLocationsSearchComponent]
})
export class InventoryLocationsSearchModule { }

export { InventoryLocationsSearchComponent }
