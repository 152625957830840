import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TabsStore {
	private tabsType = localStorage.getItem("tabsType") || 'schedule';

	tabsChanged$ = new Subject<string>();

	changeTabsType(tabsType) {
		localStorage.setItem("tabsType", tabsType);
		this.tabsChanged$.next(tabsType);
	}
}
