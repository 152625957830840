import { Directive, Input, TemplateRef, OnInit, OnDestroy, EmbeddedViewRef, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Directive({
	selector: '[slickAutoCompleteListItemTemplate]',
	host: {
	}
})
export class SlickAutoCompleteListItemTemplateDirective {
	@Input() type: string;
	@Input('slickAutoCompleteListItemTemplate') name: string;

	constructor(public template: TemplateRef<any>) { }
}

