<div>
    <input class="form-control" #autoCompleteInputRef
           type="text"
           [ngClass]="{ 'text-body': disabled }"
           autocomplete="off"
           [ngModel]="searchText"
           (ngModelChange)="onSearchTextChange($event)"
           (keyup)="onKeyUp($event)"
           (blur)="onBlur()"
           [disabled]="disabled" />
</div>

<slick-auto-complete [inputRef]="autoCompleteInputRef"
                     textField="locationName"
                     listWidth="500"
                     [items]="results"
                     (onRefresh)="search($event)"
                     (onSelect)="onSelect($event)">
    <ng-template let-result
                 [slickAutoCompleteListItemTemplate]>
        <ul class="location-list-item m-0">
            <li style="font-size:14px">{{result.locationName}}</li>
        </ul>
    </ng-template>

</slick-auto-complete>


