import { AuthService } from '@services/utils/auth.service';
import { Routes } from "@angular/router";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";

export const AppRoutes: Routes = [
	{ path: "", loadChildren: () => import('./landing/landing.module').then(m => m.LandingPageModule), pathMatch: "full" },
    { path: "customers", canActivateChild: [AuthService], loadChildren: () => import('./customers/customers-page/customers-page.module').then(m => m.CustomersPageModule) },
	{ path: "crm", canActivateChild: [AuthService], loadChildren: () => import('./crm-page/crm-page.module').then(m => m.CrmPageModule) },
	{ path: "jobs", canActivateChild: [AuthService], loadChildren: () => import('./jobs/jobs-page/jobs-page.module').then(m => m.JobsPageModule) },
	{ path: "recurring", canActivateChild: [AuthService], loadChildren: () => import('./recurring/recurring-page/recurring-page.module').then(m => m.RecurringPageModule) },
	{ path: "recurring-billing", canActivateChild: [AuthService], loadChildren: () => import('./recurring-billing/recurring-billing-page/recurring-billing-page.module').then(m => m.RecurringBillingPageModule) },
	{ path: "estimates", canActivateChild: [AuthService], loadChildren: () => import('./estimates/estimates-page/estimates-page.module').then(m => m.EstimatesPageModule) },
    { path: "invoices", canActivateChild: [AuthService], loadChildren: () => import('./invoices/invoices-page/invoices-page.module').then(m => m.InvoicesPageModule) },
	{ path: "payments", canActivateChild: [AuthService], loadChildren: () => import('./payments/payments-page/payments-page.module').then(m => m.PaymentsPageModule) },
	{ path: "salesOrders", canActivateChild: [AuthService], loadChildren: () => import('./sales-orders/sales-orders-page/sales-orders-page.module').then(m => m.SalesOrdersPageModule)},
	{ path: "lathamOrders", canActivateChild: [AuthService], loadChildren: () => import('./latham-orders/latham-orders-page/latham-orders-page.module').then(m => m.LathamOrdersPageModule) },
	{ path: "acceptLathamQuote", loadChildren: () => import('./latham-orders/accept-latham-quote-page/accept-latham-quote-page.module').then(m => m.AcceptLathamQuotePageModule) },
	{ path: "dashboard", canActivateChild: [AuthService], loadChildren: () => import('./latham-orders/mechanism-dashboard-page/mechanism-dashboard-page.module').then(m => m.MechanismDashboardPageModule) },
	{ path: "documents", canActivateChild: [AuthService], loadChildren: () => import('./documents/documents-page.module').then(m => m.DocumentsPageModule) },
	{ path: "items", canActivateChild: [AuthService], loadChildren: () => import('./items/items.module').then(m => m.ItemsModule) },
	{ path: "pickList", canActivateChild: [AuthService], loadChildren: () => import('./pick-list/pick-list-page.module').then(m => m.PickListPageModule) },
	{ path: "inventory", canActivateChild: [AuthService], loadChildren: () => import('./inventory/inventory-page/inventory-page.module').then(m => m.InventoryPageModule) },
	{ path: "login", loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
	{ path: "newCompanySetup", loadChildren: () => import('./new-company-setup/new-company-setup-page/new-company-setup-page.module').then(m => m.NewCompanySetupPageModule) },
	{ path: "quickbooks", canActivateChild: [AuthService], loadChildren: () => import('./quickbooks/quickbooks-page/quickbooks-page.module').then(m => m.QuickbooksPageModule) },
	{ path: "quickbooksOAuthResponse", loadChildren: () => import('./quickbooks/quickbooks-oauth-response/quickbooks-oauth-response.module').then(m => m.QuickbooksOAuthResponseModule) },
	{ path: "vendors", canActivateChild: [AuthService], loadChildren: () => import('./vendors/vendors-page/vendors-page.module').then(m => m.VendorsPageModule) },
	{ path: "purchaseOrders", canActivateChild: [AuthService], loadChildren: () => import('./purchase-orders/purchase-orders-page/purchase-orders-page.module').then(m => m.PurchaseOrdersPageModule) },
	{ path: "receiving", canActivateChild: [AuthService], loadChildren: () => import('./receiving/receiving-page/receiving-page.module').then(m => m.ReceivingPageModule) },
	{ path: "shipping", canActivateChild: [AuthService], loadChildren: () => import('./shipping/shipping-page/shipping-page.module').then(m => m.ShippingPageModule) },
	{ path: "schedule", canActivateChild: [AuthService], loadChildren: () => import('./schedule/schedule-page/schedule-page.module').then(m => m.SchedulePageModule) },
	{ path: "settings", canActivateChild: [AuthService], loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
	{ path: "smartlist", canActivateChild: [AuthService], loadChildren: () => import('./smartlist/smartlist-page.module').then(m => m.SmartlistPageModule) },
	{ path: "test", canActivateChild: [AuthService], loadChildren: () => import('./test/test.module').then(m => m.TestModule) },
	{ path: "textChat", canActivateChild: [AuthService], loadChildren: () => import('./text-chat/text-chat-page/text-chat-page.module').then(m => m.TextChatPageModule) },
	{ path: "timeclock", canActivateChild: [AuthService], loadChildren: () => import('./timeclock/timeclock-page/timeclock-page.module').then(m => m.TimeclockPageModule) },
	{ path: "users", canActivateChild: [AuthService], loadChildren: () => import('./users/users-page/users-page.module').then(m => m.UsersPageModule) },
	{ path: "userSettings", canActivateChild: [AuthService], loadChildren: () => import('./user-settings/user-settings.module').then(m => m.UserSettingsModule) },
	{ path: "online-payments", loadChildren: () => import('./online-payments/online-payments-page/online-payments.module').then(m => m.OnlinePaymentsModule) },
	{ path: "online-batch-payments", loadChildren: () => import('./online-payments/online-batch-payments-page/online-batch-payments.module').then(m => m.OnlineBatchPaymentsModule) },
	{ path: "reporting/apptAveragesReport", canActivateChild: [AuthService], loadChildren: () => import('./reporting/appt-averages-report/appt-averages-report.module').then(m => m.ApptAveragesReportModule) },
	{ path: "reporting/cscPartsListReport", canActivateChild: [AuthService], loadChildren: () => import('./reporting/csc-parts-list-report/csc-parts-list-report.module').then(m => m.CSCPartsListReportModule) },
	{ path: "reporting/itemUsageReport", canActivateChild: [AuthService], loadChildren: () => import('./reporting/item-usage-report/item-usage-report.module').then(m => m.ItemUsageReportModule) },
	{ path: "reporting/itemUsageByBuilderReport", canActivateChild: [AuthService], loadChildren: () => import('./reporting/item-usage-by-builder-report/item-usage-by-builder-report.module').then(m => m.ItemUsageByBuilderReportModule) },
	{ path: "reporting/lathamOrderInsights", canActivateChild: [AuthService], loadChildren: () => import('./reporting/latham-order-insights/latham-order-insights.module').then(m => m.LathamOrderInsightsModule) },
	{ path: "reporting/lathamShipping", canActivateChild: [AuthService], loadChildren: () => import('./reporting/latham-shipping/latham-shipping.module').then(m => m.LathamShippingModule) },
	{ path: "reporting/lathamReport", canActivateChild: [AuthService], loadChildren: () => import('./reporting/latham-report/latham-report.module').then(m => m.LathamReportModule) },
	{ path: "reporting/lathamSystemSummaryReport", canActivateChild: [AuthService], loadChildren: () => import('./reporting/latham-system-summary-report/latham-system-summary-report.module').then(m => m.LathamSystemSummaryReportModule) },
	{ path: "reporting/ontarioBuilderReport", canActivateChild: [AuthService], loadChildren: () => import('./reporting/ontario-builder-report/ontario-builder-report.module').then(m => m.OntarioBuilderReportModule) },
	{ path: "reporting/rebatesReport", canActivateChild: [AuthService], loadChildren: () => import('./reporting/rebates-report/rebates-report.module').then(m => m.RebatesReportModule) },
	{ path: "reporting/resourceApptTypesReport", canActivateChild: [AuthService], loadChildren: () => import('./reporting/resource-appt-types-report/resource-appt-types-report.module').then(m => m.ResourceApptTypesReportModule) },
	{ path: "reporting/employeeApptsReport", canActivateChild: [AuthService], loadChildren: () => import('./reporting/employee-appts-report/employee-appts-report.module').then(m => m.EmployeeApptsReportModule) },
	{ path: "reporting/employeeAvgHoursWorked", canActivateChild: [AuthService], loadChildren: () => import('./reporting/employee-avg-hours-worked-report/employee-avg-hours-worked-report.module').then(m => m.EmployeeAvgHoursWorkedModule ) },
	{ path: "reporting/employeeMonthlyHoursWorked", canActivateChild: [AuthService], loadChildren: () => import('./reporting/employee-monthly-hours-worked-report/employee-monthly-hours-worked-report.module').then(m => m.EmployeeMonthlyHoursWorkedModule ) },
	{ path: "reporting/employeeYearlyHoursWorked", canActivateChild: [AuthService], loadChildren: () => import('./reporting/employee-yearly-hours-worked-report/employee-yearly-hours-worked-report.module').then(m => m.EmployeeYearlyHoursWorkedModule ) },
	{ path: "reporting/quoteTracking", canActivateChild: [AuthService], loadChildren: () => import('./reporting/quote-tracking/quote-tracking.module').then(m => m.QuoteTrackingModule ) },

	{ path: "emailOptOut", loadChildren: () => import('./email-opt-out/email-opt-out.module').then(m => m.EmailOptOutModule) },
	{ path: "acceptEstimate", loadChildren: () => import('./accept-estimate/accept-estimate-page/accept-estimate.module').then(m => m.AcceptEstimateModule) },
	{ path: "acceptBatchEstimate", loadChildren: () => import('./accept-estimate/accept-batch-estimate-page/accept-batch-estimate.module').then(m => m.AcceptBatchEstimateModule) },
	{ path: "acceptServiceAgreement", loadChildren: () => import('./accept-service-agreement/accept-service-agreement.module').then(m => m.AcceptServiceAgreementModule) },
	{ path: "accessDenied/:linkCode", loadChildren: () => import('./access-denied/access-denied.module').then(m => m.AccessDeniedModule) },


	//JELLYFISH ORDERING
	{ path: "jellyFishOrders", canActivateChild: [AuthService], loadChildren: () => import('./jelly-fish-orders/jelly-fish-orders.module').then(m => m.JellyFishOrdersModule) },


	//SKEDIT PAY
	{ path: "skeditPayTransactionLog", canActivateChild: [AuthService], loadChildren: () => import('./skedit-pay-transaction-log/skedit-pay-transaction-log.module').then(m => m.SkeditPayTransactionLogModule) },
	{ path: "skeditPayRecurring", canActivateChild: [AuthService], loadChildren: () => import('./skedit-pay-recurring/skedit-pay-recurring-page/skedit-pay-recurring-page.module').then(m => m.SkeditPayRecurringPageModule) },
	{ path: "skeditPayQuickbooksOnline", canActivateChild: [AuthService], loadChildren: () => import('./skedit-pay-quickbooks-online//skedit-pay-quickbooks-online.module').then(m => m.SkeditPayQuickbooksOnlineModule) },


	{ path: "404", component: PageNotFoundComponent },
	{ path: '**', component: PageNotFoundComponent }
];