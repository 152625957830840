import { Directive, NgZone, ElementRef, Input, Output, EventEmitter, OnInit, OnDestroy } from "@angular/core";

@Directive({
	selector: '[slick-google-address-search]'
})
export class SlickGoogleAddressSearchDirective implements OnInit, OnDestroy {
	@Input() googleAddressSearch;
	@Output() googleAddressSearchChange = new EventEmitter<google.maps.places.PlaceResult>();
	@Output() onGoogleAddressSelect = new EventEmitter<google.maps.places.PlaceResult>();

	autocomplete: google.maps.places.Autocomplete;

	constructor(private el: ElementRef, private ngZone: NgZone) {
	}

	ngOnInit() {
		if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
			console.error("%c Error: It appears you have not included the google maps scripts.  Please see www.slickcomponents.com for instructions on how to add this.", 'background: red; color: white');
			return;
		}

		new google.maps.places.Autocomplete(this.el.nativeElement);
		this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, {
			types: []
		});
		this.autocomplete.addListener("place_changed", () => {
			//get the place result
			let place: google.maps.places.PlaceResult = this.autocomplete.getPlace();

			// Google maps kind of sucks.  Often times it will strip the house number, but it's there
			// in the prediction.
			// So take the predictive text and use the house number from that instead
			const sessionToken = new google.maps.places.AutocompleteSessionToken()
			const autocompleteService = new google.maps.places.AutocompleteService();
			autocompleteService.getPlacePredictions({
				input: this.el.nativeElement.value,
				sessionToken: sessionToken
			}, (predictionAddr) => {
				// Give control back to angular
				this.ngZone.run(() => {
					// See if we got a prediction back
					if (predictionAddr && predictionAddr.length > 0) {
						// See if the original place already has a street #
						if (!(place.address_components.find(x => x.types.find(addrType => addrType === 'street_number')))) {
							// Parse out the prediction addr, by spaces
							const preditionAddrParts = predictionAddr[0].structured_formatting.main_text.split(" ");
							// If we couldn't parse it, ignore the street
							if (preditionAddrParts.length > 0) {
								// Add a new "street_number" field to the components
								const streetNumber = preditionAddrParts[0];
								place.address_components.unshift({
									long_name: streetNumber,
									short_name: streetNumber,
									types: ['street_number']
								});
							}
						}
					}

					if (this.googleAddressSearchChange)
						this.googleAddressSearchChange.emit(place);

					if (this.onGoogleAddressSelect)
						this.onGoogleAddressSelect.emit(place);

					this.el.nativeElement.value = "";
				});
			});

		});
	}

	ngOnDestroy() {
		this.autocomplete.unbindAll();
	}
}
