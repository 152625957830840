import { Component, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, OnDestroy, HostListener, ChangeDetectorRef } from "@angular/core";
import { ISlickPhotoGalleryModel, SlickPhotoGalleryModel } from "./slick-photo-gallery.model";
import { ISlickFileModel } from "../slick-file-model/slick-file.model";
import { SlickSleepService } from "../utils/slick-sleep.service";

@Component({
	selector: "slick-photo-gallery",
	templateUrl: "slick-photo-gallery.component.html"
})
export class SlickPhotoGalleryComponent implements OnChanges, OnDestroy {
	@Input() photos: ISlickPhotoGalleryModel[];
	@Input() files: ISlickFileModel[];

	@ViewChild("containerRef") containerRef: ElementRef;

	@HostListener('window:keydown', ['$event'])
	async onResize(e: KeyboardEvent) {
		if (e.which === 39)
			this.nextPhoto();
		else if (e.which === 37)
			this.previousPhoto();
		else if (e.which === 27)
			this.closeWindow();
	}

	isVisible: boolean = false;
	isPhotoGalleryVisible: boolean = false;
	selectedPhoto: ISlickPhotoGalleryModel = null;
	visibleIndex: number = 0;

	constructor(private changeDetector: ChangeDetectorRef) { }

	ngOnDestroy() {
		// It's fine if this fails.  It just means it was removed elsewhere
		try {
			document.body.appendChild(this.containerRef.nativeElement);
		}
		catch { }
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.files) {
			this.photos = [];
			if (changes.files.currentValue) {
				this.photos = changes.files.currentValue.map((f) => {
					let photoModel = new SlickPhotoGalleryModel();
					photoModel.photoUrl = f.base64Image || f.url;
					photoModel.title = f.title;
					return photoModel;
				});
			}
		}
	}

	async showPhotoGallery(visibleIndex: number = 0) {
		this.isVisible = true;

		this.changeDetector.detectChanges();

		document.body.appendChild(this.containerRef.nativeElement);

		await SlickSleepService.sleep();
		this.isPhotoGalleryVisible = true;

		this.visibleIndex = visibleIndex;
		if (this.photos && this.photos.length > 0)
			this.selectedPhoto = this.photos[this.visibleIndex];
	}

	hidePhotoGallery() {
		// It's fine if this fails.  It just means it was removed elsewhere
		try {
			document.body.removeChild(this.containerRef.nativeElement);
		}
		catch { }

		this.isVisible = false;
	}

	previousPhoto() {
		if (this.visibleIndex === 0)
			this.visibleIndex = this.photos.length - 1;
		else
			this.visibleIndex--;

		this.selectedPhoto = this.photos[this.visibleIndex];
	}

	nextPhoto() {
		if (this.visibleIndex === this.photos.length - 1)
			this.visibleIndex = 0;
		else
			this.visibleIndex++;

		this.selectedPhoto = this.photos[this.visibleIndex];
	}

	setPosition(idx: number) {
		this.visibleIndex = idx;
		this.selectedPhoto = this.photos[this.visibleIndex];
	}

	closeWindow() {
		this.hidePhotoGallery();
	}
}