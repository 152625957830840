import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ChatService } from "@services";
import { SlickDialogModule, SlickPopoverModule, SlickContainerModule, SlickDropDownModule, SlickFileDropModule, SlickPhotoGalleryModule } from "@slick-components";

// Components
import { ChatGroupAttendantsComponent } from "./components/chat-group-attendants/chat-group-attendants.component";
import { ChatGroupListComponent } from "./components/chat-group-list/chat-group-list.component";
import { ChatMessagesComponent } from "./components/chat-messages/chat-messages.component";

import { SkeditChatComponent } from "./skedit-chat/skedit-chat.component";
import { LegacyChatComponent } from "./legacy-chat/legacy-chat.component";

import { TextFieldModule } from "@angular/cdk/text-field";
import { ComponentsModule } from "@components";

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        FormsModule,
        SlickDialogModule,
        SlickPopoverModule,
        SlickContainerModule,
        SlickDropDownModule,
        SlickFileDropModule,
        SlickPhotoGalleryModule,
        TextFieldModule
    ],
    declarations: [
        ChatGroupListComponent, SkeditChatComponent, LegacyChatComponent, ChatGroupAttendantsComponent, ChatMessagesComponent],
    exports: [
        ChatGroupListComponent, SkeditChatComponent, LegacyChatComponent],
    providers: [ChatService]
})
export class ChatModule { }

export { SkeditChatComponent}