import { Component, Input, AfterViewInit, OnDestroy, ElementRef, ViewChild, OnChanges, SimpleChanges, OnInit } from "@angular/core";
import { SlickSleepService } from "../utils/slick-sleep.service";
import { SlickUtilsService } from "../utils/slick-utils.service";

@Component({
	selector: 'slick-popover',
	templateUrl: 'slick-popover.component.html'
})
export class SlickPopoverComponent implements OnChanges, AfterViewInit, OnDestroy {
	@Input() element: HTMLElement;
	@Input() position: string = 'right';
	@Input() hoverDelay: number = 250;
	@Input() outDelay: number = 250;
	@Input() alignment: string = "left";
	@Input() topOffsetPx: number = 0;
	@Input() leftOffsetPx: number = 0;
	@Input() whiteBackground: boolean = false;
	@Input() showByClick: boolean = false;
	@Input() showArrow: boolean = true;

	@ViewChild("containerDivRef", { static: true }) containerDivRef: ElementRef;
	@ViewChild("popoverContentRef", { static: true }) popoverContentRef: ElementRef;
	
	uuid: string;
	top: number;
	left: number;

	fnReposition = () => this.reposition();
	fnDocumentClick = (e) => this.documentClick(e);
	fnMouseEnter = () => this.mouseEnter();
	fnMouseLeave = () => this.mouseLeave();

	displayTimeout: NodeJS.Timer;
	leaveTimeout: NodeJS.Timer;
	isVisible = false;
	elementId: string;

	constructor(private el: ElementRef) {
		this.uuid = SlickUtilsService.newGuid();		
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (changes.topOffsetPx)
			this.topOffsetPx = parseFloat(changes.topOffsetPx.currentValue);

		if (changes.leftOffsetPx)
			this.leftOffsetPx = parseFloat(changes.leftOffsetPx.currentValue);

		if (changes.position) {
			this.containerDivRef.nativeElement.classList.add("hidden");
			this.containerDivRef.nativeElement.classList.remove("visible");
		}

		if (changes.showByClick) 
			this.showByClick = (this.showByClick.toString().toLowerCase() === 'true') ? true : false;

		if (changes.whiteBackground && changes.whiteBackground.currentValue)
			this.whiteBackground = (changes.whiteBackground.currentValue.toString().toLowerCase() !== 'false');		

		if (changes.showArrow && changes.showArrow.currentValue)
			this.showArrow = (changes.showArrow.currentValue.toString().toLowerCase() !== 'false');		

		await SlickSleepService.sleep();
		this.reposition();
	}

	ngAfterViewInit() {
		this.containerDivRef.nativeElement.classList.add("hidden");
		this.containerDivRef.nativeElement.classList.add("showArrow");

		// Remove this from the inline version and put it on the body so that it doesn't hide behind the bottom
		// of a div when it expands beyond the bottom.
		//this.el.nativeElement.remove(this.el);
		document.body.appendChild(this.el.nativeElement);

		// This is necessary, otherwise `this` in the reposition function will be window
		// and not the component.
		// Since angular has no way to use the third parameter, I'm doing this in raw js
		if (this.showByClick === true) {
			document.addEventListener("click", this.fnDocumentClick, true);
			if (!this.element.getAttribute("id"))
				this.element.setAttribute('id', `slick-popover-element_${this.uuid}`);

			this.elementId = this.element.getAttribute("id");
		}
		else {
			this.el.nativeElement.addEventListener("mouseenter", this.fnMouseEnter);
			this.element.addEventListener("mouseenter", this.fnMouseEnter);
			this.el.nativeElement.addEventListener("mouseleave", this.fnMouseLeave);
			this.element.addEventListener("mouseleave", this.fnMouseLeave);
		}
	}

	ngOnDestroy() {
		document.removeEventListener("click", this.fnDocumentClick, true);			
		this.el.nativeElement.removeEventListener("mouseenter", this.fnMouseEnter);
		this.element.removeEventListener("mouseenter", this.fnMouseEnter);
		this.el.nativeElement.removeEventListener("mouseleave", this.fnMouseLeave);
		try {
			this.element.removeEventListener("mouseleave", this.fnMouseLeave);
			document.body.removeChild(this.el.nativeElement);
		}
		catch { }
	}

	private mouseEnter() {
		clearTimeout(this.displayTimeout);
		clearTimeout(this.leaveTimeout);
		this.displayTimeout = setTimeout(() => {
			this.reposition();
			this.containerDivRef.nativeElement.classList.remove("hidden");
			this.containerDivRef.nativeElement.classList.add("visible");
		}, this.hoverDelay);
	}

	private mouseLeave() {
		clearTimeout(this.displayTimeout);
		clearTimeout(this.leaveTimeout);

		this.leaveTimeout = setTimeout(() => {
			this.containerDivRef.nativeElement.classList.add("hidden");
			this.containerDivRef.nativeElement.classList.remove("visible");
		}, this.outDelay);
	}

	private async reposition() {
		if (!this.topOffsetPx)
			this.topOffsetPx = 0;

		if (!this.leftOffsetPx)
			this.leftOffsetPx = 0;

		if (this.showArrow === true)
			this.containerDivRef.nativeElement.classList.add("showArrow");
		else if (this.showArrow === false)
			this.containerDivRef.nativeElement.classList.remove("showArrow");

		if (this.whiteBackground === true)
			this.containerDivRef.nativeElement.classList.add("whiteBackground");
		else if (this.whiteBackground === false)
			this.containerDivRef.nativeElement.classList.remove("whiteBackground");


		let targetRect = this.element.getBoundingClientRect();
		let targetLeft = targetRect.left;
		let targetTop = targetRect.top;
		let targetHeight = this.element.offsetHeight;
		let targetWidth = targetRect.width;

		let popoverHeight = this.popoverContentRef.nativeElement.offsetHeight;
		let popoverWidth = this.popoverContentRef.nativeElement.getBoundingClientRect().width;

		if (this.showArrow) {
			popoverWidth += 5;
		}

		if (this.position === 'left') {
			this.top = (targetTop + this.topOffsetPx);
			this.left = (targetLeft - popoverWidth - 3 + this.leftOffsetPx);
			if (this.showArrow)
				this.left -= 2;
		}
		else if (this.position === 'right') {
			this.top = (targetTop + this.topOffsetPx);
			this.left = (targetLeft + targetWidth -7 + this.leftOffsetPx);
			if (this.showArrow)
				this.left += 7;
		}
		else if (this.position === 'top') {
			this.top = (targetTop - popoverHeight - 3 + this.topOffsetPx);
			this.left = (targetLeft + this.leftOffsetPx);
			// default to left aligned
			this.left = (targetLeft + this.leftOffsetPx);
			if (this.alignment && this.alignment.toLowerCase() === 'right')
				this.left = targetLeft + targetWidth - popoverWidth - 4 + this.leftOffsetPx;

			if (this.showArrow)
				this.top -= 7;
		}
		else if (this.position === 'bottom') {			
			this.top = (targetTop + targetHeight - 7 + this.topOffsetPx);
			// default to left aligned
			this.left = (targetLeft + this.leftOffsetPx);
			if (this.alignment && this.alignment.toLowerCase() === 'right')
				this.left = targetLeft + targetWidth - popoverWidth - 4 + this.leftOffsetPx;
			
			if (this.showArrow)
				this.top += 7;
		}
	}

	private documentClick(e: MouseEvent) {
		if (SlickUtilsService.checkParentIdExists(<HTMLElement>e.target, this.elementId) === true) {
			if (this.isVisible === true) {
				this.containerDivRef.nativeElement.classList.remove("visible");
				this.containerDivRef.nativeElement.classList.add("hidden");
				this.isVisible = false;
			}
			else {
				this.reposition();
				this.containerDivRef.nativeElement.classList.remove("hidden");
				this.containerDivRef.nativeElement.classList.add("visible");
				this.isVisible = true;
			}
		}
		else {
			if (this.isVisible === true) {
				this.containerDivRef.nativeElement.classList.remove("visible");
				this.containerDivRef.nativeElement.classList.add("hidden");
				this.isVisible = false;
			}
		}
	}
}
