import { ITimeclockEntryModel } from "./timeclock-entry.model";
import * as moment from "moment";
import { ITimeClockDailyNotesModel, TimeClockDailyNotesModel } from "./timeclock-daily-notes.model";

export interface ITimeclockEntryDay {
	emptyDate: boolean;
	date: Date;
	totalMinutes: number;
	totalDisplay: string;
	totalWorkedMinutes: number;
	totalWorkedDisplay: string;
	totalBreakMinutes: number;
	totalBreakDisplay: string;
	locked: boolean;
	timeclockEntryModels: ITimeclockEntryModel[]
	timeClockDailyNotesModel: ITimeClockDailyNotesModel;

}

export class TimeclockEntryDay implements ITimeclockEntryDay {
	constructor(date: Date, emptyDate: boolean = false) {
		this.emptyDate = emptyDate;
		this.date = date;
		this.totalMinutes = 0;
		this.totalWorkedMinutes = 0;
		this.totalBreakMinutes = 0;
		if (moment().diff(moment(this.date), 'days') > 2)
			this.locked = true;
		else
			this.locked = false;

		this.timeclockEntryModels = [];
		this.timeClockDailyNotesModel = new TimeClockDailyNotesModel(this.date);
	}

	emptyDate: boolean;
	date: Date;
	locked: boolean;
	totalMinutes: number;
	get totalDisplay(): string {
		return moment.utc(moment.duration(this.totalMinutes, "minutes").asMilliseconds()).format("H:mm");
	}
	totalWorkedMinutes: number;
	get totalWorkedDisplay(): string {
		return moment.utc(moment.duration(this.totalWorkedMinutes, "minutes").asMilliseconds()).format("H:mm");
	}
	totalBreakMinutes: number;
	get totalBreakDisplay(): string {
		return moment.utc(moment.duration(this.totalBreakMinutes, "minutes").asMilliseconds()).format("H:mm");
	}
	timeclockEntryModels: ITimeclockEntryModel[]
	timeClockDailyNotesModel: ITimeClockDailyNotesModel;
}
