export interface ITaxRegionModel {
	taxRegionId: number;
	regionName: string;
	taxRate1Percent: number;
	taxRate1IncludeLabor: boolean;
	taxRate1IncludeShipping: boolean;
	taxRate2Percent: number;
	taxRate2IncludeLabor: boolean;
	taxRate2IncludeShipping: boolean;
	taxRate3Percent: number;
	taxRate3IncludeLabor: boolean;
	taxRate3IncludeShipping: boolean;
	syncToQuickbooks: boolean;
	quickbooksId: string;
	laborQuickbooksId: string;
	shippingQuickbooksId: string;
	homeOwnerQuickbooksClassId: string;
	builderQuickbooksClassId: string;
}

export class TaxRegionModel implements ITaxRegionModel {
	constructor() {
		this.taxRegionId = 0;
	}

	taxRegionId: number;
	regionName: string;
	taxRate1Percent: number;
	taxRate1IncludeLabor: boolean;
	taxRate1IncludeShipping: boolean;
	taxRate2Percent: number;
	taxRate2IncludeLabor: boolean;
	taxRate2IncludeShipping: boolean;
	taxRate3Percent: number;
	taxRate3IncludeLabor: boolean;
	taxRate3IncludeShipping: boolean;
	syncToQuickbooks: boolean;
	quickbooksId: string;
	laborQuickbooksId: string;
	shippingQuickbooksId: string;
	homeOwnerQuickbooksClassId: string;
	builderQuickbooksClassId: string;
}