import { NewAttendantModel } from "./new-attendant.model";

export interface INewGroupModel {
    uuid: string;
    attendants: NewAttendantModel[];
    message: string;
    teamIds: number[];
    groupName: string;
    documentKey: string;
}

export class NewGroupModel implements INewGroupModel {
    uuid: string;
    attendants: NewAttendantModel[];
    message: string;
    teamIds: number[];
    groupName: string;
    documentKey: string;
}