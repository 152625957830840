<div *ngIf="isValid">
    <div *ngIf="loading" class="mt-1">
        Loading <div class="waiting-gif"></div>
    </div>
    <div *ngIf="!loading">
        <table *ngIf="jobCostingDetailModel" class="job-costing mt-2 table-bordered">
            <tr>
                <th colspan="1" style="font-size:20px">Revenue and Cost of Goods Sold</th>
                <th style="font-size:14px; text-align:right;">
                    Generated: {{ jobCostingDetailModel.createdDate | date:'M/d/yyyy hh:mm a' }}
                </th>
            </tr>
            <tr><td>Product Revenue</td><td class="right-align">{{jobCostingDetailModel.productRevenue | currency}}</td></tr>
            <tr><td>Labor Revenue</td><td class="right-align">{{jobCostingDetailModel.laborRevenue | currency}}</td></tr>

            <tr class="bold-text"><td>Total Revenue</td><td class="right-align">{{jobCostingDetailModel.totalRevenue | currency}}</td></tr>
            <tr><td>Direct Material</td><td class="right-align">{{jobCostingDetailModel.directMaterial | currency}}</td></tr>
            <tr><td>Direct Labor</td><td class="right-align">{{jobCostingDetailModel.directLabor | currency}}</td></tr>
            <tr class="bold-text"><td class="bold-text">Total Cost of Goods Sold</td><td class="right-align">{{jobCostingDetailModel.totalCOGS | currency}}</td></tr>

            <tr class="bold-text"><td>Gross Margin</td><td class="right-align">{{jobCostingDetailModel.grossMargin | currency}}</td></tr>

            <tr><th colspan="2" style="font-size:20px">Indirect Costs</th></tr>
            <tr><td>Indirect Labor</td><td class="right-align">{{jobCostingDetailModel.indirectLaborPerJob | currency}}</td></tr>
            <tr><td>Overhead Costs</td><td class="right-align">{{jobCostingDetailModel.overheadCostsPerJob | currency}}</td></tr>
            <tr><td>Unproductive Direct Labor</td><td class="right-align">{{jobCostingDetailModel.unproductiveDirectLaborPerJob | currency}}</td></tr>
            <tr class="bold-text"><td>Total SG&A</td><td class="right-align">{{jobCostingDetailModel.totalSGA | currency}}</td></tr>

            <tr [ngClass]="{'bg-danger-subtle': jobCostingDetailModel.netIncome < 0, 'bg-success-subtle': jobCostingDetailModel.netIncome >= 0}" class="bold-text">
                <td>Net Income</td>
                <td class="right-align">{{ jobCostingDetailModel.netIncome | currency }}</td>
            </tr>
        </table>
    </div>
</div>

<div *ngIf="!isValid" class="alert alert-primary" role="alert">
    <h4 class="alert-heading">Incomplete Job Costing Setup!</h4>
    <p>Your job costing setup needs to be completed to proceed. Please review your settings and ensure all necessary information has been provided.</p>
    <span *ngIf="canAccessSettings">
        <a href="/#/settings/jobCostingSetup" class="alert-link">Click here</a> to go to the setup page now.
    </span>
    <hr>
</div>
