import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IRecurringAppointmentModel, IConflictingAppointmentResultModel } from "@models";

@Injectable()
export class RecurringAppointmentsService {
    constructor(private readonly httpService: HttpService) { }

    async getRecurringAppointment(recurringAppointmentId: number): Promise<IRecurringAppointmentModel> {
        return await this.httpService.get("/recurringappointments/getRecurringAppointment", { recurringAppointmentId: recurringAppointmentId });
    }

    async checkForRecurringConflicts(recurringAppointmentModel: IRecurringAppointmentModel): Promise<IConflictingAppointmentResultModel> {
        return await this.httpService.post("/recurringappointments/checkForRecurringConflicts", recurringAppointmentModel);
    }

    async updateRecurringAppointment(recurringAppointmentModel: IRecurringAppointmentModel): Promise<IRecurringAppointmentModel> {
        return await this.httpService.post("/recurringappointments/updateRecurringAppointment", recurringAppointmentModel);
    }

    async getRecurringAppointmentsForGrid(recurringAppointmentModel: IRecurringAppointmentModel): Promise<IRecurringAppointmentModel> {
        return await this.httpService.post("/recurringappointments/getRecurringAppointmentsForGrid", recurringAppointmentModel);
    }

    async deleteRecurringAppointment(recurringAppointmentId: number): Promise<boolean> {
        return await this.httpService.delete("/recurringappointments/deleteRecurringAppointment", { recurringAppointmentId });
    }
}