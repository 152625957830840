import { Injectable } from "@angular/core";

@Injectable()
export class SlickScreenBlockerService {
	isVisible: boolean = false;
	forceBlockActive: boolean = false;

	private static blockCount: number = 0;
	private static preShowTimeout: NodeJS.Timer = null;
	private static blockTimeout: NodeJS.Timer = null;

	block(delay: number = 350) {
		if (this.forceBlockActive === true)
			return;

		SlickScreenBlockerService.blockCount++;

		if (!SlickScreenBlockerService.preShowTimeout) {
			SlickScreenBlockerService.preShowTimeout = setTimeout(() => {
				this.show();

				clearTimeout(SlickScreenBlockerService.blockTimeout);
				SlickScreenBlockerService.blockTimeout = setTimeout(() => {
					SlickScreenBlockerService.blockCount = 0;
					this.isVisible = false;
					this.hide();
				}, 20000);
			}, delay);
		}
		else {
			clearTimeout(SlickScreenBlockerService.blockTimeout);
			SlickScreenBlockerService.blockTimeout = setTimeout(() => {
				SlickScreenBlockerService.blockCount = 0;
				this.isVisible = false;
				this.hide();
			}, 20000);
		}
	}

	unblock() {
		if (this.forceBlockActive === true)
			return;

		SlickScreenBlockerService.blockCount--;

		if (SlickScreenBlockerService.blockCount <= 0) {
			SlickScreenBlockerService.blockCount = 0;
			clearTimeout(SlickScreenBlockerService.preShowTimeout);
			SlickScreenBlockerService.preShowTimeout = null;
			clearTimeout(SlickScreenBlockerService.blockTimeout);
			this.isVisible = false;
			this.hide();
		}
	}

	forceBlock() {
		clearTimeout(SlickScreenBlockerService.blockTimeout);
		this.forceBlockActive = true;
		this.isVisible = true;
		this.show();
	}

	forceUnblock() {
		clearTimeout(SlickScreenBlockerService.blockTimeout);
		SlickScreenBlockerService.blockCount = 0;
		this.forceBlockActive = false;
		this.isVisible = false;
		this.hide();
	}

	private show() {
		let screenBlockerDiv: HTMLDivElement = (<HTMLDivElement>document.getElementById("slick-screen-blocker"));
		if (!screenBlockerDiv) {
			screenBlockerDiv = document.createElement("div");
			screenBlockerDiv.id = "slick-screen-blocker";
			screenBlockerDiv.classList.add('slick-screen-blocker');
			screenBlockerDiv.innerHTML = `<div class='slick-screen-blocker_spinner'></div>`;

			document.body.appendChild(screenBlockerDiv);
		}

		screenBlockerDiv.classList.add('visible');
	}

	private hide() {
		let screenBlockerDiv: HTMLDivElement = (<HTMLDivElement>document.getElementById("slick-screen-blocker"));
		if (screenBlockerDiv)
			screenBlockerDiv.classList.remove('visible');
	}

}