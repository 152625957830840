import { Injectable } from "@angular/core"; 
import { ILathamOrderPickListSpecModel, LathamOrderPickListSpecModel, LathamOrderPickListSpecTypes } from "../models";
import { sortBy } from "sort-by-typescript";

@Injectable()
export class LathamOrdersPickListSpecsService {

	getPickListSpecs(): ILathamOrderPickListSpecModel[] {
		const pickListSpecs: ILathamOrderPickListSpecModel[] = [];

		pickListSpecs.push(this.addSpec("BondWire", LathamOrderPickListSpecTypes.string, ['True', 'False']));
		pickListSpecs.push(this.addSpec("BrandType", LathamOrderPickListSpecTypes.string, ['Coverstar', 'PCS']));
		pickListSpecs.push(this.addSpec("CliponCoping", LathamOrderPickListSpecTypes.string, ['None', 'One Piece Cantilever Reusable', 'One Piece Inclined Reusable', 'Rounded']));
		pickListSpecs.push(this.addSpec("ControlType", LathamOrderPickListSpecTypes.string, ['Circuit Board / Limits Upgrade', 'Circuit Board w / R.F', 'RFTouchPad', 'Rocker Switch', 'Standard Keyswitch', 'Touch Screen', 'TouchPad', 'Wifi TouchPad', 'Wireless TouchPad']));
		pickListSpecs.push(this.addSpec("Corners", LathamOrderPickListSpecTypes.string, ['2 foot radius', '2 ft Radius', '3 in Chop', '3 inch chopped', '6 in Radius', '6 inch radius', '90 Degree', 'Heat Sealed Color', 'None']));
		pickListSpecs.push(this.addSpec("CornerType", LathamOrderPickListSpecTypes.string, ['1 1 / 2 Inch Setback', '1 / 2 Inch Setback', '2x2 3 / 4 Inch Cutback', '3x1 Inch Cutback', '3x2 3 / 4 Inch Cutback', '403 Under Track', 'Advanced 1x1 Foldback', 'Aquamatic', 'Infinity 3x1', 'Toptrack no Gore', 'Toptrack with Gore', 'Zero Setback']));
		pickListSpecs.push(this.addSpec("DeckCoverType", LathamOrderPickListSpecTypes.string, ['23" Wide Aluminum Bench', 'Bench Brackets', 'Gray Polymer Mechanism Cover', 'None', 'Tan Bench End', 'Tan Bench', 'White Bench', 'White Polymer Mechanism Cover']));
		pickListSpecs.push(this.addSpec("Encapsulation", LathamOrderPickListSpecTypes.string, ['403 Fiberglass', '403 Gunite', 'Deck on Deck', 'Fiberglass', 'Fort Wayne', 'Gunite Cantilever', 'Gunite Non Cantilever', 'Gunite w/ Fiber Optics', 'Gunite', 'None', 'One Piece Bullnose', 'One Piece Inclined', 'One Piece Rounded', 'Outside Step', 'Pool in Pool', 'Vinyl Liner w/ Fiber Optics', 'Vinyl Liner']));
		pickListSpecs.push(this.addSpec("ExtendedMountingFeet", LathamOrderPickListSpecTypes.string, ['True', 'False']));
		pickListSpecs.push(this.addSpec("FabricColor", LathamOrderPickListSpecTypes.string, ['*Special Order', 'Aqua', 'Black', 'Brown', 'Burgundy', 'Charcoal', 'Dark Purple', 'Forest Green', 'Gray', 'Hunter Green', 'Island', 'Light Blue', 'Light Purple', 'Mocha', 'Navy Blue', 'Navy', 'None', 'Orange', 'Red', 'Royal Blue', 'Silver', 'Special Order', 'Tan', 'Teal', 'Turquiose', 'Undecided', 'White']));
		pickListSpecs.push(this.addSpec("FabricType", LathamOrderPickListSpecTypes.string, ['12oz', '16oz', '18oz', 'DuraGard', 'DuraLife 1', 'DuraLife 3', 'DuraLife 3D', 'DuraLife 3Q', 'Eurogard II', 'Eurogard', 'None', 'Pearl', 'Siemens', 'Snyder', 'special', 'Ultragard II', 'Ultragard III', 'Ultragard']));
		pickListSpecs.push(this.addSpec("FeatureControllerType", LathamOrderPickListSpecTypes.string, ['Controller w/Sensor', 'None', 'Pump Interrupter', 'Rotary Limits', 'Valve Interrupter']));
		pickListSpecs.push(this.addSpec("HoseLength", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("HousingType", LathamOrderPickListSpecTypes.string, ['concrete', 'Fort Wayne Housing', 'None', 'Polymer Housing', 'redwood', 'Short Housing for Vinyl Liner with Flush Lid', 'Short Polymer Housing', 'Short Walk on Housing', 'small', 'Standard Housing', 'Tall Housing for Gunite with Flush Lid', 'Tall Housing for Vinyl Liner with Flush Lid', 'Tall Polymer Housing', 'Tall Walk on Housing']));
		pickListSpecs.push(this.addSpec("HydromotorSize", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("LeadingEdge", LathamOrderPickListSpecTypes.string, ['1 3/4 Inch', '1 Inch Flat', '2 1/2 Inch Round', '2 1/2 Inch', '2 Inch', '3 Inch Standard', '3 Inch', '4 Inch', '5 Inch', '6 Inch', 'Fort Wayne', 'Hidden LE', 'n', 'PCS 1 1/2 Inch']));
		pickListSpecs.push(this.addSpec("LeadingEdgeAttachment", LathamOrderPickListSpecTypes.string, ['Loop', 'Rope']));
		pickListSpecs.push(this.addSpec("LeadingEdgeDiameter", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("LidType", LathamOrderPickListSpecTypes.string, ['308FLATANODIZED1250to1375in', '308FLATANODIZED1375to1600in', '308FLATPAINTED1100to1250in', '308FLATPAINTED1250to1375in', '308FLATPAINTED1375to1600in', '309FlatPainted', '309FLATPAINTED10', '309FlatPaintedWithBrackets', 'Aluminum Lid w/Hinge', 'Flat Lid No Brackets', 'Flat Lid With Brackets', 'Flush Lid 12 5/8 in w/ Riser', 'Flush Lid 14 in w/ Riser', 'Flush Lid 15 1/2 in w/ Riser', 'Flush Lid Zero Reveal', 'Flush Lid', 'No Lid', 'Undecided', 'Walk On Lid']));
		pickListSpecs.push(this.addSpec("MainLidFeet", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("MainLidInches", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("ModelType", LathamOrderPickListSpecTypes.string, ['ATOM', 'CS1000', 'CS1500', 'CS1600', 'CS1800', 'CS1800b', 'CS1800CL', 'CS1800CLb', 'CS1800FW', 'CS1800KF', 'CS1800LE', 'CS1800SW', 'CS1800SWb', 'CS1800SWU', 'CS1800VK', 'CS200', 'CS2000', 'CS250', 'CS300', 'CS3000', 'CS3000b', 'CS3000D', 'CS3000LO', 'CS3000Low', 'CS3000Q', 'CS300HD2', 'CS300HD3', 'CS300FGP', 'CS500', 'CS500FW', 'Eclipse Mini', 'Eclipse', 'Eclipseb', 'Electric Std (41 RPM)', 'Infinity 4000', 'Infinity', 'ManualTrak', 'Mini Infinity', 'MR', 'None', 'Omega', 'PCS Infinity 4000', 'PowerTrak', 'Replacement Fabric', 'Serenity']));
		pickListSpecs.push(this.addSpec("MotorEndLidFeet", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("MotorEndLidInches", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("MotorSide", LathamOrderPickListSpecTypes.string, ['Left', 'Right']));
		pickListSpecs.push(this.addSpec("MotorType", LathamOrderPickListSpecTypes.string, ['110hdro.75', '110hdro2000', '110hdro2hp', '12v solar', '3 Wire Electric', '40rpm 1997', '57rpm110', 'bison40', 'bison40l', 'CS300 Motor', 'Electric HD (30 RPM)', 'Electric HD (LV Switch)', 'Electric Std (41 RPM)', 'European Electric', 'European Hydro', 'US Hydro (220v)']));
		pickListSpecs.push(this.addSpec("NonMotorEndLidFeet", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("NonMotorEndLidInches", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("OEM", LathamOrderPickListSpecTypes.string, ['American', 'APC', 'Aquamatic', 'Cantar', 'Cover Pools II', 'Coverluxe', 'Covermatic', 'Coverstar', 'Other', 'Pool Cover Specialist', 'Pool Cover Specialists', 'PoolSaver', 'SwimWise']));
		pickListSpecs.push(this.addSpec("OrderType", LathamOrderPickListSpecTypes.string, ['System', 'MR']));
		pickListSpecs.push(this.addSpec("ParentSkuExists", LathamOrderPickListSpecTypes.string));
		pickListSpecs.push(this.addSpec("PolymerHousingMountingKit", LathamOrderPickListSpecTypes.string, ['Default', 'Fiberglass W/Standoff', 'Gunite', 'Recessed Toptrack', 'Vinyl Liner W/Stone Wall', 'Vinyl Liner']));
		pickListSpecs.push(this.addSpec("PoolType", LathamOrderPickListSpecTypes.string, ['Fiberglass', 'Gunite', 'Vinyl Liner']));
		pickListSpecs.push(this.addSpec("PreshippedCoping", LathamOrderPickListSpecTypes.string, ['True', 'False']));
		pickListSpecs.push(this.addSpec("PreshippedCorners", LathamOrderPickListSpecTypes.string, ['True', 'False']));
		pickListSpecs.push(this.addSpec("PreshippedEncapsulation", LathamOrderPickListSpecTypes.string, ['True', 'False']));
		pickListSpecs.push(this.addSpec("PreshippedGuide", LathamOrderPickListSpecTypes.string, ['True', 'False']));
		pickListSpecs.push(this.addSpec("PreshippedGuideHardware", LathamOrderPickListSpecTypes.string, ['True', 'False']));
		pickListSpecs.push(this.addSpec("PreshippedExtrusionPackage", LathamOrderPickListSpecTypes.string, ['True', 'False']));
		pickListSpecs.push(this.addSpec("PreshippedLid", LathamOrderPickListSpecTypes.string, ['True', 'False']));
		pickListSpecs.push(this.addSpec("PreshippedPolymerHousing", LathamOrderPickListSpecTypes.string, ['True', 'False']));
		pickListSpecs.push(this.addSpec("PreshippedPolymerHousingMountingKit", LathamOrderPickListSpecTypes.string, ['True', 'False']));
		pickListSpecs.push(this.addSpec("ProrationOriginalOrderDate", LathamOrderPickListSpecTypes.string, ['True', 'False']));
		pickListSpecs.push(this.addSpec("PumpType", LathamOrderPickListSpecTypes.string, ['Auto Drain', 'Little Giant 110v', 'Little Giant 220v']));
		pickListSpecs.push(this.addSpec("RollerWrap", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("RollupTube", LathamOrderPickListSpecTypes.string, ['10 Inch', '4 Inch', '4.75 Inch', '5 Inch', '6 Inch Extruded', '6 Inch Heavy Duty', '6 Inch Standard', '8 Inch']));
		pickListSpecs.push(this.addSpec("RopeAttachment", LathamOrderPickListSpecTypes.string, ['Attached', 'Continuous w/tab', 'Continuous', 'Detachable', 'Detached']));
		pickListSpecs.push(this.addSpec("RopeType", LathamOrderPickListSpecTypes.string, ['.175 dia', '.205 dia', '.205', '.220 (For Coverpools 1 replacements only) BLACK', '.250 Black Tracer', '.250 dia GREEN', '.250 dia, Heavy Duty PURPLE', '.250', '.265 dia (For Eclipse) ORANGE', '0.175', '0.205 Rope', '0.220', '0.250', 'none', 'PowerFlex']));
		pickListSpecs.push(this.addSpec("Slack", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("SliderAttachment", LathamOrderPickListSpecTypes.string, ['Attached', 'Coverpools', 'Detached', 'PCS Wheels', 'UG Detachable Rope', 'Universal Slider']));
		pickListSpecs.push(this.addSpec("SliderType", LathamOrderPickListSpecTypes.string, ['801 Slider', '801 Wire On', '801/403 Wire-on', 'Aquamatic', 'Coverpools', 'Coverstar', 'fw slider', 'None', 'PCS Topguide Slider', 'PCS Wheels', 'PCS', 'pre-403 detachable rope (white)', 'Pre-403 UG Detachable', 'Pre-403 UG Wire on slider', 'Pre-403 UG', 'Pre-403 Wire On', 'Pre-801 Wire On', 'Pre-801 Wire-on w/ Slider Plate', 'Rectangle Short', 'Residental UG', 'Snap-in Undertrack for 4" LE', 'Snap-in Undertrack', 'ug 403/801 non wire-on', 'UG Detachable Rope', 'UG Sliders', 'UG Wire On FW', 'UG Wire On', 'Universal Slider']));
		pickListSpecs.push(this.addSpec("TrackColor", LathamOrderPickListSpecTypes.string, ['Bronze', 'Gray', 'Standard', 'Tan']));
		pickListSpecs.push(this.addSpec("TrackLengthFeet", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("TrackLengthInches", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("TotalTrackLengthInches", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("TrackSpaceFeet", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("TrackSpaceInches", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("TotalTrackSpaceInches", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("TotalPerimeterInches", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("TotalPerimeterInches3Sides", LathamOrderPickListSpecTypes.number));
		pickListSpecs.push(this.addSpec("TrackType", LathamOrderPickListSpecTypes.string, ['403 Undertrack', '801 Track and Shim Only (Coping, etc. must be ordered as options)', 'Cinderella UG', 'Encapsulated Underguide Aluminum w/ vinyl clip', 'Encapsulated Underguide Gunite 801 track', 'Encapsulated Underguide Gunite w/fiber optic 801 track', 'Encapsulated Underguide- Residential', 'Encapsulated Underguide w/Plastic Housing', 'Encapsulated Underguide w/viny liner 801 track', 'Encapsulated Underguide w/vinyl liner w/fiber optic 801 track', 'Fort Wayne Underguide', 'PCS Drill Up UG', 'PCS Horizontal Flush no Retainer', 'PCS Horizontal Flush W/Retainer', 'PCS Retainer UG', 'PCS TG Screw Down', 'PCS TG Snap Down', 'Recessed Horizontal (screw down)', 'Recessed Horizontal w/ Metal Housing (Gray)', 'Recessed Horizontal w/Plastic Housing', 'Recessed Horizontal', 'Recessed Vertical w/ Metal Housing (Beige)', 'Recessed Vertical w/ Metal Housing (Gray)', 'Recessed Vertical w/ Plastic Housing', 'Recessed Vertical', 'Residential', 'RHG Screw Down', 'RHG', 'Slim Flush Track', 'Standard Topguide (Pre 2002)', 'Standard Topguide 403', 'Standard Underguide (Pre 2002)', 'Standard Underguide 801', 'Standard', 'Topguide (Before 4/17/2003)', 'Toptrack snap down', 'Toptrack w/holes', 'Toptrack', 'Underguide (Before 4/17/2003)', 'Underguide 403', 'Undertrack drill-up', 'Undertrack optimized for retainer', 'Undertrack', 'Vertical Flush']));
		pickListSpecs.push(this.addSpec("UnitLocation", LathamOrderPickListSpecTypes.string, ['Deck', 'Recessed']));
		pickListSpecs.push(this.addSpec("WebbingType", LathamOrderPickListSpecTypes.string, ['Black Dacron', 'Black Keder', 'Blue Dacron', 'Blue Keder', 'Brown Dacron', 'Charcoal Dacron', 'Color Matched Dacron', 'Extra Wide', 'Gray Dacron', 'Gray Keder', 'Heat Sealed Color', 'Heat Sealed Sewn', 'heat sealed webbing (color)', 'Heat Sealed White', 'Navy Dacron', 'Sewn', 'Tan Dacron', 'Tan Keder', 'Vented', 'White Dacron']));

		return pickListSpecs;
	}

	private addSpec(specName: string, specType: LathamOrderPickListSpecTypes, specValues: string[] = null): ILathamOrderPickListSpecModel {
		const pickListSpec = new LathamOrderPickListSpecModel();
		pickListSpec.specName = specName;
		pickListSpec.specType = specType;
		if (specValues) {
			if (specValues.findIndex(x => x === '') >= 0)
				alert(`${specName} has a blank value`);
			pickListSpec.specValues = [...specValues.sort(sortBy("^"))];
		}

		return pickListSpec;
	}
}