import { ITeamMemberModel } from "./team-member.model";
export interface ITeamModel {
	teamId: number;
	uuid: string;
	version: number;
	teamName: string;
	teamLeadUserId?: number;
	teamLeadFullName: string;
	active: boolean;

	teamMembers: ITeamMemberModel[];
}

export class TeamModel implements ITeamModel {
	teamId: number;
	uuid: string;
	version: number;
	teamName: string;
	teamLeadUserId?: number;
	teamLeadFullName: string;
	active: boolean;

	teamMembers: ITeamMemberModel[];
}