export class SlickCurrencyMaskOptions {
	prefix: string;
	decimal: string;
	thousands: string;
	precision: number;
	allowNegative: boolean;	
	updateOnBlurOnly: boolean;
	defaultToZero: boolean;

	constructor() {
		this.prefix = "$";
		this.decimal = ".";
		this.thousands = ",";
		this.precision = 2;
		this.allowNegative = true;
		this.updateOnBlurOnly = true;
		this.defaultToZero = true;
	}
}
