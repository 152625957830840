import { CustomerModel } from "@models/customers/customer.model";
import { PaymentTermModel, ILineItemModel, IInventoryLineItemModel, IAppliedPaymentModel, AddressModel, ShippingAddressModel, JellyFishOrderSpecsModel, IJobCostingDetailModel } from "@models";
import { UtilsService } from "@services/utils/utils.service";
import { IInvoiceQCCheckModel } from "./invoice-qc-check.model";
import { GlobalsService } from "@services/utils/globals.service";

export interface IInvoiceModel {
	companyId: number;
	invoiceId: number;
	invoiceGroupUuid: string;
	invoiceGroupSeq: number;
	hash: string;
	hashDebug: string;
	isQuote: boolean;
	isSalesOrder: boolean;
	jobId?: number;
	inventoryWarehouseId?: number;
	inventoryWarehouseName: string;
	useDefaultInventory: boolean;
	createdBy?: number;
	salesRepUserId?: number;
	salesRepFullName: string;
	lathamOrderId?: number;
	invoiceStatusId: number;
	invoiceStatusName: string;
	quoteDate: Date;
	invoiceDate?: Date;
	invoiceDueDate: Date;
	quoteValidUntilDate?: Date;
	documentKey: string;
	paymentTermId: number;
	paymentTerm: PaymentTermModel;
	invoiceNumber: string;
	uuid: string;
	purchaseOrderNumber: string;
	taxRate: number;
	taxExempt: boolean;
	coloradoShippingSurcharge: boolean;
	cereTaxRate?: number;
	downPaymentPercent?: number;
	downPaymentAmount?: number;
	downPaymentPaid: boolean;
	notes: string;
	customerNotes: string;
	lathamOrderNotes: string;
	lathamOfficeNotes: string;
	acceptedDate?: Date;
	acceptedSignature: string;
	acceptedIPAddress: string;
	quickbooksChanged: boolean;
	quickbooksId: string;
	quickbooksDocNumber: string;
	syncToQuickbooks?: boolean;
	businessCentralChanged: boolean;
	businessCentralId: string;
	syncToBusinessCentral: boolean;
	lathamOrderReason: string;
	preAuthPaymentId: string;
	preAuthMessage: string;
	custom1: string;
	custom2: string;
	custom3: string;
	custom4: string;
	pricingChanged: boolean;
	appointmentNotes: string[];
	customer: CustomerModel;
	jobSiteAddress: AddressModel;
	lineItems: ILineItemModel[];
	splitLineItems: ILineItemModel[];
	inventoryLineItems: IInventoryLineItemModel[];
	lineItemGroupId: number;
	appliedPayments: IAppliedPaymentModel[];
	invoiceQCChecks: IInvoiceQCCheckModel[];
	outstandingBalance: number;
	invoiceTotal: number;
	appliedPaymentsTotal: number;
	shippingRecordId?: number;
	shippingAddressId?: number;
	billingAddressId?: number;
	proNumber: string;
	CarrierId?: number;
	estimatedShipDate?: Date;
	shipped: boolean;
	submittedToParent: boolean;
	submittedDate?: Date;
	shippingAddress: ShippingAddressModel;
	billingAddress: AddressModel;
	selectedJobPhotoDocumentIds: number[];
	selectedCreditCardPaymentTypeId: number;
	jellyFishOrderSpecsId?: number;
	jellyFishOrderSpecsModel: JellyFishOrderSpecsModel;
	jellyFishOrderId?: number;
	lockJellyFishSpecs: boolean;
	jobIsComplete: boolean;
	jobCostingDetailModel: IJobCostingDetailModel;
	flagSaveForTaxUpdate: boolean;
	quoteStatus: string;
	reasonId: number;
	detailedReasonId: number;
	reasonNotes: string;
}

export class InvoiceModel implements IInvoiceModel {
	constructor() {
		this.companyId = GlobalsService.company.companyId;
		this.invoiceId = 0;
		this.invoiceGroupUuid = UtilsService.newGuid();
		this.invoiceGroupSeq = 1;
		this.uuid = UtilsService.newGuid();
		this.documentKey = UtilsService.newGuid();
		this.invoiceStatusId = 1;
		this.createdBy = GlobalsService.userInfo.userId;
		this.downPaymentPaid = false;
		this.pricingChanged = false;
		this.lineItems = [];
		this.inventoryLineItems = [];
		this.appliedPayments = [];
		this.invoiceQCChecks = [];
		this.shippingAddress = new ShippingAddressModel();
		this.jellyFishOrderSpecsModel = new JellyFishOrderSpecsModel();

		if (GlobalsService.userInfo.isSalesRep) {
			this.salesRepUserId = GlobalsService.userInfo.userId;
			this.salesRepFullName = GlobalsService.userInfo.fullName;
		}

		if (GlobalsService.company.useAdvancedTax === false)
			this.taxRate = GlobalsService.company.taxRate;

		if (GlobalsService.company.downPaymentType === 1 || GlobalsService.company.downPaymentType === 3)
			this.downPaymentPercent = GlobalsService.company.defaultDownPaymentPercent;
		else if (GlobalsService.company.downPaymentType === 2 || GlobalsService.company.downPaymentType === 4)
			this.downPaymentAmount = GlobalsService.company.defaultDownPaymentAmount;
		this.useDefaultInventory = true;
	}

	companyId: number;
	invoiceId: number;
	invoiceGroupUuid: string;
	invoiceGroupSeq: number;
	hash: string;
	hashDebug: string;
	isQuote: boolean;
	isSalesOrder: boolean;
	jobId?: number;
	inventoryWarehouseId?: number;
	inventoryWarehouseName: string;
	useDefaultInventory: boolean;
	createdBy?: number;
	salesRepUserId?: number;
	salesRepFullName: string;
	lathamOrderId?: number;
	invoiceStatusId: number;
	invoiceStatusName: string;
	quoteDate: Date;
	invoiceDate?: Date;
	invoiceDueDate: Date;
	quoteValidUntilDate?: Date;
	documentKey: string;
	paymentTermId: number;
	paymentTerm: PaymentTermModel;
	invoiceNumber: string;
	uuid: string;
	purchaseOrderNumber: string;
	taxRate: number;
	taxExempt: boolean;
	coloradoShippingSurcharge: boolean;
	cereTaxRate?: number;
	downPaymentPercent?: number;
	downPaymentAmount?: number;
	downPaymentPaid: boolean;
	notes: string;
	customerNotes: string;
	lathamOrderNotes: string;
	lathamOfficeNotes: string;
	acceptedDate?: Date;
	acceptedSignature: string;
	acceptedIPAddress: string;
	quickbooksChanged: boolean;
	quickbooksId: string;
	quickbooksDocNumber: string;
	syncToQuickbooks?: boolean;
	businessCentralChanged: boolean;
	businessCentralId: string;
	syncToBusinessCentral: boolean;
	lathamOrderReason: string;
	preAuthPaymentId: string;
	preAuthMessage: string;
	custom1: string;
	custom2: string;
	custom3: string;
	custom4: string;
	pricingChanged: boolean;
	appointmentNotes: string[];
	customer: CustomerModel;
	jobSiteAddress: AddressModel;
	lineItems: ILineItemModel[];
	splitLineItems: ILineItemModel[];
	inventoryLineItems: IInventoryLineItemModel[];
	lineItemGroupId: number;
	appliedPayments: IAppliedPaymentModel[];
	invoiceQCChecks: IInvoiceQCCheckModel[];
	outstandingBalance: number;
	invoiceTotal: number;
	appliedPaymentsTotal: number;
	shippingRecordId?: number;
	shippingAddressId?: number;
	billingAddressId?: number;
	proNumber: string;
	CarrierId?: number;
	estimatedShipDate?: Date;
	shipped: boolean;
	submittedToParent: boolean;
	submittedDate?: Date;
	shippingAddress: ShippingAddressModel;
	billingAddress: AddressModel;
	selectedJobPhotoDocumentIds: number[];
	selectedCreditCardPaymentTypeId: number;
	jellyFishOrderSpecsId?: number;
	jellyFishOrderSpecsModel: JellyFishOrderSpecsModel;
	jellyFishOrderId?: number;
	lockJellyFishSpecs: boolean;
	jobIsComplete: boolean;
	jobCostingDetailModel: IJobCostingDetailModel;
	flagSaveForTaxUpdate: boolean;
	quoteStatus: string;
	reasonId: number;
	detailedReasonId: number;
	reasonNotes: string;
}
