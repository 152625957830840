import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlickGoogleAddressSearchDirective } from "./slick-google-address-search.directive";

@NgModule({
	imports: [CommonModule],
	declarations: [SlickGoogleAddressSearchDirective],
	exports: [SlickGoogleAddressSearchDirective]
})
export class SlickGoogleAddressSearchModule { }

export { SlickGoogleAddressSearchDirective }