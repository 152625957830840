import { GlobalsService } from "@services";

export interface IEnhancementModel {
	enhancementId: number;
	userFullName: string;
	createdDate: Date;
	dateDisplay: string;
	enhancementType: string;
	shortDescription: string;
	description: string;
	priority: string;
	estimatedHours?: number;
	actualHours?: number;
	inProcess: boolean;
	completed: boolean;
	completedBy: string;
	companyName: string;
	email: string;
	requestedBy: string;
	phoneNumber: string;
}

export class EnhancementModel implements IEnhancementModel {
	constructor() {
		this.requestedBy = GlobalsService.userInfo.fullName;
		this.companyName = GlobalsService.company.companyName;
		this.email = GlobalsService.userInfo.emailAddress;
		this.phoneNumber = GlobalsService.userInfo.phoneNumber;
    }
	enhancementId: number;
	userFullName: string;
	createdDate: Date;
	dateDisplay: string;
	shortDescription: string;
	enhancementType: string;
	description: string;
	priority: string;
	estimatedHours?: number;
	actualHours?: number;
	inProcess: boolean;
	completed: boolean;
	completedBy: string;
	companyName: string;
	email: string;
	requestedBy: string;
	phoneNumber: string;
}