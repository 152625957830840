export interface IJobCostingSetupModel {
	companyId: number;
	useJobs: boolean;
	useHours: boolean;
	driverAmount: number;
	indirectLabor: number;
	overheadCosts: number;
	estimatedIndirectLabor: number;
	estimatedOverheadCost: number;
	unproductiveDirectLaborPerJob: number;
	includeOpenJobs: boolean;

}

export class JobCostingSetupModel implements IJobCostingSetupModel {
	companyId: number;
	useJobs: boolean;
	useHours: boolean;
	driverAmount: number;
	indirectLabor: number;
	overheadCosts: number;
	estimatedIndirectLabor: number;
	estimatedOverheadCost: number;
	unproductiveDirectLaborPerJob: number;
	includeOpenJobs: boolean;

}
