import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IJobModel, IJobDisplayListModel, IJobGridModel, JobModel, InvoiceModel, IInvoiceModel, AddressModel, IDocumentModel, IAppointmentModel } from "@models";
import { IJobGridRequestModel } from "@models/jobs/job-grid-request.model";
import { UtilsService } from './utils/utils.service';
import { InvoicesService } from "./invoices.service";
import * as moment from 'moment';
import { GlobalsService } from "./utils/globals.service";
import { IOpenAppointmentModel } from "../models/appointments/open-appointment.model";

export enum DefaultInvoiceTypes { Estimate, SalesOrder, Invoice }

@Injectable()
export class JobsService {
	constructor(private readonly httpService: HttpService,
		private readonly invoicesService: InvoicesService) { }

	async getNewJobModel(): Promise<IJobModel> {
		const returnJobModel = new JobModel();
		returnJobModel.jobId = 0;
		returnJobModel.documentKey = UtilsService.newGuid();
		returnJobModel.jobName = (await this.httpService.get("/jobs/getNextJobName")).jobName;

		returnJobModel.invoice = new InvoiceModel();
		returnJobModel.invoice.invoiceId = 0;
		returnJobModel.invoice.documentKey = UtilsService.newGuid();
		returnJobModel.invoice.createdBy = GlobalsService.userInfo.userId;

		return returnJobModel;
	}

	async getNextJobName(): Promise<string> {
		return (await this.httpService.get("/jobs/getNextJobName")).jobName;
	}

	async getJobsForGridView(displayRequest: IJobGridRequestModel): Promise<IJobDisplayListModel[]> {
		return await this.httpService.post("/jobs/getJobsForGridView", displayRequest);
	}

	async getJobsForInfiniteScroll(pageNumber: number, searchString: string): Promise<IJobGridModel[]> {
		const params = {
			pageNumber,
			searchString
		}
		return await this.httpService.get("/jobs/getJobsForInfiniteScroll", params);
	}

	async getJobForGridView(jobId: number) {
		return await this.httpService.get("/jobs/getJobForGridView", { jobId: jobId });
	}

	async getJob(jobId: number): Promise<IJobModel> {
		return await this.httpService.get("/jobs/getJob", { jobId: jobId });
	}

	async getJobByAppointmentId(appointmentId: number): Promise<IJobModel> {
		return await this.httpService.get("/jobs/getJobByAppointmentId", { appointmentId: appointmentId });
	}

	async getJobByInvoiceId(invoiceId: number): Promise<IJobModel> {
		return await this.httpService.get("/jobs/getJobByInvoiceId", { invoiceId: invoiceId });
	}

	async updateJob(jobModel: IJobModel): Promise<IJobModel> {
		return await this.httpService.post("/jobs/updateJob", jobModel);
	}

	async addJob(jobModel: IJobModel): Promise<IJobModel> {
		// Need to copy this here because the addJob will overwrite the model
		const invoiceId = jobModel.invoiceId;

		// Add the job so that we now have a jobId
		const returnJobModel = await this.httpService.post("/jobs/addJob", jobModel);

		// So, if we created a new invoice without saving the job first, we need to update the invoice jobId
		// to the job we just created
		if (invoiceId)
			await this.httpService.patch(`/invoices/updateJobId?invoiceId=${invoiceId}`, returnJobModel.jobId);

		return returnJobModel;
	}

	async getJobInvoice(jobModel: IJobModel, defaultInvoiceType: DefaultInvoiceTypes): Promise<IInvoiceModel> {
		if (jobModel.invoice && jobModel.invoice.invoiceId > 0) {
			return await this.invoicesService.getInvoice(jobModel.invoiceId);

		}

		let jobInvoice = new InvoiceModel();
		//if its a new job and they save an invoice, we need to get the invoice based off of the invoice id since the job won't have an id yet.
		if (!jobModel.jobId || jobModel.jobId === 0) {
			if (jobModel.invoiceId && jobModel.invoiceId !== 0)
				jobInvoice = await this.invoicesService.getInvoice(jobModel.invoiceId);
		}
		else {
			jobInvoice = await this.invoicesService.getInvoiceByJobId(jobModel.jobId);
		}

		jobInvoice.jobId = jobModel.jobId;
		jobInvoice.isQuote = false;
		switch (defaultInvoiceType) {
			case DefaultInvoiceTypes.Estimate:
				jobInvoice.isQuote = true;
				jobInvoice.isSalesOrder = false;
				break;
			case DefaultInvoiceTypes.SalesOrder:
				jobInvoice.isQuote = false;
				jobInvoice.isSalesOrder = true;
				break;
			case DefaultInvoiceTypes.Invoice:
				jobInvoice.isQuote = false;
				jobInvoice.isSalesOrder = false;
				break;
		}

		if (!jobInvoice.invoiceNumber)
			jobInvoice.invoiceNumber = jobModel.jobName;
		
		if (!jobInvoice.purchaseOrderNumber && jobModel.showJobSiteCustomer)
			jobInvoice.purchaseOrderNumber = (jobModel.jobCustomer === null || jobModel.jobCustomer === undefined) ? jobModel.billToCustomer.lastName : jobModel.jobCustomer.lastName;

		if (!jobInvoice.purchaseOrderNumber && jobModel.showOneTimeAddress)
			jobInvoice.purchaseOrderNumber = (jobModel.jobCustomer === null || jobModel.jobCustomer === undefined) ? jobModel.billToCustomer.lastName : jobModel.jobCustomer.lastName;

		if (!jobInvoice.invoiceDate)
			jobInvoice.invoiceDate = moment().startOf("date").toDate();

		jobInvoice.customer = jobModel.billToCustomer;

		if (jobInvoice.invoiceId === 0) {
			jobInvoice.paymentTermId = jobModel.billToCustomer.paymentTermId;
			jobInvoice.taxExempt = jobModel.billToCustomer.taxExempt;

			if (GlobalsService.userInfo.isSalesRep) {
				jobInvoice.salesRepUserId = GlobalsService.userInfo.userId;
				jobInvoice.salesRepFullName = GlobalsService.userInfo.fullName;
			}

			if (GlobalsService.company.useAdvancedTax === false)
				jobInvoice.taxRate = GlobalsService.company.taxRate;

			if (GlobalsService.company.downPaymentType === 1 || GlobalsService.company.downPaymentType === 3)
				jobInvoice.downPaymentPercent = GlobalsService.company.defaultDownPaymentPercent;
			else if (GlobalsService.company.downPaymentType === 2 || GlobalsService.company.downPaymentType === 4)
				jobInvoice.downPaymentAmount = GlobalsService.company.defaultDownPaymentAmount;

			jobInvoice = await this.invoicesService.updateInvoice(jobInvoice)
		}

		//since this is a new job, this information will not be updated with the invoice
		//so we have to do it here to get the correct fuel surcharge percentages/amount
		if (jobModel.jobCustomer && jobModel.showJobSiteCustomer) {
			jobInvoice.jobSiteAddress = new AddressModel();
			jobInvoice.jobSiteAddress.city = jobModel.jobCustomer.city;
			jobInvoice.jobSiteAddress.zipcode = jobModel.jobCustomer.billingZipcode;
		}

		if (jobModel.oneTimeAddress && jobModel.showOneTimeAddress) {
			jobInvoice.jobSiteAddress = new AddressModel();
			jobInvoice.jobSiteAddress = jobModel.oneTimeAddress;
		}

		return jobInvoice;
	}

	getJobPhotos(jobId: number): Promise<IDocumentModel[]> {
		return this.httpService.get(`/jobs/getJobPhotos?jobId=${jobId}`);
	}
	
	getOpenAppointments(appointments: IAppointmentModel[]): IOpenAppointmentModel[] {
		let openAppts = appointments.filter(x => x.appointmentStatusDisplayName !== "Complete" && x.appointmentStatusDisplayName !== "Cancelled" && x.appointmentStatusDisplayName !== "Not Finished");
		let returnAppts = openAppts.map(x => {
			return {
				scheduledDateTime: x.scheduledDateTime,
				appointmentId: x.appointmentId,
				appointmentType: x.appointmentTypeDisplayName,
				resourceId: x.resourceId,
				travelMinutes: x.travelMinutes,
				scheduledMinutes: x.scheduledMinutes
			};
		});

		return returnAppts;
	}
}
