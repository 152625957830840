import { IAddressModel, AddressModel } from "@models";

export interface IShippingModel {
    shipTo: string;
    standardShipDay: boolean;
    customShipDate: Date;
    carrier: string;
    shippingAddress: IAddressModel;
    billing: string;
    thirdPartyAccountNumber: string;
    carrierNotes: string;
}

export class ShippingModel implements IShippingModel
{
    shipTo: string;
    standardShipDay: boolean;
    customShipDate: Date;
    carrier: string;
    shippingAddress: IAddressModel;
    billing: string;
    thirdPartyAccountNumber: string;
    carrierNotes: string;
}