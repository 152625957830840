import { Component, ViewChild } from "@angular/core";
import { SlickEmailerDialogComponent, SlickToastService } from "@slick-components";
import { ISlickEmailerAddressModel, ISlickFileModel, SlickConfirmDialogComponent } from "@slick-components";
import { IEmailerModel, IEmailerAddressModel, EmailerModel } from "@models";
import { UtilsService, LookupService, SleepService, HttpService } from "@services";

@Component({
	selector: "emailer-dialog",
	templateUrl: "./emailer-dialog.component.html"
})
export class EmailerDialogComponent {
	@ViewChild("emailerDialogRef") emailerDialogRef: SlickEmailerDialogComponent;
	@ViewChild("noEmailAddressEnteredRef") noEmailAddressEnteredRef: SlickConfirmDialogComponent;
	

	resolve: any;
	emailerModel: IEmailerModel;
	toEmailAddresses: IEmailerAddressModel[] = [];

	constructor(private httpService: HttpService, private lookupService: LookupService, private slickToastService: SlickToastService) {
	}

	async showDialog(emailerModel: IEmailerModel = null): Promise<IEmailerModel> {
		return new Promise<IEmailerModel>(async (resolve) => {
			this.resolve = resolve;

			this.emailerModel = (emailerModel) ? emailerModel : new EmailerModel();
			this.emailerModel.uuid = (this.emailerModel.uuid) ? this.emailerModel.uuid : UtilsService.newGuid();
			await SleepService.sleep();

			this.emailerDialogRef.showDialog();
		})
	}

	async toAddressSearch(searchText) {
		this.toEmailAddresses = await this.findEmailAddress(searchText);
	}

	async onSendEmail(emailerModel: IEmailerModel) {
		try {
			await SleepService.sleep(500);

			// Check to make sure that there is an actual email address to send to
			let hasValidEmailAddress = true;
			emailerModel.toEmailAddresses.forEach(addr => {
				if (!addr.emailAddress)
					hasValidEmailAddress = false;
			})
			if (!hasValidEmailAddress) {
				this.emailerDialogRef.spinnerStatus = "error";
				await this.noEmailAddressEnteredRef.confirm();
				return;
			}

			const formData: FormData = new FormData();
			emailerModel.attachments.forEach(file => {
				// It's possible that we got a simple file name instead of an actual file.
				// Don't send the file in a multi-part if it's just a file name
				if (file.file) {
					if (file.base64Image && file.base64Image.length > 0) {
						let fileCopy: ISlickFileModel = UtilsService.clone(file);
						let fileName = file.name;
						delete fileCopy.file;
						delete fileCopy.thumbnailBase64Image;
						let fileJSON = JSON.stringify(fileCopy);
						formData.append('image_' + file.uuid, fileJSON);
					}
					else {
						formData.append('file', file.file, file.file.name);
					}
				}
			})

			// Clean up the model and send it
			let emailerModelTemp: IEmailerModel = UtilsService.clone(emailerModel);
			emailerModelTemp.attachments = emailerModelTemp.attachments.map(att => {
				delete att.file;
				delete att.base64Image;
				delete att.thumbnailBase64Image;
				return att;
			});
			formData.append("emailerModel", JSON.stringify(emailerModelTemp));
			const response = await this.httpService.postMultipart("emailer/sendEmail", formData);

			if (response.status === 'Ok') {
				this.emailerDialogRef.emailSent();
				this.slickToastService.showSuccess("Email sent")
				await SleepService.sleep(500);

				this.resolve(this.emailerModel);
			}
			else {
				this.emailerDialogRef.emailError();
			}
		}
		catch (err) {
			console.error(err);
			this.emailerDialogRef.emailError();
		}
	}

	onCancelEmail() {
		this.resolve(null);
	}

	private async findEmailAddress(searchString): Promise<ISlickEmailerAddressModel[]> {
		let returnAddresses = (<ISlickEmailerAddressModel[]>await this.httpService.get("emailer/getEmailerAddresses", { searchString: searchString }));

		returnAddresses = returnAddresses.sort((a: IEmailerAddressModel, b: IEmailerAddressModel) => {
			let aSortText = (a.displayName + " [" + a.emailAddress + "]").toLowerCase();
			let bSortText = (b.displayName + " [" + b.emailAddress + "]").toLowerCase();
			if (aSortText < bSortText)
				return -1;
			else if (aSortText > bSortText)
				return 1;
			else
				return 0;
		})

		return returnAddresses;
	}
}
