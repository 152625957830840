import { ISlickGridColumnModel } from "./slick-grid-column.model";

export interface ISlickGridFavoriteModel {
	favoriteName: string;
	columns: ISlickGridColumnModel[];
}

export class SlickGridFavoriteModel implements ISlickGridFavoriteModel {
	favoriteName: string;
	columns: ISlickGridColumnModel[];
}

