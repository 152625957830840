import { Injectable } from "@angular/core";

class SlickFunctionLockModel {
	uuid: string;
	functionKey: string;
	infiniteLoopCheck: number = 100;
	promise: Promise<void>;
	lockCount: number;
	intervalId: NodeJS.Timer;
}

@Injectable()
export class SlickFunctionLockService {
	private functionLocks: any = {};

	constructor() {}

	lock(functionKey: string, iterationTimeout: number = 200): Promise<void> {
		if (!this.functionLocks[functionKey])
			this.functionLocks[functionKey] = [];

		const functionLock = new SlickFunctionLockModel();
		functionLock.functionKey = functionKey;
		let d = new Date().getTime();
		const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
		functionLock.uuid = uuid;
		functionLock.lockCount = this.functionLocks[functionKey].length + 1;
		functionLock.promise = new Promise<any>((resolve, reject) => {
			functionLock.intervalId = setInterval(() => {
				if (functionLock.lockCount === 1 || functionLock.infiniteLoopCheck-- === 0) {
					clearInterval(functionLock.intervalId);
					if (functionLock.infiniteLoopCheck === 0) {
						alert("Inifite loop detected for " + functionLock.functionKey);
					}
					resolve(null);
				}
			}, iterationTimeout);
		});

		
		this.functionLocks[functionKey].push(functionLock);

		return functionLock.promise;
	}

	release(functionKey: string): number {
		if (this.functionLocks && this.functionLocks[functionKey]) {
			this.functionLocks[functionKey].splice(0, 1);
			this.functionLocks[functionKey].forEach((fl: SlickFunctionLockModel) => fl.lockCount--);

			return this.functionLocks[functionKey].length;
		}
		else {
			return -1;
		}
	}
	
}

