export interface ISmartlistQuestionModel {
	smartlistQuestionId: number;
	question: string;
	shortQuestion: string;
	viewOrder: number;
	active: boolean;
}

export class SmartlistQuestionModel implements ISmartlistQuestionModel {
	constructor() {
		this.smartlistQuestionId = 0;
		this.active = true;
	}

	smartlistQuestionId: number;
	question: string;
	shortQuestion: string;
	viewOrder: number;
	active: boolean;
} 