<div style="width: 400px;" class="mb-2 mt-1">
	<label>Warehouse</label>
	<slick-drop-down [disabled]="disableWarehouse"
					 [items]="inventoryWarehouses"
					 [ngModel]="inventoryWarehouseId"
					 (onSelect)="onInventoryWarehouseSelect($event)"
					 allowEmpty="false"></slick-drop-down>
	<form-error *ngIf="isSubmitted === true && !inventoryWarehouseId">Inventory Warehouse is required</form-error>
</div>
 
<div class="inventory-line-items d-flex flex-column flex-fill">
	<table class="table table-bordeless table-striped table-sm border" style="min-width: 580px;">
		<colgroup>
			<col style="width: 0px" />
			<col style="width: 120px;" />
			<col />
			<col style="width: 40px" />
			<col *ngIf="showItemLocation" style="width: 200px" />
			<col style="width: 120px" />

			<col *ngIf="editable" style="width: 25px;" />
		</colgroup>

		<thead class="thead-light border-bottom">
			<tr>
				<th class="image"></th>
				<th style="text-align: left;">SKU</th>
				<th style="text-align: left;">Description&nbsp;</th>
				<th *ngIf="!summary" style="text-align: right">Qty</th>
				<th *ngIf="showItemLocation" style="text-align: right">&nbsp;&nbsp;Inventory Location</th>
				<th *ngIf="!summary" style="text-align: right">Qty On Hand</th>

				<th *ngIf="editable"></th>
			</tr>
		</thead>

		<tbody>
			<!-- Line Items -->
			<tr *ngFor="let lineItem of visibleLineItems" [attr.data-uuid]="lineItem.uuid" class="line-item-row" [ngClass]="{ 'editable': lineItem.editable}">
				<td *ngIf="lineItem.imageThumbnailUrl" class="image" style="padding-left: 4px; padding-top: 2px;"><img [src]="lineItem.imageThumbnailUrl" (click)="enlargeImage(lineItem.imageUrl)" /></td>
				<td *ngIf="!lineItem.imageThumbnailUrl" class="image"></td>
				<td>
					<div *ngIf="!lineItem.editable || lineItem.checkedOnCheckList">&nbsp;{{lineItem.sku}}</div>
					<div *ngIf="lineItem.editable && !lineItem.checkedOnCheckList">
						<input #autoCompleteSkuInputRef type="text" class="form-control condensed" [(ngModel)]="lineItem.sku" autocomplete="off" placeholder="Sku" />
						<slick-auto-complete [inputRef]="autoCompleteSkuInputRef"
											 [items]="skuResults"
											 showNoItemsFoundDisplay="useCustomSKU"
											 (onRefresh)="searchBySku($event)"
											 (onSelect)="replaceWithSelectedSkuItem($event, lineItem)"
											 (onFreeformTextSelect)="replaceWithSelectedSkuFreeform(lineItem)"
											 listWidth="455px">
							<ng-template let-result [slickAutoCompleteListItemTemplate]>
								<div class="d-flex flex-fill" style="white-space: pre-wrap; overflow-wrap: break-word;">
									<div *ngIf="result.imageThumbnailUrl">
										<img class="float-start" src="{{ result.imageThumbnailUrl }}" width="40" height="40" />
									</div>
									<div class="p-0 ms-1" style="line-height: 18px;">
										<b>{{ result.sku }}</b> - {{ result.description }}
									</div>
								</div>
							</ng-template>
						</slick-auto-complete>
					</div>
				</td>
				<td>
					<div *ngIf="!lineItem.editable || lineItem.checkedOnCheckList">{{lineItem.description}}</div>
					<div *ngIf="lineItem.editable && lineItem.isBlankLineItem && !lineItem.checkedOnCheckList">
						<input type="text" class="form-control condensed description" [disabled]="editable" placeholder="Description" />
					</div>
					<div *ngIf="lineItem.editable && !lineItem.isBlankLineItem && !lineItem.checkedOnCheckList">
						<input type="text" class="form-control condensed description" [(ngModel)]="lineItem.description" autocomplete="off" placeholder="Description" (keydown)="onLineItemKeyDown(lineItem)" />
					</div>
				</td>
				<td>
					<div *ngIf="!lineItem.editable || lineItem.checkedOnCheckList" style="text-align: right">{{lineItem.quantity}}&nbsp;</div>
					<div *ngIf="lineItem.editable && lineItem.isBlankLineItem && !lineItem.checkedOnCheckList">
						<input type="number" step="any" class="form-control condensed quantity" disabled="disabled" style="text-align: right;" placeholder="Qty" />
					</div>
					<div *ngIf="lineItem.editable && !lineItem.isBlankLineItem && !lineItem.checkedOnCheckList">
						<input type="number" step="any" class="form-control condensed quantity" style="text-align: right;" (keyup)="updateLineItem($event)" [(ngModel)]="lineItem.quantity" placeholder="Qty" />
					</div>
				</td>
				<td *ngIf="showItemLocation && lineItem.isBlankLineItem">&nbsp;</td>
                <td *ngIf="showItemLocation && !lineItem.isBlankLineItem" >

                    <div>

                        <div *ngIf="(lineItem.inventoryItemLocations?.length ?? 0) > 0" style="line-height: 28px;">
                            <inventory-locations-search [disabled]="disableWarehouse"
														(inventoryItemLocationChange)="onLocationSelect($event, lineItem.uuid)"
														[inventoryLineItem]="lineItem"
                                                        [warehouseId]="inventoryWarehouseId"
                                                        [itemId]="lineItem.itemId"></inventory-locations-search>
                        </div>
                       
                    </div>
                    <div *ngIf="(lineItem.inventoryItemLocations?.length ?? 0) === 0">N/A</div>

                </td>
				<td style="text-align:center">
					{{lineItem.locationQuantityOnHand}}&nbsp;
				</td>

				<!-- If this line is editable, then show some icons -->
				<td *ngIf="(editable && lineItem.isBlankLineItem)">&nbsp;</td>
				<td *ngIf="editable && !lineItem.isBlankLineItem" style="text-align: right;">
					<i class="bi bi-trash" style="cursor: pointer" tabindex="-1" (click)="deleteLineItem(lineItem)"></i>
				</td>

			</tr>

		</tbody>
	</table>
</div>

<slick-dialog #enlargedImageDialogRef header="Image" [width]="525">
	<div style="min-height: 500px; min-width: 500px; line-height: 500px; vertical-align: central; text-align: center">
		<img [src]="enlargedImageUrl" style="object-fit: contain; max-width: 500px; max-height: 500px" />
	</div>

	<slick-dialog-footer>
		<button class="btn btn-outline-primary" style="width: 120px;" (click)="closeImageDialog()">Ok</button>
	</slick-dialog-footer>
</slick-dialog>

