<div class="d-flex align-items-center border-bottom ps-4" style="min-height: 60px;">
	<div class="flex-fill"><h2 class="p-0 m-0" style="font-weight: bold;">  
	<div class="d-flex flex-column mt-3 " style="width: 300px; padding-bottom: 7px;">
		<slick-search-bar #searchBarRef [(ngModel)]="searchString" [showAddButton]="false" [showEditButton]="false" style="font-size: 20px;"></slick-search-bar>
	</div>
	<slick-auto-complete [searchBarRef]="searchBarRef"
						[items]="searchResults"
						(onRefresh)="search($event)"
						(onSelect)="onSelect($event)" listWidth="500" listHeight="600px"  [offset]="-30">
						<ng-template let-appointment [slickAutoCompleteListItemTemplate]>
							<div class="my-2" style="line-height: 1rem">
								<div class="mb-1">
									<div style="display: inline-block; width: 250px; vertical-align: top; margin-right: 10px">
										{{appointment.jobSiteFullName}}<br />
										<div *ngIf='appointment.jobSiteCompanyName'>
											{{appointment.jobSiteCompanyName}}
										</div>
										<span *ngIf='appointment.jobSiteAddress1'>{{appointment.jobSiteAddress1}}<br /></span>
										<span *ngIf='appointment.jobSiteAddress2'>{{appointment.jobSiteAddress2}}<br /></span>
										{{appointment.jobSiteDisplayAddress}}
									</div>
									<div style="display: inline-block; width: 250px; vertical-align: top; margin-right: 10px">
										{{appointment.billToFullName}}<br />
										<div *ngIf='appointment.billToCompanyName'>
											{{appointment.billToCompanyName}}
										</div>
										<span *ngIf='appointment.billToAddress1'>{{appointment.billToAddress1}}<br /></span>
										<span *ngIf='appointment.billToAddress2'>{{appointment.billToAddress2}}<br /></span>
										{{appointment.billToDisplayAddress}}
									</div>
								</div>
								{{appointment.appointmentTypeDescription}} |
								{{appointment.appointmentStatusDescription}} <br />
								{{appointment.scheduledDateTime | date:'M/d/yyyy h:mm:ss a'}}
				
							</div>
						</ng-template>
	</slick-auto-complete> 
</h2></div>
	<div *ngIf="canAccessChat && !skeditPay && chatType === 'skedit' && !isLathamOrdersOnly" class="pt-1">
        <a class="btn text-primary c-pointer chat-box" (click)="openChat($event)">
			<i class="fal fa-comment fa-2x border-0"></i><span *ngIf="newChatMessagesCount" class="ms-n2 badge badge-danger badge-pill bg-danger py-1 align-self-start">
                {{newChatMessagesCount}}
            </span>
        </a>
        <span class="pb-0 pt-1 d-block"></span>

    </div>
	<div class="me-2">
		<button *ngIf="!currentTimeclockEntry && !isTimeclockExempt && !isQPro && !isLathamOrdersOnly" type="button" class="btn btn-outline-success me-2" (click)="clockIn()">Clock In</button>
		<button *ngIf="currentTimeclockEntry && !isTimeclockExempt && !isQPro && !isLathamOrdersOnly" type="button" class="btn btn-outline-danger me-2" (click)="clockOut()">Clock Out</button>
		<button *ngIf="currentTimeclockEntry && !isTimeclockExempt && !isQPro && !isLathamOrdersOnly && !currentTimeclockEntry.isOnBreak" type="button" class="btn btn-outline-primary me-2" (click)="startBreak()">Break</button>
		<button *ngIf="currentTimeclockEntry && !isTimeclockExempt && !isQPro && !isLathamOrdersOnly && currentTimeclockEntry.isOnBreak" type="button" class="btn btn-outline-danger me-2" (click)="endBreak()">End Break</button>
		<button type="button" class="btn btn-outline-danger me-2" (click)="logout()">Log Out</button>
	</div>
	<div class="text-end me-2" style="font-size:12px; line-height: 1rem;">
		Hello, {{ firstName }}

		<br>{{ companyName }}
	</div>
	<div *ngIf="!isLathamOrdersOnly" class="me-2">
		<a href="/userSettings" [routerLink]="['/userSettings']">
			<img src="{{ profilePictureUrl }}"
				 alt="{{ fullName }}"
				 style="border-radius: 50%; width: 40px; height: 40px;" />
		</a>
	</div>
	<div *ngIf="isLathamOrdersOnly" class="me-3">
		<a href="/userSettings" [routerLink]="['/userSettings']">
			<img src="{{ profilePictureUrl }}"
				 alt="{{ fullName }}"
				 style="border-radius: 50%; width: 40px; height: 40px;" />
		</a>
	</div>


	<div class="align-self-top">
		<notifications></notifications>
	</div>
</div>

<div #trialRef class="trial bg-primary">
	<div class="p-0 m-0">
		<h3 style="opacity: 1; color: white;">Your free trial expires on {{trialEndDate | date: 'mediumDate'}}.</h3>
	</div>
</div>

<skedit-chat *ngIf="canAccessChat"  #chatRef></skedit-chat>
<job-edit-dialog #jobEditDialogRef></job-edit-dialog>