import { Component, OnInit, OnDestroy, ElementRef, ViewChild, ContentChild, Input, Output, EventEmitter, forwardRef, OnChanges, SimpleChanges, AfterViewInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SlickAutoCompleteListItemTemplateDirective } from "./slick-auto-complete-list-item-template.directive";
import { SlickUtilsService } from "../utils/slick-utils.service";
import { SlickSearchBarComponent } from "../slick-search-bar/slick-search-bar.module";
import { SlickFunctionLockService } from "../utils/slick-function-lock.service";
import { SlickSleepService } from "../utils/slick-sleep.service";

@Component({
	selector: 'slick-auto-complete',
	templateUrl: 'slick-auto-complete.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SlickAutoCompleteComponent),
			multi: true
		}]
})
export class SlickAutoCompleteComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
	@Input("inputRef") inputRef: HTMLInputElement;
	@Input("searchBarRef") searchBarRef: SlickSearchBarComponent;
	@Input("items") items: any[];
	@Input("delay") delay: number = 300;
	@Input("selectFirst") selectFirst: boolean = true;
	@Input("displayTextFieldName") displayTextFieldName: string;
	@Input("offset") offset: number = 0;
	@Input("listHeight") listHeight = "300px";
	@Input("listWidth") listWidth: string = '100%';
	@Input("allowFreeformText") allowFreeformText = true;
	@Input("showNoItemsFoundDisplay") showNoItemsFoundDisplay = true;
	@Output("onRefresh") onRefresh: EventEmitter<string> = new EventEmitter<string>();
	@Output("onSelect") onSelect: EventEmitter<any> = new EventEmitter<any>();
	@Output("onFreeformTextSelect") onFreeformTextSelect: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild("autoCompleteRef", { static: true }) autoCompleteRef: ElementRef;
	@ContentChild(SlickAutoCompleteListItemTemplateDirective, { static: true }) listItemTemplateRef: SlickAutoCompleteListItemTemplateDirective;

	fnDocumentClick = (e) => this.documentClick(e);
	fnKeyDown = (e) => this.keyDown(e);

	private selectedItem: any;
	private delayTimeout: NodeJS.Timer;
	private waitForItemsInterval: NodeJS.Timer;
	private lastInputValue: string = "";
	private forcedCollapse = false;

	// This is necessary so that the scroll and resize events can add and remove the event properly,
	// as well as getting the correct ES6 function
	private fnReposition = () => this.reposition();

	uuid: string;
	left: string;
	top: string;
	width: string;
	selectedIndex: number = -1;
	showAutoComplete: boolean = false;
	autoCompleteVisiblity: boolean = false;
	expanded: boolean = true;
	loading: boolean = false;

	constructor(private slickFunctionLockService: SlickFunctionLockService) {
		this.uuid = SlickUtilsService.newGuid();
	}

	async ngOnInit() {
		await SlickSleepService.sleep();
		if (this.searchBarRef) 
			this.inputRef = (<ElementRef>this.searchBarRef.slickSearchTextBoxRef).nativeElement;
		
		// Remove this from the inline version and put it on the body so that it doesn't hide behind the bottom
		// of a div when it expands beyond the bottom.
		this.autoCompleteRef.nativeElement.remove(this.autoCompleteRef);
		document.body.appendChild(this.autoCompleteRef.nativeElement);

		this.inputRef.addEventListener("keydown", this.fnKeyDown);
	}

	ngOnDestroy() {
		// Since angular has no way to use the third parameter, I'm doing this in raw js
		document.removeEventListener("click", this.fnDocumentClick, true);
		this.inputRef.removeEventListener("keydown", this.fnKeyDown);

		clearTimeout(this.delayTimeout);
		this.delayTimeout = null;

		this.collapse();

		// Is okay if this fails.  It just means it was already removed.
		try {
			document.body.removeChild(this.autoCompleteRef.nativeElement);
		}
		catch { }
	}

	ngOnChanges(changes: SimpleChanges) {		
		if (changes.allowFreeformText) 
			this.allowFreeformText = (this.allowFreeformText.toString().toLowerCase() === 'false') ? false : true;		

		if (changes.showNoItemsFoundDisplay)
			this.showNoItemsFoundDisplay = (this.showNoItemsFoundDisplay.toString().toLowerCase() === 'false') ? false : true;		

		if (changes.items) {
			if (this.items && this.items.length === 0 && this.showNoItemsFoundDisplay === false)
				this.collapse();
		}

		if (isNaN(this.offset))
			this.offset = parseInt(this.offset.toString());
	}

	private documentClick(e: MouseEvent) {
		if (!this.expanded || !e.target)
			return;

		if (e.target === this.inputRef)
			return;

		if (SlickUtilsService.checkParentIdExists(<HTMLElement>e.target, "slick-auto-complete_" + this.uuid) === false) {
			clearInterval(this.waitForItemsInterval);
			this.slickFunctionLockService.release("AUTO_COMPLETE_WAIT_FOR_ITEMS");
			this.collapse();

			if (this.allowFreeformText && this.onFreeformTextSelect)
				this.onFreeformTextSelect.emit(this.inputRef.value);
		}
	}

	onItemClick(index) {
		this.selectItem(index);
		this.collapse();
	}

	private async keyDown(event: KeyboardEvent) {
		// alt
		if (event.keyCode === 18)
			return;

		clearInterval(this.waitForItemsInterval);
		this.slickFunctionLockService.release("AUTO_COMPLETE_WAIT_FOR_ITEMS");

		// If we're just tabbing through, don't even do anything
		if (!this.lastInputValue && (event.keyCode === 9 || event.keyCode === 13))
			return;

		await SlickSleepService.sleep();

		if (this.lastInputValue !== this.inputRef.value) {
			this.lastInputValue = this.inputRef.value;
			this.forcedCollapse = false;

			if (!this.lastInputValue) {
				this.propagateChange(null);
				this.collapse();
			}

			clearTimeout(this.delayTimeout);
			this.delayTimeout = null;
			if (this.inputRef.value === '') {
				this.items = null;
				this.collapse();
				return true;
			}
			else {
				this.delayTimeout = setTimeout(async () => {
					(this.selectFirst === true) ? this.selectedIndex = 0 : this.selectedIndex = -1;
					this.delayTimeout = null;
					this.items = null;
					this.expand();
					await this.refreshItems(true);
				}, this.delay);
			}
		}

		if (event.keyCode === 9 || event.keyCode === 13) {
			if (this.delayTimeout && this.selectFirst) {
				clearTimeout(this.delayTimeout);
				this.delayTimeout = null;
				this.items = null;
				this.collapse();

				if (this.selectFirst === true && !this.forcedCollapse) {
					await this.refreshItems(false);
					this.selectedIndex = -1;

					if (!this.items && !this.inputRef.value && this.onSelect) {
						this.onSelect.emit(null);
					}
					else if (this.items && this.items.length > 0) {
						this.selectedIndex = 0;
						this.selectItem(this.selectedIndex);
					}
					else {
						if (this.allowFreeformText && this.onFreeformTextSelect) 
							this.onFreeformTextSelect.emit(this.inputRef.value);
					}
				}
				else {
					if (this.allowFreeformText && this.onFreeformTextSelect) 
						this.onFreeformTextSelect.emit(this.inputRef.value);
				}
			}
			else {
				clearTimeout(this.delayTimeout);
				if (!this.items && !this.inputRef.value && this.onSelect) {
					this.onSelect.emit(null);
				}
				else if (this.items && this.items.length > 0 && this.expanded && !this.forcedCollapse) {
					this.selectItem(this.selectedIndex);
				}
				else {
					if (this.allowFreeformText && this.onFreeformTextSelect) {
						this.onFreeformTextSelect.emit(this.inputRef.value);
					}
				}
				this.collapse();
			}
		}

		// Esc
		if (event.which === 27) {
			this.forcedCollapse = true;
			this.collapse();

			return false;
		}

		// Arrowup
		if (event.which === 38) {
			if (this.selectedIndex === null || this.selectedIndex === undefined)
				this.selectedIndex = 0;

			if (this.selectedIndex > 0)
				this.selectedIndex--;

			return false;
		}

		// Arrowdown
		if (event.which === 40) {
			event.stopPropagation();
			if (this.selectedIndex === null || this.selectedIndex === undefined)
				this.selectedIndex = -1;

			if (!this.expanded) {
				this.expand();
				return false;
			}

			if (this.selectedIndex < this.items.length - 1)
				this.selectedIndex++;

			return false;
		}
	}

	propagateChange = (_: any) => { };

	// this is the initial value set to the component
	writeValue(obj: any) {
		if (obj) {
		}
	}

	// registers 'fn' that will be fired when changes are made
	// this is how we emit the changes back to the form
	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	// not used, used for touch input
	registerOnTouched() { }

	async selectItem(index: number) {
		if (index < 0 || !this.items || this.items.length === 0) {
			this.selectedItem = null;
		}
		else {
			this.selectedItem = this.items[index];
			if (this.displayTextFieldName) {
				const text = SlickUtilsService.getDeepObject(this.selectedItem, this.displayTextFieldName);
				if (text) {
					this.inputRef.value = text;
					this.lastInputValue = this.inputRef.value;
				}
			}
		}

		this.propagateChange(this.selectedItem);
		if (this.onSelect)
			this.onSelect.emit(this.selectedItem);
		// Call to update the model for the textbox
		await SlickSleepService.sleep();
		let evt = document.createEvent('Event');
		evt.initEvent('input', true, false);
		this.inputRef.dispatchEvent(evt);
	}

	private async expand() {
		this.reposition();

		// If the flag to show items is false, don't display "Loading..."
		if (this.showNoItemsFoundDisplay === false && (!this.items || this.items.length === 0)) {
			this.showAutoComplete = false;
			this.autoCompleteVisiblity = false;
			this.expanded = false;
			return;
		}

		this.showAutoComplete = true;
		this.autoCompleteRef.nativeElement.style.display = "inline-block";
		await SlickSleepService.sleep();
		this.autoCompleteVisiblity = true;
		this.expanded = true;

		this.inputRef.classList.add("slick-auto-complete_remove-bottom-left-border-radius");
		this.inputRef.classList.add("slick-auto-complete_remove-bottom-right-border-radius");

		// This is necessary, otherwise `this` in the reposition function will be window
		// and not the component.
		// Since angular has no way to use the third parameter, I'm doing this in raw js
		document.addEventListener("click", this.fnDocumentClick, true);
	}

	private collapse() {
		let inputGroupPrepend = SlickUtilsService.getClosestSibling(this.inputRef, "input-group-prepend");
		if (inputGroupPrepend)
			inputGroupPrepend.classList.remove("slick-auto-complete_remove-bottom-left-border-radius");

		this.inputRef.classList.remove("slick-auto-complete_remove-bottom-left-border-radius");
		this.inputRef.classList.remove("slick-auto-complete_remove-bottom-right-border-radius");

		this.autoCompleteVisiblity = false;
		this.expanded = false;
		this.autoCompleteRef.nativeElement.style.display = "none";

		// Since angular has no way to use the third parameter, I'm doing this in raw js
		document.removeEventListener("click", this.fnDocumentClick, true);
	}

	private reposition() {
		let rect = this.inputRef.getBoundingClientRect();
		let inputLeft = rect.left;
		let inputTop = rect.top;
		let inputHeight = this.inputRef.offsetHeight;
		let inputWidth = this.inputRef.offsetWidth;

		let inputGroupPrepend = SlickUtilsService.getClosestSibling(this.inputRef, "input-group-prepend");
		if (inputGroupPrepend) {
			inputLeft = inputGroupPrepend.getBoundingClientRect().left;
			inputWidth = inputWidth += inputGroupPrepend.offsetWidth;
			inputGroupPrepend.classList.add("slick-auto-complete_remove-bottom-left-border-radius");
		}

		this.left = (inputLeft + this.offset) + "px";
		this.top = (inputTop + inputHeight + 1) + "px";
		if (this.listWidth === '100%')
			this.width = inputWidth + "px";
		else if (this.listWidth === 'auto')
			this.width = "auto";
		else
			this.width = this.listWidth;
	}

	private async refreshItems(expand: boolean): Promise<void> {
		return new Promise<void>(async (resolve) => {
			await this.slickFunctionLockService.lock("AUTO_COMPLETE_WAIT_FOR_ITEMS");
			clearInterval(this.waitForItemsInterval);
			this.items = null;

			if (!this.onRefresh || !this.inputRef.value) {
				this.items = [];
				this.slickFunctionLockService.release("AUTO_COMPLETE_WAIT_FOR_ITEMS");
				resolve();
			}
			else {
				this.loading = true;
				if (this.showNoItemsFoundDisplay === false)
					this.collapse();
				// Call the users refresh method to get a fresh list of items
				this.onRefresh.emit(this.inputRef.value);

				// Wait for the list to refresh
				let timeoutCheck = 1000;
				this.waitForItemsInterval = setInterval(() => {
					// If we've timedout, got a response or they started typing something else, stop the interval
					if (timeoutCheck <= 0 || this.items) {
						clearInterval(this.waitForItemsInterval);
						if (timeoutCheck <= 0) {
							console.error("Timed out waiting for list in auto complete");
							this.items = [];
							if (this.showNoItemsFoundDisplay === false)
								this.collapse();
						}
						this.loading = false;
						if (expand)
							this.expand();
						this.slickFunctionLockService.release("AUTO_COMPLETE_WAIT_FOR_ITEMS");
						resolve();
					}

					timeoutCheck--;
				}, 100);
			}
		})
	}

}