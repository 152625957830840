<section *ngIf="!newStrike">
    <div class="row">
        <div class="col-12">
            <button class="cancel-button btn btn-primary btn-sm"
                    (click)="createStrike()"><i class="far fa-plus"></i> Mark
            </button>
        </div>
    </div>
</section>
<section *ngIf="newStrike">
    <div class="row">
        <div class="col-9">
            <slick-drop-down [items]="users"
                             (onSelect)="onEmployeeSelected($event)"
                             idFieldName="userId"
                             textFieldName="fullName"
                             placeholder="Give Mark To"></slick-drop-down>
        </div>
        <div class="col-2 btn-group">
            <div class="me-1">
                <button class="btn"
                        [class.btn-outline-success]="badMark"
                        [class.btn-success]="!badMark"
                        (click)="badMark = false"><i class="bi bi-hand-thumbs-up-fill"></i></button>
            </div>
            <div>
                <button class="btn"
                        [class.btn-outline-danger]="!badMark"
                        [class.btn-danger]="badMark"
                        (click)="badMark = true"><i class="bi bi-hand-thumbs-down-fill"></i></button>
            </div>
        </div>
    </div>
    <div *ngIf="appointmentUserStrikeTypes?.length > 0" class="row mt-1">
        <div class="col-9">
            <slick-drop-down [items]="appointmentUserStrikeTypes"
                             [(ngModel)]="newStrike.appointmentUserStrikeTypeId"
                             placeholder="Strike Type"></slick-drop-down>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="mt-1">
                <div class="form-group">
                    <div class="input-group">
                        <textarea type="text"
                                  class="form-control"
                                  [(ngModel)]="newStrike.notes"
                                  placeholder="Notes"
                                  rows="3"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-12">
            <button class="save-button btn btn-outline-success me-3 btn-sm"
                    (click)="save()">
                <i class="bi bi-check-circle"></i> Save
            </button>
            <button class="cancel-button btn btn-outline-danger  btn-sm"
                    (click)="cancel()">
                <i class="bi bi-x-circle"></i> Cancel
            </button>
        </div>
    </div>
</section>
<section *ngIf="appointmentUserStrikes">
    <div class="row item"
         *ngFor="let strike of appointmentUserStrikes">
        <div class="col-12 mt-2">
            <h4>{{strike.userName}}</h4>
            <div class="pt-2 pb-2">
                <textarea type="text"
                          class="form-control"
                          [(ngModel)]="strike.notes"
                          rows="2"
                          readonly="readonly"></textarea>
            </div>
            <div>
                <span>Entered By: </span><span>{{ strike.awardedBy }}</span>
            </div>
            <div>
                <span>Date: </span><span>{{ strike.awardedDate | date: 'short' }}</span>
            </div>
        </div>
    </div>
</section>
