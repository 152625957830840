import { Component, ViewChild } from '@angular/core';
import { SlickDialogComponent } from "@slick-components";
import { IInvoiceModel } from '@models';
import { SleepService, InvoicesService, LookupService, GlobalsService } from '@services';
import { EstimateEditComponent } from '../estimate-edit/estimate-edit.component';
import Swal from 'sweetalert2';

@Component({
	selector: 'estimate-edit-dialog',
	templateUrl: 'estimate-edit-dialog.component.html',
	providers: [InvoicesService]
})
export class EstimateEditDialogComponent {
	@ViewChild('dialogRef', { static: true }) dialogRef: SlickDialogComponent;
	@ViewChild('estimateEditComponentRef', { static: true }) estimateEditComponentRef: EstimateEditComponent;

	estimateModel: IInvoiceModel;
	defaultApptId: number;
	spinnerStatus: string;
	resolve: any;

	constructor(private invoicesService: InvoicesService, private lookupService: LookupService) {
	}

	openDialog(estimateModel: IInvoiceModel): Promise<IInvoiceModel> {
		return new Promise((resolve) => {
			this.resolve = resolve;
			this.spinnerStatus = 'reset';

			this.estimateModel = estimateModel;
			this.dialogRef.showDialog();
			if (GlobalsService.company.useAdvancedTax && !GlobalsService.company.useCereTax && !this.estimateModel.customer.taxRegionId && (this.lookupService.getTaxRegions().length ?? 0) > 0 && GlobalsService.company.companyId === 3) {
				Swal.fire({
					icon: 'warning',
					title: 'Warning',
					text: 'Customer has no tax region',
					confirmButtonColor: '#007bff',
					width: '28em',
					heightAuto: false
				});
			}
		});
	}

	async saveEstimate() {
		try {
			this.estimateEditComponentRef.isSubmitted = true;
			
			if (this.estimateEditComponentRef.isValid() === false) {
				this.spinnerStatus = 'error';
				return;
			}

			this.spinnerStatus = 'spin';

			await SleepService.sleep(500);

			this.estimateModel = await this.invoicesService.updateInvoice(this.estimateModel);

			this.spinnerStatus = 'ok';

			await SleepService.sleep(500);

			this.estimateEditComponentRef.isSubmitted = false;
			this.resolve(this.estimateModel);
			this.dialogRef.hideDialog();
		} catch {
			this.spinnerStatus = 'error';
		}
	}

	async cancelEstimate() {
		await this.dialogRef.hideDialog();
		this.resolve(null);
	}

	onSave(estimateModel: IInvoiceModel) {
		this.estimateModel = estimateModel;
	}

	async convertToSalesOrder() {
		this.estimateModel = await this.estimateEditComponentRef.convertToSalesOrder();
	}

	async convertToInvoice() {
		this.estimateModel = await this.estimateEditComponentRef.convertToInvoice();
	}

	async generateEstimatePdf() {
		if (this.estimateModel.invoiceId === 0) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please save the estimate before printing",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}
		this.estimateEditComponentRef.generateEstimatePdf();
	}

	async generateWorkOrderPdf() {
		if (this.estimateModel.invoiceId === 0) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please save the estimate before printing",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		this.estimateEditComponentRef.generateWorkOrderPdf();
	}

	async textEstimate() {
		if (this.estimateModel.invoiceId === 0) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please save the estimate before texting",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}
		await this.estimateEditComponentRef.textEstimate();
	}


	async emailEstimate() {
		if (this.estimateModel.invoiceId === 0) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please save the estimate before emailing",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}
		this.estimateEditComponentRef.emailEstimate();
	}

	async prePayment() {
		if (this.estimateModel.invoiceId === 0) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please save the estimate before pre-payment",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}
		this.estimateEditComponentRef.prePayment();
	}
}
