import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SlickToastService } from "./slick-toast.service";

@NgModule({
	imports: [RouterModule],
	providers: [SlickToastService]
})
export class SlickToastModule { }

export { SlickToastService }