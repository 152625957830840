import { ICreditCardTransactionLogModel } from "./credit-card-transaction-log.model";
import { IPaymentModel } from "../payments/payment.model";
import { IAppliedPaymentModel } from "../payments/applied-payment.model";

export interface ICreditCardPaymentApprovedModel {
	creditCardTransactionLogModel: ICreditCardTransactionLogModel;
	paymentModel: IPaymentModel;
	appliedPayment: IAppliedPaymentModel;
}

export class CreditCardPaymentApprovedModel implements ICreditCardPaymentApprovedModel {
	creditCardTransactionLogModel: ICreditCardTransactionLogModel;
	paymentModel: IPaymentModel;
	appliedPayment: IAppliedPaymentModel;
}
