import { Directive, Input, OnInit, OnChanges, SimpleChanges, ElementRef } from "@angular/core";

export enum SlickValidationTypes { None, Error, Warning }

@Directive({
	selector: '[slick-validation-indicator]'
})
export class SlickValidationIndicatorDirective implements OnInit, OnChanges {
	@Input("slick-validation-indicator") isValid: boolean;
	@Input("slickValidationType") validationType: SlickValidationTypes = SlickValidationTypes.Warning;

	private bgWarningStyle: string = "#ffc207";
	private bgDangerStyle: string = "#dc3545";
	private bgSuccessStyle: string = "#28a745";

	private originalBorderStyle: string;

	constructor(private el: ElementRef) {
		this.originalBorderStyle = this.el.nativeElement.style.borderLeft;
	}

	ngOnInit() {
		if (this.validationType) {
			if ((<any>this.validationType).toString().toLowerCase() === 'warning')
				this.validationType = SlickValidationTypes.Warning;
			else if ((<any>this.validationType).toString().toLowerCase() === 'none')
				this.validationType = SlickValidationTypes.None;
			else
				this.validationType = SlickValidationTypes.Error;
		}
		else {
			this.validationType = SlickValidationTypes.None;
		}

		this.setIndicator();
	}

	ngOnChanges(changes: SimpleChanges) {
		this.setIndicator();
	}

	private setIndicator() {
		if (this.validationType === SlickValidationTypes.None) {
			this.el.nativeElement.style.borderLeft = this.originalBorderStyle;
		}
		else {
			if (this.isValid) {
				this.el.nativeElement.style.borderLeft = "6px solid " + this.bgSuccessStyle;
			}
			else {
				if (this.validationType === SlickValidationTypes.Warning)
					this.el.nativeElement.style.borderLeft = "6px solid " + this.bgWarningStyle;
				else
					this.el.nativeElement.style.borderLeft = "6px solid " + this.bgDangerStyle;
			}
		}
	}
}
