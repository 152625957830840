import { ITaxRateModel } from "@models";

export interface IShippingAddressModel {
    shippingAddressId: number;
    useCustomAddress: boolean;
    taxRateGroupId?: number;
    companyName: string;
    firstName: string;
    lastName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    latitude?: string;
    longitude?: string;
    shipToCustomerId?: number;
    shipToWarehouseId?: number;
    shipToWarehouseName: string;
    addressHash: string;

    taxRates: ITaxRateModel[];
    taxRate?: number;
}

export class ShippingAddressModel implements IShippingAddressModel {
    constructor() {
        this.useCustomAddress = false;
    }

    shippingAddressId: number;
    useCustomAddress: boolean;
    taxRateGroupId?: number;
    companyName: string;
    firstName: string;
    lastName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    latitude?: string;
    longitude?: string;
    shipToCustomerId?: number;
    shipToWarehouseId?: number;
    shipToWarehouseName: string;
    addressHash: string;

    taxRates: ITaxRateModel[];
    taxRate?: number;

}