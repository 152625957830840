import { Input, OnChanges, SimpleChanges, Output, EventEmitter, Directive, ViewChild } from '@angular/core';
import { InventoryService,LineItemsService, UtilsService, FunctionLockService, SleepService, GlobalsService, HttpService, LookupService } from '@services';
import { IItemSearchModel, IInventoryLineItemModel, InventoryLineItemModel, IDropdownModel, ILineItemModel, IInventoryLocationsListModel, IInventoryItemLocationQuantityOnHandModel } from '@models';
import { InventoryStore } from '@stores';
import { SlickDialogComponent } from "@slick-components";
import Swal from 'sweetalert2';

@Directive()
export class InventoryLineItemsBaseComponent implements OnChanges {
	@Input() invoiceUuid: string;
	@Input() lineItems: IInventoryLineItemModel[];
	@Output() lineItemsChange: EventEmitter<IInventoryLineItemModel[]> = new EventEmitter<IInventoryLineItemModel[]>();
	@Input() inventoryWarehouseId: number;
	@Output() inventoryWarehouseIdChange: EventEmitter<number> = new EventEmitter<number>();
	@Input() isLathamOrder: boolean = false;
	@Input() editable: boolean = true;
	@Input() summary: boolean = false;
	@Input() isSubmitted: boolean;
	@Input() disableWarehouse: boolean = false;

	@ViewChild("enlargedImageDialogRef") enlargedImageDialogRef: SlickDialogComponent;

	protected enlargedImageUrl: string;

	protected visibleLineItems: IInventoryLineItemModel[];
	protected isAdmin: boolean = (GlobalsService.userInfo.roleTypeId === 1 || GlobalsService.userInfo.roleTypeId === 2);
	protected showLathamItemsInItemSearch: boolean;
	protected skuResults: any[];
	protected descResults: any[];
	protected isCustomSKU = false;
	protected oldHash: number;

	protected showItemLocation: boolean;
	protected inventoryWarehouses: IDropdownModel[];

	constructor(private lineItemsService: LineItemsService,
		private httpService: HttpService,
		private inventoryStore: InventoryStore,
		private functionLockService: FunctionLockService) {
		this.inventoryWarehouses = this.inventoryStore.getInventoryWarehousesForDropdown();
	}


	async ngOnChanges(changes: SimpleChanges) {
		this.editable = (this.editable.toString().toLowerCase() === 'true') ? true : false;
		if (changes.invoiceUuid) {
            if (this.isLathamOrder === true)
				this.showLathamItemsInItemSearch = true;
			else
				this.showLathamItemsInItemSearch = GlobalsService.company.showLathamItemsInItemSearch;
		}

		//we need a sleep here or else the expression changed after checked error will be thrown.
		await SleepService.sleep();
		if (!this.inventoryWarehouseId) {

			//check user defualt warehouse
			if (GlobalsService.userInfo.defaultWarehouseId)
				this.inventoryWarehouseId = GlobalsService.userInfo.defaultWarehouseId;
			//then check default warehouse for company
			else if (this.inventoryWarehouses.findIndex(x => x.customField) >= 0)
				this.inventoryWarehouseId = this.inventoryWarehouses.find(x => x.customField).id;
			//select the first warehouse if no defaults
			else
				this.inventoryWarehouseId = this.inventoryWarehouses[0].id;
		
			this.inventoryWarehouseIdChange.emit(this.inventoryWarehouseId);
		}

		const hash = UtilsService.getHashCode(JSON.stringify(this.lineItems));
		if (hash !== this.oldHash) {
			this.oldHash = hash;
			this.showItemLocation = false;
			this.visibleLineItems = UtilsService.clone(this.lineItems);
			this.refreshItemLocations();
		}

		let itemLocationIds: number[] = [];
        this.visibleLineItems.forEach(x => {
            if (x.inventoryItemLocationId)
                itemLocationIds.push(x.inventoryItemLocationId)
			}
		)

		let quantitiesOnHand: IInventoryItemLocationQuantityOnHandModel[] = [];
		if (itemLocationIds.length > 0) {
			quantitiesOnHand = await this.inventoryStore.getQuantitiesOnHand(itemLocationIds);
		}

		this.visibleLineItems.forEach(async x => {
			x.editable = this.editable;
			x.inventoryItemLocations = await this.inventoryStore.getInventoryItemLocationsForDropdown(x.itemId, x.inventoryWarehouseId);

			const quantityOnHand = quantitiesOnHand?.find(q => q.inventoryItemLocationId === x.inventoryItemLocationId);
			x.locationQuantityOnHand = quantityOnHand ? quantityOnHand.quantityOnHand : 0;
		});
		
		this.addBlankLineItem();
	}

	onLocationSelect(location: IInventoryLocationsListModel, uuid: string) {
		const lineItem = this.lineItems.find(x => x.uuid == uuid);
		lineItem.inventoryItemLocationId = null;
		lineItem.inventoryLocationId = null;
		lineItem.inventoryItemLocationName = null;
		if (location != null) {
			this.visibleLineItems.find(x => x.uuid == uuid).locationQuantityOnHand = location.quantityOnHand;
			if (location?.inventoryItemLocationId !== 0) {
				lineItem.inventoryItemLocationId = location.inventoryItemLocationId;
				lineItem.inventoryItemLocationName = location.locationName;

			} else {
				lineItem.inventoryLocationId = location.locationId;
				lineItem.inventoryItemLocationName = location.locationName;
			}
		}
		this.lineItemsChange.emit(this.lineItems);
	}

	protected async onInventoryWarehouseSelect(inventoryWarehouse: IDropdownModel) {
		this.inventoryWarehouseId = inventoryWarehouse.id;

		localStorage.setItem("INVENTORY_LINE_ITEMS_LOCATION_ID", inventoryWarehouse.id.toString());
		this.visibleLineItems.forEach(li => {
			li.inventoryWarehouseId = inventoryWarehouse.id;
			li.inventoryWarehouseName = inventoryWarehouse.text;
			li.inventoryItemLocationId = null
			li.inventoryItemLocationName = null;
			
		});

		await this.refreshItemLocations();
		this.lineItemsChange.emit(this.visibleLineItems.filter(x => !x.isBlankLineItem));
		this.inventoryWarehouseIdChange.emit(this.inventoryWarehouseId);
	}


	protected enlargeImage(imageUrl: string) {
		if (imageUrl.indexOf("NoImage.png") >= 0)
			return;

		this.enlargedImageUrl = imageUrl;

		this.enlargedImageDialogRef.showDialog();
	}

	protected closeImageDialog() {

		this.enlargedImageDialogRef.hideDialog()
	}

	protected async emit() {
		await SleepService.sleep();
		
		this.lineItems = UtilsService.clone(this.visibleLineItems).filter(x => !!!x.isBlankLineItem);
		this.oldHash = UtilsService.getHashCode(JSON.stringify(this.lineItems));
		this.lineItemsChange.emit(this.lineItems);
	}

	protected async onLineItemLocationSelect(x: IDropdownModel, lineItem: IInventoryLineItemModel) {
		lineItem.inventoryItemLocationId = x.id;
		this.emit();
    }

	protected async searchBySku(skuSearchText: string) {
		const params = {
			showLathamItemsInItemSearch: this.showLathamItemsInItemSearch,
			searchText: skuSearchText,
			inventoryItemsOnly: true
		}
		this.skuResults = await this.httpService.get(`/items/getItemsForItemSearch`, params);
	}

	// This will replace an existing line item with a new one
	protected async replaceWithSelectedSkuItem(itemSearchModel: IItemSearchModel, existingLineItem: IInventoryLineItemModel) {
		try {
			await this.functionLockService.lock("LINE_ITEMS_REPLACE_LINE_ITEM");

			existingLineItem.isBlankLineItem = false;

			// See if this line item exists in the line items.  If not, add it
			if (!this.visibleLineItems.find(li => li.uuid === existingLineItem.uuid))
				this.visibleLineItems.push(existingLineItem);

			const lineItem = await this.lineItemsService.getLineItemBySku(itemSearchModel.sku, 0);

			if (lineItem) {
				existingLineItem.itemId = lineItem.itemId;
				existingLineItem.sku = lineItem.sku;
				existingLineItem.description = lineItem.description;
				existingLineItem.cost = lineItem.cost;
				existingLineItem.imageUrl = lineItem.imageUrl;
				existingLineItem.imageThumbnailUrl = lineItem.imageThumbnailUrl;
			}

			await SleepService.sleep();

			const qtyTextbox = (<HTMLInputElement>document.querySelector(`.inventory-line-items table tbody tr[data-uuid='${existingLineItem.uuid}'] .quantity`));
			qtyTextbox.focus();
			qtyTextbox.select();

			this.emit();
			this.addBlankLineItem();
			existingLineItem.inventoryItemLocations = await this.inventoryStore.getInventoryItemLocationsForDropdown(existingLineItem.itemId, this.inventoryWarehouseId);
			if (existingLineItem.inventoryItemLocations?.length > 0)
				this.showItemLocation = true;
		}
		finally {
			await this.functionLockService.release("LINE_ITEMS_REPLACE_LINE_ITEM");
		}
	}

	protected async replaceWithSelectedSkuFreeform(existingLineItem: IInventoryLineItemModel) {
		const lineItem = await this.lineItemsService.getLineItemBySku(existingLineItem.sku, 0);

		if (!lineItem.itemId) {
			await Swal.fire({
				icon: 'warning',
				title: 'Item not found',
				text: `SKU "${existingLineItem.sku}" not found.`,
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			this.deleteLineItem(existingLineItem)
			this.addBlankLineItem();
			return;
		}

		try {
			await this.functionLockService.lock("LINE_ITEMS_REPLACE_LINE_ITEM");

			existingLineItem.itemId = lineItem.itemId;
			existingLineItem.sku = lineItem.sku;
			existingLineItem.description = lineItem.description;
			existingLineItem.cost = lineItem.cost;
			existingLineItem.imageUrl = lineItem.imageUrl;
			existingLineItem.imageThumbnailUrl = lineItem.imageThumbnailUrl;
			existingLineItem.isBlankLineItem = false;

			// See if this line item exists in the line items.  If not, add it
			if (!this.lineItems.find(li => li.uuid === existingLineItem.uuid))
				this.lineItems.push(existingLineItem);

			await SleepService.sleep();

			const descTextbox = (<HTMLInputElement>document.querySelector(`.inventory-line-items table tbody tr[data-uuid='${existingLineItem.uuid}'] .description`));
			descTextbox.focus();
			descTextbox.select();

			this.emit();
			this.addBlankLineItem();

			existingLineItem.inventoryItemLocations = await this.inventoryStore.getInventoryItemLocationsForDropdown(existingLineItem.itemId, this.inventoryWarehouseId);
			if (existingLineItem.inventoryItemLocations?.length > 0)
				this.showItemLocation = true;

		}
		finally {
			await this.functionLockService.release("LINE_ITEMS_REPLACE_LINE_ITEM");
		}
	}

	protected async updateLineItem(lineItem: IInventoryLineItemModel) {
		lineItem.editable = false;

		this.emit();
	}

	protected undoEdit(lineItem: IInventoryLineItemModel) {
		const idx = this.visibleLineItems.findIndex(x => x.uuid === lineItem.uuid);
		this.visibleLineItems[idx] = UtilsService.clone(this.visibleLineItems.find(x => x.uuid === lineItem.uuid));
		this.visibleLineItems[idx].editable = false;
	}

	protected deleteLineItem(lineItem: IInventoryLineItemModel) {
		this.visibleLineItems = this.visibleLineItems.filter(x => x.uuid !== lineItem.uuid);
		this.emit();
	}

	public isValid(): boolean {
		const missingLocation = this.visibleLineItems.findIndex(x => x.inventoryItemLocations?.length > 0 && x.inventoryItemLocationId === null);
		return (missingLocation < 0);
	}

	public async refreshItemLocations() {
		this.showItemLocation = false;
		if (!this.inventoryWarehouseId)
			return;

		this.visibleLineItems = await this.inventoryStore.populateInventoryLineItemLocations(this.inventoryWarehouseId, this.visibleLineItems);

		this.visibleLineItems.forEach(x => {
			if ((x.inventoryItemLocations?.length ?? 0) > 0)
				this.showItemLocation = true;
		})
	}

	private addBlankLineItem() {
		if (this.editable === false)
			return;

		if (this.visibleLineItems.find(li => li.isBlankLineItem === true))
			return;

		const blankLineItem = new InventoryLineItemModel();
		blankLineItem.uuid = UtilsService.newGuid();
		blankLineItem.editable = true;
		blankLineItem.sku = '';
		blankLineItem.displayOrder = this.lineItems.reduce((max, li) => li.displayOrder > max ? li.displayOrder : max, 0) + 1;
		blankLineItem.quantity = 1;
		blankLineItem.cost = 0;
		blankLineItem.inventoryWarehouseId = this.inventoryWarehouseId;
		blankLineItem.isBlankLineItem = true;

		this.visibleLineItems.push(blankLineItem);
	}
}