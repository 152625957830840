import { Output } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'permission-denied',
    templateUrl: './permission-denied.component.html',
    styleUrls: ['./permission-denied.component.css']
})
export class PermissionDeniedComponent implements OnInit {
    @Input()
    message: string = 'Permission denied.';

    constructor() {}

    ngOnInit() {}
}
