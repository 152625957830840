import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';  
import { SlickDialogComponent } from "./slick-dialog.component";
import { SlickDialogFooterComponent } from "./slick-dialog-footer.component";
import { SlickDialogService } from "./slick-dialog.service";
import { AngularDraggableModule } from "angular2-draggable";

@NgModule({
    imports: [CommonModule, AngularDraggableModule],
	declarations: [SlickDialogComponent, SlickDialogFooterComponent],
	exports: [SlickDialogComponent, SlickDialogFooterComponent],
	providers: [SlickDialogService]
})
export class SlickDialogModule { }

export { SlickDialogComponent, SlickDialogFooterComponent }