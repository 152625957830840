export interface ICustomerMultipleAddressModel {
    customerMultipleAddressId: number;
    firstName: string;
    lastName: string;
    companyName: string;
    description: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    email: string;
    homePhone: string;
    cellPhone: string;
    workPhone: string;
    isDeleted: boolean;
    latitude?: number;
    longitude?: number;
}

export class CustomerMultipleAddressModel implements ICustomerMultipleAddressModel {
    customerMultipleAddressId: number;
    firstName: string;
    lastName: string;
    companyName: string;
    description: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    email: string;
    homePhone: string;
    cellPhone: string;
    workPhone: string;
    isDeleted: boolean;
    latitude?: number;
    longitude?: number;
}