import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class ThemeStore {
	
	themeChanged$: Subject<string> = new Subject<string>();

	constructor() {
	}

	setTheme(theme: string) {
		this.themeChanged$.next(theme);
	}
}
