<slick-dialog #dialogRef header="Attendants" [width]="340">
    <div class="input-group mb-1">
        <span class="input-group-text"><i class="far fa-search"></i></span>
        <input #attendantSearchRef [(ngModel)]="searchString" class="form-control" placeholder="Search..." (keyup)="filterUsers()" />

    </div>	
    <div style="min-height: 400px; max-height: 400px; overflow-y: auto">
        <table class="table table-sm table-hover">
            <colgroup>
                <col style="width: 20px" />
                <col />
            </colgroup>
            <tbody>
                <tr *ngFor="let userAttendant of userAttendants" [ngClass]="{ 'bg-success text-white': userAttendant.isChecked }">
                    <td>
                        <span *ngIf="userAttendant.isChecked"><i class="far fa-check"></i></span>
                    </td>
                    <td *ngIf="userAttendant.user" (click)="userAttendant.isChecked = !userAttendant.isChecked"><i class="far fa-user"></i> {{userAttendant.user.fullName}}</td>
                    <td *ngIf="userAttendant.team" (click)="userAttendant.isChecked = !userAttendant.isChecked"><i class="far fa-users"></i> {{userAttendant.team.teamName}}</td>
                    <td *ngIf="userAttendant.isAllUsers" (click)="userAttendant.isChecked = !userAttendant.isChecked"><i class="far fa-users"></i> All Users</td>

                </tr>
            </tbody>
        </table>
    </div>
    <slick-dialog-footer>
        <button type="button" style="width: 120px" class="btn btn-outline-primary me-3" (click)="onOk()"><i class="far fa-check"></i> Ok</button>
        <button type="button" style="width: 120px" class="btn btn-outline-danger" (click)="onCancel()"><i class="bi bi-x-circle"></i> Cancel</button>
    </slick-dialog-footer>
</slick-dialog>
