import { Component, ViewChild } from '@angular/core';
import { SlickDialogComponent } from "@slick-components";
import { HttpErrorDialogService } from "@services/utils/http-error-dialog.service";
import { HttpErrorResponse } from "@angular/common/http";
import { request } from 'http';
import { Router } from '@angular/router';

@Component({
	selector: 'http-error-dialog',
	template: `
<slick-dialog key='DIALOG_HTTP_ERROR' width='800' #dialogRef header="{{header}}" (onClose)='onDialogClose()'>
	<div *ngIf="!quickBooksError" class='ps-3'>
		<div style='font-size: 1.1em;' class='text-danger'>
			<span *ngIf='errorMessage'>{{errorMessage}}<br /></span>
			{{exceptionMessage}}
			<div class='mt-1'></div>
			<span style='font-size: 12px'>{{url}}</span>
		</div>
			
		<div class='mt-1'></div>
		<div *ngIf='stackTrace && showStackTrace === false' style='font-size: .8rem;'><button class='btn btn-link' (click)='showStackTrace = true;'><i class='far fa-plus-square'></i> Show Stack Trace</button> </div>
		<div *ngIf='stackTrace && showStackTrace === true' style='font-size: .8rem;' [innerHtml]='stackTrace'></div>
		<div class='mt-2'></div>
		<div *ngIf='requestJson && showRequestJson === false' style='font-size: .8rem;'><button class='btn btn-link' (click)='showRequestJson = true;'><i class='far fa-plus-square'></i> Show JSON</button> </div>
		<div *ngIf='requestJson && showRequestJson === true' style='font-size: .8rem; max-width: 750px; word-break: break-all' [innerHtml]='requestJson'></div>
	</div>
<div *ngIf="quickBooksError" class='ps-3'>
		<div style='font-size: 1.1em;'>
			Your Quickbooks login has expired. Please disconnect and reconnect by clicking <a style="cursor: pointer" (click)="closeDialog()" href="/#/settings/quickbooksOnline">here</a>. 
			<div class='mt-1'></div>
			<span style='font-size: 12px'>{{url}}</span>
		</div>
			
		
	</div>
</slick-dialog>`
})
export class HttpErrorDialogComponent {
	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;

	constructor(private readonly router: Router) {
		HttpErrorDialogService.errorDialogComponentCallback = this;
	}

	header: string;
	errorMessage: string;
	exceptionMessage: string;
	showStackTrace: boolean = false;
	url: string;
	requestJson: string;
	showRequestJson: boolean = false;
	stackTrace: string;
	dialogShowing: boolean = false;
	quickBooksError: boolean = false;

	showHttpError(errorRes: HttpErrorResponse, url: string, requestJson: string) {
		if (this.dialogShowing)
			return;

		this.quickBooksError = false;
		this.showStackTrace = false;
		this.url = url;
		this.requestJson = (requestJson === 'null') ? null : requestJson;
		if (errorRes.error.exceptionMessage === "invalid_grant") {
			if (this.router.url.includes("quickbooksOnline"))
				return;
			this.quickBooksError = true;
			this.dialogShowing = true;
			this.dialogRef.showDialog();

		}
		else {

			if (errorRes.status === 404) {
				this.header = "Page not found";
				this.errorMessage = null;
				this.exceptionMessage = errorRes.url;
				this.stackTrace = null;
			}
			else if (errorRes.status === 500) {
				this.header = "An error has occurred";
				this.errorMessage = (errorRes.error.message === 'An error has occurred.') ? '' : errorRes.error.message;
				this.exceptionMessage = errorRes.error.exceptionMessage;
				this.stackTrace = (!errorRes.error.stackTrace) ? "" : errorRes.error.stackTrace.replace(/(?:\r\n|\r|\n)/g, '<br />');
			}
			else {
				this.header = "An error has occurred";
				this.errorMessage = errorRes.message;
				this.exceptionMessage = JSON.stringify(errorRes.error);
			}
			this.dialogShowing = true;
			this.dialogRef.showDialog();
		}
		
	}

	onDialogClose() {
		this.dialogShowing = false;
	}

	closeDialog() {
		this.dialogRef.hideDialog();
		this.dialogShowing = false;
    }
}