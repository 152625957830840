<slick-dialog #dialogRef header="Conflict" width="380" key="conflictingAppointmentsDialog" [resizable]="false" [draggable]="false" [minimizable]="false" (onClose)="cancel()">
	<section class="ms-4" *ngIf="conflictingAppointments && conflictingAppointments.length > 0">
		<h4 class="text-danger">Warning!</h4>
		<h4 class="text-danger mb-4">There are conflicts with the following:</h4>
		
		<ng-container *ngFor="let conflict of conflictingAppointments">
			<div *ngIf="conflict.conflictingBlockedTimeSlots && conflict.conflictingBlockedTimeSlots.length">
				<h4 class="border-bottom" *ngIf="conflict.conflictingBlockedTimeSlots.length === 1">Blocked Time Slot</h4>
				<h4 class="border-bottom" *ngIf="conflict.conflictingBlockedTimeSlots.length > 1">Blocked Time Slots</h4>
				<ng-container *ngFor="let blockedTime of conflict.conflictingBlockedTimeSlots | slice:0:10;">
					<div><span  class="font-weight-bold">{{ blockedTime.text }}</span></div>
					<div class="border-bottom"><span>{{ blockedTime.blockDateTime | date:'M/d/yyyy hh:mm a' }}</span>&nbsp; - &nbsp; <span>{{ convertToDate(blockedTime.blockDateTime, blockedTime.duration) | date:'M/d/yyyy hh:mm a' }}</span></div>
				</ng-container>
				<div *ngIf="blockedTimeSlotsAfterTen > 0" class="font-weight-bold mt-2 font-italic" style="font-size: 13px; color: #DC3545">
					and {{blockedTimeSlotsAfterTen}} more conflicting blocked time slots...
				</div>
			</div>

			<div *ngIf="conflict.conflictingAppointments && conflict.conflictingAppointments.length" class="mt-3">
				<h4 class="border-bottom" *ngIf=" conflict.conflictingAppointments.length === 1">Appointment</h4>
				<h4 class="border-bottom" *ngIf=" conflict.conflictingAppointments.length > 1">Appointments</h4>
				<ng-container *ngFor="let appt of conflict.conflictingAppointments | slice:0:10;" >
					<div><span class="font-weight-bold ">{{ appt.jobDisplayName }}</span></div>
					<div><span>{{ appt.jobCity }}</span></div>
					<div><span>{{ appt.appointmentTypeDescription }}</span></div>
					<div class="border-bottom"><span>{{ appt.appointmentBlockStart | date:'M/d/yyyy hh:mm a' }}</span>&nbsp; - &nbsp; <span>{{ appt.appointmentBlockEnd | date:'M/d/yyyy hh:mm a' }}</span></div>
				</ng-container>
			</div>
			<div *ngIf="apptsAfterTen > 0" class="font-weight-bold mt-2 font-italic" style="font-size: 13px; color: #DC3545">
				and {{apptsAfterTen}} more conflicting appointments... 
			</div>
		</ng-container>
	</section>
	<div class="d-flex pt-2 border-top mb-3 mt-3">
		<div class="flex-fill ms-1">
			<button class="save-button btn btn-outline-success save-button me-3" (click)="save()"><i class="bi bi-check-circle"></i> Save</button>
			<button class="cancel-button btn btn-outline-danger save-button" (click)="cancel()"><i class="far fa-ban"></i> Cancel</button>
		</div>
	</div>

</slick-dialog>
