<div *ngIf="invoiceCustomField1Name || invoiceCustomField2Name || invoiceCustomField3Name || invoiceCustomField4Name" style="gap: 15px" class="d-flex flex-fill mb-2">

	<div *ngIf="invoiceCustomField1Name" class="flex-fill">
		<label>{{invoiceCustomField1Name}}</label>
		<input type="text" class="form-control" [(ngModel)]="invoice.custom1" />
	</div>

	<div *ngIf="invoiceCustomField2Name" class="flex-fill">
		<label>{{invoiceCustomField2Name}}</label>
		<input type="text" class="form-control" [(ngModel)]="invoice.custom2" />
	</div>

	<div *ngIf="invoiceCustomField3Name" class="flex-fill">
		<label>{{invoiceCustomField3Name}}</label>
		<input type="text" class="form-control" [(ngModel)]="invoice.custom3" />
	</div>

	<div *ngIf="invoiceCustomField4Name" class="flex-fill">
		<label>{{invoiceCustomField4Name}}</label>
		<input type="text" class="form-control" [(ngModel)]="invoice.custom4" />
	</div>
</div>