import { GlobalsService } from "@services";

export interface ILocationModel {
	locationId: number;
	name: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	zipcode: string;
	country: string;
	timeZone: string;
	serviceEmailAddress: string;
	serviceEmailDisplayName: string;
	accountingEmailAddress: string;
	accountingEmailDisplayName: string;
	isMain: boolean;
	active: boolean;

	isChecked: boolean;
}

export class LocationModel implements ILocationModel {
	constructor() {
		this.locationId = 0;
		this.active = true;
		this.timeZone = GlobalsService.company.timeZone;
	}

	locationId: number;
	name: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	zipcode: string;
	country: string;
	timeZone: string;
	serviceEmailAddress: string;
	serviceEmailDisplayName: string;
	accountingEmailAddress: string;
	accountingEmailDisplayName: string;
	isMain: boolean;
	active: boolean;

	isChecked: boolean;
}
