import { UtilsService } from "@services";
import { IDropdownModel } from "../dropdown/dropdown.model";
import { LathamOrderPickListSpecTypes } from "./latham-order-pick-list-spec.model";

export interface ILathamOrderPickListDefinitionConditionModel {
	uuid: string;
	specName: string;
	operator: string;
	specValue: string;

	specType: LathamOrderPickListSpecTypes;
	specValues: IDropdownModel[];
}

export class LathamOrderPickListDefinitionConditionModel implements ILathamOrderPickListDefinitionConditionModel {
	constructor() {
		this.uuid = UtilsService.newGuid();
		this.specType = LathamOrderPickListSpecTypes.string;
	}

	uuid: string;
	specName: string;
	operator: string;
	specValue: string;

	specType: LathamOrderPickListSpecTypes;
	specValues: IDropdownModel[];
}