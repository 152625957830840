import { Injectable } from '@angular/core';
import { DropdownModel, IDropdownModel, IPayPeriodDefinitionModel, IPayPeriodModel, ITeamMemberModel, ITimeclockEntryModel, ITimeclockUserGridModel } from '@models';
import { Subject } from 'rxjs';
import { GlobalsService, HttpService } from '@services';
import * as moment from 'moment';


@Injectable()
export class TimeclockStore {
	private _selectedPayPeriodYear: number;
	private _selectedPayPeriodDefinition: IPayPeriodDefinitionModel;
	private _selectedPayPeriodDefinitionId?: number;
	private _selectedPayPeriod: IPayPeriodModel;
	private _selectedPayPeriodId?: number;
	private _filter: string;

	private _allPayPeriods: IPayPeriodModel[] = [];
	private _payPeriods: IPayPeriodModel[] = [];
	private _payPeriodDefinitions: IPayPeriodDefinitionModel[] = [];
	private _payPeriodYears: IDropdownModel[] = [];

	private _canEdit: boolean = false;
	private _canViewAll: boolean = false;
	private _canExport: boolean = false;
	private _canViewTeamMembers: boolean = false;
	private _showMyTeams: boolean = false;
	private _showErrorsOnly: boolean = false;
	private _canApprove: boolean = false;
	private _teamLeadMembers: ITeamMemberModel[];
	private _myTeamMembers: ITeamMemberModel[];

	constructor(private httpService: HttpService) {

	}

	async init() {
		for (let year = 2022; year <= moment().year() + 1; year++) {
			this._payPeriodYears.push(new DropdownModel(year.toString(), year.toString()));
		}
		this._selectedPayPeriodYear = parseInt(localStorage.getItem(`TIMECLOCK_SELECTED_PAY_PERIOD_YEAR_${GlobalsService.company.companyId}`) || '2024');

		const getPayPeriodDefinitionsPromise = this.httpService.get("/payroll/getPayPeriodDefinitions", { showInactive: false });
		const getPayPeriods = this.httpService.get("/payroll/getPayPeriods");

		const results = await Promise.all([getPayPeriodDefinitionsPromise, getPayPeriods]);
		this._payPeriodDefinitions = results[0];
		this._allPayPeriods = results[1];


		if (this._payPeriodDefinitions && this._payPeriodDefinitions.length > 0) {
			this.populatePayPeriodDefinition();

			if (this._selectedPayPeriodDefinition) {
				await this.refreshPayPeriods();
				this.populatePayPeriodDefinition();
			}
		}
		this._canExport = GlobalsService.checkPermission("timeclock", "ExportToCSV");
		this._canViewAll = GlobalsService.checkPermission("timeclock", "ViewAllUsers");
		this._showMyTeams = (localStorage.getItem(`TIMECLOCK_SHOW_MY_TEAMS_${GlobalsService.company.companyId}`) || 'false') === 'true';
		this._showErrorsOnly = (localStorage.getItem(`TIMECLOCK_SHOW_ERRORS_ONLY_${GlobalsService.company.companyId}`) || 'false') === 'true';

		this._filter = localStorage.getItem(`TIMECLOCK_FILTER_${GlobalsService.company.companyId}`) || 'all';
	}

	setAll(payPeriodYear: number, payPeriodDefinitionId: number, payPeriodId: number) {
		this._selectedPayPeriodYear = payPeriodYear;
		localStorage.setItem(`TIMECLOCK_SELECTED_PAY_PERIOD_YEAR_${GlobalsService.company.companyId}`, (payPeriodYear || 2023).toString())
		this.refreshPayPeriods();

		this._selectedPayPeriodDefinitionId = payPeriodDefinitionId;
		localStorage.setItem(`TIMECLOCK_SELECTED_PAY_PERIOD_DEFINITION_ID_${GlobalsService.company.companyId}`, (payPeriodDefinitionId ?? 0).toString())

		this._selectedPayPeriodId = payPeriodId;
		localStorage.setItem(`TIMECLOCK_SELECTED_PAY_PERIOD_ID_${GlobalsService.company.companyId}_${this._selectedPayPeriodYear}_${this._selectedPayPeriodDefinitionId}`, (payPeriodId ?? 0).toString())

		this.refreshGridSubject.next();
	}

	// Used any time we need to refresh the grid
	refreshGridSubject: Subject<void> = new Subject<void>();

	public async refreshDefinitionsAndPayPeriods() {
		const getPayPeriodDefinitionsPromise = this.httpService.get("/payroll/getPayPeriodDefinitions", { showInactive: false });
		const getPayPeriods = this.httpService.get("/payroll/getPayPeriods");

		const results = await Promise.all([getPayPeriodDefinitionsPromise, getPayPeriods]);
		this._payPeriodDefinitions = results[0];
		this._allPayPeriods = results[1];

		if (this._payPeriodDefinitions && this._payPeriodDefinitions.length > 0) {
			this.populatePayPeriodDefinition();

			if (this._selectedPayPeriodDefinition) {
				await this.refreshPayPeriods();
				this.populatePayPeriodDefinition();
			}
		}
	}

	//*****************************************************************************
	//* payPeriodYear
	//*****************************************************************************
	get selectedPayPeriodYear(): number {
		return this._selectedPayPeriodYear;
	}

	set selectedPayPeriodYear(payPeriodYear: number) {
		this._selectedPayPeriodYear = payPeriodYear;
		localStorage.setItem(`TIMECLOCK_SELECTED_PAY_PERIOD_YEAR_${GlobalsService.company.companyId}`, (payPeriodYear || moment().year).toString())
		this.refreshPayPeriods();

		this.refreshGridSubject.next();
	}

	getPayPeriodYears(): IDropdownModel[] {
		return this._payPeriodYears;
	}

	//*****************************************************************************
	//* payPeriodDefinition
	//*****************************************************************************
	get selectedPayPeriodDefinition(): IPayPeriodDefinitionModel {
		this.populatePayPeriodDefinition();
		return this._selectedPayPeriodDefinition;
	}

	set selectedPayPeriodDefinition(payPeriodDefinition: IPayPeriodDefinitionModel) {
		this._selectedPayPeriodDefinition = payPeriodDefinition;
		this._selectedPayPeriodDefinitionId = payPeriodDefinition?.payPeriodDefinitionId;

		localStorage.setItem(`TIMECLOCK_SELECTED_PAY_PERIOD_DEFINITION_ID_${GlobalsService.company.companyId}`, (payPeriodDefinition?.payPeriodDefinitionId ?? 0).toString())

		this.refreshPayPeriods();

		this.refreshGridSubject.next();
	}

	getPayPeriodDefinitions(): IPayPeriodDefinitionModel[] {
		return this._payPeriodDefinitions;
	}

	async refreshPayPeriodDefinitions(): Promise<IPayPeriodDefinitionModel[]> {
		this._payPeriodDefinitions = await this.httpService.get("/payroll/getPayPeriodDefinitions", { showInactive: false });

		return this._payPeriodDefinitions;
	}

	//*****************************************************************************
	//* filter
	//*****************************************************************************
	filterSubject: Subject<string> = new Subject<string>();
	get filter(): string {
		return this._filter;
	}

	set filter(filter: string) {
		this._filter = filter;
		localStorage.setItem(`TIMECLOCK_FILTER_${GlobalsService.company.companyId}`, filter || 'all');

		this.refreshGridSubject.next();
	}

	//*****************************************************************************
	//* payPeriods
	//*****************************************************************************
	selectedPayPeriodSubject: Subject<IPayPeriodModel> = new Subject<IPayPeriodModel>();
	get selectedPayPeriod(): IPayPeriodModel {
		this.populatePayPeriod();
		return this._selectedPayPeriod;
	}

	set selectedPayPeriod(payPeriod: IPayPeriodModel) {
		this._selectedPayPeriod = payPeriod;
		this._selectedPayPeriodId = this._selectedPayPeriod.payPeriodId;
		localStorage.setItem(`TIMECLOCK_SELECTED_PAY_PERIOD_ID_${GlobalsService.company.companyId}_${this._selectedPayPeriodYear}_${this._selectedPayPeriodDefinitionId}`, (payPeriod?.payPeriodId ?? 0).toString())

		this.refreshGridSubject.next();
	}

	getPayPeriods(): IPayPeriodModel[] {
		return this._payPeriods;
	}

	getAllPayPeriods(): IPayPeriodModel[] {
		return this._allPayPeriods;
	}

	payPeriodsChangedSubject: Subject<IPayPeriodModel[]> = new Subject<IPayPeriodModel[]>();
	private refreshPayPeriods() {
		this._payPeriods = this._allPayPeriods
			.filter(x => x.payPeriodDefinitionId === this.selectedPayPeriodDefinition.payPeriodDefinitionId && x.payPeriodYear == this._selectedPayPeriodYear);

		this.populatePayPeriod();

		this.payPeriodsChangedSubject.next(this._payPeriods);
	}

	private populatePayPeriodDefinition() {
		this._selectedPayPeriodDefinition = null;
		this._selectedPayPeriodDefinitionId = null;

		if (this._payPeriodDefinitions && this._payPeriodDefinitions.length > 0) {
			this._selectedPayPeriodDefinitionId = parseInt(localStorage.getItem(`TIMECLOCK_SELECTED_PAY_PERIOD_DEFINITION_ID_${GlobalsService.company.companyId}`) || this._payPeriodDefinitions[0].payPeriodDefinitionId.toString());
			this._selectedPayPeriodDefinition = this._payPeriodDefinitions.find(x => x.payPeriodDefinitionId === this._selectedPayPeriodDefinitionId);

			if (!this._selectedPayPeriodDefinition) {
				this._selectedPayPeriodDefinition = this._payPeriodDefinitions[0];
				this._selectedPayPeriodDefinitionId = this._selectedPayPeriodDefinition.payPeriodDefinitionId;
				localStorage.setItem(`TIMECLOCK_SELECTED_PAY_PERIOD_DEFINITION_ID_${GlobalsService.company.companyId}`, this._selectedPayPeriodDefinitionId.toString());
			}
		}
	}

	private populatePayPeriod() {
		this._selectedPayPeriod = null;
		this._selectedPayPeriodId = null;
		if (this._payPeriods && this._payPeriods.length > 0) {
			this._selectedPayPeriodId = parseInt(localStorage.getItem(`TIMECLOCK_SELECTED_PAY_PERIOD_ID_${GlobalsService.company.companyId}_${this._selectedPayPeriodYear}_${this._selectedPayPeriodDefinitionId}`) || '0');
			this._selectedPayPeriod = this._payPeriods.find(x => x.payPeriodId === this._selectedPayPeriodId);

			if (!this._selectedPayPeriod) {
				this._selectedPayPeriod = this._payPeriods[0];
				this._selectedPayPeriodId = this._selectedPayPeriod.payPeriodId;
				localStorage.setItem(`TIMECLOCK_SELECTED_PAY_PERIOD_ID_${GlobalsService.company.companyId}_${this._selectedPayPeriodYear}_${this._selectedPayPeriodDefinitionId}`, this._selectedPayPeriodId.toString());
			}
		}
	}

	//*****************************************************************************
	//* CanViewAll
	//*****************************************************************************
	get canViewAll(): boolean {
		return this._canViewAll;
	}

	//*****************************************************************************
	//* CanEdit
	//*****************************************************************************
	async canEdit(timeClockUser: ITimeclockUserGridModel): Promise<boolean> {
		if (GlobalsService.userInfo.userId === timeClockUser.userId)
			this._canEdit = GlobalsService.checkPermission("timeclock", "EditOwn")
		else
			this._canEdit = GlobalsService.checkPermission("timeclock", "EditOthers");


		return this._canEdit;
	}

	//*****************************************************************************
	//* CanExport
	//*****************************************************************************
	get canExport(): boolean {
		return this._canExport
    }

	//*****************************************************************************
	//* CanViewTeamMembers
	//*****************************************************************************
	get canViewTeamMembers(): boolean {
		return this._canViewTeamMembers;
	}

	//*****************************************************************************
	//* ShowMyTeams
	//*****************************************************************************
	get showMyTeams(): boolean {
		return this._showMyTeams;
	}

	set showMyTeams(showMyTeams: boolean) {
		this._showMyTeams = showMyTeams;
		localStorage.setItem(`TIMECLOCK_SHOW_MY_TEAMS_${GlobalsService.company.companyId}`, this._showMyTeams.toString());
	}
	//*****************************************************************************
	//* ShowMyTeams
	//*****************************************************************************
	get showErrorsOnly(): boolean {
		return this._showErrorsOnly;
	}

	set showErrorsOnly(showErrorsOnly: boolean) {
		this._showErrorsOnly = showErrorsOnly;
		localStorage.setItem(`TIMECLOCK_SHOW_ERRORS_ONLY_${GlobalsService.company.companyId}`, this._showErrorsOnly.toString());
    }
	//*****************************************************************************
	//* TeamLeadMembers
	//*****************************************************************************
	async getTeamLeadMembers(): Promise<ITeamMemberModel[]> {
		if (this._teamLeadMembers)
			return this._teamLeadMembers;
		else {
			this._teamLeadMembers = await this.httpService.get("/teams/getTeamLeadMembers", { userId: GlobalsService.userInfo.userId });
			return this._teamLeadMembers;
		}
	}

	//*****************************************************************************
	//* MyTeamMembers
	//*****************************************************************************
	async getMyTeamMembers(): Promise<ITeamMemberModel[]> {
		if (this._myTeamMembers)
			return this._myTeamMembers;
		else {
			this._myTeamMembers = await this.httpService.get("/teams/getMyTeamMembers", { userId: GlobalsService.userInfo.userId });
			return this._myTeamMembers;
		}
	}

	//*****************************************************************************
	//* CanApprove
	//*****************************************************************************
	async CanApprove(timeclockUser: ITimeclockUserGridModel): Promise<boolean> {
		this._canApprove = false;
		if (GlobalsService.userInfo.roleTypeId === 1)
			this._canApprove = true;
		else {
			this._canApprove = ((this._teamLeadMembers ?? []).findIndex(x => x.teamMemberUserId === timeclockUser.userId) >= 0);
		}
		return this._canApprove;
	}


	//*****************************************************************************
	//* RefreshCurrentEntry
	//*****************************************************************************
	refreshCurrentEntry$: Subject<ITimeclockEntryModel> = new Subject<ITimeclockEntryModel>();
	refreshCurrentEntry(timeclockEntryModel: ITimeclockEntryModel) {
		this.refreshCurrentEntry$.next(timeclockEntryModel);
	}

}
