import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SlickUtilsService } from "../utils/slick-utils.service";

@Injectable()
export class SlickDropDownService {
	constructor(private http: HttpClient) { }

	public getVisibleItems(items: any[], idFieldName: string, textFieldName: string): any[] {
		let foundSomeText: boolean = false;

		if (!items || items.length === 0)
			return null;

		let visibleItems: any[] = items.map((item) => {
			let id = SlickUtilsService.getDeepObject(item, idFieldName);
			if (id === null || id === undefined) {
				console.debug("%c slick-drop-down: Id not found for dropdown item.  The idFieldName is '" + idFieldName + "'.  Is this correct?", 'background: red; color: white');
				console.debug("First Item: ", items[0]);
			}

			let text = SlickUtilsService.getDeepObject(item, textFieldName);
			if (text !== null && text !== undefined)
				foundSomeText = true;

			return {
				id: id,
				text: text ? text.toString() : null,
				item: item
			}
		});

		if (foundSomeText === false) {
			console.error("%c slick-drop-down: Warning!  No text was found for dropdown item.  The textFieldName is '" + textFieldName + "'.  Is this correct?", 'background: red; color: white');
			console.error("First Item: ", items[0]);
		}


		return visibleItems;
	}

	public getSelectedItem(items: any[], selectedId: string | number): any {
		if (!items || items.length === 0)
			return null;

		let selectedItem = items.find((item) => { return item.id == selectedId });

		if (selectedItem)
			return selectedItem;
		else
			return null;
	}

	public async getItemsFromServer(url: string): Promise<any> {
		return Promise.resolve((await this.http.get(url).toPromise()));
	}

	public getItemIndex(items: any[], selectedId: string): number {
		return items.findIndex((item) => { return item.id == selectedId });
	}
}