import { Component, Input, ViewChild } from "@angular/core";
import { SlickDialogComponent } from "../slick-dialog/slick-dialog.component";
import { SlickSleepService } from "../utils/slick-sleep.service";

@Component({
	selector: 'slick-google-map-dialog',
	template:
	`<slick-dialog *ngIf='isVisible' header='Map' [width]='600' #googleMapDialogRef>
		<slick-google-map *ngIf='showMap' [options]="options" [overlays]="overlays" [width]="'580px'" [height]="'500px'"></slick-google-map>
	</slick-dialog>`
})
export class SlickGoogleMapDialogComponent {
	@Input() options: google.maps.MapOptions;
	@Input() overlays: google.maps.Marker[];

	@ViewChild("googleMapDialogRef") googleMapDialogRef: SlickDialogComponent;

	isVisible: boolean = false;
	showMap: boolean = false;

	constructor() { }

	async showDialog(googleMapOptions: google.maps.MapOptions = null, googleMapOverlays: google.maps.Marker[] = null) {
		this.isVisible = true;

		this.showMap = true;
		await SlickSleepService.sleep();

		if (googleMapOptions) {
			this.options = {};
			this.options = googleMapOptions;
		}

		if (googleMapOverlays)
			this.overlays = googleMapOverlays;

		this.googleMapDialogRef.showDialog();
	}

	onOk() {
		this.options = null
		this.overlays = null;

		this.googleMapDialogRef.hideDialog();
	}
}
