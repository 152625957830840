import { Component, ViewChild, Output, Input, EventEmitter } from "@angular/core";
import { SlickConfirmDialogComponent, SlickConfirmDialogResults, SlickDialogComponent, SlickToastService } from "@slick-components";
import { ICustomerModel, ICreditCardPaymentTypeModel, AddressModel } from "@models";
import * as moment from "moment";
import creditCardType, { getTypeInfo, types as CardType } from 'credit-card-type';
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { CreditCardValidators } from "angular-cc-library";
import { GlobalsService, CreditCardService } from "@services";
import { USAePayService } from "../../../../../libraries/services/credit-card-processing";

@Component({
	selector: "credit-card-edit-dialog",
	templateUrl: "./credit-card-edit-dialog.component.html",
	styleUrls: ["./credit-card-edit-dialog.component.css"],
	providers: [CreditCardService]
})

export class CreditCardEditDialogComponent {
	@ViewChild("creditCardEditDialogRef", { static: true }) creditCardEditDialogRef: SlickDialogComponent;
	@ViewChild("deleteCardRef") deleteCardRef: SlickConfirmDialogComponent;

	@Output() onChange: EventEmitter<any> = new EventEmitter();
	@Input() isMobile: boolean;
	creditCardPaymentType: ICreditCardPaymentTypeModel;

	isEditDialog: boolean;

	paymentForm: FormGroup;
	submitted: boolean;

	deleteSpinnerStatus: string;
	saveSpinnerStatus: string;

	isCanada: boolean = GlobalsService.company.useBambora;
	customerCompanyId: number;
	allowAmericanExpress: boolean = GlobalsService.company.allowAmericanExpress;
	showAmexError = false;


	countries: any = [
		{ id: 'US', text: 'US' },
		{ id: 'Canada', text: 'Canada' },
		{ id: 'Mexico', text: 'Mexico' },
		{ id: 'Israel', text: 'Israel' },
	]

	constructor(private readonly _fb: FormBuilder,
		private slickToastService: SlickToastService,
		private creditCardService: CreditCardService,
	private usaEpayService: USAePayService) {

	}

	openDialog(creditCardPaymentType: ICreditCardPaymentTypeModel, isNew: boolean, customerCompanyId: number = GlobalsService.company.companyId) {
		if (this.isMobile) {
			this.creditCardEditDialogRef.width = 360;
		}
		this.submitted = false;
		this.deleteSpinnerStatus = "reset";
		this.saveSpinnerStatus = "reset";
		this.customerCompanyId = customerCompanyId;
		this.creditCardPaymentType = creditCardPaymentType;
		this.creditCardPaymentType.expDate = this.adjustExpirationDate(this.creditCardPaymentType.expDate);
		this.isEditDialog = !isNew;
		if (!this.creditCardPaymentType.addressModel)
			this.creditCardPaymentType.addressModel = new AddressModel();
		this.paymentForm = this.buildForm(creditCardPaymentType, isNew);
		this.creditCardEditDialogRef.showDialog();
	}

	buildForm(creditCardPaymentType: ICreditCardPaymentTypeModel, isNew: boolean) {
		const base = {
			accountNumber: null,
			expDate: [this.adjustExpirationDate(this.creditCardPaymentType.expDate), [CreditCardValidators.validateExpDate as any]],
			cvc: [creditCardPaymentType.cvc, [Validators.required, Validators.minLength(3) as any, Validators.maxLength(4) as any]],
			paymentDescription: [this.creditCardPaymentType.paymentDescription, []],
			isDefault: [this.creditCardPaymentType.isDefault, []]
		};

		if (isNew) {
			base.accountNumber = [creditCardPaymentType.accountNumber, [CreditCardValidators.validateCCNumber as any]];
		}

		return this._fb.group(base);
	}

	closeDialog() {
		this.paymentForm.reset();
		this.submitted = false;
		this.creditCardEditDialogRef.hideDialog();
	}

	checkCardType(cardNumber: string): void {
        const sanitizedCardNumber = cardNumber.replace(/\D/g, '');
        const isAmex = sanitizedCardNumber.startsWith('34') || sanitizedCardNumber.startsWith('37');

        if (isAmex && !this.allowAmericanExpress) {
            this.showAmexError = true;
            this.paymentForm.controls.accountNumber.setErrors({ amexNotAllowed: true });
        } else {
            this.showAmexError = false;
            this.paymentForm.controls.accountNumber.setErrors(null);
        }
    }

	adjustExpirationDate(expDate) {
		if (!expDate) return expDate;

		let parts = [];
		if (expDate.indexOf("-") > -1)
			parts = expDate.split("-");
		else if (expDate.indexOf("/") > -1)
			parts = expDate.split("/");

		if (parts.length !== 2) return expDate;

		parts[0] = parts[0].trim();
		parts[1] = parts[1].trim();

		if (parts[0].length === 4 && parts[1].length === 2)
			return moment(expDate, "YYYY/MM").format("MM / YYYY");

		if (parts[0].length === 2 && parts[1].length === 2 && parseInt(parts[0]) <= 12 && parseInt(parts[1]) >= 18)
			return moment(expDate, "MM/YY").format("MM / YYYY");

		if (parts[0].length === 2 && parts[1].length === 2 && parseInt(parts[0]) >= 18 && parseInt(parts[1]) <= 12)
			return moment(expDate, "YY/MM").format("MM / YYYY");

		if (parts[0].length === 2 && parts[1].length === 4)
			return moment(expDate, "MM/YYYY").format("MM / YYYY");
	}

	cancel() {
		this.showAmexError = false
		this.closeDialog();
	}

	async delete() {
		this.deleteSpinnerStatus = "spin";
		const confirmDelete = await this.deleteCardRef.confirm();
		if (confirmDelete === SlickConfirmDialogResults.Ok) {

			try {
				if (GlobalsService.company.parentCompanyId === 133 && (this.customerCompanyId != GlobalsService.company.companyId)) 
					await this.usaEpayService.deleteCreditCardPaymentType(this.creditCardPaymentType);
				else
					await this.creditCardService.deleteCreditCardPaymentType(this.creditCardPaymentType);

				this.deleteSpinnerStatus = "ok";
				this.slickToastService.showSuccess("Card deleted successfully");
				this.onChange.emit(null);
				this.closeDialog();
			} catch (e) {
				this.deleteSpinnerStatus = "error";
			}
		}
		else 
			this.deleteSpinnerStatus = "reset";
	}

	getPaymentService() {
		if (GlobalsService.company.useBambora) return "Bambora";
		if (GlobalsService.company.useUSAePay) return "USAePay";
		if (GlobalsService.company.usePayTrace) return "PayTrace";
		if (GlobalsService.company.useZift) return "Zift";
	}

	async onSubmit(demoForm) {
		this.saveSpinnerStatus = 'spin';
		this.submitted = true;

		if (this.paymentForm.valid) {
			

			this.creditCardPaymentType.expDate = this.paymentForm.value.expDate;
			this.creditCardPaymentType.paymentDescription = this.paymentForm.value.paymentDescription;
			this.creditCardPaymentType.cvc = this.paymentForm.value.cvc;

			if (!this.isEditDialog) {
				this.creditCardPaymentType.accountNumber = this.paymentForm.value.accountNumber;
				const creditCardTypeData = creditCardType(this.creditCardPaymentType.accountNumber.substring(0, 4));
				if (creditCardTypeData && creditCardTypeData.length)
					this.creditCardPaymentType.paymentType = creditCardTypeData[0].niceType;
				this.creditCardPaymentType.isDefault = true;
				
				if (GlobalsService.company.parentCompanyId === 133 && (this.customerCompanyId != GlobalsService.company.companyId))
					this.creditCardPaymentType.processor = "USAePay";
				else
					this.creditCardPaymentType.processor = this.getPaymentService();
			}

			this.creditCardPaymentType.expDate =
				moment(this.creditCardPaymentType.expDate, "MM / YYYY").format("MM/YYYY");
			try {
				// TODO: Hardcoded
				let updated: ICreditCardPaymentTypeModel;
				let saved: ICreditCardPaymentTypeModel;

				if (this.isEditDialog) {
					if (GlobalsService.company.parentCompanyId === 133 && (this.customerCompanyId != GlobalsService.company.companyId))
						updated = await this.usaEpayService.updateCreditCardPaymentType(this.creditCardPaymentType);
					else
						updated = await this.creditCardService.updateCreditCardPaymentType(this.creditCardPaymentType);

					this.onChange.emit(updated);
                } else {
                    if (GlobalsService.company.parentCompanyId === 133 && (this.customerCompanyId != GlobalsService.company.companyId))

                        saved = await this.usaEpayService.addCreditCardPaymentType(this.creditCardPaymentType);
                    else
                        saved = await this.creditCardService.addCreditCardPaymentType(this.creditCardPaymentType);
                    this.onChange.emit(saved);
                }

				this.saveSpinnerStatus = "ok";
				this.closeDialog()
			} catch (e) {
				this.saveSpinnerStatus = "error";
			}
		} else {
			this.saveSpinnerStatus = "error";
		}
	}

	onGoogleMapAddressSelect(placeResult: google.maps.places.PlaceResult) {
		this.setAddress(placeResult);
	}

	private setAddress(placeResult: google.maps.places.PlaceResult) {
		this.creditCardPaymentType.addressModel.address1 = ""
		this.creditCardPaymentType.addressModel.address2 = ""
		this.creditCardPaymentType.addressModel.city = ""
		this.creditCardPaymentType.addressModel.state = ""
		this.creditCardPaymentType.addressModel.zipcode = ""
		this.creditCardPaymentType.addressModel.country = ""

		placeResult.address_components.forEach(adr => {
			adr.types.forEach(type => {
				switch (type) {
					case "street_number":
					case "route":
						this.creditCardPaymentType.addressModel.address1 += adr.long_name + " ";

						break;

					case "locality":
						this.creditCardPaymentType.addressModel.city = adr.long_name;
						break;

					case "administrative_area_level_1":
						this.creditCardPaymentType.addressModel.state = adr.short_name;
						break;

					case "postal_code":
						this.creditCardPaymentType.addressModel.zipcode = adr.short_name;
						break;

					case "country":
						if (adr.short_name === "CA") {
							this.creditCardPaymentType.addressModel.country = "Canada";

						} else if (adr.short_name === "IL") {
							this.creditCardPaymentType.addressModel.country = "Israel";
						} else if (adr.short_name === "MX") {
							this.creditCardPaymentType.addressModel.country = "Mexico";
						} else {
							this.creditCardPaymentType.addressModel.country = adr.short_name;

						}
						break;
				};
			});
		
		});
	}
}