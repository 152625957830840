import { Component, Input, OnChanges, Output, EventEmitter, ViewChild, HostBinding, OnInit } from '@angular/core';
import { SalesOrdersAuthService } from '@services/utils/auth-services/sales-orders-auth.service';
import { SleepService, GlobalsService, LookupService, LineItemsService, JobsService, FunctionLockService, UtilsService, PaymentsService, ShippingService, JellyFishService, JobCostingService } from '@services';
import { SlickToastService, SlickScreenBlockerService, SlickFileListComponent, SlickDialogComponent } from "@slick-components";
import { IInvoiceModel, IDropdownModel, ICustomerModel, CreditCardProcessPaymentModel, ICreditCardPaymentTypeModel, ICreditCardPaymentApprovedModel, IEmailerModel, UserModel, DropdownModel, IDocumentModel, AppliedPaymentModel, IAppliedPaymentModel, IPaymentListViewModel, IShippingAddressModel, ShippingAddressModel, ILineItemModel, JellyFishOrderSpecsModel, UserLookupModel, IJobCostingSetupModel, JobModel } from '@models';
import { InvoicesService } from '@services';
import { CreditCardDialogComponent } from '@components/credit-card-dialog/credit-card-dialog.module';
import { EmailerDialogComponent } from '@components/emailer-dialog';
import * as moment from "moment";
import { AddPaymentDialogComponent } from "@app/payments/payments-components";
import Swal from 'sweetalert2';
import { InventoryStore } from '@stores';
import { InventoryLineItemsComponent, InventoryLineItemsModule } from "@shared-components/inventory-line-items"
import { TexterDialogComponent } from '@shared-components/texter-dialog/texter-dialog.component';
import { JellyFishSystemLineItemService } from '@services/jelly-fish-system-line-items.service';
import { CreditCardSelectorDialogComponent } from '@shared-components/credit-card-selector-dialog/credit-card-selector-dialog.module';

@Component({
	selector: 'sales-order-edit',
	templateUrl: './sales-order-edit.component.html',
	styleUrls: ['./sales-order-edit.component.css'],
	providers: [SalesOrdersAuthService, JobsService, PaymentsService, FunctionLockService, ShippingService, JellyFishService, JellyFishSystemLineItemService, JobCostingService]
})
export class SalesOrderEditComponent implements OnChanges, OnInit {
	@HostBinding('style.flex') flex = '1';

	@Input() salesOrderModel: IInvoiceModel;
	@Input() isDialog: boolean = false;
	@Output() salesOrderModelChange: EventEmitter<IInvoiceModel> = new EventEmitter<IInvoiceModel>();
	@Output() onSave = new EventEmitter<IInvoiceModel>();
	@Output() onCancel = new EventEmitter<void>();
	@Output() onDuplicate = new EventEmitter<void>();

	@ViewChild("jobPhotosRef") jobPhotosRef: SlickFileListComponent;
	@ViewChild("creditCardDialogRef") creditCardDialogRef: CreditCardDialogComponent;
	@ViewChild("emailerDialogRef") emailerDialogRef: EmailerDialogComponent;
	@ViewChild("inventoryLineItemsRef") inventoryLineItemsRef: InventoryLineItemsComponent;
	@ViewChild("texterDialogRef") texterDialogRef: TexterDialogComponent;
	@ViewChild("addPaymentDialogRef") addPaymentDialogRef: AddPaymentDialogComponent;
	@ViewChild("creditCardSelectorDialogRef") creditCardSelectorDialogRef: CreditCardSelectorDialogComponent;

	isMizu: boolean = GlobalsService.company.companyId === 1;

	useAdvancedTax: boolean = GlobalsService.company.useAdvancedTax;
	isQuickbooksEnabled = GlobalsService.isQuickbooksEnabled;
	useLathamOrdering = GlobalsService.company.useLathamOrdering;
	paymentTerms: IDropdownModel[];
	spinnerStatus: string = null;
	isSubmitted = false;
	emailerModel: IEmailerModel;
	tabIndex = 0;
	tabKey: string;
	createdByUser: UserLookupModel;
	useInventory = GlobalsService.company.useInventory;
	unappliedPayments: IPaymentListViewModel[];
	inventoryWarehouses: IDropdownModel[];
	hasInventoryItems: boolean;
	canMoveToEstimate: boolean = GlobalsService.checkPermission("SalesOrders", "CanMoveToEstimate");
	downPaymentType: number = GlobalsService.company.downPaymentType || 0;
	showDownPaymentPercent: boolean;
	showDownPaymentDollar: boolean;
	salesReps: IDropdownModel[] = [];
	jobPhotos: IDocumentModel[];
	useShipping = GlobalsService.company.useShipping;
	useJellyFishOrdering: boolean = GlobalsService.company.parentCompanyId === 133 || GlobalsService.company.companyId === 133;
	paymentsExpanded: boolean = false;
	canAccessJobCosting: boolean = GlobalsService.checkPermission("JobCosting", "Access");

	jobCostingSetupModel: IJobCostingSetupModel;
	isJobCostSetupValid: boolean = true;
	job: JobModel;



	parentMaxHeight = (GlobalsService.userInfo.layoutSettings.salesOrdersLayout === 'grid-full-screen') ? "calc(100vh - 120px)" : "calc(100vh - 100px)"
	tabMaxHeight = (GlobalsService.userInfo.layoutSettings.salesOrdersLayout === 'grid-full-screen') ? "calc(100vh - 265px)" : "calc(100vh - 235px)"

	constructor(
		public salesOrdersAuthService: SalesOrdersAuthService,
		private invoicesService: InvoicesService,
		private lineItemsService: LineItemsService,
		private lookupService: LookupService,
		private functionLockService: FunctionLockService,
		private inventoryStore: InventoryStore,
		private jellyFishService: JellyFishService,
		private jobsService: JobsService,
		private jobCostingService: JobCostingService,

		private paymentsService: PaymentsService,
		private slickToastService: SlickToastService,
		private slickScreenBlockerService: SlickScreenBlockerService,
		private shippingService: ShippingService) {
	}

	async ngOnInit() {
		this.jobCostingSetupModel = await this.lookupService.getJobCostingSetupModel();
	}

	async ngOnChanges() {
		this.isSubmitted = false;
		this.spinnerStatus = "reset";
		this.hasInventoryItems = false;
		this.jobPhotos = null;
		this.paymentsExpanded = false;
		this.job = null;

		this.isDialog = ((this.isDialog || false).toString().toLowerCase() === 'true');
		this.tabIndex = 0;

		if (this.isDialog === true) {
			this.parentMaxHeight = null;
			this.tabMaxHeight = null;
		}

		if (this.salesOrderModel) {
			this.salesReps = this.lookupService.getUsers(this.salesOrderModel.salesRepUserId)
				.filter(x => x.isSalesRep === true)
				.map(x => new DropdownModel(x.userId, x.fullName));

			if (this.salesOrderModel.invoiceId === 0)
				this.salesOrderModel.invoiceDate = moment().startOf("date").toDate();

			this.paymentTerms = this.lookupService.getPaymentTermsForDropdown(this.salesOrderModel.paymentTermId);

			if (this.salesOrderModel.createdBy)
				this.createdByUser = this.lookupService.getUsers().find(x => x.userId === this.salesOrderModel.createdBy);

			this.inventoryWarehouses = this.inventoryStore.getInventoryWarehousesForDropdown(this.salesOrderModel.inventoryWarehouseId);

			this.unappliedPayments = null;
			if (this.tabKey === "Payments")
				this.unappliedPayments = await this.paymentsService.getUnappliedPayments(this.salesOrderModel.customer.customerId);


			if(this.salesOrderModel.jobId){
				this.job = await this.jobsService.getJob(this.salesOrderModel.jobId);
			}

			if (GlobalsService.company.useInventory && this.salesOrderModel.useDefaultInventory && this.tabKey === "Inventory") {
				this.salesOrderModel.inventoryLineItems = this.invoicesService.syncInventoryFromLineItems(this.salesOrderModel);
			}

			// Default to don't show these
			this.showDownPaymentDollar = false;
			this.showDownPaymentPercent = false;

			if (this.downPaymentType === 3 || this.downPaymentType === 4) {
				this.showDownPaymentPercent = (this.downPaymentType === 3);
				this.showDownPaymentDollar = (this.downPaymentType === 4);
				if (this.salesOrderModel.downPaymentAmount) {
					this.showDownPaymentPercent = false;
					this.showDownPaymentDollar = true;
				}
				else if (this.salesOrderModel.downPaymentPercent) {
					this.showDownPaymentDollar = false;
					this.showDownPaymentPercent = true;
				}
			}
			if (this.useInventory) {
				this.inventoryWarehouses = this.inventoryStore.getInventoryWarehousesForDropdown(this.salesOrderModel.inventoryWarehouseId);
				if (!this.salesOrderModel.inventoryWarehouseId) {
					//check user defualt warehouse
					if (GlobalsService.userInfo.defaultWarehouseId)
						this.salesOrderModel.inventoryWarehouseId = GlobalsService.userInfo.defaultWarehouseId;
					//then check default warehouse for company
					else if (this.inventoryWarehouses.findIndex(x => x.customField) >= 0)
						this.salesOrderModel.inventoryWarehouseId = this.inventoryWarehouses.find(x => x.customField).id;
					//select the first warehouse if no defaults
					else
						this.salesOrderModel.inventoryWarehouseId = this.inventoryWarehouses[0].id;

				}
			}
			if (!this.salesOrderModel.shippingAddress)
				this.salesOrderModel.shippingAddress = new ShippingAddressModel();

			if (this.useJellyFishOrdering) {
				if (!this.salesOrderModel.jellyFishOrderSpecsModel)
					this.salesOrderModel.jellyFishOrderSpecsModel = new JellyFishOrderSpecsModel();
			}
		}
	}

	calculateAppliedTotal(): number {
		let appliedTotal = 0;
		for (const appliedPayment of this.salesOrderModel.appliedPayments) {
			appliedTotal += appliedPayment.appliedAmount || 0;
		}
		return appliedTotal;
	}

	async createShippingRecord() {
		try {

			if (this.salesOrderModel.shippingRecordId) {
				await Swal.fire({
					icon: 'warning',
					title: 'Oops',
					text: 'This invoice already has a shipping record linked',
					confirmButtonColor: '#007bff',
					width: '28em',
					heightAuto: false
				});
				return;
			}

			this.isSubmitted = true;
			await this.saveSalesOrder();
			this.slickScreenBlockerService.block();

			if (this.useJellyFishOrdering && this.salesOrderModel.jellyFishOrderSpecsModel) {
				this.salesOrderModel.jellyFishOrderSpecsModel = await this.jellyFishService.saveOrderSpecs(this.salesOrderModel.jellyFishOrderSpecsModel);
				this.salesOrderModel.jellyFishOrderSpecsId = this.salesOrderModel.jellyFishOrderSpecsModel.jellyFishOrderSpecsId;
			}

			this.salesOrderModel.shippingRecordId = (await this.shippingService.createShippingRecordFromInvoice(this.salesOrderModel.invoiceId)).shippingRecordId;
			this.slickScreenBlockerService.unblock();
			this.slickToastService.showSuccess("Shipping record created")
		}
		catch {
			this.slickScreenBlockerService.unblock();

			this.slickToastService.showDanger("Error creating shipping record")
		}
	}

	async onTabChanged(tabKey: string) {
		this.tabKey = tabKey;

		if (tabKey === 'Payments' && this.salesOrderModel.customer && !this.unappliedPayments) {
			this.unappliedPayments = await this.paymentsService.getUnappliedPayments(this.salesOrderModel.customer.customerId);
		}

		if (tabKey === "Job Photos" && !this.jobPhotos) {
			this.refreshJobPhotos();
		}

		if (this.tabKey === "Inventory" && this.salesOrderModel.useDefaultInventory) {
			this.salesOrderModel.inventoryLineItems = this.invoicesService.syncInventoryFromLineItems(this.salesOrderModel);
		}

		if (this.tabKey === "Job Costing") {
			if (!this.jobCostingService.isValidJobCostingSetupModel(this.jobCostingSetupModel)) {
				this.isJobCostSetupValid = false;
				return;
			}
		}

	}

	async generateJobCostingReport() {
		try {
			this.slickScreenBlockerService.forceBlock();

			this.salesOrderModel.jobCostingDetailModel = await this.jobCostingService.generateJobCostingDetail(this.salesOrderModel.invoiceId, this.salesOrderModel.jobId);
			this.slickScreenBlockerService.forceUnblock();
			this.slickToastService.showSuccess("Report updated");
		}
		catch {
			this.slickToastService.showDanger("Error generating report")
		}

	}

	onSalesRepSelected(salesRep: IDropdownModel) {
		if (!salesRep) {
			this.salesOrderModel.salesRepUserId = null;
			this.salesOrderModel.salesRepFullName = null;

		}
		else {
			this.salesOrderModel.salesRepUserId = salesRep.id;
			this.salesOrderModel.salesRepFullName = salesRep.text;
		}
	}

	swapDownPaymentType() {
		if (this.showDownPaymentDollar) {
			this.showDownPaymentDollar = false;
			this.showDownPaymentPercent = true;
			this.salesOrderModel.downPaymentAmount = 0;
		}
		else {
			this.showDownPaymentDollar = true;
			this.showDownPaymentPercent = false;
			this.salesOrderModel.downPaymentPercent = 0;
		}
	}

	onInventoryWarehouseSelect(inventoryWarehouseDropDown: IDropdownModel) {
		this.salesOrderModel.inventoryWarehouseId = null;
		this.salesOrderModel.inventoryWarehouseName = null;

		if (inventoryWarehouseDropDown) {
			this.salesOrderModel.inventoryWarehouseId = inventoryWarehouseDropDown.id;
			this.salesOrderModel.inventoryWarehouseName = inventoryWarehouseDropDown.text;
		}

	}

	async onCustomerModelChange(customerModel: ICustomerModel) {
		this.salesOrderModel.customer = customerModel;
		if (customerModel) {
			// mapping the jobsite address here since we need it for fuel surcharge
			this.salesOrderModel.jobSiteAddress = this.invoicesService.mapAddressFromCustomer(customerModel);

			this.salesOrderModel.paymentTermId = customerModel.paymentTermId;
			this.salesOrderModel.lineItems = await this.lineItemsService.refreshPricing(this.salesOrderModel.lineItems, customerModel);
			this.salesOrderModel.pricingChanged = false;
			if (this.salesOrderModel.customer.salesRepId) {
				this.salesOrderModel.salesRepUserId = this.salesOrderModel.customer.salesRepId;
				this.salesOrderModel.salesRepFullName = this.lookupService.getUsers(this.salesOrderModel.salesRepUserId).find(x => x.userId === this.salesOrderModel.salesRepUserId).fullName;
			}

		}
	}

	async onShippingAddressChanged(newShippingAddress: IShippingAddressModel) {
		if (newShippingAddress.address1 != this.salesOrderModel.shippingAddress.address1 ||
			newShippingAddress.address2 != this.salesOrderModel.shippingAddress.address2 ||
			newShippingAddress.city != this.salesOrderModel.shippingAddress.city ||
			newShippingAddress.state != this.salesOrderModel.shippingAddress.state ||
			newShippingAddress.zipcode != this.salesOrderModel.shippingAddress.zipcode ||
			newShippingAddress.country != this.salesOrderModel.shippingAddress.country)
			this.salesOrderModel.flagSaveForTaxUpdate = true;

		this.salesOrderModel.shippingAddress = newShippingAddress;
		this.salesOrderModel.cereTaxRate = newShippingAddress.taxRate;
		this.salesOrderModel.lineItems = this.lineItemsService.recalculateByInvoice(this.salesOrderModel);
	}

	//async onColoradoShippingSurchargeChange() {
	//	await SleepService.sleep();

	//	this.salesOrderModel.lineItems = this.lineItemsService.recalculateByInvoice(this.salesOrderModel);
	//}

	async convertToEstimate(): Promise<IInvoiceModel> {
		this.slickScreenBlockerService.forceBlock();

		try {

			this.salesOrderModel.isQuote = true
			this.salesOrderModel.isSalesOrder = false;

			this.salesOrderModel.invoiceDate = new Date();

			await this.saveSalesOrder();

			this.slickToastService.showSuccess("Converted back to estimate");
		}
		finally {
			this.slickScreenBlockerService.forceUnblock();
		}

		return this.salesOrderModel;
	}

	async convertToInvoice(): Promise<IInvoiceModel> {
		this.slickScreenBlockerService.forceBlock();

		try {
			this.salesOrderModel.isQuote = false
			this.salesOrderModel.isSalesOrder = false;

			this.salesOrderModel.invoiceDate = new Date();

			await this.saveSalesOrder();

			this.slickToastService.showSuccess("Converted to invoice");
		}
		finally {
			this.slickScreenBlockerService.forceUnblock();
		}

		return this.salesOrderModel;
	}

	async onFileCheckChanged(fileModel: any) {
		await this.functionLockService.lock("ON_FILE_CHECK_CHANGED");
		try {
			await this.invoicesService.updateJobPhotoCheckChanged(this.salesOrderModel.invoiceId, fileModel.documentId, fileModel.isChecked);
		}
		finally {
			this.functionLockService.release("ON_FILE_CHECK_CHANGED");
		}
	}

	async changeDefaultInventory() {
		await SleepService.sleep();
		this.salesOrderModel.useDefaultInventory = !this.salesOrderModel.useDefaultInventory;
		if (this.salesOrderModel.useDefaultInventory)
			this.salesOrderModel.inventoryLineItems = this.invoicesService.syncInventoryFromLineItems(this.salesOrderModel);
	}

	async generateSalesOrderPdf() {
		const w = window.open('', '_blank');
		w.document.write('Generating pdf...');

		const invoiceUrl = await this.invoicesService.generateInvoicePdf(this.salesOrderModel, false);
		w.location.href = invoiceUrl;
	}

	async generateWorkOrderPdf() {
		const w = window.open('', '_blank');
		w.document.write('Generating pdf...');
		const invoiceUrl = await this.invoicesService.generateWorkOrderPdf(this.salesOrderModel);
		w.location.href = invoiceUrl;
	}

	async emailSalesOrder() {
		try {
			this.isSubmitted = true;
			this.spinnerStatus = "spin";
			if (this.isValid() === false) {
				this.spinnerStatus = "error";
				return;
			}
			await this.saveSalesOrder();
			this.spinnerStatus = "ok"


		} catch {
			this.spinnerStatus = "error"
			return;
		}
		this.slickScreenBlockerService.block();

		try {
			const emailerModel = await this.invoicesService.prepareInvoiceEmail(this.salesOrderModel);
			this.slickScreenBlockerService.unblock();
			const sentEmail = await this.emailerDialogRef.showDialog(emailerModel);

			if (sentEmail) {
				await this.invoicesService.addInvoiceEmailSentNote(this.salesOrderModel.invoiceId, false);
			}
		}
		finally {
			this.slickScreenBlockerService.unblock();
		}
	}

	async textSalesOrder() {
		this.slickScreenBlockerService.block();
		try {
			const textModel = await this.invoicesService.prepareInvoiceText(this.salesOrderModel);
			this.slickScreenBlockerService.unblock();
			const sent = await this.texterDialogRef.showDialog(textModel);
			if (sent)
				this.slickToastService.showSuccess("Sales order sent");
		}
		finally {
			this.slickScreenBlockerService.unblock();
		}
	}

	syncToQuickbooks(isSynced: boolean) {
		this.salesOrderModel.quickbooksId = null;
		this.salesOrderModel.syncToQuickbooks = isSynced;
	}

	async prePayment(): Promise<IInvoiceModel> {
		if (!this.salesOrderModel.customer) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please select a customer",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		if (!this.salesOrderModel.invoiceNumber) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Sales Order # is required",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		this.salesOrderModel = await this.invoicesService.updateInvoice(this.salesOrderModel);

		const creditCardProcessPaymentModel = new CreditCardProcessPaymentModel();
		creditCardProcessPaymentModel.invoiceId = this.salesOrderModel.invoiceId;
		creditCardProcessPaymentModel.amount = this.salesOrderModel.outstandingBalance;
		creditCardProcessPaymentModel.invoiceNumber = this.salesOrderModel.invoiceNumber;
		creditCardProcessPaymentModel.poNumber = this.salesOrderModel.purchaseOrderNumber;
		this.creditCardDialogRef.openDialog(this.salesOrderModel.customer, creditCardProcessPaymentModel);
	}

	onPaymentTypesChanged(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel[]) {
		const defaultCard = creditCardPaymentTypeModel.find(x => x.isDefault === true);

		if (defaultCard)
			this.salesOrderModel.customer.cardOnFile = defaultCard.displayText;
	}

	onApproved(paymentApprovedModel: ICreditCardPaymentApprovedModel) {
		if (paymentApprovedModel.creditCardTransactionLogModel.result === "Approved") {
			if (!this.salesOrderModel.appliedPayments)
				this.salesOrderModel.appliedPayments = [];

			this.salesOrderModel.appliedPayments = [...this.salesOrderModel.appliedPayments, paymentApprovedModel.appliedPayment];

			if (this.salesOrderModel)
				this.salesOrderModelChange.emit(this.salesOrderModel);

			if (this.onSave)
				this.onSave.emit(this.salesOrderModel);
		}
	}

	isValid(): boolean {
		if (!this.salesOrderModel.customer)
			return false;

		if (!this.salesOrderModel.invoiceNumber)
			return false;

		if (this.useInventory) {
			if (!this.salesOrderModel.inventoryWarehouseId) {
				return false;
			}
		}

		if (!this.salesOrderModel.shippingAddress.address1)
			return false;

		return true;
	}

	async onRefreshPricing() {
		this.salesOrderModel = await this.invoicesService.refreshPricing(this.salesOrderModel);
	}

	async addPayment() {
		if (!this.salesOrderModel.customer) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please select a customer",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		if (!this.salesOrderModel.invoiceNumber) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Invoice # is required",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		const addedPayment = await this.addPaymentDialogRef.showDialog(this.salesOrderModel.customer);

		if (addedPayment) {
			const newAppliedPayment = new AppliedPaymentModel();
			newAppliedPayment.uuid = UtilsService.newGuid();
			newAppliedPayment.paymentId = addedPayment.paymentId;
			newAppliedPayment.invoiceId = this.salesOrderModel.invoiceId;
			newAppliedPayment.appliedAmount = 0;
			newAppliedPayment.payment = await this.paymentsService.getPayment(addedPayment.paymentId)

			if (newAppliedPayment.payment.unappliedAmount > this.salesOrderModel.outstandingBalance)
				newAppliedPayment.appliedAmount = this.salesOrderModel.outstandingBalance;
			else
				newAppliedPayment.appliedAmount = newAppliedPayment.payment.unappliedAmount;

			this.salesOrderModel.appliedPayments.push(newAppliedPayment);

			this.recalcAppliedPayment(newAppliedPayment);

			this.salesOrderModel = await this.invoicesService.updateInvoice(this.salesOrderModel);
		}
	}

	async payment() {
		if (!this.salesOrderModel.customer) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please select a customer",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		if (!this.salesOrderModel.invoiceNumber) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Invoice # is required",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		this.salesOrderModel = await this.invoicesService.updateInvoice(this.salesOrderModel);

		const creditCardProcessPaymentModel = new CreditCardProcessPaymentModel();
		creditCardProcessPaymentModel.invoiceId = this.salesOrderModel.invoiceId;
		creditCardProcessPaymentModel.amount = this.salesOrderModel.outstandingBalance;
		creditCardProcessPaymentModel.invoiceNumber = this.salesOrderModel.invoiceNumber;
		creditCardProcessPaymentModel.poNumber = this.salesOrderModel.purchaseOrderNumber;
		this.creditCardDialogRef.openDialog(this.salesOrderModel.customer, creditCardProcessPaymentModel);
	}

	async saveSalesOrder() {
		try {
			this.isSubmitted = true;
			if (GlobalsService.company.useInventory && this.salesOrderModel.useDefaultInventory) {
				this.salesOrderModel.inventoryLineItems = this.invoicesService.syncInventoryFromLineItems(this.salesOrderModel);
			}


			if (this.isValid() === false) {
				this.spinnerStatus = "error";
				return;
			}

			this.spinnerStatus = "spin";

			await SleepService.sleep(500);

			if (this.showDownPaymentDollar)
				this.salesOrderModel.downPaymentPercent = null;
			else if (this.showDownPaymentPercent)
				this.salesOrderModel.downPaymentAmount = null;

			if (this.useJellyFishOrdering && this.salesOrderModel.jellyFishOrderSpecsModel) {
				this.salesOrderModel.jellyFishOrderSpecsModel = await this.jellyFishService.saveOrderSpecs(this.salesOrderModel.jellyFishOrderSpecsModel);
				this.salesOrderModel.jellyFishOrderSpecsId = this.salesOrderModel.jellyFishOrderSpecsModel.jellyFishOrderSpecsId;
			}

			this.salesOrderModel = await this.invoicesService.updateInvoice(this.salesOrderModel);

			if (this.onSave)
				this.onSave.emit(this.salesOrderModel);

			this.spinnerStatus = "ok";
			this.hasInventoryItems = false;
			this.isSubmitted = false;
		}
		catch (err) {
			this.spinnerStatus = "error";
		}
	}

	cancelSalesOrder() {
		if (this.onCancel)
			this.onCancel.emit();
	}


    async createJellyFishOrder() {
        try {
            let itemsToSend = this.salesOrderModel.lineItems.filter(x => x.sendToManufacturer && x.isParentItem)
            if (itemsToSend.length === 0) {
                await Swal.fire({
                    icon: 'warning',
                    title: 'Oops',
                    text: 'Cannot create order because all line items are unchecked',
                    confirmButtonColor: '#007bff',
                    width: '28em',
                    heightAuto: false
                });
                return;
            }

			await this.saveSalesOrder();

			const jellyFishCustomer = await this.jellyFishService.getJellyFishCustomer();
			// we need them to be able to choose the card they want to be charged
			const paymentTypeId = await this.creditCardSelectorDialogRef.openDialog(jellyFishCustomer);

			if (!paymentTypeId) {
				return;
			}

			this.salesOrderModel.selectedCreditCardPaymentTypeId = paymentTypeId;

            this.slickScreenBlockerService.block();
            this.salesOrderModel = await this.jellyFishService.createOrderFromInvoice(this.salesOrderModel);
            this.slickScreenBlockerService.unblock();
            this.slickToastService.showSuccess("Order Created");
        }

        catch {
            this.slickScreenBlockerService.unblock();
            this.slickToastService.showDanger("Failed to create order");
        }
    }

	onLineItemsChanged(lineItems: ILineItemModel[]) {
		this.salesOrderModel.flagSaveForTaxUpdate = true;
		this.salesOrderModel.lineItems = [...lineItems];
	}

	async onChangeAppliedAmount(appliedPayment: IAppliedPaymentModel) {
		await SleepService.sleep();
		this.recalcAppliedPayment(appliedPayment);
	}

	async printAppliedPayment(appliedPayment: IAppliedPaymentModel) {
		const w = window.open('', '_blank');
		w.document.write('Generating pdf...');
		const url = await this.paymentsService.generatePaymentReceiptPDF(appliedPayment.payment.paymentId);
		w.location.href = url;
	}

	async emailAppliedPayment(appliedPayment: IAppliedPaymentModel) {
		this.slickScreenBlockerService.block();

		try {
			const emailerModel = await this.paymentsService.preparePaymentReceiptEmail(appliedPayment.payment);
			this.emailerDialogRef.showDialog(emailerModel);
		}
		finally {
			this.slickScreenBlockerService.unblock();
		}
	}

	async removeAppliedPayment(appliedPayment: IAppliedPaymentModel) {
		this.salesOrderModel.appliedPayments = this.salesOrderModel.appliedPayments.filter(x => x.uuid !== appliedPayment.uuid);
		const paymentListViewModel = await this.paymentsService.getPaymentListView(appliedPayment.paymentId)
		this.unappliedPayments.unshift(paymentListViewModel)
	}

	async addAppliedPayment(unappliedPayment: IPaymentListViewModel) {
		const newAppliedPayment = new AppliedPaymentModel();
		newAppliedPayment.uuid = UtilsService.newGuid();
		newAppliedPayment.paymentId = unappliedPayment.paymentId;
		newAppliedPayment.invoiceId = this.salesOrderModel.invoiceId;
		newAppliedPayment.appliedAmount = 0;
		newAppliedPayment.payment = await this.paymentsService.getPayment(unappliedPayment.paymentId)

		if (newAppliedPayment.payment.unappliedAmount > this.salesOrderModel.outstandingBalance)
			newAppliedPayment.appliedAmount = this.salesOrderModel.outstandingBalance;
		else
			newAppliedPayment.appliedAmount = newAppliedPayment.payment.unappliedAmount;

		this.salesOrderModel.appliedPayments.push(newAppliedPayment);

		this.recalcAppliedPayment(newAppliedPayment);

		this.unappliedPayments = this.unappliedPayments.filter(x => x.paymentId !== newAppliedPayment.payment.paymentId);
	}

	private recalcAppliedPayment(appliedPayment: IAppliedPaymentModel) {
		appliedPayment.appliedAmount = parseFloat(appliedPayment.appliedAmount.toString());
		appliedPayment.appliedAmount = UtilsService.round(appliedPayment.appliedAmount);

		const invoiceAppliedPayment = this.salesOrderModel.appliedPayments.find(x => x.uuid === appliedPayment.uuid);
		invoiceAppliedPayment.appliedAmount = appliedPayment.appliedAmount;
		const totalAppliedAmount = this.salesOrderModel.appliedPayments.reduce((appliedAmt, ap) => (appliedAmt += ap.appliedAmount), 0);
		this.salesOrderModel.outstandingBalance = this.salesOrderModel.invoiceTotal - totalAppliedAmount;

		const additionalAppliedPayments = this.salesOrderModel.appliedPayments.filter(x => x.uuid !== appliedPayment.uuid);

		this.salesOrderModel.appliedPaymentsTotal = additionalAppliedPayments.reduce((appliedAmt, ap) => (appliedAmt += ap.appliedAmount), 0);
	}

	private async refreshJobPhotos() {
		this.jobPhotos = await this.jobsService.getJobPhotos(this.salesOrderModel.jobId);

		if (this.jobPhotos?.length > 0 && this.salesOrderModel.selectedJobPhotoDocumentIds?.length > 0) {
			this.jobPhotos.forEach(p => {
				const isChecked = this.salesOrderModel.selectedJobPhotoDocumentIds.find(x => x === p.documentId);
				if (isChecked)
					p.isChecked = true;
			});
		}

	}
}
