import { Injectable } from '@angular/core';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { DevLogsStore } from '@stores';
import { IResourceModel } from '@models';
import { SQLiteService } from '@sqlite';
import { DevTraceService } from '@services/utils/dev-trace.service';

interface IResourceRecord {
	resourceId: number;
	resourceData: string;
}

@Injectable()
export class ResourceStorageService {
	private db!: SQLiteDBConnection;

	readonly databaseName: string = 'resources';

	constructor(private devLogsStore: DevLogsStore,
		private devTrace: DevTraceService,
		private sqliteService: SQLiteService) {
	}

	async initializeDatabase(): Promise<void> {
		try {
			await this.sqliteService
				.addUpgradeStatement({
					database: this.databaseName,
					upgrade: this.resourcesUpdates
				});

			// create and/or open the database
			const loadToVersion = this.resourcesUpdates[this.resourcesUpdates.length - 1].toVersion;
			this.db = await this.sqliteService.openDatabase(this.databaseName, loadToVersion);
			this.sqliteService.setVersion(this.databaseName, loadToVersion);
			this.devLogsStore.addMessage(`Init ${this.databaseName} database to version ${loadToVersion}`);
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	async deleteDatabase(): Promise<void> {
		await this.sqliteService.deleteDatabase(this.databaseName);
		this.devLogsStore.addMessage(`Deleted ${this.databaseName}`);
	}

	async updateResource(resourceId: number, resource: IResourceModel) {
		try {
			this.devLogsStore.addMessage(`Adding resource record for resourceId ${resourceId}`);

			const resourceEncoded = SQLiteService.encodeJSON(resource);

			await this.db.run(`delete from resources where resourceId=${resourceId};`);

			const sql = `INSERT INTO resources (resourceId, resourceData) VALUES ('${resourceId}', '${resourceEncoded}');`;
			await this.db.run(sql);
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	async getResource(resourceId:number): Promise<IResourceModel> {
		try {
		const resourceRecords: IResourceRecord[] = (await this.db.query(`SELECT * FROM resources where resourceId='${resourceId}';`)).values as IResourceRecord[];

		if ((resourceRecords?.length ?? 0) === 0)
			return null;

		const resourceEncoded = resourceRecords[0].resourceData;
		const resourceJSON = SQLiteService.decodeJSON(resourceEncoded)
		const resource: IResourceModel = JSON.parse(resourceJSON);

		this.devLogsStore.addMessage(`Returning JSON ${resourceJSON}`);

			return resource;
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	readonly resourcesUpdates = [
		{
			toVersion: 1,
			statements: [
				`
CREATE TABLE IF NOT EXISTS resources(
    resourceId number PRIMARY KEY,
    resourceData TEXT NULL
);`
			]
		},
		/* add new statements below for next database version when required*/
		/*
		{
		toVersion: 2,
		statements: [
			`ALTER TABLE users ADD COLUMN email TEXT;`,
		]
		},
		*/
	]
}


