import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { IAppointmentChecklistQuestionModel, AppointmentChecklistQuestionModel, IDropdownModel, DropdownModel} from "@models";
import { UtilsService } from "@services";

@Component({
    selector: "checklist-edit",
    templateUrl: "./checklist-edit.component.html",
    styleUrls: ["./checklist-edit.component.scss"]
})

export class ChecklistEditComponent implements OnChanges {
    @Input() appointmentId: number;

    @Input() checklistQuestions: IAppointmentChecklistQuestionModel[];
    @Output() checklistQuestionsChange = new EventEmitter<IAppointmentChecklistQuestionModel[]>();

    newQuestionsList = new Array<IAppointmentChecklistQuestionModel>();
    checklistStatuses: any = [
        { id: "Complete", text: "Complete" },
        { id: "Not Finished", text: "Not Finished" },
        { id: "Both", text: "Both" }
    ];

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.checklistQuestions) {
            this.newQuestionsList = UtilsService.clone(this.checklistQuestions) || new Array<IAppointmentChecklistQuestionModel>();
        }
    }

    newQuestion() {
        const question = new AppointmentChecklistQuestionModel();
        question.appointmentId = this.appointmentId;
        this.newQuestionsList.push(question);
    }

    deleteNewQuestion(forDelete: IAppointmentChecklistQuestionModel) {
        const i = this.newQuestionsList.indexOf(forDelete);
        this.newQuestionsList = this.newQuestionsList.slice(0, i)
            .concat(this.newQuestionsList.slice(i + 1, this.newQuestionsList.length));
    }

    onCancel() {
        this.checklistQuestionsChange.emit(this.checklistQuestions);
    }

    onSave() {
        this.checklistQuestionsChange.emit(this.newQuestionsList);
    }
}