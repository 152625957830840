import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IBusinessCentralSettingsModel } from "../models";

@Injectable()
export class BusinesCentralService {
	constructor(private httpService: HttpService) { }

	getSettings(): Promise<IBusinessCentralSettingsModel> {
		return this.httpService.get("/businessCentral/getSettings");
	}

	updateSettings(businessCentralSettings: IBusinessCentralSettingsModel): Promise<IBusinessCentralSettingsModel> {
		return this.httpService.post("/businessCentral/updateSettings", businessCentralSettings);
	}
}
