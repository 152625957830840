import { GlobalsService } from '@services/utils/globals.service';

export interface ITimeOffRequestModel {
	timeOffDetailId: number;
	timeClockEntryId: number;
	employeeId: number;
	startDate: Date;
	endDate: Date;
	requestType: string;
	fullOrHalfDay: string;
	morningOrAfternoon: string;
	note: string;
}

export class TimeOffRequestModel implements ITimeOffRequestModel {
	constructor() {
		this.employeeId = GlobalsService.userInfo.userId;
	}

	timeOffDetailId: number;
	timeClockEntryId: number;
	employeeId: number;
	startDate: Date;
	endDate: Date;
	requestType: string;
	fullOrHalfDay: string;
	morningOrAfternoon: string;
	note: string;
}