<slick-dialog #dialogRef header="Invoice Edit" width="1000" top="15">
	<invoice-edit #invoiceEditComponentRef [(invoiceModel)]="invoiceModel" (onSave)="onInvoiceSaved($event)" isDialog="true"></invoice-edit>

	<slick-dialog-footer>
		<div class="d-flex pt-2 ps-3">
			<div>
				<button type="button" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-success me-2" style="width: 140px;" (click)="saveInvoice()"><i class="far fa-check"></i> Save</button>
				<button type="button" class="btn btn-outline-danger" style="width: 140px;" (click)="cancelInvoice()"><i class="far fa-times"></i> Cancel</button>
			</div>

			<div class="flex-fill me-3" style="text-align: right">
				<div class="btn-group me-1" role="group">
					<button type="button" class="btn btn-outline-primary" title="Print Invoice" (click)="generateInvoicePdf()"><i class="far fa-print"></i></button>
					<button type="button" class="btn btn-outline-primary" title="Print Work Order" (click)="generateWorkOrderPdf()">
						<div style="position: absolute; font-size: 6px; border-radius: 6px; right: 6px; top: 18px; line-height: 12px; min-width: 12px; padding: 0;" class="bg-dark text-white">WO</div>
						<i class="far fa-print"></i>
					</button>
					<button type="button" class="btn btn-outline-primary" title="Email Invoice" (click)="emailInvoice()"><i class="far fa-envelope"></i></button>
					<button type="button" class="btn btn-outline-primary" title="Text Invoice" (click)="textInvoice()"><i class="far fa-comment-alt-lines"></i></button>
					<button type="button" class="btn btn-outline-primary" title="Payment" (click)="payment()"><i class="far fa-credit-card"></i></button>
					<button *ngIf="adminUnlock && adminEdit === false" title="Admin Edit" type="button" class="btn btn-outline-primary" (click)="toggleAdminEdit()"><i class="far fa-lock"></i></button>
					<button *ngIf="adminUnlock && adminEdit === true" title="Admin Edit" type="button" class="btn btn-outline-primary" (click)="toggleAdminEdit()"><i class="far fa-unlock"></i></button>
				</div>
				<button *ngIf="canMoveToSalesOrder" type="button" class="btn btn-outline-primary" title="Back to Sales Order" (click)="convertToSalesOrder()"><i class="far fa-arrow-alt-left" style="margin-right: 2px;"></i><i class="far fa-dollar-sign"></i></button>
			</div>

		</div>
	</slick-dialog-footer>

</slick-dialog>
