import { Injectable, ElementRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// @dynamic
@Injectable()
export class SlickLogService {
	constructor(private httpClient: HttpClient) { }

	async writeLog(text: string): Promise<any> {
		const params = {
			text: text,
			logDate: new Date()
		}

		await (this.httpClient.post("https://api.jobsight.com/debuglogs/writelog", params).toPromise()); 
	};
}

