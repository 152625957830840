<div *ngIf="jobModel" style="padding-left: 15px">
	<slick-container-body>

		<slick-tabs [(tabIndex)]="tabIndex" (tabChange)="onTabChanged($event)">
			<slick-tab header="Job" icon="bi bi-clipboard-fill">
				<slick-container maxHeight="calc(100vh - 250px)" [padding]="!isDialog">

					<slick-container-body>
						<div class="d-flex flex-fill flex-column mb-3">
							<div class="bold-header mb-2 mt-1" style="display: flex; align-items: center; padding-top: 5px !important;">
								<div style="flex-grow: 1;">Job Info</div>
								<div class="gray-line"></div>
							</div>
							<div class="d-flex flex-fill">
								<div class="me-1" *ngIf="!jobModel.showOneTimeAddress" style="flex: 1;">
									<label style="padding-bottom: 3px;">
										Job Site Customer&nbsp;
										<button *ngIf="useOneTimeAddress"
												type="button"
												style="font-size: 10px; margin-top: -2px !important;"
												class="btn btn-link p-0 m-0"
												(click)="swapJobSiteCustomer()">
											<i class="far fa-sync"></i>
										</button>
									</label>
									<customer-search [(customerModel)]="jobModel.jobCustomer"
													 [customerMultiAddress]="jobModel.customerMultipleAddress"
													 (customerMultiAddressChange)="onMultiAddressChange($event)"
													 showSearchIcon="false"
													 [showCustomerDisplay]="!jobModel.customerMultipleAddress"
													 [valType]="true"
													 [submitted]="isSubmitted"
													 [showButtons]="!isQPro"
													 [includeMultiAddress]="true"
													 [disableButtons]="!jobsAuthService.canEdit()"
													 [showTaxRegionPopUp]="false"
													 [ngClass]="{'error-search': !jobModel.jobCustomer && isSubmitted && jobModel.showJobSiteCustomer}"></customer-search>
									<form-error *ngIf="!jobModel.jobCustomer && isSubmitted && jobModel.showJobSiteCustomer">The Job Site Customer is required</form-error>
								</div>


                                <div class="mt-1 ms-1 me-3 arrow-button" *ngIf="!jobModel.showOneTimeAddress && !jobModel.customerMultipleAddress">
                                    <label>&nbsp;</label>
                                    <button type="button" class="btn btn-outline-primary"
                                            [disabled]="!jobModel.jobCustomer || !jobsAuthService.canEdit()"
                                            (click)="jobModel.billToCustomer = jobModel.jobCustomer">
                                        <i class="fa fa-arrow-right"></i>
                                    </button>
                                </div>

                                <div class="me-3" *ngIf="jobModel.showOneTimeAddress" style="flex: 1;">
                                    <label style="padding-bottom: 1px;">
                                        Job Site Address&nbsp;
                                        <button *ngIf="useOneTimeAddress"
                                                type="button"
                                                style="font-size: 10px;"
                                                class="btn btn-link p-0 m-0"
                                                (click)="swapJobSiteCustomer()">
                                            <i class="far fa-sync"></i>
                                        </button>
                                    </label>

                                    <div class="d-flex flex-fill align-items-center position-relative">
                                        <input type="text"
                                               class="form-control"
                                               name="addressSearch"
                                               [slick-validation-indicator]="jobModel.oneTimeAddress?.address1"
                                               slickValidationType="error"
                                               slick-google-address-search
                                               (onGoogleAddressSelect)="onGoogleMapAddressSelect($event)" />
                                        <div class="d-flex position-absolute top-50 translate-middle-y end-0">
                                            <div class="slick-search-bar_add-button me-2">
                                                <a href="#" *ngIf="!disableAddButton" (click)="showNewAddressDialog(); false;"><i class="far fa-plus"></i></a>
                                            </div>
                                            <div class="slick-search-bar_edit-button me-2" *ngIf="jobModel?.oneTimeAddress?.address1">
                                                <a href="#" (click)="showEditAddressDialog(); false;"><i class="far fa-pencil"></i></a>
                                            </div>
                                            <div class="slick-search-bar_edit-button me-2">
                                                <div *ngIf="!jobModel?.oneTimeAddress?.address1 && !jobModel?.oneTimeAddress?.address2 && !jobModel?.oneTimeAddress?.city
													&& !jobModel?.oneTimeAddress?.state && !jobModel?.oneTimeAddress?.zipcode && !jobModel?.oneTimeAddress?.country"><i class="far fa-pencil"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                    <form-error *ngIf="!jobModel.oneTimeAddress?.address1 && isSubmitted && jobModel.showOneTimeAddress">The Job Site Address is required</form-error>

                                    <ul class="address-list-item">
                                        <li *ngIf="jobModel?.oneTimeAddress?.address1 || jobModel?.oneTimeAddress?.address2 || jobModel?.oneTimeAddress?.city
										|| jobModel?.oneTimeAddress?.state || jobModel?.oneTimeAddress?.zipcode || jobModel?.oneTimeAddress?.country"></li>
										<li *ngIf="jobModel?.oneTimeAddress?.address1">{{ jobModel.oneTimeAddress.address1 }}</li>
										<li *ngIf="jobModel?.oneTimeAddress?.address2">{{ jobModel.oneTimeAddress.address2 }}</li>
										<li>
											<span *ngIf="jobModel?.oneTimeAddress?.city">{{ jobModel.oneTimeAddress.city }}, </span>
											<span *ngIf="jobModel?.oneTimeAddress?.state">{{ jobModel.oneTimeAddress.state }}, </span>
											<span *ngIf="jobModel?.oneTimeAddress?.zipcode">{{ jobModel.oneTimeAddress.zipcode }}</span>
										</li>
									</ul>
								</div>
								<div *ngIf="!jobModel.showOneTimeAddress" style="flex: 1;">
									<label style="padding-bottom: 3px;">Billing Customer</label>
									<customer-search [(customerModel)]="jobModel.billToCustomer"
													 [isBillingCustomer]="true"
													 [showButtons]="!isQPro"
													 [valType]="true"
													 [includeMultiAddress]="false"
													 [disableButtons]="!jobsAuthService.canEdit()"
													 [showTaxRegionPopUp]="false"
													 [ngClass]="{'error-search': !jobModel.billToCustomer && isSubmitted}"></customer-search>
													 <form-error *ngIf="!jobModel.billToCustomer && isSubmitted">Billing Customer is required</form-error>
								</div>

								<div *ngIf="jobModel.showOneTimeAddress" style="flex: 1;">
									<label style="padding-bottom: 3px;">Billing Customer</label>
									<customer-search [(customerModel)]="jobModel.billToCustomer"
													 [isBillingCustomer]="true"
													 [valType]="requireBillToCustomer"
													 [showButtons]="!isQPro"
													 [valType]="true"
													 [includeMultiAddress]="false"
													 [disableButtons]="!jobsAuthService.canEdit()"
													 [showTaxRegionPopUp]="false"
													 [ngClass]="{'error-search': !jobModel.billToCustomer && isSubmitted}"></customer-search>
									<form-error *ngIf="!jobModel.billToCustomer && isSubmitted">Billing Customer is required</form-error>
								</div>
							</div>

                            <div class="d-flex flex-fill mt-3">
                                <div class="flex-fill me-3">
                                    <label>Job Name</label>
                                    <input [(ngModel)]="jobModel.jobName"
                                           [slick-validation-indicator]="jobModel.jobName"
                                           slickValidationType="error"
                                           class="form-control"
                                           type="text"
                                           [ngClass]="{'error-input': !jobModel.jobName && isSubmitted}" />
                                    <form-error *ngIf="!jobModel.jobName && isSubmitted">The Job Name is required</form-error>
                                </div>
                                <div class="me-3 flex-fill">
                                    <label>Job Status</label>
                                    <slick-drop-down [(ngModel)]="jobModel.jobStatusId"
                                                     [items]="jobStatuses"
                                                     [allowEmpty]="false"
                                                     [validationIndicator]="jobModel.jobStatusId"
                                                     (onSelect)="onJobStatusSelected($event)"
                                                     [ngClass]="{'error-input': !jobModel.jobStatusId && isSubmitted}"></slick-drop-down>
                                    <form-error *ngIf="!jobModel.jobStatusId && isSubmitted">The Job Status is required</form-error>
                                </div>
                                <div class="flex-fill">
                                    <label>Job Type</label>
                                    <slick-drop-down [(ngModel)]="jobModel.jobTypeId"
                                                     [items]="jobTypes"
                                                     [allowEmpty]="false"
                                                     [validationIndicator]="jobModel.jobTypeId"
                                                     (onSelect)="onJobTypeSelected($event)"
                                                     [ngClass]="{'error-dropdown': !jobModel.jobTypeId && isSubmitted}"></slick-drop-down>
                                    <form-error *ngIf="!jobModel.jobTypeId && isSubmitted">The Job Type is required</form-error>
                                </div>
                            </div>
                            <div class="d-flex flex-fill mt-3">
                                <div class="w-100 me-3">
                                    <label>Job Description</label>
                                    <textarea [(ngModel)]="jobModel.description"
                                              class="form-control"
                                              rows="3"
                                              style="line-height: 1rem;"
                                              type="text"></textarea>
                                </div>

                                <div class="w-100">
                                    <label>Office Notes</label>
                                    <textarea [(ngModel)]="jobModel.jobNotes"
                                              class="form-control"
                                              rows="3"
                                              style="line-height: 1rem;"
                                              type="text"></textarea>
                                </div>

                            </div>
                            <div class="d-flex mt-3 justify-content-end">
                                <div *ngIf="!isQPro" class="me-1 text">
                                    <label>&nbsp;</label>
                                    <div *ngIf="!this.jobModel.invoice || this.jobModel.invoice.invoiceId === 0">
                                        <button *ngIf='isEstimate' class="btn btn-outline-primary btn-sm" (click)="openEstimate()" [disabled]="!jobsAuthService.canEdit()"><i class="far fa-pencil"></i> Create Estimate</button>
                                        <button *ngIf='isSalesOrder' class="btn btn-outline-primary btn-sm" (click)="openSalesOrder()" [disabled]="!jobsAuthService.canEdit()"><i class="far fa-pencil"></i> Create Sales Order</button>
                                        <button *ngIf='isInvoice' class="btn btn-outline-primary btn-sm" (click)="openInvoice()" [disabled]="!jobsAuthService.canEdit()"><i class="far fa-pencil"></i> Create Invoice</button>
                                    </div>
                                    <div *ngIf="this.jobModel.invoice && this.jobModel.invoice.invoiceId !== 0">
                                        <button *ngIf='isEstimate' class="btn btn-outline-primary btn-sm" (click)="openEstimate()" [disabled]="!jobsAuthService.canEdit()"><i class="far fa-pencil"></i> Edit Estimate</button>
                                        <button *ngIf='isSalesOrder' class="btn btn-outline-primary btn-sm" (click)="openSalesOrder()" [disabled]="!jobsAuthService.canEdit()"><i class="far fa-pencil"></i> Edit Sales Order</button>
                                        <button *ngIf='isInvoice' class="btn btn-outline-primary btn-sm" (click)="openInvoice()" [disabled]="!jobsAuthService.canEdit()"><i class="far fa-pencil"></i> Edit Invoice</button>
                                    </div>
                                </div>

                                <div *ngIf="!isQPro" class="me-2">
                                    <label>&nbsp;</label>
                                    <button *ngIf='isEstimate' class="btn btn-outline-primary btn-sm" [disabled]="jobModel.jobId === 0" (click)="linkInvoice()" title="Link existing Sales Order/Invoice"><i class="far fa-link"></i> Link Existing Estimate</button>
                                    <button *ngIf='isSalesOrder' class="btn btn-outline-primary btn-sm" [disabled]="jobModel.jobId === 0" (click)="linkInvoice()" title="Link existing Sales Order/Invoice"><i class="far fa-link"></i> Link Existing Sales Order</button>
                                    <button *ngIf='isInvoice' class="btn btn-outline-primary btn-sm" [disabled]="jobModel.jobId === 0" (click)="linkInvoice()" title="Link existing Sales Order/Invoice"><i class="far fa-link"></i> Link Existing Invoice</button>
                                </div>
                            </div>
                        </div>





                        <div class="bold-header mb-2 mt-1 mb-2" style="display: flex; align-items: center; padding-top: 5px !important;">
                            <div style="flex-grow: 1;">Appointment Info</div>
                            <div class="gray-line"></div>
                        </div>
                        <!-- <h5 class="border-bottom mt-2"> Appointments</h5> -->
                        <!-- Appointments -->
                        <div class="d-flex flex-column">
                            <div class="d-flex ms-2">
                                <button class="btn btn-outline-primary btn-sm me-1" (click)="openAutoScheduler()" [disabled]="!jobsAuthService.canEdit()"><i class="fad fa-calendar"></i> Auto-Schedule</button>
                                <button class="btn btn-outline-primary btn-sm me-1" (click)="createNewAppointment()" [disabled]="!jobsAuthService.canEdit()"> <i class="far fa-plus"></i> Appointment</button>
                                <!--<div class="ms-2" *ngIf="isMizu">
                                    Last Appointment: {{jobModel.lastAppointmentDate | date:'M/d/yyyy'}}
                                </div>-->
                            </div>
                            <!-- If there is a default appt, show it first and expanded-->
                            <div *ngFor="let appointment of jobModel.appointments;let i=index">
                                <div class="ms-2 me-2 mt-2" *ngIf="!appointment.isDeleted">
                                    <div class="d-flex">

										<div class="flex-fill card p-2" *ngIf="!appointment.uiState || appointment.uiState === 'collapsed'">
											<div class="d-flex flex-column">
												<div class="d-flex justify-content-between align-items-start">
													<div>
														<span class="p-0 m-0 border-0" style="font-size: 20px;">{{ appointment.appointmentTypeDisplayName }} | {{ appointment.appointmentStatusDisplayName }}</span>
														<!-- <span class="p-0 ms-1 border-0"> | {{ appointment.appointmentStatusDisplayName }}</span> -->
														<div>
															<h6 class="p-0 m-0 border-0" style="font-weight: 400;">{{ appointment.resourceDisplayName }}</h6>
															<h6 class="p-0 m-0 border-0" style="font-weight: 400;">{{ appointment.scheduledDateTime | date:'M/d/yyyy h:mm a' }}</h6>
														</div>
													</div>
													<button class="btn btn-outline-primary btn-sm state-btn" (click)="expand(i)">
														<i *ngIf="!appointment.uiState || appointment.uiState === 'collapsed'" class="far fa-plus"></i>
													</button>
												</div>
												<div class="d-flex mt-3">
													<div class="flex-fill me-2" style="min-width: 33%; max-width: 33%; border-right: 2px solid var(--bs-gray-300);">
														<label class="job-label" style="font-size: .7rem;">Description</label>
														<div class="ps-2 pe-1">
															{{ appointment.description }}
														</div>
													</div>
													<div class="flex-fill me-2" style="min-width: 33%; max-width: 33%; border-right: 2px solid var(--bs-gray-300);">
														<label class="job-label" style="font-size: .7rem;">Customer Notes</label>
														<div class="ps-2 pe-1">
															{{ appointment.customerNotes }}
														</div>
													</div>
													<div class="flex-fill">
														<label class="job-label" style="font-size: .7rem;">Tech Notes</label>
														<div class="ps-2">
															{{ appointment.notes }}
														</div>
													</div>
												</div>
											</div>
										</div>

                                        <div class="d-flex flex-fill card p-2" *ngIf="appointment.uiState === 'expanded'">
                                            <appointment-edit [appointment]="appointment"
                                                              [isSalesOrder]="isSalesOrder"
                                                              [isInvoice]="isInvoice"
                                                              [submitted]="isSubmitted"
                                                              [showDeleteButton]="jobModel.appointments.length > 1"
                                                              (onAppointmentDelete)="onAppointmentDelete($event)"
                                                              (onAppointmentPrint)="onAppointmentPrint($event)"
                                                              (onMultiDayAppointmentsAdded)="onMultiDayAppointmentsAdded($event)"
                                                              (onExpandOrCollapse)="onExpandOrCollapse($event,i)"></appointment-edit>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form-error *ngIf="(jobModel.appointments === null || jobModel.appointments === undefined || jobModel.appointments.length <= 0) && isSubmitted"> At least one appointment is required </form-error>
                        </div>
                    </slick-container-body>
                </slick-container>
            </slick-tab>

			<slick-tab header="Photos" icon="bi bi-camera-fill far" *ngIf="jobModel.jobId > 0">
				<slick-container maxHeight="calc(100vh - 250px)">
					<slick-container-body>
						<documents-edit *ngIf="tabKey === 'Photos'"
										title="Job Photos"
										[documentKey]="jobModel.documentKey"
										documentType="Jobs"
										folderType="photos"
										[canDelete]="jobsAuthService.canEdit()"
										[hideFileDrop]="!jobsAuthService.canEdit()"></documents-edit>

                        <documents-edit *ngIf="tabKey === 'Photos'"
                                        title="Customer Photos"
                                        [documentKey]="customerDocumentKey"
                                        [excludeDocumentKey]="jobModel.documentKey"
                                        documentType="Jobs"
                                        folderType="photos"
                                        getUrl="/documents/getAllJobPhotosForCustomer"
                                        [canDelete]="false"
                                        [hideFileDrop]="true"></documents-edit>
                    </slick-container-body>
                </slick-container>
            </slick-tab>

			<slick-tab header="Documents" icon="bi bi-file-earmark-text-fill" *ngIf="jobModel.jobId > 0">
				<slick-container maxHeight="calc(100vh - 250px)">
					<slick-container-body>
						<documents-edit *ngIf="tabKey === 'Documents'"
										[documentKey]="jobModel.documentKey"
										documentType="Jobs"
										title="Job Documents"
										folderType="documents"
										[canDelete]="jobsAuthService.canEdit()"
										[hideFileDrop]="!jobsAuthService.canEdit()"></documents-edit>
					</slick-container-body>
				</slick-container>
			</slick-tab>
			<slick-tab	header="Text Chat" icon="bi bi-chat-left-text-fill" *ngIf="useTextChat && jobModel.jobId > 0 && jobModel.jobCustomer">
				<div style="min-height: calc(100vh - 257px); overflow: hidden">
					<div *ngIf="!jobModel.jobCustomer.cellPhone && !jobModel.jobCustomer.homePhone" class="m-1">
						No phone number entered for this customer
					</div>
					<text-chat-messages [isDialog]="true"></text-chat-messages>
				</div>
			</slick-tab>

            <slick-tab *ngIf="jobModel.jobId !== 0 && canAccessJobCosting" header="Job Costing" icon="bi-graph-up-arrow">
                <slick-container maxHeight="calc(100vh - 250px)">
                    <slick-container-body *ngIf="tabKey === 'Job Costing'">
                        <div *ngIf="isJobCostSetupValid">
                            <div *ngIf="!jobModel.jobCostingDetailModel">
                                <button class="btn btn-outline-primary" (click)="generateJobCostingReport()">Generate Job Costing Report</button>
                            </div>
                            <div *ngIf="jobModel.jobCostingDetailModel">
                                <button class="btn btn-outline-primary" (click)="generateJobCostingReport()">Regenerate Job Costing Report</button>
                            </div>
                        </div>
                        <job-costing-details [isValid]="isJobCostSetupValid" [jobCostingDetailModel]="jobModel.jobCostingDetailModel"></job-costing-details>
                    </slick-container-body>
                </slick-container>
            </slick-tab>

			<slick-tab header="Appt. History" icon="bi bi-clock-history" *ngIf="jobModel.jobId > 0 && !jobModel.showOneTimeAddress && showHistory">
				<slick-container maxHeight="calc(100vh - 250px)">
					<slick-container-body>
						<div *ngIf="customerJobHistory">
							<div class="mb-2 mt-2" style="width:300px">
								<slick-search-bar [ngModel]="jobHistorySearchString" (onSearch)="onSearch($event)" [showAddButton]="false" [showEditButton]="false"></slick-search-bar>
							</div>
							<div *ngIf="customerJobHistory.length === 0">
								No History to display
							</div>

							<div *ngIf="customerJobHistory.length > 0">
								<div class="card card-body mb-2 p-2 pe-3" *ngFor="let jobHistory of customerJobHistory">
									<div class="resource-label" style="font-size: 20px;">
										<span class="border-0" style="font-weight: bold;">{{jobHistory.appointmentTypeDescription}}</span>
										<span style="margin-right: 10px; margin-left: 10px;">|</span>
										<span (click)="openJob(jobHistory.jobId)" 
											[class.disabled]="!jobsAuthService.canEdit()" 
											style="text-decoration: underline; cursor: pointer; color: blue;">
											{{jobHistory.jobName}}
										</span>	
										<span style="margin-right: 13px; margin-left: 13px;">|</span>
										<span>{{jobHistory.jobSiteDisplayName}} </span>
										<span *ngIf="jobHistory.invoiceId" style="flex-grow: 1; text-align: right; font-size: 15px; font-weight: bold; margin-top: 7px;">Invoice Information</span>
										<span *ngIf="!jobHistory.invoiceId" style="flex-grow: 1; text-align: right; font-size: 15px; font-weight: bold; margin-top:7px">No Invoice</span>
									</div>
									<div class="resource-label">
										<div style="display: flex; align-items: left;">
											<span style="font-weight: bold;">{{ jobHistory.resourceName }}</span>
											<span *ngIf="jobHistory.crewDisplay" style="margin-right: 13px; margin-left: 13px;">|</span>
											<span *ngIf="jobHistory.crewDisplay">{{jobHistory.crewDisplay}} </span>
										</div>
										<span *ngIf="jobHistory.invoiceId" style="flex-grow: 1; text-align: right; font-size: 12px;">{{jobHistory.billingDisplayName}}</span>
									</div>
									<div class="resource-label">
										<div style="display: flex; align-items: left;">
											<span>{{ jobHistory.scheduledDateTime | date:'M/d/yy - h:mm a' }}</span>
											<span *ngIf="jobHistory.finishedTotalDisplay != '0:00'" style="margin-right: 13px; margin-left: 13px;">|</span>
											<span *ngIf="jobHistory.finishedTotalDisplay != '0:00'"> Total time: {{jobHistory.finishedTotalDisplay}}</span>
										</div>
										<span *ngIf="jobHistory.invoiceId" style="flex-grow: 1; text-align: right;">
											Invoice: 
											<span (click)="openInvoicefromAppt(jobHistory.invoiceId)" 
												  [class.disabled]="!jobsAuthService.canEdit()" 
												  style="text-decoration: underline; cursor: pointer; color: blue;">
												{{jobHistory.invoiceNumber}}
											</span>
										</span>									
									</div>
									<div class="resource-label">
										<div *ngIf="jobHistory.invoiceId" style="flex-grow: 1; text-align: right;">Total Cost ${{ jobHistory.invoiceTotal }}</div>
									</div>

									<div class="d-flex mt-4">
										<div class="flex-fill me-2" style="min-width: 33%; max-width: 33%; border-right: 2px solid var(--bs-gray-300);">
											<label class="job-label" style="font-size: .7rem;">Description</label>
											<div class="ps-2 pe-1">
												{{ jobHistory.appointmentDescription }}
											</div>
										</div>
										<div class="flex-fill me-2" style="min-width: 33%; max-width: 33%; border-right: 2px solid var(--bs-gray-300);">
											<label class="job-label" style="font-size: .7rem;">Customer Notes</label>
											<div class="ps-2 pe-1">
												{{ jobHistory.appointmentCustomerNotes}}
											</div>
										</div>
										<div class="flex-fill">
											<label class="job-label" style="font-size: .7rem;">Tech Notes</label>
											<div class="ps-2">
												{{ jobHistory.appointmentNotes }}
											</div>
										</div>
									</div>

                                </div>
                            </div>
                        </div>
                    </slick-container-body>
                </slick-container>
            </slick-tab>

			<slick-tab header="System Logs" icon="bi bi-info-circle-fill" *ngIf="jobModel.jobId > 0">
				<slick-container maxHeight="calc(100vh - 250px)">
					<slick-container-body>
						<system-logs *ngIf="tabKey === 'System Logs'" noteType="1" [referenceId]="jobModel.jobId"></system-logs>
					</slick-container-body>
				</slick-container>
			</slick-tab>

        </slick-tabs>
	</slick-container-body>

	<slick-container-footer *ngIf="!isDialog">
		<div class="d-flex">
			<div class="flex-fill" *ngIf="jobsAuthService.canEdit()">
				<div class="mt-2 pt-2 mb-3 me-3" style="min-width: 100%">
					<button class="save-button btn btn-outline-success me-3" style="width: 140px" (click)="saveJob()" [slick-button-spinner]="spinnerStatus"><i class="bi bi-check-circle"></i> Save</button>
					<button class="cancel-button btn btn-outline-danger" style="width: 140px" (click)="cancelJob()"><i class="bi bi-x-circle"></i> Cancel</button>
				</div>
			</div>
			<div class="flex-fill" *ngIf="!jobsAuthService.canEdit()">
				<permission-denied [message]="'*You do not have permission to edit Jobs.'"></permission-denied>
			</div>
		</div>
	</slick-container-footer>
</div>

<auto-scheduler-dialog #autoSchedulerDialogRef></auto-scheduler-dialog>
<estimate-edit-dialog #estimateEditDialogRef></estimate-edit-dialog>
<sales-order-edit-dialog #salesOrderEditDialogRef></sales-order-edit-dialog>
<invoice-edit-dialog #invoiceEditDialogRef></invoice-edit-dialog>
<conflicting-appointments-dialog #conflictingApptDialogRef></conflicting-appointments-dialog>

<slick-confirm-dialog #createNewEstimateRef>
	No estimate exists for this job.  Do you want to create a new one?
</slick-confirm-dialog>

<slick-confirm-dialog #createNewSalesOrderRef>
	No sales order exists for this job.  Do you want to create a new one?
</slick-confirm-dialog>

<slick-confirm-dialog #createNewInvoiceRef>
	No invoice exists for this job.  Do you want to create a new one?
</slick-confirm-dialog>

<slick-confirm-dialog #overwriteInvoiceRef>
	<h4 class="p-0 m-0 text-danger" style="text-align: center">
		This job is already linked with {{this.jobModel?.invoice?.invoiceNumber}}.&nbsp;
		<span *ngIf='isEstimate'>Linking a new estimate will overwrite this.</span>
		<span *ngIf='isSalesOrder'>Linking a new sales order will overwrite this.</span>
		<span *ngIf='isInvoice'>Linking a new invoice will overwrite this.</span>

		Are you sure you want to re-link?
	</h4>
</slick-confirm-dialog>

<slick-confirm-dialog #invoiceIsOnExistingJobRef>
	<h4 class="p-0 m-0 text-danger" style="text-align: center">
		This invoice is already linked with a job.
		Are you sure you want to remove it from the existing job?
	</h4>
</slick-confirm-dialog>

<slick-dialog #deleteInvoiceDialogRef width="300" (onClose)="doNotDeleteInvoice()">
	<h4 class="p-0 m-0" style="text-align:center">
		Would you like to delete the invoice linked to this job as well?
	</h4>
	<slick-container-footer>
		<div class="d-flex justify-content-center border-top mt-4 pt-2">
			<div>
				<button class="save-button btn btn-outline-primary me-3" style="width: 100px" (click)="deleteLinkedInvoice()"><i class="far fa-check"></i> Yes</button>
				<button class="cancel-button btn btn-outline-danger" style="width: 100px" (click)="doNotDeleteInvoice()"><i class="bi bi-x-circle"></i> No</button>
			</div>
		</div>
	</slick-container-footer>
</slick-dialog>

<slick-dialog #linkInvoiceDialogRef [resizable]="false" [draggable]="false" [minimizable]="false" header="Select Invoice" width="560">

	<div class="input-group mt-3" style="width: 530px">
		<span class="input-group-text"><i class="far fa-search"></i></span>
		<input (keyup)="refreshLinkInvoices()" [(ngModel)]="linkInvoiceSearchString" class="form-control" placeholder="Invoice/PO Number..." />
	</div>
	<div class="my-1">
		<input type="checkbox" [(ngModel)]="showClosedLinkInvoices" (change)="refreshLinkInvoices()" /> Show Closed Invoices
	</div>

	<div style="min-height: calc(100vh - 380px); max-height: calc(100vh - 380px); overflow-y: auto;">
		<div *ngIf="visibleLinkInvoices && visibleLinkInvoices.length === 0" class="mt-3">No sales orders or invoices available to link</div>
		<div *ngIf="visibleLinkInvoices && visibleLinkInvoices.length > 0" class="d-flex flex-column">
			<div *ngFor="let linkInvoice of visibleLinkInvoices" class="border-bottom px-1 mt-1 pb-1 me-2 d-flex" style="line-height: 1.1rem;">
				<div class="d-flex flex-fill flex-column">
					<h5 class="m-0 p-0 border-0">
						<span *ngIf='linkInvoice.isSalesOrder'>Sales Order #: </span>
						<span *ngIf='!linkInvoice.isSalesOrder'>Invoice #: </span>
						{{linkInvoice.invoiceNumber}}
					</h5>
					<div>PO #:{{linkInvoice.purchaseOrderNumber}}</div>
					<div>
						<span *ngIf='linkInvoice.isSalesOrder'>Sales Order Date: </span>
						<span *ngIf='!linkInvoice.isSalesOrder'>Invoice Date: </span>
						{{linkInvoice.invoiceDate | date:'M/d/yyyy'}}
					</div>
					<div>
						<span *ngIf='linkInvoice.isSalesOrder'>Sales Order Total: </span>
						<span *ngIf='!linkInvoice.isSalesOrder'>Invoice Total: </span>
						{{linkInvoice.invoiceTotal | currency}}
					</div>
				</div>
				<div>
					<button class="btn btn-sm btn-primary" (click)="selectInvoiceToLink(linkInvoice)"><i class="far fa-link"></i> Select</button>
				</div>
			</div>
		</div>
	</div>
</slick-dialog>

<slick-dialog #oneTimeAddressDialogRef [resizable]="true" [draggable]="true" [minimizable]="true" header="Job Site Address" width="450">
	<slick-container-body>
		<div class="d-flex flex-column" *ngIf="addressModel">
			<div>
				<div class="flex-fill mt-2">
					<label>Address 1</label>
					<input class="form-control"
						   [slick-validation-indicator]="addressModel.address1"
						   slickValidationType="error"
						   [(ngModel)]="addressModel.address1" />
				</div>
				<form-error *ngIf="!addressModel.address1 && isSubmitted">The Job Site Address is required</form-error>
			</div>

			<div>
				<div class="flex-fill mt-2">
					<label>Address 2</label>
					<input class="form-control"
						   [(ngModel)]="addressModel.address2" />
				</div>
			</div>

			<div>
				<div class="flex-fill mt-2">
					<label>City</label>
					<input class="form-control"
						   [(ngModel)]="addressModel.city" />
				</div>
			</div>
			<div class="d-flex">
				<div class="flex-fill me-2 mt-2">
					<div *ngIf="addressModel.country !== 'Canada'">
						<label>State</label>
						<input class="form-control"
							   [(ngModel)]="addressModel.state" />
					</div>

					<div *ngIf="addressModel.country === 'Canada'">
						<label>Province</label>
						<input class="form-control"
							   [(ngModel)]="addressModel.state" />
					</div>
				</div>

				<div class="flex-fill me-2 mt-2">
					<label>Zipcode</label>
					<input class="form-control"
						   [(ngModel)]="addressModel.zipcode" />
				</div>

				<div class="flex-fill mt-2">
					<label>Country</label>
					<slick-drop-down [(ngModel)]="addressModel.country"
									 [items]="countries"></slick-drop-down>
				</div>
			</div>
		</div>
	</slick-container-body>

	<slick-container-footer>
		<div class="d-flex justify-content-center border-top mt-4 pt-2">
			<div>
				<button class="save-button btn btn-outline-success me-3" style="width: 140px" (click)="saveOneTimeAddress()"><i class="bi bi-check-circle"></i> Save</button>
				<button class="cancel-button btn btn-outline-danger" style="width: 140px" (click)="cancelOneTimeAddress()"><i class="bi bi-x-circle"></i> Cancel</button>
			</div>
		</div>
	</slick-container-footer>

</slick-dialog>
<slick-dialog top="50" [header]="jobModelForDialog?.jobName" width="920" #jobEditDialogRef (onClose)="cancelDialogJob()">
    <job-edit *ngIf="jobModelForDialog" #jobEditComponentRef  [jobModel]="jobModelForDialog" [defaultApptId]="defaultApptId" isDialog="true" [showHistory]="false"></job-edit>
	<slick-dialog-footer>
		<button class="save-button btn btn-outline-success me-3" style="width: 160px" (click)="saveDialogJob()" [slick-button-spinner]="spinnerDialogStatus">
			<i class="bi bi-check-circle"></i> Save
		</button>
		<button class="cancel-button btn btn-outline-danger" style="width: 160px" (click)="cancelDialogJob()">
			<i class="bi bi-x-circle"></i> Cancel
		</button>

	</slick-dialog-footer>
</slick-dialog>