import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { GlobalsService, UtilsService } from "@services";
import { DevLogsStore } from '@stores';

@Injectable()
export class HttpService {
	constructor(private http: HttpClient,
		private devLogsStore: DevLogsStore) {
	}

	async get(url: string, params?: any): Promise<any> {
		if (!GlobalsService.isOnline)
			this.devLogsStore.addMessage(`Offline trying to get ${url}`);

		let responseObj = await this.http.get(url, { params: params }).toPromise();

		responseObj = UtilsService.dateSanitize(responseObj);

		return Promise.resolve(responseObj);
	}

	getObservable(url: string, params?: any): Observable<any> {
		return this.http.get(url, { params: params });
	}

	async getPdfByName(docPath: string, title: string): Promise<Blob> {
		// The Angular Http Interceptor screws this up, so use XHR
		return new Promise<Blob>((res) => {
			var xhr = new XMLHttpRequest();
			const url = `${GlobalsService.apiUrl}/documents/getPDFByName?docPath=${docPath}&title=${title}`;
			xhr.open('get', url);
			xhr.setRequestHeader("Authorization", `Bearer ${GlobalsService.jwtToken}`);
			xhr.responseType = 'blob';

			xhr.onload = () => {
				res(xhr.response);
			};

			xhr.send();
		});
	}

	async delete(url: string, params?: any): Promise<any> {
		let responseObj = await this.http.delete(url, { params: params }).toPromise();

		responseObj = UtilsService.dateSanitize(responseObj);

		return Promise.resolve(responseObj);
	}

	async post(url: string, body: any, trimStrings: boolean = false): Promise<any> {
		if (!GlobalsService.isOnline)
			this.devLogsStore.addMessage(`Offline trying to post ${url}`);

		// Trim all strings
		if (trimStrings) {
			let jsonString = JSON.stringify(body);
			let infiniteLoopDetector = 100;
			while ((jsonString.indexOf("\" ") >= 0) && --infiniteLoopDetector > 0)
				jsonString = jsonString.replace("\" ", "\"");

			infiniteLoopDetector = 100;
			while ((jsonString.indexOf(" \"") >= 0) && --infiniteLoopDetector > 0)
				jsonString = jsonString.replace(" \"", "\"");

			body = JSON.parse(jsonString);
		}
		let responseObj = await this.http.post(url, body, { withCredentials: true}).toPromise();

		responseObj = UtilsService.dateSanitize(responseObj);

		return Promise.resolve(responseObj);
	}

	async postMultipart(url: string, formData: FormData): Promise<any> {
		let responseObj = await this.http.post(url, formData,
			{
				headers: { 'Content-Type': "multipart/form-data" }
			}).toPromise();

		responseObj = UtilsService.dateSanitize(responseObj);

		return Promise.resolve(responseObj);
	}

	async put(url: string, body: any = null): Promise<any> {
		if (!GlobalsService.isOnline)
			this.devLogsStore.addMessage(`Offline trying to put ${url}`);

		let responseObj = await this.http.put(url, body).toPromise();

		responseObj = UtilsService.dateSanitize(responseObj);

		return Promise.resolve(responseObj);
	}

	async patch(url: string, body: any = null): Promise<any> {
		if (!GlobalsService.isOnline)
			this.devLogsStore.addMessage(`Offline trying to patch ${url}`);

		let responseObj = await this.http.patch(url, body).toPromise();

		responseObj = UtilsService.dateSanitize(responseObj);

		return Promise.resolve(responseObj);
	}
}
