import { Component } from '@angular/core';
import { AppointmentsService, FunctionLockService, JobsService, LookupService } from '@services';
import { AutoScheduleEditBase, ResourceAvailability, TimeSlot } from '@base-components/auto-scheduler.base';
import { IAvailableTimeSlotModel } from '@models';
import * as moment from 'moment';


@Component({
    selector: 'auto-scheduler',
    templateUrl: './auto-scheduler.component.html',
    styleUrls: ['./auto-scheduler.component.scss'],
    providers: [LookupService, AppointmentsService, JobsService]
})

export class AutoSchedulerComponent extends AutoScheduleEditBase {


    constructor(lookupService: LookupService,
        appointmentsService: AppointmentsService,
        jobsService: JobsService,
        functionLockService: FunctionLockService) {
        super(2, lookupService, appointmentsService, jobsService, functionLockService);
    }

    getTimeDisplay(timeSlotDisplay: string): string {
  if (!timeSlotDisplay) {
    return '';
  }
    const splitTimeString = timeSlotDisplay.replace(/(\d{1,2}:\d{2})([ap]m)/i, "$1 $2");
  return `${splitTimeString}`;
}
}
