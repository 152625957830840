<div *ngIf="customerModel" class="ps-2 mt-2 pe-4">
    <div *ngIf="!skeditPay" class="bold-header" style="display: flex; align-items: center; padding-top: 5px !important;">
        <div style="flex-grow: 1;">Customer Info</div>
        <div class="gray-line"></div>
    </div>
    <div class="row">
        <div class="col">
            <label>First Name</label>
            <input class="form-control"
                   [(ngModel)]="customerModel.firstName"
                   [slick-validation-indicator]="customerModel.firstName || customerModel.lastName || customerModel.companyName"
				    slickValidationType="warning"
                    [ngClass]="{'error-input': !customerModel.firstName && !customerModel.lastName && !customerModel.companyName && isSubmitted}"/>
        </div>

        <div class="col">
            <label>Last Name</label>
            <input class="form-control"
                   [(ngModel)]="customerModel.lastName"
                   [slick-validation-indicator]="customerModel.firstName || customerModel.lastName || customerModel.companyName"
				    slickValidationType="warning"
                    [ngClass]="{'error-input': !customerModel.firstName && !customerModel.lastName && !customerModel.companyName && isSubmitted}" />
        </div>
        <div class="col">
            <label>Company Name</label>
            <input class="form-control"
                   [(ngModel)]="customerModel.companyName"
                   [slick-validation-indicator]="customerModel.firstName || customerModel.lastName || customerModel.companyName"
				    slickValidationType="warning"
                    [ngClass]="{'error-input': !customerModel.firstName && !customerModel.lastName && !customerModel.companyName && isSubmitted}" />
            <form-error *ngIf="!customerModel.firstName && !customerModel.lastName && !customerModel.companyName && isSubmitted">
                First Name, Last Name or Company Name is required
            </form-error>
        </div>
        <div *ngIf="skeditPay" class="ms-1" style="max-width: 58px; float:right">
            <div style="min-height: 20px;"></div>
            <quickbooks-icon [syncToQuickbooks]="customerModel.syncToQuickbooks"
                             [quickbooksId]="customerModel.quickbooksId"
                             [linkedCustomer]="customerModel.quickbooksLinkOnly"
                             (onClick)="quickbooksSync()"></quickbooks-icon>
        </div>
        <div *ngIf="isQuickbooksEnabled" class="ms-1" style="max-width: 58px; margin-top: .7rem;">
            <div style="min-height: 20px;"></div>
            <quickbooks-icon [syncToQuickbooks]="customerModel.syncToQuickbooks"
                             [quickbooksId]="customerModel.quickbooksId"
                             [linkedCustomer]="customerModel.quickbooksLinkOnly"
                             (onClick)="quickbooksSync()"></quickbooks-icon>
        </div>
        <div *ngIf="isBusinessCentralEnabled" class="col" style="max-width: 58px; margin-top: .7rem; margin-left: 5px;">
            <div style="min-height: 20px;"></div>
            <business-central-icon [syncToBusinessCentral]="customerModel.syncToBusinessCentral"
                                   [businessCentralId]="customerModel.businessCentralId"
                                   (onClick)="businessCentralSync()"></business-central-icon>
        </div>

    </div>
    <div *ngIf="showQuickbooksName && customerModel.syncToQuickbooks" class="customer-info form-group">
        <div *ngIf="editQuickbooksName">
            <label>Quickbooks Name</label>
            <input class="form-control" [(ngModel)]="customerModel.quickbooksName" />
        </div>

        <div *ngIf="!editQuickbooksName" (click)="editQuickbooksName = true" style="font-size: .8rem; position: relative; top: -3px;">
            Quickbooks Name: {{customerModel.quickbooksName}}<span *ngIf='customerModel.quickbooksLinkOnly'>&nbsp;(Link Only)</span>&nbsp;&nbsp;<i class="bi bi-pencil-fill" style="cursor: pointer"></i>
        </div>
    </div>

    <div *ngIf="isQBDesktop">
        <div *ngIf="showQuickbooksName && customerModel.syncToQuickbooks" class="form-group" style="font-size: .8rem; position: relative; top: -5px;">
            <div *ngIf="editQuickbooksName" class="checkbox-item">
                <input type="checkbox" [(ngModel)]="customerModel.quickbooksLinkOnly" />Link Only (will not update customer in QuickBooks)
            </div>
            <div *ngIf="!customerModel.quickbooksId && editQuickbooksName" class="checkbox-item">
                <input type="checkbox" [(ngModel)]="customerModel.quickbooksIdOnly" />This customer already exists in quickbooks.
            </div>
        </div>

        <div *ngIf="customerModel.childCompanyId" class="mb-2">
            Customer is linked to: <b>{{customerModel.childCompanyName}}</b><br />
            <span *ngIf="isGlobalAdmin">{{customerModel.childCompanyLogin}}</span>
        </div>
    </div>

    <div *ngIf="!skeditPay" class="row">
        <div class="col">
            <label>Customer Type</label>
            <slick-drop-down [(ngModel)]="customerModel.customerTypeId"
                             [items]="customerTypes"
                             allowEmpty="false"
                             (onSelect)="onCustomerTypeSelected($event)"></slick-drop-down>
            <form-error *ngIf="!customerModel.customerTypeId && isSubmitted">Customer Type is required</form-error>
        </div>

        <div *ngIf="customerAreas.length > 0"
             class="col">
            <label>Customer Area</label>
            <slick-drop-down [(ngModel)]="customerModel.customerAreaId"
                             [items]="customerAreas"></slick-drop-down>
        </div>

        <div *ngIf="salesReps.length > 0" class="col">
            <label>Sales Rep</label>
            <div>
                <slick-drop-down [(ngModel)]="customerModel.salesRepId"
                                 (onSelect)="onSalesRepSelect($event)"
                                 [items]="salesReps"
                                 placeholder="None"></slick-drop-down>
            </div>
        </div>

        <div *ngIf="arReps.length > 0" class="col">
            <label>AR Rep</label>
            <div>
                <slick-drop-down [(ngModel)]="customerModel.arRepId"
                                 (onSelect)="onARRepSelect($event)"
                                 [items]="arReps"
                                 placeholder="None"></slick-drop-down>
            </div>
        </div>

        <div *ngIf="isJellyfish" class="col">
            <label>Dealer</label>
            <slick-drop-down [(ngModel)]="customerModel.dealerId"
                             [items]="dealerDropdown"
                             allowEmpty="true"></slick-drop-down>
        </div>

        <div *ngIf="isCanada"
             class="col">
            <label>QB Location</label>
            <slick-drop-down [(ngModel)]="customerModel.quickbooksLocationId"
                             [items]="qbLocations"></slick-drop-down>
        </div>
    </div>

    <div class="row">
        <div *ngIf="priceLevels.length > 1" class="col">
            <label>Price Level</label>
            <slick-drop-down [(ngModel)]="customerModel.priceLevelId" [items]="priceLevels" allowEmpty="false"></slick-drop-down>
        </div>

        <div *ngIf="showPaymentTerms" class="col">
            <label>Payment Terms</label>
            <slick-drop-down [(ngModel)]="customerModel.paymentTermId" [items]="paymentTerms"></slick-drop-down>
        </div>

        <div *ngIf="taxRegions.length > 0 && !useCereTax" class="col">
            <label>Tax Region</label>
            <slick-drop-down [(ngModel)]="customerModel.taxRegionId" [items]="taxRegions"></slick-drop-down>
        </div>

        <div *ngIf="useCereTax" class="col">
            <label>Tax</label>
            <slick-drop-down [(ngModel)]="taxType" [items]="taxTypes" (onSelect)="onTaxTypeSelected($event)" [allowEmpty]='false'></slick-drop-down>
        </div>

        <div *ngIf="!skeditPay && showTaxId" class="col">
            <label>Tax ID</label>
            <input class="form-control" [(ngModel)]="customerModel.taxId" />
        </div>
        <div>
            <div *ngIf="showQuickbooksName && customerModel.syncToQuickbooks" class="form-group" style="font-size: .8rem; position: relative; top: -5px;">
                <div *ngIf="editQuickbooksName" class="checkbox-item">
                    <input type="checkbox" [(ngModel)]="customerModel.quickbooksLinkOnly" />Link Only (will not update customer in QuickBooks)
                </div>
            </div>

            <div>

                <h5 *ngIf="skeditPay">Address</h5>
                <div *ngIf="!skeditPay" class="bold-header" style="display: flex; align-items: center;">
                    <div style="flex-grow: 1;">Physical Address</div>
                    <div class="gray-line"></div>
                </div>
                <div class="customer-info form-group">
                    <div class="width-full">
                        <input type="search"
                               class="form-control"
                               name="addressSearch"
                               placeholder="Address Search"
                               [slick-google-address-search]
                               (onGoogleAddressSelect)="onGoogleMapAddressSelect($event)" />
                    </div>
                </div>

                <div class="customer-info form-group">
                    <div class="width-full">
                        <label>Address 1</label>
                        <input class="form-control"
                               (change)="checkAddress()"
                               [(ngModel)]="customerModel.address1" />
                    </div>
                </div>

                <div class="customer-info form-group">
                    <div class="width-full">
                        <label>Address 2</label>
                        <input class="form-control"
                               [(ngModel)]="customerModel.address2" />
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <label>City</label>
                        <input class="form-control" [(ngModel)]="customerModel.city" />
                    </div>
                
                    <div *ngIf="customerModel.country !== 'Canada'" class="col">
                        <label>State</label>
                        <input class="form-control" [(ngModel)]="customerModel.state" />
                    </div>
                
                    <div *ngIf="customerModel.country === 'Canada'" class="col">
                        <label>Province</label>
                        <input class="form-control" [(ngModel)]="customerModel.state" />
                    </div>
                
                    <div class="col">
                        <label>Zipcode</label>
                        <input class="form-control" [(ngModel)]="customerModel.zipcode" />
                    </div>
                
                    <div class="col">
                        <label>Country</label>
                        <slick-drop-down [(ngModel)]="customerModel.country"
                                         [items]="countries"></slick-drop-down>
                    </div>
                </div>

                <div *ngIf="!skeditPay" class="customer-info form-group">
                    <div class="width-full checkbox-items d-flex align-items-center">
                        <span class="d-flex align-items-center">
                            <input type="checkbox"
                                   [(ngModel)]="customerModel.linkBillingAddress"
                                   class="form-check-input" />
                            <label class="d-inline">Billing Address Same As Physical</label>
                        </span>

                        <span class="d-flex align-items-center">
                            &nbsp;&nbsp;&nbsp;
                            <input type="checkbox"
                                   [(ngModel)]="customerModel.linkShippingAddress"
                                   class="form-check-input" />
                            <label class="d-inline">Shipping Address Same As Physical</label>
                        </span>

                    </div>
                </div>

                <!-- Change this -->
                <div *ngIf="isMizu">
                    <label>Billing Customer</label>
                    <input #autoCompleteInputRef
                           type="text"
                           class="form-control"
                           autocomplete="off"
                           [(ngModel)]="billingCustomerSearchText" />
                    <slick-auto-complete [inputRef]="autoCompleteInputRef"
                                         textField="customerFullName"
                                         [items]="billingCustomerResults"
                                         (onRefresh)="billingCustomerSearch($event)"
                                         (onSelect)="onBillingCustomerSelect($event)">
                        <ng-template let-result
                                     [slickAutoCompleteListItemTemplate]>
                            <ul class="customer-list-item">
                                <li *ngIf="result.customerCompanyName" class="bigger">{{ result.customerCompanyName }}</li>
                                <li *ngIf="result.customerFullName && !result.customerCompanyName && result.customerMultipleAddressId" class="bigger">{{ result.customerFullName }}</li>
                                <li *ngIf="result.owningCompanyName && result.customerFullName && showOwningCompany" style="font-size: 10px"><i>{{result.owningCompanyName}}</i></li>
                                <li *ngIf="result.owningCompanyName && !result.customerFullName && showOwningCompany" style="font-size: 10px"><i>{{result.owningCompanyName}}</i></li>
                                <li *ngIf="result.customerFullName && !result.customerMultipleAddressId" class="bigger">{{ result.customerFullName }}</li>
                                <li *ngIf="result.multiDescription">{{result.multiDescription}}</li>
                                <li *ngIf="result.multiCompanyName">{{ result.multiCompanyName }}</li>

                                <li *ngIf="result.multiFullName">{{ result.multiFullName }}</li>

                                <li *ngIf="result.address1 && !isBillingCustomer">{{ result.address1 }}</li>
                                <li *ngIf="result.address2 && !isBillingCustomer">{{ result.address2 }}</li>
                                <li *ngIf="result.cityStateZip && !isBillingCustomer" style="line-height: 1rem">{{ result.cityStateZip }}</li>

                                <li *ngIf="result.billingAddress1 && isBillingCustomer">{{ result.billingAddress1 }}</li>
                                <li *ngIf="result.billingAddress2 && isBillingCustomer">{{ result.billingAddress2 }}</li>
                                <li *ngIf="result.billingCityStateZip && isBillingCustomer" style="line-height: 1rem">{{ result.billingCityStateZip }}</li>

                                <li *ngIf="result.cellPhone">Cell: {{ result.cellPhone }}</li>
                                <li *ngIf="result.homePhone">Home: {{ result.homePhone }}</li>
                                <li *ngIf="result.workPhone">Work: {{ result.workPhone }}</li>
                            </ul>

                        </ng-template>
                    </slick-auto-complete>
                </div>

                <div *ngIf="!customerModel.linkBillingAddress">
                    <div *ngIf="!skeditPay" class="bold-header" style="display: flex; align-items: center;">
                        <div style="flex-grow: 1;">Billing Address</div>
                        <div class="gray-line"></div>
                    </div>
                    <div class="customer-info form-group">
                        <div class="address-info width-full">
                            <input type="search"
                                   class="form-control"
                                   name="billingAddressSearch"
                                   placeholder="Address Search"
                                   slick-google-address-search
                                   (onGoogleAddressSelect)="onGoogleMapBillingAddressSelect($event)" />
                        </div>
                    </div>

                    <div class="customer-info form-group">
                        <div class="address-info width-full">
                            <label>Address 1</label>
                            <input class="form-control"
                                   [(ngModel)]="customerModel.billingAddress1" />
                        </div>
                    </div>

                    <div class="customer-info form-group">
                        <div class="address-info width-full">
                            <label>Address 2</label>
                            <input class="form-control"
                                   [(ngModel)]="customerModel.billingAddress2" />
                        </div>
                    </div>

                    <div class="customer-info form-group">
                        <div class="width-one-fourth">
                            <label>City</label>
                            <input class="form-control"
                                   [(ngModel)]="customerModel.billingCity" />
                        </div>

                        <div>
                            <div *ngIf="customerModel.country !== 'Canada'" class="width-one-fourth">
                                <label>State</label>
                                <input class="form-control"
                                       [(ngModel)]="customerModel.billingState" />
                            </div>

                            <div *ngIf="customerModel.country === 'Canada'" class="width-one-fourth">
                                <label>Province</label>
                                <input class="form-control"
                                       [(ngModel)]="customerModel.billingState" />
                            </div>
                        </div>

                        <div class="width-one-fourth">
                            <label>Zipcode</label>
                            <input class="form-control"
                                   [(ngModel)]="customerModel.billingZipcode" />
                        </div>

                        <div class="width-one-fourth">
                            <label>Country</label>
                            <slick-drop-down [(ngModel)]="customerModel.billingCountry"
                                             [items]="countries"></slick-drop-down>
                        </div>
                    </div>
                </div>

                <div *ngIf="!customerModel.linkShippingAddress">
                    <div *ngIf="!skeditPay" class="bold-header" style="display: flex; align-items: center;">
                        <div style="flex-grow: 1;">Shipping Address</div>
                        <div class="gray-line"></div>
                    </div>
                    <div class="customer-info form-group">
                        <div class="address-info width-full">
                            <input type="search"
                                   class="form-control"
                                   name="shippingAddressSearch"
                                   placeholder="Address Search"
                                   slick-google-address-search
                                   (onGoogleAddressSelect)="onGoogleMapShippingAddressSelect($event)" />
                        </div>
                    </div>

                    <div class="customer-info form-group">
                        <div class="address-info width-full">
                            <label>Address 1</label>
                            <input class="form-control"
                                   [(ngModel)]="customerModel.shippingAddress1" />
                        </div>
                    </div>

                    <div class="customer-info form-group">
                        <div class="address-info width-full">
                            <label>Address 2</label>
                            <input class="form-control"
                                   [(ngModel)]="customerModel.shippingAddress2" />
                        </div>
                    </div>

                    <div class="customer-info form-group">
                        <div class="width-one-fourth">
                            <label>City</label>
                            <input class="form-control"
                                   [(ngModel)]="customerModel.shippingCity" />
                        </div>

                        <div>
                            <div *ngIf="customerModel.country !== 'Canada'" class="width-one-fourth">
                                <label>State</label>
                                <input class="form-control"
                                       [(ngModel)]="customerModel.shippingState" />
                            </div>

                            <div *ngIf="customerModel.country === 'Canada'" class="width-one-fourth">
                                <label>Province</label>
                                <input class="form-control"
                                       [(ngModel)]="customerModel.shippingState" />
                            </div>
                        </div>

                        <div class="width-one-fourth">
                            <label>Zipcode</label>
                            <input class="form-control"
                                   [(ngModel)]="customerModel.shippingZipcode" />
                        </div>

                        <div class="width-one-fourth">
                            <label>Country</label>
                            <slick-drop-down [(ngModel)]="customerModel.shippingCountry"
                                             [items]="countries"></slick-drop-down>
                        </div>
                    </div>
                </div>

                <div class="bold-header" style="display: flex; align-items: center;">
                    <div style="flex-grow: 1;">Contact Info</div>
                    <div class="gray-line"></div>
                </div>
                <div class="customer-info form-group">
                    <div class="width-one-third">
                        <label>Cell Phone</label>
                        <input class="form-control" [(ngModel)]="customerModel.cellPhone" />
                    </div>

                    <div class="width-one-third">
                        <label>Home Phone</label>
                        <input class="form-control" [(ngModel)]="customerModel.homePhone" />
                    </div>

                    <div class="width-one-third">
                        <label>Work Phone</label>
                        <input class="form-control" [(ngModel)]="customerModel.workPhone" />
                    </div>

                </div>

                <div class="customer-info form-group">
                    <div class="width-half">
                        <label>Email</label>
                        <input type="email" class="form-control"
                               (change)="checkForEmail()"
                               [(ngModel)]="customerModel.email" />

                        <form-error *ngIf="!emailIsValid && isSubmitted">Invalid email format</form-error>
                    </div>

                    <div class="width-half">
                        <label>AP Email</label>
                        <input type="email" class="form-control"
                               [(ngModel)]="customerModel.apEmail" />

                        <form-error *ngIf="!apEmailIsValid && isSubmitted">Invalid email format</form-error>
                    </div>

                </div>
                <div class="customer-info form-group">
                    <div class="width-one-fourth">
                        <label>Fax</label>
                        <input class="form-control"
                               [(ngModel)]="customerModel.fax" />
                    </div>
                    <div class="flex-fill ms-4">
                        <label>Website</label>
                        <input class="form-control"
                               [(ngModel)]="customerModel.website" />
                    </div>
                </div>

                <div *ngIf="!skeditPay" class="bold-header" style="display: flex; align-items: center;">
                    <div style="flex-grow: 1;">Additional Info</div>
                    <div class="gray-line"></div>
                </div>
                <div class="row">
                    <div *ngFor="let customCustomerFieldWithAnswer of customCustomerFieldWithAnswers" class="col-md-3 mb-2">
                        <label>{{ customCustomerFieldWithAnswer.customCustomerFieldModel.customText }}</label>
                        <input type="text" style="width: 100%;" class="form-control" [(ngModel)]="customCustomerFieldWithAnswer.answer" (change)="updateAnswers()" />
                    </div>
                </div>
                <!-- <div class="d-flex row form-group">

                <div class="col-2 mt-4">
                    <input type="checkbox"
                           [(ngModel)]="customerModel.emailOptOut" />
                    <label class="d-inline">Opt Out</label>
                </div>
            </div> -->

                <div class="customer-info form-group">
                    <div class="width-half">
                        <label>Attention Notes</label>
                        <textarea class="form-control"
                                  [(ngModel)]="customerModel.customerAttentionNote"></textarea>
                    </div>
                    <div class="width-half">
                        <label>Notes</label>
                        <textarea class="form-control"
                                  [(ngModel)]="customerModel.customerNotes"></textarea>
                    </div>
                </div>

                <div *ngIf="!skeditPay" class="customer-info form-group">
                    <div class="width-full">
                        <label>Billing Notes</label>
                        <textarea class="form-control"
                                  [(ngModel)]="customerModel.billingNotes"></textarea>
                    </div>
                </div>

                <div *ngIf="!skeditPay" class="customer-info form-group">
                    <div class="width-full checkbox-items d-flex align-items-center">
                        <span class="d-flex align-items-center">
                            <input type="checkbox"
                                   [(ngModel)]="customerModel.creditHold"
                                   class="form-check-input" />
                            <label class="d-inline">Credit Hold</label>
                        </span>

                        <span *ngIf="isMizu" class="d-flex align-items-center">
                            &nbsp;&nbsp;&nbsp;
                            <input type="checkbox"
                                   [(ngModel)]="customerModel.criticalCreditHold"
                                   class="form-check-input" />
                            <label class="d-inline">Critical Credit Hold</label>
                        </span>
                        <span class="d-flex align-items-center">
                            &nbsp;&nbsp;&nbsp;
                            <input type="checkbox"
                                   [(ngModel)]="customerModel.textOptOut"
                                   class="form-check-input" />
                            <label class="d-inline">Opt Out of Text</label>
                        </span>

                        <span *ngIf="useCereTax" class="d-flex align-items-center">
                            &nbsp;&nbsp;&nbsp;
                            <input type="checkbox"
                                   [(ngModel)]="customerModel.taxExempt"
                                   class="form-check-input" />
                            <label class="d-inline">Tax Exempt</label>
                        </span>
                    </div>
                </div>

                <div *ngIf="!skeditPay && customerModel.creditHold" class="customer-info form-group">
                    <div class="width-full">
                        <label>Credit Hold Notes</label>
                        <textarea class="form-control"
                                  [(ngModel)]="customerModel.creditHoldNotes"></textarea>
                    </div>
                </div>

                <div *ngIf="!skeditPay" class="bold-header" style="display: flex; align-items: center;">
                    <div *ngIf="!promotionsExpanded" (click)="promotionsExpanded = !promotionsExpanded" style="flex-grow: 1;"> <i class="bi bi-plus-circle"></i></div>
                    <div *ngIf="promotionsExpanded" (click)="promotionsExpanded = !promotionsExpanded" style="flex-grow: 1;"> <i class="bi bi-dash-circle"></i></div>
                    <div style="flex-grow: 1;">Promotions</div>
                    <div class="gray-line"></div>
                </div>


                <div *ngIf="promotionsExpanded">
                    <label> Discounts </label>

                    <div>
                        <table class="table table-responsive table-borderless" style="width: 600px;">
                            <colgroup>
                                <col />
                            </colgroup>
                            <colgroup>
                                <col style="width: 50px;" />
                            </colgroup>
                            <tbody>
                                <tr *ngFor="let discount of customerModel.discounts; let i = index">
                                    <td>
                                        <slick-drop-down [items]="adjustedDiscounts[i]"
                                                         [ngModel]="discount.discountId"
                                                         [disabled]="!customersAuthService.canEdit()"
                                                         (onChange)="updateDiscount($event, i)"
                                                         (onSelect)="recalculateAvailableDiscounts()">
                                        </slick-drop-down>
                                    </td>
                                    <td class="align-middle">
                                        <button class="btn btn-outline-danger"
                                                *ngIf="customersAuthService.canEdit()"
                                                (click)="removeDiscount(i)">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>

                                <tr *ngIf="customersAuthService.canEdit()">
                                    <td colspan="2">
                                        <button (click)="addDiscount()"
                                                class="btn btn-outline-primary">
                                            <i class="far fa-plus"></i> Add
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <label> Rebates </label>
                    <div>
                        <table style="width: 600px;"
                               class="table table-borderless">
                            <colgroup>
                                <col />
                            </colgroup>
                            <colgroup>
                                <col style="width: 50px;" />
                            </colgroup>
                            <tbody>
                                <tr *ngFor="let rebate of customerModel.rebates; let i = index">
                                    <td>
                                        <slick-drop-down [items]="adjustedRebates[i]"
                                                         [ngModel]="rebate.rebateId"
                                                         [disabled]="!customersAuthService.canEdit()"
                                                         (onChange)="updateRebate($event, i)"
                                                         (onSelect)="recalculateAvailableRebates()">
                                        </slick-drop-down>
                                    </td>
                                    <td class="align-middle">
                                        <button class="btn btn-outline-danger" *ngIf="customersAuthService.canEdit()" (click)="removeRebate(i)">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>

                                <tr *ngIf="customersAuthService.canEdit()">
                                    <td colspan="2">
                                        <button (click)="addRebate()" class="btn btn-outline-primary">
                                            <i class="far fa-plus"></i> Add
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

                <!-- <div *ngIf="!skeditPay">
                <div *ngIf="customerModel.acceptedTerms === true"><i class="bi bi-check-circle-fill text-success"></i>&nbsp;<b>Service Agreement Signed</b></div>
                <div *ngIf="customerModel.acceptedTerms === false" class="mb-3">
                    <button type="button" style="width: 280px;" class="btn btn-outline-primary" [slick-button-spinner]="serviceAgreementSpinnerStatus" (click)="sendServiceAgreement()"><i class="bi bi-envelope"></i> Send Service Agreement</button>
                </div>
            </div> -->
            </div>
        </div>

        <customer-quickbooks-link-dialog #customerQuickbooksLinkDialogRef></customer-quickbooks-link-dialog>

        <conflicting-customers-dialog #conflictingCustomerDialogRef></conflicting-customers-dialog>
