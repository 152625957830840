import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ComponentsModule } from '@components'
import { SlickConfirmDialogModule, SlickDialogModule, SlickButtonSpinnerModule } from "@slick-components";

import { TexterDialogComponent } from "./texter-dialog.component";

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		FormsModule,
		SlickConfirmDialogModule,
		SlickDialogModule,
		SlickButtonSpinnerModule
	],
	declarations: [
		TexterDialogComponent
	],
	exports: [TexterDialogComponent]
})
export class TexterDialogModule {
}

export { TexterDialogComponent }