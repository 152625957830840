import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlickPhotoGalleryComponent } from "./slick-photo-gallery.component";
import { SlickPhotoGalleryModel } from "./slick-photo-gallery.model";

@NgModule({
	imports: [CommonModule],
	exports: [SlickPhotoGalleryComponent],
	declarations: [SlickPhotoGalleryComponent]
})
export class SlickPhotoGalleryModule { }

export { SlickPhotoGalleryComponent }
export { SlickPhotoGalleryModel }