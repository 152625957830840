import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { UtilsService, NotificationsService, QuickbooksService, GlobalsService } from "@services";
import { SignalRMessageStore } from "@stores";
import { SlickToastService } from "@slick-components";
import { QuickbooksTaskDisplayDialogComponent } from "@app/quickbooks";
import { INotificationModel } from "@models";
import { Subscription } from "rxjs";

@Component({
	selector: "notifications",
	templateUrl: "notifications.component.html",
	styleUrls: ['notifications.component.scss'],
	providers: [NotificationsService, QuickbooksService, SlickToastService]
})

export class NotificationsComponent implements OnInit, OnDestroy {
	@ViewChild("quickbooksTaskDisplayDialog") quickbooksTaskDisplayDialog: QuickbooksTaskDisplayDialogComponent;

	activeNotifications: INotificationModel[] = [];
	showingNotifications = false;

	addNotification$: Subscription;
	removeNotification$: Subscription;
	quickbooksClearNotification$: Subscription;

	private fnDocumentClick = (e) => this.documentClick(e);

	constructor(
		private notificationsService: NotificationsService,
		private quickbooksService: QuickbooksService,
		private slickToastService: SlickToastService,
		private signalRMessageStore: SignalRMessageStore
	) { }

	async ngOnInit() {
		this.activeNotifications = await this.notificationsService.getActiveNotifications();

		this.addNotification$ = this.signalRMessageStore.addNotification.subscribe((notificationModel: INotificationModel) => {
			this.addNotification(notificationModel);
		});

		this.removeNotification$ = this.signalRMessageStore.removeNotification.subscribe((notificationId: number) => {
			this.removeNotification(notificationId);
		});

		this.quickbooksClearNotification$ = this.signalRMessageStore.quickbooksClearNotification.subscribe((qbwcTaskGridViewModel) => {
			const qbwcNotification = this.activeNotifications.find(x => x.isQuickbooks === true && x.referenceId === qbwcTaskGridViewModel.qbwcTaskId);
			this.removeNotification(qbwcNotification.notificationId);
		});
	}

	ngOnDestroy() {
		if (this.addNotification$)
			this.addNotification$.unsubscribe();

		if (this.removeNotification$)
			this.removeNotification$.unsubscribe();

		if (this.quickbooksClearNotification$)
			this.quickbooksClearNotification$.unsubscribe();
	}

	addNotification(notificationModel: INotificationModel) {
		if (notificationModel.notificationType === 'Quickbooks Error') {
			if (GlobalsService.userInfo.showAllQBWCErrors === true) {
				this.activeNotifications.unshift(notificationModel);
				this.slickToastService.showInfo(notificationModel.message, 1000);
			}
		}
		else {
			this.activeNotifications.unshift(notificationModel);
			this.slickToastService.showInfo(notificationModel.message, 1000);
        }
	}

	removeNotification(notificationId: number) {
		this.activeNotifications = this.activeNotifications.filter(x => x.notificationId !== notificationId);
	}

	showNotifications() {
		if (this.showingNotifications === false) {
			this.showingNotifications = true;
			document.addEventListener("click", this.fnDocumentClick, true);
		}
		else {
			this.hideNotificationWindow();
		}

	}

	async clearAllNotifications() {
		var result = await this.notificationsService.clearAllNotifications();
		if (result === 'Ok') {
			this.activeNotifications = [];
			this.hideNotificationWindow();
		}
	}

	async clearNotification(notification: INotificationModel) {
		var result = await this.notificationsService.clearNotification(notification.notificationId);
		if (result) {
			this.activeNotifications = this.activeNotifications.filter(x => x.notificationId !== notification.notificationId);
			if (this.activeNotifications.length === 0)
				this.hideNotificationWindow();
		}
	}

	async viewQuickbooksTask(qbwcTaskId: number) {
		const qbwcTaskModel = await this.quickbooksService.getQBWCTask(qbwcTaskId);
		this.quickbooksTaskDisplayDialog.showDialog(qbwcTaskModel);
	}

	private hideNotificationWindow() {
		document.removeEventListener("click", this.fnDocumentClick, true);
		this.showingNotifications = false;
	}

	documentClick(e) {
		if (UtilsService.checkParentClassExists(e.target, "notification-details"))
			return;

		this.hideNotificationWindow();
	}
}
