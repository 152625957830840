import { ISlickTreeNodeModel, SlickTreeNodeModel } from "@slick-components";
import { ILathamOrderPickListDefinitionConditionModel } from "./latham-order-pick-list-definition-condition.model";
import { ILathamOrderPickListDefinitionLineItemModel } from "./latham-order-pick-list-definition-line-item.model";

export interface ILathamOrderPickListDefinitionModel extends ISlickTreeNodeModel {
	lathamOrderPickListDefinitionId: number;
	seq?: number;
	description: string;
	treePath: string;
	active: boolean;

	pickListDefinitionConditions: ILathamOrderPickListDefinitionConditionModel[];
	pickListDefinitionLineItems: ILathamOrderPickListDefinitionLineItemModel[];
	pickListDefinitionLineItemsToDelete: ILathamOrderPickListDefinitionLineItemModel[];
}

export class LathamOrderPickListDefinitionModel extends SlickTreeNodeModel implements ILathamOrderPickListDefinitionModel {
	lathamOrderPickListDefinitionId: number;
	seq?: number;
	description: string;
	treePath: string;
	active: boolean;

	pickListDefinitionConditions: ILathamOrderPickListDefinitionConditionModel[];
	pickListDefinitionLineItems: ILathamOrderPickListDefinitionLineItemModel[];
	pickListDefinitionLineItemsToDelete: ILathamOrderPickListDefinitionLineItemModel[];
}