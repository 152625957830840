import { Component, ViewChild } from "@angular/core";
import { SlickConfirmDialogComponent } from "@slick-components";


@Component({
	selector: "concurrency-error-dialog",
	template:
		`
<slick-confirm-dialog #confirmDialogRef showCancelButton='false'>
	{{title}}<br />
	<div style='font-size: 16px' class='my-3 text-danger'>{{errorMessage}}</div>   
	<div style='font-size: 12px'>Concurrency errors occur when another user has updated this record and saving this record would overwrite those changes.  Please re-enter your changes.</div>
</slick-confirm-dialog>`
})
export class ConcurrencyErrorDialogComponent {
	title: string;
	errorMessage: string;

	@ViewChild("confirmDialogRef", { static: true }) confirmDialogRef: SlickConfirmDialogComponent;

	constructor() { }

	async show(title: string, errorMessage: any) {
		this.title = title;
		this.errorMessage = errorMessage;
		await this.confirmDialogRef.confirm();
	}
}