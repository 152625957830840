import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ISlickTreeNodeModel, SlickTreeNodeModel } from './slick-tree-node.model';
import { SlickSleepService } from "../utils/slick-sleep.service";
import { SlickUtilsService } from "../utils/slick-utils.service";
import { SlickConfirmDialogComponent, SlickConfirmDialogResults } from '../slick-confirm-dialog/slick-confirm-dialog.module';

class SlickTreeViewDisplayModel {
	constructor(node: ISlickTreeNodeModel, level: number) {
		this.uuid = node.uuid;
		this.parentUuid = node.parentUuid;
		this.node = node;
		this.text = node.text;
		this.level = level;
		this.expanded = node.expanded;
		this.editingText = false;

		this.indentArray = [];
		for (let i = 0; i < this.level; i++)
			this.indentArray.push(true);
	}

	node: ISlickTreeNodeModel;
	uuid: string;
	text: string;
	parentUuid: string;
	level: number;
	indentArray: boolean[];
	expanded: boolean;
	hasChildNodes: boolean;
	editingText: boolean;
}

@Component({
	selector: 'slick-tree-view',
	templateUrl: 'slick-tree-view.component.html'
})
export class SlickTreeViewComponent implements OnChanges, OnInit, OnDestroy {
	@Input() nodes: ISlickTreeNodeModel[];
	@Output() nodesChange: EventEmitter<ISlickTreeNodeModel[]> = new EventEmitter();
	@Input() showRootNode: boolean = true;
	@Input() rootNodeUuid: string;
	@Input() expandOnClick: boolean = true;
	@Output() onNodeSelected = new EventEmitter<ISlickTreeNodeModel>();
	@Output() onNodeChanged = new EventEmitter<ISlickTreeNodeModel>();
	@Output() onNodeAdded = new EventEmitter<ISlickTreeNodeModel>();
	@Output() onNodeDeleted = new EventEmitter<ISlickTreeNodeModel[]>();

	@ViewChild("slickTreeViewRef", { static: true }) slickTreeViewRef: ElementRef;
	@ViewChild("contextMenuRef") contextMenuRef: ElementRef;
	@ViewChild("deleteNodeConfirmRef") deleteNodeConfirmRef: SlickConfirmDialogComponent;

	fnDocumentClick = (e) => this.documentClick(e);
	fnContextMenu = (e) => this.contextMenu(e);

	contextNodeUuid: string;

	uuid: string = SlickUtilsService.newGuid();
	displayNodes: SlickTreeViewDisplayModel[];
	selectedUuid: string;
	newUuid: string = null;
	deleteNodeName: string;

	contextMenuShowAdd: boolean;
	contextMenuShowDelete: boolean;
	contextMenuShowEditText: boolean;

	constructor() { }

	ngOnInit() {
		document.addEventListener("click", this.fnDocumentClick, true);
		this.slickTreeViewRef.nativeElement.addEventListener("contextmenu", this.fnContextMenu, true);
	}

	ngOnDestroy() {
		document.removeEventListener("click", this.fnDocumentClick, true);
		this.slickTreeViewRef.nativeElement.removeEventListener("contextmenu", this.fnContextMenu, true);
	}

	ngOnChanges(changes: SimpleChanges) {
		this.expandOnClick = (this.expandOnClick.toString().toLowerCase() === 'false') ? false : true;
		this.showRootNode = (this.showRootNode.toString().toLowerCase() === 'false') ? false : true;
		this.rootNodeUuid = this.rootNodeUuid || null;

		if (changes.nodes)
			this.refreshDisplay();

	}

	private async documentClick(e: MouseEvent) {
		if (e.target && SlickUtilsService.checkParentClassExists(<HTMLElement>e.target, "slick-tree-view_node-text-edit"))
			return;

		if (e.target && SlickUtilsService.checkParentClassExists(<HTMLElement>e.target, "slick-tree-view_context-menu"))
			return;

		const contextMenuElement = <HTMLElement>this.contextMenuRef.nativeElement;
		contextMenuElement.classList.remove("menu-visible");
		contextMenuElement.classList.add("menu-hidden");

		this.processNewNode();

		this.displayNodes = this.displayNodes.map(displayNode => {
			if (displayNode.editingText) {
				displayNode.editingText = false;
				if (this.onNodeChanged && displayNode.text !== displayNode.node.text) {
					displayNode.node.text = displayNode.text;
					this.onNodeChanged.emit(displayNode.node);
				}
			}

			return displayNode;
		});
	}

	onTextKeydown(e: KeyboardEvent, displayNode: SlickTreeViewDisplayModel) {
		// Esc
		if (e.keyCode === 27) {
			displayNode.editingText = false;
			displayNode.node.text = displayNode.text;
			this.processNewNode();
		}

		// Enter or tab
		if (e.keyCode === 13 || e.keyCode === 9) {
			displayNode.editingText = false;
			this.processNewNode();
			if (this.onNodeChanged && displayNode.text !== displayNode.node.text) {
				displayNode.node.text = displayNode.text;
				this.onNodeChanged.emit(displayNode.node);
			}
		}
	}

	contextMenu(e: MouseEvent) {
		if (!e || !e.target)
			return;

		if (!SlickUtilsService.checkParentIdExists(<HTMLElement>e.target, `slick-tree-view_${this.uuid}`))
			return;

		const contextNodeElement = <HTMLDivElement>SlickUtilsService.findParent(<HTMLElement>e.target, "slick-tree-view-node");
		if (!contextNodeElement)
			return;

		e.preventDefault();

		this.contextNodeUuid = contextNodeElement.getAttribute("data-uuid");

		const node = this.nodes.find(x => x.uuid === this.contextNodeUuid);
		this.contextMenuShowAdd = node.canAddChildren;
		this.contextMenuShowDelete = node.canDelete;
		this.contextMenuShowEditText = node.canEditText;

		// If there's nothing to do, don't even show the context menu
		if (this.contextMenuShowAdd === false && this.contextMenuShowDelete === false && this.contextMenuShowEditText === false)
			return;

		const contextMenuElement = <HTMLElement>this.contextMenuRef.nativeElement;
		const top = (contextNodeElement.offsetTop + contextNodeElement.offsetHeight - 5);

		const indentationElement = <HTMLDivElement>contextNodeElement.querySelector(".slick-tree-view_indentation");
		const iconElement = <HTMLDivElement>contextNodeElement.querySelector(".slick-tree-view_icon");
		const indentationWidth = (indentationElement) ? indentationElement.offsetWidth : 0;
		const iconWidth = (iconElement) ? iconElement.offsetWidth : 0;
		const left = indentationWidth + iconWidth + 2;

		contextMenuElement.style.top = top + "px";
		contextMenuElement.style.left = left + "px";
		contextMenuElement.classList.add("menu-visible");
		contextMenuElement.classList.remove("menu-hidden");
	}

	async addNode() {
		// Hide the context menu
		const contextMenuElement = <HTMLElement>this.contextMenuRef.nativeElement;
		contextMenuElement.classList.remove("menu-visible");
		contextMenuElement.classList.add("menu-hidden");

		if (!this.contextNodeUuid)
			return;

		const parentNodeIdx = this.nodes.findIndex(x => x.uuid === this.contextNodeUuid);
		const parentNode = this.nodes[parentNodeIdx]
		// Since we want to add this one to the top, pass null as the parent
		var newNode = new SlickTreeNodeModel(null, "New Folder");
		newNode.parentUuid = parentNode.uuid;
		parentNode.expanded = true;
		this.nodes.splice(parentNodeIdx, 0, newNode);

		this.refreshDisplay();

		const displayNode = this.displayNodes.find(x => x.uuid === newNode.uuid);
		this.editText(displayNode);

		this.newUuid = newNode.uuid;
	}

	async deleteNode() {
		// Hide the context menu
		const contextMenuElement = <HTMLElement>this.contextMenuRef.nativeElement;
		contextMenuElement.classList.remove("menu-visible");
		contextMenuElement.classList.add("menu-hidden");

		if (!this.contextNodeUuid)
			return;

		const node = this.nodes.find(x => x.uuid === this.contextNodeUuid);

		this.deleteNodeName = node.text;

		const confirmResult = await this.deleteNodeConfirmRef.confirm();

		if (confirmResult === SlickConfirmDialogResults.Cancel)
			return;

		const removedNodes = this.deleteRecursive(node);

		this.refreshDisplay();

		if (this.onNodeDeleted)
			this.onNodeDeleted.emit(removedNodes);

		if (this.nodesChange) 
			this.nodesChange.emit(this.nodes);		
	}

	editNodeText() {
		// Hide the context menu
		const contextMenuElement = <HTMLElement>this.contextMenuRef.nativeElement;
		contextMenuElement.classList.remove("menu-visible");
		contextMenuElement.classList.add("menu-hidden");

		if (!this.contextNodeUuid)
			return;

		const displayNode = this.displayNodes.find(x => x.uuid === this.contextNodeUuid);
		this.editText(displayNode);

		if (this.nodesChange) 
			this.nodesChange.emit(this.nodes);		
	}

	selectNode(displayNode: SlickTreeViewDisplayModel) {
		if (this.selectedUuid === displayNode.uuid)
			return;

		this.selectedUuid = displayNode.uuid;
		if (displayNode.node.expandOnClick === true)
			this.expandNode(displayNode);

		if (this.onNodeSelected)
			this.onNodeSelected.emit(displayNode.node);
	}

	expandNode(displayNode: SlickTreeViewDisplayModel) {
		displayNode.node.expanded = true;
		this.refreshDisplay();
	}

	collapseNode(displayNode: SlickTreeViewDisplayModel) {
		displayNode.node.expanded = false;
		this.refreshDisplay();
	}

	async editText(displayNode: SlickTreeViewDisplayModel) {
		if (!displayNode.node.canEditText)
			return;

		displayNode.editingText = true;

		await SlickSleepService.sleep();
		const textbox = <HTMLInputElement>document.querySelector(`.slick-tree-view_node-text-edit_${displayNode.uuid}`);
		if (textbox) {
			textbox.focus();
			textbox.select();
		}
	}

	private refreshDisplay() {
		this.displayNodes = [];

		if (!this.nodes || this.nodes.length === 0)
			return;

		let rootNode: ISlickTreeNodeModel;
		if (this.rootNodeUuid)
			rootNode = this.nodes.find(x => x.uuid === this.rootNodeUuid);
		else
			rootNode = this.nodes.find(x => x.parentUuid === null);
		// Always expand the root node
		rootNode.expanded = true;

		// If we aren't showing the root node, start at -1 for the indent level
		this.populateDisplayNodes(rootNode, (this.showRootNode) ? 0 : -1);

		if (this.showRootNode === false) 
			this.displayNodes = this.displayNodes.filter(x => x.parentUuid !== null);		
	}

	private populateDisplayNodes(node: ISlickTreeNodeModel, level: number) {
		if (level >= 100) {
			console.error("Exceeeded max of 100 levels deep.  Possible infinite recursion.");
			return;
		}

		const displayNode = new SlickTreeViewDisplayModel(node, level);

		if (node.expandOnClick === null || node.expandOnClick === undefined)
			node.expandOnClick = this.expandOnClick;

		if (displayNode.parentUuid === null)
			displayNode.node.canDelete = false;

		if (displayNode.uuid === this.newUuid) 
			displayNode.editingText = true;
		
		this.displayNodes.push(displayNode);

		const childNodes = this.nodes.filter(x => x.parentUuid === node.uuid) || [];
		displayNode.hasChildNodes = (childNodes.length > 0);

		if (node.expanded === true)
			childNodes.forEach(childNode => {
				if (!childNode.hidden)
					this.populateDisplayNodes(childNode, level + 1)
			});
	}

	private deleteRecursive(node: ISlickTreeNodeModel): ISlickTreeNodeModel[] {
		let removedNodes: ISlickTreeNodeModel[] = [];

		const childNodes = this.nodes.filter(x => x.parentUuid === node.uuid) || [];

		childNodes.forEach(childNode => {
			removedNodes = removedNodes.concat(this.deleteRecursive(childNode))
		});

		removedNodes.push(node);

		this.nodes = this.nodes.filter(x => x.uuid !== node.uuid);

		return removedNodes;
	}

	private processNewNode() {
		if (!this.newUuid)
			return;

		const newNode = this.nodes.find(x => x.uuid === this.newUuid);
		const displayNode = this.displayNodes.find(x => x.uuid == this.newUuid);
		// Do this so that we don't trigger the node update
		 newNode.text = displayNode.text;

		this.newUuid = null;

		if (!newNode) 
			return;		

		if (this.onNodeAdded)
			this.onNodeAdded.emit(newNode);

		if (this.nodesChange)
			this.nodesChange.emit(this.nodes);
	}
}
