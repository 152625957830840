export interface IPreShippedModel {
    preshippedGuide: boolean;
    preshippedGuideHardware: boolean;
    preshippedPolymerHousing: boolean;
    preshippedLid: boolean;
    preshippedEncapsulation: boolean;
    preshippedCorners: boolean;
    preshippedCoping: boolean;
    preshippedPolymerHousingMountingKit: boolean;
    preshippedExtrusionPackage: boolean;
}

export class PreShippedModel implements IPreShippedModel {
    preshippedGuide: boolean;
    preshippedGuideHardware: boolean;
    preshippedPolymerHousing: boolean;
    preshippedLid: boolean;
    preshippedEncapsulation: boolean;
    preshippedCorners: boolean;
    preshippedCoping: boolean;
    preshippedPolymerHousingMountingKit: boolean;
    preshippedExtrusionPackage: boolean;

}