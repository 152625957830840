import { Component, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { AuthService, GlobalsService, LoginService, SleepService } from "@services";
import { IUserInfoModel } from "@models";
import { Subscription } from "rxjs";
import { SignalRMessageStore, TextChatStore } from '@stores';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ThemeService } from "@components/theme.service";
import { ThemeStore } from "@stores";


@Component({
	selector: "side-menu",
	templateUrl: "side-menu.component.html",
	styleUrls: ['side-menu.component.scss']
})

export class SideMenuComponent implements OnInit, OnDestroy {
	pendingOrdersCount$: Subscription;
	suspendedOrdersCount$: Subscription;
	smartlistOverdueCount$: Subscription;
	textChatUnreadMessageCount$: Subscription;
	recurringBillingErrrorCount$: Subscription;
	skeditPayRecurringPaymentsErrorCount$: Subscription;
	jellyFishUnapprovedOrdersCount$: Subscription;
	themeChangedSubscription$: Subscription;

	firstName: string;
	profilePictureUrl: string;
	user: IUserInfoModel;
	userIsClockedIn: boolean;
	companyName: string;
	highlightCustomers: boolean;
	highlightJobs: boolean;
	highlightAccounting: boolean;
	highlightInventory: boolean;
	highlightPurchasing: boolean;
	highlightReports: boolean;
	highlightSettings: boolean;
	customersExpanded: boolean;
	jobsExpanded: boolean;
	accountingExpanded: boolean;
	inventoryExpanded: boolean;
	purchasingExpanded: boolean;
	reportsExpanded: boolean;
	settingsExpanded: boolean;

	isSkeditPayAdmin = GlobalsService.userInfo.roleTypeId === 1;

	isMenuExpanded: boolean = (localStorage.getItem("MENU_EXPANDED") || 'true') === 'true';

	canAccessSchedule: boolean = false;
	canAccessCustomers: boolean = false;
	canAccessCRM: boolean = false;
	canAccessJobs: boolean = false;
	canAccessRecurringJobs: boolean = false
	canAccessLathamOrders: boolean = false;
	canAccessPartsList: boolean = false;
	canAccessInventory: boolean = false;
	canAccessEstimates: boolean = false;
	canAccessSalesOrders: boolean = false;
	canAccessInvoices: boolean = false;
	canAccessPayments: boolean = false;
	canAccessTextChat: boolean = false;
	canAccessDocuments: boolean = false;
	canAccessSmartlist: boolean = false;
	canAccessReports: boolean = false;
	canAccessTimeclock: boolean = false;
	canAccessSettings: boolean = false;
	canAccessQuickbooks: boolean = false;
	canAccessUsers: boolean = false;
	canAccessVendors: boolean = false;
	canAccessPurchaseOrders: boolean = false;
	canAccessReceiving: boolean = false;
	canAccessPickList: boolean = false;
	canAccessShipping: boolean = false;

	pendingOrdersCount: number = 0;
	suspendedOrdersCount: number = 0;
	totalOrdersCount: number = 0;
	totalJellyFishOrdersCount: number = 0;
	smartlistOverdueCount: number = 0;
	textChatUnreadMessagesCount: number = 0;
	recurringBillingErrorCount: number = 0;
	skeditPayRecurringPaymentsErrorCount: number = 0;
	jellyFishUnapprovedOrdersCount: number = 0;
	logoWhite: boolean;

	isIMPC = (GlobalsService.company.companyId === 1);
	isCanada = (GlobalsService.company.companyId === 3);
	isQPro = GlobalsService.company.isQPro;
	isJellyFish = (GlobalsService.company.companyId === 133)
	isLathamOrdersOnly = GlobalsService.company.isLathamOrdersOnly;
	skeditPay: boolean = GlobalsService.company.useSkeditPay;
	isQuickbooksEnabled: boolean = GlobalsService.isQuickbooksEnabled;
	useJellyFishOrdering: boolean = GlobalsService.company.useJellyFishOrdering;
	isJellyFishTrial: boolean = GlobalsService.company.isJellyFishTrial;

	sideMenusExpanded: string[] = JSON.parse(localStorage.getItem("SUB_MENUS_EXPANDED")) || [];

	constructor(private readonly authService: AuthService,
		private readonly loginService: LoginService,
		private signalRMessageStore: SignalRMessageStore,
		private themeStore: ThemeStore,
		private router: Router,
		private location: Location,
		private textChatStore: TextChatStore) {

        this.canAccessSchedule = GlobalsService.checkPermission("schedule", "access");
        this.canAccessCustomers = GlobalsService.checkPermission("customers", "access");
        this.canAccessCRM = GlobalsService.checkPermission("crm", "access");
        this.canAccessJobs = GlobalsService.checkPermission("jobs", "access");
        this.canAccessRecurringJobs = GlobalsService.checkPermission("RecurringJobs", "access");
        this.canAccessLathamOrders = GlobalsService.company.useLathamOrdering && GlobalsService.checkPermission("latham orders", "access");
        this.canAccessPartsList = GlobalsService.checkPermission("parts list", "access");
        this.canAccessInventory = GlobalsService.company.useInventory && GlobalsService.checkPermission("inventory", "access");
        this.canAccessEstimates = GlobalsService.checkPermission("estimates", "access");
        this.canAccessSalesOrders = GlobalsService.checkPermission("SalesOrders", "access");
        this.canAccessInvoices = GlobalsService.checkPermission("invoices", "access");
        this.canAccessPayments = GlobalsService.checkPermission("payments", "access");
        this.canAccessPickList = GlobalsService.company.useInventory && GlobalsService.checkPermission("Inventory", "AccessPickList");
        this.canAccessTextChat = (GlobalsService.company.useTextChat && GlobalsService.checkPermission("textchat", "access"));
        this.canAccessDocuments = GlobalsService.checkPermission("documents", "access");
        this.canAccessSmartlist = GlobalsService.checkPermission("smartlist", "access");
        this.canAccessReports = GlobalsService.checkPermission("reports", "access");
        this.canAccessVendors = GlobalsService.company.useInventory && GlobalsService.checkPermission("vendors", "access");
        this.canAccessPurchaseOrders = GlobalsService.company.useInventory && GlobalsService.checkPermission("PurchaseOrders", "access");
        this.canAccessReceiving = GlobalsService.company.useInventory && GlobalsService.checkPermission("receiving", "access");
        this.canAccessTimeclock = GlobalsService.checkPermission("timeclock", "access");
        this.canAccessSettings = GlobalsService.checkPermission("settings", "access");
        this.canAccessQuickbooks = GlobalsService.company.quickbooksWebConnectorEnabled && GlobalsService.checkPermission("quickbooks", "access");
        this.canAccessUsers = GlobalsService.checkPermission("users", "access");
        this.canAccessShipping = GlobalsService.company.useShipping && GlobalsService.checkPermission("shipping", "access");

		this.firstName = GlobalsService.userInfo.firstName;
		this.profilePictureUrl = GlobalsService.userInfo.profilePictureUrl;
		this.companyName = GlobalsService.company.companyName;

		this.user = GlobalsService.userInfo;
	}

	async ngOnInit() {
		this.setLogoColor(GlobalsService.userInfo?.theme || 'Default');

		if (this.authService.isAuthenticated === true && GlobalsService.company.useLathamOrdering) {

			this.pendingOrdersCount$ = this.signalRMessageStore.lathamOrderPendingCount.subscribe(pendingOrdersCount => {
				this.pendingOrdersCount = pendingOrdersCount;
				this.totalOrdersCount = (this.pendingOrdersCount || 0) + (this.suspendedOrdersCount || 0);
			});

			this.suspendedOrdersCount$ = this.signalRMessageStore.lathamOrderSuspendedCount.subscribe(suspendedOrdersCount => {
				this.suspendedOrdersCount = suspendedOrdersCount;
				this.totalOrdersCount = (this.pendingOrdersCount || 0) + (this.suspendedOrdersCount || 0);
			});

		}
		this.recurringBillingErrrorCount$ = this.signalRMessageStore.recurringBillingErrorCount.subscribe(errorCount => {
			this.recurringBillingErrorCount = errorCount;
		});

		this.skeditPayRecurringPaymentsErrorCount$ = this.signalRMessageStore.skeditPayRecurringPaymentsErrorCount.subscribe(errorCount => {
			this.skeditPayRecurringPaymentsErrorCount = errorCount;
		});

		this.jellyFishUnapprovedOrdersCount$ = this.signalRMessageStore.jellyFishUnapprovedOrdersCount.subscribe(unapprovedOrders => {
			this.jellyFishUnapprovedOrdersCount = unapprovedOrders;
		});



		this.themeChangedSubscription$ = this.themeStore.themeChanged$.subscribe(themeName => {
			this.setLogoColor(themeName);
		});

		const url = this.location.path();
		this.checkHighlight(url)

		this.smartlistOverdueCount$ = this.signalRMessageStore.smartlistOverdueCount.subscribe(smartlistOverdueCount => {
			this.smartlistOverdueCount = smartlistOverdueCount;
		});

		if (GlobalsService.company.useTextChat) {
			this.textChatUnreadMessageCount$ = this.textChatStore.unreadMessagesCount$.subscribe(textChatUnreadMessagesCount => {
				this.textChatUnreadMessagesCount = textChatUnreadMessagesCount;
			});
		}
		if (this.isMenuExpanded)
			document.getElementById("main-area-container").classList.add('menu-is-open');

		if (GlobalsService.isLoggedIn &&
			GlobalsService.company.useTextChat === true &&
			GlobalsService.checkPermission("TextChat", "Access") === true) {
			// Unread messages
		}

		this.sideMenusExpanded.forEach(x => {
			if (x === "Customers") {
				this.customersExpanded = true;
			}
			else if (x === "Jobs") {
				this.jobsExpanded = true;
			}
			else if (x === "Accounting") {
				this.accountingExpanded = true;
			}
			else if (x === "Inventory") {
				this.inventoryExpanded = true;
			}
			else if (x === "Purchasing") {
				this.purchasingExpanded = true;
			}
			else if (x === "Reports") {
				this.reportsExpanded = true;
			}
			else if (x === "Settings") {
				this.settingsExpanded = true;
			}
		});

	}

	resetHighlight() {
		this.highlightCustomers = false;
		this.highlightJobs = false;
		this.highlightAccounting = false;
		this.highlightInventory = false;
		this.highlightPurchasing = false;
		this.highlightReports = false;
		this.highlightSettings = false;
	}

	toggleLocalStorage(menuOption: string) {
		let menuOptionExists = this.sideMenusExpanded.findIndex(x => x === menuOption);
		if (menuOptionExists >= 0) {
			this.sideMenusExpanded.splice(menuOptionExists, 1);
		}
		else {
			this.sideMenusExpanded.push(menuOption)
		}
		localStorage.setItem("SUB_MENUS_EXPANDED", JSON.stringify(this.sideMenusExpanded));
	}

	checkHighlight(label: string) {
		this.resetHighlight();
		switch (label) {
			case "/customers":
			case "/crm":
				this.highlightCustomers = true;
				break;
			case '/jobs':
			case '/recurring':
			case '/smartlist':
				this.highlightJobs = true;
				break;
			case '/estimates':
			case '/salesOrders':
			case '/invoices':
			case '/payments':
			case '/quickbooks':
			case '/recurring-billing':
				this.highlightAccounting = true;
				break;
			case '/inventory':
			case '/items':
			case '/pickList':
				this.highlightInventory = true;
				break;
			case '/vendors':
			case '/purchaseOrders':
			case '/receiving':
				this.highlightPurchasing = true;
				break;
			case '/reporting':
			case '/reporting/apptAveragesReport':
			case '/reporting/itemUsageReport':
			case '/reporting/itemUsageByBuilderReport':
			case '/reporting/lathamOrderInsights':
			case '/reporting/resourceApptTypesReport':
			case '/reporting/employeeApptsReport':
			case '/reporting/employeeAvgHoursWorked':
				this.highlightReports = true;
				break;
			case '/settings':
			case '/users':
				this.highlightSettings = true;
				break;


		}
	}

	toggleExpanded() {
		this.isMenuExpanded = !this.isMenuExpanded;
		document.getElementById("main-area-container").classList.toggle('menu-is-open');
		localStorage.setItem("MENU_EXPANDED", this.isMenuExpanded.toString());
	}

	ngOnDestroy() {
		if (this.pendingOrdersCount$)
			this.pendingOrdersCount$.unsubscribe();
		if (this.suspendedOrdersCount$)
			this.suspendedOrdersCount$.unsubscribe();
		if (this.smartlistOverdueCount$)
			this.smartlistOverdueCount$.unsubscribe();
		if (this.textChatUnreadMessageCount$)
			this.textChatUnreadMessageCount$.unsubscribe();
		if (this.recurringBillingErrrorCount$)
			this.recurringBillingErrrorCount$.unsubscribe();
		if (this.skeditPayRecurringPaymentsErrorCount$)
			this.skeditPayRecurringPaymentsErrorCount$.unsubscribe();
		if (this.themeChangedSubscription$) {
			this.themeChangedSubscription$.unsubscribe();
		}
	}

	private setLogoColor(themeName: string) {
		if (themeName === 'superhero' || themeName === 'slate' || themeName === 'solar') 
			this.logoWhite = true;
		else 
			this.logoWhite = false;		

	}


}