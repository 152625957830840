import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { ILoginModel } from "@models";
import { ResetPasswordModel } from "../models/login/reset-password.model";

@Injectable()
export class LoginService {
	readonly NUM_BACKGROUND_IMGS: number = 9;
	static imgName: string;

	constructor(private httpService: HttpService) { }

	async tryLogin(loginModel: ILoginModel): Promise<string> {
		return this.httpService.post("/login/validateLogin", loginModel);
	}

	async logout() {
		try {
			return await Promise.resolve(await this.httpService.get("/login/logout"));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async autoLogout() {
		try {
			return await Promise.resolve(await this.httpService.get("/login/autoLogout"));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async sendResetPassword(emailAddress: string): Promise<boolean> {
		try {
			return await Promise.resolve(await this.httpService.get(`/login/sendResetPassword?emailAddress=${emailAddress}`));
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async resetPassword(encryptionLinkId: string, password: string): Promise<boolean> {
		return await this.httpService.get(`login/resetPassword?lid=${encryptionLinkId}&password=${password}`);
	}

	async getResetPasswordModelFromLink(encryptionLinkId: string): Promise<ResetPasswordModel> {
		return this.httpService.get("/login/getResetPasswordModelFromLink", { encryptionLinkId });
	}
}