import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppointmentDimensions, AppointmentVisibility, BlockedTimeSlotDimensions, IBlockedTimeSlotModel, IScheduledAppointmentModel } from '@models';
import { ScheduleStore } from './schedule.store';
import { MiniBlockedTimeSlotDimensions } from "@models/appointments/mini-blocked-time-slot-dimensions.model";
import { GlobalsService } from './utils/globals.service';
import { LookupService } from './utils/lookup.service';

class ResourceAppointments {
	constructor(resourceId: number, appointments: IScheduledAppointmentModel[]) {
		this.resourceId = resourceId
		this.appointments = appointments;

		this.appointments.sort((a, b) => {
			if (a.scheduledDateTime < b.scheduledDateTime)
				return -1;
			else if (a.resourceId > b.resourceId)
				return 1;
			else
				return 0;
		});
	}

	resourceId: number;
	appointments: IScheduledAppointmentModel[]
}

class ResourceBlockedTimeSlots {
	constructor(resourceId: number, blockedTimeSlots: IBlockedTimeSlotModel[]) {
		this.resourceId = resourceId
		this.blockedTimeSlots = blockedTimeSlots;

		this.blockedTimeSlots.sort((a, b) => {
			if (a.blockDateTime < b.blockDateTime)
				return -1;
			else if (a.resourceId > b.resourceId)
				return 1;
			else
				return 0;
		});
	}

	resourceId: number;
	blockedTimeSlots: IBlockedTimeSlotModel[]
}


@Injectable()
export class ScheduleDisplayService {
	constructor(private scheduleStore: ScheduleStore, private lookups: LookupService) {
	}

	//*******************************************************************************************
	//***   APPOINTMENTS
	//*******************************************************************************************
	renderAllAppointmentElements(): DocumentFragment {
		const resourceAppts: ResourceAppointments[] = [];

		for (let resource of this.lookups.getResources(null, true)) {
			const appts = this.scheduleStore.appointments.filter(a => a.resourceId === resource.resourceId);
			if (resourceAppts)
				resourceAppts.push(new ResourceAppointments(resource.resourceId, appts));
		}

		// Load everything into the doc fragment
		const docFragment = document.createDocumentFragment();

		resourceAppts.forEach(resourceAppts => {
			let currentDate = moment("1/1/1900", 'M/D/YYYY');
			let resourceLine: HTMLDivElement = null;

			resourceAppts.appointments.forEach(scheduledAppointment => {
				const scheduledDate = moment(scheduledAppointment.scheduledDateTime).startOf("day");
				if (currentDate.isSame(scheduledDate) === false) {
					resourceLine = this.scheduleStore.getResourceLine(resourceAppts.resourceId, scheduledAppointment.scheduledDateTime);
					currentDate = moment(scheduledDate);
				}

				if (resourceLine) {
					const element = this.createNewApptDiv(scheduledAppointment, resourceLine);

					if (element)
						docFragment.appendChild(element);
				}
			});
		});

		return docFragment;
	}

	renderAppointment(appt: IScheduledAppointmentModel, apptBlockDiv: HTMLDivElement = null): HTMLDivElement {
		// if the status says to not show this appt on the schedule, we can just return
		if (appt.showOnSchedule === false) 
			return null;
		
		const resourceLine = this.scheduleStore.getResourceLine(appt.resourceId, appt.scheduledDateTime);
		if (!resourceLine)
			return null;

		const newApptDiv = this.createNewApptDiv(appt, resourceLine);
		if (!newApptDiv)
			return null;

		if (apptBlockDiv === null) {
			const appointments = document.querySelector('.schedule .appointments');
			appointments.appendChild(newApptDiv);

			return newApptDiv;
		} else {
			apptBlockDiv.style.top = newApptDiv.style.top;
			apptBlockDiv.style.left = newApptDiv.style.left;
			apptBlockDiv.innerHTML = newApptDiv.innerHTML;
			apptBlockDiv.classList.remove('start-partial', 'end-partial');
			if (newApptDiv.classList.contains('start-partial')) apptBlockDiv.classList.add('start-partial');

			if (newApptDiv.classList.contains('end-partial')) apptBlockDiv.classList.add('end-partial');

			return apptBlockDiv;
		}
	}

	removeAllAppointments() {
		this.scheduleStore.appointments.forEach(x => {
			this.removeAppointment(x.appointmentId);
		})
	}

	removeAppointment(appointmentId: number) {
		const appointment = document.querySelector(`.schedule .appointments .appointment-block[data-apptid='${appointmentId}']`);
		if (appointment)
			appointment.remove();
	}

	private createNewApptDiv(appt: IScheduledAppointmentModel, resourceLine: HTMLDivElement): HTMLDivElement {
		if (moment(appt.appointmentBlockStart).isBefore(this.scheduleStore.visibleStartDate) &&
			moment(appt.appointmentBlockEnd).isBefore(this.scheduleStore.visibleStartDate)) {
			return null;
		}

		if (moment(appt.appointmentBlockStart).isAfter(this.scheduleStore.visibleEndDate) &&
			moment(appt.appointmentBlockEnd).isAfter(this.scheduleStore.visibleEndDate)) {
			return null;
		}

		const apptVisibility = new AppointmentVisibility(appt, this.scheduleStore.visibleStartHour, this.scheduleStore.visibleEndHour);
		if (apptVisibility.isVisible === false)
			return null;

		const apptBlockDiv = <HTMLDivElement>document.createElement('div');
		const apptDimensions: AppointmentDimensions = AppointmentDimensions.getAppointmentDimensions(resourceLine, apptVisibility);

		apptBlockDiv.classList.add('appointment-block');
		apptBlockDiv.classList.add('rounded');
		if (!GlobalsService.company.largeScheduleRows)
			apptBlockDiv.classList.add('appointment-block-sm');
		apptBlockDiv.setAttribute('data-apptid', appt.appointmentId.toString());
		apptBlockDiv.style.top = apptDimensions.blockTopPx;
		apptBlockDiv.style.left = apptDimensions.blockLeftPx;
		if (apptVisibility.startPartial) apptBlockDiv.classList.add('start-partial');

		if (apptVisibility.endPartial) apptBlockDiv.classList.add('end-partial');

		if (appt.travelMinutes && apptVisibility.isTravelVisible) {
			const travelDiv = <HTMLDivElement>document.createElement('div');
			travelDiv.classList.add('travel');
			if (appt.removeServiceAreaColor) {
				const completeApptStatus = this.lookups.getAppointmentStatuses().find(x => x.description === 'Complete');
				appt.serviceAreaColor = completeApptStatus.scheduleColor;
				appt.serviceAreaColor = this.getColor('#cccccc');
			}
			travelDiv.style.backgroundColor = this.getColor(appt.serviceAreaColor);
			travelDiv.style.width = apptDimensions.travelWidthPx;
			travelDiv.style.borderRight = '1px solid #666';
			apptBlockDiv.append(travelDiv);
		}

		if (appt.scheduledMinutes && apptVisibility.isScheduledApptVisibile) {
			const apptDiv = <HTMLDivElement>document.createElement('div');
			apptDiv.classList.add('appointment');
			apptDiv.style.backgroundColor = this.getColor(appt.appointmentStatusColor);
			apptDiv.style.width = apptDimensions.appointmentWidthPx;
			if (apptDimensions.textPadding)
				apptDiv.style.paddingLeft = "3px";
			const optimalRouteText = (appt.optimalRouteSeq ?? 0) === 0 ? '' : appt.optimalRouteSeq;
			// flagNonSignedServiceAgreement is badly named. Should be showSignedServiceAgreement.
			const acceptedTermsText = (GlobalsService.company.flagNonSignedServiceAgreement && appt.acceptedTerms) ? '<span class="far fa-check-circle" style="color: black"></span>' : '';
			const recurringApptText = (appt.recurringAppointmentId !== null) ? '<span class="far fa-repeat"></span>' : '';
			const apptConfirmedText = (appt.isConfirmed) ? '<span class="far fa-circle-c"></span>' : '';
			const apptLockedText = (appt.locked) ? '<span class="far fa-lock"></span>' : '';
			const apptGoBackText = (appt.goBack) ? '<span class="far fa-backward"></span>' : '';
			const creditHoldText = (appt.creditHold) ? '<span style="color: #FF0000;">[CH]</span>' : '';

			apptDiv.innerHTML = `${acceptedTermsText} ${optimalRouteText} ${recurringApptText} ${apptConfirmedText} ${apptLockedText} ${apptGoBackText} ${creditHoldText} ${appt.jobDisplayName} | ${appt.jobCity} | ${appt.appointmentTypeDescription}`;

			if (appt.creditHold)
				apptBlockDiv.style.border = '1px solid #FF0000';
			else if (GlobalsService.userInfo.showAppointmentBorder)
				apptBlockDiv.style.border = '1px solid black';
			else
				apptBlockDiv.style.border = '1px solid ' + appt.borderColor;

			if (appt.billToDisplayName && appt.billToDisplayName !== appt.jobDisplayName) apptDiv.innerHTML += '<br>' + appt.billToDisplayName;

			if (appt.description && GlobalsService.company.showAppointmentDescriptionOnSchedule) {
				apptDiv.innerHTML += `
				<span style="font-size: 10px; line-height: 10px">
					<br />${appt.description}
				</span>`;
			}

			apptBlockDiv.append(apptDiv);
		}

		if (!appt.locked) {
			if (apptVisibility.startPartial === false) {
				const apptResizeStartDiv = <HTMLDivElement>document.createElement('div');
				apptResizeStartDiv.classList.add('resize', 'resize-start');
				apptBlockDiv.append(apptResizeStartDiv);
			}

			if (appt.travelMinutes > 0 && apptVisibility.isTravelVisible) {
				const apptResizeTravelDiv = <HTMLDivElement>document.createElement('div');
				apptResizeTravelDiv.classList.add('resize', 'resize-travel');
				apptResizeTravelDiv.style.left = apptDimensions.resizeTravelPx;
				apptBlockDiv.append(apptResizeTravelDiv);
			}

			if (apptVisibility.endPartial === false) {
				const apptResizeEndDiv = <HTMLDivElement>document.createElement('div');
				apptResizeEndDiv.classList.add('resize', 'resize-end');
				apptResizeEndDiv.style.right = '0';
				apptBlockDiv.append(apptResizeEndDiv);
			}
		}

		return apptBlockDiv;
	}

	//*******************************************************************************************
	//***   MINI-SCHEDULE APPOINTMENTS
	//*******************************************************************************************
	renderAllMiniScheduleAppointments(): DocumentFragment {
		let lastResourceId: number = null;
		let lastCalcDate: string = null;
		let resourceDiv: HTMLDivElement = null;

		// Load everything into the doc fragment
		const docFragment = document.createDocumentFragment();

		this.scheduleStore.miniScheduleAppointments.forEach(appt => {
			const calcDate = moment(appt.scheduledDateTime).format('YYYYMMDD');

			if (lastResourceId !== appt.resourceId || lastCalcDate !== calcDate) {
				resourceDiv = this.scheduleStore.getMiniScheduleResourceRow(appt.resourceId, calcDate);
				lastResourceId = appt.resourceId;
				lastCalcDate = calcDate;
			}

			if (resourceDiv) {
				const element = this.createNewMiniApptDiv(appt);

				if (element) docFragment.appendChild(element);
			}
		});

		return docFragment;
	}

	renderMiniScheduleAppointment(appt: IScheduledAppointmentModel) {
		// if the status says to not show this appt on the schedule, we can just return
		if (appt.showOnSchedule === false)
			return null;

		if (this.scheduleStore.miniScheduleVisible === true) {
			this.removeMiniScheduleAppointment(appt.appointmentId);
			const newApptDiv = this.createNewMiniApptDiv(appt);
			if (newApptDiv) {
				const appointments = document.querySelector('.mini-schedule .mini-schedule-appointments');
				appointments.appendChild(newApptDiv);
			}
		}
	}

	removeMiniScheduleAppointment(appointmentId: number) {
		if (this.scheduleStore.miniScheduleVisible === true) {
			const miniScheduleAppointment = document.querySelector(`.mini-schedule .mini-schedule-appointments .mini-schedule-appointment-block[data-apptid='${appointmentId}']`);

			if (miniScheduleAppointment) miniScheduleAppointment.remove();
		}
	}

	createNewMiniApptDiv(appt: IScheduledAppointmentModel): HTMLDivElement {
		if (moment(appt.appointmentBlockStart).isBefore(this.scheduleStore.miniScheduleStartDate) &&
			moment(appt.appointmentBlockEnd).isBefore(this.scheduleStore.miniScheduleEndDate)) {
			return null;
		}

		if (moment(appt.appointmentBlockStart).isAfter(this.scheduleStore.miniScheduleEndDate) &&
			moment(appt.appointmentBlockEnd).isAfter(this.scheduleStore.miniScheduleStartDate)) {
			return null;
		}

		const apptVisibility = new AppointmentVisibility(appt, this.scheduleStore.visibleStartHour, this.scheduleStore.visibleEndHour);
		if (apptVisibility.isVisible === false) return null;

		const calcDate = moment(appt.scheduledDateTime).format('YYYYMMDD');
		const dateCell = <HTMLDivElement>this.scheduleStore.miniScheduleTable.querySelector(`[data-calcdate='${calcDate}']`);
		const resourceDiv = <HTMLDivElement>dateCell.querySelector(`[data-resourceid='${appt.resourceId}']`);

		const visibleEndHour = this.scheduleStore.visibleEndHour === 24 ? this.scheduleStore.visibleEndHour : this.scheduleStore.visibleEndHour + 1;
		const totalTimeSlots = (visibleEndHour - this.scheduleStore.visibleStartHour) * 4;
		const timeSlotWidth = (resourceDiv.offsetWidth - 2) / totalTimeSlots;
		const visibleStartDate = moment(apptVisibility.appointmentBlockStart)
			.startOf('day')
			.add(this.scheduleStore.visibleStartHour, 'hours');

		const apptBlockDiv = <HTMLDivElement>document.createElement('div');
		apptBlockDiv.classList.add('mini-schedule-appointment-block');
		apptBlockDiv.setAttribute('data-apptid', appt.appointmentId.toString());
		apptBlockDiv.style.top = this.scheduleStore.miniScheduleTable.offsetTop + dateCell.offsetTop + resourceDiv.offsetTop + 'px';
		apptBlockDiv.style.left = (apptVisibility.appointmentBlockStart.diff(visibleStartDate, 'minutes') / 15) * timeSlotWidth + dateCell.offsetLeft + 2 + 'px';

		//const apptBlockStartTimeSlot = this.getTimeSlot(moment(appt.scheduledDateTime));

		if (apptVisibility.startPartial) apptBlockDiv.classList.add('start-partial');

		if (apptVisibility.endPartial) apptBlockDiv.classList.add('end-partial');

		if (appt.travelMinutes && apptVisibility.isTravelVisible) {
			const travelDiv = <HTMLDivElement>document.createElement('div');
			travelDiv.classList.add('travel');
			if (appt.removeServiceAreaColor) {
				this.lookups.getAppointmentStatuses().forEach(color => {
					if (color.description === 'Complete') {
						appt.serviceAreaColor = color.scheduleColor;
					}
				});
			}
			travelDiv.style.backgroundColor = this.getColor(appt.serviceAreaColor);
			travelDiv.style.width = (apptVisibility.travelMinutes / 15) * timeSlotWidth + 'px';
			apptBlockDiv.append(travelDiv);
		}

		if (appt.scheduledMinutes && apptVisibility.isScheduledApptVisibile) {
			const apptDiv = <HTMLDivElement>document.createElement('div');
			apptDiv.classList.add('appointment');
			apptDiv.style.backgroundColor = this.getColor(appt.appointmentStatusColor);
			apptBlockDiv.style.border = appt.creditHold ? '1px solid #FF0000' : (appt.showBorderOnMiniSchedule ? '1px solid ' + appt.borderColor : '');
			apptDiv.style.width = (apptVisibility.scheduledMinutes / 15) * timeSlotWidth + 'px';
			apptBlockDiv.append(apptDiv);
		}

		return apptBlockDiv;
	}

	//*******************************************************************************************
	//***   BLOCKED TIME SLOTS
	//*******************************************************************************************
	renderAllBlockedTimeSlots(): DocumentFragment {
		const resourceBlockedTimeSlots: ResourceBlockedTimeSlots[] = [];

		for (let resource of this.lookups.getResources(null, true)) {
			const blockedTimeSlots = this.scheduleStore.blockedTimeSlots.filter(a => a.resourceId === resource.resourceId);
			if (resourceBlockedTimeSlots)
				resourceBlockedTimeSlots.push(new ResourceBlockedTimeSlots(resource.resourceId, blockedTimeSlots));
		}

		// Load everything into the doc fragment
		const docFragment = document.createDocumentFragment();

		resourceBlockedTimeSlots.forEach(resourceAppts => {
			let currentDate = moment("1/1/1900", 'M/D/YYYY');
			let resourceLine: HTMLDivElement = null;

			resourceAppts.blockedTimeSlots.forEach(blockedTimeSlot => {
				const scheduledDate = moment(blockedTimeSlot.blockDateTime).startOf("day");
				if (currentDate.isSame(scheduledDate) === false) {
					resourceLine = this.scheduleStore.getResourceLine(blockedTimeSlot.resourceId, blockedTimeSlot.blockDateTime);
					currentDate = moment(scheduledDate);
				}

				if (resourceLine) {
					const element = this.createNewBlockedTimeSlotDiv(blockedTimeSlot, resourceLine);

					if (element) docFragment.appendChild(element);
				}
			});
		});

		return docFragment;
	}

	renderBlockedTimeSlot(blockedTimeSlot: IBlockedTimeSlotModel, blockedTimeSlotDiv: HTMLDivElement = null) {
		const resourceLine = this.scheduleStore.getResourceLine(blockedTimeSlot.resourceId, blockedTimeSlot.blockDateTime);

		var newBlockedTimeSlotDiv = this.createNewBlockedTimeSlotDiv(blockedTimeSlot, resourceLine);
		if (!blockedTimeSlotDiv) {
			const blockedTimeSlots = document.querySelector('.schedule .blocked-time-slots');
			blockedTimeSlots.appendChild(newBlockedTimeSlotDiv);
		} else {
			blockedTimeSlotDiv.outerHTML = newBlockedTimeSlotDiv.outerHTML;

			//blockedTimeSlotDiv.style.top = newBlockedTimeSlotDiv.style.top;
			//blockedTimeSlotDiv.style.left = newBlockedTimeSlotDiv.style.left;
			//blockedTimeSlotDiv.innerHTML = newBlockedTimeSlotDiv.innerHTML;
			//blockedTimeSlotDiv.classList.remove("start-partial", "end-partial");
			//if (newBlockedTimeSlotDiv.classList.contains("start-partial"))
			//	blockedTimeSlotDiv.classList.add("start-partial");

			//if (newBlockedTimeSlotDiv.classList.contains("end-partial"))
			//	blockedTimeSlotDiv.classList.add("end-partial");
		}
	}

	removeBlockedTimeSlot(blockedTimeSlotId: number) {
		const blockedTimeSlot = document.querySelector(`.schedule .blocked-time-slots .blocked-time-slot[data-blockedtimeslotid='${blockedTimeSlotId}']`);

		if (blockedTimeSlot) blockedTimeSlot.remove();
	}

	private createNewBlockedTimeSlotDiv(blockedTimeSlot: IBlockedTimeSlotModel, resourceLine: HTMLDivElement): HTMLDivElement {
		const blockedTimeSlotDimensions = new BlockedTimeSlotDimensions(resourceLine, blockedTimeSlot, this.scheduleStore.visibleStartHour, this.scheduleStore.visibleEndHour);

		if (!blockedTimeSlotDimensions.isVisible) return null;

		const blockedTimeSlotDiv = <HTMLDivElement>document.createElement('div');

		blockedTimeSlotDiv.classList.add('blocked-time-slot');
		if (!GlobalsService.company.largeScheduleRows)
			blockedTimeSlotDiv.classList.add('blocked-time-slot-sm');
		blockedTimeSlotDiv.setAttribute('data-blockedtimeslotid', blockedTimeSlot.blockedTimeSlotId.toString());
		blockedTimeSlotDiv.innerHTML = blockedTimeSlot.text;

		blockedTimeSlotDiv.style.top = blockedTimeSlotDimensions.topPx;
		blockedTimeSlotDiv.style.left = blockedTimeSlotDimensions.leftPx;
		blockedTimeSlotDiv.style.minWidth = blockedTimeSlotDimensions.widthPx;
		blockedTimeSlotDiv.style.maxWidth = blockedTimeSlotDimensions.widthPx;

		if (blockedTimeSlotDimensions.startPartial) blockedTimeSlotDiv.classList.add('start-partial');

		if (blockedTimeSlotDimensions.endPartial) blockedTimeSlotDiv.classList.add('end-partial');

		return blockedTimeSlotDiv;
	}

	//*******************************************************************************************
	//***   MINI BLOCKED TIME SLOTS
	//*******************************************************************************************

	renderAllMiniBlockedTimeSlots(): DocumentFragment {
		const sortedBlockedTimeSlots = this.scheduleStore.miniScheduleBlockedTimeSlots;

		sortedBlockedTimeSlots.sort(function (a, b) {
			if (a.resourceId < b.resourceId)
				//sort string ascending
				return -1;
			if (a.resourceId > b.resourceId) return 1;
			return 0; //default return value (no sorting)
		});

		let currentResourceId = 0;
		let resourceRow = null;

		// Load everything into the doc fragment
		const docFragment = document.createDocumentFragment();

		sortedBlockedTimeSlots.forEach(blockedTimeSlot => {
			const calcDate = moment(blockedTimeSlot.blockDateTime).format('YYYYMMDD');
			if (currentResourceId !== blockedTimeSlot.resourceId) {
				resourceRow = this.scheduleStore.getMiniScheduleResourceRow(blockedTimeSlot.resourceId, calcDate);
				currentResourceId = blockedTimeSlot.resourceId;
			}

			if (resourceRow) {
				const element = this.createNewMiniBlockedTimeSlotDiv(blockedTimeSlot, resourceRow, calcDate);

				if (element) docFragment.appendChild(element);
			}
		});

		return docFragment;
	}

	renderMiniBlockedTimeSlot(blockedTimeSlot: IBlockedTimeSlotModel, blockedTimeSlotDiv: HTMLDivElement = null) {
		const calcDate = moment(blockedTimeSlot.blockDateTime).format('YYYYMMDD');
		const resourceRow = this.scheduleStore.getMiniScheduleResourceRow(blockedTimeSlot.resourceId, calcDate);

		var newBlockedTimeSlotDiv = this.createNewMiniBlockedTimeSlotDiv(blockedTimeSlot, resourceRow, calcDate);
		if (!blockedTimeSlotDiv) {
			const blockedTimeSlots = document.querySelector('.mini-schedule .mini-schedule-blocked-time-slots');
			blockedTimeSlots.appendChild(newBlockedTimeSlotDiv);
		} else {
			blockedTimeSlotDiv.outerHTML = newBlockedTimeSlotDiv.outerHTML;

			//blockedTimeSlotDiv.style.top = newBlockedTimeSlotDiv.style.top;
			//blockedTimeSlotDiv.style.left = newBlockedTimeSlotDiv.style.left;
			//blockedTimeSlotDiv.innerHTML = newBlockedTimeSlotDiv.innerHTML;
			//blockedTimeSlotDiv.classList.remove("start-partial", "end-partial");
			//if (newBlockedTimeSlotDiv.classList.contains("start-partial"))
			//	blockedTimeSlotDiv.classList.add("start-partial");

			//if (newBlockedTimeSlotDiv.classList.contains("end-partial"))
			//	blockedTimeSlotDiv.classList.add("end-partial");
		}
	}

	removeMiniBlockedTimeSlot(blockedTimeSlotId: number) {
		const blockedTimeSlot = document.querySelector(`.mini-schedule .mini-schedule-blocked-time-slots .mini-blocked-time-slot[data-blockedtimeslotid='${blockedTimeSlotId}']`);

		if (blockedTimeSlot) blockedTimeSlot.remove();
	}

	private createNewMiniBlockedTimeSlotDiv(blockedTimeSlot: IBlockedTimeSlotModel, resourceRow: HTMLDivElement, calcDate: string): HTMLDivElement {
		const blockStart = moment(blockedTimeSlot.blockDateTime);
		const blockEnd = moment(blockedTimeSlot.blockDateTime).add(blockedTimeSlot.duration, "minutes");

		if (blockStart.isBefore(this.scheduleStore.miniScheduleStartDate) &&
			blockEnd.isBefore(this.scheduleStore.miniScheduleEndDate)) {
			return null;
		}

		if (blockStart.isAfter(this.scheduleStore.miniScheduleEndDate) &&
			blockEnd.isAfter(this.scheduleStore.miniScheduleStartDate)) {
			return null;
		}

		const blockedTimeSlotDimensions = new MiniBlockedTimeSlotDimensions(resourceRow, this.scheduleStore.miniScheduleTable, blockedTimeSlot, this.scheduleStore.visibleStartHour, this.scheduleStore.visibleEndHour, calcDate);

		if (!blockedTimeSlotDimensions.isVisible)
			return null;

		const blockedTimeSlotDiv = <HTMLDivElement>document.createElement('div');

		blockedTimeSlotDiv.classList.add('mini-blocked-time-slot');
		blockedTimeSlotDiv.setAttribute('data-blockedtimeslotid', blockedTimeSlot.blockedTimeSlotId.toString());

		blockedTimeSlotDiv.style.top = blockedTimeSlotDimensions.topPx;
		blockedTimeSlotDiv.style.left = blockedTimeSlotDimensions.leftPx;
		blockedTimeSlotDiv.style.width = blockedTimeSlotDimensions.widthPx;

		if (blockedTimeSlotDimensions.startPartial) blockedTimeSlotDiv.classList.add('start-partial');

		if (blockedTimeSlotDimensions.endPartial) blockedTimeSlotDiv.classList.add('end-partial');

		return blockedTimeSlotDiv;
	}

	//*******************************************************************************************
	//*** PRIVATE FUNCTIONS
	//*******************************************************************************************
	turnOffPointerEvents() {
		const appointmentsDiv = document.querySelector('.schedule .appointments');
		appointmentsDiv.classList.add('no-pointer-events');

		const blockedTimeSlotsDiv = document.querySelector('.schedule .blocked-time-slots');
		blockedTimeSlotsDiv.classList.add('no-pointer-events');
	}

	turnOnPointerEvents() {
		const appointmentsDiv = document.querySelector('.schedule .appointments');
		if (appointmentsDiv) {
			appointmentsDiv.classList.remove('no-pointer-events');
		}

		const blockedTimeSlotsDiv = document.querySelector('.schedule .blocked-time-slots');
		if (blockedTimeSlotsDiv) {
			blockedTimeSlotsDiv.classList.remove('no-pointer-events');
		}
	}

	private getColor(color: string): string {
		color = color || '#cccccc';
		if (color[0] !== '#') return color;

		color = 'rgba(' + parseInt(color.substring(1, 3), 16) + ', ' + parseInt(color.substring(3, 5), 16) + ', ' + parseInt(color.substring(5, 7), 16) + ', .80)';
		return color;
	}
}
