import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { ISlickGridColumnModel } from "./slick-grid-column.model";
import { SlickGridRequestModel } from "./slick-grid-request.model";
import { ISlickGridFavoriteModel } from './slick-grid-favorite.model';

export interface ISlickGridOptions {
	gridKey: string;
	rowHeight: number[];

	getUrl: string;
	httpHeaders: HttpHeaders;
	requestModel: SlickGridRequestModel;
	columns: ISlickGridColumnModel[];
	favorites: ISlickGridFavoriteModel[];
	onRowSelectCallback: Function;
	bordered: boolean;
	striped: boolean;
	hover: boolean;
	autoSelectFirstRow: boolean;
	defaultRecordsPerPage: number;
}

export class SlickGridOptions  implements ISlickGridOptions{
	public gridKey: string;
	public rowHeight: number[];

	public getUrl: string;
	public httpHeaders: HttpHeaders;
	public requestModel: SlickGridRequestModel;
	public columns: ISlickGridColumnModel[];
	public favorites: ISlickGridFavoriteModel[];
	public onRowSelectCallback: Function;
	public bordered: boolean = null;
	public striped: boolean = null;
	public hover: boolean = null;
	public autoSelectFirstRow: boolean = true;
	public defaultRecordsPerPage: number;

	constructor() {
		this.columns = [];
		this.httpHeaders = new HttpHeaders();
	}
}