<div class="ms-2 mt-2">
	<div *ngIf="!logs">
		Loading <div class="waiting-gif"></div>
	</div>
	<div *ngIf="logs && logs.length === 0">
		No Logs
	</div>

	<table *ngIf="logs && logs.length > 0" class="table table-striped table-sm table-bordered" style="width: 100%; table-layout: fixed; font-size: 12px;">
		<colgroup>
		  <col style="width: 180px" />
		  <col />
		</colgroup>
	  
		<tbody>
		  <tr *ngFor="let log of logs" style="height: 10px;">
			<td style="border-right: 3px solid white; text-align: left; padding-left:10px; padding-top: 12px; padding-bottom: 12px;">
			  <span class="ms-2" style="font-size: 16px; font-weight: bold;">{{log.userName}}</span><br />
			  <span class="ms-2" style="color: var(--bs-gray-600);">{{log.logDate | date:'M/dd/yy - h:mm a'}}</span>
			</td>
	  
			<td style="font-size: 14px; padding-left:30px; padding-top: 14px; padding-bottom: 14px;" class="mb-2">
			  <span style="font-weight: 500;">{{log.logText}}</span>
			  <div *ngFor="let logDetail of log.systemLogDetails" style="color: var(--bs-gray-600); font-size: 12px; padding-left: 7px;">
				●&nbsp;&nbsp;&nbsp;{{ logDetail.detailText }}
			  </div>
			</td>
		  </tr>
		</tbody>
	  </table>
</div>
