import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { IDocumentModel, IDropdownModel, ITextChatConversationModel, ITextChatMessageModel, ITextChatTemplateModel, TextChatAttendantModel, TextChatMessageModel } from "@models";
import { TextChatStore } from "@stores";
import { Subscription } from "rxjs";
import { GlobalsService, LookupService, SleepService, UtilsService } from "@services";
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { SlickDialogComponent, SlickPhotoGalleryComponent, SlickPhotoGalleryModel } from "@slick-components";

import { sortBy } from "sort-by-typescript";
import * as moment from 'moment';

class AttendantModel {
    userId: number;
    userFullName: string;
    profilePictureUrl: string;
    isAttendant: boolean;
}

@Component({
    selector: "text-chat-messages",
    templateUrl: "./text-chat-messages.component.html",
    styleUrls: ['./text-chat-messages.component.css']
})

export class TextChatMessagesComponent implements OnInit, OnChanges, OnDestroy {
    @Input() isDialog: boolean;

    @ViewChild("mainWindowRef", { static: false }) mainWindowRef: ElementRef;
    @ViewChild('autosize') autosize: CdkTextareaAutosize;
    @ViewChild("editAttendantsDialogRef") editAttendantsDialogRef: SlickDialogComponent;
    @ViewChild("addNoteDialogRef") addNoteDialogRef: SlickDialogComponent;
    @ViewChild("photoGalleryRef") photoGalleryRef: SlickPhotoGalleryComponent;

    private fnDocumentClick = e => this.documentClick(e);

    conversation: ITextChatConversationModel;
    activeConversation$: Subscription;
    newMessage$: Subscription;
    closeConversation$: Subscription;
    reopenConversation$: Subscription;

    userId: number = GlobalsService.userInfo.userId;
    newMessage: string;
    spinnerStatus: string;
    editAttendantsSpinnerStatus: string;
    userNote: string;

    attendants: AttendantModel[] = [];
    templates: ITextChatTemplateModel[] = [];
    templatesExpanded: boolean = false;

    photos: SlickPhotoGalleryModel[];

    constructor(private textChatStore: TextChatStore,
        private lookupService: LookupService) {
        document.addEventListener('click', this.fnDocumentClick, true);

    }

    documentClick(e: Event) {
        const targetEl = <HTMLElement>e.target;
        if (UtilsService.checkParentClassExists(targetEl, "templates"))
            return;

        if (UtilsService.checkParentClassExists(targetEl, "template-button"))
            return;

        this.templatesExpanded = false;
	}

    onKeyDown(e: KeyboardEvent) {
        if (e.which === 13) {
            e.preventDefault();
            e.stopPropagation();
            this.sendMessage();
        }
    }

    async ngOnInit() {
        this.activeConversation$ = this.textChatStore.activeConversation$.subscribe(conv => {
            this.conversation = conv
            this.spinnerStatus = "reset";
            this.scrollToBottom();

            if (!this.conversation)
                return;

            const users = this.lookupService.getUsers();

            this.attendants = users
                .filter(x => x.active === true && x.canAccessTextChat === true)
                .map(u => {
                    const attendant = new AttendantModel();
                    attendant.userId = u.userId;
                    attendant.userFullName = u.fullName;
                    attendant.profilePictureUrl = u.profilePictureUrl;
                    attendant.isAttendant = (this.conversation.attendants.findIndex(a => a.userId === u.userId) >= 0)

                    return attendant;
                });

            this.attendants.sort(sortBy("userFullName^"));
        });

        this.newMessage$ = this.textChatStore.newMessage$.subscribe(message => {
            if (!this.conversation)
                return;

            if (message.textChatConversationId === this.conversation.textChatConversationId) {
                // see if this message has already been added
                if (!this.conversation.messages.find(x => x.uuid === message.uuid)) {
                    this.conversation.messages = [...this.conversation.messages, message];
                    this.scrollToBottom();
                }
            }
        })

        this.closeConversation$ = this.textChatStore.closeConversation$.subscribe(conv => {
            this.conversation.isClosed = conv.isClosed;
            this.conversation.closedDate = conv.closeDate;
            this.conversation.closedBy = conv.closedBy;
        });

        this.reopenConversation$ = this.textChatStore.reopenConversation$.subscribe(conv => {
            this.conversation.isClosed = false;
            this.conversation.closedDate = null;
            this.conversation.closedBy = null;
        });

        this.templates = await this.textChatStore.getTemplates(false);
    }

    ngOnChanges() {
        this.isDialog = (this.isDialog || false).toString() === 'true';
	}

    ngOnDestroy() {
        if (this.activeConversation$)
            this.activeConversation$.unsubscribe();
        if (this.newMessage$)
            this.newMessage$.unsubscribe();
        if (this.closeConversation$)
            this.closeConversation$.unsubscribe();
        if (this.reopenConversation$)
            this.reopenConversation$.unsubscribe();

        document.removeEventListener('click', this.fnDocumentClick, true);
    }

    async onAssignedToChanged(assignedTo: IDropdownModel) {
        await SleepService.sleep();

        this.textChatStore.updateAssignedTo(this.conversation);
    }

    async editAttendants() {
        this.editAttendantsDialogRef.showDialog();
    }

    removeAllAttendants() {
        this.attendants.forEach(x => x.isAttendant = false);
    }

    async onEditAttendantsOk() {
        this.editAttendantsSpinnerStatus = "spin";
        await SleepService.sleep(500);

        try {
            this.conversation.attendants = this.attendants
                .filter(x => x.isAttendant === true)
                .map(x => {
                    const attendant = new TextChatAttendantModel()
                    attendant.userId = x.userId;
                    attendant.userFullName = x.userFullName;
                    attendant.profilePictureUrl = x.profilePictureUrl;

                    return attendant;
                })
            await this.textChatStore.updateAttendants(this.conversation);

            this.editAttendantsSpinnerStatus = "ok";
            await SleepService.sleep(500);

            this.editAttendantsDialogRef.hideDialog();
        }
        catch {
            this.editAttendantsSpinnerStatus = "error";
        }
    }

    onEditAttendantsCancel() {
        this.editAttendantsDialogRef.hideDialog();
    }

    onAddNote() {
        this.userNote = null;
        this.addNoteDialogRef.showDialog();
    }

    async onAddNoteOk() {
        if (this.userNote) {
            const noteMessage = new TextChatMessageModel();
            noteMessage.uuid = UtilsService.newGuid();
            noteMessage.textChatConversationId = this.conversation.textChatConversationId;
            noteMessage.sentDate = moment().utc().toDate();
            noteMessage.isSystemMessage = false;
            noteMessage.isNote = true;
            noteMessage.body = this.userNote;
            noteMessage.senderId = GlobalsService.userInfo.userId;
            noteMessage.sender = {
                userId: GlobalsService.userInfo.userId,
                userFullName: GlobalsService.userInfo.fullName,
                profilePictureUrl: GlobalsService.userInfo.profilePictureUrl
            };

            this.conversation.messages = [...this.conversation.messages, noteMessage];
            this.textChatStore.sendMessage(noteMessage);
        }

        this.scrollToBottom();

        this.addNoteDialogRef.hideDialog();
    }

    onAddNoteCancel() {
        this.addNoteDialogRef.hideDialog();
	}

    async sendMessage() {
        if (this.newMessage.toLowerCase().indexOf('note:') === 0) {
            this.userNote = this.newMessage;
            this.userNote = this.userNote.replace("note: ", "");
            this.userNote = this.userNote.replace("note:", "");

            this.onAddNoteOk();

            this.newMessage = null;
            this.autosize.reset();

            return;
        }

        if (this.conversation.isClosed) {
            const reopenedMessage = new TextChatMessageModel();
            reopenedMessage.uuid = UtilsService.newGuid();
            reopenedMessage.textChatConversationId = this.conversation.textChatConversationId;
            reopenedMessage.sentDate = moment().utc().toDate();
            reopenedMessage.isSystemMessage = true;
            reopenedMessage.body = `Chat Re-Opened by ${GlobalsService.userInfo.fullName}`;
            this.conversation.messages = [...this.conversation.messages, reopenedMessage];
            this.textChatStore.addSystemMessage(reopenedMessage);
        }

        const newMessage = new TextChatMessageModel();
        newMessage.uuid = UtilsService.newGuid();
        newMessage.textChatConversationId = this.conversation.textChatConversationId;
        newMessage.sentDate = moment().utc().toDate();
        newMessage.senderId = GlobalsService.userInfo.userId;
        newMessage.sender = {
            userId: GlobalsService.userInfo.userId,
            userFullName: GlobalsService.userInfo.fullName,
            profilePictureUrl: GlobalsService.userInfo.profilePictureUrl
        };
        newMessage.body = this.newMessage;

        this.conversation.messages = [...this.conversation.messages, newMessage];

        this.scrollToBottom();

        this.newMessage = null;
        this.autosize.reset();

        this.textChatStore.sendMessage(newMessage);

    }

    async closeConversation() {
        this.spinnerStatus = "spin";
        await SleepService.sleep(500);

        try {
            const closedMessage = new TextChatMessageModel();
            closedMessage.uuid = UtilsService.newGuid();
            closedMessage.textChatConversationId = this.conversation.textChatConversationId;
            closedMessage.sentDate = moment().utc().toDate();
            closedMessage.isSystemMessage = true;
            closedMessage.body = `Chat closed by ${GlobalsService.userInfo.fullName}`;
            this.conversation.messages = [...this.conversation.messages, closedMessage];
            this.textChatStore.addSystemMessage(closedMessage);

            await this.textChatStore.closeConversation(this.conversation.textChatConversationId);

            this.spinnerStatus = "ok";

            await SleepService.sleep(500);

            this.conversation = null;
            this.textChatStore.clearActiveConversation();
        }
        catch {
            this.spinnerStatus = "error";
        }
    }

    onImageClicked(message: ITextChatMessageModel) {
        this.photos = this.conversation.messages
            .filter(x => x.imageUrl !== null)
            .map(x => {
                const photo = new SlickPhotoGalleryModel();
                photo.photoUrl = x.imageUrl;
                return photo;
            });
        const idx = this.photos.findIndex(x => x.photoUrl === message.imageUrl);
        this.photoGalleryRef.showPhotoGallery(idx);
	}

    toggleTemplates() {
        this.templatesExpanded = !this.templatesExpanded;
    }

    selectTemplate(template: ITextChatTemplateModel) {
        this.templatesExpanded = false;
        this.newMessage = template.templateText;
    }

    async onFilesDropped(files: IDocumentModel[]) {
        if (!files || files.length === 0)
            return;

        await this.textChatStore.sendAttachments(files);
    }


    private scrollToBottom() {
        setTimeout(() => {
            if (this.mainWindowRef) {
                const mainWindowRef = (<HTMLDivElement>this.mainWindowRef.nativeElement);
                mainWindowRef.scrollTop = mainWindowRef.scrollHeight;
            }
        });
    }
}
