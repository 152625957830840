<div class="d-flex flex-column flex-fill h-100" style="max-height:calc(100vh - 360px);">
    <div #messagesDiv class="flex-fill" style="overflow-y: auto">
        <div class="d-flex flex-column">
            <div *ngFor="let day of dailyMessagesLists">
                <div class="d-flex justify-content-center mb-2" style="color:gray">
                    {{day.dateString}}
                </div>

                <div *ngFor="let message of day.chatMessages" style="font-size: 1rem; line-height: .8rem;" class="d-flex mb-2" [ngClass]="{ 'justify-content-end': message.senderId === userId }">
                    <ng-container *ngIf="!message.senderId && message.isSystemMessage" [ngTemplateOutlet]="renderSystemMessage" [ngTemplateOutletContext]="{ message: message }"></ng-container>
                    <ng-container *ngIf="message.senderId === userId && !message.imageUrl && !message.isSystemMessage" [ngTemplateOutlet]="renderUserMessage" [ngTemplateOutletContext]="{ message: message }"></ng-container>
                    <ng-container *ngIf="message.senderId !== userId && !message.imageUrl && !message.isSystemMessage" [ngTemplateOutlet]="renderOtherUserMessage" [ngTemplateOutletContext]="{ message: message }"></ng-container>
                    <ng-container *ngIf="message.senderId === userId && message.imageUrl && !message.isSystemMessage" [ngTemplateOutlet]="renderUserImage" [ngTemplateOutletContext]="{ message: message }"></ng-container>
                    <ng-container *ngIf="message.senderId !== userId && message.imageUrl && !message.isSystemMessage" [ngTemplateOutlet]="renderOtherUserImage" [ngTemplateOutletContext]="{ message: message }"></ng-container>
                </div>
            </div>

        </div>
    </div>

    <div class="d-flex mt-2 mx-2">

        <div class="flex-fill me-1">
            <textarea #newMessageRef
                      class="form-control"
                      [(ngModel)]="newMessage"
                      (keydown)="onKeyDown($event)"
                      placeholder="Message..."
                      #autosize="cdkTextareaAutosize"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1.6"></textarea>
        </div>
        <div>
            <button type="button" class="btn btn-outline-primary" (click)="sendMessage()"><i class="far fa-paper-plane"></i></button>
        </div>
    </div>
    <div class="d-flex mt-1 mx-2">
        <div class="flex-fill chat-messages">
            <slick-file-drop class="drop" (filesChanged)="onFilesDropped($event)" displayText="Click or drag to send photos..."></slick-file-drop>
        </div>
    </div>

</div>


<ng-template #renderUserMessage let-message="message">
    <div style="max-width:calc(80%)" class="d-flex me-1">
        <!--<div class="me-1">
            <img [src]="message.sender.profilePictureUrl" style="border: 1px solid white; border-radius: 60px; min-width: 40px; max-width: 40px; min-height: 40px; max-height: 40px; border-radius: 50%;" />
        </div>-->
        <div>

            <div class='card chat-self bg-primary text-white' style="display:inline-block">
                <div class="align-items-center" style="max-width: calc(100%)">
                    <div style="max-width: calc(100%)">
                        {{message.message}}<br />
                    </div>
                </div>
            </div>
            <div style="font-size: .6rem; white-space: nowrap" class=" d-flex justify-content-end mt-1">{{message.sentOn | date:'h:mm a'}}</div>
        </div>
    </div>
</ng-template>

<ng-template #renderOtherUserMessage let-message="message">
    <div style="max-width:calc(80%)" class="d-flex">

        <div class="d-flex align-items-center me-1">
            <img [src]="message.sender.profilePictureUrl" style="border: 1px solid white; border-radius: 60px; min-width: 40px; max-width: 40px; min-height: 40px; max-height: 40px; border-radius: 50%;" />
        </div>
        <div>

            <div style="font-size: .8rem; white-space: nowrap" class="mb-1">{{message.sender.fullName}}<br /></div>
            <div>

                <div class='card chat-other bg-light text-body' style="display:inline-block">
                    <div class="align-items-center" style="max-width: calc(100%)">
                        <div style="max-width: calc(100%)">
                            {{message.message}}<br />
                        </div>
                    </div>
                </div>
            </div>
            <div style="font-size: .6rem; white-space: nowrap" class="mt-1">{{message.sentOn | date:'h:mm a'}}</div>
        </div>
    </div>
</ng-template>

<ng-template #renderOtherUserImage let-message="message">

    <div style="max-width:calc(80%)" class="d-flex">

        <div class="d-flex align-items-center me-1">
            <img [src]="message.sender.profilePictureUrl" style="border-radius: 60px; min-width: 40px; max-width: 40px; min-height: 40px; max-height: 40px; border-radius: 50%;" />
        </div>
        <div>

            <div style="font-size: .8rem; white-space: nowrap" class="mb-1">{{message.sender.fullName}}<br /></div>
            <img *ngIf="message.imageUrl" [src]="message.imageUrl" style="cursor: pointer" (click)="onImageClicked(message)" />
            
            <div style="font-size: .6rem; white-space: nowrap" class="mt-1">{{message.sentOn | date:'h:mm a'}}</div>
        </div>
    </div>
</ng-template>

<ng-template #renderUserImage let-message="message">
    <div style="font-size: 1rem; line-height: .8rem;" class="d-flex flex-fill mb-2 justify-content-end">

        <div style="max-width:calc(80%)">
            <div class="d-flex align-items-center">

                <div>
                    <img *ngIf="message.imageUrl" [src]="message.imageUrl" style="cursor: pointer" (click)="onImageClicked(message)"/>
                    <div style="font-size: .6rem; line-height: 11px; white-space: nowrap" class=" d-flex justify-content-end mt-1">
                        {{message.sentOn | date:'h:mm a'}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #renderSystemMessage let-message="message">
    <div style="font-size: .6rem; line-height: .8rem; color:gray" class="mb-2 flex-fill justify-content-center">
        <div style="font-size: .6rem; line-height: 11px; white-space: nowrap" class="d-flex justify-content-center mt-1">
            {{message.sentOn | date:'h:mm a'}}<br />
        </div>
        <div class="d-flex justify-content-center mt-1">
            {{message.message}}
        </div>
    </div>

</ng-template>

<slick-photo-gallery #photoGalleryRef [photos]="photos"></slick-photo-gallery>
