import { Injectable } from '@angular/core';
import { FunctionLockService, UtilsService, GlobalsService, HttpService } from '@services';
import { DevTraceService } from '@services/utils/dev-trace.service';
import { IPhotoUploadModel, PhotoUploadModel } from "./photo-upload.model";
import { IDocumentModel } from '@models';
import { Subject } from 'rxjs';
import { SlickToastService } from '@slick-components';

import { Directory, Filesystem } from "@capacitor/filesystem";

const LOCK_KEY = "PHOTOS_STORE_LOCK";
const LOCALSTORAGE_KEY = "PHOTOS_STORE_MODELS";

@Injectable()
export class PhotoUploadStore {
	private static _isLocked: boolean;
	private static MAX_PHOTOS = 500;
	private static _errors: string[];

	photoUploadedSubject: Subject<IDocumentModel> = new Subject<IDocumentModel>();

	constructor(private functionLockService: FunctionLockService,
		private slickToastService: SlickToastService,
		private httpService: HttpService,
		private devTraceService: DevTraceService) {
		PhotoUploadStore._errors = [];
		PhotoUploadStore._isLocked = true;

		const allPhotos = this.getPhotoUploadModels();

		if (allPhotos && allPhotos.length > 0)
			allPhotos[0].error = null;

		this.setPhotoUploadModels(allPhotos);
		PhotoUploadStore._isLocked = false;
	}

	async uploadPhotos() {
		if (PhotoUploadStore._isLocked === true)
			return;

		try {
			await Filesystem.readdir({
				path: `skedit-photos`,
				directory: Directory.Data
			})
		}
		catch {
			await Filesystem.mkdir({
				path: `skedit-photos`,
				directory: Directory.Data
			});
		}

		PhotoUploadStore._isLocked = true;

		const allPhotos = this.getPhotoUploadModels() || [];
		if (allPhotos.length === 0) {
			PhotoUploadStore._isLocked = false;
			return;
		}
		this.devTraceService.addTrace(`${allPhotos.length} photos to upload`);

		allPhotos.forEach(x => x.error = null);
		this.setPhotoUploadModels(allPhotos);
		if (!GlobalsService.isOnline) {
			this.devTraceService.addTrace(`Device is offline`);
			PhotoUploadStore._isLocked = false;
			return;
		}

		//if (PhotoUploadStore._errors.length > 0) {

		//	const messagesToSend = [...PhotoUploadStore._errors];
		//	let infiniteLoopCheck = 100;
		//	while (infiniteLoopCheck-- > 0 && messagesToSend.length > 0) {
		//		try {
		//			const errorMessage = messagesToSend.shift();
		//			await this.devTraceService.addTrace(`Photo Error: ${errorMessage}`);
		//			PhotoUploadStore._errors = PhotoUploadStore._errors.filter(x => x !== errorMessage);
		//		}
		//		catch { }
		//	}
		//}

		if (allPhotos.length > 0) {

			const uploadPromises: Promise<IPhotoUploadModel>[] = [];
			for (let i = 0; i < PhotoUploadStore.MAX_PHOTOS; i++) {
				if (i < allPhotos.length) {
					const currentPhoto = allPhotos[i];
					uploadPromises.push(this.upload_photo_internal(currentPhoto));
				}
			}

			await Promise.all(uploadPromises);

			if ((this.getPhotoUploadModels() || []).length === 0)
				this.slickToastService.showSuccess(`All photos uploaded`, 2000);


		}

		PhotoUploadStore._isLocked = false;
	}

	private async upload_photo_internal(photo: IPhotoUploadModel): Promise<IPhotoUploadModel> {
		try {
			// Upload photo
			const imageFile = await Filesystem.readFile({
				path: `skedit-photos/${photo.fileName}`,
				directory: Directory.Data
			});

			const uploadUrl = `documents/saveJobPhoto2?folderUuid=${photo.folderUuid}&uuid=${photo.uuid}`;

			const formData = new FormData();
			formData.append('enc64', imageFile.data);

			this.devTraceService.addTrace(`Uploading file: ${uploadUrl}`);
			const result = await this.httpService.postMultipart(uploadUrl, formData);

			if (result.status === 'ok') {
				this.devTraceService.addTrace(`Uploading file: ${uploadUrl} succeeded`);
				const doc = await this.httpService.get(`documents/getDocumentByUuid?uuid=${photo.uuid}`);
				this.photoUploadedSubject.next(doc);

				// Delete this photo
				await this.clearPhoto(photo)
			}
			else {
				this.devTraceService.addTrace(`Uploading file: ${uploadUrl} failed: ${result.status}`);
			}
		}
		catch (err) {
			photo.error = JSON.stringify(err);
			photo.currentStatus = "Error";
			photo.retryCount++;
			await this.updatePhoto(photo);
			console.error(err)
			const errMsg = JSON.stringify(err);
			if (PhotoUploadStore._errors.indexOf(errMsg) < 0) 
				PhotoUploadStore._errors.push(errMsg);
			this.devTraceService.addTrace(`Photo upload error: ${errMsg}`);
			//this.slickToastService.showDanger(`Photo ${photo.fileName} failed to upload`, 2000);
		}

		return photo;
	}

	async addPhoto(fileName: string, folderUuid: string): Promise<IPhotoUploadModel> {
		const newPhotoUploadModel = new PhotoUploadModel();

		let photosUploadModels = this.getPhotoUploadModels();

		newPhotoUploadModel.uuid = UtilsService.newGuid();
		newPhotoUploadModel.date = new Date();
		newPhotoUploadModel.folderUuid = folderUuid;
		newPhotoUploadModel.fileName = fileName;
		newPhotoUploadModel.retryCount = 0;

		photosUploadModels.push(newPhotoUploadModel);

		this.setPhotoUploadModels(photosUploadModels);

		return newPhotoUploadModel;
	}

	async clearAll(): Promise<IPhotoUploadModel[]> {

		this.setPhotoUploadModels([]);

		return this.getAllPhotos();

	}

	async getAllPhotos(): Promise<IPhotoUploadModel[]> {
		return [...this.getPhotoUploadModels()];
	}

	async clearPhoto(photo: IPhotoUploadModel): Promise<void> {

		let photoUploadModels = this.getPhotoUploadModels();

		photoUploadModels = photoUploadModels.filter(x => x.uuid !== photo.uuid);

		this.setPhotoUploadModels(photoUploadModels);

		try {
			await Filesystem.deleteFile({
				path: `skedit-photos/${photo.fileName}`,
				directory: Directory.Data
			});
		}
		catch (err) {
			console.error(err);
		}
	}

	async updatePhoto(photo: IPhotoUploadModel): Promise<IPhotoUploadModel> {

		let photoUploadModels = this.getPhotoUploadModels();

		const photoIdx = photoUploadModels.findIndex(x => x.uuid === photo.uuid);

		if (photoIdx >= 0)
			photoUploadModels[photoIdx] = photo;

		this.setPhotoUploadModels(photoUploadModels);

		return photoUploadModels[photoIdx];
	}

	private getPhotoUploadModels(): IPhotoUploadModel[] {

		const photoModelStr = localStorage.getItem(LOCALSTORAGE_KEY);

		if (!photoModelStr)
			return [];

		return JSON.parse(photoModelStr);
	}

	private setPhotoUploadModels(photoModels: IPhotoUploadModel[]) {
		const photoModelStr = JSON.stringify(photoModels);

		localStorage.setItem(LOCALSTORAGE_KEY, photoModelStr);
	}
}
