import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostBinding } from "@angular/core";

@Component({
	selector: 'slick-paging',
	templateUrl: 'slick-paging.component.html'
})
export class SlickPagingComponent implements OnChanges {
	@HostBinding('class') class = 'd-flex';

	@Input() pageNumber: number = 1;
	@Output() pageNumberChange: EventEmitter<number> = new EventEmitter();
	@Output() onPageChange: EventEmitter<number> = new EventEmitter();
	@Input() totalRecords: number;
	@Input() recordsPerPage: number;

	showEllipses: boolean = false;
	totalPages: number;
	// This will be 10 20 30, etc
	preDisplayPageNumbers: number[] = [];
	preDisplayEllipses: boolean = false;
	// This will be 41, 42, 43, 44, etc
	displayPageNumbers: number[] = [];
	// This will be 50 60 70, etc
	postDisplayPageNumbers: number[] = [];
	postDisplayEllipses: boolean = false;
	currentRecordNumber: number;
	endRecordNumber: number;

	constructor() {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.pageNumber) {
			this.pageNumber = parseInt((changes.pageNumber.currentValue || '1').toString());
		}

		this.recalc();
	}

	selectPage(selectedPageNumber: number) {
		this.pageNumber = selectedPageNumber;
		this.recalc();
		if (this.onPageChange)
			this.onPageChange.emit(this.pageNumber);
		if (this.pageNumberChange)
			this.pageNumberChange.emit(this.pageNumber);
	}

	first() {
		this.pageNumber = 1;
		this.recalc();
		if (this.onPageChange)
			this.onPageChange.emit(this.pageNumber);
		if (this.pageNumberChange)
			this.pageNumberChange.emit(this.pageNumber);
	}

	previousPage() {
		this.pageNumber--;
		this.recalc();
		if (this.onPageChange)
			this.onPageChange.emit(this.pageNumber);
		if (this.pageNumberChange)
			this.pageNumberChange.emit(this.pageNumber);
	}

	previousSet() {
		this.pageNumber -= 10;
		this.recalc();
		if (this.onPageChange)
			this.onPageChange.emit(this.pageNumber);
		if (this.pageNumberChange)
			this.pageNumberChange.emit(this.pageNumber);
	}

	nextPage() {
		this.pageNumber++;
		this.recalc();
		if (this.onPageChange)
			this.onPageChange.emit(this.pageNumber);
		if (this.pageNumberChange)
			this.pageNumberChange.emit(this.pageNumber);
	}

	nextSet() {
		this.pageNumber += 10;
		this.recalc();
		if (this.onPageChange)
			this.onPageChange.emit(this.pageNumber);
		if (this.pageNumberChange)
			this.pageNumberChange.emit(this.pageNumber);
	}

	last() {
		this.pageNumber = this.totalPages;
		this.recalc();
		if (this.onPageChange)
			this.onPageChange.emit(this.pageNumber);
		if (this.pageNumberChange)
			this.pageNumberChange.emit(this.pageNumber);
	}

	recalc() {
		this.preDisplayEllipses = false;
		this.postDisplayEllipses = false;

		if (!this.recordsPerPage)
			return;

		this.totalRecords = this.totalRecords || 0;
		if (this.totalRecords < 0)
			this.totalRecords = 0;
		this.recordsPerPage = this.recordsPerPage || 1;
		if (this.recordsPerPage <= 0)
			this.recordsPerPage = 1;

		this.totalPages = Math.floor(this.totalRecords / this.recordsPerPage);
		if ((this.totalRecords % this.recordsPerPage) > 0)
			this.totalPages++;

		if (this.pageNumber < 1)
			this.pageNumber = 1;

		if (this.pageNumber > this.totalPages)
			this.pageNumber = this.totalPages;

		this.currentRecordNumber = ((this.recordsPerPage) * (this.pageNumber - 1)) + 1;

		this.endRecordNumber = this.currentRecordNumber + this.recordsPerPage - 1;
		if (this.endRecordNumber > this.totalRecords)
			this.endRecordNumber = this.totalRecords;

		this.preDisplayPageNumbers = [];
		this.displayPageNumbers = [];
		this.postDisplayPageNumbers = [];

		// 10 is a special case where we want to just show all 10
		if (this.totalPages === 10) {
			for (let i = 1; i <= 10; i++) {
				this.displayPageNumbers.push(i);
			}
		}
		else {
			const preDisplayPageTotal = Math.floor(this.pageNumber / 5);
			if (preDisplayPageTotal > 0) {
				for (let i = 0; i < preDisplayPageTotal; i++) {
					if (i === 0)
						this.preDisplayPageNumbers.push(1);
					else
						this.preDisplayPageNumbers.push(i * 5);
				}
			}

			let currentDisplayPageStart = preDisplayPageTotal * 5;
			let currentDisplayPageEnd = currentDisplayPageStart + 4;
			if (currentDisplayPageEnd > this.totalPages)
				currentDisplayPageEnd = this.totalPages;

			for (let i = currentDisplayPageStart; i <= currentDisplayPageEnd; i++) {
				if (i > 0)
					this.displayPageNumbers.push(i);
			}

			const postDisplayPageTotal = Math.floor((this.totalPages - preDisplayPageTotal + 5) / 5);
			if (postDisplayPageTotal > 0) {
				for (let i = preDisplayPageTotal + 1; i < postDisplayPageTotal; i++)
					this.postDisplayPageNumbers.push(i * 5);
			}

			if (this.preDisplayPageNumbers.length > 1) {
				this.preDisplayEllipses = true;
				this.preDisplayPageNumbers = this.preDisplayPageNumbers.slice(this.preDisplayPageNumbers.length - 1, this.preDisplayPageNumbers.length);
			}
			if (this.postDisplayPageNumbers.length > 1) {
				this.postDisplayEllipses = true;
				this.postDisplayPageNumbers = this.postDisplayPageNumbers.slice(0, 1);
			}
		}

		//// If we can show ALL the pages without the ..., then do it
		//if (this.totalPages <= (this.adjacent * 2)) {
		//	this.allDisplayPageNumbers = [];

		//	this.showEllipses = false;

		//	for (let i = 1; i <= this.totalPages; i++) {
		//		this.allDisplayPageNumbers.push(i);
		//	}
		//}
		//else {
		//	this.showEllipses = true;

		//	if (this.allDisplayPageNumbers.indexOf(this.pageNumber) < 0) {
		//		this.allDisplayPageNumbers = [];

		//		for (let i = this.pageNumber; i < this.pageNumber + this.adjacent; i++)
		//			this.allDisplayPageNumbers.push(i);

		//		this.allDisplayPageNumbers.push(-1)

		//		for (let i = this.totalPages - this.adjacent; i < this.totalPages; i++)
		//			this.allDisplayPageNumbers.push(i);
		//	}
		//}
	}

}