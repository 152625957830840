import { ICustomerModel } from "../customers/customer.model";
import { IAppliedPaymentModel } from "./applied-payment.model";
import { UtilsService } from "@services/utils/utils.service";
import * as moment from 'moment';
import { ICreditCardTransactionLogModel } from "../credit-card-processing/credit-card-transaction-log.model";
import { IPaymentRefundModel } from "./payment-refund.model";
import { GlobalsService } from "../../services";

export interface IPaymentModel {
    companyId: number;
    paymentId: number;
    creditCardTransactionLogId: number;
    paymentType: string;
    documentKey: string;
    referenceNumber: string;
    creditCardInfo: string;
    paymentDate: Date;
    amount: number;
    unappliedAmount: number;
    invoiceTotals: number;
    notes: string;
    syncToQuickbooks: boolean;
    quickbooksId: string;
    syncToBusinessCentral: boolean;
    businessCentralId: string;
    customer: ICustomerModel;
    appliedPayments: IAppliedPaymentModel[];
    previouslyAppliedPayments: IAppliedPaymentModel[];
    creditCardTransactionLog: ICreditCardTransactionLogModel;
    appliedAmount: number;
    isOnlinePayment: boolean;
    isDownPayment: boolean;
    canVoid: boolean;
    isVoided: boolean;
    voidedMessage: string;
    canRefund: boolean;
    amountRefunded: number;
    amountAvailable: number;
    paymentRefundModels: IPaymentRefundModel[];
    recurringBillingId: number;

}

export class PaymentModel implements IPaymentModel {
    constructor() {
        this.paymentId = 0;
        this.paymentDate = moment().startOf("day").toDate();
        this.documentKey = UtilsService.newGuid();
        this.amount = 0;
        this.unappliedAmount = 0;
        this.appliedPayments = [];
        this.previouslyAppliedPayments = [];
        this.appliedAmount = 0;
        this.syncToBusinessCentral = GlobalsService.company.businessCentralEnabled;
    }

    companyId: number;
    paymentId: number;
    creditCardTransactionLogId: number;
    paymentType: string;
    documentKey: string;
    referenceNumber: string;
    creditCardInfo: string;
    paymentDate: Date;
    amount: number;
    unappliedAmount: number;
    invoiceTotals: number;
    notes: string;
    syncToQuickbooks: boolean;
    quickbooksId: string;
    syncToBusinessCentral: boolean;
    businessCentralId: string;
    customer: ICustomerModel;
    appliedPayments: IAppliedPaymentModel[];
    previouslyAppliedPayments: IAppliedPaymentModel[];
    appliedAmount: number;
    isOnlinePayment: boolean;
    isDownPayment: boolean;
    canVoid: boolean;
    isVoided: boolean;
    voidedMessage: string;
    canRefund: boolean;
    amountRefunded: number;
    amountAvailable: number;
    creditCardTransactionLog: ICreditCardTransactionLogModel;
    paymentRefundModels: IPaymentRefundModel[];
    recurringBillingId: number;

}