import { Injectable } from "@angular/core";
import { UtilsService } from "./utils/utils.service";
import { IScheduledAppointmentModel, IBlockedTimeSlotModel, ISelectedLocationModel, IAppointmentModel, ICustomerDisplayListAppointmentModel } from "@models";
import { IHoveredAppointmentModel } from "@models/schedule/hovered-appointment.model";
import { Subject } from "rxjs";
import * as moment from 'moment';

@Injectable()
export class ScheduleStore {
    private static _selectedLocations: ISelectedLocationModel[];
	private static _appointments: IScheduledAppointmentModel[];
	private static _miniScheduleAppointments: IScheduledAppointmentModel[];
	private static _unscheduledAppointments: IScheduledAppointmentModel[];
	private static _clipboardAppointment: IScheduledAppointmentModel;
	private static _blockedTimeSlots: IBlockedTimeSlotModel[];
	private static _miniScheduleBlockedTimeSlots: IBlockedTimeSlotModel[];
	private static _scheduleDiv: HTMLDivElement;
	private static _miniScheduleTable: HTMLTableElement;
	private static _selectedAppointment: IAppointmentModel;
	private static _currentDate: Date;
	private static _startDate: Date;
	private static _endDate: Date;
	private static _miniScheduleStartDate: Date;
	private static _miniScheduleEndDate: Date;
	private static _visibleStartHour: number;
	private static _visibleEndHour: number;
	private static _calendarView: string;
	private static _totalVisibleTimeSlots: number;
	private static _timeSelectorResourceId?: number;
	private static _timeSelectorStartDate: Date;
	private static _timeSelectorEndDate: Date;
	private static _miniScheduleVisible: boolean;
	private static _unscheduledAppointmentsVisible: boolean;
	private static _hoveredAppointment: IHoveredAppointmentModel;
	private static _appointmentToModify: IScheduledAppointmentModel;
	private static _appointmentToModifyOrginal: IScheduledAppointmentModel;
	private static _appointmentToModifyDiv: HTMLDivElement;
	private static _isMovingAppt = false;


	//*****************************************************************************
	//* scheduleDiv
	//*****************************************************************************
	get scheduleDiv(): HTMLDivElement {
		return ScheduleStore._scheduleDiv;
	}

	set scheduleDiv(scheduleDiv: HTMLDivElement) {
		ScheduleStore._scheduleDiv = scheduleDiv;
	}

	//*****************************************************************************
	//* resourceLine
	//*****************************************************************************
	getResourceLine(resourceId: number, date: Date): HTMLDivElement {
		const calcDate = moment(date).format("YYYYMMDD");
		return <HTMLDivElement>ScheduleStore._scheduleDiv.querySelector(`.resource-line[data-resourceid='${resourceId}'][data-calcdate='${calcDate}']`);
	}

	//*****************************************************************************
	//* miniScheduleTable
	//*****************************************************************************
	get miniScheduleTable(): HTMLTableElement {
		return ScheduleStore._miniScheduleTable;
	}

	set miniScheduleTable(miniScheduleTable: HTMLTableElement) {
		ScheduleStore._miniScheduleTable = miniScheduleTable;
	}

	//*****************************************************************************
	//* miniScheduleResourceRows
	//*****************************************************************************
	getMiniScheduleResourceRow(resourceId: number, calcDate: string): HTMLDivElement {
		return <HTMLDivElement>ScheduleStore._miniScheduleTable.querySelector(`tr td[data-calcdate='${calcDate}'] .resource-row[data-resourceid='${resourceId}']`);
	}

    //*****************************************************************************
    //* selectedLocations
    //*****************************************************************************
    private selectedLocationsSubject: Subject<ISelectedLocationModel[]> = new Subject<ISelectedLocationModel[]>();
    get selectedLocations(): ISelectedLocationModel[] {
        return ScheduleStore._selectedLocations;
    }

	set selectedLocations(selectedLocations: ISelectedLocationModel[]) {
		if (selectedLocations && selectedLocations.length === 1)
			selectedLocations[0].checked = true;
        ScheduleStore._selectedLocations = selectedLocations;
        this.selectedLocationsSubject.next(selectedLocations);
    }

    get selectedLocationsStore(): Subject<ISelectedLocationModel[]> {
        return this.selectedLocationsSubject;
    }


	//*****************************************************************************
	//* currentDate
	//*****************************************************************************
	private currentDateSubject: Subject<Date> = new Subject<Date>();
	get currentDate(): Date {
		return ScheduleStore._currentDate;
	}

	set currentDate(currentDate: Date) {
		currentDate = moment(currentDate).startOf("date").toDate();
		if (moment(currentDate).isSame(ScheduleStore._currentDate))
			return;
		ScheduleStore._currentDate = currentDate;

		switch (ScheduleStore._calendarView) {
			case "Day":
				ScheduleStore._startDate = moment(ScheduleStore._currentDate).startOf('day').toDate();
				ScheduleStore._endDate = moment(ScheduleStore._startDate).add(1, 'days').toDate();
				break;

			case "Week":
				ScheduleStore._startDate = moment(ScheduleStore._currentDate).startOf('week').toDate();
				ScheduleStore._endDate = moment(ScheduleStore._startDate).add(1, 'week').toDate();
				break;

			case "Month":
				ScheduleStore._startDate = moment(ScheduleStore._currentDate).startOf('month').startOf("week").toDate();
				ScheduleStore._endDate = moment(ScheduleStore._startDate).add(6, 'weeks').toDate();
				break;
		}

		this.currentDateSubject.next(currentDate);
	}

	get currentDateStore(): Subject<Date> {
		return this.currentDateSubject;
	}

	//*****************************************************************************
	//* selectedAppointment
	//*****************************************************************************
	private selectedAppointmentSubject: Subject<IAppointmentModel> = new Subject<IAppointmentModel>();
	get selectedAppointment(): IAppointmentModel {
		return ScheduleStore._selectedAppointment;
	}

	set selectedAppointment(selectedAppointment: IAppointmentModel) {
		ScheduleStore._selectedAppointment = selectedAppointment;
		this.selectedAppointmentSubject.next(selectedAppointment);
	}

	get selectedAppointmentStore(): Subject<IAppointmentModel> {
		return this.selectedAppointmentSubject;
	}

	setSelectedAppointment(selectedAppointment: IAppointmentModel) {
		ScheduleStore._selectedAppointment = selectedAppointment;
	}

	//*****************************************************************************
	//* startDate
	//*****************************************************************************
	private startDateSubject: Subject<Date> = new Subject<Date>();
	get startDate(): Date {
		return ScheduleStore._startDate;
	}

	set startDate(startDate: Date) {
		ScheduleStore._startDate = startDate;
		this.startDateSubject.next(startDate);
	}

	get startDateStore(): Subject<Date> {
		return this.startDateSubject;
	}

	//*****************************************************************************
	//* endDate
	//*****************************************************************************
	private endDateSubject: Subject<Date> = new Subject<Date>();
	get endDate(): Date {
		return ScheduleStore._endDate;
	}

	set endDate(endDate: Date) {
		ScheduleStore._endDate = endDate;
		this.endDateSubject.next(endDate);
	}

	get endDateStore(): Subject<Date> {
		return this.endDateSubject;
	}

	//*****************************************************************************
	//* miniScheduleStartDate
	//*****************************************************************************
	get miniScheduleStartDate(): Date {
		return ScheduleStore._miniScheduleStartDate;
	}

	set miniScheduleStartDate(miniScheduleStartDate: Date) {
		ScheduleStore._miniScheduleStartDate = miniScheduleStartDate;
	}

	//*****************************************************************************
	//* endDate
	//*****************************************************************************
	get miniScheduleEndDate(): Date {
		return ScheduleStore._miniScheduleEndDate;
	}

	set miniScheduleEndDate(miniScheduleEndDate: Date) {
		ScheduleStore._miniScheduleEndDate = miniScheduleEndDate;
	}

	//*****************************************************************************
	//* visibleStartDate
	//*****************************************************************************
	get visibleStartDate(): Date {
		return moment(ScheduleStore._startDate).add(ScheduleStore._visibleStartHour, "hours").toDate();
	}

	//*****************************************************************************
	//* visibleEndDate
	//*****************************************************************************
	get visibleEndDate(): Date {
		return moment(ScheduleStore._endDate).add(ScheduleStore._visibleEndHour, "hours").toDate();
	}

	//*****************************************************************************
	//* visibleStartHour
	//*****************************************************************************
	private visibleStartHourSubject: Subject<number> = new Subject<number>();
	get visibleStartHour(): number {
		return ScheduleStore._visibleStartHour;
	}

	set visibleStartHour(visibleStartHour: number) {
		ScheduleStore._visibleStartHour = visibleStartHour;
		ScheduleStore._totalVisibleTimeSlots = ((ScheduleStore._visibleEndHour || 0) - (ScheduleStore._visibleStartHour || 0)) * 4;
		this.visibleStartHourSubject.next(visibleStartHour);
	}

	get visibleStartHourStore(): Subject<number> {
		return this.visibleStartHourSubject;
	}

	//*****************************************************************************
	//* visibleEndHour
	//*****************************************************************************
	private visibleEndHourSubject: Subject<number> = new Subject<number>();
	get visibleEndHour(): number {
		return ScheduleStore._visibleEndHour;
	}

	set visibleEndHour(visibleEndHour: number) {
		ScheduleStore._visibleEndHour = visibleEndHour;
		ScheduleStore._totalVisibleTimeSlots = ((ScheduleStore._visibleEndHour || 0) - (ScheduleStore._visibleStartHour || 0)) * 4;
		this.visibleEndHourSubject.next(visibleEndHour);
	}

	get visibleEndHourStore(): Subject<number> {
		return this.visibleEndHourSubject;
	}

	//*****************************************************************************
	//* calendarView
	//*****************************************************************************
	private calendarViewSubject: Subject<string> = new Subject<string>();
	get calendarView(): string {
		return ScheduleStore._calendarView;
	}

	set calendarView(calendarView: string) {
		ScheduleStore._calendarView = calendarView;

		switch (ScheduleStore._calendarView) {
			case "Day":
				ScheduleStore._startDate = moment(ScheduleStore._currentDate).startOf('day').toDate();
				ScheduleStore._endDate = moment(ScheduleStore._startDate).add(1, 'days').toDate();
				break;

			case "Week":
				ScheduleStore._startDate = moment(ScheduleStore._currentDate).startOf('week').toDate();
				ScheduleStore._endDate = moment(ScheduleStore._startDate).add(1, 'week').toDate();
				break;

			case "Month":
				ScheduleStore._startDate = moment(ScheduleStore._currentDate).startOf('month').startOf("week").toDate();
				ScheduleStore._endDate = moment(ScheduleStore._startDate).add(6, 'weeks').toDate();
				break;
		}
		this.calendarViewSubject.next(calendarView);
	}

	get calendarViewStore(): Subject<string> {
		return this.calendarViewSubject;
	}

	//*****************************************************************************
	//* totalVisibleTimeSlots
	//*****************************************************************************
	get totalVisibleTimeSlots(): number {
		return ScheduleStore._totalVisibleTimeSlots;
	}

	//*****************************************************************************
	//* timeSelector
	//*****************************************************************************
	get timeSelectorResourceId(): number {
		return ScheduleStore._timeSelectorResourceId;
	}

	set timeSelectorResourceId(_timeSelectorResourceId: number) {
		ScheduleStore._timeSelectorResourceId = _timeSelectorResourceId;
	}

	get timeSelectorStartDate(): Date {
		return ScheduleStore._timeSelectorStartDate;
	}

	set timeSelectorStartDate(timeSelectorStartDate: Date) {
		ScheduleStore._timeSelectorStartDate = timeSelectorStartDate;
	}

	get timeSelectorEndDate(): Date {
		return ScheduleStore._timeSelectorEndDate;
	}

	set timeSelectorEndDate(timeSelectorEndDate: Date) {
		ScheduleStore._timeSelectorEndDate = timeSelectorEndDate;
	}

	//*****************************************************************************
	//* miniScheduleVisible
	//*****************************************************************************
	private miniScheduleVisibleSubject: Subject<boolean> = new Subject<boolean>();
	get miniScheduleVisible(): boolean {
		return ScheduleStore._miniScheduleVisible;
	}

	set miniScheduleVisible(miniScheduleVisible: boolean) {
		ScheduleStore._miniScheduleVisible = miniScheduleVisible;
		this.miniScheduleVisibleSubject.next(miniScheduleVisible);
	}

	get miniScheduleVisibleStore(): Subject<boolean> {
		return this.miniScheduleVisibleSubject;
	}

	//*****************************************************************************
	//* unscheduledAppointments
	//*****************************************************************************
	private unscheduledAppointmentsSubject: Subject<IScheduledAppointmentModel[]> = new Subject<IScheduledAppointmentModel[]>();
	get unscheduledAppointments(): IScheduledAppointmentModel[] {
		return ScheduleStore._unscheduledAppointments;
	}

	getUnscheduledAppointment(appointmentId: number): IScheduledAppointmentModel {
		const appt = ScheduleStore._unscheduledAppointments.find(x => x.appointmentId === appointmentId);

		if (!appt)
			return null;

		return UtilsService.clone(appt);
	}

	set unscheduledAppointments(unscheduledAppointments: IScheduledAppointmentModel[]) {
		ScheduleStore._unscheduledAppointments = [...unscheduledAppointments];
		this.unscheduledAppointmentsSubject.next(ScheduleStore._unscheduledAppointments);
	}

	get unscheduledAppointmentsStore(): Subject<IScheduledAppointmentModel[]> {
		return this.unscheduledAppointmentsSubject;
	}

	addUnscheduledAppointment(appt: IScheduledAppointmentModel, idx: number = 0) {
		const unscheduledAppt = this.getUnscheduledAppointment(appt.appointmentId);
		if (unscheduledAppt)
			return
		ScheduleStore._unscheduledAppointments = UtilsService.arrayInsert(appt, ScheduleStore._unscheduledAppointments, idx);
		this.unscheduledAppointmentsSubject.next(ScheduleStore._unscheduledAppointments);
	}

	removeUnscheduledAppointment(apptId: number) {
		ScheduleStore._unscheduledAppointments = ScheduleStore._unscheduledAppointments.filter(x => x.appointmentId !== apptId);
		this.unscheduledAppointmentsSubject.next(ScheduleStore._unscheduledAppointments);
	}



	//*****************************************************************************
	//* unscheduledAppointmentsVisible
	//*****************************************************************************
	private unscheduledAppointmentsVisibleSubject: Subject<boolean> = new Subject<boolean>();
	get unscheduledAppointmentsVisible(): boolean {
		return ScheduleStore._unscheduledAppointmentsVisible;
	}

	set unscheduledAppointmentsVisible(unscheduledAppointmentsVisible: boolean) {
		if (unscheduledAppointmentsVisible === true)
			localStorage.setItem("UNSCHEDULED_APPOINTMENTS_VISIBLE", 'true');
		else
			localStorage.setItem("UNSCHEDULED_APPOINTMENTS_VISIBLE", 'false');

		ScheduleStore._unscheduledAppointmentsVisible = unscheduledAppointmentsVisible;
		this.unscheduledAppointmentsVisibleSubject.next(ScheduleStore._unscheduledAppointmentsVisible);
	}

	get unscheduledAppointmentsVisibleStore(): Subject<boolean> {
		return this.unscheduledAppointmentsVisibleSubject;
	}

	//*****************************************************************************
	//* appointments
	//*****************************************************************************
	get appointments(): IScheduledAppointmentModel[] {
		return ScheduleStore._appointments;
	}

	set appointments(scheduledAppointments: IScheduledAppointmentModel[]) {
		ScheduleStore._appointments = [...scheduledAppointments];
		if (ScheduleStore._clipboardAppointment)
			ScheduleStore._appointments = ScheduleStore._appointments.filter(a => a.appointmentId !== ScheduleStore._clipboardAppointment.appointmentId);
	}

	getAppointment(appointmentId: number): IScheduledAppointmentModel {
		const appt = ScheduleStore._appointments.find(x => x.appointmentId === appointmentId);

		if (!appt)
			return null;

		return UtilsService.clone(appt);
	}

	get miniScheduleAppointments(): IScheduledAppointmentModel[] {
		return ScheduleStore._miniScheduleAppointments;
	}

	set miniScheduleAppointments(scheduledAppointments: IScheduledAppointmentModel[]) {
		ScheduleStore._miniScheduleAppointments = scheduledAppointments;
		if (ScheduleStore._clipboardAppointment)
			ScheduleStore._miniScheduleAppointments = ScheduleStore._miniScheduleAppointments.filter(a => a.appointmentId !== ScheduleStore._clipboardAppointment.appointmentId);
	}

	setAppointment(appt: IScheduledAppointmentModel) {
		const apptIdx = ScheduleStore._appointments.findIndex(x =>  x.appointmentId === appt.appointmentId);

		if (apptIdx < 0)
			ScheduleStore._appointments.push(appt);
		else
			ScheduleStore._appointments[apptIdx] = appt;

		if (ScheduleStore._miniScheduleVisible === true) {
			const miniScheduleApptIdx = ScheduleStore._miniScheduleAppointments.findIndex(x => x.appointmentId === appt.appointmentId);

			if (miniScheduleApptIdx < 0)
				ScheduleStore._miniScheduleAppointments.push(appt);
			else
				ScheduleStore._miniScheduleAppointments[apptIdx] = appt;
		}
	}

	deleteAppointment(appointmentId: number) {
		ScheduleStore._appointments = ScheduleStore._appointments.filter(a => a.appointmentId !== appointmentId);
		if (ScheduleStore._miniScheduleVisible === true) {
			ScheduleStore._miniScheduleAppointments = ScheduleStore._miniScheduleAppointments.filter(a => a.appointmentId !== appointmentId);
		}
	}

	replaceAppointment(appointment: IScheduledAppointmentModel) {
		ScheduleStore._appointments = ScheduleStore._appointments.filter(a => a.appointmentId != appointment.appointmentId);
		ScheduleStore._appointments.push(appointment);
	}

	//*****************************************************************************
	//* clipboardAppointment
	//*****************************************************************************
	private clipboardAppointmentSubject: Subject<IScheduledAppointmentModel> = new Subject<IScheduledAppointmentModel>();
	get clipboardAppointment(): IScheduledAppointmentModel {
		return ScheduleStore._clipboardAppointment;
	}

	set clipboardAppointment(clipboardAppointment: IScheduledAppointmentModel) {
		ScheduleStore._clipboardAppointment = clipboardAppointment;
		this.clipboardAppointmentSubject.next(clipboardAppointment);
	}

	get clipboardAppointmentStore(): Subject<IScheduledAppointmentModel> {
		return this.clipboardAppointmentSubject;
	}

	//*****************************************************************************
	//* blockedTimeSlots
	//*****************************************************************************
	get blockedTimeSlots(): IBlockedTimeSlotModel[] {
		return ScheduleStore._blockedTimeSlots;
	}

	set blockedTimeSlots(blockedTimeSlots: IBlockedTimeSlotModel[]) {
		ScheduleStore._blockedTimeSlots = blockedTimeSlots;
	}

	get miniScheduleBlockedTimeSlots(): IBlockedTimeSlotModel[] {
		return ScheduleStore._miniScheduleBlockedTimeSlots;
	}

	set miniScheduleBlockedTimeSlots(blockedTimeSlots: IBlockedTimeSlotModel[]) {
		ScheduleStore._miniScheduleBlockedTimeSlots = blockedTimeSlots;
	}

	getBlockedTimeSlot(blockedTimeSlotId: number): IBlockedTimeSlotModel {
		const blockedTimeSlot = ScheduleStore._blockedTimeSlots.find(x => x.blockedTimeSlotId === blockedTimeSlotId);

		if (!blockedTimeSlot)
			return null;

		return UtilsService.clone(blockedTimeSlot);
	}

	setBlockedTimeSlot(blockedTimeSlot: IBlockedTimeSlotModel) {
		const blockedTimeSlotIdx = ScheduleStore._blockedTimeSlots.findIndex(x => x.blockedTimeSlotId === blockedTimeSlot.blockedTimeSlotId);

		if (blockedTimeSlotIdx < 0)
			ScheduleStore._blockedTimeSlots.push(blockedTimeSlot);
		else
			ScheduleStore._blockedTimeSlots[blockedTimeSlotIdx] = blockedTimeSlot;

		if (ScheduleStore._miniScheduleVisible === true) {
			const miniScheduleBlockedTimeSlotIdx = ScheduleStore._miniScheduleBlockedTimeSlots.findIndex(x => x.blockedTimeSlotId === blockedTimeSlot.blockedTimeSlotId);

			if (miniScheduleBlockedTimeSlotIdx < 0)
				ScheduleStore._miniScheduleBlockedTimeSlots.push(blockedTimeSlot);
			else
				ScheduleStore._miniScheduleBlockedTimeSlots[miniScheduleBlockedTimeSlotIdx] = blockedTimeSlot;
		}
	}

	deleteBlockedTimeSlot(blockedTimeSlotId: number) {
		ScheduleStore._blockedTimeSlots = ScheduleStore._blockedTimeSlots.filter(bts => bts.blockedTimeSlotId !== blockedTimeSlotId);
	}

	//*****************************************************************************
	//* hoveredAppointment
	//*****************************************************************************
	private hoveredAppointmentSubject: Subject<IHoveredAppointmentModel> = new Subject<IHoveredAppointmentModel>();

	get hoveredAppointment(): IHoveredAppointmentModel {
		return ScheduleStore._hoveredAppointment;
	}

	set hoveredAppointment(hoveredAppointment: IHoveredAppointmentModel) {
		ScheduleStore._hoveredAppointment = hoveredAppointment;
		this.hoveredAppointmentStore.next(hoveredAppointment);
	}

	get hoveredAppointmentStore(): Subject<IHoveredAppointmentModel> {
		return this.hoveredAppointmentSubject;
	}

	//*****************************************************************************
	//* appointmentToModify
	//*****************************************************************************
	get appointmentToModify(): IScheduledAppointmentModel {
		return ScheduleStore._appointmentToModify;
	}

	set appointmentToModify(appointmentToModify: IScheduledAppointmentModel) {
		ScheduleStore._appointmentToModify = appointmentToModify;
	}

	//*****************************************************************************
	//* appointmentToModifyOrginal
	//*****************************************************************************
	get appointmentToModifyOrginal(): IScheduledAppointmentModel {
		return ScheduleStore._appointmentToModifyOrginal;
	}

	set appointmentToModifyOrginal(appointmentToModifyOrginal: IScheduledAppointmentModel) {
		ScheduleStore._appointmentToModifyOrginal = appointmentToModifyOrginal;
	}

	//*****************************************************************************
	//* appointmentToModifyDiv
	//*****************************************************************************
	get appointmentToModifyDiv(): HTMLDivElement {
		return ScheduleStore._appointmentToModifyDiv;
	}

	set appointmentToModifyDiv(appointmentToModifyDiv: HTMLDivElement) {
		ScheduleStore._appointmentToModifyDiv = appointmentToModifyDiv;
	}

	//*****************************************************************************
	//* isMovingAppt
	//*****************************************************************************
	get isMovingAppt(): boolean {
		return ScheduleStore._isMovingAppt;
	}

	set isMovingAppt(isMovingAppt: boolean) {
		ScheduleStore._isMovingAppt = isMovingAppt;
	}

	//*****************************************************************************
	//* pingAppointment
	//*****************************************************************************
	pingAppointmentSubject: Subject<ICustomerDisplayListAppointmentModel> = new Subject<ICustomerDisplayListAppointmentModel>();
	pingAppointment(appt: ICustomerDisplayListAppointmentModel) {
		this.pingAppointmentSubject.next(appt);
	}


	//*****************************************************************************
	//* offline mode
	//*****************************************************************************
	offlineApptChangedSubject: Subject<void> = new Subject<void>();
	get offlineApptChangedStore(): Subject<void> {
		return this.offlineApptChangedSubject;
	}

	offlineApptChanged() {
		this.offlineApptChangedSubject.next();
	}
}
