export interface ICustomerTypeModel {
	customerTypeId: number;
	name: string;
	active: boolean;
}

export class CustomerTypeModel implements ICustomerTypeModel {
	constructor() {
		this.customerTypeId = 0;
		this.active = true;
	}

	customerTypeId: number;
	name: string;
	active: boolean;
}