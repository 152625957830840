import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { SlickDialogComponent } from "@slick-components";
import { IJobModel } from '@models';
import { GlobalsService, JobsService, SleepService } from '@services';
import { JobEditComponent } from './job-edit.component';
import Swal from 'sweetalert2';

@Component({
	selector: 'job-edit-dialog',
	template: `
<slick-dialog key="DIALOG_JOB_EDIT" top="50" [header]="jobModel ? jobModel.jobName : 'New Job'" width="920" #dialogRef (onClose)="cancelJob()">
    <job-edit #jobEditComponentRef  [jobModel]="jobModel" [defaultApptId]="defaultApptId" isDialog="true"></job-edit>
	<slick-dialog-footer>
		<button class="save-button btn btn-outline-success me-3" style="width: 160px" (click)="saveJob()" [slick-button-spinner]="spinnerStatus">
			<i class="bi bi-check-circle"></i> Save
		</button>
		<button class="cancel-button btn btn-outline-danger" style="width: 160px" (click)="cancelJob()">
			<i class="bi bi-x-circle"></i> Cancel
		</button>

	</slick-dialog-footer>
</slick-dialog>
`,
	providers: [JobsService]
})
export class JobEditDialogComponent {
	@ViewChild('dialogRef', { static: true }) dialogRef: SlickDialogComponent;
	@ViewChild('jobEditComponentRef', { static: true }) jobEditComponentRef: JobEditComponent;

	jobModel: IJobModel;
	defaultApptId: number;
	spinnerStatus: string;
	resolve: any;

	constructor() {
	}

	openDialog(jobModel: IJobModel, defaultApptId: number = null): Promise<IJobModel> {
		return new Promise((resolve) => {
			this.resolve = resolve;
			this.spinnerStatus = 'reset';
			this.jobEditComponentRef.tabIndex = 0;

			this.jobModel = jobModel;
			this.defaultApptId = defaultApptId;
			this.dialogRef.showDialog();
		});
	}

	async saveJob() {
		try {
			this.jobEditComponentRef.isSubmitted = true;

			const isValid = await this.jobEditComponentRef.isValid();

			if (!isValid) {
				this.spinnerStatus = 'error';
				return;
			}

			// Mizu only check for critical credit hold 
			// allow them to schedule estimates
			if (GlobalsService.company.companyId === 1 && this.jobModel.jobId === 0) {
				if (this.jobModel.billToCustomer.criticalCreditHold === true && GlobalsService.userInfo.userName.toLowerCase() !== 'marisa' && this.jobModel.appointments.find(x => x.appointmentId === 0 && x.appointmentTypeDisplayName !== "Estimate")) {
					this.spinnerStatus = 'error';

					await Swal.fire({
						icon: 'warning',
						title: 'Critical Hold',
						text: `Warning: The bill to customer is on critical credit hold.  Only Marisa can schedule this.`,
						confirmButtonColor: '#007bff',
						heightAuto: false
					});

					return;
				}
			}

			this.spinnerStatus = 'spin';

			await SleepService.sleep(500);

            this.jobModel = await this.jobEditComponentRef.saveJob();

            this.spinnerStatus = "ok";

            await SleepService.sleep(1000);
            this.dialogRef.hideDialog();
            this.resolve(this.jobModel);
			
		} catch {
			this.spinnerStatus = 'error';
		}
	}

	async cancelJob() {
		await this.dialogRef.hideDialog();
		this.resolve(null);
	}
}
