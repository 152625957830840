import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { iCalModel, IInvoiceModel, IRecurringBillingModel } from "@models";
import moment from "moment";
import { IRecurringBillingHistoryModel } from "../models/recurring/recurring-billing-history.model";

@Injectable()
export class RecurringBillingsService {
    constructor(private readonly httpService: HttpService) { }

    async getRecurringBillingModel(RecurringBillingId: number): Promise<IRecurringBillingModel> {
        return await this.httpService.get("/RecurringBillings/getRecurringBillingModel", { RecurringBillingId: RecurringBillingId });
    }

    async updateRecurringBilling(RecurringBillingModel: IRecurringBillingModel): Promise<IRecurringBillingModel> {
        return await this.httpService.post("/RecurringBillings/updateRecurringBilling", RecurringBillingModel);
    }

    async getRecurringBillingsForGrid(RecurringBillingModel: IRecurringBillingModel): Promise<IRecurringBillingModel> {
        return await this.httpService.post("/RecurringBillings/getRecurringBillingsForGrid", RecurringBillingModel);
    }

    async getNextBillToDate(startDate: Date, endDate: Date, iCalModel: iCalModel): Promise<Date> {
        const sDate = moment(startDate).format("MM/DD/yyyy");
        let eDate = moment().format("MM/DD/yyyy");
        if (endDate != null)
            eDate = moment(endDate).format("MM/DD/yyyy");
        else
            eDate = moment().add(100, "year").format("MM/DD/yyyy");
        return await this.httpService.post(`/RecurringBillings/getNextBillToDate?startDate=${sDate}&endDate=${eDate}`, iCalModel);
    }

    async retryPayment(recurringBillingHistoryId: number): Promise<IRecurringBillingModel> {
        return await this.httpService.get(`/recurringBillings/retryPayment?recurringBillingHistoryId=${recurringBillingHistoryId}`);
    }

    
}