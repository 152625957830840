import { Component, Input, ElementRef, OnChanges, OnInit, SimpleChanges, AfterViewInit } from "@angular/core";
import { SlickSleepService } from "../utils/slick-sleep.service";

@Component({
	selector: '[slick-button-spinner]',
	templateUrl: "slick-button-spinner.component.html"
})
export class SlickButtonSpinnerComponent implements OnChanges, OnInit, AfterViewInit {
	@Input('slick-button-spinner') spinnerStatus: string;

	spinnerVisible: boolean = false;
	checkVisible: boolean = false;
	errorVisible: boolean = false;

	private currentStatus: string = "hidden";
	private inProcess: boolean = false;
	private expanded: boolean = false;
	private showCheckInterval: NodeJS.Timer;
	private originalPadding: string;

	constructor(private el: ElementRef) {		
		el.nativeElement.classList.add("slick-button-spinner");
	}

	ngOnInit() {
	}

	async ngAfterViewInit() {
		this.originalPadding = this.el.nativeElement.style.paddingRight;
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (!changes.spinnerStatus || !changes.spinnerStatus.currentValue)
			return;

		switch (changes.spinnerStatus.currentValue.toLowerCase()) {
			case 'spin':
				if (this.inProcess === true) {
					// If it's expanding, wait for it to finish, then collapse it
					let waitInterval = setInterval(async () => {
						if (this.inProcess === false) {
							clearTimeout(waitInterval);
							await this.expand();
							this.hideError();
							this.hideCheck();
							this.showSpinner();
						}
					}, 50);
				}
				else {
					await this.expand();
					this.hideError();
					this.hideCheck();
					this.showSpinner();
				}
				break;

			case 'ok':
				if (this.inProcess === true) {
					// If it's expanding, wait for it to finish, then collapse it
					let waitInterval = setInterval(() => {
						if (this.inProcess === false) {
							clearTimeout(waitInterval);
							this.hideSpinner();
							this.hideError();
							this.showCheck();
						}
					}, 50);
				}
				else {
					this.hideSpinner();
					this.hideError();
					this.showCheck();
				}
				break;

			case 'error':
				if (this.inProcess === true) {
					// If it's expanding, wait for it to finish, then collapse it
					let waitInterval = setInterval(() => {
						if (this.inProcess === false) {
							clearTimeout(waitInterval);
							this.hideSpinner();
							this.hideCheck();
							this.showError();
						}
					}, 50);
				}
				else {
					this.hideSpinner();
					this.hideCheck();
					this.showError();
				}
				break;

			case 'hide':
				if (this.inProcess === true) {
					// If it's expanding, wait for it to finish, then collapse it
					let waitInterval = setInterval(() => {
						if (this.inProcess === false) {
							clearTimeout(waitInterval);
							this.hideCheck();
							this.hideError();
							this.hideSpinner();
							this.collapse()
						}
					}, 50);
				}
				else {
					this.hideCheck();
					this.hideError();
					this.hideSpinner();
					this.collapse();
				}
				break;

			case 'reset':
				this.expanded = false;
				this.hideCheck();
				this.hideError();
				this.hideSpinner();
				this.el.nativeElement.disabled = false;
				this.el.nativeElement.style.paddingRight = this.originalPadding;
				this.el.nativeElement.classList.remove("expand-button-spinner");
				break;
		}
	}

	private showSpinner() {
		this.spinnerVisible = true;
		this.el.nativeElement.disabled = true;
	}

	private hideSpinner() {
		this.spinnerVisible = false;
		this.el.nativeElement.disabled = false;
	}

	private showCheck() {
		this.checkVisible = true;
		this.showCheckInterval = setTimeout(() => {
			this.hideCheck();
			this.hideError();
			this.collapse();
		}, 2500);
	}

	private hideCheck() {
		clearTimeout(this.showCheckInterval);
		this.checkVisible = false;
	}

	private showError() {
		this.el.nativeElement.disabled = false;
		this.errorVisible = true;
	}

	private hideError() {
		this.errorVisible = false;
	}

	private async expand(): Promise<void> {

		if (this.inProcess === true || this.expanded === true) {
			return Promise.resolve();
		}

		this.expanded = true;
		this.inProcess = true;
		this.el.nativeElement.classList.remove("collapse-button-spinner");
		this.el.nativeElement.classList.add("expand-button-spinner");

		await SlickSleepService.sleep(350);
		this.originalPadding = this.el.nativeElement.style.paddingRight;
		this.el.nativeElement.style.paddingRight = "35px";
		this.inProcess = false;
		return Promise.resolve();
	}

	private async collapse(): Promise<void> {
		if (this.inProcess === true || this.expanded === false) {
			return Promise.resolve();
		}

		this.expanded = false;
		this.inProcess = true;
		this.el.nativeElement.classList.remove("expand-button-spinner");
		this.el.nativeElement.classList.add("collapse-button-spinner");
		await SlickSleepService.sleep(500);
		this.el.nativeElement.style.paddingRight = this.originalPadding;
		this.inProcess = false;
		Promise.resolve();
	}
}