import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from "../../../app-desktop/src/components";

import { SlickDialogModule, SlickButtonSpinnerModule,SlickCurrencyMaskModule } from "@slick-components";
import { RefundPaymentDialogComponent } from "./refund-payment-dialog.component"

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		FormsModule,
		SlickDialogModule, SlickButtonSpinnerModule, SlickCurrencyMaskModule
	],
	declarations: [RefundPaymentDialogComponent],
	exports: [RefundPaymentDialogComponent]
})
export class RefundPaymentDialogModule{ }
