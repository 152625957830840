<div class="legacy-chat">
	<div class="legacy-chat-group-list">
		<div class="d-flex flex-column" style="min-width: 300px; max-width: 300px;">
			<div class="bg-primary text-white ps-3 py-1" (click)="isChatGroupListExpanded = !isChatGroupListExpanded">
				<div *ngIf="unreadChatMessagesCount" style="float: right" class="badge badge-danger bg-danger mt-1 me-2">{{unreadChatMessagesCount}}</div>
				<i class="far fa-comment" style="font-size: .8rem;"></i> Chat
			</div>
			<div class="modal-content border" [ngClass]="{ 'legacy-chat-group-list-expanded': isChatGroupListExpanded === true, 'legacy-chat-group-list-collapsed': isChatGroupListExpanded === false }">
				<chat-group-list (onGroupClicked)="onGroupClicked($event)" [isLegacy]="true"></chat-group-list>
			</div>
		</div>
	</div>

</div>

<div *ngFor="let chatGroup of visibleGroups; let idx = index" class="legacy-chat-group" [style.right.px]="((idx * 310) + 360)">
	<div class="d-flex flex-column" style="min-width: 300px; max-width: 300px;">
		<div class="bg-primary text-white ps-3 py-1" (click)="toggleChatGroupExpanded(chatGroup)">
			<div class="d-flex align-items-center">
				<div class="flex-fill " *ngIf="chatGroup?.groupName" style="max-width: 300px; cursor: default; font-size:14px" title="{{chatGroup.longAttendantsString}}">{{chatGroup.groupName}}</div>
				<div class="flex-fill " *ngIf="chatGroup?.attendantsString && !chatGroup?.groupName" style="max-width: 300px; cursor: default; font-size: 14px" title="{{chatGroup.longAttendantsString}}">{{chatGroup.attendantsString}}</div>

				<div *ngIf="chatGroup.isMuted" class="me-1" style="margin-top: 1px; cursor: pointer" (click)="toggleMute($event,chatGroup)"><i class="far fa-volume-slash"></i></div>
				<div *ngIf="!chatGroup.isMuted" class="me-1" style="margin-top: 1px; cursor: pointer" (click)="toggleMute($event,chatGroup)"><i class="far fa-volume"></i></div>

				<div *ngIf="!chatGroup.isDeleted" style="margin-top: 1px; cursor: pointer" class="me-1" (click)="editGroupName($event,chatGroup)"><i class="far fa-pencil"></i></div>

				<div class="me-1" style="margin-top: 1px; cursor: pointer">
					<div *ngIf="!chatGroup.isDeleted && !chatGroup.isAllUsers" class="text-white" (click)="addAttendants($event, chatGroup)"><i class="fad fa-user-plus"></i></div>
				</div>
				<div class="me-1" *ngIf="!chatGroup.isDeleted" style="margin-top: 1px; cursor: pointer" (click)="deleteGroup($event,chatGroup)"><i class="bi bi-trash"></i></div>

				<div class="me-2" style="margin-top: 1px; cursor: pointer">
					<div class="bg-danger text-white px-1" style="font-size: .8rem; line-height: 1.2rem;" (click)="closeWindow(chatGroup)"><i class="far fa-times"></i></div>
				</div>
			</div>
		</div>
		<div class="modal-content border pb-1" [ngClass]="{ 'legacy-chat-group-expanded': chatGroup.isExpanded === true, 'legacy-chat-group-collapsed': chatGroup.isExpanded === false }">
			<chat-messages [chatGroup]="chatGroup"></chat-messages>
		</div>
	</div>
</div>

<chat-group-attendants #chatGroupAttendantsRef></chat-group-attendants>

<chat-group-attendants #chatGroupAttendantsRef></chat-group-attendants>
<slick-dialog #editGroupNameRef [showHeader]="false" width="300px">
	<div>
		Group Name
		<input type="text" class="form-control" [(ngModel)]="newChatGroupName" />
	</div>
	<slick-dialog-footer>
		<button class=" btn btn-outline-primary me-1" (click)="saveChatGroupName(newChatGroupName)"><i class="far fa-check"></i> Ok</button>
		<button class="btn btn-outline-danger" (click)="cancelGroupName()"><i class="bi bi-x-circle"></i> Cancel</button>
	</slick-dialog-footer>
</slick-dialog>