import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlickAccordionComponent } from "./slick-accordion.component";
import { SlickAccordionItemComponent } from "./slick-accordion-item.component";
import { SlickAccordionItemHeaderComponent } from "./slick-accordion-item-header.component";

@NgModule({
	imports: [CommonModule],
	exports: [CommonModule, SlickAccordionComponent, SlickAccordionItemComponent, SlickAccordionItemHeaderComponent],
	declarations: [SlickAccordionComponent, SlickAccordionItemComponent, SlickAccordionItemHeaderComponent]
})
export class SlickAccordionModule { }

export { SlickAccordionComponent }
export { SlickAccordionItemComponent }
export { SlickAccordionItemHeaderComponent }
