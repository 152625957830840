<slick-dialog #dialogRef header="Sales Order Edit" width="1000" top="15">
	<div class="mt-2 ps-1">

		<sales-order-edit #salesOrderEditComponentRef [(salesOrderModel)]="salesOrderModel" (onSave)="onSave($event)" isDialog="true"></sales-order-edit>
	</div>
		<slick-dialog-footer>
			<div class="d-flex pt-2 ps-3">
				<div>
					<button type="button" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-success me-2" style="width: 140px;" (click)="saveSalesOrder()"><i class="far fa-check"></i> Save</button>
					<button type="button" class="btn btn-outline-danger" style="width: 140px;" (click)="cancelSalesOrder()"><i class="far fa-times"></i> Cancel</button>
				</div>

				<div class="flex-fill me-3" style="text-align: right">
					<div class="btn-group me-1" role="group">
						<button type="button" class="btn btn-outline-primary" title="Convert to Invoice" (click)="convertToInvoice()"><i class="far fa-file-invoice-dollar"></i></button>
						<button type="button" class="btn btn-outline-primary" title="Print Sales Order" (click)="generateSalesOrderPdf()"><i class="far fa-print"></i></button>
						<button type="button" class="btn btn-outline-primary" title="Print Work Order" (click)="generateWorkOrderPdf()">
							<div style="position: absolute; font-size: 6px; border-radius: 6px; right: 6px; top: 18px; line-height: 12px; min-width: 12px; padding: 0;" class="bg-dark text-white">WO</div>
							<i class="far fa-print"></i>
						</button>
						<button type="button" class="btn btn-outline-primary" title="Email Sales Order" (click)="emailSalesOrder()"><i class="far fa-envelope"></i></button>
						<button type="button" class="btn btn-outline-primary" title="Text Sales Order" (click)="textSalesOrder()"><i class="far fa-comment-alt-lines"></i></button>
						<button type="button" class="btn btn-outline-primary" title="Pre-Payment" (click)="prePayment()"><i class="far fa-credit-card"></i></button>
					</div>

					<button *ngIf="canMoveToEstimate" type="button" class="btn btn-outline-primary" title="Back to Estimate" (click)="convertToEstimate()"><i class="far fa-arrow-alt-left" style="margin-right: 2px;"></i><i class="far fa-calculator"></i></button>

				</div>

			</div>
		</slick-dialog-footer>
</slick-dialog>
