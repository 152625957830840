import { CustomersAuthService } from '@services/utils/auth-services/customers-auth.service';
import { Component, OnChanges, SimpleChanges, Input, EventEmitter, Output } from "@angular/core";
import { LookupService, SleepService } from "@services";
import { ICustomerModel, ILathamCarrierModel } from "@models";

class SelectedCarrierModel {
	carrierId: string;
	name: string;
	isChecked: boolean;
}

@Component({
	selector: "latham-edit",
    templateUrl: "latham-edit.component.html" ,
    providers: [CustomersAuthService]
})

export class LathamEditComponent implements OnChanges {

    @Input() customerModel: ICustomerModel;
    @Input() lathamCarriers: string;
    @Output() lathamCarriersChange: EventEmitter<string> = new EventEmitter<string>();

    lathamCustomerCarriers: ILathamCarrierModel[];
    selectedCarriers: SelectedCarrierModel[];

	useAllCarriers: boolean;

    constructor(private lookupService: LookupService, public customersAuthService: CustomersAuthService) {
        this.lathamCustomerCarriers = this.lookupService.getLathamCarriers();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.lathamCarriers) {
            this.useAllCarriers = !this.customerModel.lathamCarriers;
		}

        if (!this.lathamCustomerCarriers || this.lathamCustomerCarriers.length === 0)
            this.useAllCarriers = true;

        const customerCarriers = (this.customerModel.lathamCarriers || '').split(",");
        this.selectedCarriers = this.lathamCustomerCarriers.map(c => {
            const selectedCarrier = new SelectedCarrierModel()
            selectedCarrier.carrierId = c.lathamCarrierId;
            selectedCarrier.name = c.name;
            selectedCarrier.isChecked = customerCarriers.findIndex(x => c.lathamCarrierId === x) >= 0;

            return selectedCarrier;
        });

    }

    toggleAll() {
        this.useAllCarriers = !this.useAllCarriers;
    }

    async toggleCarrier(selectedCarrier: SelectedCarrierModel) {
        await SleepService.sleep();

        const carrier = this.selectedCarriers.find(x => x.carrierId === selectedCarrier.carrierId);
        carrier.isChecked = !carrier.isChecked;

        const newCarriersString = this.selectedCarriers.map(x => {
            if (x.isChecked)
                return x.carrierId
            else
                return null;
        })
            .filter(x => x != null)
            .join(',');

        this.customerModel.lathamCarriers = newCarriersString;
        this.lathamCarriersChange.emit(this.customerModel.lathamCarriers);        
    }
}