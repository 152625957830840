<div *ngIf="!compact" #containerDiv class="slick-drop-down form-control d-flex flex-fill align-items-center position-relative" [slick-validation-indicator]="validationIndicator" [slickValidationType]="validationIndicatorType" id="slick-drop-down_{{uuid}}" attr.data-uuid="{{uuid}}" [class.open]="expanded" (click)="onDropdownClicked($event)">
    <div *ngIf="icon" class="slick-drop-down_icon p-0" style="position: absolute;"><i class="px-1" [ngClass]="icon"></i></div>
    <input #selectedTextInputRef *ngIf="!disabled" class='form-control ps-2 pe-4' [ngClass]="{'validation-border' : validationIndicatorType}" [class.ps-4]="!!icon" [(ngModel)]="selectedText" [attr.tabindex]="tabindex" (keyup)="onKeyUp($event)" (keydown)="onKeyDown($event)" (focus)="onDropdownClicked($event)" [readonly]="!isMobile" [placeholder]='placeholder' />
    <input #selectedTextInputRef *ngIf="disabled" class='form-control ps-2 pe-4' [class.ps-4]="!!icon" disabled="disabled" [ngModel]="selectedText" tabindex="-1" [readonly]="true" [placeholder]='placeholder' />
    <div style="position: absolute; right: 0;">
        <i class="fal fa-chevron-down me-2" style="pointer-events: none"></i>
    </div>
</div>

<div *ngIf="compact" #containerDiv class="slick-drop-down slick-drop-down-compact form-control d-flex flex-fill align-items-center" id="slick-drop-down_{{uuid}}" attr.data-uuid="{{uuid}}" [class.open]="expanded" (click)="onDropdownClicked($event)">
    <input #selectedTextInputRef *ngIf="!disabled" class='form-control me-2' [(ngModel)]="selectedText" [attr.tabindex]="tabindex" (keyup)="onKeyUp($event)" (keydown)="onKeyDown($event)" (focus)="onDropdownClicked($event)" [readonly]="!isMobile" [placeholder]='placeholder' />
    <input #selectedTextInputRef *ngIf="disabled" class='form-control me-2' [ngModel]="selectedText" tabindex="-1" [readonly]="true" [placeholder]='placeholder' />
    <div>
        <i class="fal fa-chevron-down me-2" style="pointer-events: none"></i>
    </div>
</div>


<ul *ngIf="isVisible" #dropdownList id="slick-drop-down_{{uuid}}" class='slick-drop-down-list-group dropdown-menu' [style.width]="listGroupWidth" [style.visibility]="(expanded === true) ? 'visible' : 'hidden'" [style.opacity]="(expanded === true) ? '1' : '0'" [style.max-height]="height" [style.width.px]="listGroupWidth" [style.left.px]="left" [style.top.px]="top">
    <li *ngFor="let item of visibleItems; let index = index;" (click)="onItemClick(item)" [class.active]="item.id === selectedItem?.id" [class.card-header]="selectedIndex === index" class="dropdown-item">{{item.text}}</li>
</ul>
