export interface ISlickSliderTickModel {
	majorTick: boolean;
	minorTick: boolean;
	text: string;
}

export class SlickSliderTickModel implements ISlickSliderTickModel {
	majorTick: boolean;
	minorTick: boolean;
	text: string;
}