export interface ITextChatTemplateModel {
    textChatTemplateId: number;
    templateName: string;
    templateText: string;
    active: boolean;
}

export class TextChatTemplateModel implements ITextChatTemplateModel {
    constructor() {
        this.textChatTemplateId = 0;
        this.templateName = '';
        this.templateText = '';
        this.active = true;
    }

    textChatTemplateId: number;
    templateName: string;
    templateText: string;
    active: boolean;
}