<div class="mt-2 mb-2">
	<div class="col-6 mt-3">
		<div class="form-group">
			Default Notes:
			<textarea class="form-control"
					  [(ngModel)]="customerModel.lathamDefaultNotes"
					  rows="4">
			</textarea>

		</div>
	</div>
	<div class="col-6 mt-3">
		<div class="form-group">
			Carrier Notes:
			<textarea class="form-control"
					  [(ngModel)]="customerModel.lathamCarrierNotes"
					  rows="4">
			</textarea>

		</div>
	</div>


	<div class="col-12 mt-3">
		<div class="form-group">
			<div>
				<div class="col-12">
					<input type="checkbox"
						   [ngModel]="useAllCarriers"
						   [disabled]="!customersAuthService.canEdit()"
						   (ngModelChange)="toggleAll()" />
					Use All Carriers
				</div>
			</div>
		</div>

		<div *ngIf="!useAllCarriers">
			<div *ngFor="let selectedCarrier of selectedCarriers">
				<div class="form-group">
					<div>
						<div class="col-12">
							<input type="checkbox"
								   [ngModel]="selectedCarrier.isChecked"
								   [disabled]="!customersAuthService.canEdit()"
								   (ngModelChange)="toggleCarrier(selectedCarrier)" />
							{{ selectedCarrier.name }}
						</div>
					</div>
				</div>
			</div>
		</div> 
	</div>
</div>
