<div class="user-name">
    <div
        class="valid-icons"
        *ngIf="userName && isLoading === false && userNameIsDuplicate !== null"
    >
        <i
            *ngIf="userNameIsDuplicate === false"
            style="color: green; font-size: 22px;"
            class="far fa-check-circle"
        ></i>
        <i
            *ngIf="userNameIsDuplicate === true"
            style="color: red; font-size: 22px;"
            class="bi bi-x-circle"
        ></i>
    </div>
    <div class="loading waiting-gif" *ngIf="isLoading === true"></div>
    <input
        type="text"
        autocomplete="false"
        class="form-control"
        name="userName"
        [(ngModel)]="userName"
        [disabled]="readonly"
        (keyup)="onUserNameChanged()"
        required
    />
</div>
<form-error *ngIf="userNameIsDuplicate === true"
    >This user name is already taken.</form-error
>
