<div #slickTreeViewRef id="slick-tree-view_{{uuid}}" class="slick-tree-view position-relative pe-2">
	<div class="d-flex slick-tree-view-node" [attr.data-uuid]="displayNode.uuid" *ngFor="let displayNode of displayNodes">
		<div class="slick-tree-view_indentation">
			<i *ngFor="let indent of displayNode.indentArray" class="far fa-caret-right me-1" style="opacity: 0"></i>
			<i *ngIf="!displayNode.hasChildNodes" class="far fa-caret-right me-1" style="opacity: 0"></i>
			<i *ngIf="displayNode.hasChildNodes && !displayNode.expanded" class="far fa-caret-right me-1" style="cursor: pointer" (click)="expandNode(displayNode)"></i>
			<i *ngIf="displayNode.hasChildNodes && displayNode.expanded" class="far fa-caret-down me-1" style="cursor: pointer" (click)="collapseNode(displayNode)"></i>
		</div>
		<div class="d-flex px-1" [ngClass]="{'bg-success text-white' : displayNode.uuid === selectedUuid }">
			<div *ngIf="displayNode.node.iconClass || displayNode.node.iconUrl" class="me-1 slick-tree-view_icon">
				<i *ngIf="displayNode.node.iconClass" [style.color]="displayNode.node.iconColor" [ngClass]="displayNode.node.iconClass"></i>
			</div>
			<div *ngIf="!displayNode.editingText" style="cursor: pointer" (click)="selectNode(displayNode)" (dblclick)="editText(displayNode)">{{displayNode.text}}</div>
			<div *ngIf="displayNode.editingText" class="slick-tree-view_node-text-edit"><input type="text" class="form-control slick-tree-view_node-text-edit_{{displayNode.uuid}}" [(ngModel)]="displayNode.text" (keydown)="onTextKeydown($event, displayNode)" /></div>
		</div>
	</div>

	<div #contextMenuRef class='slick-tree-view_context-menu dropdown-menu p-0 show menu-hidden'>
		<div *ngIf="contextMenuShowAdd" class="px-2 dropdown-item" (click)="addNode()">Add</div>
		<div *ngIf="contextMenuShowDelete" class="px-2 dropdown-item" (click)="deleteNode()">Delete</div>
		<div *ngIf="contextMenuShowEditText" class="px-2 dropdown-item" (click)="editNodeText()">Edit Name</div>
	</div>
</div>

<slick-confirm-dialog #deleteNodeConfirmRef>
	Are you sure you want to delete {{deleteNodeName}}?
</slick-confirm-dialog>