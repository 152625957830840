<slick-dialog #dialogRef header="Latham Bill Compare" [width]="800" (onClose)="onDialogClose()">
	<div *ngIf="loading" class="m-1">
		<span class="waiting-gif"></span> Getting bill from Latham...
	</div>
	<div *ngIf="!loading">
		<h4 class="border-0">Invoice # {{invoiceModel?.invoiceNumber}}</h4>

		<div class="d-flex flex-fill mb-2">
			<div class="me-2 flex-fill">
				<label>Ship Date</label>
				<input class="form-control"
					   (ngModelChange)="onShipDateChanged($event)"
					   [slick-date-picker] [(ngModel)]="shipDate" />
				<form-error *ngIf="submitted && invalidDate">Invalid Ship Date</form-error>
			</div>
			<div class="me-2 flex-fill">
				<label>Warehouse</label>
				<slick-drop-down [disabled]="false"
								 [items]="inventoryWarehouses"
								 [ngModel]="inventoryWarehouseId"
								 (onSelect)="onInventoryWarehouseSelect($event)"
								 allowEmpty="false"></slick-drop-down>
				<form-error *ngIf="submitted && !inventoryWarehouseId">Invalid Warehouse</form-error>

			</div>
			<div class="flex-fill">
				<label>Shipping</label>
				<input type="text" class="form-control" [slick-currency-mask] [(ngModel)]="shippingCharge" />
			</div>
		</div>


		<table class="table table-sm table-bordered">
			<colgroup>
				<col />
			</colgroup>

			<thead>
				<tr>
					<td>Skedit Sku</td>
					<td>Skedit Qty</td>
					<td>Skedit Cost</td>
					<td style="border: 0"></td>
					<td>Latham Sku</td>
					<td>Latham Qty</td>
					<td>Latham Price</td>
				</tr>
			</thead>

			<tbody>
				<tr *ngFor="let displayLineItem of displayLineItems">
					<td [class]="displayLineItem.classSkeditSku">{{displayLineItem.skeditLineItem?.sku}}</td>
					<td [class]="displayLineItem.classSkeditQty">{{displayLineItem.skeditLineItem?.quantity}}</td>
					<td [class]="displayLineItem.classSkeditCost">{{displayLineItem.skeditLineItem?.cost | currency}}</td>
					<td style="border-top: 0; border-bottom: 0;"></td>
					<td [class]="displayLineItem.classLathamSku">{{displayLineItem.lathamLineItem?.sku}}</td>
					<td [class]="displayLineItem.classLathamQty">{{displayLineItem.lathamLineItem?.quantity}}</td>
					<td [class]="displayLineItem.classLathamPrice">{{displayLineItem.lathamLineItem?.price | currency}}</td>
				</tr>
			</tbody>
		</table>

		<div *ngIf="!loading" class="mt-3 flex-fill justify-content-center" style="text-align: center;">
			<button class="btn btn-outline-primary me-2" style="width: 200px;" [slick-button-spinner]="spinnerStatus" (click)="process()"><i class="far fa-check"></i> Fix Invoice</button>
			<button *ngIf="!purchaseOrder" class="btn btn-outline-primary" style="width: 200px;" [slick-button-spinner]="poSpinnerStatus" (click)="generatePO()"><i class="far fa-file-invoice"></i> Generate PO</button>
			<button *ngIf="purchaseOrder" class="btn btn-outline-primary" disabled="disabled" style="width: 300px;"><i class="far fa-file-invoice"></i> PO has been generated</button>
		</div>
	</div>
</slick-dialog>

