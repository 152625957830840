import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { SlickUtilsService } from "../utils/slick-utils.service";
import { SlickSleepService } from "../utils/slick-sleep.service";
import { ISlickFileModel } from "../slick-file-model/slick-file.model";
import { SlickPhotoGalleryComponent, SlickPhotoGalleryModel } from "../slick-photo-gallery/slick-photo-gallery.module";

@Component({
	selector: 'slick-file-list',
	templateUrl: 'slick-file-list.component.html'
})
export class SlickFileListComponent implements OnChanges {
	@Input("files") files: ISlickFileModel[];
	@Input("allowDelete") allowDelete: boolean = true;
	@Input("showPhotoGallery") showPhotoGallery: boolean = false;
	@Input("showCheckboxes") showCheckboxes: boolean = false;
	@Input("thumbnailSize") thumbnailSize: number = 90;
	@Input("confirmDelete") confirmDelete: boolean = true;
	@Output("filesEmitter") filesEmitter: EventEmitter<ISlickFileModel[]> = new EventEmitter();
	@Output("onFileDelete") onFileDeleteEmitter: EventEmitter<ISlickFileModel> = new EventEmitter();
	@Output("onFileCheckChanged") onFileCheckChanged: EventEmitter<ISlickFileModel> = new EventEmitter();
	@Output("onFileClicked") onFileClicked: EventEmitter<ISlickFileModel> = new EventEmitter();

	@ViewChild("photoGalleryRef") photoGalleryRef: SlickPhotoGalleryComponent;

	photos: SlickPhotoGalleryModel[] = [];
	uuid: string;

	private lastCheckedIdx: number = null;

	constructor() {
		this.uuid = SlickUtilsService.newGuid();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.thumbnailSize)
			this.thumbnailSize = parseInt(this.thumbnailSize.toString());

		if (changes.allowDelete && changes.allowDelete.currentValue)
			this.allowDelete = (changes.allowDelete.currentValue.toString().toLowerCase() !== 'false');

		if (changes.showPhotoGallery && changes.showPhotoGallery.currentValue)
			this.showPhotoGallery = (changes.showPhotoGallery.currentValue.toString().toLowerCase() !== 'false');

		if (changes.showCheckboxes && changes.showCheckboxes.currentValue)
			this.showCheckboxes = (changes.showCheckboxes.currentValue.toString().toLowerCase() !== 'false');

		if (changes.deleteConfirmation && changes.deleteConfirmation.currentValue)
			this.confirmDelete = (this.confirmDelete.toString() === 'true') ? true : false;
	}

	fileClicked(file: ISlickFileModel, idx: number) {
		if (this.showPhotoGallery === true) {
			this.photoGalleryRef.showPhotoGallery(idx);
		}

		if (this.onFileClicked)
			this.onFileClicked.emit(file);
	}

	onFileChange(file: ISlickFileModel, idx: number) {
		this.files[idx] = file;
		this.filesEmitter.emit(this.files);
	}

	async onFileDelete(file: ISlickFileModel, idx) {
		this.files.splice(idx, 1);
		this.filesEmitter.emit(this.files);
		if (this.onFileDeleteEmitter)
			this.onFileDeleteEmitter.emit(file);
	}

	async onCheckChanged(e: MouseEvent, fileIdx: number) {
		await SlickSleepService.sleep();

		if (e.shiftKey === true && this.lastCheckedIdx !== null) {
			let startIdx = (this.lastCheckedIdx < fileIdx) ? this.lastCheckedIdx : fileIdx;
			let endIdx = (this.lastCheckedIdx < fileIdx) ? fileIdx : this.lastCheckedIdx;

			for (let i = startIdx; i <= endIdx; i++) {
				this.files[i].isChecked = true;
				if (this.onFileCheckChanged)
					this.onFileCheckChanged.emit(this.files[i]);
			}
		}
		else {
			this.lastCheckedIdx = fileIdx;
			if (this.onFileCheckChanged)
				this.onFileCheckChanged.emit(this.files[fileIdx]);
		}


	}

	checkAllFiles() {
		this.files.forEach(x => {
			if (x.isChecked === false && this.onFileCheckChanged) {
				x.isChecked = true;
				this.onFileCheckChanged.emit(x);
			}

			x.isChecked = true;
		});
	}

	uncheckAllFiles() {
		this.files.forEach(x => {
			if (x.isChecked === true && this.onFileCheckChanged) {
				x.isChecked = false;
				this.onFileCheckChanged.emit(x);
			}

			x.isChecked = false;
		});
	}

	getCheckedFiles(): ISlickFileModel[] {
		return this.files.filter(x => x.isChecked === true);
	}
}
