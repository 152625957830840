<div class="slick-paging">
	<div class="ms-2 d-flex flex-column">
		<ul class="pagination m-0">
			<li class="page-item" [class.disabled]="!totalRecords || pageNumber === 1"><button type="button" class="page-link" (click)="previousPage()" style="margin-left: 1px;"><i class="far fa-angle-left"></i></button></li>
			<li *ngIf="pageNumber > 10" class="page-item"><button type="button" class="page-link" (click)="first()" style="margin-left: 1px;">1</button></li>
			<li *ngIf="preDisplayEllipses === true" class="page-item"><button type="button" class="page-link" (click)="previousSet()" style="margin-left: 1px;"><i style="line-height: .8rem; vertical-align: bottom;" class="far fa-ellipsis-h"></i></button></li>

			<li *ngIf="totalPages > 10" class="page-item" style="margin-left: 2px;"></li>

			<li class="page-item" *ngFor="let displayPageNumber of preDisplayPageNumbers">
				<button type="button" class="page-link" style="margin-right: 2px;" (click)="selectPage(displayPageNumber)">{{displayPageNumber}}</button>
			</li>

			<li class="page-item" [class.active]="pageNumber === displayPageNumber" *ngFor="let displayPageNumber of displayPageNumbers">
				<button *ngIf="displayPageNumber !== -1" type="button" class="page-link" (click)="selectPage(displayPageNumber)">{{displayPageNumber}}</button>
				<div *ngIf="displayPageNumber === -1" class="page-link ellipses px-1 text-white bg-primary"><i class="far fa-ellipsis-h" style="position: relative;	top: 4px;"></i></div>
			</li>

			<li *ngIf="postDisplayPageNumbers.length > 0" class="page-item" style="margin-left: 2px;"></li>

			<li class="page-item" *ngFor="let displayPageNumber of postDisplayPageNumbers">
				<button type="button" class="page-link" style="margin-right: 2px;" (click)="selectPage(displayPageNumber)">{{displayPageNumber}}</button>
			</li>

			<li *ngIf="postDisplayEllipses === true" class="page-item"><button type="button" class="page-link" (click)="nextSet()" style="margin-right: 2px;"><i style="line-height: .8rem; vertical-align: bottom;" class="far fa-ellipsis-h"></i></button></li>
			<li *ngIf="totalPages > 10" class="page-item" [class.disabled]="pageNumber === totalPages"><button type="button" class="page-link" (click)="last()" style="margin-right: 2px;">{{totalPages}}</button></li>
			<li class="page-item" [class.disabled]="!totalRecords || pageNumber === totalPages"><button type="button" class="page-link" (click)="nextPage()"><i class="far fa-angle-right"></i></button></li>
		</ul>
		<div *ngIf="totalRecords <= 0" style="font-size: .8em; line-height: 1.4em;">&nbsp;</div>
		<div *ngIf="totalRecords > 0" style="font-size: .8em; line-height: 1.4em;">
			Showing {{currentRecordNumber}}-{{endRecordNumber}} of {{totalRecords}}
		</div>
	</div>
</div>
