import { CustomerModel } from "../customers/customer.model";
import { IInvoiceModel, InvoiceModel } from "../invoices/invoice.model";
import { LineItemModel } from "../line-items/line-item.model";
import { IPurchaseOrderModel } from "../purchase-orders/purchase-order.model";
import { IJellyFishOrderSpecsModel, JellyFishOrderSpecsModel } from "./jelly-fish-order-specs.model";

export interface IJellyFishOrderModel
{
	jellyFishOrderId: number;
	orderNumber?: string;
	parentOrderId: number;
	childOrderId: number;
	paymentId?: number;
	submittedDate: Date;
	approved: boolean;
	approvalDate: Date;
	completedDate: Date;
	jellyFishOrderNotes: string;
	orderNotes: string;
	homeownerCustomerId?: number;
	invoiceId?: number;
	purchaseOrderId?: number;
	selectedCreditCardPaymentTypeId?: number;
	status: string;
	jobId?: number;

	homeowner: CustomerModel;
	dealer: CustomerModel;
	invoiceModel: IInvoiceModel;
	purchaseOrderModel: IPurchaseOrderModel;
	jellyFishOrderSpecsModel: IJellyFishOrderSpecsModel
}

export class JellyFishOrderModel implements IJellyFishOrderModel {
	constructor() {
		this.jellyFishOrderSpecsModel = new JellyFishOrderSpecsModel();
		this.status = "Quote";
	}

	jellyFishOrderId: number;
	parentOrderId: number;
	orderNumber?: string;
	childOrderId: number;
	paymentId?: number;
	submittedDate: Date;
	approved: boolean;
	approvalDate: Date;
	completedDate: Date;
	jellyFishOrderNotes: string;
	orderNotes: string;
	homeownerCustomerId?: number;
	invoiceId?: number;
	selectedCreditCardPaymentTypeId?: number;
	status: string;
	jobId?: number;


	homeowner: CustomerModel;
	dealer: CustomerModel;
	invoiceModel: IInvoiceModel;
	purchaseOrderModel: IPurchaseOrderModel;
	jellyFishOrderSpecsModel: IJellyFishOrderSpecsModel
}
