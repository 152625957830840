<slick-dialog #dialogRef header="Release Notes">
    <section class="ms-4">
        <h4><strong> What's New</strong></h4>
        <div *ngFor="let versionModel of versionModels">
            <div class="mt-2 mb-2" style="font-size: 13px; color:darkgray">
                Version {{versionModel.version}}
            </div>
            <span class="versionNotes" [innerHTML]="versionModel.notes">
            </span>
        </div>
    </section>

    <slick-dialog-footer>
        <div style="text-align: center">
            <button class="btn btn-outline-primary" (click)="close()"><i></i> OK </button>
        </div>
    </slick-dialog-footer>

</slick-dialog>
