import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { SlickSearchBarModule } from "../slick-search-bar/slick-search-bar.module";
import { SlickAutoCompleteComponent } from "./slick-auto-complete.component";
import { SlickAutoCompleteListItemTemplateDirective } from "./slick-auto-complete-list-item-template.directive";
import { SlickFunctionLockService } from "../utils/slick-function-lock.service";

@NgModule({
	imports: [CommonModule, FormsModule, SlickSearchBarModule],
	declarations: [SlickAutoCompleteComponent, SlickAutoCompleteListItemTemplateDirective],
	exports: [SlickAutoCompleteComponent, SlickAutoCompleteListItemTemplateDirective],
	providers: [SlickFunctionLockService]
})
export class SlickAutoCompleteModule { }

export { SlickAutoCompleteComponent }
export { SlickAutoCompleteListItemTemplateDirective }