import { Component, Input } from '@angular/core';
import { GlobalsService } from '@services';
import { IJobCostingDetailModel } from '@models';

@Component({
	selector: 'job-costing-details',
	templateUrl: './job-costing-details.component.html',
	styleUrls: ['./job-costing-details.component.scss']
})
export class JobCostingDetailsComponent{
	@Input() jobCostingDetailModel: IJobCostingDetailModel;
	@Input() isValid: boolean;

	canAccessSettings: boolean = GlobalsService.checkPermission("Settings", "Access");
	constructor() {	}

}
