import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { DropdownModel, ICustomerModel, IDropdownModel, IInvoiceModel, ILathamOrderModel, IShippingAddressModel, ITaxRateGroupModel, ShippingAddressModel } from "@models";
import { GlobalsService, HttpService, InventoryService, LathamOrdersService, SleepService, UtilsService } from "@services";
import { ShippingAddressDialogComponent } from "./shipping-address-dialog.component";
import { InventoryStore } from "@stores";

@Component({
	selector: "shipping-address",
	templateUrl: 'shipping-address.component.html',
	providers: [LathamOrdersService]
})

export class ShippingAddressComponent implements OnInit, OnChanges {
	@Input() customer: ICustomerModel;
	@Input() shippingAddress: IShippingAddressModel;
	@Input() lathamOrderId: number;
	@Input() editable: boolean;
	@Input() isMobile: boolean;
	@Output() shippingAddressChange: EventEmitter<IShippingAddressModel> = new EventEmitter();

	@ViewChild("shippingAddressDialogRef") shippingAddressDialogRef: ShippingAddressDialogComponent;

	isCanada = (GlobalsService.company.companyId === 3);
	lathamOrder: ILathamOrderModel;
	shippingOptions: IDropdownModel[];
	shipTo: number;

	constructor(private httpService: HttpService,
		private lathamOrderService: LathamOrdersService,
		private inventoryStore: InventoryStore,
		private inventoryService: InventoryService) { }

	async ngOnInit() {
		if (!this.shippingOptions) 
			await this.inventoryStore.refreshInventoryWarehouses();
	}

	async ngOnChanges(changes: SimpleChanges) {
		this.editable = (this.editable === null || this.editable === undefined) ? true : (this.editable.toString() === 'true')
		let shippingOptions: IDropdownModel[] = [];
		if (GlobalsService.company.useInventory)
			shippingOptions = this.inventoryStore.getInventoryWarehousesForDropdown();
		if (changes.lathamOrderId && this.lathamOrderId) {
			this.lathamOrder = await this.lathamOrderService.getLathamOrder(this.lathamOrderId);

			if (this.lathamOrder.orderSpecs.homeOwner.customerId > 0)
				shippingOptions.unshift(new DropdownModel(this.lathamOrder.orderSpecs.homeOwner.customerId, "Home Owner", "Customer"));
			if (this.lathamOrder.orderSpecs.distributor.customerId > 0)
				shippingOptions.unshift(new DropdownModel(this.lathamOrder.orderSpecs.distributor.customerId, "Distributor", "Customer"));
			if (this.lathamOrder.orderSpecs.builder.customerId > 0)
				shippingOptions.unshift(new DropdownModel(this.lathamOrder.orderSpecs.builder.customerId, "Builder", "Customer"));
		}
		if (!shippingOptions)
			shippingOptions = [];

		shippingOptions.unshift(new DropdownModel(0, "Custom", "Custom"));

		this.shippingOptions = [...shippingOptions];
		
		this.shipTo = this.shippingAddress?.shipToCustomerId ?? this.shippingAddress?.shipToWarehouseId ?? 0;

		if (changes.customer && !this.shippingAddress.useCustomAddress) {
			this.fillShippingAddressFromCustomer();
			await this.updateTaxRate();
			this.shippingAddressChange.emit(this.shippingAddress);
		}

		this.shippingAddress = {...this.shippingAddress}
	}

	async onLinkAddressesChange() {
		this.shippingAddress.useCustomAddress = true;

			const updatedShippingAddress = await this.shippingAddressDialogRef.openDialog(this.shippingAddress);

			// If they didn't save the dialog, go back to a linked address
			if (updatedShippingAddress == null) {
				this.shippingAddress.useCustomAddress = false;
				this.fillShippingAddressFromCustomer();
			}
			else {
				this.shippingAddress = updatedShippingAddress;
			}

		await this.updateTaxRate();
		this.shippingAddressChange.emit(this.shippingAddress);
	}

	async editShippingAddress() {
		const updatedShippingAddress = await this.shippingAddressDialogRef.openDialog(this.shippingAddress);

		if (updatedShippingAddress != null) {
			this.shippingAddress = updatedShippingAddress;

			await this.updateTaxRate();
			this.shippingAddressChange.emit(this.shippingAddress);
		}
	}

	async onShippingAddressSelected(shipTo: IDropdownModel) {
		this.shippingAddress.useCustomAddress = true;
		this.shippingAddress.shipToWarehouseId = null;
		this.shippingAddress.shipToCustomerId = null;
		this.shipTo = shipTo.id;
		if (shipTo.customField === "Customer") {
			this.shippingAddress.shipToCustomerId = shipTo.id;
			if (shipTo.text === "Builder") {
				this.shippingAddress.companyName = this.lathamOrder.orderSpecs.builder.companyName;
				this.shippingAddress.firstName = this.lathamOrder.orderSpecs.builder.firstName;
				this.shippingAddress.lastName = this.lathamOrder.orderSpecs.builder.lastName;
				this.shippingAddress.address1 = this.lathamOrder.orderSpecs.builder.address1;
				this.shippingAddress.address2 = this.lathamOrder.orderSpecs.builder.address2;
				this.shippingAddress.city = this.lathamOrder.orderSpecs.builder.city;
				this.shippingAddress.state = this.lathamOrder.orderSpecs.builder.state;
				this.shippingAddress.zipcode = this.lathamOrder.orderSpecs.builder.zipcode;
				this.shippingAddress.country = this.lathamOrder.orderSpecs.builder.country;
			}
			if (shipTo.text === "Distributor") {
				this.shippingAddress.companyName = this.lathamOrder.orderSpecs.distributor.companyName;
				this.shippingAddress.firstName = this.lathamOrder.orderSpecs.distributor.firstName;
				this.shippingAddress.lastName = this.lathamOrder.orderSpecs.distributor.lastName;
				this.shippingAddress.address1 = this.lathamOrder.orderSpecs.distributor.address1;
				this.shippingAddress.address2 = this.lathamOrder.orderSpecs.distributor.address2;
				this.shippingAddress.city = this.lathamOrder.orderSpecs.distributor.city;
				this.shippingAddress.state = this.lathamOrder.orderSpecs.distributor.state;
				this.shippingAddress.zipcode = this.lathamOrder.orderSpecs.distributor.zipcode;
				this.shippingAddress.country = this.lathamOrder.orderSpecs.distributor.country;
			}
			if (shipTo.text === "Home Owner") {
				this.shippingAddress.companyName = this.lathamOrder.orderSpecs.homeOwner.companyName;
				this.shippingAddress.firstName = this.lathamOrder.orderSpecs.homeOwner.firstName;
				this.shippingAddress.lastName = this.lathamOrder.orderSpecs.homeOwner.lastName;
				this.shippingAddress.address1 = this.lathamOrder.orderSpecs.homeOwner.address1;
				this.shippingAddress.address2 = this.lathamOrder.orderSpecs.homeOwner.address2;
				this.shippingAddress.city = this.lathamOrder.orderSpecs.homeOwner.city;
				this.shippingAddress.state = this.lathamOrder.orderSpecs.homeOwner.state;
				this.shippingAddress.zipcode = this.lathamOrder.orderSpecs.homeOwner.zipcode;
				this.shippingAddress.country = this.lathamOrder.orderSpecs.homeOwner.country;
			}

		}
		else if (shipTo.customField === "Custom") {
			this.shippingAddress.shipToCustomerId = null;
			this.shippingAddress.shipToWarehouseId = null;
			this.shippingAddress.shipToWarehouseName = null;

		}
		else {
			this.shippingAddress.shipToWarehouseId = shipTo.id;
			const warehouse = await this.inventoryService.getInventoryWarehouse(shipTo.id);
			this.shippingAddress.address1 = warehouse.address.address1;
			this.shippingAddress.address2 = warehouse.address.address2;
			this.shippingAddress.city = warehouse.address.city;
			this.shippingAddress.state = warehouse.address.state;
			this.shippingAddress.zipcode = warehouse.address.zipcode;
			this.shippingAddress.country = warehouse.address.country;
			this.shippingAddress.shipToWarehouseName = warehouse.warehouseName;
		}

		await this.updateTaxRate();
		this.shippingAddressChange.emit(this.shippingAddress);
	}


	private fillShippingAddressFromCustomer() {
		if (this.editable) {
			if (!this.customer) {
				this.shippingAddress.address1 = null;
				this.shippingAddress.address2 = null;
				this.shippingAddress.city = null;
				this.shippingAddress.state = null;
				this.shippingAddress.zipcode = null;
				this.shippingAddress.country = null;
				return;
			}

			if (this.customer.linkShippingAddress) {
				this.shippingAddress.companyName = this.customer?.companyName;
				this.shippingAddress.firstName = this.customer?.firstName;
				this.shippingAddress.lastName = this.customer?.lastName
				this.shippingAddress.address1 = this.customer?.address1;
				this.shippingAddress.address2 = this.customer?.address2;
				this.shippingAddress.city = this.customer?.city;
				this.shippingAddress.state = this.customer?.state;
				this.shippingAddress.zipcode = this.customer?.zipcode;
				this.shippingAddress.country = this.customer?.country;
			}
			else {
				this.shippingAddress.companyName = this.customer?.companyName;
				this.shippingAddress.firstName = this.customer?.firstName;
				this.shippingAddress.lastName = this.customer?.lastName
				this.shippingAddress.address1 = this.customer?.shippingAddress1;
				this.shippingAddress.address2 = this.customer?.shippingAddress2;
				this.shippingAddress.city = this.customer?.shippingCity;
				this.shippingAddress.state = this.customer?.shippingState;
				this.shippingAddress.zipcode = this.customer?.shippingZipcode;
				this.shippingAddress.country = this.customer?.shippingCountry;

			}
		}
	}

	private async updateTaxRate() {
		if (GlobalsService.company.useCereTax && this.editable) {
			const taxRateGroup: ITaxRateGroupModel = await this.httpService.post('taxRates/getTaxRateGroupByShippingAddress', this.shippingAddress);

			this.shippingAddress.taxRates = [];
			this.shippingAddress.taxRate = 0;
			if (taxRateGroup) {
				this.shippingAddress.taxRates = [...taxRateGroup.taxRates];
				this.shippingAddress.taxRate = this.shippingAddress.taxRates
					.map(rate => rate.taxRatePercent)
					.reduce((total, rate) => total + rate);
				this.shippingAddress.taxRate = UtilsService.round(this.shippingAddress.taxRate, 4);
			}
		}
	}

}