export interface IAppointmentSmartlistQuestionModel {
	appointmentId: number;
	smartlistQuestionId: number;
	question: string;
	isChecked: boolean;
}

export class AppointmentSmartlistQuestionModel implements IAppointmentSmartlistQuestionModel {
	constructor(smartlistQuestionId: number, question: string, isChecked: boolean) {
		this.smartlistQuestionId = smartlistQuestionId;
		this.question = question;
		this.isChecked = isChecked
	}

	appointmentId: number;
	smartlistQuestionId: number;
	question: string;
	isChecked: boolean;
}