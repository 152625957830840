export interface ICalcHourModel {
	calcHour: string;
	hour: number;
	minute: number;
}

export class CalcHourModel implements ICalcHourModel {
	constructor(calcHour: string) {
		this.calcHour = calcHour
	}

	calcHour: string;
	get hour(): number {
		return parseInt(this.calcHour.substring(0, 2));
	}
	get minute(): number {
		return parseInt(this.calcHour.substring(2, 4));
	}
}