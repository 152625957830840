export interface ILineItemTotalsModel {
	cost: number;
	price: number;
}

export class LineItemTotalsModel implements ILineItemTotalsModel {
	constructor() {
		this.cost = 0;
		this.price = 0;
	}

	cost: number;
	price: number;
}