export interface IAppointmentUserStrikeModel {
    appointmentUserStrikeId: number;
    appointmentId: number;
    userId: number;
    appointmentUserStrikeTypeId?: number;
    awardedBy: string;
    awardedDate: Date;
    userName: string;
    notes: string;
    badStrike: boolean;
}

export class AppointmentUserStrikeModel implements IAppointmentUserStrikeModel {
    appointmentUserStrikeId: number;
    appointmentId: number;
    userId: number;
    appointmentUserStrikeTypeId?: number;
    awardedBy: string;
    awardedDate: Date;
    userName: string;
    notes: string;
    badStrike: boolean;
}
