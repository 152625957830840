import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { SlickButtonSpinnerModule, SlickConfirmDialogModule, SlickDatePickerModule, SlickDialogModule, SlickDropDownModule, SlickToastModule, SlickToastService } from "@slick-components";

import { JellyFishOrderSpecsComponent } from "./jelly-fish-order-specs.component";



@NgModule({
	imports: [CommonModule, FormsModule,
		SlickButtonSpinnerModule, SlickConfirmDialogModule, SlickDatePickerModule, SlickDialogModule, SlickDropDownModule, SlickToastModule],
	declarations: [JellyFishOrderSpecsComponent],
	exports: [JellyFishOrderSpecsComponent],
	providers: [SlickToastService]
})
export class JellyFishOrderSpecsModule {
}
