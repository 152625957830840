import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from "@components";
import { SystemLogsModule } from "@components/system-logs";
import { CustomerSearchModule } from "@components/customer-search";
import { CreditCardDialogModule } from '@components/credit-card-dialog/credit-card-dialog.module';
import { EmailerDialogModule } from "@components/emailer-dialog";
import { ShippingAddressModule } from "@shared-components/shipping-address";
import { TexterDialogModule } from "@shared-components/texter-dialog";

import { InvoiceCustomFieldsModule } from "@shared-components/invoice-custom-fields"

import { SlickButtonSpinnerModule, SlickConfirmDialogModule, SlickContainerModule, SlickCurrencyMaskModule, SlickDatePickerModule, SlickDialogModule, SlickDropDownModule, SlickFileListModule, SlickScreenBlockerModule, SlickTabsModule, SlickToastModule, SlickValidationIndicatorModule } from "@slick-components";

import { LineItemsModule } from "@components/line-items"

import { EstimateEditComponent } from "./estimate-edit/estimate-edit.component";
import { EstimateEditDialogComponent } from "./estimate-edit-dialog/estimate-edit-dialog.component";
import { InvoicesService } from "@services";
import { JellyFishOrderSpecsModule } from "@shared-components/jelly-fish-order-specs/jelly-fish-order-specs.module"
import { CreditCardSelectorDialogModule } from "@shared-components/credit-card-selector-dialog/credit-card-selector-dialog.module";
import { JobCostingDetailsModule } from "@app/job-costing-details/job-costing-details.module";


@NgModule({
	imports: [CommonModule,
		FormsModule,
		ComponentsModule,
		CreditCardSelectorDialogModule,
		SystemLogsModule,
		CustomerSearchModule,
		CreditCardDialogModule,
		JellyFishOrderSpecsModule,
		JobCostingDetailsModule,
		LineItemsModule,
		EmailerDialogModule,
		TexterDialogModule,
		ShippingAddressModule,
		InvoiceCustomFieldsModule,
		SlickButtonSpinnerModule, SlickConfirmDialogModule, SlickContainerModule, SlickCurrencyMaskModule, SlickDialogModule, SlickDropDownModule, SlickFileListModule, SlickDatePickerModule, SlickScreenBlockerModule, SlickTabsModule, SlickToastModule, SlickValidationIndicatorModule],
	exports: [EstimateEditComponent, EstimateEditDialogComponent],
	declarations: [EstimateEditComponent, EstimateEditDialogComponent],
	providers: [InvoicesService]
})
export class EstimatesComponentsModule { }

