export interface IDeckEdgeModel {
    encapsulation: string;
    corners: string;
    cliponCoping: string;
	reusableForms: string;
	showCliponCoping: boolean;
	showCorners: boolean;
	showEncapsulation: boolean;
	showDeckEdge: boolean;
	showReusableForms: boolean;
}

export class DeckEdgeModel implements IDeckEdgeModel
{
    encapsulation: string;
    corners: string;
    cliponCoping: string;
	reusableForms: string;
	showCliponCoping: boolean;
	showCorners: boolean;
	showEncapsulation: boolean;
	showDeckEdge: boolean;
	showReusableForms: boolean;
}