<slick-dialog #splitInvoiceDialogRef width="800" header="Split {{labelText}}">	
	<table class="table table-sm table-striped table-borderless" style="width: 740px; margin-left: 20px;">
		<colgroup>
			<col style="width: 20px" />
			<col style="width: 120px" />
			<col />
		</colgroup>

		<thead>
			<tr>
				<td></td>
				<td>Sku</td>
				<td>Description</td>
			</tr>
		</thead>

		<tbody>
			<tr *ngFor="let lineItem of lineItems">
				<td style="text-align: right"><input type="checkbox" [(ngModel)]="lineItem.toSplit" /></td>
				<td>
					{{lineItem.sku}}
					<form-error [absolute]="true" width="220px" *ngIf="lineItem.toSplit === true && lineItem.splitQuantity > lineItem.quantity">Quantity must be less than {{lineItem.quantity}}</form-error>
					<form-error [absolute]="true" width="180px" *ngIf="lineItem.toSplit === true && lineItem.splitQuantity <= 0">Quantity must be >= 0</form-error>
				</td>
				<td>{{lineItem.description}}</td>
			</tr>
		</tbody>
	</table>


	<slick-dialog-footer>
		<button type="button" class="btn btn-outline-success me-3" style="width: 140px" [slick-button-spinner]="spinnerStatus" (click)="onSave()"><i class="bi bi-check-circle"></i> Save</button>
		<button type="button" class="btn btn-outline-danger" style="width: 140px" (click)="onCancel()"><i class="bi bi-x-circle"></i> Cancel</button>
	</slick-dialog-footer>
</slick-dialog>