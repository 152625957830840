import { IUserInfoModel } from "../users/user-info.model";
import { IChatGroupAttendantModel } from "./chat-group-attendant.model";
import { IChatMessageModel } from "./chat-message.model";

export interface IChatGroupModel {
    id: number;
    uuid: string;
    groupCreatorId: number;
    createdOn: Date;
    groupCreator: IUserInfoModel;
    attendants: IChatGroupAttendantModel[];
    lastMessage: IChatMessageModel;
    messages: IChatMessageModel[];
    attendantsString: string;
    longAttendantsString: string;
    unreadMessages: number;
    isExpanded: boolean;
    groupName: string;
    isDeleted: boolean;
    lastSentMessageTimeString: string;
    isAllUsers: boolean;
    documentKey: string; 
    chatGroupAttendantsForList: IChatGroupAttendantModel[];
    isMuted: boolean;
    
}

export class ChatGroupModel implements IChatGroupModel {
    id: number;
    uuid: string;
    groupCreatorId: number;
    createdOn: Date;
    groupCreator: IUserInfoModel;
    attendants: IChatGroupAttendantModel[];
    lastMessage: IChatMessageModel;
    messages: IChatMessageModel[];
    attendantsString: string;
    longAttendantsString: string;
    unreadMessages: number;
    isExpanded: boolean;
    groupName: string;
    isDeleted: boolean;
    lastSentMessageTimeString: string;
    isAllUsers: boolean;
    documentKey: string;
    chatGroupAttendantsForList: IChatGroupAttendantModel[];
    isMuted: boolean;

}
