import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from "@components";
import { CustomerComponentsModule } from "@app/customers/customer-components/customer-components.module";
import { SlickCalendarModule, SlickDialogModule, SlickDropDownModule, SlickMultiSelectModule } from "@slick-components";

import { AutoSchedulerComponent } from "./auto-scheduler.component";
import { AutoSchedulerDialogComponent } from "./auto-scheduler-dialog.component";
import { VisibleResourceService } from "@services";
import { CustomerSearchModule } from "@components/customer-search";


@NgModule({
	imports: [
		CommonModule, FormsModule, ComponentsModule,
		SlickCalendarModule, SlickDialogModule, SlickDropDownModule, SlickMultiSelectModule,
		CustomerComponentsModule, CustomerSearchModule
	],
    declarations: [AutoSchedulerComponent, AutoSchedulerDialogComponent],
    exports: [AutoSchedulerDialogComponent],
    providers: [VisibleResourceService]
})
export class AutoSchedulerModule {
}

export { AutoSchedulerDialogComponent }
