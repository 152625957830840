import {Component, ViewChild} from "@angular/core";
import {SlickDialogComponent} from "@slick-components";
import { ICustomerModel, IJobModel} from "@models";
import { SleepService } from "@services";
import { AutoSchedulerComponent } from "./auto-scheduler.component";

@Component({
	selector: "auto-scheduler-dialog",
	template: `
<div *ngIf='showDialog'>
	<slick-dialog key="DIALOG_AUTO_SCHEDULER" header="Auto-Schedule" [width]="1120" [resizable]="false" #dialogRef (onClose)="onClose()">
		<auto-scheduler #autoSchedulerRef (onResourceSelected)="onResourceSelected($event)"></auto-scheduler>
	</slick-dialog>
</div>`
})
export class AutoSchedulerDialogComponent {
	@ViewChild("dialogRef") dialogRef: SlickDialogComponent;
	@ViewChild("autoSchedulerRef") autoSchedulerRef: AutoSchedulerComponent;

	resolve: any;
	showDialog: boolean = false;

    constructor() { }

	openDialog(jobModel: IJobModel = null, customerModel: ICustomerModel = null): Promise<IJobModel> {
		return new Promise<IJobModel>(async (resolve) => {			
			this.resolve = resolve;

			this.showDialog = true;
			await SleepService.sleep();

			this.autoSchedulerRef.reset(jobModel);

			if (customerModel)
				this.autoSchedulerRef.customer = customerModel;

			this.dialogRef.showDialog();
		})
	}

	onResourceSelected(jobModel: IJobModel) {
		this.dialogRef.hideDialog();
		this.resolve(jobModel);
	}

	onClose() {
		this.resolve();
	}
}
