import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlickDialogModule } from "../slick-dialog/slick-dialog.module";
import { SlickGoogleMapComponent } from "./slick-google-map.component";
import { SlickGoogleMapDialogComponent } from "./slick-google-map-dialog.component";

@NgModule({
	imports: [CommonModule, SlickDialogModule],
	declarations: [SlickGoogleMapComponent, SlickGoogleMapDialogComponent],
	exports: [SlickGoogleMapComponent, SlickGoogleMapDialogComponent]
})
export class SlickGoogleMapModule { }

export { SlickGoogleMapComponent }
export { SlickGoogleMapDialogComponent }
