export interface IPaymentTermModel {
	paymentTermId: number;
	name: string;
	daysUntilOverdue: number;
	requireDeposit: boolean;
	isEditable: boolean;
	sendToSuspended: boolean;
	showNotificationPopup: boolean;
	notificationMessage: string;
	active: boolean;
}

export class PaymentTermModel implements IPaymentTermModel { 
	constructor() {
		this.paymentTermId = 0;
		this.active = true;
		this.isEditable = true;
	}

	paymentTermId: number;
	name: string;
	daysUntilOverdue: number;
	requireDeposit: boolean;
	isEditable: boolean;
	sendToSuspended: boolean;
	showNotificationPopup: boolean;
	notificationMessage: string;
	active: boolean;
}