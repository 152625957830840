import * as moment from "moment";
import { GlobalsService } from "@services/utils/globals.service";
import { IBlockedTimeSlotModel } from "./blocked-time-slots.model";

export interface IBlockedTimeSlotDimensions {
	isVisible: boolean;
	startPartial: boolean;
	endPartial: boolean;
	topPx: string;
	leftPx: string;
	widthPx: string;
}

export class BlockedTimeSlotDimensions implements IBlockedTimeSlotDimensions {
	isVisible = true;
	startPartial = false;
	endPartial = false;
	topPx: string;
	leftPx: string;
	widthPx: string;

	constructor(resourceLine: HTMLDivElement, blockedTimeSlot: IBlockedTimeSlotModel, visibleStartHour: number, visibleEndHour: number) {		
		visibleEndHour = (visibleEndHour < 24) ? visibleEndHour + 1 : visibleEndHour;

		let blockStartDate = moment(blockedTimeSlot.blockDateTime);
		let blockEndDate = moment(blockStartDate).add(blockedTimeSlot.duration, "minutes");
		const visibleStartDate = moment(blockStartDate).startOf("day").add(visibleStartHour, "hours");
		const visibleEndDate = moment(blockStartDate).startOf("day").add(visibleEndHour, "hours");

		if (blockEndDate.diff(visibleStartDate, "minutes") <= 0 || blockStartDate.diff(visibleEndDate, "minutes") > 0) {
			this.isVisible = false;
			return;
		}

		if (blockStartDate.diff(visibleStartDate, "minutes") < 0) {
			blockStartDate = moment(visibleStartDate);
			this.startPartial = true;
		}

		if (blockEndDate.diff(visibleEndDate, "minutes") > 0) {
			blockEndDate = moment(visibleEndDate);
			this.endPartial = true;
		}

		let top = resourceLine.offsetTop + 1;
		if (GlobalsService.company.largeScheduleRows)
			top++;
		this.topPx = (top) + "px";

		const left = this.findPixelFromTimeSlot(resourceLine, moment(blockStartDate));

		const width = this.findPixelFromTimeSlot(resourceLine, blockEndDate) - left - 2;

		// adjust the margin and resource column
		this.leftPx = (left + resourceLine.offsetLeft) + 'px';
		this.widthPx = (width + 1) + 'px'; 
	}

	private findPixelFromTimeSlot(resourceLine: HTMLDivElement, time: moment.Moment): number {
		const timeSlotEl = <HTMLTableCellElement>resourceLine.querySelector(`[data-calchour="${time.format("HHmm")}"]`);
		if (!timeSlotEl) {
			// Ok, so the timeslots will only go to n-15 mins for the last time slot.  For example,
			// if it's 8am-5pm, the last time slot will actually be 4:45pm.  If we have an ending time slot of 5:00
			// it won't be found.  Hacky, but take 15 minutes off of the requested time and see if that time slot exists.
			// If it does, return that time slot + the width.
			time = time.subtract(15, "minutes");
			const lastTimeSlotEl = <HTMLDivElement>resourceLine.querySelector(`[data-calchour="${time.format("HHmm")}"]`);
			if (!lastTimeSlotEl)
				return 0;

			return lastTimeSlotEl.offsetLeft + lastTimeSlotEl.offsetWidth - 1;
		}

		return timeSlotEl.offsetLeft;
	}
}