export enum LathamOrderPickListSpecTypes { string, number }

export interface ILathamOrderPickListSpecModel {
	specName: string;
	specType: LathamOrderPickListSpecTypes;
	specValues: string[];
}

export class LathamOrderPickListSpecModel implements ILathamOrderPickListSpecModel {
	specName: string;
	specType: LathamOrderPickListSpecTypes;
	specValues: string[];
}
