import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SlickDialogModule, SlickDropDownModule, SlickGoogleAddressSearchModule } from "@slick-components";
import { ComponentsModule } from "@components";


import { AddressSearchComponent } from "./address-search.component"


@NgModule({
	imports: [
		ComponentsModule,
		CommonModule,
		FormsModule,
		SlickDialogModule, SlickDropDownModule, SlickGoogleAddressSearchModule
	],
	declarations: [AddressSearchComponent],
	exports: [AddressSearchComponent]
})
export class AddressSearchModule { }
