export interface IHolidayModel {
  
    holidayId: number;
    dateStart: Date;
    dateEnd: Date;
    description: string;
    isPaid: boolean;
    paySeasonalEmployees: boolean;
    holidayYear: number; 
    hasChanged: boolean;
    flagDateStartYear: boolean;
    flagDateEndYear: boolean;
    endBeforeStartDate: boolean;
}

export class HolidayModel implements IHolidayModel{
    constructor() {
        this.isPaid = true;
    }
    holidayId: number;
    dateStart: Date;
    dateEnd: Date;
    description: string;
    isPaid: boolean;
    paySeasonalEmployees: boolean;
    holidayYear: number;
    hasChanged: boolean;
    flagDateStartYear: boolean;
    flagDateEndYear: boolean;
    endBeforeStartDate: boolean;
}