import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlickCardHeaderComponent } from "./slick-card-header.component";
import { SlickCardComponent } from "./slick-card.component";

@NgModule({
	imports: [CommonModule],
	exports: [SlickCardComponent, SlickCardHeaderComponent, CommonModule],
	declarations: [SlickCardComponent, SlickCardHeaderComponent]
})
export class SlickCardModule { }

export { SlickCardComponent, SlickCardHeaderComponent }
