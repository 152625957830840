import { Component, Input, ViewChild, ElementRef, OnChanges, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IQBWCTaskModel } from "@models";
import { UtilsService, GlobalsService, QuickbooksService } from "@services";
import { SlickToastService } from "@slick-components";
import * as vkbeautify from "vkbeautify";
import Swal from 'sweetalert2';

@Component({
	selector: "quickbooks-task-display",
	templateUrl: "quickbooks-task-display.component.html",
	styleUrls: ["quickbooks-task-display.component.scss"],
	providers: [QuickbooksService]
})
export class QuickbooksTaskDisplayComponent implements OnChanges {
	@Input() qbwcTaskModel: IQBWCTaskModel;
	@ViewChild("requestXMLRef") requestXMLRef: ElementRef;

	requestXML: string;
	responseXML: string;
	isScott: boolean = false;

	constructor(private quickbooksService: QuickbooksService,
		private slickToastService: SlickToastService,
		route: ActivatedRoute) {
		this.isScott = GlobalsService.userInfo.userId === 1;
		if (route.snapshot.queryParams.slg)
			this.isScott = true;

		if (GlobalsService.isDebug === true)
			this.isScott = true;
	}


	ngOnChanges() {
		if (this.qbwcTaskModel) {
			this.requestXML = (this.qbwcTaskModel.requestXML) ? vkbeautify.xml(this.qbwcTaskModel.requestXML) : "<empty>";
			this.responseXML = (this.qbwcTaskModel.responseXML) ? vkbeautify.xml(this.qbwcTaskModel.responseXML) : "<empty>";
		}
	}

	copyRequestXMLToClipboard() {
		UtilsService.copyToClipboard(this.requestXMLRef.nativeElement);
		this.slickToastService.showSuccess("Text Copied", 1000);
	}

	async processResponseXML() {
		if (!this.qbwcTaskModel.responseXML) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "No response XML to process",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			return;
		}

		const success = await this.quickbooksService.processResponseXML(this.qbwcTaskModel.qbwcTaskId);

		if (success === true)
			await Swal.fire({
				icon: 'success',
				title: 'Success!',
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
	}

}