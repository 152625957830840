import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SlickSleepService } from "../utils/slick-sleep.service";

@Component({
	selector: "slick-search-bar",
	templateUrl: "slick-search-bar.component.html",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SlickSearchBarComponent),
			multi: true
		}]
})
export class SlickSearchBarComponent implements OnChanges, AfterViewInit {
	@HostBinding('style.flex') flex = '1 1';

	@Input() showSearchIcon: boolean;
	@Input() showAddButton: boolean;
	@Input() disableAddButton: boolean;
	@Input() showEditButton: boolean;
	@Input() disableEditButton: boolean;
	@Input() autofocus: boolean;
	@Input() delay: number;
	@Input() placeholder: string;
	@Input() icon: string;

	@Output() onAddClick: EventEmitter<string> = new EventEmitter();
	@Output() onEditClick: EventEmitter<string> = new EventEmitter();
	@Output() onSearch: EventEmitter<string> = new EventEmitter();

	@ViewChild("slickSearchTextBoxRef") slickSearchTextBoxRef: ElementRef;
	public get slickSearchTextBox(): HTMLInputElement {
		return <HTMLInputElement>this.slickSearchTextBoxRef?.nativeElement;
	}

	protected searchTimer: NodeJS.Timer;
	protected searchText: string;
	protected lastSearchText: string;

	constructor() {
		this.searchText = '';
		this.lastSearchText = '';
	}

	ngOnInit() {
		this.setDefaults();
	}

	ngOnChanges() {
		this.setDefaults();
	}

	ngAfterViewInit() {
		if (this.autofocus === true) {
			setTimeout(() => {
				(<HTMLInputElement>this.slickSearchTextBoxRef.nativeElement).focus();
			}, 100);
		}
	}

	// Since it's possible to have this with no inputs, we need to set the inputs on both OnInit and OnChanges
	private setDefaults() {
		this.showSearchIcon = (this.showSearchIcon ?? "true").toString().toLowerCase() === "true";
		this.showAddButton = (this.showAddButton ?? "true").toString().toLowerCase() === "true";
		this.disableAddButton = (this.disableAddButton ?? "false").toString().toLowerCase() === "true";
		this.showEditButton = (this.showEditButton ?? "true").toString().toLowerCase() === "true";
		this.disableEditButton = (this.disableEditButton ?? "false").toString().toLowerCase() === "true";
		this.autofocus = (this.autofocus ?? "true").toString().toLowerCase() === "true";
		if (isNaN(this.delay))
			this.delay = 600;
		else
			this.delay = parseInt((this.delay ?? '600').toString());
		this.placeholder = this.placeholder || 'Search...';
		this.icon = this.icon || 'far fa-search';
	}

	async onKeyDown() {
		clearTimeout(this.searchTimer);

		this.searchTimer = setTimeout(async () => {
			await SlickSleepService.sleep();
			if (this.lastSearchText === this.searchText)
				return;
			this.lastSearchText = this.searchText;

			this.propagateChange(this.searchText);
			this.onSearch.emit(this.searchText);
		}, this.delay);
	}

	protected propagateChange = (_: any) => { };

	protected async writeValue(obj: any) {
		await SlickSleepService.sleep();
		this.searchText = obj;
		this.lastSearchText = this.searchText;
	}

	protected registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	// not used, used for touch input
	protected registerOnTouched() { }

	protected async onAddClicked() {
		if (this.disableAddButton)
			return;

		await SlickSleepService.sleep();
		this.onAddClick.emit(this.searchText);
	}

	protected async onEditClicked() {
		if (this.disableEditButton)
			return;

		await SlickSleepService.sleep();
		this.onEditClick.emit(this.searchText);
	}
}