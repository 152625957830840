<div class="d-flex ps-3 pe-3" style="min-height: calc(100vh - 280px);">
    <div class="w-50 me-3 d-flex flex-column">
        <div class="d-flex mt-2">
            <div class="w-100">
                <label>Job Site Customer</label>
                <customer-search [customerModel]="customer"
                                 (customerModelChange)="onCustomerModelChange($event)"
                                 [showAttentionNote]="false"></customer-search>

            </div>
        </div>
        <div class="d-flex mt-2">
            <div class="w-50 me-2">
                <label>Job Type</label>
                <slick-drop-down [(ngModel)]="jobTypeId"
                                 [items]="jobTypes"
                                 textFieldName="description"
                                 idFieldName="jobTypeId"
                                 [disabled]="!!jobModel"
                                 (onSelect)="onJobTypeChanged()"></slick-drop-down>
            </div>
            <div class="w-50">
                <label>Appointment Type</label>
                <slick-drop-down [(ngModel)]="appointmentTypeId"
                                 [items]="appointmentTypes"
                                 textFieldName="description"
                                 idFieldName="appointmentTypeId"
                                 (onSelect)="onAppointmentTypeChanged($event)"></slick-drop-down>
            </div>

        </div>

        <div class="d-flex mt-2">
            <div class="w-50 me-2">
                <label>Duration</label>
                <slick-drop-down [(ngModel)]="durationTime"
                                 [items]="durationTimes"
                                 (onSelect)="onAppointmentDurationChanged()"></slick-drop-down>
            </div>
            <div class="w-50">
                <label>Travel</label>
                <slick-drop-down [(ngModel)]="travelTime"
                                 [items]="travelTimes"
                                 (onSelect)="onAppointmentTravelTimeChanged()"></slick-drop-down>
            </div>
        </div>





        <label class="mt-2"><input type="checkbox" [(ngModel)]="multiDayClicked" (click)="resetCalendar()" /> Multi-Day Appointment</label>
        <div class="w-100 mt-2" *ngIf="!multiDayClicked">
            <slick-calendar [(ngModel)]="selectedDate"
                            monthsToShow="2"
                            [inactiveDates]="inactiveDates"
                            (onDateChange)="onDateChanged($event)"
                            (onMonthChange)="onMonthChanged($event)"></slick-calendar>
        </div>

        <div class="w-100 mt-2" *ngIf="multiDayClicked">
            <slick-calendar (click)="findAvailableSlotsForMultiDay($event)"
                            monthsToShow="2"
                            [inactiveDates]="inactiveDates"
                            [multiSelect]="true"
                            [(multiSelectDates)]="multiDates"
                            (onMonthChange)="onMonthChanged($event)"></slick-calendar>
        </div>
    </div>
    <div class="w-50">
        <div [ngStyle]="{'margin': customer ? '0px' : '20px'}" style="max-height: calc(100vh - 310px); min-height: calc(100vh - 310px);  min-width: 122px; overflow-y: auto; border: 2px solid var(--bs-gray-300); border-radius: 6px;">
            <div *ngFor="let timeSlot of timeSlots" class="d-flex flex-column pb-1 me-2" style="border-bottom: 1px solid var(--bs-light); padding: 7px !important;">
                <div class="d-flex">
                    <div style="text-align: left; min-width: 90px; padding-top: 5px; padding-left: 10px;" class="me-2">
                        {{getTimeDisplay(timeSlot.timeSlotDisplay)}}
                    </div>
                    <div *ngIf="timeSlot.availableResources.length > 0" class="d-flex">
                        <div class="resource border p-1" *ngFor="let availableResource of timeSlot.availableResources" (click)="selectResource(timeSlot, availableResource.resourceId)" [style.background]="availableResource.backgroundColor" [style.color]="availableResource.textColor">
                            <span>{{availableResource.resourceShortName}} <i *ngIf="availableResource.inArea && !multiDayClicked" class="bi bi-check"></i></span>
                        </div>
                    </div>
                    <div *ngIf="timeSlot.availableResources.length === 0">
                        <i>Not available</i>
                    </div>
                </div>
            </div>
        </div>
        <span style="line-height: 1.0rem" *ngIf="customer && !multiDayClicked"><i class="bi bi-check"></i> : Indicates resource in the job site customers area</span>
    </div>
</div>
