import {EventEmitter, Injectable} from '@angular/core';
import { HttpService } from './utils/http.service';
import {
	IScheduledAppointmentModel, IBlockedTimeSlotModel, IAppointmentModel,
	ICreditCardPaymentTypeModel, IAvailableTimeSlotModel,
	INewAppointment, IAppointmentStatusUpdate, IAppointmentNotesUpdate, IAppointmentStatusModel,
	IConflictingAppointment, IConflictingAppointmentResultModel,
	ISmartlistAppointmentModel,
	IScheduleAppointmentSearchModel,
	IAppointmentSmartlistQuestionModel, JobModel, IJobModel, AppointmentModel, IAppointmentChecklistQuestionModel, IUnscheduledAppointmentOrder, ITimeSlotModel
} from '@models';
import * as moment from 'moment';
import { GlobalsService } from './utils/globals.service';
import {ICompletedAppointmentModel} from "@models/appointments/completed-appointment.model";
import { LookupService } from './utils/lookup.service';
import { UtilsService } from './utils/utils.service';

@Injectable()
export class AppointmentsService {

	onAppointmentSaved: EventEmitter<IJobModel> = new EventEmitter<IJobModel>();

	constructor(private readonly httpService: HttpService,
		private readonly lookupService: LookupService) { }

	getNewAppointmentModel(jobId: number): IAppointmentModel {
		const newAppointmentModel: IAppointmentModel = new AppointmentModel();
		newAppointmentModel.appointmentId = 0;
		newAppointmentModel.uuid = UtilsService.newGuid();
		newAppointmentModel.jobId = jobId;
		newAppointmentModel.createdByInitials = GlobalsService.userInfo.fullName;
		newAppointmentModel.createdDate = new Date();
		newAppointmentModel.uiState = 'expanded';
		newAppointmentModel.appointmentStatusId = this.lookupService.getDefaultAppointmentStatus().appointmentStatusId;
		newAppointmentModel.travelMinutes = 15;
		newAppointmentModel.scheduledDateTime = moment().startOf("day").add(8, 'hours').toDate();
		newAppointmentModel.checklistQuestions = [];

		return newAppointmentModel;
	}

	async getAvailableTimeSlots(startDate: Date, endDate: Date, duration: number, travelTime: number, customerId?: number): Promise<IAvailableTimeSlotModel[]> {
		let params: any = {
			startDate: moment(startDate).format('YYYY-MM-DD'),
			endDate: moment(endDate).format('YYYY-MM-DD'),
			duration: duration,
			travelTime: travelTime
		};

		if (customerId != null) { 
			params.customerId = customerId;
		}

		return await this.httpService.get('/appointments/getAvailableTimeSlots', params);
	}


	async getAppointment(appointmentId: number, includeJob: boolean): Promise<IAppointmentModel> {
		return this.httpService.get('/appointments/getAppointment', { appointmentId, includeJob });
	}

	async getAppointmentsByJobId(jobId: number): Promise<IAppointmentModel[]> {
		return this.httpService.get('/appointments/getAppointmentsByJobId', { jobId: jobId });
	}

	async getScheduledAppointment(appointmentId: number): Promise<IScheduledAppointmentModel> {
		return this.httpService.get('/appointments/getScheduledAppointment', { appointmentId: appointmentId });
	}

	async getScheduledAppointmentsForJob(jobId: number) {
		return this.httpService.get('/appointments/getScheduledAppointmentsForJob', { jobId: jobId });
	}

	async getScheduledAppointments(startDate: Date, endDate: Date): Promise<IScheduledAppointmentModel[]> {
		const params = {
			startDate: startDate,
			endDate: endDate
		};
		return this.httpService.post('/appointments/getScheduledAppointments', params);
	}

	async getScheduledAppointmentsForCurrentUser(startDate: Date, endDate: Date): Promise<IScheduledAppointmentModel[]> {
		const params = {
			startDate: startDate,
			endDate: endDate
		};

		return this.httpService.post('/appointments/getScheduledAppointmentsForCurrentUser', params);
	}

	async getScheduledAppointmentsForCustomer(customerId: number, defaultAppointmentId?: number): Promise<IScheduledAppointmentModel[]> {
		const params = {
			customerId,
			defaultAppointmentId: defaultAppointmentId || 0
		}

		return this.httpService.get('/appointments/getScheduledAppointmentsForCustomer', params);
	}

	async getUnscheduledAppointments(): Promise<IScheduledAppointmentModel[]> {
		return this.httpService.get('/appointments/getUnscheduledAppointments');
	}

	async addUnscheduledAppointment(appointmentId: number, displaySeq: number): Promise<IScheduledAppointmentModel> {
		return this.httpService.patch(`/appointments/addUnscheduledAppointment?appointmentId=${appointmentId}&displaySeq=${displaySeq}`);
	}

	async reorderUnscheduledAppointment(originalDisplaySeq: number, newDisplaySeq: number): Promise<void> {
		return this.httpService.patch(`/appointments/reorderUnscheduledAppointment?originalDisplaySeq=${originalDisplaySeq}&newDisplaySeq=${newDisplaySeq}`);
	}

	async getCompletedAppointmentsByUser(userId: number, startDate: Date, endDate: Date): Promise<ICompletedAppointmentModel[]> {
		const params = {
			companyId: GlobalsService.company.companyId,
			userId: userId,
			startDate: moment(startDate).format('YYYY-MM-DD'),
			endDate: moment(endDate).format('YYYY-MM-DD')
		};

		return this.httpService.get('/appointments/getCompletedAppointmentsByUser', params);
	}

	async getAllCompletedAppointments(startDate: Date, endDate: Date): Promise<ICompletedAppointmentModel[]> {
		const params = {
			companyId: GlobalsService.company.companyId,
			startDate: moment(startDate).format('YYYY-MM-DD'),
			endDate: moment(endDate).format('YYYY-MM-DD')
		};

		return this.httpService.get('/appointments/getAllCompletedAppointments', params);
	}

	async getAllCompletedAppointmentsByAppointmentTypeId(apptTypeId: number, startDate: Date, endDate: Date): Promise<ICompletedAppointmentModel[]> {
		const params = {
			companyId: GlobalsService.company.companyId,
			apptTypeId: apptTypeId,
			startDate: moment(startDate).format('YYYY-MM-DD'),
			endDate: moment(endDate).format('YYYY-MM-DD')
		};

		return this.httpService.get('/appointments/getAllCompletedAppointmentsByAppointmentTypeId', params);
	}

	async deleteAppointment(appointmentId: number): Promise<boolean> {
		return this.httpService.delete('/appointments/deleteAppointment', { appointmentId: appointmentId });
	}

	async getBlockedTimeSlotsForDateRange(startDate: Date, endDate: Date): Promise<IBlockedTimeSlotModel[]> {
		const params = {
			startDate: moment(startDate).format('YYYY-MM-DD'),
			endDate: moment(endDate).format('YYYY-MM-DD')
		};

		return this.httpService.get('/appointments/getBlockedTimeSlots', params);
	}

	async getBlockedTimeSlotsForCurrentUser(startDate: Date, endDate: Date): Promise<IBlockedTimeSlotModel[]> {
		const params = {
			startDate: moment(startDate).format('YYYY-MM-DD'),
			endDate: moment(endDate).format('YYYY-MM-DD')
		};

		return this.httpService.get('/appointments/getBlockedTimeSlotsForCurrentUser', params);
	}

	async updateAppointment(model: IAppointmentModel): Promise<IAppointmentModel> {
		return this.httpService.post(`/appointments/updateAppointment`, model);
	}

	async updateScheduledAppointment(model: IScheduledAppointmentModel): Promise<IScheduledAppointmentModel> {
		return this.httpService.post('/appointments/updateScheduledAppointment', model);
	}

	async editTimes(apptModel: IAppointmentModel): Promise<IAppointmentModel> {
		
		return this.httpService.post('/appointments/editTimes', apptModel);
}

	async moveAppointment(model: IScheduledAppointmentModel): Promise<IScheduledAppointmentModel> {
		return this.httpService.post('/appointments/moveAppointment', model);
	}

	async updateBlockedTimeSlot(model: IBlockedTimeSlotModel): Promise<IBlockedTimeSlotModel> {
		return this.httpService.post('/appointments/updateBlockedTimeSlot', model);
	}

	async deleteBlockedTimeSlot(blockedTimeSlotId: number): Promise<boolean> {
		return this.httpService.delete('/appointments/deleteBlockedTimeSlot', { blockedTimeSlotId: blockedTimeSlotId });
	}

	async setAppointmentStatus(model: IAppointmentStatusUpdate): Promise<IAppointmentStatusModel> {
		return this.httpService.post('/appointments/setAppointmentStatus', model);
	}

	async appointmentNotesUpdate(model: IAppointmentNotesUpdate): Promise<boolean> {
		return this.httpService.post('/appointments/appointmentNotesUpdate', model);
	}

	async checkForConflicts(dtos: IConflictingAppointment[]): Promise<IConflictingAppointmentResultModel[]> {
		return this.httpService.post('/appointments/checkForConflicts', dtos);
	}

	async getSmartlistAppointments(): Promise<ISmartlistAppointmentModel[]> {
		return this.httpService.get('/appointments/getSmartlistAppointments');
	}

	async scheduleAppointmentSearch(searchString: string): Promise<IScheduleAppointmentSearchModel[]> {
		const params = {
			searchString: searchString
		};
		return await this.httpService.get('/appointments/scheduleAppointmentSearch', params);
	}

	async getScheduledAppointmentsForDateRange(startDate: Date, endDate: Date): Promise<IScheduledAppointmentModel[]> {
		const params = {
			startDate: moment(startDate).startOf('day').format('YYYY-MM-DD'),
			endDate: moment(endDate).startOf('day').format('YYYY-MM-DD')
		};

		return await this.httpService.get('/appointments/getScheduledAppointmentsForDateRange', params);
	}

	async updateAppointmentSmartlistQuestion(model: IAppointmentSmartlistQuestionModel): Promise<boolean> {
		return this.httpService.post('/appointments/updateAppointmentSmartlistQuestion', model);
	}

	isApptValid(appt: IAppointmentModel): boolean {
		let isValid = true;

		if (!appt.appointmentTypeId) {
			console.info("Appointment Type is not valid");
			isValid = false;
		}

		if (!appt.appointmentStatusId) {
			console.info("Appointment Status is not valid");
			isValid = false;
		}

		if (!appt.resourceId) {
			console.info("Resource is not valid");
			isValid = false;
		}

		if (!moment(appt.scheduledDateTime).isValid()) {
			console.info("Scheduled date or time is not valid");
			isValid = false;
		}

		if (!appt.scheduledMinutes) {
			console.info("Scheduled Minutes is not valid");
			isValid = false;
		}

		if (appt.travelMinutes === null) {
			console.info("Travel Minutes is not valid");
			isValid = false;
		}

		return isValid;
	}

	toggleAppointmentChecklistQuestion(appointmentChecklistQuestionId: number): Promise<IAppointmentChecklistQuestionModel> {
		return this.httpService.patch(`/appointments/toggleAppointmentChecklistQuestion?appointmentChecklistQuestionId=${appointmentChecklistQuestionId}`);
	}

	getOptimalAppointmentRoutes(origin: string, appointmentIds: number[]): Promise<number[]> {
		const params = {
			origin,
			appointmentIds
		}
		return this.httpService.get(`/appointments/getOptimalAppointmentRoutes`, params);
	}

	rescheduleOptimalAppointmentRoutes(date: Date, appointmentIds: number[]): Promise<IScheduledAppointmentModel[]> {
		const params = {
			date: moment(date).format('YYYYMMDD'),
			appointmentIds
		}
		return this.httpService.get(`/appointments/rescheduleOptimalAppointmentRoutes`, params);
	}

	async generateAppointmentPdf(appointmentModel: IAppointmentModel): Promise<string> {
		return await this.httpService.post("/appointments/generateAppointmentPdf", appointmentModel);
	}

	async setPhotoUploaded(appointmentId: number): Promise<void> {
		return await this.httpService.post("/appointments/setPhotoUploaded", appointmentId);
	}

	async updateActualCrew(appointmentId: number, actualCrew: number[]): Promise<void> {
		const params: any = {
			appointmentId: appointmentId,
			userIds: actualCrew
		}
		return await this.httpService.post(`/appointments/updateActualCrew`, params);
	}

}
