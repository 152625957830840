import { Component, ViewChild, Input } from "@angular/core";
import { SlickDialogComponent } from "@slick-components";

@Component({
    selector: "marks-dialog",
    templateUrl: "./marks-dialog.component.html",
    styleUrls: ["./marks-dialog.component.scss"]
})

export class MarksDialogComponent {
    @ViewChild("strikesDialog", { static: true }) strikesDialog: SlickDialogComponent;
    @Input() appointmentId: number;

    constructor() { }

    openDialog(appointmentId: number) {
        this.appointmentId = appointmentId;
        this.strikesDialog.showDialog();
    }

    closeDialog() {
        this.strikesDialog.hideDialog();
    }
}
