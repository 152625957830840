import { Component, Input, Output, EventEmitter, ViewChild, HostListener, ElementRef, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, ContentChildren, QueryList, HostBinding, ChangeDetectorRef } from "@angular/core";
import { SlickGridService } from "./slick-grid.service";
import { ISlickGridColumnModel, SlickGridColumnFilterTypes, SlickGridColumnSortDirection } from "./slick-grid-column.model";
import { ISlickGridOptions } from "./slick-grid.options";
import { SlickGridRequestModel, ISlickGridRequestSearchModel } from "./slick-grid-request.model";
import { SlickGridResponseModel } from "./slick-grid-response.model";
import { SlickDialogComponent } from "../slick-dialog/slick-dialog.module";
import { SlickSleepService } from "../utils/slick-sleep.service";
import { SlickUtilsService } from "../utils/slick-utils.service";
import { SlickFunctionLockService } from "../utils/slick-function-lock.service";
import { SlickGridColumnTemplateDirective } from "./slick-grid-column-template.directive";
import { SlickConfirmDialogComponent, SlickConfirmDialogResults } from '../slick-confirm-dialog/slick-confirm-dialog.module';
import { ISlickGridFavoriteModel, SlickGridFavoriteModel } from './slick-grid-favorite.model';

class DropDownModel {
	constructor(text: string) {
		this.id = text;
		this.text = text;
	}

	id: string;
	text: string;
}

@Component({
	selector: 'slick-grid',
	templateUrl: 'slick-grid.component.html',
	providers: [SlickGridService]
})
export class SlickGridComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	@HostBinding('class') flexColumn = 'slick-flex-column';
	//@HostBinding('class') class = 'd-flex flex-column flex-fill';

	@Input() gridOptions: ISlickGridOptions;
	@Input() bordered: boolean = true;
	@Input() striped: boolean = true;
	@Input() hover: boolean = true;
	@Input() showingAllRecords = false;
	@Input() data: any[];
	@Output() onRowSelected: EventEmitter<any> = new EventEmitter<any>();
	@Output() onRowDoubleClick: EventEmitter<any> = new EventEmitter<any>();
	@Output() onRowContextMenu: EventEmitter<any> = new EventEmitter<any>();
	@Output() onFavoritesModified: EventEmitter<any[]> = new EventEmitter<any[]>();
	@ViewChild("slickGrid", { static: true }) slickGridRef: ElementRef;
	@ViewChild("tableContainer") tableContainerRef: ElementRef;
	@ViewChild("tableRef") tableRef: ElementRef;
	@ViewChild("tableHeaderRow") tableHeaderRowRef: ElementRef;
	@ViewChild("tableHeaderSearchRow") tableHeaderSearchRowRef: ElementRef;
	// This is to test how high a standard row is
	@ViewChild("testRow") testRowRef: ElementRef;
	@ViewChild("tableBodyRows") tableBodyRowsRef: ElementRef;
	@ViewChild("columnSelectDialogRef") columnSelectDialogRef: SlickDialogComponent;
	@ViewChild("addNewFavoriteDialogRef") addNewFavoriteDialogRef: SlickConfirmDialogComponent;

	@ContentChildren(SlickGridColumnTemplateDirective) slickGridColumnTemplates: QueryList<SlickGridColumnTemplateDirective>;
	templateRefs: any;

	private fnOnMouseUp = (e) => this.onMouseUp(e);

	uuid: string;
	selectedIndex: number = null;
	gridOpacity = 0;
	visibleColumns: ISlickGridColumnModel[];
	favorites: DropDownModel[];
	selectedFavorite: string;
	newFavoriteText: string;
	columnSelectDialogColumns: ISlickGridColumnModel[];
	loaded: boolean = false;
	isDoubleClick: boolean = false;
	doubleClickWaitTimeout: NodeJS.Timer;
	currentPage: number = 1;
	searchTimeout: NodeJS.Timer;
	keypressTimeout: NodeJS.Timer;
	showTestRow = false;
	showSearchRow = false;
	isResizing: boolean = false;
	visibleData: any[] = [];
	recordsPerPage: number;
	totalRecords: number;
	recordsPerPageValue: string = "Auto";

	private slickGridHeight: number;
	private resizeTimeout: NodeJS.Timer = null;
	private requestModel: SlickGridRequestModel;
	private resizeInitialPos: number;
	private resizeInitialWidth: number;
	private resizeColIdx: number;

	@HostListener('window:resize', ['$event', '$event.target'])
	async onResize(event: UIEvent, targetElement: HTMLElement) {
		// This is here because firefox shows/hides the menu if you press alt. Don't redraw the grid if we're
		// just showing the menu
		if (Math.abs(this.slickGridHeight - this.slickGridRef.nativeElement.offsetHeight) < 50)
			return;

		if (this.slickGridHeight !== this.slickGridRef.nativeElement.offsetHeight) {
			clearTimeout(this.resizeTimeout);
			this.resizeTimeout = setTimeout(async () => {
				this.currentPage = 1;
				this.requestModel.page = 1;
				await this.resizeGrid();
				this.requestModel.recordsPerPage = this.recordsPerPage;
				this.reload(this.requestModel);
			}, 500);
		}
	}

	constructor(private gridService: SlickGridService,
		private functionLockService: SlickFunctionLockService,
		private changeDetector: ChangeDetectorRef) {
		this.uuid = SlickUtilsService.newGuid();
		this.requestModel = new SlickGridRequestModel();
	}

	async ngOnInit() {
		this.setGridOptions(this.gridOptions);
	}

	async ngAfterViewInit() {
		// Copy the templates to a variable that the html can access.  It can't access a QueryList
		this.templateRefs = {};
		this.slickGridColumnTemplates.forEach(t => {
			this.templateRefs[t.columnKey] = t.template;
		});
		if (this.tableRef)
			(<HTMLTableElement>this.tableRef.nativeElement).focus();
	}

	async tableKeydown(e: KeyboardEvent) {
		// Key up
		if (e.which === 38 && !(this.selectedIndex === 0 && this.currentPage === 1)) {
			clearTimeout(this.keypressTimeout);

			this.selectedIndex--;
			if (this.selectedIndex < 0) {
				if (this.currentPage > 1) {
					this.currentPage--;
					this.selectedIndex = 0;
					await this.onPageChange(this.currentPage, false);
					this.selectedIndex = this.recordsPerPage - 1;
				}
			}

			this.keypressTimeout = setTimeout(() => {
				const rowData = this.visibleData[this.selectedIndex];
				this.onRowClicked(rowData, this.selectedIndex);
			}, 500);
		}
		// Key down
		if (e.which === 40) {
			if (((this.currentPage - 1) * this.recordsPerPage) + this.selectedIndex >= this.totalRecords)
				return;

			clearTimeout(this.keypressTimeout);
			this.selectedIndex++;
			if (this.selectedIndex >= this.recordsPerPage) {
				if (((this.currentPage - 1) * this.recordsPerPage) + this.selectedIndex < this.totalRecords) {
					this.currentPage++;
					this.selectedIndex = 0;
					await this.onPageChange(this.currentPage, false);
				}
			}

			this.keypressTimeout = setTimeout(() => {
				const rowData = this.visibleData[this.selectedIndex];
				this.onRowClicked(rowData, this.selectedIndex);
			}, 500);
		}
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (changes.bordered) {
			if (this.gridOptions.bordered === null)
				this.bordered = (this.bordered.toString().toLowerCase() !== 'false') ? true : false;
		}

		if (changes.striped) {
			// The grid options override the tag options
			if (this.gridOptions.striped === null)
				this.striped = (this.striped.toString().toLowerCase() !== 'false') ? true : false;
		}

		if (changes.hover) {
			// The grid options override the tag options
			if (this.gridOptions.hover === null)
				this.hover = (this.hover.toString().toLowerCase() !== 'false') ? true : false;
		}

		if (changes.gridOptions) {
			if (this.gridOptions.bordered !== null)
				this.bordered = this.gridOptions.bordered;

			if (this.gridOptions.striped !== null)
				this.striped = this.gridOptions.striped;

			if (this.gridOptions.hover !== null)
				this.hover = this.gridOptions.hover;

			this.setGridOptions(this.gridOptions);
		}

		if (changes.data) {
			// If we don't do this lock, the first change will have null data and crash.  The second request will have the actual data
			await this.functionLockService.lock("GRID_" + this.uuid + "_DATA_CHANGES");
			// Only resize the grid on the first change, otherwise we get a flicker
			if (changes.data.firstChange === true)
				await this.resizeGrid();
			// We need to wait for the resize in the OnInit to finish before we continue
			this.loaded = true;
			this.currentPage = 1;
			this.requestModel.page = 1;
			this.requestModel.recordsPerPage = this.recordsPerPage;
			await this.reload(this.requestModel);
			if (this.visibleData && this.visibleData.length > 0 && this.gridOptions.autoSelectFirstRow === true)
				this.selectRow(0);
			this.functionLockService.release("GRID_" + this.uuid + "_DATA_CHANGES");
		}
	}

	ngOnDestroy() {
		document.removeEventListener('mouseup', this.fnOnMouseUp);
	}

	async onRecordsPerPageSelected(recordsPerPage: string) {
		if (!recordsPerPage)
			return;

		this.recordsPerPageValue = recordsPerPage;
		localStorage.setItem("GRID_" + this.gridOptions.gridKey + "_RECORDS_PER_PAGE", recordsPerPage);

		this.visibleData = [];
		this.gridOpacity = 0;
		await SlickSleepService.sleep();
		await this.resizeGrid(true);
		this.requestModel.recordsPerPage = this.recordsPerPage;

		this.currentPage = 1;
		await this.onPageChange(this.currentPage, true);
	}

	onColResizeMousedown(e: MouseEvent, columnIdx: number) {
		this.isResizing = true;
		this.resizeInitialPos = e.pageX;
		this.resizeColIdx = columnIdx;
		let col = (<HTMLTableRowElement>this.tableHeaderRowRef.nativeElement).querySelectorAll("td").item(columnIdx);
		this.resizeInitialWidth = col.offsetWidth - 9;

		this.visibleColumns[columnIdx].flexible = false;

		document.addEventListener('mouseup', this.fnOnMouseUp);
	}

	onColResizeDoubleclick(e: MouseEvent, columnIdx: number) {
		this.visibleColumns[columnIdx].flexible = true;
		this.visibleColumns[columnIdx].width = null;
		this.saveColumnsToLocalStorage();
	}

	onColResizeMousemove(e: MouseEvent) {
		if (!this.isResizing)
			return;

		let newCursorPos = e.pageX;
		let diff = newCursorPos - this.resizeInitialPos;
		this.visibleColumns[this.resizeColIdx].width = (this.resizeInitialWidth + diff) + "px";
		if ((this.resizeInitialWidth + diff) < 10)
			this.visibleColumns[this.resizeColIdx].width = "10px";
		this.visibleColumns[this.resizeColIdx].flexible = false;
	}

	private onMouseUp(e) {
		this.isResizing = false;
		document.removeEventListener('mouseup', this.fnOnMouseUp);
		this.saveColumnsToLocalStorage();
	}

	getVisibleRecords() {
		return this.visibleData;
	}

	addNewRecord(newRecord: any, selectNewRecord: boolean = false) {
		this.visibleData.unshift(newRecord);

		if (selectNewRecord === true) {
			this.selectedIndex = 0;
			this.selectRow(0);
		}
		else {
			if (this.selectedIndex)
				this.selectedIndex++;
		}
	}

	updateRecord(rowNum: number, record: any) {
		this.visibleData[rowNum] = record;
	}

	onRowClicked(rowData: any, idx: number) {
		this.selectedIndex = idx;
		clearTimeout(this.doubleClickWaitTimeout);
		this.doubleClickWaitTimeout = setTimeout(() => {
			this.onRowSelected.emit(rowData);
		}, 250);
	}

	onRowDoubleClicked(rowData: any, idx) {
		this.selectedIndex = idx;
		clearTimeout(this.doubleClickWaitTimeout);
		this.onRowDoubleClick.emit(rowData);
	}

	onRowContextMenuClicked(e: MouseEvent, rowData, idx) {
		if (this.onRowContextMenu) {
			let rowContextMenuModel = {
				mouseEvent: e,
				data: rowData,
				rowIdx: idx
			}

			this.onRowContextMenu.emit(rowContextMenuModel);
		}
	}

	async onPageChange(pageNumber, selectRow: boolean = true) {
		this.currentPage = pageNumber;
		this.requestModel.page = pageNumber;
		await this.reload(this.requestModel);
		this.selectedIndex = (this.gridOptions.autoSelectFirstRow === true) ? 0 : null;

		if (selectRow === true && this.gridOptions.autoSelectFirstRow === true) {
			const rowData = this.visibleData[this.selectedIndex];
			this.onRowClicked(rowData, this.selectedIndex);
		}

		(<HTMLTableElement>this.tableRef.nativeElement).focus();
	}

	async reloadGrid(additionalParams: any): Promise<SlickGridResponseModel> {
		try {
			await this.resizeGrid();
			await this.functionLockService.lock("GRID_" + this.uuid + "_RELOADGRID");
			this.requestModel.url = this.gridOptions.getUrl;
			this.requestModel.httpHeaders = this.gridOptions.httpHeaders;
			this.requestModel.additionalParams = additionalParams;
			this.requestModel.page = this.currentPage;
			this.requestModel.recordsPerPage = this.recordsPerPage;

			let responseModel: SlickGridResponseModel = await this.reload(this.requestModel);
			this.functionLockService.release("GRID_" + this.uuid + "_RELOADGRID");
			return Promise.resolve(responseModel);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}

	}

	async reloadGridWithRequestModel(requestModel: SlickGridRequestModel): Promise<SlickGridResponseModel> {
		try {
			await this.resizeGrid();
			await this.functionLockService.lock("GRID_" + this.uuid + "_RELOADGRIDWITHREQUESTMODEL");
			this.requestModel = requestModel;
			this.currentPage = 1;
			this.requestModel.page = 1;
			let responseModel: SlickGridResponseModel = await this.reload(this.requestModel);
			this.functionLockService.release("GRID_" + this.uuid + "_RELOADGRIDWITHREQUESTMODEL");
			return Promise.resolve(responseModel);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	private async reload(requestModel: SlickGridRequestModel): Promise<SlickGridResponseModel> {
		try {
			await this.functionLockService.lock("GRID_" + this.uuid + "_RELOAD");

			requestModel = this.getRequestModel(this.requestModel);

			let responseModel: SlickGridResponseModel = null;
			if (requestModel.url)
				responseModel = await this.gridService.getDataFromServer(requestModel);
			else
				responseModel = await this.gridService.getDataFromDataObj(requestModel, this.data);

			this.loaded = true;
			this.totalRecords = responseModel.totalRecords;
			this.visibleData = responseModel.data;

			this.functionLockService.release("GRID_" + this.uuid + "_RELOAD");
			Promise.resolve(responseModel);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	unselectRow() {
		this.selectedIndex = null;
	}

	selectRow(rowNumber: number) {
		let rowData = this.visibleData[rowNumber];
		this.onRowClicked(rowData, rowNumber);
	}

	getColumnHTML(rowData, dataFieldName) {
		const columnHTML = SlickUtilsService.getDeepObject(rowData, dataFieldName);

		if (columnHTML === null || columnHTML === undefined)
			return '';

		return columnHTML.toString();
	}

	async sortBy(sortColumn: ISlickGridColumnModel) {
		this.gridOptions.columns.forEach(c => {
			if (sortColumn && c.columnKey !== sortColumn.columnKey) {

				c.sortDirection = SlickGridColumnSortDirection.none;
			}
		})

		if (sortColumn) {
			if (sortColumn.sortDirection === SlickGridColumnSortDirection.none) {
				this.visibleColumns.forEach(c => c.sortDirection = SlickGridColumnSortDirection.none);
				sortColumn.sortDirection = SlickGridColumnSortDirection.asc;
			}
			else if (sortColumn.sortDirection === SlickGridColumnSortDirection.asc)
				sortColumn.sortDirection = SlickGridColumnSortDirection.desc;
			else {
				sortColumn.sortDirection = SlickGridColumnSortDirection.none;
			}
		}

		this.saveColumnsToLocalStorage();

		this.currentPage = 1;
		this.requestModel.page = 1;
		await this.reload(this.requestModel);
		if (this.visibleData && this.visibleData.length > 0 && this.gridOptions.autoSelectFirstRow === true)
			this.selectRow(0);
	}

	async onSearchSelect(ddValue, column: ISlickGridColumnModel) {
		if (!this.requestModel.columnSearchValues)
			this.requestModel.columnSearchValues = [];

		let columnSearchValueIdx = this.requestModel.columnSearchValues.findIndex(x => x.columnName === column.dataFieldName);
		if (columnSearchValueIdx >= 0)
			this.requestModel.columnSearchValues.splice(columnSearchValueIdx, 1);

		column.isFiltering = false;

		if (ddValue) {
			this.requestModel.columnSearchValues.push(<ISlickGridRequestSearchModel>{
				columnName: column.dataFieldName,
				searchValueId: ddValue.id,
				searchValueText: ddValue.text
			});

			column.isFiltering = true;
		}
		else {
			column.isLocked = false;
		}

		this.currentPage = 1;
		this.requestModel.page = 1;
		await this.reload(this.requestModel);
		if (this.visibleData && this.visibleData.length > 0 && this.gridOptions.autoSelectFirstRow === true)
			this.selectRow(0);
	}

	async onSearch(column: ISlickGridColumnModel) {
		await SlickSleepService.sleep();

		if (!this.requestModel.columnSearchValues)
			this.requestModel.columnSearchValues = [];

		if (column.filterText === column.lastSearchText)
			return;

		column.lastSearchText = column.filterText;
		column.isFiltering = !!column.filterText;
		if (!column.isFiltering)
			column.isLocked = false;

		clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(async () => {
			this.saveColumnsToLocalStorage();

			let columnSearchValueIdx = this.requestModel.columnSearchValues.findIndex(x => x.columnName === column.dataFieldName);
			if (columnSearchValueIdx >= 0)
				this.requestModel.columnSearchValues.splice(columnSearchValueIdx, 1);

			if (column.filterText) {
				this.requestModel.columnSearchValues.push(<ISlickGridRequestSearchModel>{
					columnName: column.dataFieldName,
					searchValueText: column.filterText
				});
			}

			this.currentPage = 1;
			this.requestModel.page = 1;
			await this.reload(this.requestModel);
			if (this.visibleData && this.visibleData.length > 0 && this.gridOptions.autoSelectFirstRow === true)
				this.selectRow(0);
		}, 250);
	}

	toggleLock(column: ISlickGridColumnModel) {
		column.isLocked = !column.isLocked;

		this.saveColumnsToLocalStorage();
	}

	async resetFilter(column: ISlickGridColumnModel) {
		column.isFiltering = false;
		column.isLocked = false;

		column.filterText = null;
		column.lastSearchText = null;
		column.filterDropdownValue = null;
		this.requestModel.columnSearchValues = this.requestModel.columnSearchValues.filter(x => x.columnName !== column.dataFieldName);

		this.saveColumnsToLocalStorage();

		this.currentPage = 1;
		this.requestModel.page = 1;
		await this.reload(this.requestModel);
		if (this.visibleData && this.visibleData.length > 0 && this.gridOptions.autoSelectFirstRow === true)
			this.selectRow(0);
	}

	openColumnSelectDialog() {
		this.columnSelectDialogColumns = [...this.gridOptions.columns];
		this.columnSelectDialogColumns.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : (a.displayOrder < b.displayOrder) ? -1 : 0);

		this.columnSelectDialogRef.showDialog();
	}

	onColumnDrop(e: any) {
		console.info(e);
	}

	async onColumnReorder(e: number[]) {
		const oldOrder = e[0];
		const newOrder = e[1];

		if (oldOrder === newOrder)
			return;

		const selectedCol = this.columnSelectDialogColumns.splice(oldOrder, 1)[0];
		selectedCol.displayOrder = newOrder;

		let currentOrder = 0;
		// Everything up to the new order reordered from 0
		for (let i = 0; i < this.columnSelectDialogColumns.length; i++) {
			// If we're at newOrder, leave a spot
			if (i === newOrder)
				currentOrder++;
			this.columnSelectDialogColumns[i].displayOrder = currentOrder++;
		}

		this.columnSelectDialogColumns = [...this.columnSelectDialogColumns, selectedCol];
		this.columnSelectDialogColumns = this.columnSelectDialogColumns.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : (a.displayOrder < b.displayOrder) ? -1 : 0);
	}

	async addNewFavorite() {
		this.newFavoriteText = '';

		const confirmResult = await this.addNewFavoriteDialogRef.confirm();

		if (confirmResult === SlickConfirmDialogResults.Ok && this.newFavoriteText) {
			// Save the previous selection to localstorage
			this.saveDialogColumnsToLocalStorage();

			this.favorites.push(new DropDownModel(this.newFavoriteText));

			this.selectedFavorite = this.newFavoriteText;
			localStorage.setItem("GRID_" + this.gridOptions.gridKey + "_SELECTED_FAVORITE", this.selectedFavorite);

			const favoritesJson = JSON.stringify(this.favorites.map(x => x.text));
			localStorage.setItem("GRID_" + this.gridOptions.gridKey + "_FAVORITES", favoritesJson);

			// Save the new one to localstorage
			this.saveDialogColumnsToLocalStorage();
		}
	}

	loadFavorite(favorite: DropDownModel) {
		this.saveDialogColumnsToLocalStorage();

		this.selectedFavorite = favorite.text;
		let gridColumnsJson = localStorage.getItem(`GRID_${this.gridOptions.gridKey}_COLUMNS${this.selectedFavorite === 'Default' ? '' : '_' + this.selectedFavorite}`);
		if (gridColumnsJson) {
			this.columnSelectDialogColumns = <ISlickGridColumnModel[]>JSON.parse(gridColumnsJson);
			this.columnSelectDialogColumns.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : (a.displayOrder < b.displayOrder) ? -1 : 0);
		}
	}

	async setFavorite(favorite: string) {
		this.selectedFavorite = favorite;
		localStorage.setItem("GRID_" + this.gridOptions.gridKey + "_SELECTED_FAVORITE", this.selectedFavorite);

		let gridColumnsJson = localStorage.getItem(`GRID_${this.gridOptions.gridKey}_COLUMNS${this.selectedFavorite === 'Default' ? '' : '_' + this.selectedFavorite}`);
		this.gridOptions.columns = <ISlickGridColumnModel[]>JSON.parse(gridColumnsJson);
		this.gridOptions.columns.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : (a.displayOrder < b.displayOrder) ? -1 : 0);

		this.visibleColumns = this.gridOptions.columns.filter(c => c.visible === true);
		this.visibleColumns = this.visibleColumns.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : (a.displayOrder < b.displayOrder) ? -1 : 0);
		this.showSearchRow = this.visibleColumns.filter(c => c.filterType !== SlickGridColumnFilterTypes.none).length > 0;

		this.currentPage = 1;
		this.requestModel.page = 1;
		this.requestModel.recordsPerPage = this.recordsPerPage;
		await this.reload(this.requestModel);
	}

	onSaveColumns() {
		this.saveDialogColumnsToLocalStorage();

		this.gridOptions.columns = [...this.columnSelectDialogColumns];

		// Make sure if they've hidden a column that we aren't trying to sort or filter by this column
		this.gridOptions.columns.forEach(x => {
			if (x.visible === false) {
				x.filterDropdownValue = null;
				x.filterText = null;
				x.isFiltering = false;
				x.isLocked = false;
				x.lastSearchText = null;
				x.sortDirection = null;
			}
		});

		this.visibleColumns = this.gridOptions.columns.filter(c => c.visible === true);
		this.visibleColumns = this.visibleColumns.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : (a.displayOrder < b.displayOrder) ? -1 : 0);
		this.showSearchRow = this.visibleColumns.filter(c => c.filterType !== SlickGridColumnFilterTypes.none).length > 0;

		this.columnSelectDialogRef.hideDialog();
		this.resizeGrid();

		if (this.onFavoritesModified) {
			const gridFavorites: ISlickGridFavoriteModel[] = this.favorites.map(favorite => {
				const gridFavorite = new SlickGridFavoriteModel();
				gridFavorite.favoriteName = favorite.text;
				const suffix = gridFavorite.favoriteName === 'Default' ? '' : ('_' + gridFavorite.favoriteName);
				const dialogColumnsJSON = localStorage.getItem(`GRID_${this.gridOptions.gridKey}_COLUMNS${suffix}`);
				gridFavorite.columns = JSON.parse(dialogColumnsJSON);
				return gridFavorite;
			});

			this.onFavoritesModified.emit(gridFavorites);
		}
	}

	onCancelColumns() {
		this.columnSelectDialogRef.hideDialog();
	}

	saveDialogColumnsToLocalStorage() {
		let dialogColumnsJSON = JSON.stringify(this.columnSelectDialogColumns);
		if (this.gridOptions.gridKey) {
			const suffix = this.selectedFavorite === 'Default' ? '' : ('_' + this.selectedFavorite);
			localStorage.setItem(`GRID_${this.gridOptions.gridKey}_COLUMNS${suffix}`, dialogColumnsJSON);
		}
	}

	saveColumnsToLocalStorage() {
		let gridColumnsJSON = JSON.stringify(this.gridOptions.columns);
		if (this.gridOptions.gridKey) {
			const suffix = this.selectedFavorite === 'Default' ? '' : ('_' + this.selectedFavorite);

			let gridColumnsJson = localStorage.getItem(`GRID_${this.gridOptions.gridKey}_COLUMNS${suffix}`);
			
			if (gridColumnsJson) {
				let columns = <ISlickGridColumnModel[]>JSON.parse(gridColumnsJson);

				let displayOrder = 0;
				columns.forEach(c => {
					let column = this.gridOptions.columns.find(gridColumn => gridColumn.dataFieldName === c.dataFieldName);
					if (column) {
						c.displayOrder = displayOrder;
						c.titleOrientation = column.titleOrientation;
						c.titleHeight = column.titleHeight;
						c.width = column.width;
						c.filterDropdownValue = column.filterDropdownValue;
						c.filterText = column.filterText;
						c.isLocked = column.isLocked;
						c.isFiltering = column.isFiltering;
						c.sortDirection = column.sortDirection;
					}

					displayOrder++;
				});
				
				columns = columns.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : (a.displayOrder < b.displayOrder) ? -1 : 0);
				gridColumnsJSON = JSON.stringify(columns);
			}

			localStorage.setItem(`GRID_${this.gridOptions.gridKey}_COLUMNS${suffix}`, gridColumnsJSON);
		}

		if (this.onFavoritesModified) {
			const gridFavorites: ISlickGridFavoriteModel[] = this.favorites.map(favorite => {
				const gridFavorite = new SlickGridFavoriteModel();
				gridFavorite.favoriteName = favorite.text;
				const suffix = gridFavorite.favoriteName === 'Default' ? '' : ('_' + gridFavorite.favoriteName);
				const dialogColumnsJSON = localStorage.getItem(`GRID_${this.gridOptions.gridKey}_COLUMNS${suffix}`);
				gridFavorite.columns = JSON.parse(dialogColumnsJSON);
				return gridFavorite;
			});

			this.onFavoritesModified.emit(gridFavorites);
		}
	}


	private async resizeGrid(forceRefresh: boolean = false): Promise<void> {
		return new Promise<void>(async (resolve) => {
			if (!forceRefresh && this.slickGridRef.nativeElement.offsetHeight === this.slickGridHeight) {
				resolve();
				return;
			}

			if (this.recordsPerPageValue === 'Auto') {
				await this.functionLockService.lock("GRID_" + this.uuid + "_RESIZEGRID");

				this.slickGridHeight = this.tableContainerRef.nativeElement.offsetHeight;

				const testRow = (<HTMLTableElement>this.tableRef.nativeElement).insertRow(0);
				const testCell = testRow.insertCell(0);
				testCell.style.visibility = "hidden";
				testCell.innerHTML = "&NBSP";
				const rowHeight = testCell.offsetHeight;
				(<HTMLTableElement>this.tableRef.nativeElement).deleteRow(0);

				let headerHeight = this.tableHeaderRowRef.nativeElement.offsetHeight + 2;
				if (this.tableHeaderSearchRowRef)
					headerHeight += this.tableHeaderSearchRowRef.nativeElement.offsetHeight + 2;
				const clientHeight = this.tableContainerRef.nativeElement.offsetHeight - headerHeight;
				this.recordsPerPage = parseInt(((clientHeight / rowHeight) - 1).toFixed(0));
				let actualRowHeight = parseInt((clientHeight / this.recordsPerPage).toFixed(0)) - 1;
				if (actualRowHeight < rowHeight)
					actualRowHeight = rowHeight;

				const allRows = <HTMLElement[]>this.slickGridRef.nativeElement.querySelectorAll(".table tbody tr");
				allRows.forEach(row => {
					row.style.height = (actualRowHeight) + "px";
				});

				this.functionLockService.release("GRID_" + this.uuid + "_RESIZEGRID");

				this.gridOpacity = 1;
				this.showTestRow = false;

				resolve();
			}
			else {
				await SlickSleepService.sleep();

				this.recordsPerPage = parseInt(this.recordsPerPageValue);
				this.gridOpacity = 1;
				resolve();
			}
		});
	}

	addColumn(column: ISlickGridColumnModel, idx: number = null) {

		if (idx === null)
			this.gridOptions.columns.push(column);
		else
			this.gridOptions.columns.splice(idx, 0, column);

		this.setGridOptions(this.gridOptions);
	}

	getRequestModel(requestModel: SlickGridRequestModel = null): SlickGridRequestModel {
		if (!requestModel)
			requestModel = this.requestModel;

		requestModel.columnSearchValues = [];

		this.gridOptions.columns.forEach(c => {
			if (c.visible) {
				if (c.filterType === SlickGridColumnFilterTypes.text && c.filterText) {
					c.filterText = c.filterText;
					requestModel.columnSearchValues.push(<ISlickGridRequestSearchModel>{
						columnName: c.dataFieldName,
						searchValueId: null,
						searchValueText: c.filterText
					});
				}
				else if (c.filterType === SlickGridColumnFilterTypes.dropdown && c.filterDropdownValue) {
					c.filterDropdownValue = c.filterDropdownValue;
					requestModel.columnSearchValues.push(<ISlickGridRequestSearchModel>{
						columnName: c.dataFieldName,
						searchValueId: c.filterDropdownValue,
						searchValueText: null
					});
				}
			}
		});

		requestModel.sortColumn = null;
		const sortColumn = this.gridOptions.columns.find(x => x.sortDirection === SlickGridColumnSortDirection.asc || x.sortDirection === SlickGridColumnSortDirection.desc);
		if (sortColumn) {
			requestModel.sortColumn = (sortColumn.sortDirection === 0) ? null : sortColumn.dataFieldName;
			requestModel.sortDirection = sortColumn.sortDirection;
		}

		if (requestModel.recordsPerPage <= 0)
			requestModel.recordsPerPage = 20;

		return this.requestModel;
	}

	setGridOptions(gridOptions: ISlickGridOptions) {
		this.gridOptions = gridOptions;

		if (this.gridOptions.gridKey) {

			if (this.gridOptions.favorites) {
				const favoriteNames = this.gridOptions.favorites.map(f => f.favoriteName);
				localStorage.setItem(`GRID_${this.gridOptions.gridKey}_FAVORITES`, JSON.stringify(favoriteNames));

				this.gridOptions.favorites.forEach(favorite => {
					// Make sure we haven't added a new column that needs to be saved
					this.gridOptions.columns.forEach(c => {
						const columnExists = favorite.columns.findIndex(x => x.dataFieldName === c.dataFieldName);
						if (columnExists < 0)
							favorite.columns.push(c);
					});
					let gridColumnsJSON = JSON.stringify(favorite.columns);
					if (this.gridOptions.gridKey) {
						const suffix = favorite.favoriteName === 'Default' ? '' : ('_' + favorite.favoriteName);
						localStorage.setItem(`GRID_${this.gridOptions.gridKey}_COLUMNS${suffix}`, gridColumnsJSON);
					}
				});

				if (favoriteNames.indexOf(this.selectedFavorite) < 0)
					this.selectedFavorite = "Default";
			}

			this.selectedFavorite = localStorage.getItem("GRID_" + this.gridOptions.gridKey + "_SELECTED_FAVORITE") || 'Default';

			// Check to make sure we don't have any duplicate column names
			this.gridOptions.columns.forEach(c1 => {
				const dataFieldCount = (this.gridOptions.columns.filter(x => x.dataFieldName === c1.dataFieldName) || []).length;
				if (dataFieldCount > 1)
					console.error(`Grid ${this.gridOptions.gridKey} column ${c1.dataFieldName} is defined ${dataFieldCount} times`);
			});

			const suffix = this.selectedFavorite === 'Default' ? '' : ('_' + this.selectedFavorite);
			let gridColumnsJson = localStorage.getItem(`GRID_${this.gridOptions.gridKey}_COLUMNS${suffix}`);
			if (gridColumnsJson) {
				let columns = <ISlickGridColumnModel[]>JSON.parse(gridColumnsJson);

				let displayOrder = 0;
				columns.forEach(c => {
					let column = this.gridOptions.columns.find(gridColumn => gridColumn.dataFieldName === c.dataFieldName);
					if (column) {
						column.visible = c.visible;
						column.displayOrder = displayOrder;
						column.titleOrientation = c.titleOrientation;
						column.titleHeight = c.titleHeight;
						column.width = c.width;
						column.flexible = c.flexible;
						column.sortDirection = c.sortDirection;
						column.isLocked = c.isLocked;
						//column.filterType = c.filterType;
						if (column.isLocked && column.filterType !== SlickGridColumnFilterTypes.none) {
							column.isFiltering = true;

							if (column.filterType === SlickGridColumnFilterTypes.text)
								column.filterText = c.filterText;
							else if (column.filterType === SlickGridColumnFilterTypes.dropdown)
								column.filterDropdownValue = c.filterDropdownValue;

							if (!column.filterText && !column.filterDropdownValue) {
								column.isFiltering = false;
								column.isLocked = false;
								column.filterText = null;
								column.filterDropdownValue = null;
							}
						}
					}

					displayOrder++;
				});
			}

			if (this.gridOptions.defaultRecordsPerPage && !localStorage.getItem("GRID_" + this.gridOptions.gridKey + "_RECORDS_PER_PAGE"))
				this.recordsPerPageValue = this.gridOptions.defaultRecordsPerPage.toString();
			else
				this.recordsPerPageValue = localStorage.getItem("GRID_" + this.gridOptions.gridKey + "_RECORDS_PER_PAGE") || "Auto";

			const favoritesJson = localStorage.getItem("GRID_" + this.gridOptions.gridKey + "_FAVORITES") || `["Default"]`;
			this.favorites = (<string[]>JSON.parse(favoritesJson)).map(favorite => {
				return new DropDownModel(favorite);
			});
		}

		this.visibleColumns = this.gridOptions.columns.filter(c => c.visible === true);
		this.visibleColumns = this.visibleColumns.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : (a.displayOrder < b.displayOrder) ? -1 : 0);

		this.showSearchRow = this.visibleColumns.filter(c => c.filterType !== SlickGridColumnFilterTypes.none).length > 0;
		let sortColumn = this.visibleColumns.find(c => c.sortable === true && c.sortDirection > 0);
		if (this.visibleColumns.length > 0 && sortColumn) {
			this.requestModel.sortColumn = sortColumn.dataFieldName;
			this.requestModel.sortDirection = sortColumn.sortDirection;
		}
	}
}