import { Component, Input, isDevMode, Output, ViewChild, EventEmitter } from "@angular/core";
import { SlickDialogComponent, SlickToastService } from "@slick-components";
import { CreditCardService, GlobalsService, TimeclockService } from "@services"
import { IPaymentModel, ITimeclockEntryModel, PaymentModel } from "@models";



@Component({
	selector: "refund-payment-dialog",
	templateUrl: "./refund-payment-dialog.component.html",
	 providers: [TimeclockService]
})
export class RefundPaymentDialogComponent{
	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;
	@Input() isMobile: boolean = false;
	@Input() paymentModel: IPaymentModel;
	@Output() onPaymentRefunded = new EventEmitter<IPaymentModel>();


	refundAmount: number;
	invalidRefund: boolean;
	refundEqualZero: boolean;
	submitted: boolean;
	refundButtonStatus: string;
	resolve: any;

	constructor(
		private creditCardService: CreditCardService,
		private slickToastService: SlickToastService,


	) {
		
	}

	async showDialog(): Promise<IPaymentModel> {
		return new Promise<IPaymentModel>((resolve) => {
			this.resolve = resolve;
			if (this.isMobile) {
				this.dialogRef.width = 360;
			}
			else {
				this.dialogRef.width = 700;
			}
			this.refundAmount = 0;
			this.refundEqualZero = false;
			this.invalidRefund = false;
			this.refundButtonStatus = 'reset';
			this.submitted = false;

			this.dialogRef.showDialog();
		});
		
	}

	async refundPayment() {
		try {
			this.refundButtonStatus = "spin";
			this.submitted = true;
			this.invalidRefund = false;
			this.refundEqualZero = false;

			if (this.refundAmount > this.paymentModel.amountAvailable)
				this.invalidRefund = true;
			if (this.refundAmount <= 0)
				this.refundEqualZero = true;
			if (this.refundEqualZero || this.invalidRefund ) {
				this.refundButtonStatus = "error"
				return;
			}

			const refundedPaymentModel = await this.creditCardService.refundPayment(this.paymentModel, this.refundAmount);
			if (refundedPaymentModel) {
				this.slickToastService.showSuccess(`Payment refunded for $${this.refundAmount.toFixed(2)}`);
				this.dialogRef.hideDialog();
				this.refundButtonStatus = "ok";
				this.onPaymentRefunded.emit(refundedPaymentModel);
				this.resolve(refundedPaymentModel);
			}

		} catch {
			this.refundButtonStatus = "error";
		}
	}

	close() {
		this.resolve();
		this.dialogRef.hideDialog();
	}
}
