import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlickSplitScreenComponent } from "./slick-split-screen.component";
import { SlickSplitScreenLeftContentComponent } from "./slick-screen-split-left-content.component";
import { SlickSplitScreenRightContentComponent } from "./slick-screen-split-right-content.component";
@NgModule({
	imports: [CommonModule],
	declarations: [SlickSplitScreenComponent, SlickSplitScreenLeftContentComponent, SlickSplitScreenRightContentComponent],
	exports: [SlickSplitScreenComponent, SlickSplitScreenLeftContentComponent, SlickSplitScreenRightContentComponent]
})
export class SlickSplitScreenModule { }

export { SlickSplitScreenComponent, SlickSplitScreenLeftContentComponent, SlickSplitScreenRightContentComponent }