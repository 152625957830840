import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";

import { IOpenHoursDayModel } from "@models";
import moment from "moment";

@Injectable()
export class OpenHoursDayService {
    constructor(private httpService: HttpService) { }

    getOpenHours(): Promise<IOpenHoursDayModel> {
        return this.httpService.get("/openHoursDay/getOpenHours");
    }

    getOpenHoursForDate(openHoursDate: Date): Promise<IOpenHoursDayModel> {
        const params = {
            openHoursDate: moment(openHoursDate).format('YYYY-MM-DD')
        };
        return this.httpService.get(`/openHoursDay/getOpenHoursForDate`, params);
    }
}