import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlickAccordionModule, SlickAutoCompleteModule, SlickButtonSpinnerModule, SlickCardModule, SlickConfirmDialogModule, SlickContainerModule, SlickContextMenuModule, SlickDatePickerModule, SlickDropDownModule, SlickFileDropModule, SlickFileListModule, SlickGridModule, SlickProfilePictureModule, SlickSplitScreenModule, SlickTabsModule, SlickToastModule, SlickValidationIndicatorModule, SlickGoogleAddressSearchModule, SlickSearchBarModule } from "@slick-components";
import { SystemLogsModule } from "@components/system-logs";
import { ComponentsModule } from "@components";
import { CustomerSearchModule } from "@components/customer-search";
import { AppointmentsComponentsModule } from "@app/appointments/components";
import { AutoSchedulerModule } from "@app/auto-scheduler/auto-scheduler.module";
import { EstimatesComponentsModule } from "@app/estimates/estimates-components";
import { SalesOrdersComponentsModule } from "@app/sales-orders/sales-orders-components";
import { InvoiceComponentsModule } from "@app/invoices/invoice-components";

import { JobEditComponent } from "./job-edit/job-edit.component";
import { JobEditDialogComponent } from "./job-edit/job-edit-dialog.component";
import { ScheduleDisplayService } from "@services";
import { ConflictingAppointmentsDialogModule } from "@shared-components/conflicting-appointments-dialog";
import { TextChatMessagesModule } from "@app/text-chat/text-chat-components/text-chat-messages";
import { JobCostingDetailsModule } from "@app/job-costing-details/job-costing-details.module";

@NgModule({
	imports: [CommonModule,
		ComponentsModule,
		SystemLogsModule,
		CustomerSearchModule,
		AutoSchedulerModule,
		AppointmentsComponentsModule,
		EstimatesComponentsModule,
		SalesOrdersComponentsModule,
		InvoiceComponentsModule,
		JobCostingDetailsModule,
		TextChatMessagesModule,
		ConflictingAppointmentsDialogModule,
		SlickAutoCompleteModule, SlickAccordionModule, SlickButtonSpinnerModule, SlickContextMenuModule, SlickCardModule, SlickConfirmDialogModule, SlickContainerModule,
        SlickDropDownModule, SlickDatePickerModule, SlickFileDropModule, SlickFileListModule, SlickProfilePictureModule, SlickToastModule, SlickGridModule, SlickGoogleAddressSearchModule,
		SlickSplitScreenModule, SlickTabsModule, SlickValidationIndicatorModule, SlickSearchBarModule],
	declarations: [JobEditDialogComponent, JobEditComponent],
	exports: [JobEditDialogComponent, JobEditComponent]
})
export class JobComponentsModule {
}


