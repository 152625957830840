<slick-dialog #dialogRef header="Refund Payment">
    <div>
        <div class="ms-3 mb-2">
            Amount available: {{paymentModel?.amountAvailable | currency}}
        </div>
        <div class="col-4">
            <label>Amount to refund:</label>
            <input type="text"
                   [slick-currency-mask]
                   class="form-control"
                   [(ngModel)]="refundAmount" />

        </div>
        <form-error *ngIf="refundEqualZero && submitted">Refund amount is zero</form-error>
        <form-error *ngIf="invalidRefund && submitted">Refund amount greater than amount available</form-error>

    </div>
    <slick-dialog-footer>
        <button class="btn btn-outline-success me-2" style="width:200px" type="button" [slick-button-spinner]="refundButtonStatus" (click)="refundPayment()"><i class="far fa-credit-card"></i> Process Refund</button>
        <button class="btn btn-outline-danger" type="button" (click)="close()"><i class="far fa-ban"></i> Cancel</button>
    </slick-dialog-footer>

</slick-dialog>