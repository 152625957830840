import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { SlickDatePickerComponent } from "./slick-date-picker.component";
import { SlickCalendarModule } from "../slick-calendar/slick-calendar.module";

@NgModule({
	imports: [CommonModule, FormsModule, SlickCalendarModule],
	declarations: [SlickDatePickerComponent],
	exports: [SlickDatePickerComponent, SlickCalendarModule]
})
export class SlickDatePickerModule { }

export { SlickDatePickerComponent }