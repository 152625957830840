import * as moment from "moment";
import { IScheduledAppointmentModel } from "./scheduled-appointment.model";

export interface IAppointmentVisibility {
	isVisible: boolean;
	isTravelVisible: boolean;
	isScheduledApptVisibile: boolean;
	appointmentBlockStart: moment.Moment;
	appointmentBlockEnd: moment.Moment;
	travelStart: moment.Moment;
	travelEnd: moment.Moment;
	travelMinutes: number;
	scheduledApptStart: moment.Moment;
	scheduledApptEnd: moment.Moment;
	scheduledMinutes: number;
	startPartial: boolean;
	endPartial: boolean;
}

export class AppointmentVisibility implements IAppointmentVisibility {
	isVisible: boolean;
	isTravelVisible: boolean;
	isScheduledApptVisibile: boolean;
	appointmentBlockStart: moment.Moment;
	appointmentBlockEnd: moment.Moment;
	travelStart: moment.Moment;
	travelEnd: moment.Moment;
	travelMinutes: number;
	scheduledApptStart: moment.Moment;
	scheduledApptEnd: moment.Moment;
	scheduledMinutes: number;
	startPartial: boolean;
	endPartial: boolean;

	constructor(appt: IScheduledAppointmentModel, visibleStartHour: number, visibleEndHour: number) {
		visibleEndHour = (visibleEndHour < 24) ? visibleEndHour + 1 : visibleEndHour;

		this.isVisible = true;
		this.isTravelVisible = true;
		this.isScheduledApptVisibile = true;
		this.startPartial = false;
		this.endPartial = false;

		const visibleStartDate = moment(appt.scheduledDateTime).startOf("date").add(visibleStartHour, "hours");
		const visibleEndDate = moment(appt.scheduledDateTime).startOf("date").add(visibleEndHour, "hours");

		this.appointmentBlockStart = moment(appt.appointmentBlockStart);
		this.appointmentBlockEnd = moment(appt.appointmentBlockEnd);
		this.travelStart = moment(this.appointmentBlockStart);
		this.travelEnd = moment(this.travelStart).add(appt.travelMinutes, "minutes");
		this.travelMinutes = appt.travelMinutes;
		this.scheduledApptStart = moment(this.travelEnd);
		this.scheduledApptEnd = moment(this.scheduledApptStart).add(appt.scheduledMinutes, "minutes");
		this.scheduledMinutes = appt.scheduledMinutes;

		if (this.appointmentBlockEnd.diff(visibleStartDate, "minutes") <= 0 || this.appointmentBlockStart.diff(visibleEndDate, "minutes") > 0) {
			this.isVisible = false;
			return;
		}

		// See if travel is visible
		if (this.travelEnd.diff(visibleStartDate, "minutes") <= 0) {
			this.isTravelVisible = false;
			this.startPartial = true;
		}

		if (this.travelStart.diff(visibleStartDate, "minutes") < 0) {
			this.travelStart = moment(visibleStartDate);
			this.appointmentBlockStart = moment(visibleStartDate);
			this.travelMinutes = this.scheduledApptStart.diff(this.travelStart, "minutes");
			this.startPartial = true;
		}

		if (this.travelEnd.diff(visibleEndDate, "minutes") > 0) {
			this.isScheduledApptVisibile = false;
			this.travelEnd = moment(visibleEndDate);
			this.appointmentBlockEnd = moment(visibleEndDate);
			this.travelMinutes = this.travelEnd.diff(this.travelStart, "minutes");
			this.endPartial = true;
		}

		// See if appt is visible
		if (this.scheduledApptStart.diff(visibleEndDate, "minutes") >= 0) {
			this.isScheduledApptVisibile = false;
			this.endPartial = true;
		}

		if (this.isScheduledApptVisibile === true && visibleEndDate.diff(this.scheduledApptEnd, "minutes") < 0) {
			this.scheduledApptEnd = moment(visibleEndDate);
			this.appointmentBlockEnd = moment(visibleEndDate);
			this.scheduledMinutes = visibleEndDate.diff(this.scheduledApptStart, "minutes");
			this.endPartial = true;
		}

	}
}
