<slick-dialog #dialogRef header="Conflict" width="380" key="conflictingCustomersDialog" [resizable]="false" [draggable]="false" [minimizable]="false" (onClose)="cancel()">
    <section class="ms-4">
        <h4 class="text-danger">Warning!</h4>
        <h4 class="text-danger">A customer with this address already exists. Continue?</h4>
        <h4></h4>
        <ng-container>
            <div>
                <ng-container *ngFor="let customer of conflictingCustomers">
                    <div style="position:relative; left:250px; top:50px">
                        <button class="btn btn-outline-primary" style="margin-left: auto;" (click)="onCustomerClicked(customer.customerId)">Select</button>
                    </div>
                    <h4 class="border-bottom"></h4>
                    <div><span class="font-weight-bold">{{ customer.companyName }}</span></div>
                    <div><span>{{ customer.firstName }}</span>&nbsp;<span>{{ customer.lastName }}</span></div>
                    <div><span>{{ customer.address1 }}</span></div>
                    <div><span>{{ customer.city }}</span><span>,</span>&nbsp;<span>{{ customer.state }}</span><span>,</span>&nbsp;<span>{{ customer.zipcode }}</span></div>
                    <div><span>Cell: </span><span>{{ customer.cellPhone }}</span></div>
                    <div><span>Email: </span><span>{{ customer.email }}</span></div>

                </ng-container>
            </div>
        </ng-container>


        <!--<div style="cursor:pointer" (click)="onCustomerClicked(customer.customerId)">
           </div>-->
    </section>
    <slick-dialog-footer >
        <div class="flex-fill ms-1">
            <button class="btn btn-outline-success me-3" (click)="continue()"><i class="far fa-check"></i> Continue</button>
            <button class="btn btn-outline-danger" (click)="cancel()"><i class="far fa-ban"></i> Cancel</button>
        </div>
    </slick-dialog-footer>
</slick-dialog>

