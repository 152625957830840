import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlickDropListDirective } from "./slick-drop-list.directive";
import { SlickDropListItemDirective } from "./slick-drop-list-item.directive";
import { SlickDropListGrabDirective } from "./slick-drop-list-grab.directive";

@NgModule({
	imports: [CommonModule],
	declarations: [SlickDropListDirective, SlickDropListItemDirective, SlickDropListGrabDirective],
	exports: [SlickDropListDirective, SlickDropListItemDirective, SlickDropListGrabDirective]
})
export class SlickDropListModule { }

export { SlickDropListDirective } 
export { SlickDropListItemDirective } 
export { SlickDropListGrabDirective }
