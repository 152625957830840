import { IJobModel } from "../jobs/job.model";
import { IAppointmentChecklistQuestionModel } from "../appointments/appointment-checklist-question.model";
import { IAppointmentSmartlistQuestionModel } from "./appointment-smartlist-question.model";
import * as moment from 'moment';
import { UtilsService } from "../../services";

export interface IAppointmentModel {
	appointmentId: number;
	uuid: string;
	sequence: number;
	jobId: number;
	invoiceId?: number;
	acuityReferenceId?: number;
	qproItemIdentifier?: number;
	appointmentTypeId: number;
	appointmentTypeDisplayName: string;
	appointmentStatusId: number;
	appointmentStatusDisplayName: string;
	appointmentStatusScheduleColor: string;
	resourceId: number;
	resourceDisplayName: string;
	crewDisplay: string;
	scheduledDateTime: Date;
	scheduledMinutes: number;
	travelMinutes: number;
	laborHours: number;
	isEmergency: boolean;
	goBack: boolean;
	taxable: boolean;
	suspended: boolean;
	locked: boolean;
	unscheduled?: boolean;
	unscheduledDisplaySeq?: number;
	showOnSmartlist: boolean;
	smartlistFutureAppt: boolean;
	smartlistDelinquent: boolean;
	linkToAcuity: boolean;
	description: string;
	notes: string;
	customerNotes: string;
	createdByInitials: string;
	createdDate: Date;
	inTransitStartTime?: Date;
	inTransitTotalDisplay: string;
	atJobsiteStartTime?: Date;
	atJobsiteTotalDisplay: string;
	finishedTime?: Date;
	finishedTotalDisplay?: string;
	finishedByUserName: string;
	excludeFromInsights: boolean;
	lastUpdated: Date;
    job: IJobModel;
    uiState: string;
	isDeleted: boolean;
	lockDuration: boolean;
	sendReminder: boolean;
	actualCrew: string;
	photoUploaded: boolean;

	checklistQuestions: IAppointmentChecklistQuestionModel[];
	smartlistQuestions: IAppointmentSmartlistQuestionModel[];
}

export class AppointmentModel implements IAppointmentModel {
	constructor() {
		this.uuid = UtilsService.newGuid();
		this.sendReminder = true;
    }
	appointmentId: number;
	uuid: string;
	sequence: number;
	jobId: number;
	invoiceId?: number;
	acuityReferenceId?: number;
	qproItemIdentifier?: number;
	appointmentTypeId: number;
	appointmentTypeDisplayName: string;
	appointmentStatusId: number;
	appointmentStatusDisplayName: string;
	appointmentStatusScheduleColor: string;
	resourceId: number;
	resourceDisplayName: string;
	crewDisplay: string;
	scheduledDateTime: Date;
	scheduledMinutes: number;
	travelMinutes: number;
	laborHours: number;
	isEmergency: boolean;
	goBack: boolean;
	taxable: boolean;
	suspended: boolean;
	locked: boolean;
	unscheduled?: boolean;
	unscheduledDisplaySeq?: number;
	showOnSmartlist: boolean;
	smartlistFutureAppt: boolean;
	smartlistDelinquent: boolean;
	linkToAcuity: boolean;
	description: string;
	notes: string;
	customerNotes: string;
	createdByInitials: string;
	createdDate: Date;
	inTransitStartTime?: Date;
	inTransitTotalDisplay: string;
	atJobsiteStartTime?: Date;
	atJobsiteTotalDisplay: string;
	finishedTime?: Date;
	finishedTotalDisplay?: string;
	finishedByUserName: string;
	excludeFromInsights: boolean;
	lastUpdated: Date;
    job: IJobModel;
    uiState: string;
	isDeleted: boolean;
	lockDuration: boolean;
	sendReminder: boolean;
	actualCrew: string;
	photoUploaded: boolean;


	checklistQuestions: IAppointmentChecklistQuestionModel[];
	smartlistQuestions: IAppointmentSmartlistQuestionModel[];

}
