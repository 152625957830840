import { Injectable, Optional } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { SlickSleepService } from "../utils/slick-sleep.service";
import { SlickUtilsService } from "../utils/slick-utils.service";

interface ISlickToastInfo {
	uuid: string
	message: string;
	fadeTimeout: NodeJS.Timer;
	messageDiv: HTMLDivElement;
}

class SlickToastInfo {
	uuid: string;
	message: string;
	fadeTimeout: NodeJS.Timer;
	messageDiv: HTMLDivElement;
}

@Injectable()
export class SlickToastService {
	private static messageInfos: ISlickToastInfo[] = [];

	constructor(@Optional() private router: Router) {
		if (document.getElementById("slick-toasts") === null) {
			let container = document.createElement("div");
			container.id = "slick-toasts";
			document.body.appendChild(container);
		}

		if (this.router) {
			// Every time we change a page, reset the page auto-logout timeout
			this.router.events.subscribe((event) => {
				if (event instanceof NavigationStart) {
					this.resetAll();
				}
			});
		}
	}

	showSuccess(message: string, delay: number = 6000) {
		this.showMessage("<i style='font-size: 1.2em' class='far fa-check'></i>&nbsp;&nbsp;" + message, delay, "bg-success");
	}

	showInfo(message: string, delay: number = 6000) {
		this.showMessage("<i style='font-size: 1.2em' class='far fa-info-circle'></i>&nbsp;&nbsp;" + message, delay, "bg-info");
	}

	showWarning(message: string, delay: number = 6000) {
		this.showMessage("<i style='font-size: 1.2em' class='far fa-exclamation-triangle'></i>&nbsp;&nbsp;" + message, delay, "bg-warning");
	}

	showDanger(message: string, delay: number = 6000) {
		this.showMessage("<i style='font-size: 1.2em' class='far fa-times-circle'></i>&nbsp;&nbsp;" + message, delay, "bg-danger");
	}

	resetAll() {
		for (let i = 0; i < SlickToastService.messageInfos.length; i++) {
			clearTimeout(SlickToastService.messageInfos[i].fadeTimeout);
			const container = document.getElementById("slick-toasts");

			if (container) {
				try {
					container.removeChild(SlickToastService.messageInfos[i].messageDiv);
				}
				catch { }
			}
		}
	}

	private async fadeOut(messageInfo: ISlickToastInfo) {
		messageInfo.messageDiv.style.opacity = "0";
		await SlickSleepService.sleep(600);
		let container = document.getElementById("slick-toasts");
		if (container) {
			try {
				container.removeChild(messageInfo.messageDiv);
			}
			catch { }
		}

		SlickToastService.messageInfos = SlickToastService.messageInfos.filter(x => x.uuid !== messageInfo.uuid);
	}

	private async showMessage(message: string, delay: number, bgClass: string) {
		let container = document.getElementById("slick-toasts");

		let newMessage: ISlickToastInfo = new SlickToastInfo();
		newMessage.uuid = SlickUtilsService.newGuid();
		newMessage.message = message;
		newMessage.fadeTimeout = setTimeout(() => this.fadeOut(newMessage), delay);

		newMessage.messageDiv = document.createElement("div");
		newMessage.messageDiv.id = "slick-toast_" + newMessage.uuid;
		newMessage.messageDiv.classList.add('slick-toast');
		newMessage.messageDiv.classList.add('jumbotron');
		newMessage.messageDiv.classList.add(bgClass);
		newMessage.messageDiv.innerHTML = message;

		SlickToastService.messageInfos.push(newMessage);

		container.insertAdjacentElement('afterbegin', newMessage.messageDiv);
		await SlickSleepService.sleep();
		document.getElementById("slick-toast_" + newMessage.uuid).style.opacity = "1";
	}
}