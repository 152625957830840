import { ICustomerModel } from "../customers/customer.model";
import * as moment from 'moment';
import { iCalModel } from "./iCal.model";
import { LineItemModel } from "../line-items/line-item.model";
import { GlobalsService } from "../../services";

export interface IRecurringBillingModel {
    recurringBillingId: number;
    recurringName: string;
    lineItemGroupId: number;
    invoiceNumber: string;
    purchaseOrderNumber: string;
    lineItems: LineItemModel[];
    billToCustomerId: number;
    startDate: Date;
    endDate: Date;
    lastUpdated: Date;
    billToCustomer: ICustomerModel;
    iCalBilling: iCalModel;
    lastExtendedDate: Date;
    notes: string;
    creditCardPaymentTypeId?: number;
    isPaused: boolean;
    nextBillDate?: Date;
    lastTransactionIsSuccess: boolean;
    lastTransactionStatusMessage: string;
    createdDate: Date;
    amount: number;
    syncToQuickbooks: boolean;
    taxRate: number;
}

export class RecurringBillingModel implements IRecurringBillingModel {
    constructor() {
        this.lineItems = [];
        this.startDate = moment().startOf("day").toDate();
        this.iCalBilling = new iCalModel();
        this.taxRate = GlobalsService.company.taxRate || 0;
    }
    recurringBillingId: number;
    recurringName: string;
    lineItemGroupId: number;
    invoiceNumber: string;
    purchaseOrderNumber: string;
    lineItems: LineItemModel[];
    billToCustomerId: number;
    startDate: Date;
    endDate: Date;
    lastUpdated: Date;
    billToCustomer: ICustomerModel;
    iCalBilling: iCalModel;
    lastExtendedDate: Date;
    notes: string;
    creditCardPaymentTypeId?: number;
    isPaused: boolean;
    nextBillDate?: Date;
    lastTransactionIsSuccess: boolean;
    lastTransactionStatusMessage: string;
    createdDate: Date;
    amount: number;
    syncToQuickbooks: boolean;
    taxRate: number;



}