import { Injectable } from "@angular/core"; 
import { ILathamOrderModel } from "@models";
import * as moment from "moment";
import { GlobalsService, LookupService } from "./services.module";

@Injectable()
export class LathamOrderValidationService {
	private lathamOrderModel: ILathamOrderModel;
	private errors: string[];
	hasLathamDistributors: boolean = false;

	constructor(private lookupService: LookupService) {
		this.hasLathamDistributors = (this.lookupService.getLathamDistributors().length > 0) || GlobalsService.company.isLathamDistributor || GlobalsService.company.lathamCustomerType === 'Distributor';

	}

	validate(lathamOrderModel: ILathamOrderModel): string[] {

		this.lathamOrderModel = lathamOrderModel;
		this.errors = [];

		this.customer();
		this.shipTo();
		this.shipDate();
		this.purchaseOrder();

		// If a MR, the rest doesn't matter
		if (this.lathamOrderModel.orderSpecs.system.orderType === "MR" || this.lathamOrderModel.orderSpecs.system.orderType === "Parts Order")
			return this.errors;

		this.trackDimensions();

		if (this.lathamOrderModel.orderSpecs.system.modelType === 'Eclipse' ||
			this.lathamOrderModel.orderSpecs.system.modelType === 'Atom' ||
			this.lathamOrderModel.orderSpecs.system.modelType === 'ATOM' ||
			this.lathamOrderModel.orderSpecs.system.modelType === 'CS3000' ||
			this.lathamOrderModel.orderSpecs.system.modelType === 'CS3000Q' ||
			this.lathamOrderModel.orderSpecs.system.modelType === 'CS3000D' ||
			this.lathamOrderModel.orderSpecs.system.modelType === 'PCS Infinity 4000' ||
			this.lathamOrderModel.orderSpecs.system.modelType === 'CS300' ||
			this.lathamOrderModel.orderSpecs.system.modelType === 'CS300HD2' ||
			this.lathamOrderModel.orderSpecs.system.modelType === 'CS300HD3' ||
			this.lathamOrderModel.orderSpecs.system.modelType === 'CS300FGP') {

			this.unitLocation();
			this.poolType();
			this.motorSide();
			this.motorType();
			this.hydroMotors();
			this.controlType();
			this.fabricType();
			this.fabricColor();
			this.webbingType();
			this.slack();
			this.rollerWrap();
			this.trackType();
			if (this.lathamOrderModel.orderSpecs.system.modelType !== 'PCS Infinity 4000')
				this.trackColor();
			this.lidType();
			this.leadingEdgeBar();
		}
		else if (this.lathamOrderModel.orderSpecs.system.modelType === 'CS500') {
			this.trackLocation();
			this.fabricType();
			this.fabricColor();
			this.slack();
			this.rollerWrap();
			this.webbingType();
			this.oem();
			this.sliderType();
		}
		else if (this.lathamOrderModel.orderSpecs.system.modelType === 'Eclipseb') {
			this.trackLocation();
			this.fabricType();
			this.fabricColor();
			this.slack();
			this.rollerWrap();
			this.webbingType();
			this.oem();
			this.sliderType();
		}
		else if (this.lathamOrderModel.orderSpecs.system.modelType === 'IN4000b') {
			this.trackLocation();
			this.fabricType();
			this.fabricColor();
			this.slack();
			this.rollerWrap();
			this.webbingType();
			this.oem();
			this.sliderType();
		}
		else if (this.lathamOrderModel.orderSpecs.system.modelType === 'CS3000b') {
			this.trackLocation();
			this.fabricType();
			this.fabricColor();
			this.slack();
			this.rollerWrap();
			this.webbingType();
			this.oem();
			this.sliderType();
		}
		else if (this.lathamOrderModel.orderSpecs.system.modelType === 'Replacement Fabric') {
			this.trackLocation();
			this.fabricType();
			this.fabricColor();
			this.slack();
			this.rollerWrap();
			this.webbingType();
			this.oem();
			this.sliderType();
		}

		return this.errors;
	}


    customer(altErrorMessage: string = null) {
        if ((!this.lathamOrderModel.orderSpecs.builder || !this.lathamOrderModel.orderSpecs.builder.customerId) &&
            (!this.lathamOrderModel.orderSpecs.homeOwner || !this.lathamOrderModel.orderSpecs.homeOwner.customerId) &&
            (!this.lathamOrderModel.orderSpecs.distributor || !this.lathamOrderModel.orderSpecs.distributor.customerId)) {
            if (this.hasLathamDistributors)
                this.addError(altErrorMessage || "A distributor, builder, or homeowner is required");
            else
                this.addError(altErrorMessage || "A builder or homeowner is required");
        }
    }

	billTo(altErrorMessage: string = null) {
		if (!this.lathamOrderModel.orderSpecs.billing.billTo)
			this.addError(altErrorMessage || "Bill To is required");
	}

	shipTo(altErrorMessage: string = null) {
		if (!this.lathamOrderModel.orderSpecs.shipping.shipTo)
			this.addError(altErrorMessage || "Ship To is required");
	}

	shipDate(altErrorMessage: string = null) {
		if (this.lathamOrderModel.orderSpecs.shipping.standardShipDay === false && moment.isDate(this.lathamOrderModel.orderSpecs.shipping.customShipDate) === false) 
			this.addError(altErrorMessage || "Ship To is required");
	}

	purchaseOrder(altErrorMessage: string = null) {
		if (!this.lathamOrderModel.invoice.purchaseOrderNumber || this.lathamOrderModel.invoice.purchaseOrderNumber === '') {
			this.addError("Purchase Order is required");
		}
	}

	trackDimensions() {
		// All systems need track space/length
		if (!this.lathamOrderModel.orderSpecs.system.trackSpaceFeet) {
			this.addError("Track Space Feet is required");
		}

		if (this.lathamOrderModel.orderSpecs.system.trackSpaceInches === null || this.lathamOrderModel.orderSpecs.system.trackSpaceInches === undefined) {
			this.addError("Track Space Inches is required");
		}

		if (!this.lathamOrderModel.orderSpecs.system.trackLengthFeet) {
			this.addError("Track Length Feet is required");
		}

		if (this.lathamOrderModel.orderSpecs.system.trackLengthInches === null || this.lathamOrderModel.orderSpecs.system.trackLengthInches === undefined) {
			this.addError("Track Length Inches is required");
		}
	}

	unitLocation(altErrorMessage: string = null) {
		if (!this.lathamOrderModel.orderSpecs.system.unitLocation)
			this.addError(altErrorMessage || "Unit Location is required");
	}

	poolType(altErrorMessage: string = null) {
		if (this.lathamOrderModel.orderSpecs.system.unitLocation === 'Recessed' && !this.lathamOrderModel.orderSpecs.system.poolType)
			this.addError(altErrorMessage || "Pool Type is required");
	}

	motorSide(altErrorMessage: string = null) {
		if (!this.lathamOrderModel.orderSpecs.system.motorSide)
			this.addError(altErrorMessage || "Motor Side is required");
	}

	motorType(altErrorMessage: string = null) {
		if (!this.lathamOrderModel.orderSpecs.system.motorType)
			this.addError(altErrorMessage || "Motor Type is required");
	}

	hydroMotors() {
		if (this.lathamOrderModel.orderSpecs.system.motorType === 'US Hydro (220v)' || this.lathamOrderModel.orderSpecs.system.motorType === 'European Hydro') {
			if (!this.lathamOrderModel.orderSpecs.system.hydroMotorSize)
				this.addError("Hydro Motor Size is required");

			if (this.lathamOrderModel.orderSpecs.system.hoseLength === null)
				this.addError("Hose Length is required");
		}
	}

	trackLocation(altErrorMessage: string = null) {
		if (!this.lathamOrderModel.orderSpecs.track.trackType)
			this.addError(altErrorMessage || "Track Location is required");
	}

	controlType(altErrorMessage: string = null) {
		if (!this.lathamOrderModel.orderSpecs.system.controlType)
			this.addError(altErrorMessage || "Control Type is required");
	}

	fabricType(altErrorMessage: string = null) {
		if (!this.lathamOrderModel.orderSpecs.cover.fabricType && !this.lathamOrderModel.orderSpecs.cover.removeFabric)
			this.addError(altErrorMessage || "Fabric Type is required");
	}

	fabricColor(altErrorMessage: string = null) {
		if (!this.lathamOrderModel.orderSpecs.cover.fabricColor && !this.lathamOrderModel.orderSpecs.cover.removeFabric)
			this.addError(altErrorMessage || "Fabric Color is required");
	}

	webbingType(altErrorMessage: string = null) {
		if (!this.lathamOrderModel.orderSpecs.cover.webbingType && !this.lathamOrderModel.orderSpecs.cover.removeFabric)
			this.addError(altErrorMessage || "Webbing Type is required");
	}

	oem(altErrorMessage: string = null) {
		if (!this.lathamOrderModel.orderSpecs.cover.oem && !this.lathamOrderModel.orderSpecs.cover.removeFabric)
			this.addError(altErrorMessage || "Cover OEM is required");
	}

	sliderType(altErrorMessage: string = null) {
		if (this.lathamOrderModel.orderSpecs.track.trackType === 'Undertrack' && !this.lathamOrderModel.orderSpecs.cover.removeFabric) {
			if (!this.lathamOrderModel.orderSpecs.cover.sliderType)
				this.addError(altErrorMessage || "Slider Type is required");
		}
	}

	slack(altErrorMessage: string = null) {
		if (!this.lathamOrderModel.orderSpecs.cover.slack && !this.lathamOrderModel.orderSpecs.cover.removeFabric)
			this.addError(altErrorMessage || "Slack is required");
	}

	rollerWrap(altErrorMessage: string = null) {
		if (!this.lathamOrderModel.orderSpecs.cover.removeFabric) {
			if (!this.lathamOrderModel.orderSpecs.cover.rollerWrap) {
				this.addError(altErrorMessage || "Roller Wrap is required");
				return;
			}

			if (this.lathamOrderModel.orderSpecs.cover.rollerWrap !== 2 && this.lathamOrderModel.orderSpecs.cover.rollerWrap !== 3 &&
				this.lathamOrderModel.orderSpecs.cover.rollerWrap !== 4 && this.lathamOrderModel.orderSpecs.cover.rollerWrap !== 5)
				this.addError(altErrorMessage || "Invalid Roller Wrap");
		}
	}

	trackType(altErrorMessage: string = null) {
		if (!this.lathamOrderModel.orderSpecs.track.trackType)
			this.addError(altErrorMessage || "Track Type is required");
	}

	trackColor(altErrorMessage: string = null) {
		if (!this.lathamOrderModel.orderSpecs.track.trackColor)
			this.addError(altErrorMessage || "Track Color is required");
	}

	lidType(altErrorMessage: string = null) {
		if (this.lathamOrderModel.orderSpecs.system.unitLocation === 'Deck')
			return;

		if (!this.lathamOrderModel.orderSpecs.lid.lidType)
			this.addError(altErrorMessage || "Lid is required");

		if (this.lathamOrderModel.orderSpecs.lid.lidType === 'Aluminum Lid w/Hinge') {
			// All systems need track space/length
			if (this.lathamOrderModel.orderSpecs.lid.motorEndLidFeet === null || this.lathamOrderModel.orderSpecs.lid.motorEndLidFeet === undefined) {
				this.addError("Motor End Lid Feet is required");
			}

			if (this.lathamOrderModel.orderSpecs.lid.motorEndLidInches === null || this.lathamOrderModel.orderSpecs.lid.motorEndLidInches === undefined) {
				this.addError("Motor End Inches is required");
			}

			if (this.lathamOrderModel.orderSpecs.lid.mainLidFeet === null || this.lathamOrderModel.orderSpecs.lid.mainLidFeet === undefined) {
				this.addError("Main Lid Feet is required");
			}

			if (this.lathamOrderModel.orderSpecs.lid.mainLidInches === null || this.lathamOrderModel.orderSpecs.lid.mainLidInches === undefined) {
				this.addError("Main Lid Inches is required");
			}

			if (this.lathamOrderModel.orderSpecs.lid.nonMotorEndLidFeet === null || this.lathamOrderModel.orderSpecs.lid.nonMotorEndLidFeet === undefined) {
				this.addError("Non-Motor End Lid Feet is required");
			}

			if (this.lathamOrderModel.orderSpecs.lid.nonMotorEndLidInches === null || this.lathamOrderModel.orderSpecs.lid.nonMotorEndLidInches === undefined) {
				this.addError("Non-Motor End Inches is required");
			}
		}
	}

	leadingEdgeBar() {
		if (this.lathamOrderModel.orderSpecs.track.trackType === 'Undertrack' && this.lathamOrderModel.orderSpecs.tubes.leadingEdge === '3 Inch Standard' && this.lathamOrderModel.orderSpecs.system.totalTrackSpace > 294)
			this.addError("Undertrack 3\" Leading Edge track space must be <= 24' 6\"");

		if (this.lathamOrderModel.orderSpecs.track.trackType !== 'Undertrack' && this.lathamOrderModel.orderSpecs.tubes.leadingEdge === '3 Inch Standard' && this.lathamOrderModel.orderSpecs.system.totalTrackSpace > 293)
			this.addError(this.lathamOrderModel.orderSpecs.track.trackType + " 3\" Leading Edge track space must be <= 24' 3\"");

		if (this.lathamOrderModel.orderSpecs.track.trackType === 'Undertrack' && this.lathamOrderModel.orderSpecs.tubes.leadingEdge === '2 1/2 Inch' && this.lathamOrderModel.orderSpecs.system.totalTrackSpace > 295)
			this.addError("Undertrack 2.5\" Leading Edge track space must be <= 24' 7\"");

		if (this.lathamOrderModel.orderSpecs.track.trackType !== 'Undertrack' && this.lathamOrderModel.orderSpecs.tubes.leadingEdge === '2 1/2 Inch' && this.lathamOrderModel.orderSpecs.system.totalTrackSpace > 295)
			this.addError(this.lathamOrderModel.orderSpecs.track.trackType + " 2.5\" Leading Edge track space must be <= 24' 4\"");

	}


	addError(msg) {
		this.errors.push(msg);
	}
}
