import { Injectable } from "@angular/core";
import { ITimeOffDetailModel, ITimeOffRequestModel, ITimeOffSummaryModel } from "@models";
import { HttpService } from "./utils/http.service";

@Injectable()
export class TimeOffService {
	constructor(private httpService: HttpService) { }

	async getTimeOffDetails(userId: number): Promise<ITimeOffDetailModel[]> {
		return await this.httpService.get("/timeOff/getTimeOffDetails", { userId: userId });
	}

	async getTimeOffDetail(timeOffDetailId: number): Promise<ITimeOffDetailModel> {
		return await this.httpService.get("/timeOff/getTimeOffReqest", { timeOffDetailId: timeOffDetailId });
	}

	async getTimeOffDetailByTimeclockEntryId(timeclockEntryId: number): Promise<ITimeOffDetailModel> {
		return await this.httpService.get("/timeOff/getTimeOffDetailByTimeclockEntryId", { timeclockEntryId: timeclockEntryId });
	}

	async addTimeOffDetail(timeOffDetailModel: ITimeOffDetailModel): Promise<ITimeOffDetailModel> {
		return await this.httpService.post(`/timeOff/addTimeOffDetail`, timeOffDetailModel);
	}

	async getTimeOffSummary(userId): Promise<ITimeOffSummaryModel> {
		return await this.httpService.get("/timeOff/getTimeOffSummary", { userId: userId });
	}

	async requestTimeOff(timeOffRequestModel: ITimeOffRequestModel): Promise<ITimeOffDetailModel> {
		return await this.httpService.post("/timeOff/requestTimeOff", timeOffRequestModel);
	}

	async approveTimeOff(timeOffDetailId: number): Promise<ITimeOffDetailModel> {
		return await this.httpService.patch(`/timeOff/approveTimeOff?timeOffDetailId=${timeOffDetailId}`);
	}

	async deleteTimeOffDetail(timeOffDetailId: number): Promise<void> {
		return await this.httpService.delete("/timeOff/deleteTimeOffDetail", { timeOffDetailId: timeOffDetailId });
	}
}
