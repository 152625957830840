import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { SlickValidationIndicatorModule } from "../slick-validation-indicator/slick-validation-indicator.module";
import { SlickDropDownComponent } from "./slick-drop-down.component";

@NgModule({
	imports: [CommonModule, FormsModule, HttpClientModule, SlickValidationIndicatorModule],
	declarations: [SlickDropDownComponent],
	exports: [SlickDropDownComponent],
	providers: [HttpClientModule]
})
export class SlickDropDownModule { }

export { SlickDropDownComponent }
export { SlickDropDownButtonModel, ISlickDropDownButtonModel } from "./slick-drop-down-button.model";