import { NgModule } from "@angular/core";
import { SlickCurrencyMaskDirective } from "./slick-currency-mask.directive";

@NgModule({
	imports: [],
	declarations: [SlickCurrencyMaskDirective],
	exports: [SlickCurrencyMaskDirective]
})
export class SlickCurrencyMaskModule { }
export { SlickCurrencyMaskDirective }
export { SlickCurrencyMaskOptions } from "./slick-currency-mask-options.model";
