import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { LineItemsComponent } from "./line-items.component";
import { SlickAutoCompleteModule, SlickCurrencyMaskModule, SlickDialogModule, SlickDropDownModule, SlickTabsModule } from "@slick-components";
import { ComponentsModule } from "@components";
import { FunctionLockService, LineItemsService } from "@services";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ComponentsModule,
		SlickAutoCompleteModule, SlickCurrencyMaskModule, SlickDialogModule, SlickDropDownModule, SlickTabsModule],
	declarations: [LineItemsComponent],
	exports: [LineItemsComponent],
	providers: [FunctionLockService, LineItemsService]
})
export class LineItemsModule { }
