export interface ISlickEmailerAddressModel {
	contactId: number;
	displayName: string;
	emailAddress: string;
}

export class SlickEmailerAddressModel implements ISlickEmailerAddressModel {
	contactId: number;
	displayName: string;
	emailAddress: string;
}
