import { Injectable } from "@angular/core";
import { promises } from "fs";
import { IDropdownModel, ITeamMemberModel, ITeamModel } from "../models";
import { GlobalsService } from "./services.module";
import { HttpService } from "./utils/http.service";

@Injectable()
export class TeamsService {
    constructor(private httpService: HttpService) { }

    async getTeams(): Promise<ITeamModel[]> {
        return this.httpService.get("/teams/getTeams");
    }

    async addTeam(teamModel: ITeamModel): Promise<ITeamModel> {
        return this.httpService.post("/teams/addTeam", teamModel);
    }

    async updateTeam(teamModel: ITeamModel): Promise<ITeamModel> {
        return this.httpService.post("/teams/updateTeam", teamModel);
    }

    async getTeamLeadMembers(userId: number): Promise<ITeamMemberModel[]> {
        return this.httpService.get("/teams/getTeamLeadMembers", {userId: userId});
    }

    async getMyTeamMembers(): Promise<ITeamMemberModel[]> {
        return this.httpService.get("/teams/getMyTeamMembers");
    }

}
