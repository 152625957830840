import { Component, Input, isDevMode, ViewChild } from "@angular/core";
import { SlickDialogComponent } from "@slick-components";
import { GlobalsService, TimeclockService } from "@services"
import { ITimeclockEntryModel } from "@models";
import * as moment from "moment";
import { TimeclockStore } from "../../stores";
import Swal from 'sweetalert2';

@Component({
	selector: "missed-clock-out-dialog",
	templateUrl: "./missed-clock-out-dialog.component.html",
	 providers: [TimeclockService]
})
export class MissedClockOutDialogComponent{
	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;
	@Input() isMobile: boolean = false;

	timeClockEntry: ITimeclockEntryModel;
	timeClockDate: string;
	isSubmitted: boolean = false;
	formatError: boolean = false;
	clockOutBeforeClockIn: boolean = false;
	constructor(private timeclockService: TimeclockService,
	private timeclockStore: TimeclockStore) {
		
	}

	async showDialog() {
		if (this.isMobile) {
			this.dialogRef.width = 360;
		}
		else {
			this.dialogRef.width = 700;
		}
		if (GlobalsService.lastTimeclockModel) {
			this.timeClockEntry = GlobalsService.lastTimeclockModel;
			this.timeClockDate = moment(this.timeClockEntry.clockIn).format('dddd MM/DD/yyyy')
		}
		this.dialogRef.showDialog();
		
	}

	async continue() {
		this.isSubmitted = true;
		this.formatError = false;
		this.clockOutBeforeClockIn = false;
		
		if (!this.timeClockEntry.clockOutHoursText)
			return;

		// Check to see if they are still clocked into this job
		const actualCurrentTimeclockEntry = await this.timeclockService.getLastTimeclockEntry(GlobalsService.userInfo.userId);

		// Not clocked in at all.  Show an error
		if (!actualCurrentTimeclockEntry) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops',
				text: 'You are not currently clocked in',
				confirmButtonColor: '#007bff',
				width: '28em',
				heightAuto: false
			});

			this.timeclockStore.refreshCurrentEntry(null);

			this.dialogRef.hideDialog();
			return;
		}

		const timeCheck = /^([0-9]|0[0-9]|1[0-2]):[0-5][0-9]\s?[a|A|P|p][M|m]$/.test(this.timeClockEntry.clockOutHoursText);
		if (!timeCheck) {
			this.formatError = true;
			return
		}

		const timeClockEntryDate = moment(this.timeClockEntry.clockIn).startOf('day').toDate();
		this.timeClockEntry.clockOut = this.timeclockService.combineToDate(timeClockEntryDate, this.timeClockEntry.clockOutHoursText);
		this.timeClockEntry.clockIn = this.timeclockService.combineToDate(timeClockEntryDate, this.timeClockEntry.clockInHoursText);
		
		if (moment(this.timeClockEntry.clockOut).isBefore(moment(this.timeClockEntry.clockIn))) {
			this.clockOutBeforeClockIn = true;
			return;
		}
		GlobalsService.lastTimeclockModel = await this.timeclockService.updateTimeclockEntry(this.timeClockEntry);
		this.timeclockStore.refreshCurrentEntry(null);

		this.dialogRef.hideDialog();
    }
	

	close() {
		this.dialogRef.hideDialog();
	}
}
