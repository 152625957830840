import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { SignalRFeatureTypes } from "@signalr"
import { INotificationModel, IQBWCTaskModel, IScheduledAppointmentModel, IBlockedTimeSlotModel, IQBWCTaskGridViewModel, IOfflineAppointmentUpdateModel } from "@models";
import { HttpService, GlobalsService, UtilsService } from "@services";

@Injectable()
export class SignalRMessageStore {
    private _jellyFishUnapprovedOrdersCount = 0;
    private _lathamOrderPendingCount = 0;
    private _lathamOrderSuspendedCount = 0;
    private _smartlistOverdueCount = 0;
    private _recurringBillingErrorCount = 0;
    private _skeditPayRecurringPaymentsErrorCount = 0;

    appointmentChanged: Subject<IOfflineAppointmentUpdateModel> = new Subject<IOfflineAppointmentUpdateModel>();
    appointmentDeleted: Subject<number> = new Subject<number>();
    appointmentUnscheduled: Subject<IScheduledAppointmentModel> = new Subject<IScheduledAppointmentModel>();
    blockedTimeSlotChanged: Subject<IBlockedTimeSlotModel> = new Subject<IBlockedTimeSlotModel>();
    blockedTimeSlotDeleted: Subject<number> = new Subject<number>();
    addNotification: Subject<INotificationModel> = new Subject<INotificationModel>();
    removeNotification: Subject<number> = new Subject<number>();
    jellyFishUnapprovedOrdersCount: BehaviorSubject<number> = new BehaviorSubject<number>(this._jellyFishUnapprovedOrdersCount);
    lathamOrderPendingCount: BehaviorSubject<number> = new BehaviorSubject<number>(this._lathamOrderPendingCount);
    lathamOrderSuspendedCount: BehaviorSubject<number> = new BehaviorSubject<number>(this._lathamOrderSuspendedCount);
    smartlistOverdueCount: BehaviorSubject<number> = new BehaviorSubject<number>(this._smartlistOverdueCount);
    recurringBillingErrorCount: BehaviorSubject<number> = new BehaviorSubject<number>(this._recurringBillingErrorCount);
    skeditPayRecurringPaymentsErrorCount: BehaviorSubject<number> = new BehaviorSubject<number>(this._skeditPayRecurringPaymentsErrorCount);
    quickbooksAddTask: Subject<IQBWCTaskGridViewModel> = new Subject<IQBWCTaskGridViewModel>();
    quickbooksUpdateTask: Subject<IQBWCTaskGridViewModel> = new Subject<IQBWCTaskGridViewModel>();
    quickbooksSuccess: Subject<IQBWCTaskGridViewModel> = new Subject<IQBWCTaskGridViewModel>();
    quickbooksError: Subject<IQBWCTaskGridViewModel> = new Subject<IQBWCTaskGridViewModel>();
    quickbooksClearNotification: Subject<IQBWCTaskGridViewModel> = new Subject<IQBWCTaskGridViewModel>();

    constructor(private httpService: HttpService) {
    }


    async init() {
        if (GlobalsService.company.useLathamOrdering) {
            this._lathamOrderPendingCount = GlobalsService.initialBadgeDisplayModel.lathamOrdersPendingCount;
            this.lathamOrderPendingCount.next(this._lathamOrderPendingCount);
            this._lathamOrderSuspendedCount = GlobalsService.initialBadgeDisplayModel.lathamOrdersSuspendedCount;
            this.lathamOrderSuspendedCount.next(this._lathamOrderSuspendedCount);
            this._smartlistOverdueCount = GlobalsService.initialBadgeDisplayModel.smartlistOverdueCount;
            this.smartlistOverdueCount.next(this._smartlistOverdueCount);
        }
        if (GlobalsService.company.companyId === 133) {
            this._jellyFishUnapprovedOrdersCount = GlobalsService.initialBadgeDisplayModel.jellyFishUnapprovedOrdersCount;
            this.jellyFishUnapprovedOrdersCount.next(this._jellyFishUnapprovedOrdersCount);
        }
        this._recurringBillingErrorCount = GlobalsService.initialBadgeDisplayModel.recurringBillingErrorCount;
        this.recurringBillingErrorCount.next(this._recurringBillingErrorCount);

        this._skeditPayRecurringPaymentsErrorCount = GlobalsService.initialBadgeDisplayModel.skeditPayRecurringPaymentsErrorCount;
        this.skeditPayRecurringPaymentsErrorCount.next(this._skeditPayRecurringPaymentsErrorCount);

    }

    onSendMessage(featureType: SignalRFeatureTypes, action: string, data: any) {
        switch (featureType) {
            case SignalRFeatureTypes.Appointments:
                this.processAppointment(action, data);
                break;

            case SignalRFeatureTypes.JellyFishOrders:
                this.processJellyFishOrders(action, data);
                break;

            case SignalRFeatureTypes.LathamOrders:
                this.processLathamOrders(action, data);
                break;

            case SignalRFeatureTypes.Notification:
                this.processNotification(action, data);
                break;

            case SignalRFeatureTypes.Quickbooks:
                this.processQuickbooks(action, data);
                break;
            case SignalRFeatureTypes.RecurringBilling:
                this.processRecurringBilling(action, data);
                break;
            case SignalRFeatureTypes.SkeditPayRecurringPayments:
                this.processSkeditPayRecurringPayments(action, data);
                break;
        }
    }

    private processAppointment(action: string, data: any) {
        switch (action) {
            case "APPOINTMENT_CHANGED":
                data.scheduledAppointmentModel.appointmentBlockStart = data.scheduledAppointmentModel.appointmentBlockStart + "Z";
                data.scheduledAppointmentModel.appointmentBlockEnd = data.scheduledAppointmentModel.appointmentBlockEnd + "Z";
                data.scheduledAppointmentModel.scheduledDateTime = data.scheduledAppointmentModel.scheduledDateTime + "Z";
                data = UtilsService.dateSanitize(data);
                this.appointmentChanged.next(<IOfflineAppointmentUpdateModel>data);
                break;

            case "APPOINTMENT_DELETED":
                this.appointmentDeleted.next(<number>data);
                break;

            case "APPOINTMENT_UNSCHEDULED":
                this.appointmentUnscheduled.next(<IScheduledAppointmentModel>data);
                break;

            case "BLOCKED_TIME_SLOT_CHANGED":
                data = UtilsService.dateSanitize(data);
                this.blockedTimeSlotChanged.next(<IBlockedTimeSlotModel>data);
                break;

            case "BLOCKED_TIME_SLOT_DELETED":
                this.blockedTimeSlotDeleted.next(<number>data);
                break;
        }
    }

    private processRecurringBilling(action: string, data: any) {
        switch (action) {
            case "ERROR_COUNT":
                this._recurringBillingErrorCount = <number>data;
                this.recurringBillingErrorCount.next(<number>data);
                break;
        }
    }

    private processSkeditPayRecurringPayments(action: string, data: any) {
        switch (action) {
            case "ERROR_COUNT":
                this._skeditPayRecurringPaymentsErrorCount = <number>data;
                this.skeditPayRecurringPaymentsErrorCount.next(<number>data);
                break;
        }
    }

    private processNotification(action: string, data: any) {
        switch (action) {
            case "ADD_NOTIFICATION":
                this.addNotification.next(<INotificationModel>data);
                break;

            case "REMOVE_NOTIFICATION":
                this.removeNotification.next(<number>data);
                break;
        }
    }

    private processJellyFishOrders(action: string, data: any) {
        switch (action) {
            case "JELLY_FISH_UNAPPROVED_ORDERS_COUNT":
                this._jellyFishUnapprovedOrdersCount = <number>data;
                this.jellyFishUnapprovedOrdersCount.next(<number>data);
                break;
        }
    }

    private processLathamOrders(action: string, data: any) {
        switch (action) {
            case "PENDING_COUNT":
                this._lathamOrderPendingCount = <number>data;
                this.lathamOrderPendingCount.next(<number>data);
                break;

            case "SUSPENDED_COUNT":
                this._lathamOrderSuspendedCount = <number>data;
                this.lathamOrderSuspendedCount.next(<number>data);
                break;
        }
    }

    private processQuickbooks(action: string, data: any) {
        switch (action) {
            case "QB_ADD_TASK":
                this.quickbooksAddTask.next(<IQBWCTaskGridViewModel>data);
                break;

            case "QB_UPDATE_TASK":
                this.quickbooksUpdateTask.next(<IQBWCTaskGridViewModel>data);
                break;

            case "QB_SUCCESS":
                this.quickbooksSuccess.next(<IQBWCTaskGridViewModel>data);
                break;

            case "QB_ERROR":
                this.quickbooksError.next(<IQBWCTaskGridViewModel>data);
                break;

            case "QB_CLEAR_NOTIFICATION":
                this.quickbooksError.next(<IQBWCTaskGridViewModel>data);
                break;

        }
    }
}