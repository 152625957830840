
export interface ITimeClockDailyNotesModel {
	timeClockDailyNoteId: number;
	userId: number;
	date: Date;
	notes: string;
	originalNote: string;
}

export class TimeClockDailyNotesModel implements ITimeClockDailyNotesModel {
	constructor(date: Date) {
		this.date = date;
    }
	timeClockDailyNoteId: number;
	userId: number;
	date: Date;
	notes: string;
	originalNote: string;
}

