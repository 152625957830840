import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SlickDialogModule } from "../slick-dialog/slick-dialog.module";
import { SlickAutoCompleteModule } from "../slick-auto-complete/slick-auto-complete.module";
import { SlickButtonSpinnerModule } from "../slick-button-spinner/slick-button-spinner.module";
import { SlickFileDropModule } from "../slick-file-drop/slick-file-drop.module";
import { SlickFileListModule } from "../slick-file-list/slick-file-list.module";
import { SlickEmailerComponent } from "./slick-emailer.component";
import { SlickEmailerDialogComponent } from "./slick-emailer-dialog.component";

@NgModule({
	imports: [CommonModule, FormsModule, SlickDialogModule, SlickAutoCompleteModule, SlickButtonSpinnerModule, SlickFileDropModule, SlickFileListModule],
	declarations: [SlickEmailerComponent, SlickEmailerDialogComponent],
	exports: [CommonModule, FormsModule, SlickEmailerComponent, SlickEmailerDialogComponent]
})
export class SlickEmailerModule { }
export { SlickEmailerComponent }
export { SlickEmailerDialogComponent }
export { ISlickEmailerModel, SlickEmailerModel } from "./slick-emailer.model";
export { ISlickEmailerAddressModel, SlickEmailerAddressModel } from "./slick-emailer-address.model";
