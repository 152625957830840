import { IPriceLevelGroupModel } from "../price-levels/price-level-group.model";
import { IPriceLevelItemModel } from "../price-levels/price-level-item.model";

export interface IPriceLevelModel {
	priceLevelId: number;
	name: string; 
	priceLevelType: string;
	defaultMarkup?: number;
	defaultMargin?: number;
	exchangeRate?: number;
	active: boolean;

	priceLevelItems: IPriceLevelItemModel[];
	priceLevelGroups: IPriceLevelGroupModel[];
}

export class PriceLevelModel implements IPriceLevelModel { 
	constructor() {
		this.priceLevelId = 0;
		this.priceLevelType = "Markup";
		this.active = true;
		this.priceLevelGroups = [];
	}

	priceLevelId: number;
	name: string;
	priceLevelType: string;
	defaultMarkup?: number;
	defaultMargin?: number;
	exchangeRate?: number;
	active: boolean; 

	priceLevelItems: IPriceLevelItemModel[];
	priceLevelGroups: IPriceLevelGroupModel[];
}