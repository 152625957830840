export interface ITubesModel {
    rollupTube: string;
    rollupTubeOverride: boolean;
    leadingEdge: string;
	leadingEdgeOverride: boolean;
	showTubes: boolean;
	showLeadingEdge: boolean;
	showRollupTube: boolean;
}

export class TubesModel implements ITubesModel
{
    rollupTube: string;
    rollupTubeOverride: boolean;
    leadingEdge: string;
	leadingEdgeOverride: boolean;
	showTubes: boolean;
	showLeadingEdge: boolean;
	showRollupTube: boolean;
}