import { Component, Input } from "@angular/core";
import { IInvoiceModel } from "@models";
import { GlobalsService } from "@services";

@Component({
	selector: "invoice-custom-fields",
	templateUrl: "./invoice-custom-fields.component.html"
})

export class InvoiceCustomFieldsComponent {
	@Input() invoice: IInvoiceModel;

	invoiceCustomField1Name = GlobalsService.company.invoiceCustomField1Name;
	invoiceCustomField2Name = GlobalsService.company.invoiceCustomField2Name;
	invoiceCustomField3Name = GlobalsService.company.invoiceCustomField3Name;
	invoiceCustomField4Name = GlobalsService.company.invoiceCustomField4Name;

	constructor() {
	}

}