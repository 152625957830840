import { Injectable } from "@angular/core";
import { ISkeditPayTransactionLogModel, IPaymentModel, ISkeditPayRecurringPaymentModel, iCalModel } from "@models";
import { HttpService } from "./utils/http.service";
import * as moment from "moment";

@Injectable()
export class SkeditPayService {
	constructor(private httpService: HttpService) { }

    async getPayment(paymentId: number): Promise<IPaymentModel> {
        return await this.httpService.get(`/skeditPay/getPayment?paymentId=${paymentId}`)
    }

    async getTransactionLogsForInfiniteScroll(pageNumber: number, searchString: string): Promise<ISkeditPayTransactionLogModel[]> {
        const params = {
            pageNumber,
            searchString
        }
        return await this.httpService.get("/skeditPay/getTransactionLogsForInfiniteScroll", params);
    }

    async getRecurringPaymentModel(recurringPaymentId: number): Promise<ISkeditPayRecurringPaymentModel> {
        return await this.httpService.get(`/skeditPay/getRecurringPaymentModel?recurringPaymentId=${recurringPaymentId}`)
    }

    async updateRecurringPayment(skeditPayRecurringPaymentModel: ISkeditPayRecurringPaymentModel): Promise<ISkeditPayRecurringPaymentModel> {
        return await this.httpService.post(`/skeditPay/updateRecurringPayment`, skeditPayRecurringPaymentModel)
    }

    async retryPayment(skeditPayRecurringPaymentHistoryId: number): Promise<ISkeditPayRecurringPaymentModel> {
        return await this.httpService.get(`/skeditPay/retryPayment?skeditPayRecurringBillingHistoryId=${skeditPayRecurringPaymentHistoryId}`);
    }

    async getNextPaymentDate(startDate: Date, endDate: Date, iCalModel: iCalModel): Promise<Date> {
        const sDate = moment(startDate).format("MM/DD/yyyy");
        let eDate = moment().format("MM/DD/yyyy");
        if (endDate != null)
            eDate = moment(endDate).format("MM/DD/yyyy");
        else
            eDate = moment().add(100, "year").format("MM/DD/yyyy");
        return await this.httpService.post(`/skeditPay/getNextPaymentDate?startDate=${sDate}&endDate=${eDate}`, iCalModel);
    }
}
