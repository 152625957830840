
export interface IJellyFishOrderSpecsModel {
	jellyFishOrderSpecsId: number;
	linearFeet: number;
	trackHousing: string;
	trackType: string;
	trackColor: string;
	clipType: string;
	clipPieces: number;
	controllerType: string;
	homeRunsOver50ft: number;
	jumpsOver20ft: number;

}

export class JellyFishOrderSpecsModel implements IJellyFishOrderSpecsModel {
	constructor() {
		this.linearFeet = 0;
		this.jumpsOver20ft = 0;
		this.homeRunsOver50ft = 0;
	}
	jellyFishOrderSpecsId: number;
	linearFeet: number;
	trackHousing: string;
	trackType: string;
	trackColor: string;
	clipType: string;
	clipPieces: number;
	controllerType: string;
	homeRunsOver50ft: number;
	jumpsOver20ft: number;
}
