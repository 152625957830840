import {Component, OnInit, OnDestroy, Output, EventEmitter, Input, ViewChild } from "@angular/core";
import { ChatStore } from "@stores";
import { SleepService, GlobalsService, ChatService, TeamsService, LookupService, UtilsService } from "@services";
import { ChatGroupModel, DropdownModel, IChatGroupModel, IDropdownModel, ITeamModel, NewGroupModel } from "@models";
import { Subscription } from "rxjs";
import { ChatGroupAttendantsComponent } from "../../components/chat-group-attendants/chat-group-attendants.component";


@Component({
    selector: "chat-group-list",
	templateUrl: "./chat-group-list.component.html",
	styleUrls: ['./chat-group-list.component.scss'],
	providers: [TeamsService]
})
export class ChatGroupListComponent implements OnInit, OnDestroy  {
	@Input() isLegacy: boolean;
	@Output() onGroupClicked: EventEmitter<IChatGroupModel> = new EventEmitter();	
	@ViewChild("chatGroupAttendantsRef") chatGroupAttendantsRef: ChatGroupAttendantsComponent;

	searchString: string;
	showDeletedGroups: boolean;
	visibleChatGroups: IChatGroupModel[];
	selectedChatGroup: IChatGroupModel;

	chatGroupsCreated$: Subscription;
	chatGroupsUpdated$: Subscription;
	refreshChatList$: Subscription;

	constructor(private chatStore: ChatStore,
		private chatService: ChatService,
		private teamsService: TeamsService,
private lookupService: LookupService	) {
	}

	async ngOnInit() {
		this.chatGroupsCreated$ = this.chatStore.chatGroupCreatedStore.subscribe(() => {
			this.filterUsers();
		});

		this.chatGroupsUpdated$ = this.chatStore.chatGroupUpdatedStore.subscribe(() => {
			this.filterUsers();
		});

		this.refreshChatList$ = this.chatStore.refreshChatListStore.subscribe(() => {
			this.filterUsers();
		});

		//this.showDeletedGroups = this.chatStore.showDeletedGroups;
		this.filterUsers();

	}

	ngOnDestroy() {
		this.chatGroupsCreated$.unsubscribe();
		this.chatGroupsUpdated$.unsubscribe();
		this.refreshChatList$.unsubscribe();
	}

	async filterUsers() {
		await SleepService.sleep();
		this.visibleChatGroups = [...this.chatStore.chatGroups];

		if (!this.showDeletedGroups)
			this.visibleChatGroups = this.visibleChatGroups.filter(x => !x.isDeleted);
		
		this.visibleChatGroups = this.visibleChatGroups.map(g => {
			g.lastSentMessageTimeString = this.chatService.calcLastSentMessageString(g.lastMessage);
			if (g.attendants.find(x => x.isAllUsers))
				g.isAllUsers = true;
			if (g.groupName) {
				g.attendantsString = g.groupName;
				g.longAttendantsString = g.groupName;
			}
			else {
                if (g.attendants.find(x => x.isAllUsers)) {
					g.attendantsString = "All Users";
					g.groupName = "All Users";
					g.attendants.forEach(x => x.profilePictureUrl = GlobalsService.company.logoUrl)
                }
				else {
					g.attendantsString = this.chatService.getAttendantsString(g);
					g.longAttendantsString = this.chatService.generateLongAttendantString(g);
                }
			}
			g.chatGroupAttendantsForList = g.attendants.filter(x => x.userId !== GlobalsService.userInfo.userId).slice(0, 3);
            return g;
        });

		if(this.searchString) {
			const searchTerms = this.searchString.split(" ");
			searchTerms.forEach(term => {
				this.visibleChatGroups = this.visibleChatGroups.filter(x => {
					const attendantExists = (x.attendantsString.toLowerCase().match(term.toLowerCase())) || (x.attendants.map(a => a.fullName).join('').toLowerCase().match(term.toLowerCase()));
					return (attendantExists)
				})
			})
		}
		
	}
	
	async openUserChat(chatGroup: IChatGroupModel) {
		this.selectedChatGroup = chatGroup;
		this.chatStore.setSelectedChatGroup(chatGroup);
		this.visibleChatGroups;
		this.onGroupClicked.emit(chatGroup);
	}

	async createNewChat() {
		const chatGroup = new ChatGroupModel();
		chatGroup.id = -1;
		chatGroup.uuid = UtilsService.newGuid();
		chatGroup.documentKey = UtilsService.newGuid();
		chatGroup.isExpanded = false;
		chatGroup.attendants = [];
		chatGroup.messages = [];
		this.selectedChatGroup = await this.chatGroupAttendantsRef.showDialog(chatGroup);
		this.selectedChatGroup.attendants.filter(x => x.userId !== GlobalsService.userInfo.userId);
		this.onGroupClicked.emit(this.selectedChatGroup);
		await this.chatStore.refreshChatListAfterEvent();
    }

	//async onShowDeletedGroups() {
	//	await SleepService.sleep();
	//	this.chatStore.toggleShowDeletedGroups();
	//	this.visibleChatGroups = [...this.chatStore.chatGroups];
	//	if (!this.showDeletedGroups)
	//		this.visibleChatGroups = this.visibleChatGroups.filter(x => !x.isDeleted);
	//	if (this.selectedChatGroup?.isDeleted)
	//		this.selectedChatGroup = null;
	//	this.filterUsers();
	//}

	
}
