import { Injectable } from '@angular/core';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { DevLogsStore } from '@stores';
import { SQLiteService } from '@sqlite';
import { GlobalsService, IInitModel } from '@services/utils/globals.service';
import { DevTraceService } from '@services/utils/dev-trace.service';
import { HttpService, UtilsService } from '@services';
import * as moment from "moment";

export enum OfflineRequestTypes { InTransit, AtJobsite, NotFinished, Complete, SaveNotes, SetPhotoAdded, ToggleCheckListQuestion }

export interface IOfflineRequestRecordModel {
	requestUuid: string,
	userId: number,
	requestDate: string,
	offlineUpdateRequestType: string,
	offlineRequestData: string
}

@Injectable()
export class OfflineRequestQueueStorageService {
	private db!: SQLiteDBConnection;

	readonly databaseName: string = 'offlineRequests';

	constructor(private devLogsStore: DevLogsStore,
		private devTrace: DevTraceService,
		private sqliteService: SQLiteService,
		private httpService: HttpService) {
	}

	async initializeDatabase(): Promise<void> {
		try {
			await this.sqliteService
				.addUpgradeStatement({
					database: this.databaseName,
					upgrade: this.offlineRequestsUpdates
				});

			// create and/or open the database
			const loadToVersion = this.offlineRequestsUpdates[this.offlineRequestsUpdates.length - 1].toVersion;
			this.db = await this.sqliteService.openDatabase(this.databaseName, loadToVersion);
			this.sqliteService.setVersion(this.databaseName, loadToVersion);
			this.devLogsStore.addMessage(`Init ${this.databaseName} database to version ${loadToVersion}`);
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.message}`);
		}

	}

	async deleteDatabase(): Promise<void> {
		await this.sqliteService.deleteDatabase(this.databaseName);
		this.devLogsStore.addMessage(`Deleted ${this.databaseName}`);
	}

	async addOfflineRequest(requestType: OfflineRequestTypes, offlineRequestData: any): Promise<void> {
		try {
			this.devLogsStore.addMessage(`Adding offlineRequests record`);

			const jsonEncoded = SQLiteService.encodeJSON(offlineRequestData);

			const sql = `
				INSERT INTO offlineRequests (requestUuid, userId, requestDate, offlineRequestType, offlineRequestData)
				VALUES ('${UtilsService.newGuid()}', ${GlobalsService.userInfo.userId}, '${moment().format("YYYYMMddhhmmss")}', '${requestType.toString()}', '${jsonEncoded}')`;
			await this.db.run(sql);
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}

	async getAllOfflineRequests(): Promise<IOfflineRequestRecordModel[]> {
		try {
			const offlineRequestRecords = (await this.db.query(`SELECT * FROM offlineRequests;`)).values as IOfflineRequestRecordModel[];

			// Loop through each record and decode the offlineRequestData field
			offlineRequestRecords.forEach(record => {
				record.offlineRequestData = SQLiteService.decodeJSON(record.offlineRequestData);
			});

			return offlineRequestRecords;
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}


	async clearAllOfflineRequests(): Promise<IOfflineRequestRecordModel[]> {
		try {
			const sql = `DELETE FROM offlineRequests`;
			await this.db.run(sql);

			return this.getAllOfflineRequests();
		}
		catch (err) {
			this.devTrace.addTrace(`${this.databaseName}: ${(err as Error)?.stack}`);
		}
	}
	async processOfflineRequest(offlineRequestRecord: IOfflineRequestRecordModel): Promise<boolean> {
		const result = await this.httpService.post("/offline/processOfflineRequest", offlineRequestRecord);

		if (result.status === 'Ok') {
			const sql = `DELETE FROM offlineRequests WHERE requestUuid='${offlineRequestRecord.requestUuid}'`;
			await this.db.run(sql);

			return true;
		}
	}

	readonly offlineRequestsUpdates = [
		{
			toVersion: 1,
			statements: [
				`
CREATE TABLE IF NOT EXISTS offlineRequests(
    requestUuid TEXT PRIMARY KEY,
	userId NUMBER,
	requestDate TEXT,
	offlineRequestType TEXT,
    offlineRequestData TEXT
);`
			]
		},
		/* add new statements below for next database version when required*/
		/*
		{
		toVersion: 2,
		statements: [
			`ALTER TABLE users ADD COLUMN email TEXT;`,
		]
		},
		*/
	]
}


