import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComponentsModule } from "@components"
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { SlickButtonSpinnerModule, SlickCurrencyMaskModule, SlickDialogModule, SlickValidationIndicatorModule, SlickAutoCompleteModule, SlickConfirmDialogModule, SlickDropDownModule, SlickGoogleAddressSearchModule } from "@slick-components";
import { CreditCardEditDialogComponent } from "./credit-card-edit-dialog.component";

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		FormsModule, ReactiveFormsModule,
		CreditCardDirectivesModule,
		SlickConfirmDialogModule,
		SlickDropDownModule,
		SlickButtonSpinnerModule, SlickCurrencyMaskModule, SlickDialogModule, SlickValidationIndicatorModule, SlickAutoCompleteModule, SlickGoogleAddressSearchModule
	],
	declarations: [
		CreditCardEditDialogComponent,
	],
	exports: [CreditCardEditDialogComponent]
})
export class CreditCardEditDialogModule {
}

export { CreditCardEditDialogComponent }