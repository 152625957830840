import { Component, Input, Output, EventEmitter, forwardRef, AfterViewInit, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { SlickSleepService } from "../utils/slick-sleep.service";
import { SlickUtilsService } from "../utils/slick-utils.service";
import { SlickFunctionLockService } from "../utils/slick-function-lock.service";
import { ISlickFileModel } from "../slick-file-model/slick-file.model";
import { ISlickEmailerModel, } from "./slick-emailer.model";
import { ISlickEmailerAddressModel, SlickEmailerAddressModel } from "./slick-emailer-address.model";

declare const tinymce: any;

@Component({
	selector: 'slick-emailer',
	templateUrl: 'slick-emailer.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SlickEmailerComponent),
			multi: true,
		},
		SlickFunctionLockService
	]
})
export class SlickEmailerComponent implements AfterViewInit, OnDestroy {
	@Input("toEmailAddressList") toEmailAddressList: ISlickEmailerAddressModel[];
	@Input("showButtons") showButtons: boolean = true;
	@Input("bodyHeight") bodyHeight: string = "180px";
	@Output("toAddressSearch") toAddressSearchEmitter: EventEmitter<string> = new EventEmitter();
	@Output("sendEmailClick") sendEmailClickEmitter: EventEmitter<ISlickEmailerModel> = new EventEmitter();
	@Output("cancelEmailClick") cancelEmailClickEmitter: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild("tinyMCEContainerRef") tinyMCEContainerRef: ElementRef;

	emailerModel: ISlickEmailerModel;
	isSubmitted: boolean = false;

	uuid: string;
	tinyMCEUuid: string;
	tinyMCEInitialized: boolean = false;
	toEmailAddress: string;
	editor: any;
	spinnerStatus: string = null;

	constructor(private slickFunctionLockService: SlickFunctionLockService, private changeDetector: ChangeDetectorRef) {
		this.uuid = SlickUtilsService.newGuid();

	}

	async ngAfterViewInit() {
		await this.initTinyMCE();
		const body = (this.emailerModel) ? (this.emailerModel.body || '') : '';
		tinymce.get(`emailer_tinymce_${this.tinyMCEUuid}`).setContent(body);
	}

	ngOnDestroy() {
		this.removeTinyMCE();
	}

	propagateChange = (_: any) => { };

	// this is the initial value set to the component
	public async writeValue(obj: ISlickEmailerModel) {
		if (obj) {
			this.spinnerStatus = "reset";
			this.emailerModel = obj;

			if (!this.emailerModel.attachments)
				this.emailerModel.attachments = [];

			if (!this.emailerModel.toEmailAddresses)
				this.emailerModel.toEmailAddresses = [];			
		}
		else {
			this.emailerModel = null;
		}

		await this.initTinyMCE();
		const body = (this.emailerModel) ? (this.emailerModel.body || '') : '';
		tinymce.get(`emailer_tinymce_${this.tinyMCEUuid}`).setContent(body);
	}

	// registers 'fn' that will be fired when changes are made
	// this is how we emit the changes back to the form
	public registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	// not used, used for touch input
	public registerOnTouched() { }

	toAddressSearch(searchText) {
		this.toAddressSearchEmitter.emit(searchText);
	}

	onSelectEmailAddress(emailerAddressModel: ISlickEmailerAddressModel) {
		this.emailerModel.toEmailAddresses.push(emailerAddressModel);

		this.toEmailAddress = null;
	}

	onFreeformTextSelect(text: string) {
		const freeformEmailAddress = new SlickEmailerAddressModel();
		freeformEmailAddress.displayName = text;
		freeformEmailAddress.emailAddress = text;

		this.emailerModel.toEmailAddresses.push(freeformEmailAddress);
		this.toEmailAddress = null;
	}

	deleteEmailAddress(idx: number) {
		this.emailerModel.toEmailAddresses.splice(idx, 1);
	}

	onAttachmentsChanged(attachments: ISlickFileModel[]) {
		this.emailerModel.attachments = this.emailerModel.attachments.concat(attachments);
	}

	validate() {
		this.isSubmitted = true;
		if (this.emailerModel.toEmailAddresses.length === 0)
			return false;

		return true;
	}

	getEmailAddresses(emailerModel: ISlickEmailerModel): ISlickEmailerAddressModel[] {
		let slickEmailerAddresses = emailerModel.toEmailAddresses || [];

		if (this.toEmailAddress) {
			const scrubbedToEmailAddress = this.toEmailAddress.replace(",", ";").replace(/\s/g, ";");
			const emailAddressesToAddStrings = scrubbedToEmailAddress.split(";").filter(x => x !== '');
			const emailAddressesToAdd = emailAddressesToAddStrings.map(x => {
				const slickEmailerAddressModel = new SlickEmailerAddressModel();
				slickEmailerAddressModel.contactId = 0;
				slickEmailerAddressModel.displayName = x;
				slickEmailerAddressModel.emailAddress = x;
				return slickEmailerAddressModel;
			});
			slickEmailerAddresses = slickEmailerAddresses.concat(emailAddressesToAdd);
		}

		this.toEmailAddress = null;

		return slickEmailerAddresses;
	}

	async setBody(body: string) {
		try {
			await this.slickFunctionLockService.lock("SLICK_EMAILER_SET_BODY");

			await this.initTinyMCE();
			const tinyMCE = tinymce.get(`emailer_tinymce_${this.tinyMCEUuid}`);

			if (!tinyMCE)
				console.error("tinyMCE not initialized");

			tinyMCE.setContent(body);

		}
		finally {
			this.slickFunctionLockService.release("SLICK_EMAILER_SET_BODY");
		}
	}

	getBody() {
		return tinymce.get(`emailer_tinymce_${this.tinyMCEUuid}`).getContent();
	}

	onSendEmail() {
		this.emailerModel.toEmailAddresses = this.getEmailAddresses(this.emailerModel);

		if (this.validate() === false)
			return;

		this.emailerModel.body = this.getBody();
		this.spinnerStatus = "spin";
		if (this.sendEmailClickEmitter)
			this.sendEmailClickEmitter.emit(this.emailerModel);
	}

	emailSent() {
		this.spinnerStatus = "ok";
	}

	emailError() {
		this.spinnerStatus = "error";
	}

	onCancelEmail() {
		if (this.cancelEmailClickEmitter)
			this.cancelEmailClickEmitter.emit();
	}

	async dialogInit() {
		this.removeTinyMCE();

		await this.initTinyMCE();
		const body = (this.emailerModel) ? (this.emailerModel.body || '') : '';
		tinymce.get(`emailer_tinymce_${this.tinyMCEUuid}`).setContent(body);
	}

	async initTinyMCE(): Promise<void> {

		return new Promise<void>(async (resolve) => {
			await this.slickFunctionLockService.lock("SLICK_EMAILER_INIT_TINY_MCE");

			if (this.tinyMCEUuid) {
				this.slickFunctionLockService.release("SLICK_EMAILER_INIT_TINY_MCE");
				resolve();
			}
			else {
				this.tinyMCEUuid = SlickUtilsService.newGuid();

				const tinyMCEContainer = <HTMLDivElement>this.tinyMCEContainerRef.nativeElement;
				const tinyMCETextarea = document.createElement("textarea");
				tinyMCETextarea.id = `emailer_tinymce_${this.tinyMCEUuid}`;
				tinyMCETextarea.style.minHeight = "300px";
				tinyMCETextarea.style.maxHeight = "300px";
				tinyMCEContainer.appendChild(tinyMCETextarea);

				tinymce.init({
					selector: `#emailer_tinymce_${this.tinyMCEUuid}`,
					branding: false,
					menubar: 'edit insert format tools',
					plugins: 'link image imagetools code',
					toolbar: 'fontselect | fontsizeselect | styleselect | bold italic underline | indent outdent | bullist | alignleft aligncenter alignright | code | image',
					fontsize_formats: "8px 10px 12px 14px 18px 24px 36px",
					height: this.bodyHeight,
					convert_urls: false,
					setup: (ed) => {
						ed.on('init', (args) => {

							ed.setContent((this.emailerModel) ? (this.emailerModel.body || '') : '');
							this.tinyMCEInitialized = true;
							this.slickFunctionLockService.release("SLICK_EMAILER_INIT_TINY_MCE");
							resolve();
						});
					}
				})
			}
		});
	}

	async removeTinyMCE() {
		if (this.tinyMCEUuid) {
			const tinyMCE = tinymce.get(`emailer_tinymce_${this.tinyMCEUuid}`);

			if (tinyMCE)
				tinyMCE.remove();

			const tinyMCEContainer = <HTMLDivElement>this.tinyMCEContainerRef.nativeElement;
			tinyMCEContainer.innerHTML = "";

			this.tinyMCEInitialized = false;

			this.tinyMCEUuid = null;
		}
	}
}
