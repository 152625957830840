import { UtilsService } from "@services";

export interface IPriceLevelGroupModel {
	priceLevelGroupId: number;
	uuid: string;
	groupName: string;
	defaultMarkup?: number;
	defaultMargin?: number;

}

export class PriceLevelGroupModel implements IPriceLevelGroupModel { 
	constructor() {
		this.uuid = UtilsService.newGuid();
		this.groupName = "New Group";
	}

	priceLevelGroupId: number;
	uuid: string;
	groupName: string;
	defaultMarkup?: number;
	defaultMargin?: number;
}