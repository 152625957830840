import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ComponentsModule } from "@components";
import { TextFieldModule } from "@angular/cdk/text-field";
import { SlickButtonSpinnerModule, SlickContainerModule, SlickDialogModule, SlickDropDownModule, SlickFileDropModule, SlickPhotoGalleryModule } from "@slick-components";

import { TextChatMessagesComponent } from "./text-chat-messages.component";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ComponentsModule,
		TextFieldModule,
		SlickButtonSpinnerModule, SlickContainerModule, SlickDialogModule, SlickDropDownModule, SlickFileDropModule, SlickPhotoGalleryModule
	],
	declarations: [TextChatMessagesComponent],
	exports: [TextChatMessagesComponent]
})
export class TextChatMessagesModule { }


