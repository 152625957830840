import { Injectable } from "@angular/core";
import { ILathamOrderModel, ILathamOrderFavoriteModel, ISavedShippingAddressModel, LineItemTypes } from "@models";
import { Subject } from "rxjs";
import { HttpService } from "./utils/http.service";
import { LathamOrderHelpersService } from "./latham-order-helpers.service";
import { LathamOrderSystemLineItemsService } from "./latham-order-system-line-items.service";
import { LathamOrdersService } from "./latham-orders.service";
import { FunctionLockService } from "./utils/function-lock.service";

@Injectable()
export class LathamOrderStore {
	private static _lathamOrder: ILathamOrderModel;
	private static _favorites: ILathamOrderFavoriteModel[];
	private static _savedShippingAddresses: ISavedShippingAddressModel[];

	constructor(private httpService: HttpService,
		private lathamOrdersService: LathamOrdersService,
		private lathamOrderHelpersService: LathamOrderHelpersService,
		private lathamOrderSystemLineItemsService: LathamOrderSystemLineItemsService,
		private functionLockService: FunctionLockService) { }

	//*****************************************************************************
	//* Latham Order Model
	//*****************************************************************************
	lathamOrderSubject: Subject<ILathamOrderModel> = new Subject<ILathamOrderModel>();
	get lathamOrder(): ILathamOrderModel {
		return LathamOrderStore._lathamOrder;
	}

	async initLathamOrder(lathamOrderModel: ILathamOrderModel) {
		await this.functionLockService.lock("INIT_LATHAM_ORDER");

		try {
			if (!lathamOrderModel) {
				LathamOrderStore._lathamOrder = null;
				return;
			}

			clearTimeout(LathamOrderStore._subscriptionPassTimeout);
			clearTimeout(LathamOrderStore._refreshLineItemsTimeout);
			LathamOrderStore._emittedHistory = [];

			const currentInvoiceCustomerId = (lathamOrderModel.invoice.customer) ? lathamOrderModel.invoice.customer.customerId : null;
			LathamOrderStore._lastInvoiceCustomerId = currentInvoiceCustomerId;

			LathamOrderStore._lathamOrder = lathamOrderModel;
			LathamOrderStore._lathamOrder = this.lathamOrderHelpersService.setLathamOrderModelHelperValues(LathamOrderStore._lathamOrder);

			// Update line items, but keep the original pricing
			LathamOrderStore._lathamOrder.invoice.pricingChanged = false;
			// Save the original line items because the recalc is going to bring in new pricing and we don't want that
			const savedLineItems = [...LathamOrderStore._lathamOrder.invoice.lineItems];
			LathamOrderStore._lathamOrder.invoice.lineItems = await this.lathamOrderSystemLineItemsService.calculateSystemLineItems(LathamOrderStore._lathamOrder, currentInvoiceCustomerId, true);
			// Go through each item and see if there's a matching item that we saved.  If so, revert the price and cost back to that price/cost
			LathamOrderStore._lathamOrder.invoice.lineItems.forEach(li => {
				const existingLineItem = savedLineItems.find(x => x.sku === li.sku);

				if (existingLineItem && existingLineItem.price !== 0 && (existingLineItem.priceModified ?? false) === false && existingLineItem.price !== li.price) {
					li.cost = existingLineItem.cost;
					li.price = existingLineItem.price;
					LathamOrderStore._lathamOrder.invoice.pricingChanged = true;
				}
			})

			this.lathamOrderSubject.next(LathamOrderStore._lathamOrder);

			// If we're processing from the favorites, some special processing needs to happen.
			// Reset this flag
			LathamOrderStore._lathamOrder.processingFavorite = false;
		}
		finally {
			this.functionLockService.release("INIT_LATHAM_ORDER");
		}
	}

	clearLathamOrder() {
		LathamOrderStore._lathamOrder = null;
	}

	private static _emittedHistory: string[] = [];
	private static _lastInvoiceCustomerId: number;
	private static _subscriptionPass: number = 0;
	private static _subscriptionPassTimeout: NodeJS.Timer;
	private static _refreshLineItemsTimeout: NodeJS.Timer;
	async emitLathamOrder(emittedBy: string, maxAllowed: number = 10) {
		if (!LathamOrderStore._lathamOrder)
			return;

		// Safeguard to make sure we don't go into a crazy emit infintie loop
		LathamOrderStore._emittedHistory.unshift(emittedBy);
		// Only allow this emittedBy to execute maxAllowed # of times.  Any more than this and we're in an infinite loop
		if (LathamOrderStore._emittedHistory.filter(x => x === emittedBy).length > (maxAllowed - 1)) {
			console.error(`Order: ${LathamOrderStore._lathamOrder.orderId} | Emit cancelled for ${emittedBy}`);
			return;
		}

		const currentInvoiceCustomerId = (LathamOrderStore._lathamOrder.invoice.customer) ? LathamOrderStore._lathamOrder.invoice.customer.customerId : null;

		if (emittedBy === "bill-to:refreshPricing")
			LathamOrderStore._lastInvoiceCustomerId = 0;

		LathamOrderStore._lathamOrder = this.lathamOrderHelpersService.setLathamOrderModelHelperValues(LathamOrderStore._lathamOrder);

		// This is here because we were calling the subscription WAY too many times
		// This will limit the # of calls to the subscriptions
		clearTimeout(LathamOrderStore._subscriptionPassTimeout);
		LathamOrderStore._subscriptionPassTimeout = setTimeout(() => {
			LathamOrderStore._subscriptionPass++;

			if (LathamOrderStore._subscriptionPass > 10) {
				clearTimeout(LathamOrderStore._refreshLineItemsTimeout);
				clearTimeout(LathamOrderStore._subscriptionPassTimeout);
				console.error(`Order: ${LathamOrderStore._lathamOrder.orderId} | Too many Latham store passes`);
				return;
			}

			this.lathamOrderSubject.next(LathamOrderStore._lathamOrder);
		}, 100);

		// Wait for .5 seconds before we actually do a refresh of the line items because we need to
		// make sure that al the sub-components have had a chance to do their thing
		if (LathamOrderStore._refreshLineItemsTimeout) {
			clearTimeout(LathamOrderStore._refreshLineItemsTimeout);
		}

		LathamOrderStore._refreshLineItemsTimeout = setTimeout(async () => {
			LathamOrderStore._subscriptionPass = 0;
			LathamOrderStore._refreshLineItemsTimeout = null;
			LathamOrderStore._emittedHistory = [];

			if (LathamOrderStore._lathamOrder) {
				const forceRefresh = (currentInvoiceCustomerId !== LathamOrderStore._lastInvoiceCustomerId);
				
				let refreshedLineItems = await this.lathamOrderSystemLineItemsService.calculateSystemLineItems(LathamOrderStore._lathamOrder, currentInvoiceCustomerId, forceRefresh);
				if (forceRefresh)
					refreshedLineItems = await this.lathamOrdersService.refreshPricing(refreshedLineItems, currentInvoiceCustomerId);
				LathamOrderStore._lathamOrder.invoice.lineItems = [...refreshedLineItems];
				LathamOrderStore._lathamOrder.invoice.pricingChanged = false;
				LathamOrderStore._lastInvoiceCustomerId = currentInvoiceCustomerId;
			}
		}, 500);
	}

	//*****************************************************************************
	//* Submit
	//*****************************************************************************
	submitSubject: Subject<boolean> = new Subject<boolean>();
	submit() {
		this.submitSubject.next(true);
	}

	clearSubmit() {
		this.submitSubject.next(false);
	}

	//*****************************************************************************
	//* Favorites
	//*****************************************************************************
	async getFavorites(forceRefresh = false): Promise<ILathamOrderFavoriteModel[]> {
		return new Promise<ILathamOrderFavoriteModel[]>(async (resolve) => {
			if (!LathamOrderStore._favorites || forceRefresh === true)
				LathamOrderStore._favorites = await this.httpService.get("/lathamOrders/getLathamOrderFavorites");

			resolve(LathamOrderStore._favorites);
		});
	}

	//*****************************************************************************
	//* Custom Shipping Addresses
	//*****************************************************************************
	getSavedShippingAddress(savedShippingAddressId: number): ISavedShippingAddressModel {
		return LathamOrderStore._savedShippingAddresses.find(ad => ad.addressId === savedShippingAddressId);
	}

	getSavedShippingAddressesForUnassigned(): ISavedShippingAddressModel[] {
		return LathamOrderStore._savedShippingAddresses.filter(ad => ad.distributorId === null && ad.builderId === null);
	}

	getSavedShippingAddressesForDistributor(distributorId?: number): ISavedShippingAddressModel[] {
		return LathamOrderStore._savedShippingAddresses.filter(ad => ad.distributorId === distributorId);
	}

	getSavedShippingAddressesForBuilder(builderId?: number): ISavedShippingAddressModel[] {
		return LathamOrderStore._savedShippingAddresses.filter(ad => ad.builderId === builderId);
	}

	async refreshSavedShippingAddresses(): Promise<void> {
		LathamOrderStore._savedShippingAddresses = await this.httpService.get("/lathamOrders/getSavedAddresses");
	}


}