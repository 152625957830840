import { UtilsService } from "@services";
import { IDropdownModel } from "../dropdown/dropdown.model";

export interface IInventoryLineItemModel {
	uuid: string;
	itemId?: number;
	inventoryWarehouseId: number;
	inventoryWarehouseName: string;
	inventoryItemLocationId?: number;
	inventoryItemLocationName: string;
	sendToInventoryItemLocationId?: string;
	sendToInventoryItemLocationName: string;
	inventoryLocationId: number;
	inventoryAdjustmentId?: number;
	imageUrl: string;
	imageThumbnailUrl: string;
	sku: string;
	description: string;
	quantity: number;
	locationQuantityOnHand: number;
	cost: number;
	editable: boolean
	displayOrder?: number;
	isBlankLineItem: boolean;
	isAdded: boolean;
	isModified: boolean;
	isDeleted: boolean;

	sendToLocationId: number;
	checkedOnCheckList: boolean;
	sendToManufacturer: boolean;

	inventoryItemLocations: IDropdownModel[];
	showAllItemLocations: boolean;

	shippingInventoryItemLocations: IDropdownModel[];
}

export class InventoryLineItemModel implements IInventoryLineItemModel {
	constructor() {
		this.uuid = UtilsService.newGuid();

		this.isModified = false;
		this.inventoryItemLocationId = null;		
	}

	uuid: string;
	itemId?: number;
	inventoryWarehouseId: number;
	inventoryWarehouseName: string;
	inventoryItemLocationId?: number;
	sendToInventoryItemLocationId?: string;
	sendToInventoryItemLocationName: string;
	inventoryItemLocationName: string;
	inventoryLocationId: number;
	inventoryAdjustmentId?: number;
	imageUrl: string;
	imageThumbnailUrl: string;
	sku: string;
	description: string;
	quantity: number;
	locationQuantityOnHand: number;
	cost: number;
	editable: boolean
	displayOrder?: number;
	isBlankLineItem: boolean;
	isAdded: boolean;
	isModified: boolean;
	isDeleted: boolean;
	sendToLocationId: number;
	checkedOnCheckList: boolean;
	sendToManufacturer: boolean;

	inventoryItemLocations: IDropdownModel[];
	showAllItemLocations: boolean;
	shippingInventoryItemLocations: IDropdownModel[];

}