import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { InventoryLineItemsComponent } from "./inventory-line-items.component";
import { SlickAutoCompleteModule, SlickCurrencyMaskModule, SlickDialogModule, SlickDropDownModule } from "@slick-components";
import { ComponentsModule } from "@components";
import { FunctionLockService, LineItemsService } from "@services";
import { InventoryLocationsSearchModule } from "@shared-components/inventory-locations-search";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ComponentsModule,
		InventoryLocationsSearchModule,
		SlickAutoCompleteModule, SlickCurrencyMaskModule, SlickDialogModule, SlickDropDownModule],
	declarations: [InventoryLineItemsComponent],
	exports: [InventoryLineItemsComponent],
	providers: [FunctionLockService, LineItemsService]
})
export class InventoryLineItemsModule {}
