﻿import { Component ,Input,ViewChild, OnInit, OnChanges, SimpleChanges,} from "@angular/core";
import { GlobalsService , CustomersService, LookupService, CustomerOutreachService } from "@services";
import { ICustomerModel , CustomerOutreachModel, IDropdownModel, DropdownModel } from "@models";
import {SlickConfirmDialogComponent, SlickConfirmDialogResults, SlickToastService} from "@slick-components";
import * as moment from 'moment';
import Swal from "sweetalert2";
@Component({
	selector: "crm-edit",
	templateUrl: "crm-edit.component.html",
	providers:[SlickToastService, CustomerOutreachService]
})

export class CrmEditComponent  implements OnInit {
	@ViewChild("removeCustomerOutReach",{ static: true}) removeCustomerOutReach: SlickConfirmDialogComponent;
	@Input() customerModel: ICustomerModel;

	dateAllowed: boolean = true;

	constructor(private slickToastService: SlickToastService,
		private customerOutreachService: CustomerOutreachService,
		private lookupService: LookupService,
		private customersService: CustomersService) {
	}

	async removeOutreach(i) {
		Swal.fire({
			title: 'Are you sure you want to delete this outreach?',
			showDenyButton: false,
			showCancelButton: true,
			confirmButtonText: 'Delete',
			customClass: {
				confirmButton: 'btn btn-outline-danger',
				cancelButton:'btn btn-outline-secondary',
			},
			heightAuto: false
		  }).then(async (result) => {
			if (result.isConfirmed) {
				var crmModel = this.customerModel.customerOutreaches[i];
				if (crmModel.customerOutreachId !== 0) {
					await this.customerOutreachService.deleteCustomerOutreach(crmModel);
				}
				this.customerModel.customerOutreaches.splice(i, 1);

			}
		  })
	}

	async ngOnInit() {
		this.setOutreachDates()
	}
	
	addOutreach(outreachType: string) {
		const outreachModel = new CustomerOutreachModel();
		outreachModel.userFullName = GlobalsService.userInfo.fullName;
		outreachModel.outreachType = outreachType;
		outreachModel.outreachDate = new Date();
		this.customerModel.customerOutreaches.unshift(outreachModel)
		this.setOutreachDates()
	}

	changeNextOutreachDate(){
		if(this.customerModel.lastOutreachDate && this.customerModel.daysBetweenOutreach > 0){
			this.customerModel.nextOutreachDate = moment(this.customerModel.lastOutreachDate).add(Number(this.customerModel.daysBetweenOutreach), "days").toDate();
			return this.customerModel.nextOutreachDate;
		}
		if(this.customerModel.lastOutreachDate > this.customerModel.nextOutreachDate ){
			this.customerModel.nextOutreachDate = null;
			return this.customerModel.nextOutreachDate;
		}
		else{
			return this.customerModel.nextOutreachDate;
		}
	}

	changeOutreachDays(){
		let lastOutreachDateMoment = moment(new Date(this.customerModel.lastOutreachDate).toDateString());
		let nextOutreachDateMoment = moment(this.customerModel.nextOutreachDate);
		let today = moment();

		if(nextOutreachDateMoment.isBefore(today)){
			this.dateAllowed = false;
			this.changeNextOutreachDate();
		}
		
		else{
			this.dateAllowed = true;
		}
		
		// else if(lastOutreachDateMoment.isSame(nextOutreachDateMoment)){
		// 	this.daysBetweenOutreach = 0;
		// }
		// else{
		// 	this.daysBetweenOutreach = nextOutreachDateMoment.diff(lastOutreachDateMoment, 'days')
		// }
	}

	getLastoutreach(){
		if(this.customerModel && this.customerModel.customerOutreaches[0] && this.customerModel.customerOutreaches[0].outreachDate){
			return new Date(this.customerModel.customerOutreaches[0].outreachDate)
		}else{
			return '';
		}
	}

	setOutreachDates() {
		if (this.customerModel.customerOutreaches && this.customerModel.customerOutreaches.length > 0) {
			this.customerModel.lastOutreachDate = this.customerModel.customerOutreaches[0].outreachDate;
			this.customerModel.nextOutreachDate = this.changeNextOutreachDate()
		}
	}
}