export interface ICoverModel {
    removeFabric: boolean;
    fabricType: string;
    fabricColor: string;
    webbingType: string;
    cornerType: string;
    ropeType: string;
    oem: string;
    slack: number;
    slackOverride: boolean;
    rollerWrap: number;
    leadingEdgeAttachment: string;
    leadingEdgeDiameter: number;
    sliderType: string;
    ropeAttachment: string;
    sliderAttachment: string;
    bondWire: boolean;
    bondWireOverride: boolean;
    printedCover: boolean;
}

export class CoverModel implements ICoverModel
{
    removeFabric: boolean;
    fabricType: string;
    fabricColor: string;
    webbingType: string;
    cornerType: string;
    ropeType: string;
    oem: string;
    slack: number;
    slackOverride: boolean;
    rollerWrap: number;
    leadingEdgeAttachment: string;
    leadingEdgeDiameter: number;
    sliderType: string;
    ropeAttachment: string;
    sliderAttachment: string;
    bondWire: boolean;
    bondWireOverride: boolean;
    printedCover: boolean;
}