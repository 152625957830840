import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { IInvoiceModel, IShippingAddressModel, ShippingAddressModel } from "@models";
import { SlickDialogComponent } from "@slick-components";
import { GlobalsService } from "@services";

@Component({
	selector: "shipping-address-dialog",
	template: `
<slick-dialog header="Edit Shipping Address" #shippingAddressEditDialogRef>
    <address-search [(shippingAddress)]="shippingAddress"></address-search>
    <slick-dialog-footer>
        <button (click)="saveShippingAddress()" style="width:140px" class="btn btn-outline-success me-2"><i class="bi bi-check-circle"></i> Save</button>
        <button (click)="cancelShippingAddress()" style="width:140px" class="btn btn-outline-danger"><i class="bi bi-x-circle"></i> Cancel</button>
    </slick-dialog-footer>
</slick-dialog>`
})

export class ShippingAddressDialogComponent {
	@ViewChild("shippingAddressEditDialogRef") shippingAddressEditDialogRef: SlickDialogComponent;
	@Input() isMobile: boolean;
	shippingAddress: IShippingAddressModel;
	res: any;

	constructor() { }

	async openDialog(shippingAddress: IShippingAddressModel): Promise<IShippingAddressModel> {
		return new Promise<IShippingAddressModel>((res) => {
			if (this.isMobile)
				this.shippingAddressEditDialogRef.width = 360;
			this.shippingAddress = shippingAddress;
			this.res = res;

			this.shippingAddressEditDialogRef.showDialog();
		})
	}

	saveShippingAddress() {
		this.shippingAddressEditDialogRef.hideDialog();
		this.res(this.shippingAddress);
	}

	cancelShippingAddress() {
		this.shippingAddressEditDialogRef.hideDialog();
		this.res(null);
	}

}