import { Component, HostBinding } from "@angular/core";

@Component({
	selector: 'slick-dialog-footer',
	template: `<ng-content></ng-content>`
})
export class SlickDialogFooterComponent {
	@HostBinding('style.flex') flex = '1 1';
	@HostBinding('style.textAlign') textAlign = 'center';
}
