import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { GlobalsService } from "@services";

@Component({
    selector: "quickbooks-icon",
	templateUrl: "quickbooks-icon.component.html",
	styleUrls: ["quickbooks-icon.component.css"]
})

export class QuickbooksIconComponent implements OnChanges {
	@Input() mini: boolean = false;
	@Input() lockSynced: boolean
	@Input() quickbooksSyncStatus: string;
	@Input() syncToQuickbooks: boolean;
	@Input() linkedCustomer: boolean;
	@Input() quickbooksId: string;
	@Input() quickbooksDocNumber: string;
	@Input() modifyDocNumber: boolean;

	@Output() onClick: EventEmitter<boolean> = new EventEmitter();

	quickbooksEnabled: boolean = GlobalsService.company.quickbooksOnlineEnabled || GlobalsService.company.quickbooksWebConnectorEnabled;
	titleString: string;

	ngOnChanges(changes: SimpleChanges) {
		if (changes.mini)
			this.mini = (this.mini.toString().toLowerCase() !== 'true') ? false : true;

		if (changes.lockSynced)
			this.lockSynced = (this.lockSynced.toString().toLowerCase() !== 'true') ? false : true;

		this.modifyDocNumber = ((this.modifyDocNumber || false).toString().toLowerCase() === 'true') ? true : false;

		if (changes.syncToQuickbooks) {
			if (this.syncToQuickbooks === null || this.syncToQuickbooks === undefined)
				this.syncToQuickbooks = false;
			else
				this.syncToQuickbooks = (this.syncToQuickbooks.toString().toLowerCase() !== 'true') ? false : true;
		}

		if (changes.quickbooksDocNumber) {
			this.titleString = null;
			if (this.quickbooksDocNumber)
				this.titleString = `Invoice Doc Number: ${this.quickbooksDocNumber}`;
		}
	}

	unSync(e: MouseEvent) {
		// These are here because the grid will go to the row and we just want the icon clicked
		e.preventDefault();
		// Special code for canada to change the doc number
		if (this.modifyDocNumber && GlobalsService.company.quickbooksOnlineEnabled === true) {
			this.onClick.emit(true);
			return;
		}

		if (this.lockSynced)
			return;

		this.onClick.emit(false);
	}

	setSync(e: MouseEvent, isSynced: boolean) {
		// These are here because the grid will go to the row and we just want the icon clicked
		e.preventDefault();
		e.stopPropagation();

		this.onClick.emit(isSynced);
	}
}
