<div *ngIf="orderSpecs" >
    <div class="d-flex thirds">

        <div class="me-1">
            <label>Linear Feet</label>
            <input [disabled]="(isJellyFish && dealerId) || locked" type="number" class="form-control" (change)="onLinearFeetChanged()" [(ngModel)]="orderSpecs.linearFeet" />
        </div>

        <div class="me-1">
            <label>Track Housing</label>
            <slick-drop-down [disabled]="(isJellyFish && dealerId) || locked" [items]="trackHousing" [(ngModel)]="orderSpecs.trackHousing" (onSelect)="onTrackHousingChanged($event)"></slick-drop-down>
        </div>

        <div class="me-1">
            <label>Track Type</label>
            <slick-drop-down [disabled]="(isJellyFish && dealerId) || (orderSpecs.trackHousing !== 'No Housing' && orderSpecs.trackHousing) || locked"
                             [items]="trackTypes"
                             [(ngModel)]="orderSpecs.trackType"
                             (onSelect)="onTrackTypeChanged($event)"></slick-drop-down>
        </div>

        <div class="me-1">
            <label>Track Color</label>
            <slick-drop-down [disabled]="(isJellyFish && dealerId) || locked"
                             [items]="trackColors"
                             [(ngModel)]="orderSpecs.trackColor"
                             (onSelect)="onTrackColorChanged($event)"></slick-drop-down>
        </div>

        <div class="me-1">
            <label>Clip Type</label>
            <slick-drop-down [disabled]="(isJellyFish && dealerId) || locked"
                             [items]="clipTypes"
                             [(ngModel)]="orderSpecs.clipType"
                             (onSelect)="onClipTypeChanged($event)"></slick-drop-down>
        </div>

        <div>
            <label>Controller Type</label>
            <slick-drop-down [disabled]="(isJellyFish && dealerId) || locked"
                             [items]="controllerTypes"
                             [(ngModel)]="orderSpecs.controllerType"
                             (onSelect)="onControllerTypeChanged($event)"></slick-drop-down>
        </div>

        <div class="me-1">
            <label> Home runs over 50ft</label>
            <input [disabled]="(isJellyFish && dealerId) || locked" type="number" class="form-control" (change)="calcLineItems()" [(ngModel)]="orderSpecs.homeRunsOver50ft" />
        </div>

        <div class="me-1">
            <label> Jumps over 20ft </label>
            <input [disabled]="(isJellyFish && dealerId) || locked" type="number" class="form-control" (change)="calcLineItems()" [(ngModel)]="orderSpecs.jumpsOver20ft" />
        </div>
    </div>


    <div *ngIf="itemsToOrder && itemsToOrder.length > 0">


        <div class="bold-header" style="display: flex; align-items: center;">
            <div *ngIf="!itemsToOrderExpanded" (click)="itemsToOrderExpanded = !itemsToOrderExpanded" style="flex-grow: 1;"> <i class="bi bi-plus-circle"></i></div>
            <div *ngIf="itemsToOrderExpanded" (click)="itemsToOrderExpanded = !itemsToOrderExpanded" style="flex-grow: 1;"> <i class="bi bi-dash-circle"></i></div>
            <div style="flex-grow: 1;">Items To Order</div>
            <div class="gray-line"></div>
        </div>

        <table class="table" *ngIf="itemsToOrderExpanded">
            <thead>
                <tr>
                    <th>SKU</th>
                    <th>Description</th>
                    <th>Quantity</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of itemsToOrder">
                    <tr>
                        <td>{{ item.sku }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.quantity }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>

    </div>

</div>
