import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { DropdownModel, IAppointmentUserStrikeModel, IAppointmentUserStrikeTypeModel, IDropdownModel } from "@models";

@Injectable()
export class AppointmentUserStrikesService {
    constructor(private readonly httpService: HttpService) { }

    async getAppointmentUserStrikes(appointmentId: number): Promise<IAppointmentUserStrikeModel[]> {
        return await this.httpService.get("/appointmentUserStrikes/getAppointmentUserStrikes", { appointmentId: appointmentId });
    }

    async addAppointmentUserStrike(model: IAppointmentUserStrikeModel): Promise<IAppointmentUserStrikeModel> {
        return await this.httpService.post("/appointmentUserStrikes/addAppointmentUserStrike", model);
    }

    async getAppointmentUserStrikeTypesForDropDown(): Promise<IDropdownModel[]> {
        const appointmentUserStrikeTypes: IAppointmentUserStrikeTypeModel[] = await this.httpService.get('/appointmentUserStrikes/getAppointmentUserStrikeTypes');

        return appointmentUserStrikeTypes
            .filter(x => x.active === true)
            .map(x => new DropdownModel(x.appointmentUserStrikeTypeId, x.description));
	}
}