export interface ITrackModel {
    trackType: string;
    color: string;
	trackColor: string;
	isToptrack: boolean;
	isUndertrack: boolean;
}

export class TrackModel implements ITrackModel
{
    trackType: string;
    color: string;
    trackColor: string;
	isToptrack: boolean;
	isUndertrack: boolean;
}