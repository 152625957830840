import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IGetPaymentsListModel, IPaymentListViewModel, IPaymentModel, IAppliedPaymentModel, IInvoiceModel, IEmailerModel } from "@models";
import { UtilsService } from "@services/utils/utils.service";

@Injectable()
export class PaymentsService {
    constructor(private readonly httpService: HttpService) { }

    async getPayment(paymentId: number): Promise<IPaymentModel> {
        return await this.httpService.get("/payments/getPayment", { paymentId: paymentId });
    }

    async getPaymentListView(paymentId: number): Promise<IPaymentListViewModel> {
        return await this.httpService.get("/payments/getPaymentListView", { paymentId: paymentId });
	}

    async getUnappliedPayments(customerId: number): Promise<IPaymentListViewModel[]> {
		return await this.httpService.get("/payments/getUnappliedPayments", { customerId: customerId });
	}

    async updatePayment(model: IPaymentModel): Promise<IPaymentModel> {
        // Because of the pay the applied payments are structured, it causes a circular dependency
        // No need to pass the invoice or payment into the update
        model.appliedPayments = model.appliedPayments.map(ap => {
            delete ap.invoice;
            delete ap.payment;
            return ap
        });

        return await this.httpService.post("/payments/updatePayment", model);
    }

    async deletePayment(paymentId: number): Promise<boolean> {
        return await this.httpService.delete("/payments/deletePayment", { paymentId: paymentId });
    }

    async generatePaymentReceiptPDF(paymentId: number): Promise<any> {
        return await this.httpService.post(`/payments/generatePaymentReceiptPDF?paymentId=${paymentId}`, null);
    }

    async preparePaymentReceiptEmail(model: IPaymentModel): Promise<IEmailerModel> {
        return await this.httpService.post("/payments/preparePaymentReceiptEmail", model);
    }

    async generateInvoicePaymentReceiptPdf(appliedPaymentUuid: string): Promise<string> {
        return await this.httpService.post(`/payments/generateAppliedPaymentReceiptPDF?appliedPaymentUuid=${appliedPaymentUuid}`, null);
    }

    async emailPaymentReceipt(model: IPaymentModel): Promise<IEmailerModel> {
        return await this.httpService.post("/payments/emailPaymentReceipt", model);
    }

    async syncToQuickbooks(paymentId: number): Promise<IPaymentModel> {
        return await this.httpService.post(`/payments/syncPaymentToQuickbooks`, paymentId);
    }

}