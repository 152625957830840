import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SlickProfilePictureComponent } from "./slick-profile-picture.component";

@NgModule({
	imports: [CommonModule, FormsModule],
	declarations: [SlickProfilePictureComponent],
	exports: [CommonModule, FormsModule, SlickProfilePictureComponent]
})
export class SlickProfilePictureModule { }
export { SlickProfilePictureComponent }
