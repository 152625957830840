import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SlickOrderedListComponent } from "./slick-ordered-list.component";

@NgModule({
	imports: [CommonModule, FormsModule],
	declarations: [SlickOrderedListComponent],
	exports: [CommonModule, FormsModule, SlickOrderedListComponent]
})
export class SlickOrderedListModule { }

export { SlickOrderedListComponent };