import { UtilsService } from "@services/utils/utils.service";

export interface ITimeclockBreakModel {
	uuid: string;
	timeclockEntryId: number;
	breakStart?: Date;
	breakStartHoursText?: string;
	breakStartGeolocation: string;
	breakEnd?: Date;
	breakEndHoursText?: string;
	breakEndGeolocation: string;
	totalMinutes?: number;
	isBefore: boolean;
}

export class TimeclockBreakModel implements ITimeclockBreakModel {
	constructor() {
		this.uuid = UtilsService.newGuid();
	}
	uuid: string;
	timeclockEntryId: number;
	breakStart?: Date;
	breakStartHoursText?: string;
	breakStartGeolocation: string;
	breakEnd?: Date;
	breakEndHoursText?: string;
	breakEndGeolocation: string;
	totalMinutes?: number;
	isBefore: boolean;

}