import { Component, ViewChild, Input, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import { SlickDialogComponent, SlickScreenBlockerService, SlickConfirmDialogComponent, SlickConfirmDialogResults, SlickToastService } from "@slick-components";
import { ICustomerModel } from "@models";
import { CustomerMultipleAddressModel, ICustomerMultipleAddressModel } from "@models/customers/customer-multiple-address.model"
import { CustomerEditLayoutComponent } from "@app/customers/customer-components/customer-edit/customer-edit-layout/customer-edit-layout.component";
import { UtilsService, CustomersService } from "@services";

@Component({
	selector: "customer-multiple-addresses",
	templateUrl: "customer-multiple-addresses.component.html",
	styleUrls: ["customer-multiple-addresses.component.css"],
	providers: [SlickScreenBlockerService, SlickToastService]
})

export class CustomerMultipleAddressesComponent {
	@ViewChild("addAddressRef") addAddressRef: SlickDialogComponent;
	@ViewChild("customerEditLayoutRef") customerEditLayoutRef: CustomerEditLayoutComponent;
	@ViewChild("deleteAddressConfirmRef", { static: true }) deleteAddressConfirmRef: SlickConfirmDialogComponent;
	@Input() customerModel: ICustomerModel;

	
	multiAddressModel: ICustomerMultipleAddressModel;
	indexOfModel: number;
	isValid: boolean;
	isSubmitted: boolean;
	emailIsValid: boolean;
	invalidEmail: boolean;

	countries: any = [
		{ id: 'US', text: 'US' },
		{ id: 'Canada', text: 'Canada' },
		{ id: 'Mexico', text: 'Mexico' },
		{ id: 'Israel', text: 'Israel' }
	];


	constructor(
		private slickToastService: SlickToastService,
		private customersService: CustomersService,
		private changeDetector: ChangeDetectorRef) {
	}

	async ngOnChanges(changes: SimpleChanges) {
		this.isSubmitted = false;

		if (!this.customerModel.customerMultipleAddresses)
			this.customerModel.customerMultipleAddresses = [];
	}

	async addAddress() {
		this.indexOfModel = -1;
		this.multiAddressModel = new CustomerMultipleAddressModel();
		this.addAddressRef.showDialog();
	}

	validate() {
		this.isValid = true;
		if (!this.multiAddressModel.description && !this.multiAddressModel.companyName && !this.multiAddressModel.lastName)
			this.isValid = false;

		this.emailIsValid = true;
		const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,}$/;
		if (regex.test(this.multiAddressModel.email) === false && this.multiAddressModel.email) {
			this.emailIsValid = false;
		}
    }

	async saveAddress() {
		this.isSubmitted = true;
		this.validate();
		if (!this.isValid || ! this.emailIsValid)
			return;
		if (this.customerModel.customerId > 0) {
			var address = await this.customersService.addMultipleAddress(this.multiAddressModel, this.customerModel.customerId);
			if (this.indexOfModel >= 0) 
				this.customerModel.customerMultipleAddresses[this.indexOfModel] = address;
			else 
				this.customerModel.customerMultipleAddresses.push(address);
			
		}
		else {
			if (this.indexOfModel >= 0)
				this.customerModel.customerMultipleAddresses[this.indexOfModel] = this.multiAddressModel;
			else
				this.customerModel.customerMultipleAddresses.push(this.multiAddressModel);
		}
		this.addAddressRef.hideDialog();
	}

	editAddress(idx: number) {
		this.indexOfModel = idx;
		this.multiAddressModel = UtilsService.clone(this.customerModel.customerMultipleAddresses[this.indexOfModel]);
		this.addAddressRef.showDialog();
	}

	cancelAddAddress() {
		this.multiAddressModel = null;
		this.addAddressRef.hideDialog();
    }

	async onGoogleMapAddressSelect(placeResult: google.maps.places.PlaceResult) {
		this.multiAddressModel.address1 = '';
		this.multiAddressModel.address2 = '';
		this.multiAddressModel.city = '';
		this.multiAddressModel.state = '';
		this.multiAddressModel.country = '';
		this.multiAddressModel.zipcode = '';

		placeResult.address_components.forEach(adr => {
			adr.types.forEach(type => {
				switch (type) {
					case 'street_number':
					case 'route':
						this.multiAddressModel.address1 += adr.long_name + ' ';
						break;

					case 'locality':
						this.multiAddressModel.city = adr.long_name;
						break;

					case 'administrative_area_level_1':
						this.multiAddressModel.state = adr.short_name;
						break;

					case 'postal_code':
						this.multiAddressModel.zipcode = adr.short_name;
						break;

					case 'country':
						if (adr.short_name === 'CA') {
							this.multiAddressModel.country = 'Canada';
						} else if (adr.short_name === 'IL') {
							this.multiAddressModel.country = 'Israel';
						} else if (adr.short_name === 'MX') {
							this.multiAddressModel.country = 'Mexico';
						} else {
							this.multiAddressModel.country = adr.short_name;
						}
						break;
				}
			});
		});
		this.changeDetector.detectChanges();
	}

	async deleteMultiAddress(e: MouseEvent, index: number) {
		e.stopPropagation()
		const confirmResult = await this.deleteAddressConfirmRef.confirm();
		if (confirmResult === SlickConfirmDialogResults.Ok) {
			var addressModel = this.customerModel.customerMultipleAddresses[index];
			await this.customersService.deleteMultipleAddress(addressModel.customerMultipleAddressId);
			this.customerModel.customerMultipleAddresses = this.customerModel.customerMultipleAddresses.filter(x => x.customerMultipleAddressId !== addressModel.customerMultipleAddressId);
			this.slickToastService.showSuccess("Address Deleted", 1000);
		}

    }

}