export interface IFuelSurchargeModel {
	fuelSurchargeId: number;
	serviceAreaId?: number;
	serviceAreaName: string;
	dollarAmount?: number;
	percentAmount?: number;
	includeShipping: boolean;
	includeLabor: boolean;
	quickbookId: string;
}

export class FuelSurchargeModel implements IFuelSurchargeModel {
	fuelSurchargeId: number;
	serviceAreaId?: number;
	serviceAreaName: string;
	dollarAmount?: number;
	percentAmount?: number;
	includeShipping: boolean;
	includeLabor: boolean;
	quickbookId: string;
}