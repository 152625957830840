import { ISlickFileModel } from "../slick-file-model/slick-file.model";
import { ISlickEmailerAddressModel } from "./slick-emailer-address.model";

export interface ISlickEmailerModel {
	uuid: string;
	toEmailAddresses: ISlickEmailerAddressModel[];
	subject: string;
	body: string;
	attachments: ISlickFileModel[];
}

export class SlickEmailerModel implements ISlickEmailerModel {
	uuid: string;
	toEmailAddresses: ISlickEmailerAddressModel[];
	subject: string;
	body: string;
	attachments: ISlickFileModel[];
}
