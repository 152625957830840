import { Injectable } from "@angular/core";
import { UtilsService } from "./utils/utils.service";
import { ILathamOrderModel, ILathamOrderSpecsModel, ISystemModel, IDeckEdgeModel, ITrackModel, ITubesModel, ILidModel } from "@models";

@Injectable()
export class LathamOrderHelpersService {
	setLathamOrderModelHelperValues(lathamOrderModel: ILathamOrderModel): ILathamOrderModel {
		if (!lathamOrderModel)
			return null;

		const brand = lathamOrderModel.orderSpecs.system.brandType;
		const orderType = lathamOrderModel.orderSpecs.system.modelType;

		delete lathamOrderModel.isSystem;
		delete lathamOrderModel.isSentry;
		delete lathamOrderModel.isCover;
		delete lathamOrderModel.isManual;
		delete lathamOrderModel.isLowOffset;

		lathamOrderModel.isSentry = (brand === 'Sentry');

		if (!orderType || orderType === "None") {
			lathamOrderModel.orderSpecs.system.orderType = "None";
			lathamOrderModel.orderSpecs.system.modelType = "None";
		}
		else {
			if (orderType === "MR") {

				lathamOrderModel.orderSpecs.system.orderType = "MR";
				lathamOrderModel.orderSpecs.system.modelType = "MR";
			}
			else if (orderType == "Parts Order") {
				lathamOrderModel.orderSpecs.system.orderType = "Parts Order";
				lathamOrderModel.orderSpecs.system.modelType = "Parts Order";
			}
			else {
				lathamOrderModel.isSystem = true;
				lathamOrderModel.orderSpecs.system.orderType = "System";
				lathamOrderModel.orderSpecs.system.modelType = orderType;

				switch (orderType) {
					case "CS500":
					case "CS3000b":
					case "Eclipseb":
					case "IN4000b":
					case "Replacement Fabric":
					case "Sentry Replacement Cover":
						lathamOrderModel.isCover = true;
						break;

					case "CS1000":
					case "ManualTrak":
						lathamOrderModel.isManual = true;
						break;
				}
			}

		}

		// Just for ease of use in ng-shows
		lathamOrderModel.brandType = lathamOrderModel.orderSpecs.system.brandType;
		lathamOrderModel.modelType = lathamOrderModel.orderSpecs.system.modelType;

		//if (lathamOrderModel.orderSpecs.shipping && lathamOrderModel.orderSpecs.shipping.shippingAddress) {
		//	if (!lathamOrderModel.orderSpecs.shipping.shippingAddress.customerId)
		//		lathamOrderModel.orderSpecs.shipping.shippingAddress.customerId = -1;
		//}

		lathamOrderModel.orderSpecs.system = this.setSystemHelperValues(lathamOrderModel.orderSpecs.system);
		lathamOrderModel.orderSpecs.track = this.setTrackHelperValues(lathamOrderModel.orderSpecs);
		lathamOrderModel.orderSpecs.deckEdge = this.setDeckEdgeHelperValues(lathamOrderModel.orderSpecs);
		lathamOrderModel.orderSpecs.tubes = this.setTubesHelperValues(lathamOrderModel.orderSpecs);
		lathamOrderModel.orderSpecs.lid = this.setLidHelperValues(lathamOrderModel.orderSpecs);

		return lathamOrderModel;
	}

	setDeckEdgeHelperValues(orderSpecs: ILathamOrderSpecsModel): IDeckEdgeModel {
		orderSpecs.deckEdge.showDeckEdge = true;
		orderSpecs.deckEdge.showEncapsulation = false;
		orderSpecs.deckEdge.showCorners = false;
		orderSpecs.deckEdge.showCliponCoping = false;
		orderSpecs.deckEdge.showReusableForms = false;

		if (orderSpecs.system.unitLocation !== 'Recessed')
			orderSpecs.deckEdge.showDeckEdge = false;
		else if (orderSpecs.system.modelType !== 'Eclipse' &&
			orderSpecs.system.modelType !== 'Atom' &&
			orderSpecs.system.modelType !== 'ATOM' &&
			orderSpecs.system.modelType !== 'CS3000' &&
			orderSpecs.system.modelType !== 'CS3000Q' &&
			orderSpecs.system.modelType !== 'CS3000D' &&
			orderSpecs.system.modelType !== 'PCS Infinity 4000' &&
			orderSpecs.system.modelType !== 'CS300' &&
			orderSpecs.system.modelType !== 'CS300HD2' &&
			orderSpecs.system.modelType !== 'CS300HD3' && 
			orderSpecs.system.modelType !== 'CS300FGP')
			orderSpecs.deckEdge.showDeckEdge = false;
		else if (orderSpecs.system.poolType !== 'Vinyl Liner' &&
			orderSpecs.system.poolType !== 'Gunite' &&
			orderSpecs.system.poolType !== 'Fiberglass')
			orderSpecs.deckEdge.showDeckEdge = false;
		else if (orderSpecs.track.isToptrack)
			orderSpecs.deckEdge.showDeckEdge = false;

		if (orderSpecs.deckEdge.showDeckEdge === false) {
			orderSpecs.deckEdge.encapsulation = "None";
			orderSpecs.deckEdge.corners = "None";
			orderSpecs.deckEdge.cliponCoping = "None";
		}

		switch (orderSpecs.system.poolType) {
			case 'Vinyl Liner':
				orderSpecs.deckEdge.showEncapsulation = true;
				orderSpecs.deckEdge.showReusableForms = true;
				break;

			case 'Gunite':
				orderSpecs.deckEdge.showEncapsulation = true;
				break;

			case 'Fiberglass':
				orderSpecs.deckEdge.showEncapsulation = true;
				break;
		}

		if (orderSpecs.deckEdge.encapsulation === 'Vinyl Liner' ||
			orderSpecs.deckEdge.encapsulation === 'One Piece Rounded' ||
			orderSpecs.deckEdge.encapsulation === 'One Piece Inclined' ||
			orderSpecs.deckEdge.encapsulation === 'One Piece Bullnose' ||
			orderSpecs.deckEdge.encapsulation === 'Outside Step' ||
			orderSpecs.deckEdge.encapsulation === 'Vinyl Liner w/ Fiber Optics') {
			orderSpecs.deckEdge.showCorners = true;

			if (orderSpecs.system.modelType === 'Eclipse' ||
				orderSpecs.system.modelType === 'Atom' ||
				orderSpecs.system.modelType === 'ATOM' ||
				orderSpecs.system.modelType === 'CS3000' ||
				orderSpecs.system.modelType === 'CS3000Q' ||
				orderSpecs.system.modelType === 'CS3000D' ||
				orderSpecs.system.modelType === 'CS300' ||
				orderSpecs.system.modelType === 'CS300HD2' ||
				orderSpecs.system.modelType === 'CS300HD3' ||
				orderSpecs.system.modelType === 'CS300FGP')
				orderSpecs.deckEdge.showCliponCoping = true;
		}

		return orderSpecs.deckEdge;
	}

	setSystemHelperValues(systemSpecs: ISystemModel): ISystemModel {
		if (!systemSpecs.trackSpaceInches)
			systemSpecs.trackSpaceInches = 0;

		if (!systemSpecs.trackLengthInches)
			systemSpecs.trackLengthInches = 0;

		if (!isNaN(systemSpecs.trackSpaceFeet) && !isNaN(systemSpecs.trackSpaceInches))
			systemSpecs.totalTrackSpace = systemSpecs.trackSpaceFeet * 12 + systemSpecs.trackSpaceInches;

		if (!isNaN(systemSpecs.trackLengthFeet) &&
			!isNaN(systemSpecs.trackLengthInches))
			systemSpecs.totalTrackLength = systemSpecs.trackLengthFeet * 12 + systemSpecs.trackLengthInches;

		systemSpecs.totalPerimeter = (systemSpecs.totalTrackLength * 2) + systemSpecs.totalTrackSpace;

		delete systemSpecs.is10x20;
		delete systemSpecs.is12x24;
		delete systemSpecs.is16x32;
		delete systemSpecs.is18x36;
		delete systemSpecs.is20x40;
		delete systemSpecs.is22x50;
		delete systemSpecs.isReallyBig;

		if ((systemSpecs.totalTrackSpace <= (10 * 12)) && (systemSpecs.totalTrackLength <= (20 * 12)))
			systemSpecs.is10x20 = true;
		else if ((systemSpecs.totalTrackSpace <= (12 * 12)) && (systemSpecs.totalTrackLength <= (24 * 12)))
			systemSpecs.is12x24 = true;
		else if ((systemSpecs.totalTrackSpace <= (16 * 12)) && (systemSpecs.totalTrackLength <= (32 * 12)))
			systemSpecs.is16x32 = true;
		else if ((systemSpecs.totalTrackSpace <= (18 * 12)) && (systemSpecs.totalTrackLength <= (36 * 12)))
			systemSpecs.is18x36 = true;
		else if ((systemSpecs.totalTrackSpace <= (20 * 12)) && (systemSpecs.totalTrackLength <= (42 * 12)))
			systemSpecs.is20x40 = true;
		else if ((systemSpecs.totalTrackSpace <= (22 * 12)) && (systemSpecs.totalTrackLength <= (50 * 12)))
			systemSpecs.is22x50 = true;
		else if ((systemSpecs.totalTrackSpace > (22 * 12) || systemSpecs.totalTrackLength > (50 * 12)))
			systemSpecs.isReallyBig = true;

		//console.info('Is10x20', systemSpecs.is10x20);
		//console.info('Is12x24', systemSpecs.is12x24);
		//console.info('Is16x32', systemSpecs.is16x32);
		//console.info('Is18x36', systemSpecs.is18x36);
		//console.info('Is20x40', systemSpecs.is20x40);
		//console.info('Is22x50', systemSpecs.is22x50);
		//console.info('IsReallyBig', systemSpecs.isReallyBig);

		return systemSpecs;
	}

	setTrackHelperValues(orderSpecs: ILathamOrderSpecsModel): ITrackModel {
		delete orderSpecs.track.isToptrack;
		delete orderSpecs.track.isUndertrack;

		switch (orderSpecs.track.trackType) {
			case "Undertrack":
			case "403 Undertrack":
			case "Undertrack optimized for retainer":
			case "Undertrack drill-up":
				orderSpecs.track.isToptrack = false;
				orderSpecs.track.isUndertrack = true;
				break;

			case "Toptrack":
			case "RHG Screw Down":
			case "Toptrack w/holes":
			case "Toptrack snap down":
			case 'Vertical Flush':
			case "PCS Horizontal Flush W/Retainer":
			case "PCS Horizontal Flush no Retainer":
			case "RHG":
			case "Slim Flush Track":
				orderSpecs.track.isToptrack = true;
				orderSpecs.track.isUndertrack = false;
				break;
		}

		return orderSpecs.track;
	}

	setTubesHelperValues(orderSpecs: ILathamOrderSpecsModel): ITubesModel {
		orderSpecs.tubes.showTubes = false;
		orderSpecs.tubes.showLeadingEdge = false;
		orderSpecs.tubes.showRollupTube = false;

		switch (orderSpecs.system.modelType) {
			case "Eclipse":
			case "Atom":
			case "ATOM":
			case "PCS Infinity 4000":
			case "CS3000":
			case "CS3000Q":
			case "CS3000D":
			case "CS1500":
			case "CS1000":
			case "CS300HD2":
			case "CS300HD3":
			case "CS300FGP":
			case "CS300":
			case "CS3000LO":
			case "Sentry System":
			
				orderSpecs.tubes.showTubes = true;
				orderSpecs.tubes.showLeadingEdge = true;
				orderSpecs.tubes.showRollupTube = true;
				break;

			case "CS500":
			case "CS3000b":
			case "Eclipseb":
			case "IN4000b":
			case "Replacement Fabric":
			case "Sentry Replacement Cover":
				orderSpecs.tubes.showTubes = true;
				orderSpecs.tubes.showLeadingEdge = true;
				orderSpecs.tubes.showRollupTube = false;
				break;

		}

		return orderSpecs.tubes;
	}

	setLidHelperValues(orderSpecs: ILathamOrderSpecsModel): ILidModel {
		if (orderSpecs.lid.lidType === 'Flush Lid Zero Reveal' ||
			orderSpecs.lid.lidType === 'Flush Lid 12 5/8 in w/ Riser' ||
			orderSpecs.lid.lidType === 'Flush Lid 14 in w/ Riser' ||
			orderSpecs.lid.lidType === 'Flush Lid 15 1/2 in w/ Riser')
			orderSpecs.lid.hasFlushLid = true;

		return orderSpecs.lid;

	}

	public calculateSquareFeet(system: ISystemModel): number {
		if (isNaN(system.trackSpaceFeet) ||
			isNaN(system.trackSpaceInches) ||
			isNaN(system.trackLengthFeet) ||
			isNaN(system.trackLengthInches))
			return null;

		var totalSqft = (((system.trackSpaceFeet * 12) + system.trackSpaceInches) * ((system.trackLengthFeet * 12) + system.trackLengthInches)) / 144;
		// Round Up
		totalSqft += 0.5;
		totalSqft = UtilsService.round(totalSqft, 0);
		return totalSqft;
	}

	calculateAluminumLidDimensions(lathamOrderModel: ILathamOrderModel): ILathamOrderModel {
		var additionalMainLid;

		if (lathamOrderModel.brandType === 'PCS') {
			lathamOrderModel.orderSpecs.lid.motorEndLidFeet = 2;
			lathamOrderModel.orderSpecs.lid.motorEndLidInches = 10;
			// PCS systems have GS + 6
			additionalMainLid = 6;

			lathamOrderModel.orderSpecs.lid.mainLidFeet = lathamOrderModel.orderSpecs.system.trackSpaceFeet;
			lathamOrderModel.orderSpecs.lid.mainLidInches = lathamOrderModel.orderSpecs.system.trackSpaceInches + additionalMainLid;

			if (lathamOrderModel.orderSpecs.lid.mainLidInches >= 12) {
				lathamOrderModel.orderSpecs.lid.mainLidFeet++;
				lathamOrderModel.orderSpecs.lid.mainLidInches -= 12;
			}
			lathamOrderModel.orderSpecs.lid.nonMotorEndLidFeet = 1;
			lathamOrderModel.orderSpecs.lid.nonMotorEndLidInches = 4;
		}
		else {
			lathamOrderModel.orderSpecs.lid.motorEndLidFeet = 3;
			lathamOrderModel.orderSpecs.lid.motorEndLidInches = 0;
			// TG systems have GS + 5
			additionalMainLid = 5;
			// UG systems have GS + 4
			if (lathamOrderModel.orderSpecs.track.trackType === "Undertrack")
				additionalMainLid = 4;

			lathamOrderModel.orderSpecs.lid.mainLidFeet = lathamOrderModel.orderSpecs.system.trackSpaceFeet;
			lathamOrderModel.orderSpecs.lid.mainLidInches = lathamOrderModel.orderSpecs.system.trackSpaceInches + additionalMainLid;

			if (lathamOrderModel.orderSpecs.lid.mainLidInches >= 12) {
				lathamOrderModel.orderSpecs.lid.mainLidFeet++;
				lathamOrderModel.orderSpecs.lid.mainLidInches -= 12;
			}
			lathamOrderModel.orderSpecs.lid.nonMotorEndLidFeet = 1;
			lathamOrderModel.orderSpecs.lid.nonMotorEndLidInches = 6;

		}

		return lathamOrderModel;
	}

	public getMotorType(sqft: number): string {
		if (sqft >= 1200 && sqft <= 1499) return 'Heavy Duty';
		if (sqft >= 1500) return 'Hydraulic';
		return null;
	};

	
}