<div *ngIf="isLoggedIn === true" class="main-container">
	<div class="content">
		<side-menu></side-menu>
		<div id="main-area-container" class="main-area-container">
			<header-bar></header-bar>
			<!-- <top-menu></top-menu> -->
			<div class="main-area" id="main-area-section">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
	<div class="main-footer">
		<footer-bar></footer-bar>
	</div>
</div>

<div *ngIf="isLoggedIn === false">
	<router-outlet></router-outlet>
</div>


<http-error-dialog></http-error-dialog>

<legacy-chat *ngIf="isLoggedIn === true && chatType === 'legacy'"></legacy-chat>

<version-release-dialog #dialogRef [isMobile]="false"></version-release-dialog>
<missed-clock-out-dialog #missedClockOutDialogRef [isMobile]="false"></missed-clock-out-dialog>
<missed-clock-in-dialog #missedClockInDialogRef [isMobile]="false"></missed-clock-in-dialog>
