import { Injectable } from "@angular/core";
import { ILathamOrderPickListDefinitionModel } from "@models";
import { HttpService } from "@services/utils/http.service";
import { Subject } from "rxjs";

@Injectable()
export class LathamOrdersPickListStore {
	private static _pickListDefinitions: ILathamOrderPickListDefinitionModel[];

	constructor(private httpService: HttpService) { }

	async init() {
		if (!LathamOrdersPickListStore._pickListDefinitions)
			LathamOrdersPickListStore._pickListDefinitions = await this.httpService.get("/lathamOrderPickLists/getPickListDefinitions");

		this.pickListDefinitionsSubject.next(LathamOrdersPickListStore._pickListDefinitions);
	}

	private pickListDefinitionsSubject: Subject<ILathamOrderPickListDefinitionModel[]> = new Subject<ILathamOrderPickListDefinitionModel[]>();
	get pickListDefinitionsStore(): Subject<ILathamOrderPickListDefinitionModel[]> {
		return this.pickListDefinitionsSubject;
	}

	async getPickListDefinitions(): Promise<ILathamOrderPickListDefinitionModel[]> {
		if (!LathamOrdersPickListStore._pickListDefinitions)
			LathamOrdersPickListStore._pickListDefinitions = await this.httpService.get("/lathamOrderPickLists/getPickListDefinitions");

		return LathamOrdersPickListStore._pickListDefinitions;
	}

	async getPickListDefinition(uuid: string): Promise<ILathamOrderPickListDefinitionModel> {
		return this.httpService.get(`/lathamOrderPickLists/getPickListDefinition?uuid=${uuid}`);
	}

	async addPickListDefinition(model: ILathamOrderPickListDefinitionModel): Promise<ILathamOrderPickListDefinitionModel> {
		const pickListDefinition: ILathamOrderPickListDefinitionModel = await this.httpService.post("/lathamOrderPickLists/addPickListDefinition", model);
		LathamOrdersPickListStore._pickListDefinitions.push(pickListDefinition);

		return pickListDefinition;
	}

	async updatePickListDefinition(model: ILathamOrderPickListDefinitionModel): Promise<ILathamOrderPickListDefinitionModel> {
		const pickListDefinition: ILathamOrderPickListDefinitionModel = await this.httpService.post("/lathamOrderPickLists/updatePickListDefinition", model);
		const idx = LathamOrdersPickListStore._pickListDefinitions.findIndex(x => x.uuid === pickListDefinition.uuid);
		LathamOrdersPickListStore._pickListDefinitions[idx] = pickListDefinition;

		return pickListDefinition;
	}

	async deletePickListDefinition(uuid: string): Promise<void> {
		await this.httpService.delete(`/lathamOrderPickLists/deletePickListDefinition?uuid=${uuid}`);
		LathamOrdersPickListStore._pickListDefinitions = LathamOrdersPickListStore._pickListDefinitions.filter(x => x.uuid !== uuid);
	}
}
