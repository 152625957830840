<slick-container *ngIf="paymentModel">
	<slick-container-body>
		<slick-tabs [(tabIndex)]="tabIndex" (tabChange)="onTabChanged($event)">
			<slick-tab header="Payment" icon="fa-calculator">

				<slick-container maxHeight="calc(100vh - 250px)">
					<slick-container-body>
						<div class="bold-header" style="display: flex; align-items: center; padding-top: 5px !important;">
                            <div style="flex-grow: 1;">Customer Info</div>
                            <div class="gray-line"></div>
                        </div>
						<div class="d-flex flex-fill">
							<div class="flex-fill">
								<label class="mt-1" style="padding-bottom: 4px;"><b>Billing Customer</b></label>
								<div  style="width: 380px">
									<customer-search (customerModelChange)="onCustomerModelChange($event)"
													 [(customerModel)]="paymentModel.customer"
													 [showButtons] ="!adminEdit === false"
                                                    [disabled]="adminEdit === false && paymentModel.paymentId > 0"></customer-search>
									<form-error *ngIf="isSubmitted && !paymentModel.customer">Customer is required</form-error>
								</div>
								<!-- <div *ngIf="paymentModel.paymentId > 0 && adminEdit === false" style="width: 380px">
									<customer-display [customerModel]="paymentModel.customer"
													  [isBillingCustomer]="true"></customer-display>
								</div> -->
							</div>

							<div *ngIf="isQuickbooksEnabled" class="position-relative top-0 end-0" style="margin-top: -25px;">
								<div style="min-height: 20px;"></div>
								<quickbooks-icon [lockSynced]="adminEdit === false && paymentModel.paymentId !== 0"
												 [syncToQuickbooks]="paymentModel.syncToQuickbooks"
												 [quickbooksId]="paymentModel.quickbooksId"
												 (onClick)="paymentModel.syncToQuickbooks = $event"></quickbooks-icon>
							</div>
							<div *ngIf="isBusinessCentralEnabled" class="position-relative top-0 end-0" style="margin-top: -25px;">
								<div style="min-height: 20px;"></div>
								<business-central-icon [lockSynced]="adminEdit === false && paymentModel.paymentId !== 0"
													   [syncToBusinessCentral]="paymentModel.syncToBusinessCentral"
												 [businessCentralId]="paymentModel.businessCentralId"
												 (onClick)="paymentModel.syncToBusinessCentral = $event"></business-central-icon>
							</div>
						</div>

						<div class="bold-header mb-1 mt-3" style="display: flex; align-items: center; padding-top: 5px !important;">
							<div style="flex-grow: 1;">Payment Info</div>
							<div class="gray-line"></div>
						</div>

						<div class="d-flex row form-group">
							<div class="col">
								<div class="w-100">
									<label>Ref #</label>
									<input type="text"
										   class="form-control"
										   [(ngModel)]="paymentModel.referenceNumber" />
								</div>
							</div>
							<div class="col">
								<label>Payment Type</label>
								<slick-drop-down [disabled]="adminEdit === false && paymentModel.paymentId !== 0"
												 [items]="paymentTypes"
												 [(ngModel)]="paymentModel.paymentType"></slick-drop-down>
								<form-error *ngIf="isSubmitted && !paymentModel.paymentType">Payment Type is required</form-error>
								<form-error *ngIf="!allowAmericanExpress && paymentModel.paymentType === 'American Express' && (adminEdit === true || paymentModel.paymentId === 0)">American Express cards are not accepted.</form-error>
							</div>
							<div class="col">
								<label>Payment Date</label>
								<input *ngIf="adminEdit === true || paymentModel.paymentId === 0"
									   slick-date-picker
									   type="text"
									   class="form-control"
									   [(ngModel)]="paymentModel.paymentDate" />

								<input *ngIf="!(adminEdit === true || paymentModel.paymentId === 0)"
									   disabled
									   slick-date-picker
									   type="text"
									   class="form-control"
									   [(ngModel)]="paymentModel.paymentDate" />
								<form-error *ngIf="isSubmitted && !paymentModel.paymentDate">Payment Date is required</form-error>
							</div>
							<div class="col">
								<div class="d-flex">
									<label class="me-3">Amount</label>
								</div>
								<input *ngIf="adminEdit === true || paymentModel.paymentId === 0"
									   type="text"
									   [slick-currency-mask]
									   class="form-control"
									   [(ngModel)]="paymentModel.amount"
									   (change)="recalcAppliedAmount()" />

								<input *ngIf="!(adminEdit === true || paymentModel.paymentId === 0)"
									   disabled
									   type="text"
									   [slick-currency-mask]
									   class="form-control"
									   [(ngModel)]="paymentModel.amount"
									   (change)="recalcAppliedAmount()" />
								<form-error *ngIf="isSubmitted && paymentModel.amount === null">Payment Amount is required</form-error>
								<div class="d-flex justify-content-end">
									<button *ngIf="adminEdit === true || paymentModel.paymentId === 0" class="btn btn-link p-0 mt-1 ms-1" style="font-size: 14px;" (click)="copyAppliedAmountToAmount()">Copy applied amount</button>
								</div>
							</div>
							
						</div>
						<div class="col">
							<div class="ps-2 mb-1 mt-2 bg-danger text-white" style="border-radius:5px" *ngIf="paymentModel.isVoided">
								{{paymentModel.voidedMessage}}
							</div>

							<div class="mb-2 mt-2" *ngIf="paymentModel.paymentRefundModels?.length > 0">
								<div class="ps-2 mb-1 bg-danger text-white" style="border-radius:5px" *ngFor="let refund of paymentModel.paymentRefundModels">
									{{refund.userFullName}} refunded {{refund.refundAmount | currency}} on {{refund.date | date:'M/dd/yyyy'}}
								</div>
	
							</div>
						</div>

						<div class="d-flex row form-group mt-3">
							<div class="col-12">
								<label>Notes</label>
								<textarea [disabled]="adminEdit === false && paymentModel.paymentId !== 0"
										  type="text"
										  class="form-control"
										  [(ngModel)]="paymentModel.notes"
										  rows="4"></textarea>
							</div>
						</div>

						<div class="mt-3"></div>

						<div class="bold-header mb-1 mt-3" style="display: flex; align-items: center; padding-top: 5px !important;">
							<div style="flex-grow: 1;">Applied</div>
							<div class="gray-line"></div>
						</div>

						<!-- <h5>Applied</h5> -->
						<div class="ms-2">
							<div class="mb-3" *ngIf="paymentModel.appliedPayments?.length === 0">No applied payments</div>
							<table *ngIf="paymentModel.appliedPayments?.length > 0" class="table table-sm striped table-borderless">

								<colgroup>
									<col style="width: 25px" />
									<col style="width: 120px" />
									<col style="width: 100px" />
									<col style="width: 120px" />
									<col style="width: 110px" />
									<col style="width: 100px" />
									<col style="width: 110px" />
									<col style="width: 18px" />
								</colgroup>

								<thead >
									<tr class="border-bottom">
										<td style="text-align: right;"></td>
										<td>Invoice #</td>
										<td >PO #</td>
										<td >Date</td>
										<td style="text-align: right;">Invoice Total</td>
										<td style="text-align: right;">Applied</td>
										<td style="text-align: right;">Balance Remaining</td>
										<td></td>
									</tr>
								</thead>

								<tbody>
									<tr *ngFor="let appliedPayment of paymentModel.previouslyAppliedPayments">
										<td style="text-align: right;">&nbsp;</td>
										<td>{{appliedPayment.invoice?.invoiceNumber}}</td>
										<td>{{appliedPayment.invoice?.purchaseOrderNumber}}</td>
										<td>{{appliedPayment.invoice?.invoiceDate | date: 'MM/dd/yy'}}</td>
										<td style="text-align: right;">{{appliedPayment.invoice?.invoiceTotal | currency}}</td>
										<td style="text-align: right;">{{appliedPayment.appliedAmount | currency}}</td>
										<td style="text-align: right;">{{appliedPayment.invoice?.outstandingBalance | currency}}</td>
										<td></td>
									</tr>

									<tr *ngFor="let appliedPayment of paymentModel.appliedPayments">
										<td style="text-align: left; max-width: 10px;"><button *ngIf="adminEdit === true || paymentModel.paymentId === 0" type="button" class="btn btn-link text-danger" (click)="removeAppliedPayment(appliedPayment)"><i class="far fa-minus-circle"></i></button></td>
										<td>{{appliedPayment.invoice?.invoiceNumber}}</td>
										<td>{{appliedPayment.invoice?.purchaseOrderNumber}}</td>
										<td>{{appliedPayment.invoice?.invoiceDate | date: 'MM/dd/yy'}}</td>
										<td style="text-align: right;">{{appliedPayment.invoice?.invoiceTotal | currency}}</td>
										<td style="text-align: right;">
											<input *ngIf="adminEdit === true || paymentModel.paymentId === 0" type="text" class="form-control" style="text-align: right; padding: 2px; padding-right: 5px; height: 26px;" [slick-currency-mask] [(ngModel)]="appliedPayment.appliedAmount" (change)="onAppliedAmountChanged(appliedPayment)" />
											<input *ngIf="!(adminEdit === true || paymentModel.paymentId === 0)" disabled type="text" class="form-control" style="text-align: right; padding: 2px; padding-right: 5px; height: 26px;" [slick-currency-mask] [(ngModel)]="appliedPayment.appliedAmount" (change)="onAppliedAmountChanged(appliedPayment)" />
										</td>
										<td style="text-align: right;">{{appliedPayment.invoice?.outstandingBalance | currency}}</td>
										<td></td>
									</tr>
								</tbody>
							</table>
							<table *ngIf="paymentModel.appliedPayments?.length > 0">
								<colgroup>
									<col style="width: 100%" />
									<col style="min-width: 250px" />
									<col style="min-width: 40px" />
									<col style="min-width: 100px" />
									<col style="min-width: 40px" />
								</colgroup>					

								<tbody>
									<tr>
										<td></td>
										<td style="text-align: right;"><b>Payment Total: </b></td>
										<td></td>
										<td style="text-align: right;"><b>{{paymentModel.amount | currency}}</b></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td style="text-align: right;"><b>Applied Total: </b></td>
										<td></td>
										<td style="text-align: right;"><b>{{paymentModel.appliedAmount | currency}}</b></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td style="text-align: right;"><b>Total Balance Due: </b></td>
										<td></td>
										<td style="text-align: right;"><b>{{paymentModel.invoiceTotals | currency}}</b></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td style="font-weight: bold; text-align: right; border-top: 2px solid var(--bs-border-color);" [ngClass]="{ 'text-danger': paymentModel.unappliedAmount < 0 }"><b>Remaining Payment: </b></td>
										<td style="font-weight: bold; text-align: right; border-top: 2px solid var(--bs-border-color);"></td>
										<td style="text-align: right; font-weight: bold; border-top: 2px solid var(--bs-border-color);"><b>{{paymentModel.unappliedAmount | currency}}</b></td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</div>

						<div class="bold-header" style="display: flex; align-items: center;">
							<div *ngIf="!invoicesExpanded" (click)="invoicesExpanded = !invoicesExpanded" style="flex-grow: 1;"> <i class="bi bi-plus-circle"></i></div>
							<div *ngIf="invoicesExpanded" (click)="invoicesExpanded = !invoicesExpanded" style="flex-grow: 1;"> <i class="bi bi-dash-circle"></i></div>
							<div style="flex-grow: 1;">Customer Invoices</div>
							<div class="gray-line"></div>
						</div>

						<div class="ms-2" *ngIf="invoicesExpanded">
							<div class="mb-3" *ngIf="!customerInvoices">Loading...</div>
							<div class="mb-3" *ngIf="customerInvoices && customerInvoices.length === 0">No invoices with outstanding balances</div>
							<table *ngIf="customerInvoices && customerInvoices.length > 0" class="table table-sm striped table-borderless">

								<colgroup>
									<col style="width: 25px" />
									<col style="width: 120px" />
									<col style="width: 100px" />
									<col style="width: 120px" />
									<col style="width: 110px" />
									<col style="width: 100px" />
									<col style="width: 110px" />
									<col style="width: 18px" />
								</colgroup>

								<thead>
									<tr class="border-bottom">
										<td></td>
										<td>Invoice #</td>
										<td>PO #</td>
										<td>Date</td>
										<td style="text-align: right;">Invoice Total</td>
										<td style="text-align: right;">Applied</td>
										<td style="text-align: right;">Balance Remaining</td>
										<td></td>
									</tr>
								</thead>

								<tbody>
									<tr *ngFor="let customerInvoice of customerInvoices">
										<td style="text-align: left; max-width: 10px;"><button *ngIf="adminEdit === true || paymentModel.paymentId === 0" type="button" class="btn btn-link text-success" (click)="addAppliedPayment(customerInvoice)"><i class="far fa-plus-circle"></i></button></td>
										<td>{{customerInvoice.invoiceNumber}}</td>
										<td>{{customerInvoice.purchaseOrderNumber}}</td>
										<td>{{customerInvoice.invoiceDate | date: 'MM/dd/yy'}}</td>
										<td style="text-align: right;">{{customerInvoice.invoiceTotal | currency}}</td>
										<td style="text-align: right;">{{customerInvoice.appliedPaymentsTotal | currency}}</td>
										<td style="text-align: right;">{{customerInvoice.outstandingBalance | currency}}</td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</div>
					</slick-container-body>
				</slick-container>

			</slick-tab>

			<slick-tab header="Photos" icon="fa-camera">
				<slick-container maxHeight="calc(100vh - 250px)">
					<slick-container-body>
						<documents-edit *ngIf="tabKey === 'Photos'"
										[documentKey]="paymentModel.documentKey"
										documentType="Payments"
										folderType="photos"
										[canDelete]="true"
										[hideFileDrop]="!paymentsAuthService.canEdit()"></documents-edit>
					</slick-container-body>
				</slick-container>
			</slick-tab>

			<slick-tab header="Documents" icon="fa-file-alt">
				<slick-container maxHeight="calc(100vh - 250px)">
					<slick-container-body>
						<documents-edit *ngIf="tabKey === 'Documents'"
										[documentKey]="paymentModel.documentKey"
										documentType="Payments"
										folderType="documents"
										[canDelete]="true"
										[hideFileDrop]="!paymentsAuthService.canEdit()"></documents-edit>
					</slick-container-body>
				</slick-container>
			</slick-tab>

			<slick-tab header="System Logs" icon="fa-info-circle">
				<slick-container maxHeight="calc(100vh - 250px)">
					<slick-container-body>
						<system-logs *ngIf="tabKey === 'System Logs'" noteType="3" [referenceId]="paymentModel.paymentId"></system-logs>
					</slick-container-body>
				</slick-container>
			</slick-tab>
		</slick-tabs>
	</slick-container-body>

	<slick-container-footer *ngIf="!isDialog">
		<div class="d-flex flex-column" *ngIf="paymentsAuthService.canEdit()">
			<div class="mt-1 pt-1 mb-0 row" style="max-width: 100%;">
				<div class="col">
					<button type="button" [slick-button-spinner]="spinnerStatus" class="btn btn-outline-success me-2" style="width: 140px;" (click)="savePayment()"><i class="bi bi-check-circle"></i> Save</button>
					<button type="button" class="btn btn-outline-danger" style="width: 140px;" (click)="cancelPayment()"><i class="bi bi-x-circle"></i> Cancel</button>
				</div>
				<div class="col text-end">
					<div class="btn-group" role="group">
						<!-- hide the void and refund buttons if the company is using bambora -->
						<button type="button" [disabled]="paymentModel.paymentId === 0" class="btn btn-outline-primary" title="Print Payment" (click)="generatePaymentPdf()"><i class="far fa-print"></i></button>
						<button type="button" [disabled]="paymentModel.paymentId === 0" class="btn btn-outline-primary" title="Email Payment" (click)="emailPayment()"><i class="far fa-envelope"></i></button>
						<button type="button" class="btn btn-outline-primary" title="Charge Card" (click)="openCreditCardDialog()"><i class="far fa-credit-card"></i></button>
						<button *ngIf="!isBambora && canRefundPayments && paymentModel.canRefund" [disabled]="paymentModel.paymentId === 0" type="button" class="btn btn-outline-primary" title="Refund Payment" (click)="openRefundPaymentDialog()">R</button>
						<button *ngIf="!isBambora && canVoidPayments && paymentModel.canVoid" [disabled]="paymentModel.paymentId === 0" type="button" class="btn btn-outline-primary" title="Void Payment" (click)="voidPayment()">V</button>
						<button *ngIf="adminUnlock && adminEdit === false" title="Admin Edit" [disabled]="paymentModel.paymentId === 0" type="button" class="btn btn-outline-primary" (click)="adminEdit=true"><i class="far fa-lock"></i></button>
						<button *ngIf="adminUnlock && adminEdit === true" title="Admin Edit" [disabled]="paymentModel.paymentId === 0" type="button" class="btn btn-outline-primary" (click)="adminEdit=false"><i class="far fa-unlock"></i></button>

					</div>
				</div>
			</div>
		</div>
		<div class="d-flex flex-fill" *ngIf="!paymentsAuthService.canEdit()">
			<permission-denied [message]="'*You do not have permission to edit Payments.'"></permission-denied>
		</div>
	</slick-container-footer>
</slick-container>

<credit-card-dialog #creditCardDialogRef
					(onApproved)="onApproved($event)"
					(onPaymentTypesChanged)="onPaymentTypesChanged($event)"></credit-card-dialog>
<emailer-dialog #emailerDialogRef></emailer-dialog>


<refund-payment-dialog [paymentModel]="paymentModel" #refundPaymentDialogRef></refund-payment-dialog>

<slick-confirm-dialog #confirmVoidRef>
	<div class="text-danger" style="line-height: 24px;">Are you sure you want to void this payment? This will remove all applied payments!</div>
</slick-confirm-dialog>

<slick-confirm-dialog #confirmRefundRef>
	<div class="text-danger" style="line-height: 24px;">Are you sure you want to refund this payment? This will remove all applied payments!</div>
</slick-confirm-dialog>