import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlickPickListComponent } from "./slick-pick-list.component";
import { SlickOrderedListModule } from "../slick-ordered-list/slick-ordered-list.module";

@NgModule({
	imports: [CommonModule, SlickOrderedListModule],
	declarations: [SlickPickListComponent],
	exports: [CommonModule, SlickOrderedListModule, SlickPickListComponent]
})
export class SlickPickListModule { }

export { SlickPickListComponent }