import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SlickDialogModule } from "@slick-components";

import { ConflictingCustomerDialogComponent } from "./conflicting-customers-dialog.component";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SlickDialogModule
	],
	declarations: [ConflictingCustomerDialogComponent],
	exports: [ConflictingCustomerDialogComponent]
})
export class ConflictingCustomerDialogModule { }