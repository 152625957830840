<div class="col mt-2 ps-2">
    <ng-template [ngIf]="customerModel.customerMultipleAddresses">
        <article class="btn btn-outline-light m-2" style="border: 1px solid #d1d0cd;" *ngFor="let address of customerModel.customerMultipleAddresses; let idx = index" (click)="editAddress(idx)">
            <div class="address-card-ui add-address-holder btn col-12">
                <div class="col-12 ps-0 pe-0">
                    <div *ngIf="address.description" style="font-size: 20px; font-weight: bold; line-height: 26px; text-align:left" class="border-bottom">
                        {{address.description}}
                    </div>

                    
                    <div class="row" style="margin-left:1px">

                        <div style="text-align:left; width:300px" class="mt-2">

                            <div>
                                <i class="bi bi-person-fill" *ngIf="address.companyName"></i>
                                <span class="ms-1">
                                    {{address.companyName}}
                                </span>
                            </div>
                            <div *ngIf="!address.companyName && (address.firstName || address.lastName)">
                                <i class="bi bi-person-fill"></i>
                                <span class="ms-1">
                                    {{address.firstName}} {{address.lastName}}
                                </span>
                            </div>

                            <div style="margin-left:22px" *ngIf="address.companyName">
                                <span>
                                    {{address.firstName}} {{address.lastName}}
                                </span>
                            </div>

                            <div class="mt-2">
                                <i *ngIf="address.address1" class="bi bi-house-fill"></i>
                                <span>
                                    {{address.address1}}

                                </span>
                            </div>
                            <div *ngIf="!address.address1 && address.address2">
                                <i class="bi bi-house-fill"></i>
                                {{address.address2}}
                            </div>
                            <div *ngIf="address.address1" style="margin-left: 22px">
                                {{address.address2}}
                            </div>

                            <div class="mt-1" *ngIf="!address.address1 && !address.address2">
                                <i *ngIf="address.city || address.state || address.zipcode" class="bi bi-house-fill"></i>
                                <span *ngIf="address.city">
                                    {{address.city}},
                                </span>
                                <span *ngIf="address.state">
                                    {{address.state}},
                                </span>
                                <span>
                                    {{address.zipcode}}
                                </span>
                            </div>

                            <div style="margin-left: 22px" *ngIf="address.address2 || address.address1">

                                <span *ngIf="address.city">
                                    {{address.city}},
                                </span>
                                <span *ngIf="address.state">
                                    {{address.state}},
                                </span>
                                <span>
                                    {{address.zipcode}}
                                </span>
                            </div>
                        </div>
                        <div style="text-align: left; width: 300px" class="mt-2 ms-2">



                            <div *ngIf="address.cellPhone">
                                <i class="bi bi-telephone-fill"></i>
                                <span class="ms-1">
                                    {{address.cellPhone}}
                                </span>
                            </div>

                            <div style="margin-left: 24px" *ngIf="address.workPhone && address.cellPhone">
                                <span>
                                    {{address.workPhone}}
                                </span>
                            </div>

                            <div *ngIf="!address.cellPhone && address.workPhone">
                                <i class="bi bi-telephone-fill"></i>
                                <span class="ms-1">
                                    {{address.workPhone}}
                                </span>
                            </div>

                            <div style="margin-left: 24px" *ngIf="address.homePhone">
                                <span>
                                    {{address.homePhone}}
                                </span>
                            </div>

                            <div *ngIf="address.homePhone && !address.cellPhone && !address.workPhone">
                                <i class="bi bi-telephone-fill"></i>
                                <span class="ms-1">
                                    {{address.homePhone}}
                                </span>
                            </div>

                            <div class="mt-2" *ngIf="address.email">
                                <i class="bi bi-envelope-fill"></i>
                                <span style="margin-left:3px">
                                    {{address.email}}
                                </span>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
             <button class="btn btn-md" style="float:right; color: var(--bs-danger);" (click)="deleteMultiAddress($event,idx)"><i  class="bi bi-trash"></i></button>
            
        </article>
    </ng-template>



    <article class="m-2">
        <button type="button" class="btn btn-outline-primary" (click)="addAddress()">
            <div class="add-address"><i class="fa fa-plus"></i> New Address</div>
        </button>
    </article>
</div>

<slick-confirm-dialog #deleteAddressConfirmRef>
    Are you sure you want to delete this address?
</slick-confirm-dialog>

<slick-dialog #addAddressRef header="Address Edit">
    <div *ngIf="multiAddressModel" class="me-3 ms-3">
        <div class="d-flex row form-group">
            <div class="col-6">
                <label>Description</label>
                <input class="form-control"
                       [slick-validation-indicator]="multiAddressModel.description || multiAddressModel.companyName || multiAddressModel.lastName"
                       slickValidationType="warning"
                       [(ngModel)]="multiAddressModel.description"
                       [ngClass]="{'error-input': !multiAddressModel.lastName && !multiAddressModel.companyName && !multiAddressModel.description && isSubmitted}" />
            </div>

            <div class="col-6">
                <label>Company Name</label>
                <input class="form-control"
                       [slick-validation-indicator]="multiAddressModel.description || multiAddressModel.companyName || multiAddressModel.lastName"
                       slickValidationType="warning"
                       [(ngModel)]="multiAddressModel.companyName"
                       [ngClass]="{'error-input': !multiAddressModel.lastName && !multiAddressModel.companyName && !multiAddressModel.description && isSubmitted}" />
            </div>
        </div>

        <div class="d-flex row form-group">
            <div class="col-6">
                <label>First Name</label>
                <input class="form-control"
                       [(ngModel)]="multiAddressModel.firstName" />
            </div>

            <div class="col-6">
                <label>Last Name</label>
                <input class="form-control"
                       [slick-validation-indicator]="multiAddressModel.description || multiAddressModel.companyName || multiAddressModel.lastName"
                       slickValidationType="warning"
                       [(ngModel)]="multiAddressModel.lastName"
                       [ngClass]="{'error-input': !multiAddressModel.lastName && !multiAddressModel.companyName && !multiAddressModel.description && isSubmitted}" />
            </div>

        </div>
        <div class="mb-2" *ngIf="isSubmitted && !isValid">
            <form-error >Description, Company Name, or Last Name is required</form-error>
        </div>

        <div class="d-flex row form-group">
            <div class="col-12">
                <label>Address Search</label>
                <input type="search"
                       class="form-control"
                       name="addressSearch"
                       [slick-google-address-search]
                       (onGoogleAddressSelect)="onGoogleMapAddressSelect($event)" />
            </div>
        </div>

        <div class="d-flex row form-group">
            <div class="col-12">
                <label>Address 1</label>
                <input class="form-control"
                       [(ngModel)]="multiAddressModel.address1" />
            </div>
        </div>

        <div class="d-flex row form-group">
            <div class="col-12">
                <label>Address 2</label>
                <input class="form-control"
                       [(ngModel)]="multiAddressModel.address2" />
            </div>
        </div>

        <div class="d-flex row form-group">
            <div class="col-12 col-lg-6 col-xl">
                <label>City</label>
                <input class="form-control"
                       [(ngModel)]="multiAddressModel.city" />
            </div>

            <div class="col-12 col-lg-6 col-xl-2">
                <div *ngIf="customerModel.country !== 'Canada'">
                    <label>State</label>
                    <input class="form-control"
                           [(ngModel)]="multiAddressModel.state" />
                </div>

                <div *ngIf="customerModel.country === 'Canada'">
                    <label>Province</label>
                    <input class="form-control"
                           [(ngModel)]="multiAddressModel.state" />
                </div>
            </div>

            <div class="col-12 col-lg-6 col-xl-2">
                <label>Zipcode</label>
                <input class="form-control"
                       [(ngModel)]="multiAddressModel.zipcode" />
            </div>

            <div class="col-12 col-lg-6 col-xl-3">
                <label>Country</label>
                <slick-drop-down [(ngModel)]="multiAddressModel.country"
                                 [items]="countries"></slick-drop-down>
            </div>
        </div>

        <div class="d-flex row form-group">
            <div class="col-4">
                <label>Cell Phone</label>
                <input class="form-control" [(ngModel)]="multiAddressModel.cellPhone" />
            </div>

            <div class="col-4">
                <label>Home Phone</label>
                <input class="form-control" [(ngModel)]="multiAddressModel.homePhone" />
            </div>

            <div class="col-4">
                <label>Work Phone</label>
                <input class="form-control" [(ngModel)]="multiAddressModel.workPhone" />
            </div>

        </div>

        <div class="d-flex row form-group">
            <div class="col-12">
                <label>Email</label>
                <input class="form-control"
                       [(ngModel)]="multiAddressModel.email" />
                <form-error *ngIf="!emailIsValid && isSubmitted">Invalid email format</form-error>

            </div>

        </div>
    </div>

    <slick-dialog-footer>
        <div>
            <button class="save-button btn btn-outline-success me-3"
                    (click)="saveAddress()">
                <i class="bi bi-check-circle"></i> Save
            </button>
            <button class="cancel-button btn btn-outline-danger"
                    (click)="cancelAddAddress()">
                <i class="bi bi-x-circle"></i> Cancel
            </button>
        </div>
    </slick-dialog-footer>
</slick-dialog>
