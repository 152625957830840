import { Component, ViewChild} from "@angular/core";
import { SlickDialogComponent } from "@slick-components";
import { CustomerModel, ICustomerModel } from "@models";

@Component({
	selector: "conflicting-customers-dialog",
	templateUrl: "./conflicting-customers-dialog.component.html"
})

export class ConflictingCustomerDialogComponent {
	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;

	originalText: string;
	conflictingCustomers: ICustomerModel[];


	resolve: any;

	constructor() {
	}

	async showDialog(conflictingCustomers: ICustomerModel[]): Promise<number> {
		return new Promise<number>((resolve) => {
			this.resolve = resolve;
			this.conflictingCustomers = conflictingCustomers;
			this.dialogRef.showDialog();
		})
	}


	continue() {
		this.resolve(0);
		this.dialogRef.hideDialog();
	}

	onCustomerClicked(customerId: number) {
		this.resolve(customerId);
		this.dialogRef.hideDialog();
	}

	cancel() {
		this.resolve(null)
		this.dialogRef.hideDialog();
	}
}