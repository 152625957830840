import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { LookupService } from "./utils/lookup.service";
import { GlobalsService } from "./utils/globals.service";
import {
	IChatGroupModel, ChatGroupModel, IUserInfoModel, IChatMessageModel,
	INewGroupModel, INewMessageModel, INewAttendantModel, NewAttendantModel, IDocumentModel, ChatMessageModel
} from "@models";
import * as moment from 'moment';
import * as _ from 'lodash';
import { UtilsService } from "./utils/utils.service";


class DailyMessages {
	chatMessages: IChatMessageModel[] = [];
	dateString: string;
	constructor() { }
}



@Injectable()
export class ChatService {

	constructor(private readonly httpService: HttpService, private readonly lookupService: LookupService) { }

	async getConversations(): Promise<IChatGroupModel[]> {
		const chatGroups = await this.httpService.get("/chat/getConversations");
		chatGroups.forEach(g => g.attendantsString = this.getAttendantsString(g));

		return chatGroups;
	}

	async getChatGroup(chatGroupId: number): Promise<IChatGroupModel> {
		const chatGroup = await this.httpService.get(`/chat/getChatGroup?chatGroupId=${chatGroupId}`);
		if (chatGroup) {
			if (chatGroup.groupName)
				chatGroup.attendantsString = chatGroup.groupName;
			else {
				let allUserAttendant = chatGroup.attendants.find(x => x.isAllUsers)
				chatGroup.isAllUsers = true;
				if (allUserAttendant) {
					allUserAttendant.profilePictureUrl = GlobalsService.company.logoUrl;
					chatGroup.groupName = "All Users";
				}
				else {
					chatGroup.groupName = this.getAttendantsString(chatGroup);
				}
			}
		}
		return chatGroup;
    }

	async getChatMessages(groupId: number, take: number, skip: number): Promise<IChatMessageModel[]> {
		return await this.httpService.get("/chat/getChatMessages", { groupId: groupId, take: take, skip: skip });
	}

	async sendMessage(newMessage: INewMessageModel): Promise<IChatMessageModel> {
		return await this.httpService.post("/chat/sendMessage", newMessage);
	}

	async createNewGroup(newGroup: INewGroupModel): Promise<IChatGroupModel> {
		const chatGroup: IChatGroupModel = await this.httpService.post("/chat/createNewGroup", newGroup);
		if (chatGroup.groupName)
			chatGroup.attendantsString = chatGroup.groupName;
		else {
			let allUserAttendant = chatGroup.attendants.find(x => x.isAllUsers)
			if (allUserAttendant) {
				allUserAttendant.profilePictureUrl = GlobalsService.company.logoUrl;
				chatGroup.groupName = "All Users";
			}
			else {
				chatGroup.groupName = this.getAttendantsString(chatGroup);
			}
		}
        
		return chatGroup;
	}

	async updateGroupAttendants(chatGroupId: number, attendants: INewAttendantModel[]): Promise<IChatGroupModel> {
		const chatGroup = await this.httpService.post(`/chat/updateGroupAttendants?chatGroupId=${chatGroupId}`, attendants);

		chatGroup.attendantsString = this.getAttendantsString(chatGroup);
		return chatGroup;
	}

	async flagAsRead(groupId: number): Promise<boolean> {
		return await this.httpService.post("/chat/flagAsRead", groupId);
	}

	getAttendantsString(chatGroup: IChatGroupModel): string {
		const otherAttendantNames = chatGroup.attendants
			.filter(x => x.userId !== GlobalsService.userInfo.userId)
			.slice(0, 3)
			.map(x => {
				if (x.teamId) {
					x.profilePictureUrl = GlobalsService.company.logoUrl;
					return this.lookupService.getTeams().find(t => t.teamId === x.teamId)?.teamName ?? "";

				}
				else {
					if (chatGroup.attendants.filter(x => x.userId !== GlobalsService.userInfo.userId).length > 3)
						return x.fullName.split(' ').slice(0, -1).join(' ');
					else if (chatGroup.attendants.filter(x => x.userId !== GlobalsService.userInfo.userId).length > 1)
						return x.fullName.split(' ').slice(0, -1).join(' ');
					else
						return x.fullName;

				}
			});

		if (chatGroup.attendants.filter(x => x.userId !== GlobalsService.userInfo.userId).length > 3) {
			return `${otherAttendantNames.join(", ")}, + ${chatGroup.attendants.filter(x => x.userId !== GlobalsService.userInfo.userId).length - 3}`;
        }
        else
            return otherAttendantNames.join(', ');
	}

	generateLongAttendantString(chatGroup: IChatGroupModel): string {
	const otherAttendantNames = chatGroup.attendants
		.filter(x => x.userId !== GlobalsService.userInfo.userId)
		.map(x => {
			if (x.teamId) {
				x.profilePictureUrl = GlobalsService.company.logoUrl;
				return this.lookupService.getTeams().find(t => t.teamId === x.teamId)?.teamName ?? "";

			}
			else {
					return x.fullName;
			}
		});
		return otherAttendantNames.join(', ');
}


	async getExistingChat(checkedUserAttendants: INewAttendantModel[]): Promise<IChatGroupModel> {
		const chatGroup = await this.httpService.post('/chat/getExistingChat', checkedUserAttendants);
		if (chatGroup) {
			if (chatGroup.groupName)
				chatGroup.attendantsString = chatGroup.groupName;
			else {
				let allUserAttendant = chatGroup.attendants.find(x => x.isAllUsers)
				if (allUserAttendant) {
					allUserAttendant.profilePictureUrl = GlobalsService.company.logoUrl;
					chatGroup.groupName = "All Users";
				}
				else {
					chatGroup.groupName = this.getAttendantsString(chatGroup);
				}
			}
		}
		return chatGroup;
	}

	async saveChatGroupName(groupName: string, groupId: number) {
		await this.httpService.get(`/chat/saveChatGroupName?groupId=${groupId}&groupName=${groupName}`);
	}

	async deleteGroup(groupId: number, userId: number) {
		await this.httpService.get(`/chat/deleteGroup?groupId=${groupId}&userId=${userId}`);
	}

	calcLastSentMessageString(chatGroupLastSentMessage: IChatMessageModel): string {
		if (chatGroupLastSentMessage) {
			let messageTime = moment(chatGroupLastSentMessage.sentOn);
			if (messageTime.add(1, 'minute') > moment()) {
				return "Now";
			}

			else if (messageTime.add(1, 'hour') > moment()) {
				return `${Math.trunc(moment.duration(moment().diff(chatGroupLastSentMessage.sentOn)).asMinutes())} min`;
			}

			else if (messageTime.add(23, 'hour') > moment()) {
				return `${moment(chatGroupLastSentMessage.sentOn).format('h:mm a')}`;
			}

			else {
				return `${moment(chatGroupLastSentMessage.sentOn).format('MMM D')}`;
			}
		}
	}

	buildChatDays(chatMessages: IChatMessageModel[]): DailyMessages[] {
		let dailyMessagesList = [];
		const getDay = item => moment(item.sentOn, 'YYYY-MM-DD').startOf("day").format('dddd, MMMM D');

		const result = _.groupBy(chatMessages, getDay);

		for (var key in result) {
			let dailyMessages = new DailyMessages();
			dailyMessages.dateString = key;
			dailyMessages.chatMessages = result[key];
			dailyMessagesList.push(dailyMessages);
        }
		
		return dailyMessagesList;
	}

	async updateChatGroupUserLastRead(userId: number, groupId: number) {
		await this.httpService.get(`/chat/updateChatGroupUserLastRead?userId=${userId}&groupId=${groupId}`);
	}

	async toggleMute(groupId: number) {
		await this.httpService.get(`/chat/toggleChatMute?groupId=${groupId}`)
	}

}
