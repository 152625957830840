export interface ITextChatAttendantModel {
    userId: number;
    userFullName: string;
    profilePictureUrl: string;
}


export class TextChatAttendantModel implements ITextChatAttendantModel {
    userId: number;
    userFullName: string;
    profilePictureUrl: string;
}
