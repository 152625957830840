import { Injectable } from '@angular/core';
import { GlobalsService, HttpService, NavPageService } from '@services';
import { DevTraceService } from '@services/utils/dev-trace.service';
import { DevLogsStore } from '@stores';

import { Platform } from "@ionic/angular";

import {
	ActionPerformed,
	PushNotificationSchema,
	PushNotifications,
	Token,
} from '@capacitor/push-notifications';

@Injectable()
export class PushNotificationStore {
	constructor(private httpService: HttpService,
		private devTrace: DevTraceService,
		private devLogStore: DevLogsStore,
		private platform: Platform,
		private navPageService: NavPageService) {

	}

	async init() {
		if (GlobalsService.isDebug) {
			this.devLogStore.addMessage(`Push Notification Exit 0 for ${GlobalsService.userInfo.fullName}`);
			await this.devTrace.addTrace(`Push Notification Exit 0 for ${GlobalsService.userInfo.fullName}`);
			return;
		}

		// iOS simulator can't handle push notifications
		if (GlobalsService.isDebug && GlobalsService.isiOS) {
			this.devLogStore.addMessage(`Push Notification Exit 1 for ${GlobalsService.userInfo.fullName}`);
			await this.devTrace.addTrace(`Push Notification Exit 1 for ${GlobalsService.userInfo.fullName}`);
			return;
		}

		// If we're in staging or rc, return
		if (GlobalsService.isStaging || GlobalsService.isRC) {
			this.devLogStore.addMessage(`Push Notification Exit 2 for ${GlobalsService.userInfo.fullName}`);
			await this.devTrace.addTrace(`Push Notification Exit 2 for ${GlobalsService.userInfo.fullName}`);
			return;
		}

		// Request permission to use push notifications
		// iOS will prompt user and return if they granted permission or not
		// Android will just grant without prompting
		PushNotifications.requestPermissions().then(result => {
			this.devLogStore.addMessage(`requestPermissions: ${JSON.stringify(result)}`);

			if (result.receive === 'granted') {

				// Register with Apple / Google to receive push via APNS/FCM
				try {
					PushNotifications.register();
				}
				catch (err) {
					alert((<Error>err).message);
					this.devLogStore.addMessage(`requestPermissions err: ${(<Error>err).message}`);
				}
			} else {
				// Show some error
			}
		});

		PushNotifications.addListener('registration', async (token: Token) => {
			this.devLogStore.addMessage(`registration: ${token}`);

			GlobalsService.pushNotificationToken = token.value;
			await this.httpService.put(`pushNotifications/mapUser?userId=${GlobalsService.userInfo.userId}&token=${token.value}`);

			await this.httpService.put(`pushNotifications/updateSignedIn?token=${GlobalsService.pushNotificationToken}&signedIn=${true}`);

			this.platform.resume.subscribe(async () => {
				await this.httpService.put(`pushNotifications/updateSignedIn?token=${GlobalsService.pushNotificationToken}&signedIn=${true}`);

			});

			this.platform.pause.subscribe(async () => {
				await this.httpService.put(`pushNotifications/updateSignedIn?token=${GlobalsService.pushNotificationToken}&signedIn=${false}`);
			});
		});

		PushNotifications.addListener('registrationError', async (error: any) => {
			await this.devTrace.addTrace(`Push Notification registration error for user ${GlobalsService.userInfo.fullName}`);
			await this.devTrace.addTrace(`Push Notification registration error: ${JSON.stringify(error)}`);
			alert('Error on registration: ' + JSON.stringify(error));
		});

		PushNotifications.addListener(
			'pushNotificationReceived',
			async (notification: PushNotificationSchema) => {
			},
		);

		PushNotifications.addListener(
			'pushNotificationActionPerformed',
			async (notification: ActionPerformed) => {
				if (notification.notification.data.ConversationId)
					this.navPageService.push(`text-chat-messages/${notification.notification.data.ConversationId}`);

				if (notification.notification.data.ChatGroupId)
					this.navPageService.push(`skedit-chat/${notification.notification.data.ChatGroupId}`);
			}
		);

	}


	//testPush() {
	//	const notification: any = { "id": "0:1669828732993393%83005a0183005a01", "data": { "ChatGroupId": "344" }, "title": "New Chat", "body": "Test4\n" };
	//	if (notification.data.ConversationId)
	//		this.navPageService.push(`text-chat-messages/${notification.notification.data.ConversationId}`);

	//	if (notification.data.ChatGroupId)
	//		this.navPageService.push(`skedit-chat/${notification.data.ChatGroupId}`);

	//}
}
