import { IDropdownModel } from "..";
import { IChecklistQuestionLocationModel } from "./checklist-question-location.model";

export interface IChecklistQuestionModel {
	checklistQuestionId: number;
	appointmentTypeId: number;
	locations: number[];
	question: string;
	sortOrder: string;
	active: boolean;
	checklistStatus: string;

	checklistQuestionLocations: IChecklistQuestionLocationModel[];
}

export class ChecklistQuestionModel implements IChecklistQuestionModel {
	constructor() {
		this.checklistQuestionId = 0;
		this.active = true;
		this.checklistStatus = "Complete";
	}

	checklistQuestionId: number;
	appointmentTypeId: number;
	locations: number[];
	question: string;
	sortOrder: string;
	active: boolean;
	checklistStatus: string;

	checklistQuestionLocations: IChecklistQuestionLocationModel[];
}