import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SlickDialogModule } from "@slick-components";
import { MissedClockInDialogComponent } from "./missed-clock-in-dialog.component";


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SlickDialogModule
	],
	declarations: [MissedClockInDialogComponent],
	exports: [MissedClockInDialogComponent]
})
export class MissedClockInDialogModule{ }
