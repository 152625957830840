import {CustomersAuthService} from '@services/utils/auth-services/customers-auth.service';
import {Component, EventEmitter, Input, OnChanges, Output, ViewChild} from "@angular/core";
import {CustomersService, GlobalsService, SleepService, LookupService} from "@services";
import {ICustomerModel, ICustomCustomerFieldModel, CustomerModel} from "@models";
import {CustomerEditLayoutComponent} from './customer-edit-layout/customer-edit-layout.component';

@Component({
    selector: "customer-edit",
    templateUrl: "customer-edit.component.html",
    styleUrls: ['customer-edit.component.scss'],
    providers: [CustomersAuthService]
})

export class CustomerEditComponent implements OnChanges {
    @Input() customerModel: ICustomerModel;
    @Input() isDialog: boolean = false;
    @Output() onCustomerSaved = new EventEmitter<ICustomerModel>();
    @Output() onCustomerCancel = new EventEmitter<void>();
    @Output() onCustomerLoaded : EventEmitter<ICustomerModel> = new EventEmitter<ICustomerModel>();

    @ViewChild("customerEditLayoutRef") customerEditLayoutRef: CustomerEditLayoutComponent;
    skeditPay: boolean = GlobalsService.company.useSkeditPay;
    customers: ICustomerModel[];
    visibleItems: any[];
    spinnerStatus: string;
    agreementspinnerStatus: string;
    tabKey: string;
    flagNonSignedServiceAgreement: boolean;
    useLathamOrdering = GlobalsService.company.useLathamOrdering;
    showAdditionalAddresses: boolean = GlobalsService.company.customerAdditionalAddresses;
    customCustomerFields: ICustomCustomerFieldModel[];
    usingCreditCards = GlobalsService.company.useUSAePay ||
        GlobalsService.company.useBambora ||
        GlobalsService.company.useZift ||
        GlobalsService.company.usePayTrace;

    tabIndex: number = 0;
    constructor(private customersService: CustomersService,
        private lookupService: LookupService,
        public customersAuthService: CustomersAuthService) {
        this.flagNonSignedServiceAgreement = GlobalsService.company.flagNonSignedServiceAgreement;
        this.customCustomerFields = this.lookupService.getCustomCustomerFields();
    }

    ngOnChanges() {
        if (this.customerEditLayoutRef)
            this.customerEditLayoutRef.isSubmitted = false;

        this.tabIndex = 0;
    }

    onTabChanged(tabKey: string) {
        this.tabKey = tabKey;
    }

    clearSubmitted() {
        if (this.customerEditLayoutRef)
            this.customerEditLayoutRef.isSubmitted = false;
        ;
    }

    setSubmitted() {
        if (this.customerEditLayoutRef)
            this.customerEditLayoutRef.isSubmitted = true;
    }

    isValid(): boolean {
        let isValid = true;
        if (this.customerEditLayoutRef.isValid() === false)
            isValid = false;

        return isValid;
    }

    async saveCustomer() {
        this.setSubmitted();
        if (this.isValid() === false) {
            this.spinnerStatus = "error";
            return;
        }

        try {
            this.spinnerStatus = "spin";
            await SleepService.sleep(500);

            if (this.customerModel.linkBillingAddress) {
                this.customerModel.billingAddress1 = this.customerModel.address1;
                this.customerModel.billingAddress2 = this.customerModel.address2;
                this.customerModel.billingCity = this.customerModel.city;
                this.customerModel.billingState = this.customerModel.state;
                this.customerModel.billingCountry = this.customerModel.country;
                this.customerModel.billingZipcode = this.customerModel.zipcode;
            }

            if (this.customerModel.linkShippingAddress) {
                this.customerModel.shippingAddress1 = this.customerModel.address1;
                this.customerModel.shippingAddress2 = this.customerModel.address2;
                this.customerModel.shippingCity = this.customerModel.city;
                this.customerModel.shippingState = this.customerModel.state;
                this.customerModel.shippingCountry = this.customerModel.country;
                this.customerModel.shippingZipcode = this.customerModel.zipcode;
            }

            if (this.customerModel.discounts)
                this.customerModel.discounts = this.customerModel.discounts.filter(x => x.discountId !== 0);

            if (this.customerModel.rebates)
                this.customerModel.rebates = this.customerModel.rebates.filter(x => x.rebateId !== 0);

            if (!this.customerModel.customerId) {
                this.customerModel = await this.customersService.addCustomer(this.customerModel);

                if (GlobalsService.company.autoSendServiceAgreement && this.customerModel.email) {
                    await this.customersService.sendServiceAgreement(this.customerModel.customerId);
				}
            }
            else
                this.customerModel = await this.customersService.updateCustomer(this.customerModel);
            this.onCustomerSaved.emit(this.customerModel);
            this.spinnerStatus = "ok";
        } catch (err) {
            this.spinnerStatus = "error";
        }

    }

    async sendServiceAgreement() {
        this.agreementspinnerStatus = "spin";
        await SleepService.sleep(500);

        try {
            await this.customersService.sendServiceAgreement(this.customerModel.customerId);
            this.agreementspinnerStatus = "ok";
        } catch (err) {
            this.agreementspinnerStatus = "error";
        }
    }

    cancelCustomer() {
        this.customerModel = null;
    }

    async existingCustomerSelected(customerId: number) {
        this.customerModel = await this.customersService.getCustomer(customerId);
        this.onCustomerLoaded.emit(this.customerModel);
    }
}
