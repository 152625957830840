import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SlickMultiSelectComponent } from "./slick-multi-select.component";
import { SlickMultiSelectService } from "./slick-multi-select.service";

@NgModule({
	imports: [CommonModule, FormsModule],
	declarations: [SlickMultiSelectComponent],
	exports: [SlickMultiSelectComponent]
})
export class SlickMultiSelectModule { }

export { SlickMultiSelectComponent }