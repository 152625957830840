import { QuickbooksAuthService } from './utils/auth-services/quickbooks-auth.service';
import { AppointmentsAuthService } from './utils/auth-services/appointments-auth.service';
import { NgModule } from "@angular/core";
import { AuthService } from "./utils/auth.service";
import { ConcurrencyValidationService, ConcurrencyRecordTypes } from "./utils/concurrency-validation.service";
import { FunctionLockService } from "./utils/function-lock.service";
import { GlobalsService } from "./utils/globals.service";
import { HttpService } from "./utils/http.service";
import { LookupService } from "./utils/lookup.service";
import { SleepService } from "./utils/sleep.service";
import { UtilsService } from "./utils/utils.service";

import { AcuityService } from "./acuity.service";
import { QuickbooksService } from "./quickbooks.service";
import { UsersService } from "./users.service";
import { ScheduleStore } from "./schedule.store";
import {ScheduleService} from "@services/schedule.service";
import {CrewsService} from "@services/crews.service";
import {AppointmentsService} from "@services/appointments.service";
import { ChatService } from "@services/chat.service";
@NgModule({
	providers: [HttpService, LookupService, FunctionLockService, AuthService, ConcurrencyValidationService, QuickbooksService, UsersService, ScheduleStore, ScheduleService, CrewsService, AppointmentsService, ChatService]
})
export class ServicesModule { }

export { AuthService }
export { ConcurrencyValidationService, ConcurrencyRecordTypes }
export { FunctionLockService }
export { GlobalsService }
export { HttpService }
export { LookupService }
export { SleepService }
export { UtilsService }

// Credit card processing
export { ICreditCardService, CreditCardService } from "./credit-card-processing/credit-card.service";

export { AcuityService } from "./acuity.service";
export { AppointmentsService } from "./appointments.service";
export { ChecklistService } from "./checklist.service";
export { CustomersService } from "./customers.service";
export { CrewsService } from "./crews.service";
export { CompanyService } from "./company.service";
export { DocumentsService, DocumentTypes } from "./documents.service";
export { InventoryService } from "./inventory.service";
export { ItemsService } from "./items.service";
export { LineItemsService } from "./line-items.service";
export { JobsService } from "./jobs.service";
export { LoginService } from "./login.service";
export { MaintenanceHistoryService } from "./maintenance-history.service";
export { NotificationsService } from "./notifications.service";
export { PriceLevelService } from "./price-levels.service";
export { PurchaseOrdersService } from "./purchase-orders.service";
export { QuickbooksService } from "./quickbooks.service";
export { ReceivingService } from "./receiving.service";
export { ServiceTechAppointmentsService } from "./service-tech-appointments.service";
export { UsersService } from "./users.service";
export { LathamOrdersService } from "./latham-orders.service";
export { LathamOrdersPickListSpecsService } from "./latham-order-pick-list-specs.service";
export { LathamOrderPickListEngineService } from "./latham-order-pick-list-engine.service";
export { LathamOrderValidationService } from "./latham-order-validation.service";
export { LathamOrderSystemLineItemsService } from "./latham-order-system-line-items.service";
export { LathamOrderHelpersService } from "./latham-order-helpers.service";
export { LathamOrderStore } from "./latham-order.store";
export { ScheduleService } from "./schedule.service";
export { ScheduleDisplayService } from "./schedule-display.service";
export { ScheduleMouseEventsService } from "./schedule-mouse-events.service";
export { ScheduleStore } from "./schedule.store";
export { SmartlistService } from "./smartlist.service";
export { ChatService } from "./chat.service";
export { RecurringAppointmentsService } from "./recurring-appointments.service";
export { AppointmentUserStrikesService } from "./appointment-user-strikes.service";
export { PaymentsService } from "./payments.service";
export { InvoicesService } from "./invoices.service";
export { VisibleResourceService } from "./visible-resource.service";
export { VendorsService } from "./vendors.service";
