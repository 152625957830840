export interface ISavedShippingAddressModel {
    savedShippingAddressId: number;
    addressId: number;
    distributorId?: number;
    builderId?: number;
    firstName: string;
    lastName: string;
    companyName: string;
    displayName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    active: boolean
}

export class SavedShippingAddressModel implements ISavedShippingAddressModel {
    savedShippingAddressId: number;
    addressId: number;
    distributorId?: number;
    builderId?: number;
    firstName: string;
    lastName: string;
    companyName: string;
    displayName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    active: boolean
}