export interface ISelectedLocationModel {
    locationId: number;
    locationName: string;
    checked: boolean;
}

export class SelectedLocationModel implements ISelectedLocationModel {
    locationId: number;
    locationName: string;
    checked: boolean;
}