import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {IVisibleResourceModel} from "@models/resources/visible-resource.model";
import {IBlockedTimeSlotModel, IDailyCrewModel, IResourceModel, IScheduledAppointmentModel} from "@models";
import * as moment from 'moment';
import {ScheduleStore} from "@services/schedule.store";
import {CrewsService} from "@services/crews.service";
import {ScheduleService} from "@services/schedule.service";
import {AppointmentsService} from "@services/appointments.service";
import {LookupService} from "@services/utils/lookup.service";

@Injectable()
export class VisibleResourceService {

    onVisibleResourcesChanged: EventEmitter<boolean>;

    private _visibleResources: IVisibleResourceModel[] = [];

    constructor(private scheduleStore: ScheduleStore,
        private scheduleService: ScheduleService,
        private appointmentsService: AppointmentsService,
        private crewsService: CrewsService,
        private lookupService: LookupService) {
        this.onVisibleResourcesChanged = new EventEmitter();
    }

    async reloadVisibleResources() {
        this._visibleResources = await this.getVisibleResources();
    }

    async getVisibleResources() {
        const startDate = this.scheduleStore.currentDate;
        const endDate = moment(this.scheduleStore.currentDate)
            .add(1, 'days')
            .toDate();
        const appointmentsPromise: Promise<IScheduledAppointmentModel[]> = this.appointmentsService.getScheduledAppointmentsForDateRange(startDate, endDate);
        const blockedTimeSlotsPromise: Promise<IBlockedTimeSlotModel[]> = this.appointmentsService.getBlockedTimeSlotsForDateRange(startDate, endDate);
        const dailyCrewsPromise: Promise<IDailyCrewModel[]> = this.crewsService.getDailyCrewsForDate(this.scheduleStore.currentDate);
        return await Promise.all([appointmentsPromise, blockedTimeSlotsPromise, dailyCrewsPromise]).then(values => {
            this.scheduleStore.appointments = <IScheduledAppointmentModel[]>values[0];
            this.scheduleStore.blockedTimeSlots = <IBlockedTimeSlotModel[]>values[1];
            const dailyCrews = <IDailyCrewModel[]>values[2];
            const  visibleResourcesReturn = this.scheduleService.getVisibleResources(this.scheduleStore.appointments, this.scheduleStore.blockedTimeSlots);//.filter(resource => this.locationsService.selectedLocationsContains(resource.locationId, this.lookupService.getUsers().find(x => x.userId === GlobalsService.userInfo.userId)));
            visibleResourcesReturn.forEach(async r => {
                if (this.visibleResources.find(vr => vr.resource.resourceId === r.resourceId)) {
                    this.getVisibleResourceModel(r.resourceId).shown = this.resourceIsShown(r.resourceId);
                } else {
                    this._visibleResources.push({
                        resource: r,
                        shown: true
                    });
                }
            });
            dailyCrews.forEach(c => {
                const resource = this._visibleResources.find(r => r.resource.resourceId === c.resourceId);
                if (resource) {
                    resource.resource.crewMembersDisplay = c.crewMembersDisplay;
                }
            });
            return this.visibleResources;
        });
    }

    visibleResourcesContains(resourceId): boolean {
        let rVal: boolean = false;
        if (this.visibleResources.find(vr => vr.resource.resourceId === resourceId)) {
            rVal = true;
        }
        return rVal;
    }

    resourceIsShown(resourceId: number): boolean {
        return this.getVisibleResourceModel(resourceId).shown;
    }

    showResource(resourceId: number) {
        this.getVisibleResourceModel(resourceId).shown = true;
    }

    hideResource(resourceId: number) {
        this.getVisibleResourceModel(resourceId).shown = false;
    }

    setResourceShownByBoolean(resourceId: number, show: boolean) {
        this.getVisibleResourceModel(resourceId).shown = show;
    }

    private getResourceModel(resourceId: number): IResourceModel {
        let rVal: IResourceModel = null;
        this.visibleResources.forEach(res => {
            if (res.resource.resourceId === resourceId) {
                rVal = res.resource;
            }
        });

        return rVal;
    }

    private getVisibleResourceModel(resourceId: number): IVisibleResourceModel {
        let rVal: IVisibleResourceModel = null;
        this.visibleResources.forEach(res => {
            if (res.resource.resourceId === resourceId) {
                rVal = res;
            }
        });

        return rVal;
    }

    get visibleResources() {
        return this._visibleResources;
    }

}
