import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SlickAutoCompleteModule, SlickConfirmDialogModule, SlickContainerModule, SlickEmailerModule, SlickEmailViewerModule, SlickDialogModule, SlickPopoverModule, SlickValidationIndicatorModule, SlickFileDropModule, SlickFileListModule, SlickSearchBarModule, SlickDropDownModule } from "@slick-components";
import { FormErrorComponent } from "./form-error.component";
import { DefaultLayoutComponent } from "./default-layout/default-layout.component";
import { CardItemTemplateDirective } from "./default-layout/card-item-template.directive";
import { ItemSearchComponent } from "./item-search/item-search.component";
import { LayoutBodyComponent } from "./default-layout/layout-body.component";
import { LayoutFooterComponent } from "./default-layout/layout-footer.component";
import { LayoutFiltersComponent } from "./default-layout/layout-filters.component";
import { DocumentViewerComponent } from "./document-viewer.component";
import { DocumentViewerDialogComponent } from "./document-viewer-dialog.component";
//import { SignatureDialogComponent } from "./signature-dialog.component";
//import { SignatureComponent } from "./signature.component";
//import { Angular2SignaturepadModule } from 'angular2-signaturepad';
import { ColorPickerModule } from "ngx-color-picker";
import { ColorPickerDialogComponent } from "./color-picker-dialog.component";
import { ConcurrencyErrorDialogComponent } from "./concurrency-error-dialog.component";
import { UserNameComponent } from "./user-name/user-name.component";
import { QuickAddsComponent } from "./quick-adds/quick-adds.component";
import { QuickbooksIconComponent } from "./quickbooks-icon/quickbooks-icon.component";
import { BusinessCentralIconComponent } from "./business-central-icon/business-central-icon.component";
import { DocumentsEditComponent } from "./documents-edit/documents-edit.component";

import { PermissionDeniedComponent } from "./permission-denied/permission-denied.component";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
	imports: [CommonModule, FormsModule, ColorPickerModule, SlickAutoCompleteModule, SlickConfirmDialogModule, SlickContainerModule, SlickEmailerModule, SlickPopoverModule, SlickEmailViewerModule,
		SlickDialogModule, SlickDropDownModule, SlickValidationIndicatorModule, SlickFileDropModule, SlickFileListModule, DragDropModule, SlickSearchBarModule],
	declarations: [FormErrorComponent, ColorPickerDialogComponent, DefaultLayoutComponent,
		CardItemTemplateDirective, LayoutBodyComponent, LayoutFooterComponent, LayoutFiltersComponent, ItemSearchComponent, DocumentViewerComponent,
		DocumentViewerDialogComponent, ConcurrencyErrorDialogComponent, UserNameComponent,
		QuickAddsComponent, QuickbooksIconComponent, BusinessCentralIconComponent,
		DocumentsEditComponent, PermissionDeniedComponent],
	exports: [FormErrorComponent, ColorPickerDialogComponent, DefaultLayoutComponent,
		CardItemTemplateDirective, LayoutBodyComponent, LayoutFooterComponent, LayoutFiltersComponent, ItemSearchComponent, DocumentViewerComponent,
		DocumentViewerDialogComponent, ConcurrencyErrorDialogComponent, UserNameComponent,
		QuickAddsComponent, QuickbooksIconComponent, BusinessCentralIconComponent,
		DocumentsEditComponent, PermissionDeniedComponent]
})
export class ComponentsModule {
}

export { ColorPickerDialogComponent }
export { DocumentViewerComponent }
export { DocumentViewerDialogComponent }
export { ConcurrencyErrorDialogComponent }
export { UserNameComponent }

