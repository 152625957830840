import { Injectable } from "@angular/core";
import { IInventoryLineItemModel, ILathamOrderModel, ILathamOrderPickListDefinitionConditionModel, ILathamOrderPickListDefinitionModel, ILathamOrderPickListLineItemModel, ILathamOrderPickListModel, ILathamOrderPickListSpecModel, InventoryLineItemModel, LathamOrderPickListLineItemModel } from "@models";
import { UtilsService } from "./utils/utils.service";
import { LathamOrdersPickListStore } from "@stores";
import { HttpService } from "./utils/http.service";
import { SleepService } from "./utils/sleep.service";

@Injectable()
export class LathamOrderPickListEngineService {

	// Note that 
	constructor(private lathamOrdersPickListStore: LathamOrdersPickListStore,
		private httpService: HttpService) {
	}

	async getPickList(lathamOrderId: number): Promise<ILathamOrderPickListModel> {
		return this.httpService.get(`/lathamOrderPickLists/getPickList?lathamOrderId=${lathamOrderId}`);
	}

	async generatePickList(lathamOrder: ILathamOrderModel): Promise<ILathamOrderPickListModel> {
		return this.httpService.post(`/lathamOrderPickLists/generatePickList`, lathamOrder);
	}

	async generatePickListPDF(lathamOrderId: number): Promise<string> {
		return this.httpService.get(`/lathamOrderPickLists/generatePickListPDF?lathamOrderId=${lathamOrderId}`);
	}

	syncInventoryFromPickList(lathamOrderModel: ILathamOrderModel, pickList: ILathamOrderPickListModel): IInventoryLineItemModel[] {
		// check if there is anything to transfer over
		if (!pickList.pickListLineItems || pickList.pickListLineItems.length === 0)
			return [];
		// set custom inventory
		lathamOrderModel.invoice.useDefaultInventory = false;

		const topLevelPickListLineItems: ILathamOrderPickListLineItemModel[] = pickList.pickListLineItems
			.filter(x => (x.childLineItems || []).length == 0 && x.itemInventoryType === 1);

		const childPickListLineItems: ILathamOrderPickListLineItemModel[] = pickList.pickListLineItems
			.filter(x => (x.childLineItems || []).length > 0 && x.itemInventoryType === 1)
			.flatMap(x => x.childLineItems)

		const allPickListLineItems = [...topLevelPickListLineItems, ...childPickListLineItems];

		const returnInventoryLineItems: IInventoryLineItemModel[] = allPickListLineItems
			.map(li => {
				if (li.itemId) {
					let inventoryLineItem = new InventoryLineItemModel();
					inventoryLineItem.uuid = li.uuid;
					inventoryLineItem.itemId = li.itemId;
					inventoryLineItem.sku = li.sku;
					inventoryLineItem.description = li.description;
					inventoryLineItem.quantity = li.quantity;
					inventoryLineItem.cost = li.cost;
					inventoryLineItem.editable = true;
					inventoryLineItem.displayOrder = li.displayOrder || 0;
					if (inventoryLineItem.inventoryWarehouseId !== lathamOrderModel.invoice.inventoryWarehouseId) {
						inventoryLineItem.inventoryWarehouseId = lathamOrderModel.invoice.inventoryWarehouseId;
						inventoryLineItem.inventoryWarehouseName = lathamOrderModel.invoice.inventoryWarehouseName;
						inventoryLineItem.inventoryItemLocationId = null;
						inventoryLineItem.inventoryItemLocationName = null;
					}

					return inventoryLineItem;
				}
				else {
					return null;
				}
			})
			.filter(x => x !== null);

		return returnInventoryLineItems;
	}
}
