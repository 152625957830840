import { Component, ViewEncapsulation, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FunctionLockService, GlobalsService, SleepService, ChatService } from "@services";
import { ChatGroupModel, IChatGroupModel } from '@models';
import { ChatStore } from '@stores';
import { Subscription } from "rxjs";
import { ChatGroupAttendantsComponent } from '../components/chat-group-attendants/chat-group-attendants.component';
import { SlickDialogComponent, SlickToastService } from "@slick-components";

class LegacyChatGroupModel {
	chatGroupId: number;
	isExpanded: boolean;
}

@Component({
	selector: 'legacy-chat',
	templateUrl: './legacy-chat.component.html',
	styleUrls: ['./legacy-chat.component.scss'],
	providers: [FunctionLockService],
	encapsulation: ViewEncapsulation.None
})
export class LegacyChatComponent implements OnInit, OnDestroy {
	@ViewChild("chatGroupAttendantsRef") chatGroupAttendantsRef: ChatGroupAttendantsComponent;
	@ViewChild("editGroupNameRef") editGroupNameRef: SlickDialogComponent;

	isChatGroupListExpanded: boolean = false;
	newChatGroupName: string;
	newChatNameGroupId: number;

	visibleGroups: IChatGroupModel[];

	onNewMessage$: Subscription;
	unreadMessageCountStore$: Subscription;
	chatGroupUpdatedStore$: Subscription;
	unreadChatMessagesCount: number;

	constructor(private readonly chatStore: ChatStore,
		private readonly chatService: ChatService,
		private readonly slickToastService: SlickToastService,
		private readonly functionLockSvc: FunctionLockService) {

	}

	async ngOnInit() {
		this.visibleGroups = [];

		this.onNewMessage$ = this.chatStore.onNewMessageStore.subscribe(async (newMessage) => {
			if (newMessage.senderId === this.chatStore.currentUser.userId)
				return;

			await this.functionLockSvc.lock("LEGACY_CHAT_MESSAGE");

			try {
				// Make sure this chatgroup is up
				let chatGroup = this.visibleGroups.find(x => x.id === newMessage.chatGroupId);
				if (!chatGroup) {
					chatGroup = this.chatStore.chatGroups.find(x => x.id === newMessage.chatGroupId);
					this.visibleGroups.push(chatGroup);
					chatGroup.isExpanded = true;

					this.updateLegacyChatGroups();

					this.chatStore.playNewMessageSound(newMessage);
				}

				this.chatStore.setAllMessagesAsRead(chatGroup.id);
			}
			finally {
				this.functionLockSvc.release("LEGACY_CHAT_MESSAGE");
			}
		})

		this.unreadMessageCountStore$ = this.chatStore.unreadMessageCountStore.subscribe((unreadChatMessagesCount) => {
			this.unreadChatMessagesCount = unreadChatMessagesCount;
		});

		this.chatGroupUpdatedStore$ = this.chatStore.chatGroupUpdatedStore.subscribe((chatGroup) => {
			const chatGroupIdx = this.visibleGroups.findIndex(x => x.id === chatGroup.id);

			if (chatGroupIdx >= 0) {
				chatGroup.isExpanded = this.visibleGroups[chatGroupIdx].isExpanded;
				this.visibleGroups[chatGroupIdx] = chatGroup;
				this.updateLegacyChatGroups();
			}
		});

		this.showSavedGroups();
		this.unreadChatMessagesCount = this.chatStore.unreadMessageCount;
	}

	ngOnDestroy() {
		this.onNewMessage$.unsubscribe();
		this.unreadMessageCountStore$.unsubscribe();
		this.chatGroupUpdatedStore$.unsubscribe();
	}

	async onGroupClicked(chatGroup: IChatGroupModel) {
		this.chatStore.setAllMessagesAsRead(chatGroup.id);
		const existingChatGroup = this.visibleGroups.find(x => x.id === chatGroup.id);
		if (existingChatGroup) {
			existingChatGroup.isExpanded = true;
		}
		else {
			this.visibleGroups.push(chatGroup);
			chatGroup.isExpanded = true;
		}

		this.updateLegacyChatGroups();
	}

	toggleChatGroupExpanded(chatGroup) {
		chatGroup.isExpanded = !chatGroup.isExpanded;
		this.updateLegacyChatGroups();
	}

	async addAttendants(e: MouseEvent, chatGroup) {
		e.preventDefault();
		e.stopPropagation();

		const updatedChatGroup = await this.chatGroupAttendantsRef.showDialog(chatGroup);

		if (updatedChatGroup) {
			const visibleGroupIdx = this.visibleGroups.findIndex(x => x.uuid === updatedChatGroup.uuid);
			if (visibleGroupIdx >= 0)
				this.visibleGroups[visibleGroupIdx] = updatedChatGroup;
			this.updateLegacyChatGroups();
		}
	}

	closeWindow(chatGroup: IChatGroupModel) {
		this.visibleGroups = this.visibleGroups.filter(x => x.uuid !== chatGroup.uuid);
		this.updateLegacyChatGroups();
	}

	private updateLegacyChatGroups() {
		const legacyChatGroups = this.visibleGroups.map(x => {
			const legacyChatGroup = new LegacyChatGroupModel();
			legacyChatGroup.chatGroupId = x.id;
			legacyChatGroup.isExpanded = x.isExpanded;

			return legacyChatGroup;
		})

		localStorage.setItem("CHAT_VISIBLE_LEGACY_GROUPS", JSON.stringify(legacyChatGroups));
	}

	private showSavedGroups() {
		const visibleGroupsStr = localStorage.getItem("CHAT_VISIBLE_LEGACY_GROUPS");
		if (visibleGroupsStr) {
			try {
				const legacyChatGroups = <LegacyChatGroupModel[]>JSON.parse(visibleGroupsStr);

				this.visibleGroups = legacyChatGroups.map(x => {
					const group = this.chatStore.chatGroups.find(y => y.id === x.chatGroupId);
					if (group)
						group.isExpanded = x.isExpanded;

					return group;
				})

				this.visibleGroups = this.visibleGroups.filter(x => x !== undefined);
			}
			catch (err) {
				localStorage.removeItem("CHAT_VISIBLE_LEGACY_GROUPS");
				this.visibleGroups = [];
			}
		}
	}

	async editGroupName(e: MouseEvent, chatGroup: IChatGroupModel) {
		e.preventDefault();
		e.stopPropagation();
		let chatGroupToRename = this.visibleGroups.find(x => x.id == chatGroup.id);
		this.newChatGroupName = chatGroupToRename.groupName;
		this.newChatNameGroupId = chatGroupToRename.id;
		this.editGroupNameRef.showDialog();
	}

	toggleMute(e: MouseEvent, chatGroup: IChatGroupModel) {
		e.preventDefault();
		e.stopPropagation();
		this.chatStore.toggleChatMuted(chatGroup);
    }

	async deleteGroup(e: MouseEvent, chatGroup: IChatGroupModel) {
		e.preventDefault();
		e.stopPropagation();
		await this.chatService.deleteGroup(chatGroup.id, GlobalsService.userInfo.userId);
		this.chatStore.refreshChatListAfterEvent();
		this.visibleGroups = this.visibleGroups.filter(x => x.id !== chatGroup.id);
		this.slickToastService.showSuccess("Chat Deleted", 1000);
	}

	async saveChatGroupName(newGroupName: string) {
		let chatGroupToRename = this.visibleGroups.find(x => x.id == this.newChatNameGroupId);
		await this.chatService.saveChatGroupName(newGroupName, this.newChatNameGroupId);
		chatGroupToRename.groupName = newGroupName;
		this.editGroupNameRef.hideDialog();
		this.chatStore.refreshChatList();
	}

	cancelGroupName() {
		this.editGroupNameRef.hideDialog();
	}
}
