import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { CustomerComponentsModule } from "@app/customers/customer-components";
import { CustomerDisplayModule } from "@components/customer-display";
import { SlickAutoCompleteModule, SlickValidationIndicatorModule,SlickDialogModule } from "@slick-components";

import { CustomerSearchComponent } from "./customer-search.component";

@NgModule({
	imports: [CommonModule,
		FormsModule,
		CustomerComponentsModule,
		CustomerDisplayModule,
		SlickAutoCompleteModule, SlickValidationIndicatorModule, SlickDialogModule
	],
	declarations: [CustomerSearchComponent],
	exports: [CustomerSearchComponent]
})
export class CustomerSearchModule { }

export { CustomerSearchComponent }
