export interface IEmployeePayRateModel {
	employeePayRateId: number;
	userId: number;
	effectiveDate: Date;

	regularPayRate: number;
	overtimePayRate: number;
	holidayPayRate: number;
	editing: boolean;
}

export class EmployeePayRateModel implements IEmployeePayRateModel {
	employeePayRateId: number;
	userId: number;
	effectiveDate: Date;

	regularPayRate: number;
	overtimePayRate: number;
	holidayPayRate: number;
	editing: boolean;
}