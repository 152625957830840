import { Component, ViewChild, forwardRef, ChangeDetectorRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { SlickDialogComponent } from "../slick-dialog/slick-dialog.component";
import { ISlickEmailerModel } from "../slick-emailer/slick-emailer.model";

@Component({
	selector: 'slick-email-viewer-dialog',
	template:
	`<slick-dialog *ngIf='isVisible' header='View Email' [width]='800' #emailViewerDialogRef>
		<slick-email-viewer [(ngModel)]='emailerModel'></slick-email-viewer>

		<slick-dialog-footer>
			<div class="row">
				<div class="col-12 text-center mb-2 mt-1">
					<button type="button" style="width: 120px;" class="btn btn-outline-primary" (click)="onOk()">Ok</button>
				</div>
			</div>
		</slick-dialog-footer>
	</slick-dialog>`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SlickEmailViewerDialogComponent),
			multi: true,
		}]
})
export class SlickEmailViewerDialogComponent {

	@ViewChild("emailViewerDialogRef") emailViewerDialogRef: SlickDialogComponent;

	emailerModel: ISlickEmailerModel;
	isVisible = false;

	constructor(private changeDetector: ChangeDetectorRef) { }

	propagateChange = (_: ISlickEmailerModel) => { };

	// this is the initial value set to the component
	public writeValue(obj: ISlickEmailerModel) {
		this.emailerModel = obj;
	}

	// registers 'fn' that will be fired when changes are made
	// this is how we emit the changes back to the form
	public registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	// not used, used for touch input
	public registerOnTouched() { }

	showDialog() {
		this.isVisible = true;

		this.changeDetector.detectChanges();

		this.emailViewerDialogRef.showDialog();
	}

	onOk() {
		this.isVisible = false;

		this.emailViewerDialogRef.hideDialog();
	}
}
