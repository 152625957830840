import * as moment from 'moment';
import { IBlockedTimeSlotModel } from './blocked-time-slots.model';

export interface IBlockedTimeSlotDimensions {
    isVisible: boolean;
    startPartial: boolean;
    endPartial: boolean;
    topPx: string;
    leftPx: string;
    widthPx: string;
}

export class MiniBlockedTimeSlotDimensions implements IBlockedTimeSlotDimensions {
    isVisible = true;
    startPartial = false;
    endPartial = false;
    topPx: string;
    leftPx: string;
    widthPx: string;

    constructor(resourceRow: HTMLDivElement, miniScheduleTable: HTMLTableElement, blockedTimeSlot: IBlockedTimeSlotModel, visibleStartHour: number, visibleEndHour: number, calcDate: string) {
        visibleEndHour = visibleEndHour === 24 ? visibleEndHour : visibleEndHour + 1;

        let blockStartDate = moment(blockedTimeSlot.blockDateTime);
        const  blockEndDate = moment(blockStartDate).add(blockedTimeSlot.duration, 'minutes');
        const visibleStartDate = moment(blockStartDate)
            .startOf('day')
            .add(visibleStartHour, 'hours');
        const visibleEndDate = moment(blockStartDate)
            .startOf('day')
            .add(visibleEndHour, 'hours');
        let blockStart = moment(blockedTimeSlot.blockDateTime);
        let duration = blockedTimeSlot.duration;

        if (blockEndDate.diff(visibleStartDate, 'minutes') <= 0 || blockStartDate.diff(visibleEndDate, 'minutes') > 0) {
            this.isVisible = false;
            return;
        }

        if (blockStartDate.diff(visibleStartDate, 'minutes') < 0) {
            blockStartDate = moment(visibleStartDate, 'minutes');
            this.startPartial = true;
        }

        if (blockEndDate.diff(visibleEndDate, 'minutes') > 0) {
            this.endPartial = true;
            duration = visibleEndDate.diff(blockStartDate, 'minutes');
        }

        const totalTimeSlot = (visibleEndHour - visibleStartHour) * 4;
        const dataCell = <HTMLDivElement>miniScheduleTable.querySelector(`[data-calcdate="${calcDate}`);
        const top = miniScheduleTable.offsetTop + dataCell.offsetTop + resourceRow.offsetTop;
        this.topPx = top + 'px';

        // adjust the margin and resource column
        const timeSlotWidth = (resourceRow.offsetWidth - 2) / totalTimeSlot;
        const left = (blockStartDate.diff(visibleStartDate, 'minutes') / 15) * timeSlotWidth + dataCell.offsetLeft + 2;
        this.leftPx = left + 'px';
        this.widthPx = (duration / 15) * timeSlotWidth + 'px';
    }

    private findPixelFromTimeSlot(resourceRow: HTMLTableRowElement, time: moment.Moment): number {
        const timeSlotEl = <HTMLTableCellElement>resourceRow.querySelector(`[data-calchour="${time.format('HHmm')}"]`);
        if (!timeSlotEl) {
            // Ok, so the timeslots will only go to n-15 mins for the last time slot.  For example,
            // if it's 8am-5pm, the last time slot will actually be 4:45pm.  If we have an ending time slot of 5:00
            // it won't be found.  Hacky, but take 15 minutes off of the requested time and see if that time slot exists.
            // If it does, return that time sloT + the width.
            time = time.subtract(15, 'minutes');
            const lastTimeSlotEl = <HTMLTableCellElement>resourceRow.querySelector(`[data-calchour="${time.format('HHmm')}"]`);
            if (!lastTimeSlotEl) return 0;

            return lastTimeSlotEl.offsetLeft + lastTimeSlotEl.offsetWidth - 1;
        }

        return timeSlotEl.offsetLeft;
    }
}
