import { Component, Input, HostBinding, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectorRef, Directive, ViewChild, input } from '@angular/core';
import { LineItemsService, UtilsService, FunctionLockService, SleepService, GlobalsService, LookupService, HttpService, ItemsService } from '@services';
import { ILineItemModel, IAppliedPaymentModel, LineItemTypes, LineItemModel, ICustomerModel, ILineItemTotalsModel, IDropdownModel, IInvoiceLaborItemModel, IItemSearchModel, InvoiceModel, ICompanyModel, IAddressModel, IItemModel, IShippingAddressModel, IInvoiceModel } from '@models';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { SlickDialogComponent } from "@slick-components";
import {
	BarcodeScanner
} from '@capacitor-mlkit/barcode-scanning';

@Directive()
export class LineItemsBaseComponent implements OnChanges {
	@Input() lineItems: ILineItemModel[];
	@Output() lineItemsChange: EventEmitter<ILineItemModel[]> = new EventEmitter<ILineItemModel[]>();
	@Input() amountDue: number;
	@Output() amountDueChange: EventEmitter<number> = new EventEmitter();
	@Output() onRefreshPricing: EventEmitter<ILineItemModel[]> = new EventEmitter();
	@Input() appliedPayments: IAppliedPaymentModel[];
	@Input() invoiceGroupSeq: number;
	@Input() splitLineItems: ILineItemModel[];
	@Input() customer: ICustomerModel;
	@Input() jobSiteAddress: IAddressModel;
	@Input() shippingAddress: IShippingAddressModel;
	@Input() editable: boolean = true;
	@Input() taxable: boolean = true;
	@Input() taxRate: number;
	@Input() addColoradoShippingSurcharge: boolean = false;
	@Input() isLathamOrder: boolean = false;
	@Input() showSendToCheckBox: boolean = false;
	@Input() pricingChanged: boolean = false;
	@Input() showBalanceDue: boolean = true;
	@Input() summary: boolean = false;
	@Input() invoiceModel: IInvoiceModel;

	@ViewChild("enlargedImageDialogRef") enlargedImageDialogRef: SlickDialogComponent;

	enlargedImageUrl: string;

	visibleLineItems: ILineItemModel[];
	isAdmin: boolean = (GlobalsService.userInfo.roleTypeId === 1 || GlobalsService.userInfo.roleTypeId === 2 || GlobalsService.checkPermission("Invoices", "adminUnlock"));
	useFuelSurcharge: boolean = GlobalsService.company.useFuelSurcharge;
	allowTips: boolean = GlobalsService.company.allowTips;
	showLathamItemsInItemSearch: boolean;
	showCost: boolean = false;
	skuResults: any[];
	descResults: any[];
	labor: ILineItemModel = new LineItemModel();
	labor2: ILineItemModel = new LineItemModel();
	labor3: ILineItemModel = new LineItemModel();
	labor4: ILineItemModel = new LineItemModel();
	shipping: ILineItemModel = new LineItemModel();
	fuelSurcharge: ILineItemModel = new LineItemModel();
	tip: ILineItemModel = new LineItemModel();
	tax: ILineItemModel = new LineItemModel();
	taxRate1: ILineItemModel = new LineItemModel();
	taxRate2: ILineItemModel = new LineItemModel();
	taxRate3: ILineItemModel = new LineItemModel();
	coloradoShippingSurcharge: ILineItemModel = new LineItemModel();
	discounts: ILineItemModel[];
	rebates: ILineItemModel[];
	itemsSubtotals: ILineItemTotalsModel;
	subtotals: ILineItemTotalsModel;
	totals: ILineItemTotalsModel;
	appliedPaymentsTotal: number;
	isPaid = false;
	isNoCharge = false;
	isCustomSKU = false;
	colspan: number = 5;
	selectedItem: IItemModel;
	selectedItemId: number;
	tabIndex = 0;
	tabKey = '';
	skus: string[] = [];
	isMizu: boolean = GlobalsService.company.companyId === 1;

	useBarCodes = GlobalsService.company.useBarCodes;
	barCode: string;

	useCustomSKU = GlobalsService.company.useCustomSKU
	useAdvancedTax = GlobalsService.company.useAdvancedTax;
	useCereTax = GlobalsService.company.useCereTax;
	showLabor2 = GlobalsService.company.useLabor2LineItem;
	showLabor3 = GlobalsService.company.useLabor3LineItem;
	showLabor4 = GlobalsService.company.useLabor4LineItem;

	lathamDiscountCompany: ICompanyModel;
	lathamDiscountTotal: number;

	laborItems: IInvoiceLaborItemModel[];

	customerId: number = 0;

	lastHashCode: string = '';
	flagSaveForTaxUpdate: boolean = false;
	constructor(private itemsService: ItemsService,
		private lineItemsService: LineItemsService,
		private lookupService: LookupService,
		private httpService: HttpService,
		private functionLockService: FunctionLockService) {
		this.laborItems = this.lookupService.getInvoiceLaborItems();
		if (this.laborItems.length === 0)
			this.laborItems = null;
	}


	async ngOnChanges(changes: SimpleChanges) {
		await SleepService.sleep();
		this.editable = (this.editable.toString().toLowerCase() === 'true') ? true : false;
		//this.addColoradoShippingSurcharge = (this.addColoradoShippingSurcharge?.toString().toLowerCase() === 'true') ? true : false;
		this.taxable = true;// (this.taxable.toString().toLowerCase() === 'true') ? true : false;
		this.isLathamOrder = (this.isLathamOrder.toString().toLowerCase() === 'true') ? true : false;
		this.pricingChanged = (this.pricingChanged.toString().toLowerCase() === 'true') ? true : false;
		// If this is a latham order, always show the latham items
		if (this.isLathamOrder === true)
			this.showLathamItemsInItemSearch = true;
		else
			this.showLathamItemsInItemSearch = GlobalsService.company.showLathamItemsInItemSearch;

		this.colspan = (this.summary) ? 2 : 5;

		if (GlobalsService.company.useAdvancedTax === false && this.taxRate === null)
			this.taxRate = GlobalsService.company.taxRate;

		if (changes.invoiceGroupSeq)
			this.invoiceGroupSeq = parseInt(this.invoiceGroupSeq.toString());

		if (changes.customer) {
			if (!this.customer) {
				this.customerId = 0;
				this.lineItems = this.lineItems.map(li => { li.price = 0; return li; })
			}
			else {
				if (this.customer.customerId !== this.customerId) {
					this.customerId = this.customer.customerId;
					if (this.editable === true) {
						if (GlobalsService.company.useLathamOrdering === true) {
							const companyId = (GlobalsService.company.companyId === 1) ? this.customer.childCompanyId : GlobalsService.company.companyId;

							if (!companyId) {
								this.lathamDiscountCompany = null;
								this.lathamDiscountTotal = null;
							}
							else if (companyId && (!this.lathamDiscountCompany || this.lathamDiscountCompany.companyId !== companyId)) {
								this.lathamDiscountCompany = <ICompanyModel>(await this.httpService.get(`/companies/getCompany?companyId=${companyId}`));

								if (this.lathamDiscountCompany.useLathamDiscount === true)
									this.lathamDiscountTotal = await this.httpService.get(`/lathamOrders/getLathamDiscountTotal?companyId=${companyId}`);
								else
									this.lathamDiscountTotal = 0;
							}

						}
					}
				}
				else {
					if (changes.customer.previousValue.taxExempt !== changes.customer.currentValue.taxExempt && changes.customer.currentValue.taxExempt === false) {
						if(this.invoiceModel)
							this.invoiceModel.flagSaveForTaxUpdate = true;
						this.flagSaveForTaxUpdate = true;
					}
				}
            }
		}

		this.flagSaveForTaxUpdate = this.invoiceModel?.flagSaveForTaxUpdate;

		if (changes.invoiceModel)
			this.flagSaveForTaxUpdate = false;

		const hasCustomerChanged = changes.customer && changes.customer.currentValue && changes.customer.previousValue &&
			(changes.customer.currentValue.customerId !== changes.customer.previousValue.customerId) &&
			((this.lineItems.filter(x => (x.lineItemType == LineItemTypes.LineItem || (x.lineItemType == LineItemTypes.Shipping && (x.price ?? 0) > 0)) && !x.isBlankLineItem).length ?? 0) > 0);

		this.recalc();

		const hasDiscountsChanged = (changes.lineItems && changes.lineItems.currentValue && changes.lineItems.previousValue && changes.lineItems.currentValue?.filter(x => x.lineItemType == LineItemTypes.Discount).length !== changes.lineItems.previousValue.filter(x => x.lineItemType === LineItemTypes.Discount).length);
		
        if (changes.taxable || changes.customer || changes.addColoradoShippingSurcharge || (changes.lineItems && (changes.lineItems.currentValue.length !== this.lineItems.length)) ||
            (changes.lineItems && (changes.lineItems.currentValue?.map(x => x.price).reduce((sum, current) => sum + current, 0) !== this.lineItems.map(x => x.price).reduce((sum, current) => sum + current, 0)))) {
			if (!changes.invoiceModel && (changes.taxable || hasCustomerChanged || hasDiscountsChanged))
                this.emit()
            else
                this.emit(false);
        }

		//let hash = this.getHash();
		//const hasChanges = changes.editable || changes.taxable || changes.customer || changes.addColoradoShippingSurcharge;
		//const isHashDifferent = this.lastHashCode != hash;

		//if (hasChanges || isHashDifferent) {
		//	if (isHashDifferent) {
		//		this.lastHashCode = hash;
		//	}
		//	this.emit();
		//}

	}

	async updateLathamItemAssemblies() {
		this.selectedItem = await this.httpService.get(`/lathamOrders/syncItemAssemblies?sku=${this.selectedItem.sku}`)
	}

	async onTabChanged(tabKey: string) {
		this.tabKey = tabKey;
	}

	async goBack() {
		if (this.skus.length > 1)
			this.skus.pop();
		const items = await this.itemsService.getItemsBySkus([this.skus[this.skus.length - 1]], 0, true);
		this.selectedItem = items[0];
	}

	async onItemAssemblySkuClick(sku: string) {
		this.skus.push(sku);
		const items = await this.itemsService.getItemsBySkus([sku], 0, true);
		this.selectedItem = items[0];
	}

	async enlargeImage(imageUrl: string, itemId: number) {
		//if (imageUrl.indexOf("NoImage.png") >= 0)
		//	return;
		this.enlargedImageUrl = imageUrl;
		this.skus = [];
		this.selectedItem = await this.itemsService.getItem(itemId);
		this.skus.push(this.selectedItem.sku);
		this.enlargedImageDialogRef.showDialog();
	}

	closeImageDialog() {

		this.enlargedImageDialogRef.hideDialog()
	}

	async refreshPricing() {
		await SleepService.sleep();
		this.onRefreshPricing.emit();
	}

	async emit(flagTax: boolean = true) {
		await SleepService.sleep();
		this.lineItems.forEach(x => x.quantity = (x.quantity === null) ? 1 : x.quantity);
		if (flagTax && this.invoiceModel) {
			this.invoiceModel.flagSaveForTaxUpdate = true;
			this.flagSaveForTaxUpdate = true;
		}

		this.lineItemsChange.emit(this.lineItems);
		this.amountDueChange.emit(this.amountDue);
	}

	async onBarCodeKeyDown(e: KeyboardEvent) {
		if (e.code === "Enter") {
			await SleepService.sleep();

			if (!this.barCode)
				return;

			const barCode = (this.barCode || '');

			const params = {
				customerId: this.customerId,
				barCode: barCode
			}
			const barCodeLineItem: ILineItemModel = await this.httpService.get(`/items/getLineItemByBarCode`, params);

			if (!barCodeLineItem) {
				await Swal.fire({
					icon: 'warning',
					title: 'Oops...',
					text: `Bar code ${barCode} not found`,
					confirmButtonColor: '#007bff',
					heightAuto: false
				});

				this.barCode = null;
				return;
			}

			if (barCodeLineItem) {
				const existingLineItem = this.lineItems.find(x => x.sku.toLowerCase() === barCodeLineItem.sku.toLowerCase());
				if (existingLineItem) {
					existingLineItem.quantity++;
				}
				else {
					if (!barCodeLineItem.isGlobalItem)
						barCodeLineItem.sendToManufacturer = false;

					barCodeLineItem.quantity = 1;
					barCodeLineItem.invoiceGroupSeq = this.invoiceGroupSeq;
					this.lineItems.push(barCodeLineItem);
				}

				this.recalc();
				this.emit();
			}

			this.barCode = null;
		}
	}

	async scanBarcode() {
		const requestPermissions = async () => {
			await BarcodeScanner.requestPermissions();
		};

		this.barCode = (await BarcodeScanner.scan()).barcodes[0].rawValue;
		if (!this.barCode)
			return;

		const barCode = (this.barCode || '');

		const params = {
			customerId: this.customerId,
			barCode: barCode
		}
		const barCodeLineItem: ILineItemModel = await this.httpService.get(`/items/getLineItemByBarCode`, params);

		if (!barCodeLineItem) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: `Bar code ${barCode} not found`,
				confirmButtonColor: '#007bff',
				heightAuto: false
			});

			this.barCode = null;
			return;
		}

		if (barCodeLineItem) {

			const existingLineItem = this.lineItems.find(x => x.sku.toLowerCase() === barCodeLineItem.sku.toLowerCase());
			if (existingLineItem) {
				existingLineItem.quantity++;
			}
			else {
				if (!barCodeLineItem.isGlobalItem)
					barCodeLineItem.sendToManufacturer = false;
				barCodeLineItem.quantity = 1;
				barCodeLineItem.invoiceGroupSeq = this.invoiceGroupSeq;
				this.lineItems.push(barCodeLineItem);
			}

			this.recalc();
			this.emit();
		}

		this.barCode = null;


	}

	async onLineItemKeyDown(lineItem: ILineItemModel) {
		await SleepService.sleep();

		if (lineItem.isBlankLineItem && (lineItem.sku || lineItem.description)) {
			const lineItemIdx = this.lineItems.findIndex(x => x.uuid === lineItem.uuid);
			this.lineItems[lineItemIdx].isBlankLineItem = false;
			this.addBlankLineItem();
			this.emit();
		}
	}

	async searchBySku(skuSearchText: string) {
		const params = {
			showLathamItemsInItemSearch: this.showLathamItemsInItemSearch,
			searchText: skuSearchText,
			inventoryItemsOnly: false
		}
		this.skuResults = await this.httpService.get(`/items/getItemsForItemSearch`, params);
	}

	// This will replace an existing line item with a new one
	async replaceWithSelectedSkuItem(itemSearchModel: IItemSearchModel, existingLineItem: ILineItemModel) {
		try {
			await this.functionLockService.lock("LINE_ITEMS_REPLACE_LINE_ITEM");

			// See if this line item exists in the line items.  If not, add it
			if (!this.lineItems.find(li => li.uuid === existingLineItem.uuid))
				this.lineItems.push(existingLineItem);

			const lineItem = await this.lineItemsService.getLineItemBySku(itemSearchModel.sku, this.customerId);

			if (lineItem) {
				existingLineItem.itemId = lineItem.itemId;
				existingLineItem.sku = lineItem.sku;
				existingLineItem.description = lineItem.description;
				existingLineItem.cost = lineItem.cost;
				existingLineItem.imageUrl = lineItem.imageUrl;
				existingLineItem.imageThumbnailUrl = lineItem.imageThumbnailUrl;
				existingLineItem.itemInventoryType = lineItem.itemInventoryType;
				existingLineItem.isParentItem = lineItem.isParentItem;
				if (!existingLineItem.priceModified)
					existingLineItem.price = lineItem.price;
				if (!lineItem.isGlobalItem)
					existingLineItem.sendToManufacturer = false;
				existingLineItem.taxable = lineItem.taxable;
			}

			await SleepService.sleep();

			if (GlobalsService.company.companyId === 88) {
				const descTextbox = (<HTMLInputElement>document.querySelector(`.line-items table tbody tr[data-uuid='${existingLineItem.uuid}'] .description`));
				descTextbox.focus();
				descTextbox.select();
			}
			else {
				const qtyTextbox = (<HTMLInputElement>document.querySelector(`.line-items table tbody tr[data-uuid='${existingLineItem.uuid}'] .quantity`));
				qtyTextbox.focus();
				qtyTextbox.select();
			}

			this.recalc();
			this.emit();
		}
		finally {
			await this.functionLockService.release("LINE_ITEMS_REPLACE_LINE_ITEM");
		}
	}

	async replaceWithSelectedSkuFreeform(existingLineItem: ILineItemModel) {
		if (this.useCustomSKU === false) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: `SKU "${existingLineItem.sku}" not found.`,
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			this.deleteLineItem(existingLineItem)
			return;
		}

		try {
			await this.functionLockService.lock("LINE_ITEMS_REPLACE_LINE_ITEM");

			// See if this line item exists in the line items.  If not, add it
			if (!this.lineItems.find(li => li.uuid === existingLineItem.uuid)) {
				if (!existingLineItem.isGlobalItem)
					existingLineItem.sendToManufacturer = false;
				else
					existingLineItem.sendToManufacturer = true;
				this.lineItems.push(existingLineItem);
			}

			await SleepService.sleep();

			const descTextbox = (<HTMLInputElement>document.querySelector(`.line-items table tbody tr[data-uuid='${existingLineItem.uuid}'] .description`));
			descTextbox.focus();
			descTextbox.select();
			this.recalc();
			this.emit();
		}
		finally {
			await this.functionLockService.release("LINE_ITEMS_REPLACE_LINE_ITEM");
		}
	}

	async searchByDesc(descSearchText: string) {
		const params = {
			showLathamItemsInItemSearch: this.showLathamItemsInItemSearch,
			searchText: descSearchText
		}

		this.descResults = await this.httpService.get("/items/getItemsForItemSearch", params);
	}

	// This will replace an existing line item with a new one
	async replaceWithSelectedDescItem(itemSearchModel: IItemSearchModel, existingLineItem: ILineItemModel) {
		try {
			await this.functionLockService.lock("LINE_ITEMS_REPLACE_LINE_ITEM");

			// See if this line item exists in the line items.  If not, add it
			if (!this.lineItems.find(li => li.uuid === existingLineItem.uuid))
				this.lineItems.push(existingLineItem);

			const lineItem = await this.lineItemsService.getLineItemBySku(itemSearchModel.sku, this.customerId);

			if (lineItem) {
				existingLineItem.sku = lineItem.sku;
				existingLineItem.description = lineItem.description;
				existingLineItem.cost = lineItem.cost;
				if (!existingLineItem.priceModified)
					existingLineItem.price = lineItem.price;
				if (!lineItem.isGlobalItem)
					existingLineItem.sendToManufacturer = false;
				else
					existingLineItem.sendToManufacturer = true;
			}

			this.recalc();
			this.emit();
		}
		finally {
			await this.functionLockService.release("LINE_ITEMS_REPLACE_LINE_ITEM");
		}
	}

	async replaceWithSelectedDescFreeform(existingLineItem: ILineItemModel) {
		if (this.useCustomSKU === false) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: `Description "${existingLineItem.description}" not found.`,
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			this.deleteLineItem(existingLineItem)
			return;
		}

		try {
			await this.functionLockService.lock("LINE_ITEMS_REPLACE_LINE_ITEM");

			// See if this line item exists in the line items.  If not, add it
			if (!this.lineItems.find(li => li.uuid === existingLineItem.uuid)) {
				if (!existingLineItem.isGlobalItem)
					existingLineItem.sendToManufacturer = false;
				else
					existingLineItem.sendToManufacturer = true;
				this.lineItems.push(existingLineItem);
			}

			this.recalc();
			this.emit();
		}
		finally {
			await this.functionLockService.release("LINE_ITEMS_REPLACE_LINE_ITEM");
		}
	}

	toggleTaxable(lineItem: ILineItemModel) {
		const existingLineItem = this.lineItems.find(x => x.uuid === lineItem.uuid);
		existingLineItem.taxable = !existingLineItem.taxable;

		this.recalc();
		this.emit();
	}

	async updateLineItem(lineItem: ILineItemModel) {
		// Find the line item in the line items and check to see if the price was changed
		const existingLineItem = this.lineItems.find(x => x.uuid === lineItem.uuid);

		if (lineItem.price !== existingLineItem.price)
			lineItem.priceModified = true;

		if (lineItem.quantity !== existingLineItem.quantity)
			lineItem.quantityModified = true;

		lineItem.editable = false;

		this.recalc();
		this.emit();
	}

	undoEdit(lineItem: ILineItemModel) {
		const idx = this.lineItems.findIndex(x => x.uuid === lineItem.uuid);
		this.lineItems[idx] = UtilsService.clone(this.lineItems.find(x => x.uuid === lineItem.uuid));
		this.lineItems[idx].editable = false;
	}

	deleteLineItem(lineItem: ILineItemModel) {
		this.lineItems = this.lineItems.filter(x => x.uuid !== lineItem.uuid);
		this.recalc();
		this.emit();
	}

	deleteDiscount(discount: ILineItemModel) {
		discount.cost = 0;
		discount.price = 0;
		discount.priceModified = true;

		this.recalc();
		this.emit();
	}

	async onLaborSelected(laborItem: IInvoiceLaborItemModel, lineItem: ILineItemModel) {
		if (laborItem) {
			lineItem.laborItemId = laborItem.invoiceLaborItemId;
			lineItem.description = laborItem.description;
			lineItem.price = laborItem.price;
		}
		else {
			lineItem.laborItemId = null;
			lineItem.description = null;
			lineItem.price = null
		}

		this.recalc();
		this.emit(false);
	}

	async updatePricing(lineItem: ILineItemModel = null) {
		if (lineItem)
			lineItem.priceModified = true;

		await SleepService.sleep();

		this.recalc();
		this.emit();
	}

	//visibleLineItems(lineItems: ILineItemModel[]): ILineItemModel[] {
	//	return lineItems.filter(li => li.lineItemType === LineItemTypes.LineItem);
	//}

	private async recalc() {
		this.visibleLineItems = [];

		if (this.lineItems) {
			this.lineItems = this.lineItems.map(li => {
				li.sku = (li.sku || '').trim();
				li.description = (li.description || '').trim();
				li.cost = li.cost || 0;
				li.price = li.price || 0;

				li.editable = this.editable;

				if (li.isSystemLineItem === true)
					li.editable = false;

				return li;
			});

			// Only recalc if it's editable
			if (this.editable) {
				this.lineItems = this.lineItemsService.recalculate(this.lineItems, this.customer, this.taxRate, this.shippingAddress?.taxRates, this.customer?.taxExempt, this.addColoradoShippingSurcharge);
				// Remove and re-add the skedit discount
				this.lineItems = this.lineItems.filter(x => x.description !== 'Skedit Discount');
				if (this.lathamDiscountCompany && this.lathamDiscountCompany.useLathamDiscount === true && moment().isBefore(this.lathamDiscountCompany.lathamDiscountEndDate, "date")) {
					const LineItemSubtotals = this.lineItemsService.calcItemsSubtotals(this.lineItems);
					const discount = UtilsService.round(LineItemSubtotals.price * .02, 2);
					if (discount > 0 && (this.lathamDiscountTotal + discount) < this.lathamDiscountCompany.lathamDiscountAmount) {
						const lathamOrderDiscount = new LineItemModel(LineItemTypes.Discount);
						lathamOrderDiscount.sku = "Discount";
						lathamOrderDiscount.description = "Skedit Discount";
						lathamOrderDiscount.quantity = 1;
						lathamOrderDiscount.cost = 0;
						lathamOrderDiscount.price = discount * -1;
						this.lineItems.push(lathamOrderDiscount);
					}
				}

				// Make sure blank line item is at the bottom
				this.lineItems = this.lineItems.filter(x => !x.isBlankLineItem);
				this.addBlankLineItem();
			}

			this.labor = this.getLineItem(LineItemTypes.Labor);
			this.labor2 = this.getLineItem(LineItemTypes.Labor2);
			this.labor3 = this.getLineItem(LineItemTypes.Labor3);
			this.labor4 = this.getLineItem(LineItemTypes.Labor4);
			this.shipping = this.getLineItem(LineItemTypes.Shipping, true);
			this.fuelSurcharge = this.getLineItem(LineItemTypes.FuelSurcharge, true);
			this.tip = this.getLineItem(LineItemTypes.Tip, true);
			this.tax = this.getLineItem(LineItemTypes.Tax, true);
			this.taxRate1 = this.getLineItem(LineItemTypes.TaxRate1, true);
			this.taxRate2 = this.getLineItem(LineItemTypes.TaxRate2);
			// Only add taxrate2 if we have a taxrate2 name
			if (GlobalsService.company.taxRate2Name && this.editable && !this.taxRate2)
				this.taxRate2 = new LineItemModel(LineItemTypes.TaxRate2);
			this.taxRate3 = this.getLineItem(LineItemTypes.TaxRate3);
			this.coloradoShippingSurcharge = this.getLineItem(LineItemTypes.ColoradoShippingSurcharge);
			// Only add taxrate2 if we have a taxrate3 name
			if (GlobalsService.company.taxRate3Name && this.editable && !this.taxRate3)
				this.taxRate3 = new LineItemModel(LineItemTypes.TaxRate3);
			this.discounts = this.lineItems.filter(li => li.lineItemType === LineItemTypes.Discount && li.price !== 0);
			this.rebates = this.lineItems.filter(li => li.lineItemType === LineItemTypes.Rebate);

			this.itemsSubtotals = this.lineItemsService.calcItemsSubtotals(this.lineItems);
			if (this.editable && this.fuelSurcharge && GlobalsService.company.useFuelSurcharge)
				this.fuelSurcharge = this.lineItemsService.calcFuelSurcharge(this.fuelSurcharge, this.lineItems, this.jobSiteAddress);
			this.subtotals = this.lineItemsService.calcSubtotals(this.lineItems);
			this.totals = this.lineItemsService.calcTotals(this.lineItems);

			this.appliedPaymentsTotal = 0;
			if (this.appliedPayments)
				this.appliedPaymentsTotal = this.appliedPayments.reduce((tot, ap) => tot += ap.appliedAmount, 0);

			this.isPaid = false;
			this.isNoCharge = false;
			// Only paid or no charge if there are actual line items
			if (this.lineItems.filter(x => x.lineItemType === LineItemTypes.LineItem).length > 1) {
				if (this.customer) {
					if (this.amountDue === 0 && this.appliedPaymentsTotal > 0)
						this.isPaid = true;

					if (this.amountDue === 0 && this.appliedPaymentsTotal === 0)
						this.isNoCharge = true;
				}
			}

			setTimeout(() => this.amountDueChange.emit(this.totals.price - this.appliedPaymentsTotal));

			this.visibleLineItems = this.lineItems.filter(x => x.lineItemType === LineItemTypes.LineItem);
		}
	}

	private addBlankLineItem(): ILineItemModel {
		if (this.editable === false)
			return;

		if (this.lineItems.find(li => li.isBlankLineItem === true))
			return;

		const blankLineItem = new LineItemModel();
		blankLineItem.uuid = UtilsService.newGuid();
		blankLineItem.lineItemType = LineItemTypes.LineItem;
		blankLineItem.isSystemLineItem = false;
		blankLineItem.editable = true;
		blankLineItem.priceModified = false;
		blankLineItem.sku = '';
		blankLineItem.displayOrder = this.lineItems.filter(x => x.lineItemType === LineItemTypes.LineItem && x.isSystemLineItem === false).reduce((max, li) => li.displayOrder > max ? li.displayOrder : max, 0) + 1;
		blankLineItem.quantity = 1;
		blankLineItem.cost = 0;
		blankLineItem.price = 0;
		blankLineItem.sendToManufacturer = true;
		blankLineItem.isBlankLineItem = true;
		blankLineItem.taxable = true;
		blankLineItem.invoiceGroupSeq = this.invoiceGroupSeq;

		this.lineItems.push(blankLineItem);

		return blankLineItem;
	}

	private getLineItem(lineItemType: LineItemTypes, addDefault: boolean = false): ILineItemModel {
		let lineItem = this.lineItems.find(li => li.lineItemType === lineItemType);

		if (!lineItem && addDefault && this.editable)
			lineItem = new LineItemModel(lineItemType);

		return lineItem;
	}

	private getHash(): string {
		let fullHashCode = '';
		if ((this.lineItems?.length ?? 0) === 0) {
			return '';
		}


		this.lineItems.forEach(li => {
			const bigString: string = `${li.cost}${li.price}${li.sku}${li.description}${li.taxable}${li.quantity}`;
			fullHashCode += UtilsService.getHashCode(bigString).toString();
		})

		return UtilsService.getHashCode(fullHashCode).toString();
	}

}