import { Component, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { SlickDialogComponent } from "@slick-components";
import { IAppointmentChecklistQuestionModel } from "@models";
import { UtilsService } from "@services";

@Component({
    selector: "checklist-dialog",
    templateUrl: "./checklist-dialog.component.html",
    styleUrls: ["./checklist-dialog.component.scss"]
})

export class ChecklistDialogComponent {
    @ViewChild("checklistDialog", { static: true }) checklistDialog: SlickDialogComponent;

    @Input() appointmentId: number;
    @Input() checklistQuestions: IAppointmentChecklistQuestionModel[];
    @Output() checklistQuestionsChange = new EventEmitter<IAppointmentChecklistQuestionModel[]>();

    constructor() { }

    openDialog() {
        this.checklistQuestions = UtilsService.clone(this.checklistQuestions);
        this.checklistDialog.showDialog();
    }

    closeDialog() {
        this.checklistDialog.hideDialog();
    }

    forwardModelChange(e: any) {
        this.checklistQuestionsChange.emit(e);
        this.closeDialog();
    }
}