import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComponentsModule } from "@components"
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { SlickButtonSpinnerModule, SlickCurrencyMaskModule, SlickDialogModule, SlickValidationIndicatorModule, SlickAutoCompleteModule, SlickConfirmDialogModule, SlickDropDownModule, SlickGoogleAddressSearchModule } from "@slick-components";
import { CreditCardDialogComponent } from "./credit-card-dialog.component";
import { CreditCardEditDialogModule } from "./credit-card-edit-dialog/credit-card-edit-dialog.module";
import { EmailerDialogModule } from "@components/emailer-dialog";

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		CreditCardEditDialogModule,
		FormsModule, ReactiveFormsModule,
		CreditCardDirectivesModule,
		EmailerDialogModule,
		SlickConfirmDialogModule,
		SlickDropDownModule,
		SlickButtonSpinnerModule, SlickCurrencyMaskModule, SlickDialogModule, SlickValidationIndicatorModule, SlickAutoCompleteModule, SlickGoogleAddressSearchModule
	],
	declarations: [		
		CreditCardDialogComponent,
		
	],
	exports: [CreditCardDialogComponent]
})
export class CreditCardDialogModule {
}

export { CreditCardDialogComponent }