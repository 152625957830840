import { Injectable } from "@angular/core";
import { LookupService } from "./utils/lookup.service";
import { ITimeSlotModel, TimeSlotModel, IScheduledAppointmentModel, IResourceModel, IBlockedTimeSlotModel } from "@models";
import * as moment from "moment";
import { GlobalsService } from "@services/utils/globals.service";
import { UsersService } from "@services/users.service";

@Injectable()
export class ScheduleService {
	constructor(private lookupService: LookupService, private usersService: UsersService) { }

	getTimeSlots(date: Date, visibleStartHour: number, visibleEndHour: number): ITimeSlotModel[] {
		const timeSlots: ITimeSlotModel[] = [];

		let currentTime = moment(date).startOf("day").add(visibleStartHour, "hours");
		const endTime = moment(date).startOf("day").add(visibleEndHour, "hours");
		if (visibleEndHour < 24)
			endTime.add(1, "hours");
		const timeSlotCount = endTime.diff(currentTime, "minutes") / 15;

		for (let i = 0; i < timeSlotCount; i++) {
			const timeSlot = new TimeSlotModel(currentTime);
			timeSlots.push(timeSlot);
			currentTime = currentTime.add(15, "minutes");
		}

		return timeSlots;
	}

	getHeaderTimeSlots(timeSlots: ITimeSlotModel[]): ITimeSlotModel[] {
		const headerTimeSlots: ITimeSlotModel[] = [];

		for (let i = 0; i < timeSlots.length; i++) {
			if (i % 4 === 0)
				headerTimeSlots.push(timeSlots[i]);
		}

		return headerTimeSlots;
	}

	getVisibleResources(appointments: IScheduledAppointmentModel[], blockedTimeSlots: IBlockedTimeSlotModel[]): IResourceModel[] {
		appointments = appointments || [];
		const allResources = this.lookupService.getResources(null, true);
		const userResourceSortOrder = this.lookupService.getUserResourceSortOrder();

		let visibleResources = allResources
			.filter(r => r.active || (appointments.findIndex(appt => r.resourceId === appt.resourceId) > -1) || (blockedTimeSlots.findIndex(timeSlot => r.resourceId === timeSlot.resourceId) > -1))
			.map(r => {
				const resourceSortOrder = userResourceSortOrder.find(x => x.resourceId === r.resourceId);
				r.sortOrder = resourceSortOrder?.sortOrder || 9999;
				return r;
			});


		visibleResources.sort(function (a, b) {
			if (a.sortOrder < b.sortOrder)
				return -1;
			else if (a.sortOrder > b.sortOrder)
				return 1;
			else
				return 0;
		});
		
		// If this company only has 1 location, always show that location
		if (GlobalsService.company.locations.length === 1) {
			return visibleResources;
		}
		else if (GlobalsService.company.locations.length > 1 && GlobalsService.userInfo.allowedLocationIds.length === 1) {
			// If the company has > 1 location, but this user only has access to 1, use the 1 they have access to and any that have appts 
			const allowedLocation = GlobalsService.userInfo.allowedLocationIds[0];
			visibleResources = visibleResources.filter(res => res.locationId === allowedLocation);

		}
		else {
			// Show any that are checked
			const selectedLocations = GlobalsService.userInfo.selectedLocationIds;
			visibleResources = visibleResources.filter(res => selectedLocations.findIndex(l => l === res.locationId) >= 0);
		}

		return visibleResources;
	}

	getVisibleResourcesForDesktop(appointments: IScheduledAppointmentModel[], blockedTimeSlots: IBlockedTimeSlotModel[]): IResourceModel[] {
		appointments = appointments || [];
		const allResources = this.lookupService.getResources(null, true);
		const userResourceSortOrder = this.lookupService.getUserResourceSortOrder();

		let visibleResources = allResources.filter(r => r.active || (appointments.findIndex(appt => r.resourceId === appt.resourceId) > -1) || (blockedTimeSlots.findIndex(timeSlot => r.resourceId === timeSlot.resourceId) > -1));

		visibleResources.sort(function (a, b) {
			const sortA = userResourceSortOrder.find(x => x.resourceId === a.resourceId);
			const sortB = userResourceSortOrder.find(x => x.resourceId === b.resourceId);
			if (!sortA || !sortB) {
				if (a.sortOrder < b.sortOrder)
					return -1;
				else if (a.sortOrder > b.sortOrder)
					return 1;
				else
					return 0;
			}

			if (sortA.sortOrder < sortB.sortOrder) //sort string ascending
				return -1;
			if (sortA.sortOrder > sortB.sortOrder)
				return 1;
			return 0 //default return value (no sorting)
		});

		// If this company only has 1 location, always show that location
		if (GlobalsService.company.locations.length === 1) {
			return visibleResources;
		}
		else if (GlobalsService.company.locations.length > 1 && GlobalsService.userInfo.allowedLocationIds.length === 1) {
			// If the company has > 1 location, but this user only has access to 1, use the 1 they have access to
			const allowedLocation = GlobalsService.userInfo.allowedLocationIds[0];
			visibleResources = visibleResources.filter(res => res.locationId === allowedLocation);
		}
		else {
			// Show any that are checked
			const selectedLocations = GlobalsService.userInfo.selectedLocationIds;
			visibleResources = visibleResources.filter(res => selectedLocations.findIndex(l => l === res.locationId) >= 0);
		}

		return visibleResources;
	}

	createTimeSlotsHTML(timeSlots: ITimeSlotModel[], timeSlotHeight: string, showTicks: boolean = true): string {
		let timeSlotsDivText = `
<div class="d-flex flex-fill">`

		timeSlots.forEach((timeSlot, idx) => {
			timeSlotsDivText += `
	<div class="time-slot" style='display: flex; flex: 1 1 0px; min-height: ${timeSlotHeight};' data-calcdate="${timeSlot.calcDate}" data-calchour="${timeSlot.calcHour}">`;

			if (showTicks) {
				const hour = (timeSlot.hour > 12) ? `${timeSlot.hour - 12}` : `${timeSlot.hour}`;
				if (idx > 0 && idx % 4 === 0)
					timeSlotsDivText += `<div class="tick hour-tick">${(idx % 4 === 0) ? hour : ''}</div>`
				else
					timeSlotsDivText += `<div class="tick minute-tick">${(idx % 4 === 0) ? hour : ''}</div>`
			}
			timeSlotsDivText += `
	</div>`
		});

		timeSlotsDivText += `
</div>
`

		return timeSlotsDivText;
	}
}
