import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IQBWCTaskGridViewModel, IQBWCTaskModel } from "@models";

@Injectable()
export class QuickbooksService {
	constructor(private httpService: HttpService) {

	}

	async getQBWCTask(qbwcTaskId: number): Promise<IQBWCTaskModel> {
		return this.httpService.get("/quickbooks/getQBWCTask", { qbwcTaskId });
	}

	async syncAccounts(): Promise<IQBWCTaskGridViewModel> {
		return this.httpService.get("/quickbooks/syncAccounts");
	}

	async syncClasses(): Promise<IQBWCTaskGridViewModel> {
		return this.httpService.get("/quickbooks/syncClasses");
	}

	async syncSalesTaxCodes(): Promise<IQBWCTaskGridViewModel> {
		return this.httpService.get("/quickbooks/syncSalesTaxCodes");
	}

	async syncItemSalesTaxes(): Promise<IQBWCTaskGridViewModel> {
		return this.httpService.get("/quickbooks/syncItemSalesTaxes");
	}

	async syncItemSalesTaxGroups(): Promise<IQBWCTaskGridViewModel> {
		return this.httpService.get("/quickbooks/syncItemSalesTaxGroups");
	}

	async syncInventorySites(): Promise<IQBWCTaskGridViewModel> {
		return this.httpService.get("/quickbooks/syncInventorySites");
	}

	async syncInventoryItems(): Promise<IQBWCTaskGridViewModel> {
		return this.httpService.get("/quickbooks/syncInventoryItems");
	}

	async syncItemSites(): Promise<IQBWCTaskGridViewModel> {
		return this.httpService.get("/quickbooks/syncItemSites");
	}

	async syncItems(): Promise<IQBWCTaskGridViewModel> {
		return this.httpService.get("/quickbooks/syncItems");
	}

	async syncItemReceipts(): Promise<IQBWCTaskGridViewModel> {
		return this.httpService.get("/quickbooks/syncItemReceipts");
	}

	async syncCustomers(): Promise<IQBWCTaskGridViewModel> {
		return this.httpService.get("/quickbooks/syncCustomers");
	}

	async syncCustomersPartial(fromName: string, toName: string): Promise<IQBWCTaskGridViewModel> {
		const params = {
			fromName,
			toName
		};

		return this.httpService.get("/quickbooks/SyncCustomersPartial", params);
	}

	async syncCustomersSequenceOnly(): Promise<IQBWCTaskGridViewModel> {
		return this.httpService.get("/quickbooks/syncCustomersSequenceOnly");
	}

	async syncVendors(): Promise<IQBWCTaskGridViewModel> {
		return this.httpService.get("/quickbooks/syncVendors");
	}

		async addItemToQuickbooks(itemId: number, quickbooksCategoryId: string, quickbooksIncomeAccountId: string) {
			var params = {
		itemId: itemId,
		quickbooksItemInfo: quickbooksCategoryId,
		quickbooksIncomeAccountId: quickbooksIncomeAccountId
	}

	return this.httpService.post('/quickbooks/addItemToQuickbooks', params)
}


	async clearErrorNotification(qbwcTaskId: number): Promise<IQBWCTaskGridViewModel> {
		return this.httpService.get("/quickbooks/clearErrorNotification", { qbwcTaskId });
	}

	async temp(): Promise<boolean> {
		return this.httpService.get("/quickbooks/temp");
	}

	async processResponseXML(qbwcTaskId) {
		return this.httpService.get("/quickbooks/processResponseXML", { qbwcTaskId: qbwcTaskId });
	}
}