import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, OnChanges, SimpleChanges, OnInit } from "@angular/core";
import { SlickDialogService } from "./slick-dialog.service";
import { SlickSleepService } from "../utils/slick-sleep.service";
import { SlickInitService } from "../utils/slick-init.service";

@Component({
    selector: 'slick-dialog',
    templateUrl: 'slick-dialog.component.html',
    styleUrls: ["slick-dialog.component.css"],
    providers: [SlickDialogService]
})
export class SlickDialogComponent implements OnChanges, OnDestroy, OnInit {
    @Input() draggable: boolean = true;
    @Input() resizable: boolean = true;
    @Input() minimizable: boolean = true;
    @Input() header: string;
    @Input() showHeader: boolean = true;
    @Input() noPadding: boolean = false;
    @Input() top: number = 80;
    @Input() width: number = 600;
    @Input() height: number = 0;
    @Input() maxHeight: number = 0;
    @Input() cssClass: string;
    @Input() key: string;
    @Input() showOverlay: boolean = true;
    @Input() disableScrollbar: boolean = false;

    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild("containerRef") containerRef: ElementRef;
	@ViewChild("dialogRef") dialogRef: ElementRef;
	@ViewChild("headerRef") headerRef: ElementRef;
	@ViewChild("footerRef") footerRef: ElementRef;
	@ViewChild("dialogWrapper") dialogWrapper: ElementRef;

    fnResize = () => this.resize();

    private overlayEl: HTMLDivElement;

    showDialog_internal: boolean = false;
    isDialogVisible: boolean = false;
    isBodyVisible: boolean = false;
    hasFooter: boolean = false;
    zIndex: number = 0;
    inBounds: boolean = true;
    isMaximized: boolean = false;
    isMinimized: boolean = false;

    positionXOffset: number = 0;
    positionYOffset: number = 0;

    position: any;

    constructor(private readonly dialogService: SlickDialogService) {}

    ngOnInit() {
        this.loadPositionAndSize();
    }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.draggable) 
			this.draggable = (this.draggable.toString().toLowerCase() === 'false') ? false : true;

		if (changes.resizable)
			this.resizable = (this.resizable.toString().toLowerCase() === 'false') ? false : true;

		if (changes.minimizable)
			this.minimizable = (this.minimizable.toString().toLowerCase() === 'false') ? false : true;

		if (changes.showHeader)
			this.showHeader = (this.showHeader.toString().toLowerCase() === 'false') ? false : true;

        if (changes.noPadding)
            this.noPadding = (this.noPadding.toString().toLowerCase() === 'true') ? true : false;

		if (changes.top)
			this.top = parseInt(this.top.toString());

		if (changes.width)
			this.width = parseInt(this.width.toString());

		if (changes.height)
			this.height = parseInt(this.height.toString());

		if (changes.maxHeight)
			this.maxHeight = parseInt(this.maxHeight.toString());

		if (changes.showOverlay)
			this.showOverlay = (this.showOverlay.toString().toLowerCase() === 'false') ? false : true;

		if (changes.disableScrollbar)
			this.disableScrollbar = (this.disableScrollbar.toString().toLowerCase() === 'true') ? true : false;
	}


    ngOnDestroy() {
        window.removeEventListener("resize", this.fnResize, true);

        // It's fine if this fails.  It just means they were already removed
        try {
            document.body.removeChild(this.containerRef.nativeElement);
            document.body.removeChild(this.overlayEl);
        }
        catch { }

        this.overlayEl = null;
    }

    onMoveEnd(event) {
        this.positionXOffset = event.x;
        this.positionYOffset = event.y;
        this.updatePositionAndSize();
    }

    onResizeStop(event) {
        this.width = event.size.width;
        this.height = event.size.height;
        this.updatePositionAndSize();
    }

    updatePositionAndSize() {
        const state = {
            positionXOffset: this.positionXOffset,
            positionYOffset: this.positionYOffset,
            width: this.width,
            height: this.height
        };

        this.position = {
            x: this.positionXOffset,
            y: this.positionYOffset
        };

        if (this.key) {
            localStorage.setItem(`DIALOG_STATE_${this.key}`, JSON.stringify(state));
        }
    }

    loadPositionAndSize() {
        if (!this.key && SlickInitService.getParams().errorLog === "verbose") {
            console.error("Storage Key ([key] parameter) is not provided for Slick Dialog. Persisting state is disabled.");
            return;
        }

        const state = JSON.parse(localStorage.getItem(`DIALOG_STATE_${this.key}`));
        
        if (state) {
            this.positionXOffset = state.positionXOffset;
            this.positionYOffset = state.positionYOffset;
            this.width = state.width;
            this.height = state.height;

            // Sometimes this goes above the top of the screen.  If it does, reset it
            if (this.positionYOffset < 0 && this.positionYOffset < (this.top * -1)) {
                this.positionYOffset = this.top * -1;
                this.updatePositionAndSize();
            }

            this.position = {
                x: this.positionXOffset,
                y: this.positionYOffset
            };
        }
    }

	async showDialog() {
		this.showDialog_internal = true;

		await SlickSleepService.sleep();

        document.body.appendChild(this.containerRef.nativeElement);
        this.overlayEl = this.dialogService.createOverlay();

        this.isMinimized = false;
        this.isMaximized = false;
        this.dialogService.removeDialogFromMinimized(this.dialogWrapper);

        this.dialogService.positionAtCenter(this.dialogWrapper, this.top, this.width, this.height, this.positionXOffset, this.positionYOffset);
        this.dialogRef.nativeElement.style.width = this.width + "px";

        this.zIndex = ((SlickDialogService.overlayCount * 100) + 1);
        if (this.showOverlay) {
            this.dialogService.showOverlay(this.overlayEl);
        }
        this.isDialogVisible = true;
        this.resize();

        this.dialogRef.nativeElement.classList.remove("collapse-dialog");
        this.dialogRef.nativeElement.classList.add("expand-dialog");

        window.addEventListener("resize", this.fnResize, true);
    }

    async onDialogClose(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        await this.hideDialog();

        if (this.onClose)
            this.onClose.emit();
    }

    async hideDialog() {
        if (this.isDialogVisible === false)
            return;

        this.isMaximized = false;
        if (this.showOverlay) {
            this.dialogService.hideOverlay(this.overlayEl);
        }
        this.dialogRef.nativeElement.classList.remove("expand-dialog");
        this.dialogRef.nativeElement.classList.add("collapse-dialog");
        await SlickSleepService.sleep(350);
        this.isBodyVisible = false;
        this.isDialogVisible = false;

        if (this.isMinimized) {
            this.dialogService.removeDialogFromMinimized(this.dialogWrapper);
            this.isMinimized = false;
        }

        window.removeEventListener("resize", this.fnResize, true);

        // It's fine if this fails.  It just means they were already removed
        try {
            document.body.removeChild(this.containerRef.nativeElement);
            document.body.removeChild(this.overlayEl);
        }
        catch { }

        this.overlayEl = null;
        this.showDialog_internal = false;
    }

    onDialogMinimize(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        
        this.isMaximized = false;
        this.isMinimized = true;
        this.dialogRef.nativeElement.style.height = "initial";

        if (this.showOverlay) {
            this.dialogService.hideOverlay(this.overlayEl);
        }
        this.dialogService.minimize(this.dialogWrapper);
    }

    onDialogMaximize(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        this.dialogService.maximize(this.dialogWrapper, this.top, this.width, this.height, this.positionXOffset, this.positionYOffset);

        if (this.showOverlay) {
            this.dialogService.showOverlay(this.overlayEl);
        }

        this.zIndex = parseInt(this.overlayEl.style.zIndex) + 1;
        setTimeout(() => {
            this.isMinimized = false;
            this.dialogRef.nativeElement.style.height = "100%";
            this.dialogRef.nativeElement.style.width = this.width + "px";
            this.resize();
        }, 300);
    }

    toggleFullSize(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        
        if (!this.isMaximized) {
            this.dialogWrapper.nativeElement.style.top = "0px";
            this.dialogWrapper.nativeElement.style.right = "0px";
            this.dialogWrapper.nativeElement.style.left = "0px";
            this.dialogRef.nativeElement.style.height = window.innerHeight + "px";

            this.dialogRef.nativeElement.style.width = window.innerWidth + "px";
            this.dialogWrapper.nativeElement.style.width = window.innerWidth + "px";

            this.dialogRef.nativeElement.style.maxHeight = "initial";
            this.dialogWrapper.nativeElement.style.transform = "translate(0%)";
            this.isMaximized = true;
            this.resize(true);

        } else {
            this.dialogWrapper.nativeElement.style.top = this.top + "px";
            this.dialogWrapper.nativeElement.style.right = "calc(50% - " + (this.width / 2) + "px)";
            this.dialogWrapper.nativeElement.style.left = "calc(50% - " + (this.width / 2) + "px)";
            this.dialogWrapper.nativeElement.style.transform = "translate(" + this.positionXOffset + "px, " + this.positionYOffset + "px)";
            this.dialogRef.nativeElement.style.height = this.height === 0 ? "initial" : this.height + "px";

            this.dialogRef.nativeElement.style.width = this.width + "px";
            this.dialogWrapper.nativeElement.style.width = this.width + "px";

            this.dialogRef.nativeElement.style.maxHeight = this.maxHeight + "";
            this.isMaximized = false;
            this.resize(false);
        }
    }

    private async resize(forFullSize: boolean = false) {

        if (this.isMaximized)
            forFullSize = true;

        await SlickSleepService.sleep(50);
        let headerHeight = (this.headerRef) ? this.headerRef.nativeElement.clientHeight : 0;
        let footerHeight = (this.footerRef) ? this.footerRef.nativeElement.clientHeight : 0;
        this.hasFooter = footerHeight > 0;

        let documentBodyHeight = !forFullSize ? (document.body.clientHeight || window.innerHeight) : window.innerHeight;
        this.maxHeight = !forFullSize ? documentBodyHeight - (this.top * 2) : documentBodyHeight;
        this.isBodyVisible = true;
    }
}