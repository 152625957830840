import { IQuickAddCategoryItemModel } from "./quick-add-category-item.model";

export interface IQuickAddCategoryModel {
	quickAddCategoryId: number;
	categoryName: string;
	imageUrl: string;
	imageSku: string;
	showOnAdmin: boolean;
	showOnServiceTech: boolean;
	active: boolean;

	quickAddCategoryItems: IQuickAddCategoryItemModel[];
}

export class QuickAddCategoryModel implements IQuickAddCategoryModel {
	constructor() {
		this.quickAddCategoryId = 0;
		this.active = true;
		this.quickAddCategoryItems = [];
	}

	quickAddCategoryId: number;
	categoryName: string;
	imageUrl: string;
	imageSku: string;
	showOnAdmin: boolean;
	showOnServiceTech: boolean;
	active: boolean;

	quickAddCategoryItems: IQuickAddCategoryItemModel[];
}