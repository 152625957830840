import { Component, Input, ViewChild } from "@angular/core";
import { SlickDialogComponent, SlickScreenBlockerService } from "@slick-components";
import { HttpService, FunctionLockService } from "@services";
import { ICustomerModel } from "@models";

@Component({
    selector: "customer-quickbooks-link-dialog",
    templateUrl: "./customer-quickbooks-link-dialog.component.html"
})
export class CustomerQuickbooksLinkDialogComponent  {
    @ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;

    resolve: any;
    customerModel: ICustomerModel;
    linkOnly: boolean;
    searchString: string;
    lastSearchString: string;
    searchTimeout: NodeJS.Timer;
    searching: boolean;
    spinnerStatus: string;
    qbCustomers: ICustomerModel[];

    constructor(private httpService: HttpService,
        private functionLockService: FunctionLockService,
    private slickScreenBlockerService: SlickScreenBlockerService) { }

    openDialog(customerModel: ICustomerModel): Promise<ICustomerModel> {
        this.spinnerStatus = "reset";
        this.customerModel = customerModel;
        this.linkOnly = false;
        this.searchString = customerModel.displayName;
        this.lastSearchString = null;
        this.searching = false;
        this.qbCustomers = null;

        return new Promise<ICustomerModel>((resolve) => {
            this.resolve = resolve;

            this.dialogRef.showDialog();

            this.refresh();
		})
    }

    onKeyUp() {
        if (this.searchString === this.lastSearchString)
            return;

        this.lastSearchString = this.searchString;

        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.refresh();
        }, 700);
    }

    addNew() {
        this.customerModel.syncToQuickbooks = true;
        this.customerModel.quickbooksId = null;
        this.customerModel.quickbooksName = null;
        this.customerModel.quickbooksLinkOnly = false;

        this.resolve(this.customerModel);
        this.dialogRef.hideDialog();
    }

    private async refresh() {
        await this.functionLockService.lock("REFRESH_QUICKBOOKS_CUSTOMERS");

        try {
            this.qbCustomers = null;
            this.searching = true;
            this.qbCustomers = await this.httpService.get("/customers/findQuickbooksCustomers", { searchString: this.searchString });
        }
        finally {
            this.searching = false;
            this.functionLockService.release("REFRESH_QUICKBOOKS_CUSTOMERS");
		}
    }

    async selectCustomer(qbCustomer: ICustomerModel) {
        this.slickScreenBlockerService.block();
        const updatedCustomerModel = await this.httpService.patch(`/customers/UpdateQuickbooksCustomerId?customerId=${this.customerModel.customerId}&quickbooksId=${qbCustomer.quickbooksId}&quickbooksLinkOnly=${this.linkOnly}`);
        this.slickScreenBlockerService.unblock();
        this.resolve(updatedCustomerModel);
        this.dialogRef.hideDialog();
    }

    onCancel() {
        this.resolve(null);
        this.dialogRef.hideDialog();
	}
}