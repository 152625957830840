import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { InventoryService, UtilsService, SleepService, GlobalsService } from "@services";
import { CustomerModel, ICustomerModel } from "@models/customers/customer.model";
import { ICustomerListModel } from "@models/customers/customer-list.model";
import { SlickDialogComponent, SlickValidationTypes } from "@slick-components";
import { IInventoryItemGridModel, IInventoryItemModel, IInventoryLocationsListModel, InventoryLineItemModel, InventoryLocationModel } from "../../models";

@Component({
	selector: "inventory-locations-search",
	templateUrl: "./inventory-locations-search.component.html",
	styleUrls: ["./inventory-locations-search.css"],
	providers: [InventoryService]
})

export class InventoryLocationsSearchComponent implements OnChanges {
	
	@Input() inventoryLineItem: InventoryLineItemModel
	@Input() warehouseId: number;
	@Input() itemId: number;
	@Input() disabled: boolean;
	@Input() showAll: boolean = false;
	@Output() inventoryItemLocationChange: EventEmitter<IInventoryLocationsListModel> = new EventEmitter<IInventoryLocationsListModel>();
	
	results: IInventoryLocationsListModel[];
	selectedLocationBasic: IInventoryLocationsListModel;
	searchText: string;
	defaultLocations: IInventoryLocationsListModel[];
	isMizu = (GlobalsService.company.companyId === 1);

	constructor(private readonly inventoryService: InventoryService) {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.inventoryLineItem?.inventoryItemLocationId) {
			this.searchText = this.inventoryLineItem.inventoryItemLocationName;
		}

		if (changes.inventoryItemModel) {
			this.searchText = "";
			this.selectedLocationBasic = null;
		}
	}

	async onKeyUp(e: KeyboardEvent) {
	}

	async onBlur() {
	}

	async search(searchText) {
		this.results = await this.inventoryService.getLocationsForList(this.warehouseId, this.itemId, this.showAll, searchText);

	}

	async onSelect(location: IInventoryLocationsListModel) {
		this.selectedLocationBasic = location;
		this.searchText = location.locationName;
		this.inventoryItemLocationChange.emit(location);
	}

	searchTimeout: NodeJS.Timeout;
	async onSearchTextChange(searchText: string) {
		clearTimeout(this.searchTimeout);

		this.searchTimeout = setTimeout(async () => {
			this.searchText = searchText;
			const searchResults = await this.inventoryService.getLocationsForList(this.warehouseId, this.itemId, this.showAll, searchText);

			const searchResult = searchResults.find(x => x.locationName.toLowerCase().trim() === searchText.toLowerCase().trim());
			if (searchResult)
				this.onSelect(searchResult);
		}, 700);

	}
}
