import {IAppointmentModel} from "@models";

export interface IHoveredAppointmentModel {
    appointment: IAppointmentModel;
    appointmentBlock: HTMLDivElement;
}

export class HoveredAppointmentModel implements IHoveredAppointmentModel {
    constructor(appointment: IAppointmentModel, appointmentBlock: HTMLDivElement) {
        this.appointment = appointment;
        this.appointmentBlock = appointmentBlock;
    }

    appointment: IAppointmentModel;
    appointmentBlock: HTMLDivElement;
}
