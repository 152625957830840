import { ICustomerModel } from "../customers/customer.model";
import { IAppointmentModel } from "../appointments/appointment.model";
import { IInvoiceModel } from '../invoices/invoice.model';
import { UtilsService } from "@services/utils/utils.service";
import { IAddressModel } from "../latham-ordering/address.model";
import { ICustomerMultipleAddressModel } from "../customers/customer-multiple-address.model";
import { IJobCostingDetailModel } from "../job-costing/job-costing-detail.model";


export interface IJobModel {
	jobId: number;
	jobTypeId: number;
	jobTypeDisplayName: string;
	jobStatusId: number;
	jobStatusDisplayName: string;
	jobCustomer: ICustomerModel;
	oneTimeAddress: IAddressModel;
	customerMultipleAddress: ICustomerMultipleAddressModel;
	showOneTimeAddress: boolean;
	showJobSiteCustomer: boolean;
    billToCustomer: ICustomerModel;
    invoiceId: number;
	documentKey: string;
	jobName: string;
	description: string;
	jobNotes: string;
	sendEmails: string;
	creditHoldApprovedByUserId?: number;
	creditHoldApprovedByUserName: string;
	photoCount: number;
	qproSaleIdentifier?: number;
	appointments: IAppointmentModel[];
	showAllItemLocations: boolean;
	invoice: IInvoiceModel;
	jobCostingDetailModel: IJobCostingDetailModel;
	lastAppointmentDate: Date;

}

export class JobModel implements IJobModel {
	constructor() {
		this.jobId = 0;
		this.documentKey = UtilsService.newGuid();
	}
	jobId: number;
	jobTypeId: number;
	jobTypeDisplayName: string;
	jobStatusId: number;
	jobStatusDisplayName: string;
	jobCustomer: ICustomerModel;
	oneTimeAddress: IAddressModel;
	customerMultipleAddress: ICustomerMultipleAddressModel;
	showOneTimeAddress: boolean;
	showJobSiteCustomer: boolean;
    billToCustomer: ICustomerModel;
    invoiceId: number;
	documentKey: string;
	jobName: string;
	description: string;
	jobNotes: string;
	sendEmails: string;
	creditHoldApprovedByUserId?: number;
	creditHoldApprovedByUserName: string;
	photoCount: number;
	qproSaleIdentifier?: number;
	showAllItemLocations: boolean;
    appointments: IAppointmentModel[];
	invoice: IInvoiceModel;
	jobCostingDetailModel: IJobCostingDetailModel;
	lastAppointmentDate: Date;


}
