export interface IInvoiceStatusModel {
	invoiceStatusId: number;
	name: string;
	systemStatus: boolean;
	active: boolean;
}

export class InvoiceStatusModel implements IInvoiceStatusModel {
	constructor() {
		this.invoiceStatusId = 0;
		this.systemStatus = false;
		this.active = true;
	}

	invoiceStatusId: number;
	name: string;
	systemStatus: boolean;
	active: boolean;
}