import { EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, Directive } from '@angular/core';
import { AppointmentsService, JobsService, LookupService, SleepService, GlobalsService, UtilsService, ScheduleDisplayService } from '@services';
import { IJobModel, IDropdownModel, IInvoiceModel, AppointmentModel, IAppointmentModel, ICustomerJobHistoryModel, JobModel, AddressModel } from '@models';
import { ConflictingAppointmentsDialogComponent } from '@shared-components/conflicting-appointments-dialog';
import Swal from 'sweetalert2';

@Directive()
export class JobEditBase implements OnChanges {
	@Input() jobModel: IJobModel;
	@Input() defaultApptId: number;
	@Output() onSave = new EventEmitter<IJobModel>();
	@Output() onCancel = new EventEmitter<IJobModel>();

	@ViewChild('conflictingApptDialogRef', { static: true }) conflictingApptDialogRef: ConflictingAppointmentsDialogComponent;

	jobTypes: IDropdownModel[];
	jobStatuses: IDropdownModel[];

	isSubmitted: boolean;

	customerJobHistory: ICustomerJobHistoryModel[];
	customerDocumentKey: string;
	constructor(
		protected readonly jobsService: JobsService,
		protected readonly appointmentsService: AppointmentsService,
		protected readonly lookupService: LookupService
	) {
		this.jobTypes = this.lookupService.getJobTypesForDropdown();
		this.jobStatuses = this.lookupService.getJobStatusesForDropdown();
	}

	ngOnChanges() {
		this.isSubmitted = false;		
		this.customerJobHistory = null;

		if (this.jobModel) {
			// If we have a default appt, move it to the top
			if (this.defaultApptId && this.jobModel.appointments[0].appointmentId !== this.defaultApptId) {
				const defaultAppt = this.jobModel.appointments.find(x => x.appointmentId === this.defaultApptId);
				if (defaultAppt) {
					defaultAppt.uiState = "expanded";
					this.jobModel.appointments = this.jobModel.appointments.filter(x => x.appointmentId !== this.defaultApptId);
					this.jobModel.appointments.unshift(defaultAppt);
				}
			}

			if (this.jobModel.appointments && this.jobModel.appointments.length > 0 && (this.jobModel.appointments.findIndex(x => x.uiState === 'expanded') < 0))
				this.jobModel.appointments[0].uiState = "expanded";

			this.setOneTimeAddress();
			if (this.jobModel.showJobSiteCustomer)
				this.customerDocumentKey = this.jobModel.jobCustomer?.documentKey;
			else
				this.customerDocumentKey = null;
		}
	}

	onAppointmentDelete(appointment: IAppointmentModel) {
		this.jobModel.appointments = this.jobModel.appointments.filter(appt => appt.uuid !== appointment.uuid);
		this.appointmentsService.deleteAppointment(appointment.appointmentId);
	}

	async isValid(): Promise<boolean> {
		let isValid = true;

		// Mizu only check for critical credit hold
		// allow them to schedule estimates
		if (GlobalsService.company.companyId === 1) {
			if (this.jobModel.jobId === 0 && this.jobModel.billToCustomer.criticalCreditHold === true && GlobalsService.userInfo.userName.toLowerCase() !== 'marisa' && this.jobModel.appointments.find(x => x.appointmentId === 0 && x.appointmentTypeDisplayName !== "Estimate")) {
				await Swal.fire({
					icon: 'error',
					title: 'Critical Hold',
					text: `Warning: The bill to customer is on critical credit hold.  Only Marisa can schedule this.`,
					confirmButtonColor: '#007bff',
					heightAuto: false
				});

				return false;
			}
		}

		//check if the job is closed, if so we need to check to make sure all the appts are cancelled, complete or not finished in order for them to save
		if (this.jobModel.appointments && this.jobModel.appointments.length > 0) {
			if (this.jobModel.jobStatusId === 3){
				const openAppts = await this.jobsService.getOpenAppointments(this.jobModel.appointments);
				if (openAppts.length > 0) {
					let appointmentsString = openAppts.map(appt => {
						let scheduledDate = appt.scheduledDateTime.toLocaleDateString();
						let startTime = appt.scheduledDateTime.toLocaleTimeString();
						return `${appt.appointmentType} - ${scheduledDate} - ${startTime}`;
					}).join("<br/>");

					await Swal.fire({
						icon: 'error',
						title: 'A job with open appointments cannot be closed.',
						html: appointmentsString,
						confirmButtonColor: '#007bff',
						heightAuto: false
					});
					isValid = false;
					return;
				}
			}
		}

		if (!this.jobModel.jobStatusId)
			isValid = false;

		if (!this.jobModel.jobCustomer && this.jobModel.showJobSiteCustomer)
			isValid = false;

		if (!this.jobModel.oneTimeAddress?.address1 && this.jobModel.showOneTimeAddress)
			isValid = false;

		if (!this.jobModel.billToCustomer && this.jobModel.showOneTimeAddress)
			isValid = false;

		if (!this.jobModel.jobTypeId)
			isValid = false;

		if (!this.jobModel.jobStatusId)
			isValid = false;

		if (this.jobModel.appointments && this.jobModel.appointments.find(appt => this.appointmentsService.isApptValid(appt) === false))
			isValid = false;

		return isValid;
	}

	setOneTimeAddress() {
		if (GlobalsService.company.useOneTimeAddress === false) {
			this.jobModel.showJobSiteCustomer = true;
            return;
        }

        // If this is a new job and onetimeaddress is on, check to see what default is set to.
        if (this.jobModel.jobId === 0 && GlobalsService.company.defaultToJobCustomer === false) {
            this.jobModel.showOneTimeAddress = true;
            this.jobModel.showJobSiteCustomer = false;
        }

        else if (this.jobModel.jobId === 0 && GlobalsService.company.defaultToJobCustomer === true) {
            this.jobModel.showJobSiteCustomer = true;
            this.jobModel.showOneTimeAddress = false;
        }

        else {
            if ((this.jobModel.jobCustomer?.customerId ?? 0) === 0) {
                if (GlobalsService.company.defaultToJobCustomer === false) {
                    this.jobModel.showOneTimeAddress = true;
                    this.jobModel.showJobSiteCustomer = false;
                }
            }
        }

        if (!this.jobModel.oneTimeAddress)
            this.jobModel.oneTimeAddress = new AddressModel();
    }

}
