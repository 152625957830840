export interface IAppointmentStatusModel {
	appointmentStatusId: number;
	description: string;
	showOnSchedule: boolean;
	scheduleColor: string;
	borderColor: string;
	active: boolean;
	isCustom: boolean;
	isDefault: boolean;
	removeServiceAreaColor: boolean;
	showBorderOnMiniSchedule: boolean;
}

export class AppointmentStatusModel implements IAppointmentStatusModel {
	constructor() {
		this.appointmentStatusId = 0;
		this.showOnSchedule = true;
		this.showBorderOnMiniSchedule = true;
		this.isCustom = true;
		this.isDefault = false;
		this.active = true;
	}

	appointmentStatusId: number;
	description: string;
	showOnSchedule: boolean;
	scheduleColor: string;
	borderColor: string;
	active: boolean;
	isCustom: boolean;
	isDefault: boolean;
	removeServiceAreaColor: boolean;
	showBorderOnMiniSchedule: boolean;
}
