import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlickPagingComponent } from "./slick-paging.component";

@NgModule({
	imports: [CommonModule],
	declarations: [SlickPagingComponent],
	exports: [SlickPagingComponent]
})
export class SlickPagingModule { }

export { SlickPagingComponent }