import { Component, Input, Output, EventEmitter, ElementRef, OnInit, OnDestroy, OnChanges, SimpleChanges, ViewChild, forwardRef, ChangeDetectorRef, AfterViewInit } from "@angular/core";
import { SlickDropDownService } from "./slick-drop-down.service";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SlickSleepService } from "../utils/slick-sleep.service";
import { SlickUtilsService } from "../utils/slick-utils.service";
import { SlickInitService } from "../utils/slick-init.service";
import { SlickLogService } from "../utils/slick-log.service";
import { ISlickDropDownButtonModel } from "./slick-drop-down-button.model";

export enum SlickDropDownSearchTypes { startsWith, eachWord, any }

@Component({
	selector: 'slick-drop-down',
	templateUrl: 'slick-drop-down.component.html',
	providers: [SlickDropDownService, SlickLogService,
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SlickDropDownComponent),
			multi: true,
		}]
})
export class SlickDropDownComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor {
	@Input() placeholder: string = '';
	@Input() items: any[];
	@Input() idFieldName: string = "id";
	@Input() textFieldName: string = "text";
	@Input() compact: boolean = false;
	@Input() disabled: boolean = false;
	@Input() allowEmpty: boolean = true;
	@Input() getUrl: string;
	@Input() width: string = '100%';
	@Input() listWidth: string = '100%';
	@Input() height: string = '300px';
	@Input() showLoadingMessage: boolean = false;
	@Input() showDebug: boolean;
	@Input() tabindex: number;
	@Input() validationIndicator: boolean;
	@Input() validationIndicatorType: string;
	@Input() attachTo: string;
	@Input() searchType: SlickDropDownSearchTypes = SlickDropDownSearchTypes.startsWith;
	@Input() icon: string;
	@Input() isMobile: boolean = false;
	@Input() cssClass: string;

	@Output() onExpand: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
	@Output() onChange: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild("containerDiv") containerDiv: ElementRef;
	@ViewChild("dropdownList") dropdownList: ElementRef;
	@ViewChild("selectedTextInputRef") selectedTextInputRef: ElementRef;

	// This is necessary so that the scroll and resize events can add and remove the event properly,
	// as well as getting the correct ES6 function
	fnReposition = () => this.reposition();
	fnDocumentClick = (e) => this.documentClick(e);
	uuid: string;
	selectedId: string;
	selectedText: string;
	expanded: boolean = false;
	selectedItem: any;
	selectedIndex: number;
	filterText: string = '';
	visibleItems: any[];
	left: number;
	top: number;
	listGroupWidth: string;
	isVisible: boolean = false;
	isAndroid: boolean = ((SlickInitService.getPlatform() || '').toLowerCase() === 'android');



	constructor(private slickDropDownService: SlickDropDownService,
		private changeDetector: ChangeDetectorRef) {
		this.uuid = SlickUtilsService.newGuid();
	}

	async ngOnInit() {
		this.attachTo = this.attachTo || SlickInitService.getParams().attachTo || 'body';
	}

	ngAfterViewInit() {
		if (this.cssClass)
			(<HTMLDivElement>this.containerDiv.nativeElement).classList.add(...this.cssClass.split(" "));
	}

	propagateChange = (_: any) => { };

	// this is the initial value set to the component
	public writeValue(obj: any) {
		if (obj !== null && obj !== undefined)
			this.selectedId = obj;
		else
			this.selectedId = null;

		this.selectItem(this.selectedId, false);
		this.collapse();
	}

	// registers 'fn' that will be fired when changes are made
	// this is how we emit the changes back to the form
	public registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	// not used, used for touch input
	public registerOnTouched() { }

	async ngOnChanges(changes: SimpleChanges) {
		await SlickSleepService.sleep();

		if (changes.items) {
			this.visibleItems = this.slickDropDownService.getVisibleItems(this.items, this.idFieldName, this.textFieldName);
			this.selectItem(this.selectedId, false);
			if (this.selectedTextInputRef && document.activeElement === this.selectedTextInputRef.nativeElement)
				this.expand();
		}

		if (changes.showLoadingMessage) {
			await SlickSleepService.sleep();
			if (this.showLoadingMessage === true)
				this.selectedText = "Loading...";
		}

		if (changes.getUrl) {
			if (this.showLoadingMessage === true) {
				this.selectedText = "Loading...";
			}

			let items: any[] = await this.slickDropDownService.getItemsFromServer(this.getUrl)
			this.selectedText = null;
			this.items = items
			this.visibleItems = this.slickDropDownService.getVisibleItems(this.items, this.idFieldName, this.textFieldName);
			this.selectItem(this.selectedId, false);
			if (document.activeElement === this.selectedTextInputRef.nativeElement)
				this.expand();
		}

		if (changes.validationIndicator && changes.validationIndicator.firstChange) {
			if (!this.validationIndicatorType)
				this.validationIndicatorType = "error";
		}

		if (changes.allowEmpty)
			this.allowEmpty = (this.allowEmpty.toString().toLowerCase() === 'false') ? false : true;

		if (changes.isMobile)
			this.isMobile = (this.isMobile.toString().toLowerCase() === 'true') ? true : false;

		if (changes.disabled && changes.disabled.currentValue)
			this.disabled = (changes.disabled.currentValue.toString().toLowerCase() !== 'false');

		if (changes.compact && changes.compact.currentValue)
			this.compact = (changes.compact.currentValue.toString().toLowerCase() !== 'false');

		if (changes.searchType) {
			if (changes.searchType.currentValue.toString().toLowerCase() === 'eachword')
				this.searchType = SlickDropDownSearchTypes.eachWord;
			else if (changes.searchType.currentValue.toString().toLowerCase() === 'any')
				this.searchType = SlickDropDownSearchTypes.any;
			else
				this.searchType = SlickDropDownSearchTypes.startsWith;
		}
	}

	ngOnDestroy() {
		this.collapse();
		document.removeEventListener("click", this.fnDocumentClick, true);

		// it's okay if this fails.  It was already removed
		try {
			document.body.removeChild(this.dropdownList.nativeElement);
		}
		catch { }
	}

	async setFocus(showDropdownList: boolean = false) {
		this.filterText = '';
		this.visibleItems = this.slickDropDownService.getVisibleItems(this.items, this.idFieldName, this.textFieldName);

		// If this is true, just set expanded to false to force an expand
		if (showDropdownList === true)
			this.expanded = false;

		await SlickUtilsService.waitForElement("#slick-drop-down_" + this.uuid);
		(<HTMLInputElement>this.selectedTextInputRef.nativeElement).focus();
	}

	private async expand() {
		if (this.disabled)
			return;

		this.isVisible = true;

		this.changeDetector.detectChanges();

		SlickUtilsService.attachElement(this.dropdownList.nativeElement, this.attachTo)

		this.reposition();

		await SlickSleepService.sleep();
		this.dropdownList.nativeElement.style.display = "inline-block";

		const selectedItems = this.dropdownList.nativeElement.getElementsByClassName("active");
		if (selectedItems && selectedItems.length > 0)
			(<HTMLElement>selectedItems[0]).scrollIntoView({ block: 'nearest' });

		this.expanded = true;


		// This is necessary, otherwise `this` in the reposition function will be window
		// and not the component.
		// Since angular has no way to use the third parameter, I'm doing this in raw js
		document.addEventListener("click", this.fnDocumentClick, true);

		if (this.onExpand)
			this.onExpand.emit();
	}

	private collapse() {
		if (this.expanded === false)
			return;

		this.expanded = false;
		this.dropdownList.nativeElement.style.display = "none";
		// Since angular has no way to use the third parameter, I'm doing this in raw js
		document.removeEventListener("click", this.fnDocumentClick, true);
		SlickUtilsService.removeElement(this.dropdownList.nativeElement);

		this.isVisible = false;
	}

	private reposition() {
		const dropDownRect = this.containerDiv.nativeElement.getBoundingClientRect();
		const parentRect = (this.attachTo === 'body') ? null : document.body.querySelector(this.attachTo).getBoundingClientRect();

		const containerLeft = (this.attachTo === 'body') ? dropDownRect.left : (dropDownRect.left - parentRect.left);
		const containerTop = (this.attachTo === 'body') ? dropDownRect.top : (dropDownRect.top - parentRect.top);

		const containerHeight = this.containerDiv.nativeElement.offsetHeight;
		const containerWidth = dropDownRect.width;

		this.width = containerWidth;
		if (this.listWidth === '100%')
			this.listGroupWidth = this.width;
		else if (this.listWidth === 'auto')
			this.listGroupWidth = "auto";
		else
			this.listGroupWidth = this.listWidth;
		this.top = (containerTop + containerHeight);
		this.left = containerLeft;
	}

	private async documentClick(e: MouseEvent) {
		// Not entirely sure why, but iPhones call onFocus when clicking out of the dropdown
		if (!this.expanded || !e.target)
			return;

		if (SlickUtilsService.checkParentIdExists(<HTMLElement>e.target, "slick-drop-down_" + this.uuid) === true)
			return;

		(<HTMLInputElement>this.selectedTextInputRef.nativeElement).setSelectionRange(0, 0);
		if (this.selectedIndex !== null && this.visibleItems.length > 0) {
			let itemId = this.visibleItems[this.selectedIndex].id;
			this.selectItem(itemId, true);
		}

		this.collapse();
	}

	private selectItem(id: string | number, emitOnSelect: boolean) {
		let item = this.slickDropDownService.getSelectedItem(this.visibleItems, id);

		if (item) {
			this.selectedItem = item;
			this.selectedId = item.id;
			this.selectedText = item.text;
			this.selectedIndex = this.slickDropDownService.getItemIndex(this.visibleItems, this.selectedId);

			if (this.onChange)
				this.onChange.emit(this.selectedItem.item);
			if (emitOnSelect === true && this.onSelect)
				this.onSelect.emit(this.selectedItem.item);
			this.propagateChange(this.selectedId);
		}
		else {
			this.selectedItem = null;
			this.selectedText = null;
			this.selectedIndex = null;
		}
	}

	async onKeyDown(e: KeyboardEvent) {
		if (this.isAndroid)
			return;

		// Tab/enter
		if (e.which === 9 || e.which === 13) {
			this.collapse();

			if (this.selectedIndex === null || this.selectedIndex === undefined)
				return;

			(<HTMLInputElement>this.selectedTextInputRef.nativeElement).setSelectionRange(0, 0);
			let itemId = this.visibleItems[this.selectedIndex].id;
			this.selectItem(itemId, true);

			return true;
		}

		if (e.key && e.key.length > 1)
			return true;

		if (e.which === 46 || e.which === 8) {
			e.preventDefault();
			e.stopPropagation();
		}

		if (e.which >= 32 && e.which <= 126) {
			e.preventDefault();
			e.stopPropagation();
		}
	}

	async onKeyUp(e: KeyboardEvent) {
		e.preventDefault();
		e.stopPropagation();

		// If this is android, it won't pass us any key data so just read the entire input element
		if (this.isAndroid === true) {
			await SlickSleepService.sleep();

			this.filterText = (<HTMLInputElement>this.selectedTextInputRef.nativeElement).value;
			this.filterSearch();
			return;
		}

		// Esc
		if (e.which === 27) {
			this.collapse();

			return false;
		}

		// del or backspace
		if (e.which === 46 || e.which === 8 && !this.filterText) {
			e.preventDefault();
			e.stopPropagation();

			if (this.allowEmpty === true) {
				this.selectItem(null, true);
				this.selectedId = null;
				this.propagateChange(null);

				if (this.onChange)
					this.onChange.emit(null);

				if (this.onSelect)
					this.onSelect.emit(null);
			}

			return false;
		}

		if (e.which === 8 && this.filterText) {
			this.filterText = this.filterText.substring(0, this.filterText.length - 1);
			if (this.filterText.length === 0) {
				this.selectedText = '';
				this.selectedIndex = null;
				this.visibleItems = this.slickDropDownService.getVisibleItems(this.items, this.idFieldName, this.textFieldName);
			}
			else
				this.filterSearch();
			return false;
		}

		// Arrowup
		if (e.which === 38) {
			if (this.selectedIndex === null || this.selectedIndex === undefined)
				this.selectedIndex = 0;

			if (this.selectedIndex > 0)
				this.selectedIndex--;

			return false;
		}

		// Arrowdown
		if (e.which === 40) {
			if (this.selectedIndex === null || this.selectedIndex === undefined)
				this.selectedIndex = -1;

			if (!this.expanded || !this.visibleItems) {
				this.expand();
				return false;
			}

			if (this.selectedIndex < this.visibleItems.length - 1)
				this.selectedIndex++;

			return false;
		}

		// If F1 is pressed, key length will be 2
		if (e.key.length > 1 || e.ctrlKey || e.altKey)
			return false;

		if (e.which >= 32 && e.which <= 126) {
			this.filterText += e.key;

			this.filterSearch();
		}

		return true;
	}

	private async filterSearch() {
		if (!this.expanded)
			this.expand();

		this.visibleItems = this.slickDropDownService.getVisibleItems(this.items, this.idFieldName, this.textFieldName);
		if (this.searchType === SlickDropDownSearchTypes.startsWith) {
			this.visibleItems = this.visibleItems.filter(x => (x.text ?? '').toLowerCase().indexOf((this.filterText ?? '').toLowerCase()) === 0);
			if (this.visibleItems.length > 0) {
				this.selectedIndex = 0;
				if (this.isAndroid === false) {
					this.selectedText = this.visibleItems[0].text;
					await SlickSleepService.sleep();
					(<HTMLInputElement>this.selectedTextInputRef.nativeElement).setSelectionRange(this.filterText.length, this.selectedText.length);
				}
			}
			else {
				this.selectedText = this.filterText;
				this.selectedIndex = null;
			}
		}
		else if (this.searchType === SlickDropDownSearchTypes.eachWord) {
			this.visibleItems = this.visibleItems.filter(x => (" " + x.text.toLowerCase()).indexOf(" " + this.filterText.toLowerCase()) >= 0);
			if (this.visibleItems.length > 0) {
				this.selectedIndex = 0;
				if (this.isAndroid === false) {
					this.selectedText = this.visibleItems[0].text;
					const selectedTextStart = (" " + this.visibleItems[0].text).toLowerCase().indexOf(" " + this.filterText.toLowerCase());
					await SlickSleepService.sleep();
					(<HTMLInputElement>this.selectedTextInputRef.nativeElement).setSelectionRange(selectedTextStart, selectedTextStart + this.filterText.length);
				}
			}
			else {
				this.selectedText = this.filterText;
				this.selectedIndex = null;
			}

		}
	}

	lastEvent: string = null;
	onDropdownClicked(e: MouseEvent) {
		// This is some hacky code that only allows 1 event every 100ms
		// This is because we don't know if they will tab into the focus or click into it.  If they tab, we only get 1 focus event
		// if they click we get focus AND click.
		if (this.lastEvent !== null)
			return;

		this.lastEvent = e.type;

		// If this is the focus event, we may or may not get
		// a click event after.  If it is, wait a bit longer for
		// slower computers to fire the click
		if (this.lastEvent === 'focus')
			setTimeout(() => this.lastEvent = null, 500);
		else
			setTimeout(() => this.lastEvent = null, 250);

		this.filterText = '';
		this.visibleItems = this.slickDropDownService.getVisibleItems(this.items, this.idFieldName, this.textFieldName);
		this.selectItem(this.selectedId, false);
		(<HTMLInputElement>this.selectedTextInputRef.nativeElement).focus();

		if (this.expanded === false)
			this.expand();
		else
			this.collapse();
	}

	onItemClick(item: any) {
		this.selectItem(item.id, true);
		this.collapse();
	}
}