import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlickScreenBlockerService } from "./slick-screen-blocker.service";

@NgModule({
	imports: [CommonModule],
	providers: [SlickScreenBlockerService]
})
export class SlickScreenBlockerModule { }

export { SlickScreenBlockerService }
