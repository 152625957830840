import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";

import { IConflictingAppointmentResultModel, IHolidayModel } from "@models";
import moment from "moment";

@Injectable()
export class HolidayService {
    constructor(private httpService: HttpService) { }

    getHolidays(year: number): Promise<IHolidayModel[]> {
        return this.httpService.get(`/holiday/getHolidays?year=${year}`);
    }

    deleteHoliday(holidayId: number): void {
        this.httpService.get(`/holiday/deleteHoliday?holidayId=${holidayId}`);
    }

    saveHoliday(holidayModels: IHolidayModel[], holidayYear: number): Promise<IHolidayModel[]> {
        return this.httpService.post(`/holiday/saveHolidays?holidayYear=${holidayYear}`, holidayModels);
    }

    checkForConflicting(holiday: IHolidayModel[]): Promise<IConflictingAppointmentResultModel> {
        holiday.forEach(holiday => {
            holiday.dateStart = this.convertToDateOnly(holiday.dateStart);
            holiday.dateEnd = this.convertToDateOnly(holiday.dateEnd);
        });
        return this.httpService.post("/holiday/checkForConflicting", holiday);
    }

    sortHolidays(holidays) {
        const sortedHolidays = holidays.sort((a, b) => {
            const dateA = moment(a.dateStart, 'MM/DD/YYYY hh:mm A');
            const dateB = moment(b.dateStart, 'MM/DD/YYYY hh:mm A');

            if (dateA.isAfter(dateB)) return 1;
            else if (dateA.isBefore(dateB)) return -1;
            else return 0;
        });
        return sortedHolidays;
    }


    convertToDateOnly(date: Date): Date {
        // Extract year, month, and day
        const year = moment(date).year();
        const month = moment(date).month(); 
        const day = moment(date).date();

        // Return new Date object with only the year, month, and day
        return new Date(year, month, day);
    }
}