export interface ISlickGridResponseModel {
	totalRecords: number;
	data: any[];
}

export class SlickGridResponseModel implements ISlickGridResponseModel {
	totalRecords: number;
	data: any[];
}

