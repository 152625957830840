import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SlickConfirmDialogModule, SlickEmailerModule, SlickToastModule } from "@slick-components";

import { EmailerDialogComponent } from "./emailer-dialog.component";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SlickConfirmDialogModule, SlickEmailerModule
	],
	declarations: [
		EmailerDialogComponent
	],
	exports: [EmailerDialogComponent]
})
export class EmailerDialogModule {
}

export { EmailerDialogComponent }