import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { UtilsService } from './utils/utils.service';
import * as moment from 'moment';
import { CalcHourModel, IAppointmentModel, ICalcHourModel, IScheduledAppointmentModel } from '@models';
import { AppointmentsService } from './appointments.service';
import { ScheduleDisplayService } from './schedule-display.service';
import { ScheduleStore } from './schedule.store';
import { ConflictingAppointmentsDialogComponent } from '@shared-components/conflicting-appointments-dialog';
import { HoveredAppointmentModel } from "@models/schedule/hovered-appointment.model";
import Timer = NodeJS.Timer;
import { GlobalsService } from './utils/globals.service';

@Injectable()
export class ScheduleMouseEventsService implements OnDestroy {
	private fnDocumentMouseDown = ((e) => this.documentMouseDown(e));
	private fnDocumentMouseMove = ((e) => this.documentMouseMove(e));
	private fnDocumentMouseUp = ((e) => this.documentMouseUp(e));
	private fnDocumentDoubleClick = ((e) => this.documentMouseDoubleClick(e));
	private fnDocumentMouseEnter = ((e) => this.documentMouseEnter(e));
	private fnDocumentMouseLeave = ((e) => this.documentMouseLeave(e));

	private singleClickTimer: Timer;
	private isSingleClick: boolean;
	private showApptPreviewTimer: Timer;

	isDirty = false;
	isResizeApptStart = false;
	isResizeTravel = false;
	isResizeApptEnd = false;
	moveInitialClickXPos: number;
	moveTimeOffset: number;
	lastCalcHour = '';
	openDialog: EventEmitter<IScheduledAppointmentModel> = new EventEmitter();
	conflictingApptDialogRef: ConflictingAppointmentsDialogComponent;

	constructor(private appointmentsService: AppointmentsService,
		private scheduleStore: ScheduleStore,
		private scheduleDisplayService: ScheduleDisplayService) {
	}

	ngOnDestroy() {
		this.destroyMouseEvents();
	}

	initMouseEvents() {
		document.addEventListener('mousedown', this.fnDocumentMouseDown, true);
		document.addEventListener('dblclick', this.fnDocumentDoubleClick, true);
		document.addEventListener('mouseup', this.fnDocumentMouseUp, true);
		document.addEventListener('mouseenter', this.fnDocumentMouseEnter, true);
		document.addEventListener('mouseleave', this.fnDocumentMouseLeave, true);
	}

	destroyMouseEvents() {
		document.removeEventListener('mousedown', this.fnDocumentMouseDown, true);
		document.removeEventListener('mousemove', this.fnDocumentMouseMove, true);
		document.removeEventListener('mouseup', this.fnDocumentMouseDown, true);
		document.removeEventListener('mouseenter', this.fnDocumentMouseEnter, true)
		document.removeEventListener('mouseleave', this.fnDocumentMouseLeave, true)
	}

	addToSchedule(e: MouseEvent, appt: IScheduledAppointmentModel) {
		this.scheduleStore.isMovingAppt = true;
		this.scheduleStore.appointmentToModify = appt;
		this.scheduleStore.appointmentToModifyDiv = null;

		if (this.scheduleStore.appointmentToModify.scheduledMinutes >= 60)
			this.moveTimeOffset = 30;
		else if (this.scheduleStore.appointmentToModify.scheduledMinutes >= 45)
			this.moveTimeOffset = 15;
		else
			this.moveTimeOffset = 0;

		this.moveTimeOffset += this.scheduleStore.appointmentToModify.travelMinutes;


		document.addEventListener('mousemove', this.fnDocumentMouseMove, true);
	}

	private async documentMouseDown(e: MouseEvent) {
		document.removeEventListener('mousemove', this.fnDocumentMouseMove, true);

		clearTimeout(this.showApptPreviewTimer);
		this.scheduleStore.hoveredAppointment = null;
		this.scheduleStore.appointmentToModify = null;
		this.scheduleStore.appointmentToModifyDiv = null;

		
		this.isSingleClick = true;
		this.singleClickTimer = setTimeout(async () => {			
			if (this.isSingleClick) {
				const targetEl = <HTMLElement>e.target;

				this.clear();

				if (e.button !== 0) {
					return;
				}

				this.lastCalcHour = null;

				if (UtilsService.checkParentClassExists(targetEl, 'appointment-block')) {
					this.moveInitialClickXPos = e.pageX;

					this.scheduleStore.appointmentToModifyDiv = <HTMLDivElement>UtilsService.findParent(<HTMLDivElement>e.target, 'appointment-block');

					const appointmentId = parseInt(this.scheduleStore.appointmentToModifyDiv.getAttribute('data-apptid'));
					// I don't know why this is here
					//if (this.scheduleStore.appointmentToModify && this.scheduleStore.appointmentToModify.appointmentId === appointmentId) {
					//	return;
					//}

					document.addEventListener('mousemove', this.fnDocumentMouseMove, true);

					this.scheduleDisplayService.turnOffPointerEvents();

					this.scheduleStore.appointmentToModify = UtilsService.clone(this.scheduleStore.getAppointment(appointmentId) || this.scheduleStore.getUnscheduledAppointment(appointmentId));
					this.scheduleStore.appointmentToModifyOrginal = UtilsService.clone(this.scheduleStore.appointmentToModify);

					if (this.scheduleStore.appointmentToModify && this.scheduleStore.appointmentToModify.locked === true)
						return;

					if (GlobalsService.checkPermission('appointments', 'edit') === false)
						return;

					if (targetEl.classList.contains('resize-start')) {
						document.body.style.cursor = 'ew-resize';
						this.isResizeApptStart = true;
					} else if (targetEl.classList.contains('resize-travel')) {
						document.body.style.cursor = 'ew-resize';
						this.isResizeTravel = true;
					} else if (targetEl.classList.contains('resize-end')) {
						document.body.style.cursor = 'ew-resize';
						this.isResizeApptEnd = true;
					} else {
						document.body.style.cursor = 'move';
						this.scheduleStore.isMovingAppt = !this.scheduleStore.appointmentToModify.locked;
						// Find out where the click happened and how far of an offset that is from the appt start block
						// First, we need a ref to the schedule div

						// Next get the resource line
						const resourceTimeSlotLine = this.scheduleStore.getResourceLine(this.scheduleStore.appointmentToModify.resourceId, this.scheduleStore.appointmentToModify.appointmentBlockStart);

						// Get all the td time slots in the row
						if (!this.scheduleStore.appointmentToModify.unscheduled) {
							const allTimeSlots = resourceTimeSlotLine.querySelectorAll('div.time-slot');
						
							for (let i = 0; i < allTimeSlots.length; i++) {
								const timeSlot = <HTMLDivElement>allTimeSlots[i];
								const left = timeSlot.getBoundingClientRect().left;
								const right = left + timeSlot.offsetWidth;
								if (this.moveInitialClickXPos >= left && this.moveInitialClickXPos < right) {
									const calcHourModel = this.getCalcHour(timeSlot);
									const clickDateTime = moment(this.scheduleStore.appointmentToModify.appointmentBlockStart).startOf('day').add(calcHourModel.hour, 'hours').add(calcHourModel.minute, 'minutes');
									this.moveTimeOffset = clickDateTime.diff(moment(this.scheduleStore.appointmentToModify.appointmentBlockStart), 'minutes');
									break;
								}
							}
						}
					}
				}
			}
		}, 150);
	}

	private documentMouseMove(e: MouseEvent) {
		const el = <HTMLElement>e.target;

		if (el.classList.contains('time-slot') === false) {
			return;
		}

		// If we're moving the appt, get the resource of the current mouse position
		if (this.scheduleStore.isMovingAppt) {
			const resourceLine = <HTMLDivElement>UtilsService.getParentByClass(el, 'resource-line');
			const newResourceId = parseInt(resourceLine.getAttribute('data-resourceid'));
			// If we've changed resource, set last calc hour to null so that we force a redraw
			if (newResourceId !== this.scheduleStore.appointmentToModify.resourceId) {
				this.lastCalcHour = null;
			}
			this.scheduleStore.appointmentToModify.resourceId = newResourceId;
		}

		const calcHourModel = this.getCalcHour(el);
		if (!calcHourModel || this.lastCalcHour === calcHourModel.calcHour) {
			return;
		}

		this.lastCalcHour = calcHourModel.calcHour;

		this.isDirty = true;

		if (this.isResizeApptStart === true) {
			this.doResizeApptStart(calcHourModel);
		} else if (this.isResizeTravel === true) {
			this.doResizeApptTravel(calcHourModel);
		} else if (this.isResizeApptEnd === true) {
			this.doResizeApptEnd(calcHourModel);
		} else if (this.scheduleStore.isMovingAppt === true) {
			const date = moment(el.getAttribute('data-calcdate'), 'YYYYMMDD').toDate();
			this.doMoveAppt(date, calcHourModel);
		}
		
	}

	private async documentMouseUp(e: MouseEvent) {
		clearTimeout(this.showApptPreviewTimer);
		clearTimeout(this.singleClickTimer);

		this.scheduleStore.hoveredAppointment = null;

		this.scheduleDisplayService.turnOnPointerEvents();

		if (!this.scheduleStore.appointmentToModify || this.scheduleStore.appointmentToModify.unscheduled) {
			this.scheduleStore.appointmentToModify = null;
			this.scheduleStore.appointmentToModifyOrginal = null;
			this.clear();
			return;
		}
		
		const apptToSave: IScheduledAppointmentModel = UtilsService.clone(this.scheduleStore.appointmentToModify);
		const orginalAppt: IScheduledAppointmentModel = UtilsService.clone(this.scheduleStore.appointmentToModifyOrginal);
		this.scheduleStore.appointmentToModify = null;
		this.scheduleStore.appointmentToModifyOrginal = null;

		this.isSingleClick = false;

		this.clear();

		if (this.isDirty) {
			this.isDirty = false;

			let shouldSave = true;

			const conflictingAppts = await this.appointmentsService.checkForConflicts([apptToSave]);

			if (conflictingAppts)
				shouldSave = await this.conflictingApptDialogRef.showDialog(conflictingAppts);

			if (shouldSave) {
				// This is purposfully NOT doing an await.  We only need to save, not wait for it to come back
				this.appointmentsService.moveAppointment(apptToSave);
				this.scheduleStore.setAppointment(apptToSave);
				this.scheduleDisplayService.renderMiniScheduleAppointment(apptToSave);
			} else {
				// ROllback
				this.scheduleDisplayService.removeAppointment(orginalAppt.appointmentId);
				this.scheduleStore.deleteAppointment(orginalAppt.appointmentId);
				this.scheduleStore.setAppointment(orginalAppt);
				this.scheduleDisplayService.renderAppointment(orginalAppt);
				this.scheduleDisplayService.renderMiniScheduleAppointment(orginalAppt);
			}
		}
	}

	private documentMouseDoubleClick(e: MouseEvent) {
		clearTimeout(this.showApptPreviewTimer);
		this.scheduleStore.hoveredAppointment;

		this.isSingleClick = false;
		clearTimeout(this.singleClickTimer);

		const targetEl = <HTMLElement>e.target;

		this.clear();

		if (e.button !== 0) {
			return;
		}

		this.lastCalcHour = null;
		if (UtilsService.checkParentClassExists(targetEl, 'appointment-block')) {
			this.scheduleStore.appointmentToModifyDiv = <HTMLDivElement>UtilsService.findParent(<HTMLDivElement>e.target, 'appointment-block');

			const appointmentId = parseInt(this.scheduleStore.appointmentToModifyDiv.getAttribute('data-apptid'));
			if (this.scheduleStore.appointmentToModify && this.scheduleStore.appointmentToModify.appointmentId === appointmentId) {
				return;
			}

			this.scheduleStore.appointmentToModify = this.scheduleStore.getAppointment(appointmentId);
			this.openDialog.emit(this.scheduleStore.appointmentToModify);
		}
	}

	private documentMouseEnter(e: MouseEvent) {
		clearTimeout(this.showApptPreviewTimer);
		this.scheduleStore.hoveredAppointment = null;


		const targetEl = <HTMLElement>e.target;
		if (UtilsService.checkParentClassExists(targetEl, 'appointment-block')) {
			const parentDiv = <HTMLDivElement>UtilsService.findParent(targetEl, 'appointment-block');
			const appointmentId = parseInt(parentDiv.getAttribute('data-apptid'));
			if (appointmentId) {
				// Jeff Dumas Construction wanted the delay to be really quick
				const delay = (GlobalsService.company.companyId === 661) ? 200 : 600;
				this.showApptPreviewTimer = setTimeout(async () => {
					const hoveredAppointment = await this.appointmentsService.getAppointment(appointmentId, true);
					this.scheduleStore.hoveredAppointment = new HoveredAppointmentModel(hoveredAppointment, parentDiv);
				}, delay)
			}
		}
	}

	private documentMouseLeave(e: MouseEvent) {
		clearTimeout(this.showApptPreviewTimer);
		this.scheduleStore.hoveredAppointment = null;
	}

	private clear() {
		document.removeEventListener('mousemove', this.fnDocumentMouseMove, true);

		this.scheduleDisplayService.turnOnPointerEvents();
		clearTimeout(this.singleClickTimer);

		document.body.style.cursor = 'auto';
		this.isSingleClick = false;
		this.isResizeApptStart = false;
		this.isResizeTravel = false;
		this.isResizeApptEnd = false;
		this.scheduleStore.isMovingAppt = false;
		this.moveInitialClickXPos = null;
		this.moveTimeOffset = null;
		this.scheduleStore.appointmentToModify = null;
		this.scheduleStore.appointmentToModifyOrginal = null;
		this.scheduleStore.appointmentToModifyDiv = null;
	}

	private doResizeApptStart(calcHourModel: CalcHourModel) {
		const newBlockStartTime = moment(this.scheduleStore.appointmentToModify.scheduledDateTime).startOf('day').add(calcHourModel.hour, 'hour').add(calcHourModel.minute, 'minutes');
		const scheduledMinutes = moment(this.scheduleStore.appointmentToModify.appointmentBlockEnd).diff(newBlockStartTime, 'minutes') - this.scheduleStore.appointmentToModify.travelMinutes;
		// Only allow 15 mins at the min
		if (scheduledMinutes <= 0) {
			return;
		}

		this.scheduleStore.appointmentToModify.scheduledMinutes = scheduledMinutes;
		this.scheduleStore.appointmentToModify.scheduledDateTime = newBlockStartTime.add(this.scheduleStore.appointmentToModify.travelMinutes, "minutes").toDate();
		this.scheduleStore.appointmentToModify.appointmentBlockStart = moment(this.scheduleStore.appointmentToModify.appointmentBlockEnd).subtract((this.scheduleStore.appointmentToModify.scheduledMinutes + this.scheduleStore.appointmentToModify.travelMinutes), 'minutes').toDate();
		this.scheduleDisplayService.renderAppointment(this.scheduleStore.appointmentToModify, this.scheduleStore.appointmentToModifyDiv);
	}

	private doResizeApptTravel(calcHourModel: CalcHourModel) {
		const newTravelEndTime = moment(this.scheduleStore.appointmentToModify.scheduledDateTime).startOf('day').add(calcHourModel.hour, 'hour').add(calcHourModel.minute, 'minutes');
		const scheduledTravelMinutes = newTravelEndTime.diff(moment(this.scheduleStore.appointmentToModify.appointmentBlockStart), 'minutes');
		// Only allow 15 mins at the min
		if (scheduledTravelMinutes <= 0) {
			return;
		}

		this.scheduleStore.appointmentToModify.scheduledDateTime = moment(this.scheduleStore.appointmentToModify.appointmentBlockStart).add(scheduledTravelMinutes, 'minutes').toDate();
		this.scheduleStore.appointmentToModify.travelMinutes = scheduledTravelMinutes;
		this.scheduleStore.appointmentToModify.appointmentBlockEnd = moment(this.scheduleStore.appointmentToModify.scheduledDateTime).add(this.scheduleStore.appointmentToModify.scheduledMinutes, 'minutes').toDate();

		this.scheduleDisplayService.renderAppointment(this.scheduleStore.appointmentToModify, this.scheduleStore.appointmentToModifyDiv);
	}

	private doResizeApptEnd(calcHourModel: CalcHourModel) {
		const newBlockEndTime = moment(this.scheduleStore.appointmentToModify.scheduledDateTime).startOf('day').add(calcHourModel.hour, 'hour').add(calcHourModel.minute, 'minutes').add(15, 'minutes');
		const scheduledMinutes = newBlockEndTime.diff(moment(this.scheduleStore.appointmentToModify.scheduledDateTime), 'minutes');
		// Only allow 15 mins at the min
		if (scheduledMinutes <= 0) {
			return;
		}

		this.scheduleStore.appointmentToModify.scheduledMinutes = scheduledMinutes;
		this.scheduleStore.appointmentToModify.appointmentBlockEnd = moment(this.scheduleStore.appointmentToModify.scheduledDateTime).add(this.scheduleStore.appointmentToModify.scheduledMinutes, 'minutes').toDate();

		this.scheduleDisplayService.renderAppointment(this.scheduleStore.appointmentToModify, this.scheduleStore.appointmentToModifyDiv);
	}

	private doMoveAppt(date: Date, calcHourModel: CalcHourModel) {
		this.scheduleStore.appointmentToModify.appointmentBlockStart = moment(date)
			.startOf('day')
			.add(calcHourModel.hour, 'hours')
			.add(calcHourModel.minute, 'minutes')
			.subtract(this.moveTimeOffset, 'minutes')
			.toDate();

		this.scheduleStore.appointmentToModify.appointmentBlockEnd = moment(this.scheduleStore.appointmentToModify.appointmentBlockStart)
			.add(this.scheduleStore.appointmentToModify.travelMinutes + this.scheduleStore.appointmentToModify.scheduledMinutes, 'minutes')
			.toDate();

		this.scheduleStore.appointmentToModify.scheduledDateTime = moment(this.scheduleStore.appointmentToModify.appointmentBlockStart).add(this.scheduleStore.appointmentToModify.travelMinutes, 'minutes').toDate();
		if (!this.scheduleStore.appointmentToModifyDiv)  
			this.scheduleStore.appointmentToModifyDiv = this.scheduleDisplayService.renderAppointment(this.scheduleStore.appointmentToModify);		
		else
			this.scheduleDisplayService.renderAppointment(this.scheduleStore.appointmentToModify, this.scheduleStore.appointmentToModifyDiv);
	}

	private getCalcHour(el: HTMLElement): ICalcHourModel {
		const calcHour = el.getAttribute('data-calchour');
		if (!calcHour) {
			return null;
		}

		const calcHourModel = new CalcHourModel(calcHour);

		return calcHourModel;
	}

}
