import { Injectable } from '@angular/core';
import { HttpService } from './utils/http.service';
import { IJobCostingDetailModel, IJobCostingSetupModel, JobCostingSetupModel } from '@models';
import { GlobalsService } from './services.module';

@Injectable()
export class JobCostingService {
    constructor(private httpService: HttpService) { }

    public async getJobCostingSetupModel(): Promise<IJobCostingSetupModel> {
        return await this.httpService.get(`jobCosting/getJobCostingSetupModel?companyId=${GlobalsService.company.companyId}`);
       
    }

    public async saveJobCostingSetupModel(jobCostingSetupModel: IJobCostingSetupModel): Promise<IJobCostingSetupModel> {
        return await this.httpService.post(`jobCosting/saveJobCostingSetupModel`, jobCostingSetupModel);
    }


    public async calculateJobCostingDetail(invoiceId: number): Promise<IJobCostingDetailModel> {
        return await this.httpService.get(`jobCosting/calculateJobCostingDetail?invoiceId=${invoiceId}`);
    }

    public async generateJobCostingDetail(invoiceId?: number, jobId?: number): Promise<IJobCostingDetailModel> {
        let url = `jobCosting/generateJobCostingDetail?`;
        if (invoiceId != null)
            url += `&invoiceId=${invoiceId}`
        if (jobId != null) {
            url += `&jobId=${jobId}`;
        }
        return await this.httpService.get(url);

    }

    public isValidJobCostingSetupModel(model: IJobCostingSetupModel): boolean {
        return !model || (!model.useJobs && !model.useHours) && !model.driverAmount ? false : true;
    }




}
