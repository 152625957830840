import { Injectable } from "@angular/core";
import { HttpService } from "../utils/http.service";
import { ICreditCardPaymentTypeModel, ICreditCardTransactionLogModel, ICreditCardProcessPaymentModel, ICreditCardProcessPaymentResult, IPaymentModel } from "@models";
import { ICreditCardService } from "./credit-card.service";

@Injectable()
export class USAePayService implements ICreditCardService {

    constructor(private httpService: HttpService) { }

    async getNewCreditCardPaymentType(customerId: number): Promise<ICreditCardPaymentTypeModel> {
        return await this.httpService.get("/usaepay/getNewCreditCardPaymentType", { customerId: customerId });
    }

    async getCreditCardPaymentType(creditCardPaymentTypeId: number): Promise<ICreditCardPaymentTypeModel> {
        return await this.httpService.get("/usaepay/getCreditCardPaymentType", { creditCardPaymentTypeId: creditCardPaymentTypeId });
    }

    async getCreditCardPaymentTypeForCustomer(customerId: number): Promise<ICreditCardPaymentTypeModel[]> {
        return await this.httpService.get("/usaepay/getCreditCardPaymentTypeForCustomer", { customerId: customerId });
    }

    async addCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
        return await this.httpService.post("/usaepay/addCreditCardPaymentType", creditCardPaymentTypeModel);
    }

    async updateCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
        return await this.httpService.post("/usaepay/updateCreditCardPaymentType", creditCardPaymentTypeModel);
    }

    async deleteCreditCardPaymentType(creditCardPaymentTypeModel: ICreditCardPaymentTypeModel): Promise<ICreditCardPaymentTypeModel> {
        return await this.httpService.post("/usaepay/deleteCreditCardPaymentType", creditCardPaymentTypeModel);
    }

    async processPrePayment(processCreditCardPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardProcessPaymentResult> {
        return await this.httpService.post("/usaepay/processPrePayment", processCreditCardPaymentModel);
    }

    async processPayment(processCreditCardPaymentModel: ICreditCardProcessPaymentModel): Promise<ICreditCardProcessPaymentResult> {
		return await this.httpService.post("/usaepay/processPayment", processCreditCardPaymentModel);
    }

    async voidPayment(paymentModel: IPaymentModel): Promise<IPaymentModel> {
        return await this.httpService.post("/usaepay/voidPayment", paymentModel);
    }

    async refundPayment(paymentModel: IPaymentModel, amount: number): Promise<IPaymentModel> {
        return await this.httpService.post(`/usaepay/refundPayment?amount=${amount}`, paymentModel);
    }
}