import { SlickUtilsService } from '../utils/slick-utils.service';

export interface ISlickTreeNodeModel {
	uuid: string;
	parentUuid: string;
	iconClass: string;
	iconColor: string;
	iconUrl: string;
	text: string;
	expanded: boolean;
	expandOnClick: boolean;
	canEditText: boolean;
	canAddChildren: boolean;
	canDelete: boolean;
	hidden: boolean;
}

export class SlickTreeNodeModel implements ISlickTreeNodeModel {
	constructor(parentNode: ISlickTreeNodeModel, text: string) {
		this.expanded = false;
		this.canEditText = true;
		this.canDelete = true;
		this.canAddChildren = true;
		this.uuid = SlickUtilsService.newGuid();
		this.text = text;
		this.hidden = false;

		if (parentNode) 
			this.parentUuid = parentNode.uuid;
		
		else {
			this.parentUuid = null;
		}
	}

	uuid: string;
	parentUuid: string;
	sortOrder: number;
	iconClass: string;
	iconColor: string;
	iconUrl: string;
	text: string;
	expanded: boolean;
	expandOnClick: boolean;
	canEditText: boolean;
	canAddChildren: boolean;
	canDelete: boolean;
	hidden: boolean;
}