import { Component, ViewChild } from "@angular/core";
import { SlickDialogComponent } from "@slick-components";
import { IQBWCTaskModel } from "@models";

@Component({
	selector: "quickbooks-task-display-dialog",
	template: `
<slick-dialog key='DIALOG_QUICKBOOKS_TASK' header='Quickbooks Task' [width]='800' #dialogRef>
	<quickbooks-task-display [qbwcTaskModel]='qbwcTaskModel'></quickbooks-task-display>
</slick-dialog>`
})
export class QuickbooksTaskDisplayDialogComponent {
	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;

	qbwcTaskModel: IQBWCTaskModel;

	showDialog(qbwcTaskModel: IQBWCTaskModel) {
		this.qbwcTaskModel = qbwcTaskModel;
		this.dialogRef.showDialog();
	}

	closeDialog() {
		this.dialogRef.hideDialog();
	}
}