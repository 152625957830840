import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlickContextMenuItemComponent } from "./slick-context-menu-item.component";
import { SlickContextMenuComponent } from "./slick-context-menu.component";

@NgModule({
	imports: [CommonModule],
	exports: [SlickContextMenuComponent, SlickContextMenuItemComponent, CommonModule],
	declarations: [SlickContextMenuComponent, SlickContextMenuItemComponent]
})
export class SlickContextMenuModule {}

export { SlickContextMenuComponent, SlickContextMenuItemComponent }