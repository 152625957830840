import { ITimeclockBreakModel } from "./timeclock-break.model";

export interface ITimeclockEntryModel {
	timeclockEntryId: number;
	userId: number;
	userFullName: string;
	clockIn: Date;
	clockInHoursText: string;
	clockInGeolocation: string;
	clockOut: Date;
	clockOutHoursText: string;
	clockOutGeolocation: string;
	totalMinutes: number;
	totalDisplay: string;
	totalBreakMinutes: number;
	totalBreakDisplay: string;
	totalWorkedMinutes: number;
	totalWorkedDisplay: string;
	regularPayTotalMinutes: number;
	regularPayTotalDisplay: string;
	overtimePayTotalMinutes: number;
	overtimePayTotalDisplay: string;
	ptoTotalMinutes: number;
	ptoTotalDisplay: string;
	holidayTotalMinutes: number;
	holidayTotalDisplay: string;
	isOnBreak: boolean;
	currentBreak: ITimeclockBreakModel;
    notes: string;
	isTimeOffRequest: boolean;
	isUnpaid: boolean;
	approved: boolean;
	syncToQuickbooks: boolean;
	locked: boolean;
	hasError: boolean;

	breaks: ITimeclockBreakModel[];
}

export class TimeclockEntryModel implements ITimeclockEntryModel {
	timeclockEntryId: number;
	userId: number;
	userFullName: string;
	clockIn: Date;
	clockInHoursText: string;
	clockInGeolocation: string;
	clockOut: Date;
	clockOutHoursText: string;
	clockOutGeolocation: string;
	totalMinutes: number;
	totalDisplay: string;
	totalBreakMinutes: number;
	totalBreakDisplay: string;
	totalWorkedMinutes: number;
	totalWorkedDisplay: string;
	regularPayTotalMinutes: number;
	regularPayTotalDisplay: string;
	overtimePayTotalMinutes: number;
	overtimePayTotalDisplay: string;
	ptoTotalMinutes: number;
	ptoTotalDisplay: string;
	holidayTotalMinutes: number;
	holidayTotalDisplay: string;
	isOnBreak: boolean;
	currentBreak: ITimeclockBreakModel;
	notes: string;
	isTimeOffRequest: boolean;
	isUnpaid: boolean;
	approved: boolean;
	syncToQuickbooks: boolean;
	locked: boolean;
	hasError: boolean;

	breaks: ITimeclockBreakModel[];


    constructor() {
        this.breaks = [];
		this.approved = false;
		this.syncToQuickbooks = false;
    }
}