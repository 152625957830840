export interface IInventoryLocationModel {
	inventoryLocationId: number;
	inventoryWarehouseId: number;
	locationName: string;
	isShippingLocation: boolean;
	isGlobalLocation: boolean;
	isReceivingLocation: boolean;
	isStockLocation: boolean;
	isDefault: boolean;
	active: boolean;
}

export class InventoryLocationModel implements IInventoryLocationModel {
	constructor(inventoryWarehouseId: number) {
		this.inventoryWarehouseId = inventoryWarehouseId;
		this.active = true;
	}

	inventoryLocationId: number;
	inventoryWarehouseId: number;
	locationName: string;
	isShippingLocation: boolean;
	isGlobalLocation: boolean;
	isReceivingLocation: boolean;
	isStockLocation: boolean;
	isDefault: boolean;
	active: boolean;
}