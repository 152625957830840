import { Component, ViewChild, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { GlobalsService, HttpService, SleepService } from "@services";
import { SlickDialogComponent, SlickToastService, SlickDropDownComponent } from "@slick-components";
import { IEnhancementModel, EnhancementModel, DropdownModel } from "@models";
import Swal from 'sweetalert2';
import { VersionReleaseDialogComponent } from "@shared-components/version-release-dialog"

@Component({
	selector: "footer-bar",
	templateUrl: "footer-bar.component.html",
	styles: [`
		.footer-bar {
			height: 1px;
			min-height: 23px;
			line-height: 23px;
		}`],
	providers: [SlickToastService]
})

export class FooterBarComponent implements OnInit{
	@ViewChild("dialogRef", { static: true }) dialogRef: VersionReleaseDialogComponent;
	@ViewChild("enhancementDialgRef") enhancementDialgRef: SlickDialogComponent;

	priorities = [new DropdownModel("Low", "Low"),
	new DropdownModel("Medium", "Medium"),
	new DropdownModel("High", "High")
];

	isStaging: boolean = GlobalsService.isStaging;
	isRC: boolean = GlobalsService.isRC;
	isLoggedIn: boolean = false;
	isLoginPage: boolean;
	currentYear: number = new Date().getFullYear();
	enhancementModel: IEnhancementModel = new EnhancementModel();
	isSubmitted: boolean;
	spinnerStatus: string;
	latestVersion: string;

	constructor(private router: Router,
		private httpService: HttpService,
		private slickToastService: SlickToastService) {
		this.isLoggedIn = GlobalsService.isLoggedIn;
		this.isLoginPage = (router.url.toLowerCase() === '/#/login' || router.url.toLowerCase() === '/#/login/forgotpassword');
	}

	async ngOnInit() {
		this.latestVersion = GlobalsService.appVersion
	}

	async showCurrentVersion() {
		await this.dialogRef.showClickedVersion();
    }

	addEnhancement() {
		this.isSubmitted = false;
		this.spinnerStatus = "reset"

		this.enhancementModel = new EnhancementModel();
		
		this.enhancementModel.enhancementType = "Bug";
		this.enhancementDialgRef.showDialog();
	}

	async saveEnhancement() {
		if (!this.enhancementModel.shortDescription) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Please enter a short description",
				confirmButtonColor: '#007bff',
				heightAuto: false
			});
			this.spinnerStatus = "error";
			return;
		}

		try {
			this.spinnerStatus = "spin";
			await SleepService.sleep(500);
			this.enhancementModel = await this.httpService.post("/enhancements/addEnhancement", this.enhancementModel);

			this.spinnerStatus = "ok";
			this.slickToastService.showSuccess(`${this.enhancementModel.enhancementType} added.  Thank you for your feedback!`);

			await SleepService.sleep(500);
			this.enhancementDialgRef.hideDialog();
		}
		catch {
			this.spinnerStatus = "error";
		}
	}

	cancelEnhancement() {
		this.enhancementDialgRef.hideDialog();
	}
}