import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SlickDialogModule } from "@slick-components";

import { VersionReleaseDialogComponent } from "./version-release-dialog.component";


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SlickDialogModule
	],
	declarations: [VersionReleaseDialogComponent],
	exports: [VersionReleaseDialogComponent]
})
export class VersionReleaseDialogModule { }
