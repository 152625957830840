import { Component, Input, OnInit } from "@angular/core";
import { CreditCardService } from "@services";
import { AddressModel, ShippingAddressModel } from "../../models";

@Component({
    selector: "address-search",
    templateUrl: "./address-search.component.html",
   
})
export class AddressSearchComponent {
    @Input() address: AddressModel;
	@Input() shippingAddress: ShippingAddressModel
	@Input() disabled: boolean = false;
	@Input() isSubmitted: boolean = false;
    countries: any = [
        { id: 'US', text: 'US' },
        { id: 'Canada', text: 'Canada' },
        { id: 'Mexico', text: 'Mexico' },
        { id: 'Israel', text: 'Israel' }
	];
	constructor() {
	}

	onGoogleMapAddressSelect(placeResult: google.maps.places.PlaceResult) {
		this.setAddress(placeResult);
	}

	private setAddress(placeResult: google.maps.places.PlaceResult) {
		if (this.address) {
			this.address.address1 = "";
			this.address.city = "";
			this.address.state = "";
			this.address.zipcode = "";
			this.address.country = "";

			placeResult.address_components.forEach(adr => {
				adr.types.forEach(type => {
					switch (type) {
						case "street_number":
						case "route":
							this.address.address1 += adr.long_name + " ";
							break;

						case "locality":
							this.address.city = adr.long_name;
							break;

						case "administrative_area_level_1":
							this.address.state = adr.short_name;
							break;

						case "postal_code":
							this.address.zipcode = adr.short_name;
							break;

						case "country":
							if (adr.short_name === "CA") {
								this.address.country = "Canada";
							} else if (adr.short_name === "IL") {
								this.address.country = "Israel";
							} else if (adr.short_name === "MX") {
								this.address.country = "Mexico";
							} else {
								this.address.country = adr.short_name;
							}
							break;
					};
				});
			});
		}
		else {
			this.shippingAddress.address1 = "";
			this.shippingAddress.city = "";
			this.shippingAddress.state = "";
			this.shippingAddress.zipcode = "";
			this.shippingAddress.country = "";

			placeResult.address_components.forEach(adr => {
				adr.types.forEach(type => {
					switch (type) {
						case "street_number":
						case "route":
							this.shippingAddress.address1 += adr.long_name + " ";
							break;

						case "locality":
							this.shippingAddress.city = adr.long_name;
							break;

						case "administrative_area_level_1":
							this.shippingAddress.state = adr.short_name;
							break;

						case "postal_code":
							this.shippingAddress.zipcode = adr.short_name;
							break;

						case "country":
							if (adr.short_name === "CA") {
								this.shippingAddress.country = "Canada";
							} else if (adr.short_name === "IL") {
								this.shippingAddress.country = "Israel";
							} else if (adr.short_name === "MX") {
								this.shippingAddress.country = "Mexico";
							} else {
								this.shippingAddress.country = adr.short_name;
							}
							break;
					};
				});
			});
		}
	}

}
