import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from "@angular/core";
import { SlickDialogComponent } from "@slick-components";

@Component({
	selector: "color-picker-dialog",
	template: `
<slick-dialog key='DIALOG_COLOR_PICKER' #colorPickerDialogRef header='Color Picker' [width]="265">
	<div *ngIf='color' [style.background]="color" [cpToggle]="true" [cpDialogDisplay]="'inline'" [cpCmykEnabled]="false" cpOutputFormat="hex" cpAlphaChannel="disabled" [(colorPicker)]="color"></div>
	<slick-dialog-footer>
		<div class="form-group form-save-buttons">
			<button type="button" class="save-button btn btn-outline-primary" style='width: 100px; margin-right: 12px;' (click)="selectColor()"><i class='far fa-check-circle'></i>&nbsp;Select</button>
			<button type="button" class="cancel-button btn btn-outline-danger" style='width: 110px' (click)="onCancelColorPicker()"><i class='bi bi-x-circle'></i>&nbsp;Cancel</button>
		</div>
	</slick-dialog-footer>

</slick-dialog>`,
	providers: []
})
export class ColorPickerDialogComponent {
	@Input("color") color: string;
	@Output("colorChanged") colorChanged: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild("colorPickerDialogRef", { static: true }) colorPickerDialogRef: SlickDialogComponent;

	constructor() {
	}

	showDialog() {
		if (!this.color)
			this.color = "#ffffff";
		this.colorPickerDialogRef.showDialog();
	}

	selectColor() {
		this.colorPickerDialogRef.hideDialog();
		this.colorChanged.emit(this.color);
	}

	onCancelColorPicker() {
		this.colorPickerDialogRef.hideDialog();
	}

}
