import { Injectable } from '@angular/core';
import { ICustomerModel, ICreditCardProcessPaymentModel, ICreditCardPaymentApprovedModel } from '@models';
import { Subject } from 'rxjs';
import { ICreditCardPaymentTypeModel } from '@models';

@Injectable()
export class CreditCardStore {
	customerModel: ICustomerModel;
	creditCardPaymentType: ICreditCardPaymentTypeModel;
	creditCardProcessPaymentModel: ICreditCardProcessPaymentModel;

	transactionSuccess$ = new Subject<ICreditCardPaymentApprovedModel>();
	cardUpdated$ = new Subject<ICreditCardPaymentTypeModel>();
	creditCardEditSuccess$ = new Subject<ICreditCardPaymentTypeModel>();
}
