import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComponentsModule } from "@components";

import { SystemLogsComponent } from "./system-logs.component";

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule
    ],
    declarations: [
        SystemLogsComponent
    ],
    exports: [
        SystemLogsComponent
    ]
})

export class SystemLogsModule { }
