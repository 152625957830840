import { Component, HostBinding, ViewChild, ViewEncapsulation } from '@angular/core';
import { LineItemsBaseComponent } from "@base-components/line-items-base.component";
import { LineItemsService, LookupService, HttpService, FunctionLockService, GlobalsService, ItemsService } from '@services';
import { SlickScreenBlockerService } from "@slick-components";

@Component({
	selector: 'line-items',
	templateUrl: 'line-items.component.html',
	styleUrls: ['line-items.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers:[ItemsService]
})
export class LineItemsComponent extends LineItemsBaseComponent {
	@HostBinding('style.flex') flex = '1';

	constructor(private slickScreenBlockerService: SlickScreenBlockerService,
		itemsService: ItemsService,
		lineItemsService: LineItemsService,
		lookupService: LookupService,
		httpService: HttpService,
		functionLockService: FunctionLockService) {
		super(itemsService, lineItemsService, lookupService, httpService, functionLockService);
	}

	// All the rest of the code is in line-items-base.component.ts

	updateItemAssemblies() {
		try {
			this.slickScreenBlockerService.block();
			super.updateLathamItemAssemblies();
		}
		finally {
			this.slickScreenBlockerService.unblock();
		}
	}
}