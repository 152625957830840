import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";

export enum ConcurrencyRecordTypes {
	Appointment,
	Customer,
	Invoice,
	Item,
	Job,
	LathamOrder,
	Payment
}

@Injectable()
export class ConcurrencyValidationService {
	constructor(private httpService: HttpService) { }

	async checkConcurrency(recordId: number, concurrencyType: ConcurrencyRecordTypes, version: number): Promise<string> {
		const params = {
			recordId: recordId,
			concurrencyType: ConcurrencyRecordTypes[concurrencyType],
			version: version
		};

		const result = await this.httpService.get("/concurrency/checkConcurrency", params)

		return result.message;
	}
}
