import { Component, Input, AfterViewInit, ViewChild, ElementRef, OnDestroy, HostBinding, ContentChild, OnChanges } from "@angular/core";
import { SlickUtilsService } from '../utils/slick-utils.service';

@Component({
	selector: 'slick-container',
	templateUrl: 'slick-container.component.html'
})
export class SlickContainerComponent {
	@Input() maxHeight: string;
	@Input() padding: boolean = true;

	uuid: string = SlickUtilsService.newGuid();
}
