import { IChatGroupModel } from "./chat-group.model";
import { IChatUserModel } from "./chat-user.model";

export interface IChatMessageModel {
    id: number;
    chatGroupId: number;
    senderId: number;
    sentOn: Date;
    message: string;
    sender: IChatUserModel;
    chatGroup: IChatGroupModel;
    imageUrl: string;
    imgHeight: string;
    isSystemMessage: boolean;
}

export class ChatMessageModel implements IChatMessageModel {
    id: number;
    chatGroupId: number;
    senderId: number;
    sentOn: Date;
    message: string;
    sender: IChatUserModel;
    chatGroup: IChatGroupModel;
    imageUrl: string;
    imgHeight: string;
    isSystemMessage: boolean;

}