import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComponentsModule } from "@components";
import { FormsModule } from '@angular/forms';

import { SystemLogsModule } from "@components/system-logs";
import { SlickButtonSpinnerModule, SlickCurrencyMaskModule, SlickDatePickerModule, SlickDialogModule, SlickDropDownModule } from "@slick-components";

import { CompareLathamBillDialogComponent } from "./compare-latham-bill-dialog.component";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ComponentsModule,
		SystemLogsModule,
		SlickButtonSpinnerModule, SlickCurrencyMaskModule, SlickDialogModule, SlickDropDownModule, SlickDatePickerModule
	],
	declarations: [CompareLathamBillDialogComponent],
	exports: [CompareLathamBillDialogComponent]
})
export class CompareLathamBillDialogModule { }

