<section *ngIf="appointment">
	<div class="d-flex mb-2">
		<h5 style="border-bottom: none;" *ngIf="appointment.appointmentTypeDisplayName">{{appointment?.appointmentTypeDisplayName}}</h5>
		<h5 style="border-bottom: none;" *ngIf="!appointment.appointmentTypeDisplayName">Appointment Type</h5>
		<div class="me-2">
			<button class="btn btn-outline-primary btn-sm state-btn" (click)="toggleState(appointment)">
				<i *ngIf="!appointment.uiState || appointment.uiState === 'collapsed'" class="far fa-plus"></i>
				<i *ngIf="appointment.uiState === 'expanded'" class="far fa-minus"></i>
			</button>
		</div>
	</div>
	<div class="d-flex">
		<div class="flex-fill me-3">
			<label>Appointment Type</label>
			<slick-drop-down [items]="appointmentTypes"
							 [(ngModel)]="appointment.appointmentTypeId"
							 [allowEmpty]="false"
							 [validationIndicator]="appointment.appointmentTypeId"
							 (onSelect)="onAppointmentTypeSelect($event)"
							 [ngClass]="{'error-dropdown': !appointment.appointmentTypeId &&  submitted || (!appointment.appointmentTypeId && !isValid && multiDayAppointmentClick)}"></slick-drop-down>
			<form-error *ngIf="!appointment.appointmentTypeId &&  submitted || (!appointment.appointmentTypeId && !isValid && multiDayAppointmentClick)">Appointment Type is required</form-error>
		</div>
		<div class="flex-fill me-3">
			<label>Appointment Status</label>
			<slick-drop-down [items]="appointmentStatuses"
							 [(ngModel)]="appointment.appointmentStatusId"
							 [allowEmpty]="false"
							 [validationIndicator]="appointment.appointmentStatusId"
							 (onSelect)="onAppointmentStatusSelect($event)"
							 [ngClass]="{'error-dropdown': !appointment.appointmentStatusId &&  submitted || (!appointment.appointmentStatusId && !isValid && multiDayAppointmentClick)}"></slick-drop-down>
			<form-error *ngIf="!appointment.appointmentStatusId &&  submitted || (!appointment.appointmentStatusId && !isValid && multiDayAppointmentClick)">Status is required</form-error>
		</div>
		<div class="flex-fill">
			<label>Resource</label>
			<slick-drop-down [items]="resources"
							 [(ngModel)]="appointment.resourceId"
							 [allowEmpty]="false"
							 [validationIndicator]="appointment.resourceId"
							 (onSelect)="onResourceSelect($event)"
							 [ngClass]="{'error-dropdown': !appointment.resourceId &&  submitted || (!appointment.resourceId && !isValid && multiDayAppointmentClick)}"></slick-drop-down>
			<form-error *ngIf="!appointment.resourceId &&  submitted || (!appointment.resourceId && !isValid && multiDayAppointmentClick)">Resource is required</form-error>
		</div>
	</div>
	<div class="d-flex mt-3">
		<div style="width: 160px" class="me-3">
			<label>Scheduled Date</label>
			<input slick-date-picker
				   type="text"
				   class="form-control"
				   [(ngModel)]="scheduledDate"
				   [slick-validation-indicator]="scheduledDate"
				   slickValidationType="error"
				   (onDateChange)="onScheduledDateChange($event)"
				   [ngClass]="{'error-input': !scheduledDate &&  submitted || (!scheduledDate && !isValid && multiDayAppointmentClick)}" />
			<form-error *ngIf="!scheduledDate &&  submitted || (!scheduledDate && !isValid && multiDayAppointmentClick)">Start date is required</form-error>
		</div>
		<div style="width: 145px" class="me-3">
			<label>Start Time</label>
			<div class="input-group">
				<slick-drop-down [items]="startTimeItems"
								 [ngModel]="startTime"
								 [allowEmpty]="false"
								 [validationIndicator]="startTime"
								 (onSelect)="onStartTimeSelected($event)"
								 [ngClass]="{'error-dropdown': !startTime && submitted || (!startTime && !isValid && multiDayAppointmentClick)}"></slick-drop-down>
				<form-error *ngIf="!startTime && submitted || (!startTime && !isValid && multiDayAppointmentClick)">Start time is required</form-error>
			</div>
		</div>
		<div style="width: 120px" class="me-3">
			<label>Duration</label>
			<slick-drop-down [items]="durationItems"
							 [(ngModel)]="appointment.scheduledMinutes"
							 [allowEmpty]="false"
							 [validationIndicator]="appointment.scheduledMinutes"
							 [ngClass]="{'error-dropdown': !appointment.scheduledMinutes && submitted || (!appointment.scheduledMinutes && !isValid && multiDayAppointmentClick)}"></slick-drop-down>
			<form-error *ngIf="!appointment.scheduledMinutes && submitted || (!appointment.scheduledMinutes && !isValid && multiDayAppointmentClick)">Duration is required</form-error>
		</div>
		<div style="width: 120px" class="me-3">
			<label>Travel Time</label>
			<slick-drop-down [items]="travelItems"
							 [(ngModel)]="appointment.travelMinutes"
							 [allowEmpty]="false"
							 [validationIndicator]="appointment.travelMinutes !== null"
							 [ngClass]="{'error-dropdown': appointment.travelMinutes === null && submitted ||  (!appointment.travelMinutes && !isValid && multiDayAppointmentClick)}"></slick-drop-down>
			<form-error *ngIf="appointment.travelMinutes === null && submitted ||  (!appointment.travelMinutes && !isValid && multiDayAppointmentClick)">Travel Time is required</form-error>
		</div>
	</div>
		<div class="d-flex mt-3">
			<div class="flex-fill me-2">
				<label>Description</label>
				<textarea type="text"
						  class="form-control"
						  style="line-height: 1rem"
						  [(ngModel)]="appointment.description"
						  rows="4"></textarea>
			</div>
			<div *ngIf="showCustomerNotes" class="flex-fill">
				<label>Customer Notes</label>
				<textarea type="text"
						  class="form-control"
						  style="line-height: 1rem"
						  [(ngModel)]="appointment.customerNotes"
						  rows="4"></textarea>
			</div>
			<div class="flex-fill ms-2">
				<label>Technician Notes</label>
				<textarea type="text"
						  class="form-control"
						  style="line-height: 1rem"
						  [(ngModel)]="appointment.notes"
						  rows="4"></textarea>
			</div>
		</div>

	<div style="font-size: 12px; line-height: .7rem; max-width: 67%;" class="my-1 border d-flex mt-3">
		<div class="d-flex flex-column mt-1 mb-1 me-2">
			<div class="m-1">Created By: {{appointment.createdByInitials}} on {{appointment.createdDate | date:'shortDate'}}</div>
			<div class="m-1" *ngIf="appointment.finishedByUserName && appointment.finishedTime">
				Completed By: {{appointment.finishedByUserName}} on {{appointment.finishedTime | date:'M/dd/yyyy h:mma'}}
			</div>
			<div class="m-1">
				Crew: {{appointment.crewDisplay}}
				<i style="color:#007BFF; cursor:pointer" class="fa fa-pencil fa-sm" (click)="showEditCrewDialog()"></i>
			</div>
		</div>
		<div class="d-flex flex-column mt-1 ms-auto mb-1">
			<div class="m-1">In Transit: {{appointment.inTransitTotalDisplay}}</div>
			<div class="m-1">At Jobsite: {{appointment.atJobsiteTotalDisplay}}</div>
			<div class="m-1">Total: {{appointment.finishedTotalDisplay}}</div>
		</div>
		<button class="btn btn-xs ms-2 mb-1" title="Edit Times" (click)="showEditTimeDialog()">
			<i style="color:#007BFF" class="fa fa-pencil fa-sm"></i>
		</button>
	</div>

    <div class="d-flex flex-column mt-3 mb-1">
        <div class="d-flex">
			<label><input type="checkbox" [(ngModel)]="appointment.locked">Locked&nbsp;&nbsp;</label>
			<label><input type="checkbox" [(ngModel)]="appointment.isConfirmed">Confirmed&nbsp;&nbsp;</label>
			<label *ngIf="useAppointmentReminder"><input type="checkbox" [(ngModel)]="appointment.sendReminder">Send Reminder&nbsp;&nbsp;</label>
			<label><input type="checkbox" [(ngModel)]="appointment.goBack">Go Back&nbsp;&nbsp;</label>
		</div>

        <div *ngIf="appointment.smartlistQuestions && appointment.smartlistQuestions.length > 0" class="d-flex flex-wrap" style="line-height: 1.2rem">
			<label><input type="checkbox" [(ngModel)]='appointment.showOnSmartlist' />Show on Smartlist&nbsp;&nbsp;</label>
            <label><input type="checkbox" [(ngModel)]='appointment.smartlistFutureAppt' />Future Appt&nbsp;&nbsp;</label>
            <label><input type="checkbox" [(ngModel)]='appointment.smartlistDelinquent' />Delinquent&nbsp;&nbsp;</label>
            <label *ngIf="appointment.acuityReferenceId"><input type="checkbox" [(ngModel)]="appointment.linkToAcuity" />Link To Acuity&nbsp;&nbsp;</label>
        </div>

		<div class="d-flex">
			<div *ngFor="let smartlistQuestion of appointment.smartlistQuestions" class="me-3">
                <label><input type="checkbox" [(ngModel)]='smartlistQuestion.isChecked' />{{smartlistQuestion.question}}</label>
            </div>
		</div>
    </div>

    <div class="d-flex mt-3">
        <div class="flex-fill" style="text-align: right">
            <button class="btn btn-sm btn-outline-primary me-2" (click)="openChecklistDialog()"><i class="fad fa-clipboard-list"></i> Checklist</button>
            <button class="btn btn-sm btn-outline-primary me-2" *ngIf="appointment.appointmentId" (click)="openStrikesDialog()"><i class="fad fa-certificate"></i> Marks</button>
            <button class="btn btn-sm btn-outline-primary me-2" (click)="openMultiDaySchedule()"><i class="fad fa-calendar"></i> Multi-Day Appointment</button>
            <button class="btn btn-sm btn-outline-primary" *ngIf="appointment.appointmentId && appointment.invoiceId && isSalesOrder" (click)="openSalesOrderEditDialog()"><i class="bi bi-pencil-fill"></i> Sales Order</button>
            <button class="btn btn-sm btn-outline-primary" *ngIf="appointment.appointmentId && appointment.invoiceId && isInvoice" (click)="openInvoiceEditDialog()"><i class="bi bi-pencil-fill"></i> Invoice</button>
            <button class="btn btn-outline-primary btn-sm me-2" (click)="printAppointment(appointment)"><i class="fa fa-print"></i></button>
            <button *ngIf="showDeleteButton" class="btn btn-outline-danger btn-sm" (click)="deleteAppointment(appointment)"><i class="fa fa-trash"></i></button>
        </div>
    </div>
</section>

<slick-dialog #editTimeDialogRef [resizable]="true" [draggable]="true" [minimizable]="true" header="Edit Times" width="300">
	<div class="d-flex">
		<div class="flex-fill mt-2">

			<label class="mt-2"> In Transit Start Time</label>
			<div class="d-flex">
				<input slick-date-picker
					   class="form-control flex-fill me-1"
					   [(ngModel)]="inTransitDate"
					   (onDateChange)="onTransitDateChange($event)" />
				<input type="text"
					   class="form-control"
					   pattern="^([0-9]|0[0-9]|1[0-2]):[0-5][0-9]\s?[a|A|P|p][M|m]"
					   #transitTime="ngModel"
					   name="transitTime"
					   [(ngModel)]="inTransitStartTime"
					   placeholder="HH:MM AM/PM" />
			</div>
			<div>
				<form-error *ngIf="(!validateTimes() && transitTime.errors?.pattern) && isSubmitted">Invalid time format. Use HH:MM AM/PM</form-error>
				<form-error *ngIf="!validateDateTime() && isSubmitted && ((!inTransitDate && inTransitStartTime) || (inTransitDate && !inTransitStartTime))">Both date and time are required</form-error>
			</div>
			<label class="mt-2">At Jobsite Start Time</label>
			<div class="d-flex">
				<input slick-date-picker
					   class="form-control flex-fill me-1"
					   [(ngModel)]="jobsiteDate"
					   (onDateChange)="onJobsiteDateChange($event)" />
				<input type="text"
					   class="form-control"
					   pattern="^([0-9]|0[0-9]|1[0-2]):[0-5][0-9]\s?[a|A|P|p][M|m]"
					   #jobsiteTime="ngModel"
					   name="jobsiteTime"
					   [(ngModel)]="jobsiteStartTime"
					   placeholder="HH:MM AM/PM" />
			</div>
			<div>
				<form-error *ngIf="(!validateTimes() && jobsiteTime.errors?.pattern) && isSubmitted">Invalid time format. Use HH:MM AM/PM</form-error>
				<form-error *ngIf="!validateDateTime() && isSubmitted && ((!jobsiteDate && jobsiteStartTime) || (jobsiteDate && !jobsiteStartTime))">Both date and time are required</form-error>
			</div>
			<label class="mt-2">Appointment Completed Time</label>
			<div class="d-flex">
				<input slick-date-picker
					   class="form-control flex-fill  me-1"
					   [(ngModel)]="completedDate"
					   (onDateChange)="onCompleteDateChange($event)" />

				<input type="text"
					   class="form-control"
					   pattern="^([0-9]|0[0-9]|1[0-2]):[0-5][0-9]\s?[a|A|P|p][M|m]"
					   name="completedTime"
					   #completedTime="ngModel"
					   [(ngModel)]="completedStartTime"
					   placeholder="HH:MM AM/PM" />
			</div>
			<div>
				<form-error *ngIf="(!validateTimes() && completedTime.errors?.pattern) && isSubmitted ">Invalid time format. Use HH:MM AM/PM</form-error>
				<form-error *ngIf="!validateDateTime() && isSubmitted && ((!completedStartTime && completedDate) || (completedStartTime && !completedDate))">Both date and time are required</form-error>
			</div>

		</div>
	</div>

	<slick-dialog-footer>
		<div>
			<button class="save-button btn btn-outline-success me-3" 
					style="width: 105px" 
					(click)="saveTimes()"><i class="bi bi-check-circle"></i> Save</button>
			<button class="cancel-button btn btn-outline-danger" 
					style="width: 105px" 
					(click)="cancelEditTimes()"><i class="bi bi-x-circle" ></i> Cancel</button>
		</div>
	</slick-dialog-footer>

</slick-dialog>

<checklist-dialog [(checklistQuestions)]="appointment.checklistQuestions"
				  [appointmentId]="appointment.appointmentId"
				  #checklistDialogRef></checklist-dialog>
<marks-dialog #strikesDialogRef></marks-dialog>
<sales-order-edit-dialog #salesOrderEditDialogRef></sales-order-edit-dialog>
<invoice-edit-dialog #invoiceEditDialogRef></invoice-edit-dialog>

<slick-confirm-dialog #noInvoiceErrorRef [showCancelButton]="false">
	<div class="text-danger">The <span *ngIf='isSalesOrder'>Sales Order</span><span *ngIf="isInvoice">Invoice</span> does not appear to have any line items available to split.</div>
</slick-confirm-dialog>

<slick-confirm-dialog #deleteApptRef>
	<div class="text-danger" style="line-height: 24px;">Are you sure you want to delete this appointment?</div>
</slick-confirm-dialog>

<slick-dialog #multiDayDialogRef header="Multi-Day Schedule">
	<div class="w-100 mt-2">
		<slick-calendar 
						monthsToShow="2"
						[multiSelect]="true"
						[(multiSelectDates)]="multiDates"
						></slick-calendar>
	</div>

	<slick-dialog-footer>
		<div>
			<button class="save-button btn btn-outline-success me-3"
					style="width: 100px"
					(click)="saveMultiDay()">
				<i class="bi bi-check-circle"></i> Save
			</button>
			<button class="cancel-button btn btn-outline-danger"
					style="width: 105px"
					(click)="cancelMultiDay()">
				<i class="bi bi-x-circle"></i> Cancel
			</button>
		</div>
	</slick-dialog-footer>
</slick-dialog>

<slick-dialog #editCrewDialogRef header="Edit Crew">
	<div *ngFor="let crewMember of actualCrew" class="d-flex mt-1">

		<div class="flex-fill">
			<slick-drop-down [items]="allCrewMembers"
							 [ngModel]="crewMember.id"
							 (onSelect)="onCrewMemberSelect($event, crewMember)">
			</slick-drop-down>
		</div>
		<div class="ms-1">
			<button class="btn btn-outline-danger" (click)="removeCrewMember(crewMember)"><i class="fas fa-trash"></i></button>
		</div>
	</div>
	<button class="btn btn-outline-primary mt-2" (click)="addCrewMember()"><i class="far fa-plus"></i> Add Crew Member</button>
	<slick-dialog-footer>
		<div>
			<button class="save-button btn btn-outline-success me-3"
					style="width: 100px"
					(click)="saveActualCrew()">
				<i class="bi bi-check-circle"></i> Save
			</button>
			<button class="cancel-button btn btn-outline-danger"
					style="width: 105px"
					(click)="cancelActualCrew()">
				<i class="bi bi-x-circle"></i> Cancel
			</button>
		</div>
	</slick-dialog-footer>
</slick-dialog>
