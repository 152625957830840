import { ItemModel } from "../items/item.model";

export interface IJellyFishOrderingSettingsModel {
	jellyFishOrderingSettingsId: number;
	item: ItemModel;
	includeElectronics: boolean;
}

export class JellyFishOrderingSettingsModel implements IJellyFishOrderingSettingsModel {
	jellyFishOrderingSettingsId: number;
	item: ItemModel;
	includeElectronics: boolean;
}
