import { Component, Input, isDevMode, ViewChild } from "@angular/core";
import { SlickDialogComponent } from "@slick-components";
import { GlobalsService, TimeclockService } from "@services"
import { ITimeclockEntryModel, TimeclockEntryModel } from "@models";
import * as moment from "moment";
import { TimeclockStore } from "../../stores";
import Swal from 'sweetalert2';

@Component({
	selector: "missed-clock-in-dialog",
	templateUrl: "./missed-clock-in-dialog.component.html",
	 providers: [TimeclockService]
})
export class MissedClockInDialogComponent{
	@ViewChild("dialogRef", { static: true }) dialogRef: SlickDialogComponent;
	@Input() isMobile: boolean = false;

	timeClockEntry: ITimeclockEntryModel;
	isSubmitted: boolean = false;
	formatError: boolean = false;
	constructor(private timeclockService: TimeclockService,
	private timeclockStore: TimeclockStore) {
		
	}

	async showDialog() {
		if (this.isMobile) {
			this.dialogRef.width = 360;
		}
		else {
			this.dialogRef.width = 700;
		}
		this.timeClockEntry = new TimeclockEntryModel();
		this.dialogRef.showDialog();
		
	}

	async continue() {
		this.isSubmitted = true;
		this.formatError = false;

		// Check to see if they are still clocked into this job
		const actualCurrentTimeclockEntry = await this.timeclockService.getLastTimeclockEntry(GlobalsService.userInfo.userId);

		// Not clocked in at all.  Show an error
		if (actualCurrentTimeclockEntry) {


			await Swal.fire({
				icon: 'warning',
				title: 'Oops',
				text: 'You are already clocked in',
				confirmButtonColor: '#007bff',
				width: '28em',
				heightAuto: false
			});
			this.timeclockStore.refreshCurrentEntry(actualCurrentTimeclockEntry);

			this.dialogRef.hideDialog();
			return;
		}


		if (this.timeClockEntry.clockInHoursText) {


			const timeCheck = /^([0-9]|0[0-9]|1[0-2]):[0-5][0-9]\s?[a|A|P|p][M|m]$/.test(this.timeClockEntry.clockInHoursText);
			if (!timeCheck) {
				this.formatError = true;
				return
			}

			const timeClockEntryDate = moment().startOf('day').toDate();
			this.timeClockEntry.clockIn = this.timeclockService.combineToDate(timeClockEntryDate, this.timeClockEntry.clockInHoursText);
			this.timeClockEntry.userId = GlobalsService.userInfo.userId;

			this.timeclockService.addTimeclockEntry(this.timeClockEntry)
		}
		else {
			this.timeClockEntry.userId = GlobalsService.userInfo.userId;
			this.timeClockEntry.clockIn = new Date();
			this.timeClockEntry = await this.timeclockService.clockIn(this.timeClockEntry);

		}
		this.timeclockStore.refreshCurrentEntry(this.timeClockEntry);
		this.dialogRef.hideDialog();
    }
	

	close() {
		this.dialogRef.hideDialog();
	}
}
