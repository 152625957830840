<slick-dialog #dialogRef [showHeader]="false">
    <section class="text-center" *ngIf="timeClockEntry">
        <h2>
            Oops...
        </h2>
        <div>
            Looks like you forgot to clock out of your last shift
        </div>
        <div class="mt-2">
            Please enter a clock out time here
        </div>
        <div class="mt-3">
            {{timeClockDate}}
        </div>

        <div class="mt-1 d-flex me-2 col-12">
            <div class="col-6 me-2">
                Clock In
                <input disabled class="form-control" [(ngModel)]="timeClockEntry.clockInHoursText" />
                <div *ngIf="!timeClockEntry.clockOutHoursText && isSubmitted && isMobile" style="width:180px" class='error-wrapper show-icons text-danger'>
                    <div class='error'>Clock out is required <ng-content></ng-content></div>
                </div>
                <div *ngIf="formatError && isSubmitted && isMobile" style="width:220px" class='error-wrapper show-icons text-danger'>
                    <div class='error'>Clock out is in incorrect format <ng-content></ng-content></div>
                </div>
                <div *ngIf="clockOutBeforeClockIn && isSubmitted && isMobile" style="width:220px" class='error-wrapper show-icons text-danger'>
                    <div class='error'>Clock in must be before clock out<ng-content></ng-content></div>
                </div>

            </div>
            <div class="col-6 mb-4">
                Clock Out
                <input class="form-control" [(ngModel)]="timeClockEntry.clockOutHoursText" />
                <div *ngIf="!timeClockEntry.clockOutHoursText && isSubmitted && !isMobile" style="width:180px" class='error-wrapper show-icons text-danger'>
                    <div class='error'>Clock out is required <ng-content></ng-content></div>
                </div>
                <div *ngIf="formatError && isSubmitted && !isMobile" style="width:220px" class='error-wrapper show-icons text-danger'>
                    <div class='error'>Clock out is in incorrect format <ng-content></ng-content></div>
                </div>
                <div *ngIf="clockOutBeforeClockIn && isSubmitted && !isMobile" style="width:220px" class='error-wrapper show-icons text-danger'>
                    <div class='error'>Clock out is before clock in<ng-content></ng-content></div>
                </div>
            </div>
        </div>
    </section>

    <slick-dialog-footer>
        <button class="btn btn-outline-primary me-2" style="width:100px" (click)="continue()">Continue</button>
    </slick-dialog-footer>

</slick-dialog>
