export interface ICustomerAreaModel {
	customerAreaId: number;
	name: string;
	qbClassListId: string;
	qbClassName: string;
	businessCentralId: string;
	businessCentralRegionId: string;
	active: boolean;
}

export class CustomerAreaModel implements ICustomerAreaModel {
	constructor() {
		this.customerAreaId = 0;
		this.active = true;
	}

	customerAreaId: number;
	name: string;
	qbClassListId: string;
	qbClassName: string;
	businessCentralId: string;
	businessCentralRegionId: string;
	active: boolean;
}