export interface IPhotoUploadModel {
	uuid: string;
	date: Date;
	folderUuid: string;
	fileName: string;
	error: string;
	currentStatus: string;
	retryCount: number;
	fileExists: boolean;
}

export class PhotoUploadModel implements IPhotoUploadModel {
	uuid: string;
	date: Date;
	folderUuid: string;
	fileName: string;
	error: string;
	currentStatus: string;
	retryCount: number;
	fileExists: boolean;
}

