import { UtilsService } from "@services/utils/utils.service";
import { IDropdownModel } from "../dropdown/dropdown.model";

export enum LineItemTypes {
	LineItem = 0,
	Tax = 1,
	TaxRate1 = 2,
	TaxRate2 = 3,
	Shipping = 4,
	Labor = 5,
	Labor2 = 6,
	Labor3 = 7,
	Discount = 8,
	Rebate = 9,
	Labor4 = 10,
	TaxRate3 = 11,
	FuelSurcharge = 12,
	Tip = 13,
	ColoradoShippingSurcharge = 14
}

export interface ILineItemModel {
	uuid: string;
	lineItemType: LineItemTypes;
	invoiceGroupSeq: number;
	itemId: number;
	laborItemId: number;
	discountId: number;
	imageUrl: string;
	imageThumbnailUrl: string;
	sku: string;
	description: string;
	itemInventoryType?: number;
	quantity: number;
	quantityModified: boolean;
	cost: number;
	costModified: boolean;
	price: number;
	priceModified: boolean;
	taxable: boolean;
	editable: boolean;
	editing: boolean;
	isSystemLineItem: boolean;
	isTax: boolean;
	isLabor: boolean;
	isLabor2: boolean;
	isLabor3: boolean;
	isLabor4: boolean;
	isShipping: boolean;
	displayOrder: number;
	quickbooksItemId: string;
	priority: number;
	sendToManufacturer: boolean;
	isBlankLineItem: boolean;
	editingDescription: boolean;
	toSplit: boolean;
	splitQuantity: number;
	isGlobalItem: boolean;
	isParentItem: boolean;


	inventoryItemLocationId?: number;
	inventoryItemLocations: IDropdownModel[];
}
export class LineItemModel implements ILineItemModel {
	constructor(lineItemType: LineItemTypes = LineItemTypes.LineItem) {
		this.uuid = UtilsService.newGuid();
		this.lineItemType = lineItemType;
		this.sendToManufacturer = true;

		this.inventoryItemLocationId = null;
		this.inventoryItemLocations = [];

	}

	uuid: string;
	lineItemType: LineItemTypes;
	invoiceGroupSeq: number;
	itemId: number;
	laborItemId: number;
	discountId: number;
	imageUrl: string;
	imageThumbnailUrl: string;
	sku: string;
	description: string;
	itemInventoryType?: number;
	quantity: number;
	quantityModified: boolean;
	cost: number;
	costModified: boolean;
	price: number;
	priceModified: boolean;
	taxable: boolean;
	editable: boolean;
	editing: boolean;
	isSystemLineItem: boolean;
	isTax: boolean;
	isLabor: boolean;
	isLabor2: boolean;
	isLabor3: boolean;
	isLabor4: boolean;
	isShipping: boolean;
	displayOrder: number;
	quickbooksItemId: string;
	priority: number;
	sendToManufacturer: boolean;
	isBlankLineItem: boolean;
	editingDescription: boolean;
	toSplit: boolean;
	splitQuantity: number;
	isGlobalItem: boolean;
	isParentItem: boolean;


	inventoryItemLocationId?: number;
	inventoryItemLocations: IDropdownModel[] = [];
}