import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { DevTraceService } from '@services/utils/dev-trace.service';
import { GlobalsService } from '../services';

@Injectable()
export class DevLogsStore {
	private _logMessages: string[] = [];

	constructor(private devTraceService: DevTraceService) {

	}

	alert(message: string) {
		this.addMessage(message);
		if (!GlobalsService.isOnline)
			alert(message);
	}

	addMessage(message: string) {
		console.info(`${moment().format("h:mm:ss.SSS")}: ${message}`);
		this._logMessages.push(message);
		this.devLog$.next(message);

		this.devTraceService.addTrace(message);
	}

	getMessages(): string[] {
		return this._logMessages;
	}

	devLog$: Subject<string> = new Subject<string>();

}
