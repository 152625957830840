import { Directive, Output, EventEmitter, OnInit } from '@angular/core';
import { IJobModel, IResourceModel, IAppointmentTypeModel, IJobTypeModel, IDropdownModel, IAvailableTimeSlotModel, DropdownModel, AppointmentModel, AppointmentChecklistQuestionModel, AppointmentSmartlistQuestionModel, ICustomerModel } from '@models';
import { LookupService, AppointmentsService, JobsService, FunctionLockService, UtilsService, GlobalsService, SleepService } from '@services';
import * as moment from 'moment';

export class TimeSlot {
    timeSlotDisplay: string;
    availableResources: ResourceAvailability[];
}

export class ResourceAvailability {
    resourceId: number;
    resourceShortName: string;
    backgroundColor: string;
    textColor: string;
    inArea: boolean;
}

@Directive()
export class AutoScheduleEditBase implements OnInit {
    delayTimer: NodeJS.Timer;

    @Output() onResourceSelected = new EventEmitter<IJobModel>();
    numberOfMonths: number;

    jobModel: IJobModel;
    resources: IResourceModel[];
    appointmentTypes: IAppointmentTypeModel[];
    jobTypes: IJobTypeModel[];
    customer: ICustomerModel;

    selectedDate: Date;
    jobTypeId: number;
    appointmentTypeId: number;
    durationTimes: IDropdownModel[];
    travelTimes: IDropdownModel[];
    currentDate: moment.Moment;

    startDate: Date;
    endDate: Date;
    durationTime: number;
    travelTime: number;
    availableTimeSlots: IAvailableTimeSlotModel[];
    inactiveDates: Date[];
    timeSlots: TimeSlot[];

    multiDayClicked: boolean;
    multiDates: Date[] = [];


    constructor(numberOfMonths: number,
        private readonly lookupService: LookupService,
        public appointmentsService: AppointmentsService,
        private readonly jobsService: JobsService,
        private functionLockService: FunctionLockService) {
        this.numberOfMonths = numberOfMonths;
        this.jobTypes = this.lookupService.getJobTypes();
        this.appointmentTypes = this.lookupService.getAppointmentTypes();
        this.resources = this.lookupService.getResources().filter(x => x.active === true);
        this.durationTimes = this.renderTimesList(8);
        this.travelTimes = this.renderTimesList(6);
        this.travelTimes.unshift(new DropdownModel(0, "0:00"));
        this.travelTime = 15;
        this.startDate = moment().startOf("month").toDate();
        this.endDate = moment(this.startDate).add(this.numberOfMonths, "months").toDate();
        this.multiDayClicked = false;
    }

    ngOnInit() {
        this.reloadAvailableTimeSlots();
    }

    reset(jobModel: IJobModel) {
        this.multiDates = [];
        this.jobModel = (jobModel || null);
        this.selectedDate = null;
        this.customer = null;
        this.startDate = moment().startOf("day").toDate();
        this.endDate = moment(this.startDate).startOf("day").add(2, "months").toDate();
        //this.endDate = moment(this.startDate).startOf("month").add(2, "months").toDate();
        this.jobTypeId = (jobModel === null) ? null : jobModel.jobTypeId;
        this.appointmentTypeId = null;
        this.durationTime = null;
        this.travelTime = 15;
        this.timeSlots = [];
        let currentDate = moment(moment(this.startDate).startOf('month').toDate());
        this.inactiveDates = [];
        if (!this.durationTime || this.travelTime === null) {
            while (currentDate.isBefore(this.endDate)) {
                const date = currentDate.toDate();
                this.inactiveDates.push(date);
                currentDate = moment(currentDate).add(1, "days");
            }
        }
        this.availableTimeSlots = null;
    }

    async onJobTypeChanged() {
        await this.reloadAvailableTimeSlots();
        if (this.multiDayClicked)
            this.getAvailableResources();
    }

    async onAppointmentTypeChanged(appointmentType: IAppointmentTypeModel) {
        this.durationTime = appointmentType.defaultMinutes;
        await this.reloadAvailableTimeSlots();
        if (this.multiDayClicked)
            this.getAvailableResources();
        
    }

    async onAppointmentDurationChanged() {
        await this.reloadAvailableTimeSlots();
        if (this.multiDayClicked)
            this.getAvailableResources();
        
    }

    async onAppointmentTravelTimeChanged() {
        await this.reloadAvailableTimeSlots();
        if (this.multiDayClicked)
            this.getAvailableResources();
    }

    async reloadAvailableTimeSlots() {
        try {
            await this.functionLockService.lock("AUTO_SCHEDULER_RELOAD");


            this.inactiveDates = [];

            let currentDate = moment(this.startDate).startOf('month');
            while (currentDate.isBefore(this.endDate)) {
                const date = currentDate.toDate();
                this.inactiveDates.push(date);
                currentDate = currentDate.add(1, "days");
            }

            if (!this.jobTypeId || !this.appointmentTypeId || !this.durationTime || this.travelTime === null) 
                return;
            
            this.availableTimeSlots = await this.appointmentsService.getAvailableTimeSlots(this.startDate, this.endDate, this.durationTime, this.travelTime, this.customer?.customerId);
            this.inactiveDates = [];
            currentDate = moment(this.startDate).startOf('month');
            while (currentDate.isBefore(this.endDate)) {
                const date = currentDate.toDate();

                if (this.isDateBeforeToday(date) || this.isDateBooked(date)) {
                    this.inactiveDates.push(currentDate.toDate());
                }

                currentDate = moment(currentDate).add(1, "days");
            }

            if (this.selectedDate)
                this.onDateChanged(this.selectedDate);
        } finally {
            this.functionLockService.release("AUTO_SCHEDULER_RELOAD");
        }
    }

    isDateBooked(date: Date): boolean {
        const availableTimeSlotsForDateIdx = this.availableTimeSlots.findIndex(x => moment(x.startDateTime).isSame(moment(date), "day"));

        return (availableTimeSlotsForDateIdx === -1);
    }

    isDateBeforeToday(date: Date) {
        return (moment(date).isBefore(moment().startOf("day")))
    }

    renderTimesList(maxHours: number): IDropdownModel[] {
        const times = new Array<IDropdownModel>();

        for (let h = 0; h < maxHours; h++) {
            for (let m = 0; m < 60; m += 15) {
                if (h === 0 && m === 0) {
                    continue;
                }

                const time = `0${h}:${m === 0 ? '00' : m}`;
                const id = h * 60 + m;
                times.push(new DropdownModel(id, time));
            }
        }

        times.push(new DropdownModel(maxHours * 60, `0${maxHours}:00`));

        return times;
    }

    onDateChanged(newDate: Date) {
        if (!newDate)
            return;
        let currentTimeSlot = moment(newDate).startOf('date')

        this.timeSlots = [];
        if (!this.availableTimeSlots)
            return;
        
        const dailyTimeSlots = this.availableTimeSlots.filter(x => moment(x.startDateTime).isSame(newDate, 'date'))
        
        for (let i = 0; i < 96; i++) {
            const newTimeSlot = new TimeSlot()
            newTimeSlot.timeSlotDisplay = currentTimeSlot.format('h:mma')
            newTimeSlot.availableResources = []
            this.timeSlots.push(newTimeSlot)
            currentTimeSlot = currentTimeSlot.add(15, 'minutes')
        }

        dailyTimeSlots.forEach(timeSlot => {
            const startTime = moment(timeSlot.startDateTime)
            const endTime = moment(timeSlot.startDateTime).add(timeSlot.duration, "minutes")
            let currentTimeSlot = moment(newDate).startOf('date')
            const resource = this.resources.find(r => r.resourceId === timeSlot.resourceId)
            const availableResource = new ResourceAvailability()
            availableResource.resourceId = resource.resourceId;
            availableResource.backgroundColor = resource.backgroundColor;
            availableResource.resourceShortName = resource.shortName;
            availableResource.textColor = resource.color;
            availableResource.inArea = timeSlot.inArea;

            for (let i = 0; i < 96; i++) {
                if (currentTimeSlot.isSameOrBefore(endTime) && currentTimeSlot.isSameOrAfter(startTime)) {
                    this.timeSlots[i].availableResources.push(availableResource)
                }
                currentTimeSlot = currentTimeSlot.add(15, 'minutes');
            }
        })
        this.timeSlots = this.timeSlots.filter(x => x.availableResources.length > 0)
    }

    onMonthChanged(newStartDate: Date) {
        if (moment(newStartDate).add(this.numberOfMonths, "months").toDate() >= this.endDate)
            this.endDate = moment(newStartDate).add(this.numberOfMonths, "months").toDate();
        this.reloadAvailableTimeSlots();
    }

    async selectResource(timeSlot: TimeSlot, resourceId: number) {
        if (!this.jobModel) {
            this.jobModel = await this.jobsService.getNewJobModel();
            this.jobModel.appointments = [];
        }

        if (this.customer)
            this.jobModel.jobCustomer = this.customer;

        if (!this.jobModel.jobName)
            this.jobModel.jobName = await this.jobsService.getNextJobName();

        if (!this.jobModel.jobStatusId) {
            this.jobModel.jobStatusId = 1;
            this.jobModel.jobStatusDisplayName = this.lookupService.getJobStatuses().find(x => x.jobStatusId === 1).description;;
		}

        if (!this.jobModel.jobTypeId) {
            this.jobModel.jobTypeId = this.jobTypeId;
            this.jobModel.jobTypeDisplayName = this.lookupService.getJobTypes().find(x => x.jobTypeId === this.jobTypeId).description;
        }

        if (this.multiDayClicked)
            this.multiDates.forEach(x => {
                const appt = this.generateAppt(x, timeSlot, resourceId);
                if (!this.jobModel.appointments) {
                    this.jobModel.appointments = [];
                }
                this.jobModel.appointments.unshift(appt);
            })
        else {
            if (!this.jobModel.appointments) {
                this.jobModel.appointments = [];
            }
            this.jobModel.appointments.unshift(this.generateAppt(this.selectedDate, timeSlot, resourceId));
        }

        //set the job status back to in process if its closed
        if (this.jobModel.jobStatusId === 3) {
            this.jobModel.jobStatusId = this.lookupService.getJobStatuses().find(x => x.description === "In Process").jobStatusId;
            this.jobModel.jobStatusDisplayName = "In Process";
        }

        this.onResourceSelected.emit(this.jobModel);
    }

    generateAppt(selectedDate: Date, timeSlot: TimeSlot, resourceId: number) {
        const newAppt = new AppointmentModel();
        newAppt.uuid = UtilsService.newGuid();
        newAppt.appointmentId = 0;
        newAppt.createdByInitials = GlobalsService.userInfo.fullName;
        newAppt.createdDate = new Date();
        newAppt.appointmentTypeId = this.appointmentTypeId;
        newAppt.appointmentTypeDisplayName = this.lookupService.getAppointmentTypes(newAppt.appointmentTypeId).find(x => x.appointmentTypeId === newAppt.appointmentTypeId).description;
        const defaultApptStatus = this.lookupService.getDefaultAppointmentStatus();
        newAppt.appointmentStatusId = defaultApptStatus.appointmentStatusId;
        newAppt.appointmentStatusDisplayName = defaultApptStatus.description;
        newAppt.description = this.lookupService.getAppointmentTypes().find(x => x.appointmentTypeId === this.appointmentTypeId).defaultDescription;
        newAppt.notes = this.lookupService.getAppointmentTypes().find(x => x.appointmentTypeId === this.appointmentTypeId).defaultNotes;
        newAppt.resourceId = resourceId;
        newAppt.resourceDisplayName = this.lookupService.getResources().find(x => x.resourceId === resourceId).resourceName;
        const startDateString = `${moment(selectedDate).format("YYYYMMDD")} ${timeSlot.timeSlotDisplay}`;
        newAppt.scheduledDateTime = moment(startDateString, "YYYYMMDD h:mm a").toDate();
        newAppt.scheduledMinutes = this.durationTime;
        newAppt.travelMinutes = this.travelTime;
        newAppt.uiState = "expanded";

        // Any custom questions that were entered, keep
        newAppt.checklistQuestions = [];
        const checklistQuestions = this.lookupService.getChecklistQuestions().filter(c => c.appointmentTypeId === this.appointmentTypeId);
        const resource = this.lookupService.getResources(newAppt.resourceId).find(x => x.resourceId === newAppt.resourceId);
        newAppt.checklistQuestions = checklistQuestions.map(c => {
            // don't add this question if the resource location is not one of the locations selected
            if (c.locations && resource && resource.locationId) {
                if (c.locations.findIndex(x => x === resource.locationId) < 0)
                    return null;
            }
            const appointmentChecklistQuestion = new AppointmentChecklistQuestionModel();
            appointmentChecklistQuestion.appointmentChecklistQuestionId = 0;
            appointmentChecklistQuestion.checklistQuestionId = c.checklistQuestionId;
            appointmentChecklistQuestion.question = c.question;
            appointmentChecklistQuestion.isChecked = false
            appointmentChecklistQuestion.checkedBy = null;
            return appointmentChecklistQuestion;
        })
            .filter(x => x !== null);

        newAppt.showOnSmartlist = this.lookupService.getAppointmentTypes().find(x => x.appointmentTypeId === this.appointmentTypeId).showOnSmartlist;



        if (newAppt.showOnSmartlist) {
            // Get the smartlist questions
            const apptTypeSmartlistQuestions = this.lookupService.getAppointmentTypes().find(x => x.appointmentTypeId === this.appointmentTypeId).appointmentTypeSmartlistQuestions;
            newAppt.smartlistQuestions = apptTypeSmartlistQuestions.map(x => {
                return new AppointmentSmartlistQuestionModel(x.smartlistQuestionId, x.question, false);
            })
        }

        return newAppt;
    }


    async findAvailableSlotsForMultiDay() {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(() => {
            this.getAvailableResources()
        }, 1000)
    }


    getAvailableResources() {
        if (this.multiDates.length === 1) {
            this.onDateChanged(this.multiDates[0]);
            return;
        }

        const compareTimes = [];
        this.multiDates.forEach(x => {
            this.onDateChanged(x);
            compareTimes.push(this.timeSlots);
        });

        this.timeSlots = [];
        let currentTime = moment().startOf('date');
        let resourcesForDate = [];

        for (let i = 0; i < 96; i++) {
            compareTimes.forEach(x => {
                x.forEach(time => {
                    if (time.timeSlotDisplay === currentTime.format('h:mma'))
                        resourcesForDate.push(time.availableResources)
                });

                if (resourcesForDate.length === compareTimes.length) {
                    let compareResources = resourcesForDate[0];
                    resourcesForDate.splice(0, 1);
                    const newTimeSlot = new TimeSlot();
                    newTimeSlot.availableResources = [];
                    newTimeSlot.timeSlotDisplay = currentTime.format('h:mma');
                    let totalResourcesForDay = [];

                    for (let r of compareResources) {
                        resourcesForDate.forEach(x => {
                            x.forEach(ra => {
                                if (ra.resourceId === r.resourceId) {
                                    totalResourcesForDay.push(r);
                                }

                                if (totalResourcesForDay.length === resourcesForDate.length) {
                                    const availableResource = new ResourceAvailability();
                                    const resource = this.resources.find(r => r.resourceId === ra.resourceId);
                                    availableResource.resourceId = resource.resourceId;
                                    availableResource.backgroundColor = resource.backgroundColor;
                                    availableResource.resourceShortName = resource.shortName;
                                    availableResource.textColor = resource.color;
                                    availableResource.inArea = ra.inArea
                                    newTimeSlot.availableResources.push(availableResource);
                                    totalResourcesForDay = [];
                                }

                            })

                        })

                        totalResourcesForDay = [];
                    }

                    this.timeSlots.push(newTimeSlot);
                }

            });

            resourcesForDate = [];
            currentTime = currentTime.add(15, 'minutes');
        }
        this.timeSlots = this.timeSlots.filter(x => x.availableResources.length > 0)

    }

    resetCalendar() {
        this.multiDates = [];
        this.timeSlots = [];
        this.selectedDate = null;
        this.reloadAvailableTimeSlots();
    }

    async onCustomerModelChange(customerModel: ICustomerModel) {
        this.customer = customerModel;
        this.reloadAvailableTimeSlots();
    }



}
