export interface IAppointmentTypeSmartlistQuestionModel {
	appointmentTypeId: number;
	smartlistQuestionId: number;
	question: string;
	isChecked: boolean;
}
 
export class AppointmentTypeSmartlistQuestionModel implements IAppointmentTypeSmartlistQuestionModel {
	constructor() { 
		this.appointmentTypeId = 0;
		this.isChecked = true;
	}

	appointmentTypeId: number;
	smartlistQuestionId: number;
	question: string;
	isChecked: boolean;
} 