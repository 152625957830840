import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { JobCostingDetailsComponent } from './job-costing-details.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
	],
	declarations: [JobCostingDetailsComponent],
	exports: [JobCostingDetailsComponent]

})
export class JobCostingDetailsModule { }
