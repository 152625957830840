<slick-dialog #texterDialogRef header="Send Text">
	<div *ngIf="texterModel">
		<label>Numbers</label>
		<input type="text" class="form-control" [(ngModel)]="texterModel.numbers" />
		<div class="form-group mt-3">
			<div>
				Message
			</div>
			<div class=" mb-2">
				<textarea  class="form-control"
						  (keyup)="checkLength()"
						  [(ngModel)]="texterModel.body"></textarea>
			</div>
			<form-error *ngIf="messageSplit">
				Message is greater than 160 characters and will be split into 2 texts.
			</form-error>
		</div>
	</div>
	<slick-dialog-footer>
		<button type="button" style="width: 120px" class="btn btn-outline-primary me-3" [slick-button-spinner]="spinnerStatus" (click)="onSendText(texterModel)"><i class="far fa-paper-plane"></i> Send</button>
		<button type="button" style="width: 120px" class="btn btn-outline-danger" (click)="onCancel()"><i class="bi bi-x-circle"></i> Cancel</button>

	</slick-dialog-footer>
</slick-dialog>

<slick-confirm-dialog #noNumberEnteredRef showCancelButton='false'>
	<h5 class="border-0 p-0 m-0">No number entered. Please enter at least 1 number.</h5>
</slick-confirm-dialog>

<slick-confirm-dialog #noMessageEnteredRef showCancelButton='false'>
	<h5 class="border-0 p-0 m-0">No message entered. Please enter a message.</h5>
</slick-confirm-dialog>