import * as moment from "moment";

export interface ITimeclockStartEndModel {
	uuid: string;
	startDate?: Date;
	breakStartHoursText?: string;
	startGeolocation?: string;
	endDate?: Date;
	breakEndHoursText?: string;
	endGeolocation?: string;
    totalMinutes?: number;
	totalDisplay: string
	isValid: boolean
	isBefore: boolean;
}

export class TimeclockStartEndModel implements ITimeclockStartEndModel {
	uuid: string;
	startDate?: Date;
	breakStartHoursText?: string;
	startGeolocation?: string;
	endDate?: Date;
	breakEndHoursText?: string;
	endGeolocation?: string;
    totalMinutes?: number;
	totalDisplay: string
	isValid: boolean
	isBefore: boolean;

    constructor(startDate?: Date, endDate?: Date, totalMinutes?: number, uuid: string = null) {
		this.uuid = uuid
		this.isValid = true;
		this.isBefore = false;
        this.startDate = null;
        this.endDate = null;
        this.totalMinutes = null;

        // If we only have total minutes, ignore the start/end
        if (!startDate && !endDate && totalMinutes) {
            this.totalMinutes = totalMinutes;
            this.totalDisplay = moment.utc(this.totalMinutes * 60 * 1000).format("H:mm");
            return;
        }

		if (startDate && moment(startDate).isValid())
			this.startDate = moment(startDate).startOf("minute").toDate();
		if (endDate && moment(endDate).isValid())
            this.endDate = moment(endDate).startOf("minute").toDate();

		if (!this.startDate || moment(this.startDate).isValid() === false)
			this.isValid = false;

		if (!this.endDate || moment(this.endDate).isValid() === false)
			this.isValid = false;

		if (this.isValid) {
			if ((moment(this.startDate).format("hh:mm a") !== "12:00 am") && (moment(this.endDate).format("hh:mm a") !== "12:00 am"))
				this.isBefore = (moment(this.startDate).isSameOrBefore(moment(this.endDate)) === false);
			const totalBreakMinutes = moment.duration(moment(this.endDate).diff(moment(this.startDate))).asMinutes();
			this.totalDisplay = moment.utc(totalBreakMinutes * 60 * 1000).format("H:mm");
		}
		else {
			this.totalDisplay = "N/A";
		}

	}
}