import { Injectable } from '@angular/core';
import moment from 'moment';
import { GlobalsService, UsersService } from '@services';

@Injectable()
export class KeepAliveStore {
	lastActiveTime: Date;

    constructor(private usersService: UsersService) {

    }

    //check if current user is active every 5 minutes
    public start() {
        if (GlobalsService.isDebug)
            return;

        this.usersService.updateLastActiveTime(moment().toDate());
        setInterval(() => {
            if (moment(this.lastActiveTime).add(5, 'minutes') >= moment())
                this.usersService.updateLastActiveTime(moment().toDate())
        }, 300_000);
    }

    public updateLastActiveTime() {
        this.lastActiveTime = moment().toDate();
    }
}
