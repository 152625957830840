export interface IDiscountModel {

	discountId: number;
	name: string;
	sku: string;
	description: string;
	percentAmount?: number; 
	dollarAmount?: number;
	applyToItemList: string;
	hideSKU: boolean;
	hideDescription: boolean;
	hideDiscountAmount: boolean;
	isGlobal: boolean;
	syncToQuickbooks?: boolean;
	quickbooksId: string;
}

export class DiscountModel implements IDiscountModel {
	constructor() {
		this.discountId = 0;
	}

	discountId: number;
	name: string;
	sku: string;
	description: string;
	percentAmount?: number;
	dollarAmount?: number;
	applyToItemList: string;
	hideSKU: boolean;
	hideDescription: boolean;
	hideDiscountAmount: boolean;
	isGlobal: boolean;
	syncToQuickbooks?: boolean;
	quickbooksId: string;
}