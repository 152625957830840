import {ServiceAreaLocationModel} from "./service-area-location.model";

export interface IServiceAreaModel {
	serviceAreaId: number;
	name: string;
	color: string;
	zipcodes: string;
	cityNames: string;
	active: boolean;

	serviceAreaLocations: ServiceAreaLocationModel[]
}

export class ServiceAreaModel implements IServiceAreaModel {
	constructor() {
		this.serviceAreaId = 0;
		this.active = true;
	}

	serviceAreaId: number;
	name: string;
	color: string;
	zipcodes: string;
	cityNames: string;
	active: boolean;

	serviceAreaLocations: ServiceAreaLocationModel[]
}
