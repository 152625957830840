import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { IJobModel, ICustomerJobHistoryModel, IInvoiceModel } from "@models";
import { CustomersService, InvoicesService, LookupService} from "@services";
import { SlickScreenBlockerService } from "@slick-components";

@Component({
    selector: "job-history",
    templateUrl: "./job-history.component.html",
    styleUrls: ["./job-history.component.scss"],
    providers: [CustomersService]
})

export class JobHistoryComponent implements OnChanges {
    @Input() customerId: number;

    customerJobHistory: ICustomerJobHistoryModel[];
    searchString: string = " ";
    invoice: IInvoiceModel;

    constructor(private readonly customersService: CustomersService,
        protected readonly lookupService: LookupService,
        private readonly invoicesService: InvoicesService,
private readonly slickScreenBlockerService: SlickScreenBlockerService    ) {
        
    }
    async ngOnChanges(changes: SimpleChanges) {
        if(changes.customerId){
            this.refreshHistory();
            if (this.customerJobHistory) {
                for (const historyItem of this.customerJobHistory) {
                    if (historyItem.actualCrew) {
                        const userIds: number[] = JSON.parse(historyItem.actualCrew);
                        const users = this.lookupService.getUsers(null, true)
                            .filter(x => userIds.includes(x.userId))
                            .map(u => { return u.fullName });
                            historyItem.crewDisplay = users.join(", ");
                    }
                }
            }
        }
    }

    async refreshHistory() {
        this.slickScreenBlockerService.block();
        this.customerJobHistory = await this.customersService.getCustomerJobHistory(this.customerId, this.searchString);
        this.slickScreenBlockerService.unblock();
    }

    onSearch(searchString: string) {
        this.searchString = searchString;
        this.refreshHistory();
    }

    async openInvoice(invoiceId: number) {
        this.invoice = await this.invoicesService.getInvoice(invoiceId);
    }
}