import { Component, ViewChild } from '@angular/core';
import { SlickDialogComponent, SlickToastService } from "@slick-components";
import { IInvoiceModel } from '@models';
import { SleepService, InvoicesService, GlobalsService, LookupService } from '@services';
import { InvoiceEditComponent } from '../invoice-edit/invoice-edit.component';
import Swal from 'sweetalert2';

@Component({
	selector: 'invoice-edit-dialog',
	templateUrl: 'invoice-edit-dialog.component.html',
	providers: [InvoicesService]
})
export class InvoiceEditDialogComponent {
	@ViewChild('dialogRef', { static: true }) dialogRef: SlickDialogComponent;
	@ViewChild('invoiceEditComponentRef', { static: true }) invoiceEditComponentRef: InvoiceEditComponent;

	invoiceModel: IInvoiceModel;
	defaultApptId: number;
	spinnerStatus: string;
	resolve: any;

	isUserAdmin: boolean = (GlobalsService.userInfo.roleTypeId === 1);
	adminEdit: boolean;

	adminUnlock: boolean = GlobalsService.checkPermission("Invoices", "adminUnlock");
	canMoveToSalesOrder: boolean = GlobalsService.checkPermission("Invoices", "CanMoveToSalesOrder");

	constructor(private invoicesService: InvoicesService,
		private slickToastService: SlickToastService,
	private lookupService: LookupService) {
	}

	openDialog(invoiceModel: IInvoiceModel): Promise<IInvoiceModel> {
		return new Promise((resolve) => {
			this.resolve = resolve;
			this.spinnerStatus = 'reset';

			this.adminEdit = false;
			this.invoiceModel = invoiceModel;
			this.dialogRef.showDialog();
			if (GlobalsService.company.useAdvancedTax && !GlobalsService.company.useCereTax && !this.invoiceModel.customer.taxRegionId && (this.lookupService.getTaxRegions().length ?? 0) > 0 && GlobalsService.company.companyId === 3) {
				Swal.fire({
					icon: 'warning',
					title: 'Warning',
					text: 'Customer has no tax region',
					confirmButtonColor: '#007bff',
					width: '28em',
					heightAuto: false
				});
			}
		});
	}

	async convertToSalesOrder() {
		this.invoiceModel = await this.invoiceEditComponentRef.convertToSalesOrder();

		this.slickToastService.showSuccess("Converted back to sales order");
		this.invoiceEditComponentRef.isSubmitted = false;
		this.resolve(this.invoiceModel);
		this.dialogRef.hideDialog();
	}

	async saveInvoice() {
		try {
			this.invoiceEditComponentRef.isSubmitted = true;
			
			if (!this.invoiceEditComponentRef.isValid()) {
				this.spinnerStatus = 'error';
				return;
			}

			this.spinnerStatus = 'spin';

			await SleepService.sleep(500);

			if (this.invoiceEditComponentRef.useInventory && this.invoiceModel.useDefaultInventory) {
				this.invoiceModel.inventoryLineItems = this.invoicesService.syncInventoryFromLineItems(this.invoiceModel);
			}

			this.invoiceModel = await this.invoicesService.updateInvoice(this.invoiceModel);

			this.spinnerStatus = 'ok';

			await SleepService.sleep(500);

			this.invoiceEditComponentRef.isSubmitted = false;
			this.resolve(this.invoiceModel);
			this.dialogRef.hideDialog();
		} catch {
			this.spinnerStatus = 'error';
		}
	}

	async cancelInvoice() {
		await this.dialogRef.hideDialog();
		this.resolve(null);
	}

	async generateInvoicePdf() {
		await this.invoiceEditComponentRef.generateInvoicePdf();
	}

	async generateWorkOrderPdf() {
		this.invoiceEditComponentRef.generateWorkOrderPdf();
	}

	async emailInvoice() {
		await this.invoiceEditComponentRef.emailInvoice();
	}

	async textInvoice() {
		await this.invoiceEditComponentRef.textInvoice();
	}

	async payment() {
		await this.invoiceEditComponentRef.payment();
	}

	toggleAdminEdit() {
		this.adminEdit = !this.adminEdit;
		this.invoiceEditComponentRef.adminEdit = this.adminEdit;
	}
	onInvoiceSaved(invoiceModel: IInvoiceModel) {
		this.invoiceModel = invoiceModel;
	}
}
