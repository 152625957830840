import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { GlobalsService } from '../services';

@Injectable()
export class LoggerStore {
    logs: string[] = [];

    addLog(log: string) {
        console.info(log);

        this.logs.unshift(`${moment().format("M/D/YYYY h:mm:ss")}: ${log}`);
        if (this.logs.length > 50)
            this.logs = this.logs.slice(0, 50);
    }
}
